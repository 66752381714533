program TeampartnerProject;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Main in 'Main.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.Run;
end.
