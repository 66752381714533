{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPlanner;

interface

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF FMXLIB}
{$DEFINE FMXWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE FMXWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

uses
  Classes, Types, WEBLib.Controls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCImage, WEBLib.TMSFNCPlannerData, WEBLib.Graphics,
  WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCGraphics, WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.Menus, WEBLib.TMSFNCHTMLText, WEBLib.TMSFNCPopup,
  WEBLib.TMSFNCPlannerBase, WEBLib.Forms, WEBLib.TMSFNCTypes, WEBLib.TMSFNCCustomComponent, WEBLib.TMSFNCToolBarPopup, WEBLib.TMSFNCGraphicsTypes
  {$IFDEF FMXLIB}
  ,FMX.DateTimeCtrls, FMX.ListBox, FMX.Edit, FMX.Memo, FMX.Objects, FMX.Types
  {$ENDIF}
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,Generics.Collections, Generics.Defaults, UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF VCLLIB}
  ,ComCtrls
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl, DateTimePicker
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,SysUtils
  {$ENDIF}
  ;

const
  TTMSFNCPlannerDocURL = TTMSFNCBaseDocURL + 'tmsfncuipack/components/ttmsfncplanner/';
  CACHEWIDTH = 1000;
  CACHEHEIGHT = 1000;
  {$IFDEF ANDROID}
  SCROLLINGDELAY = 40;
  {$ELSE}
  SCROLLINGDELAY = 0;
  {$ENDIF}
  {$IFDEF FMXLIB}
  TMSFNCPlannerAreaColor = $BFA9A9A9;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TMSFNCPlannerAreaColor = $BDAA86;
  {$ENDIF}

const
  MAJ_VER = 2; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 2; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed: Issue with displaying items in pmMultiMonth mode
  //          : Fixed: Issue selecting multiple items programmatically
  // v1.0.0.2 : Fixed: Issue with cell to custom datetime conversion
  //          : Fixed: Issue with moving, sizing items in custom mode
  // v1.0.0.3 : Improved: UnselectItem & UnselectAllItems
  //          : Fixed: Issue with destroying planner on mobile operating systems
  // v1.0.0.4 : Fixed: Issue with auto-sizing of labels
  // v1.0.0.5 : Fixed: Issue with design-time initialization of SwipeToNextDateTime
  // v1.0.0.6 : Improved: Exposed OnAfterItemChanged
  // v1.0.0.7 : Fixed: Issue with minimum size of item
  // v1.0.0.8 : Improved: Right mouse button excluded from being able to select a range
  // v1.0.0.9 : Fixed: Issue getting current time in dmDay mode
  // v1.0.1.0 : Fixed: Issue with hints not showing properly when items are close to eachother
  // v1.0.1.1 : Fixed: Issue with OnAfterMoveItem/OnAfterSizeItem events called when combining mouse and keyboard
  // v1.0.1.2 : Fixed: Issue with correct DBKey value in Database after selecting, inserting or updating an item
  // v1.0.1.3 : Fixed: Issue with detecting anchors in combination with IMG tag
  //          : Improved: procedure ClearSelection
  // v1.0.1.4 : Fixed: Issue with current time display in half day period mode
  // v1.0.2.0 : New : OnItemTitleAnchorClick, OnGroupAnchorClick and OnPositionAnchorClick events implemented
  // v1.0.2.1 : Fixed : Issue with FindFirstItem and FindNextItem not returning the correct items
  // v1.0.2.2 : Fixed : Issue with scrolling events OnHScroll & OnVScroll not called
  // v1.0.2.3 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.2.4 : Fixed : Issue with caching mechanism
  // v1.0.2.5 : Fixed : Issue with anchoring in item when text alignment is changed
  // v1.0.2.6 : Fixed : Issue with AddItemAtSelection in combination with resources
  // v2.0.0.0 : New : Full day items & Full day appearance
  //          : New : GlobalFont interface implemented
  //          : New : Updated initial look
  //          : Fixed : Issue with handling double click and custom item editor
  // v2.0.1.0 : New : CustomItemEditorForm to custom design and integrate your own item editing dialog
  // v2.0.1.1 : Fixed : Issue with displaying current time in pmDay mode
  // v2.0.1.2 : Fixed : Issue with clearing and adding items in TMS WEB Core

resourcestring
  sTMSFNCPlannerOK = 'OK';
  sTMSFNCPlannerCancel = 'Cancel';
  sTMSFNCPlannerRemove = 'Remove';
  sTMSFNCPlannerStartTime = 'Start Time';
  sTMSFNCPlannerEndTime = 'End Time';
  sTMSFNCPlannerPosition = 'Position';
  sTMSFNCPlannerTitle = 'Title';
  sTMSFNCPlannerText = 'Text';
  sTMSFNCPlannerFullDay = 'Full Day';

type
  TTMSFNCCustomPlanner = class;

  {$IFDEF FMXLIB}
  TTMSFNCPlannerDateEdit = class(TDateEdit);
  TTMSFNCPlannerTimeEdit = class(TTimeEdit);
  TTMSFNCPlannerMemo = class(TMemo);
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCPlannerDateEdit = class(TDateTimePicker);
  TTMSFNCPlannerTimeEdit = class(TDateTimePicker);
  TTMSFNCPlannerMemo = class(TMemo)
  private
    FPlanner: TTMSFNCCustomPlanner;
  protected
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure DoExit; override;
  public
    constructor Create(AOwner: TComponent); override;
  end;
  {$ENDIF}

  {$IFDEF WEBLIB}
  TTMSFNCPlannerCache = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
  public
    property Items[Index: Integer]: TTMSFNCPlannerCacheItem read GetItem write SetItem; default;
  end;
  TTMSFNCPlannerDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
  public
    property Items[Index: Integer]: TTMSFNCPlannerCacheItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerCache = class(TObjectList<TTMSFNCPlannerCacheItem>);
  TTMSFNCPlannerDisplayList = class(TList<TTMSFNCPlannerCacheItem>);
  {$ENDIF}
  TTMSFNCPlannerGridCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerItemCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerFullDaysItemTopCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerFullDaysItemBottomCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerPositionsCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerPositionsTopCache = class(TTMSFNCPlannerPositionsCache);
  TTMSFNCPlannerPositionsBottomCache = class(TTMSFNCPlannerPositionsCache);
  TTMSFNCPlannerGroupsCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerGroupsTopCache = class(TTMSFNCPlannerGroupsCache);
  TTMSFNCPlannerGroupsBottomCache = class(TTMSFNCPlannerGroupsCache);
  TTMSFNCPlannerFullDaysCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerFullDaysTopCache = class(TTMSFNCPlannerFullDaysCache);
  TTMSFNCPlannerFullDaysBottomCache = class(TTMSFNCPlannerFullDaysCache);
  TTMSFNCPlannerTimeLineCache = class(TTMSFNCPlannerCache);
  TTMSFNCPlannerTimeLineLeftCache = class(TTMSFNCPlannerTimeLineCache);
  TTMSFNCPlannerTimeLineRightCache = class(TTMSFNCPlannerTimeLineCache);

  TTMSFNCPlannerGridDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerItemDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerFullDaysItemTopDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerFullDaysItemBottomDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerPositionsDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerPositionsTopDisplayList = class(TTMSFNCPlannerPositionsDisplayList);
  TTMSFNCPlannerPositionsBottomDisplayList = class(TTMSFNCPlannerPositionsDisplayList);
  TTMSFNCPlannerGroupsDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerGroupsTopDisplayList = class(TTMSFNCPlannerGroupsDisplayList);
  TTMSFNCPlannerGroupsBottomDisplayList = class(TTMSFNCPlannerGroupsDisplayList);
  TTMSFNCPlannerFullDaysDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerFullDaysTopDisplayList = class(TTMSFNCPlannerFullDaysDisplayList);
  TTMSFNCPlannerFullDaysBottomDisplayList = class(TTMSFNCPlannerFullDaysDisplayList);
  TTMSFNCPlannerTimeLineDisplayList = class(TTMSFNCPlannerDisplayList);
  TTMSFNCPlannerTimeLineLeftDisplayList = class(TTMSFNCPlannerTimeLineDisplayList);
  TTMSFNCPlannerTimeLineRightDisplayList = class(TTMSFNCPlannerTimeLineDisplayList);

  TTMSFNCPlannerPositionsLayout = (pplTop, pplBottom);
  TTMSFNCPlannerPositionsLayouts = set of TTMSFNCPlannerPositionsLayout;

  TTMSFNCPlannerGroupLayout = (pglTop, pglBottom);
  TTMSFNCPlannerGroupsLayouts = set of TTMSFNCPlannerGroupLayout;

  TTMSFNCPlannerTimeLineLayout = (ptlLeft, ptlRight);
  TTMSFNCPlannerTimeLineLayouts = set of TTMSFNCPlannerTimeLineLayout;

  TTMSFNCPlannerDisplayGroup = record
    StartPosition: Integer;
    EndPosition: Integer;
    TextRect: TRectF;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCPlannerDisplayGroup) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCPlannerDisplayFullDay = record
    StartPosition: Integer;
    EndPosition: Integer;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCPlannerDisplayFullDay) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCPlannerAdapter = class(TTMSFNCCustomComponent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FActive: boolean;
    procedure SetActive(const Value: boolean);
  protected
    function GetInstance: NativeUInt; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
  public
    property Planner: TTMSFNCCustomPlanner read FPlanner write FPlanner;
    procedure LoadItems; virtual;
    procedure GetItems(PeriodFrom,PeriodTo: TDateTime); virtual; abstract;
    procedure DeleteItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure InsertItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure UpdateItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure AfterUpdateItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure SelectItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure UpdateItems; virtual;
    constructor Create(AOwner: TComponent); override;
  published
    property Active: Boolean read FActive write SetActive default False;
  end;

  {$IFDEF FMXLIB}
  TTMSFNCPlannerInplaceEditor = TControl;
  TTMSFNCPlannerEditingDialogContentPanel = TControl;
  TTMSFNCPlannerCustomParent = TFmxObject;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCPlannerInplaceEditor = TWinControl;
  TTMSFNCPlannerEditingDialogContentPanel = TWinControl;
  TTMSFNCPlannerCustomParent = TWinControl;
  {$ENDIF}
  TTMSFNCPlannerInplaceEditorClass = class of TTMSFNCPlannerInplaceEditor;

  TTMSFNCPlannerEditingMode = (pemInsert, pemUpdate);

  {$IFDEF WEBLIB}
  TTMSFNCPlannerCustomItemEditorForm = class(TWebForm)
  {$ELSE}
  TTMSFNCPlannerCustomItemEditorForm = class(TForm)
  {$ENDIF}
  private
    FPlanner: TTMSFNCCustomPlanner;
    FPlannerItem: TTMSFNCPlannerItem;
    FPlannerMode: TTMSFNCPlannerEditingMode;
    FPlannerEndTime: TDateTime;
    FPlannerStartTime: TDateTime;
    FPlannerResource: Integer;
    FPlannerTitle: string;
    FPlannerText: string;
    FPlannerFullDay: Boolean;
  public
    property PlannerMode: TTMSFNCPlannerEditingMode read FPlannerMode;
    property PlannerItem: TTMSFNCPlannerItem read FPlannerItem;
    property Planner: TTMSFNCCustomPlanner read FPlanner;
    property PlannerEndTime: TDateTime read FPlannerEndTime write FPlannerEndTime;
    property PlannerStartTime: TDateTime read FPlannerStartTime write FPlannerStartTime;
    property PlannerResource: Integer read FPlannerResource write FPlannerResource;
    property PlannerTitle: string read FPlannerTitle write FPlannerTitle;
    property PlannerText: string read FPlannerText write FPlannerText;
    property PlannerFullDay: Boolean read FPlannerFullDay write FPlannerFullDay;

    procedure Initialize; virtual; abstract;
  end;

  TTMSFNCPlannerCustomItemEditorFormClass = class of TTMSFNCPlannerCustomItemEditorForm;

  TTMSFNCPlannerCustomItemEditor = class(TTMSFNCCustomComponent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FCreated: Boolean;
    FInitialized: Boolean;
  protected
    function GetInstance: NativeUInt; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    property Created: Boolean read FCreated write FCreated default False;
    property Initialized: Boolean read FInitialized write FInitialized default False;
  public
    property Planner: TTMSFNCCustomPlanner read FPlanner write FPlanner;
    function CreateInstance: TTMSFNCPlannerCustomItemEditor; virtual; abstract;
    procedure CreateCustomContentPanel; virtual; abstract;
    procedure InitializeCustomContentPanel; virtual; abstract;
    procedure GetCustomContentPanel(AItem: TTMSFNCPlannerItem; var AContentPanel: TTMSFNCPlannerEditingDialogContentPanel); virtual; abstract;
    procedure ItemToCustomContentPanel(AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel); virtual; abstract;
    procedure CustomContentPanelToItem(AContentPanel: TTMSFNCPlannerEditingDialogContentPanel; AItem: TTMSFNCPlannerItem); virtual; abstract;
    constructor Create(AOwner: TComponent); override;
    procedure Assign({%H-}Source: TPersistent); override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerDisplayGroups = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerDisplayGroup;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerDisplayGroup);
  public
    property Items[Index: Integer]: TTMSFNCPlannerDisplayGroup read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerDisplayGroups = class(TList<TTMSFNCPlannerDisplayGroup>);
  {$ENDIF}

  {$IFDEF WEBLIB}
  TTMSFNCPlannerDisplayFullDays = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerDisplayFullDay;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerDisplayFullDay);
  public
    property Items[Index: Integer]: TTMSFNCPlannerDisplayFullDay read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerDisplayFullDays = class(TList<TTMSFNCPlannerDisplayFullDay>);
  {$ENDIF}

  TTMSFNCPlannerConflict = class
  private
    FPlanner: TTMSFNCCustomPlanner;
    {$IFNDEF LCLLIB}
    {$IFNDEF WEBLIB}
    FCompareResourceDates: IComparer<TTMSFNCPlannerResourceDate>;
    {$ENDIF}
    {$ENDIF}
    FNeedsConflictsUpdate: Boolean;
    FItems: TTMSFNCPlannerResourceItems;
    FDates: TTMSFNCPlannerResourceDates;
    FPosition: Integer;
  protected
    property NeedsConflictsUpdate: Boolean read FNeedsConflictsUpdate write FNeedsConflictsUpdate;
    property Position: Integer read FPosition write FPosition;
    property Items: TTMSFNCPlannerResourceItems read FItems write FItems;
    property Dates: TTMSFNCPlannerResourceDates read FDates write FDates;
    procedure UpdateItems;
    procedure UpdateDates;
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerConflicts = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerConflict;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerConflict);
  public
    property Items[Index: Integer]: TTMSFNCPlannerConflict read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerConflicts = class(TObjectList<TTMSFNCPlannerConflict>);
  {$ENDIF}

  TTMSFNCPlannerGridCellAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FVerticalStroke: TTMSFNCGraphicsStroke;
    FHorizontalStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FDisabledFill: TTMSFNCGraphicsFill;
    FInActiveFill: TTMSFNCGraphicsFill;
    FHorizontalSubStroke: TTMSFNCGraphicsStroke;
    procedure SetHorizontalStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetInActiveFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHorizontalSubStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property VerticalStroke: TTMSFNCGraphicsStroke read FVerticalStroke write SetVerticalStroke;
    property HorizontalStroke: TTMSFNCGraphicsStroke read FHorizontalStroke write SetHorizontalStroke;
    property HorizontalSubStroke: TTMSFNCGraphicsStroke read FHorizontalSubStroke write SetHorizontalSubStroke;
    property InActiveFill: TTMSFNCGraphicsFill read FInActiveFill write SetInActiveFill;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
  end;

  TTMSFNCPlannerLinkArrowShape = (lasNormal, lasFilled);

  TTMSFNCPlannerItemsAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FGap: Double;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    FTitleFill: TTMSFNCGraphicsFill;
    FTitleStroke: TTMSFNCGraphicsStroke;
    FTitleFont: TTMSFNCGraphicsFont;
    FDisabledTitleStroke: TTMSFNCGraphicsStroke;
    FSelectedTitleStroke: TTMSFNCGraphicsStroke;
    FActiveTitleStroke: TTMSFNCGraphicsStroke;
    FDisabledFont: TTMSFNCGraphicsFont;
    FSelectedFont: TTMSFNCGraphicsFont;
    FActiveFont: TTMSFNCGraphicsFont;
    FDisabledFill: TTMSFNCGraphicsFill;
    FSelectedFill: TTMSFNCGraphicsFill;
    FActiveFill: TTMSFNCGraphicsFill;
    FDisabledStroke: TTMSFNCGraphicsStroke;
    FDisabledTitleFont: TTMSFNCGraphicsFont;
    FSelectedStroke: TTMSFNCGraphicsStroke;
    FSelectedTitleFont: TTMSFNCGraphicsFont;
    FActiveStroke: TTMSFNCGraphicsStroke;
    FActiveTitleFont: TTMSFNCGraphicsFont;
    FDisabledTitleFill: TTMSFNCGraphicsFill;
    FSelectedTitleFill: TTMSFNCGraphicsFill;
    FActiveTitleFill: TTMSFNCGraphicsFill;
    FSizeAreaSize: Double;
    FMoveAreaSize: Double;
    FMoveAreaColor: TTMSFNCGraphicsColor;
    FSizeAreaColor: TTMSFNCGraphicsColor;
    FShowMoveArea: Boolean;
    FShowSizeArea: Boolean;
    FTextVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTextHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FShowItemHelpers: Boolean;
    FShowItemSizeHandlers: Boolean;
    FSizeHandlerWidth: Double;
    FSizeHandlerHeight: Double;
    FSizeHandlerUpBitmap: TTMSFNCBitmap;
    FSizeHandlerLeftBitmap: TTMSFNCBitmap;
    FSizeHandlerRightBitmap: TTMSFNCBitmap;
    FSizeHandlerDownBitmap: TTMSFNCBitmap;
    FDeleteHandlerBitmap: TTMSFNCBitmap;
    FDeleteHandlerWidth: Double;
    FDeleteHandlerHeight: Double;
    FDeleteAreaSize: Double;
    FDeleteAreaColor: TTMSFNCGraphicsColor;
    FShowDeleteArea: Boolean;
    FShowLinks: Boolean;
    FLinkStroke: TTMSFNCGraphicsStroke;
    FLinkArrowShape: TTMSFNCPlannerLinkArrowShape;
    FLinkArrowSize: Double;
    FAlternativeHints: Boolean;
    procedure SetGap(const Value: Double);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTitleFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTitleStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTitleFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDisabledTitleFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledTitleFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDisabledTitleStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSelectedFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSelectedFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSelectedTitleFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSelectedTitleFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedTitleStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetActiveFill(const Value: TTMSFNCGraphicsFill);
    procedure SetActiveFont(const Value: TTMSFNCGraphicsFont);
    procedure SetActiveStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetActiveTitleFill(const Value: TTMSFNCGraphicsFill);
    procedure SetActiveTitleFont(const Value: TTMSFNCGraphicsFont);
    procedure SetActiveTitleStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetMoveAreaSize(const Value: Double);
    procedure SetSizeAreaSize(const Value: Double);
    procedure SetMoveAreaColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSizeAreaColor(const Value: TTMSFNCGraphicsColor);
    procedure SetShowMoveArea(const Value: Boolean);
    procedure SetShowSizeArea(const Value: Boolean);
    procedure SetTextHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTextVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetShowItemHelpers(const Value: Boolean);
    procedure SetSizeHandlerHeight(const Value: Double);
    procedure SetSizeHandlerWidth(const Value: Double);
    procedure SetSizeHandlerDownBitmap(const Value: TTMSFNCBitmap);
    procedure SetSizeHandlerLeftBitmap(const Value: TTMSFNCBitmap);
    procedure SetSizeHandlerRightBitmap(const Value: TTMSFNCBitmap);
    procedure SetSizeHandlerUpBitmap(const Value: TTMSFNCBitmap);
    procedure SetDeleteHandlerBitmap(const Value: TTMSFNCBitmap);
    procedure SetDeleteHandlerHeight(const Value: Double);
    procedure SetDeleteHandlerWidth(const Value: Double);
    procedure SetDeleteAreaColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDeleteAreaSize(const Value: Double);
    procedure SetShowDeleteArea(const Value: Boolean);
    procedure SetShowLinks(const Value: Boolean);
    procedure SetLinkStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetLinkArrowShape(const Value: TTMSFNCPlannerLinkArrowShape);
    procedure SetLinkArrowSize(const Value: Double);
    procedure SetAlternativeHints(const Value: Boolean);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property AlternativeHints: Boolean read FAlternativeHints write SetAlternativeHints default True;
    property Gap: Double read FGap write SetGap;
    property MoveAreaSize: Double read FMoveAreaSize write SetMoveAreaSize;
    property SizeAreaSize: Double read FSizeAreaSize write SetSizeAreaSize;
    property DeleteAreaSize: Double read FDeleteAreaSize write SetDeleteAreaSize;
    property DeleteHandlerBitmap: TTMSFNCBitmap read FDeleteHandlerBitmap write SetDeleteHandlerBitmap;
    property SizeHandlerHeight: Double read FSizeHandlerHeight write SetSizeHandlerHeight;
    property SizeHandlerWidth: Double read FSizeHandlerWidth write SetSizeHandlerWidth;
    property DeleteHandlerHeight: Double read FDeleteHandlerHeight write SetDeleteHandlerHeight;
    property DeleteHandlerWidth: Double read FDeleteHandlerWidth write SetDeleteHandlerWidth;
    property SizeHandlerLeftBitmap: TTMSFNCBitmap read FSizeHandlerLeftBitmap write SetSizeHandlerLeftBitmap;
    property SizeHandlerRightBitmap: TTMSFNCBitmap read FSizeHandlerRightBitmap write SetSizeHandlerRightBitmap;
    property SizeHandlerUpBitmap: TTMSFNCBitmap read FSizeHandlerUpBitmap write SetSizeHandlerUpBitmap;
    property SizeHandlerDownBitmap: TTMSFNCBitmap read FSizeHandlerDownBitmap write SetSizeHandlerDownBitmap;
    property MoveAreaColor: TTMSFNCGraphicsColor read FMoveAreaColor write SetMoveAreaColor default TMSFNCPlannerAreaColor;
    property SizeAreaColor: TTMSFNCGraphicsColor read FSizeAreaColor write SetSizeAreaColor default TMSFNCPlannerAreaColor;
    property DeleteAreaColor: TTMSFNCGraphicsColor read FDeleteAreaColor write SetDeleteAreaColor default gcSteelBlue;
    property ShowMoveArea: Boolean read FShowMoveArea write SetShowMoveArea default True;
    property ShowSizeArea: Boolean read FShowSizeArea write SetShowSizeArea default True;
    property ShowDeleteArea: Boolean read FShowDeleteArea write SetShowDeleteArea default False;
    property ShowItemHelpers: Boolean read FShowItemHelpers write SetShowItemHelpers default True;
    property ShowLinks: Boolean read FShowLinks write SetShowLinks default False;
    property LinkStroke: TTMSFNCGraphicsStroke read FLinkStroke write SetLinkStroke;
    property LinkArrowShape: TTMSFNCPlannerLinkArrowShape read FLinkArrowShape write SetLinkArrowShape default lasNormal;
    property LinkArrowSize: Double read FLinkArrowSize write SetLinkArrowSize;


    property TitleFill: TTMSFNCGraphicsFill read FTitleFill write SetTitleFill;
    property TitleStroke: TTMSFNCGraphicsStroke read FTitleStroke write SetTitleStroke;
    property TitleFont: TTMSFNCGraphicsFont read FTitleFont write SetTitleFont;

    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;

    property SelectedTitleFill: TTMSFNCGraphicsFill read FSelectedTitleFill write SetSelectedTitleFill;
    property SelectedTitleStroke: TTMSFNCGraphicsStroke read FSelectedTitleStroke write SetSelectedTitleStroke;
    property SelectedTitleFont: TTMSFNCGraphicsFont read FSelectedTitleFont write SetSelectedTitleFont;

    property ActiveTitleFill: TTMSFNCGraphicsFill read FActiveTitleFill write SetActiveTitleFill;
    property ActiveTitleStroke: TTMSFNCGraphicsStroke read FActiveTitleStroke write SetActiveTitleStroke;
    property ActiveTitleFont: TTMSFNCGraphicsFont read FActiveTitleFont write SetActiveTitleFont;

    property SelectedFill: TTMSFNCGraphicsFill read FSelectedFill write SetSelectedFill;
    property SelectedStroke: TTMSFNCGraphicsStroke read FSelectedStroke write SetSelectedStroke;
    property SelectedFont: TTMSFNCGraphicsFont read FSelectedFont write SetSelectedFont;

    property ActiveFill: TTMSFNCGraphicsFill read FActiveFill write SetActiveFill;
    property ActiveStroke: TTMSFNCGraphicsStroke read FActiveStroke write SetActiveStroke;
    property ActiveFont: TTMSFNCGraphicsFont read FActiveFont write SetActiveFont;

    property DisabledTitleFill: TTMSFNCGraphicsFill read FDisabledTitleFill write SetDisabledTitleFill;
    property DisabledTitleStroke: TTMSFNCGraphicsStroke read FDisabledTitleStroke write SetDisabledTitleStroke;
    property DisabledTitleFont: TTMSFNCGraphicsFont read FDisabledTitleFont write SetDisabledTitleFont;

    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read FDisabledStroke write SetDisabledStroke;
    property DisabledFont: TTMSFNCGraphicsFont read FDisabledFont write SetDisabledFont;

    property TitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTitleHorizontalTextAlign write SetTitleHorizontalTextAlign default gtaLeading;
    property TitleVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTitleVerticalTextAlign write SetTitleVerticalTextAlign default gtaLeading;
    property TextHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTextHorizontalTextAlign write SetTextHorizontalTextAlign default gtaLeading;
    property TextVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTextVerticalTextAlign write SetTextVerticalTextAlign default gtaLeading;
  end;

  TTMSFNCPlannerSelectionAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCPlannerVerticalTextMode = (pvtmAuto, pvtmAlways, pvtmNone);

  TTMSFNCPlannerTimeLineAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FLayouts: TTMSFNCPlannerTimeLineLayouts;
    FLeftSize: Double;
    FRightSize: Double;
    FRightStroke: TTMSFNCGraphicsStroke;
    FLeftFill: TTMSFNCGraphicsFill;
    FLeftStroke: TTMSFNCGraphicsStroke;
    FRightFill: TTMSFNCGraphicsFill;
    FLeftFont: TTMSFNCGraphicsFont;
    FRightFont: TTMSFNCGraphicsFont;
    FStretch: Boolean;
    FLeftSubUnitFontSize: Double;
    FRightSubUnitFontSize: Double;
    FCurrentTimeColor: TTMSFNCGraphicsColor;
    FLeftSubVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FLeftVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FRightSubVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FLeftSubHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FRightVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FLeftHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FRightSubHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FRightHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FLeftSubStroke: TTMSFNCGraphicsStroke;
    FRightSubStroke: TTMSFNCGraphicsStroke;
    FLeftSubVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FLeftVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FRightSubVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FRightVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    procedure SetLayouts(const Value: TTMSFNCPlannerTimeLineLayouts);
    procedure SetLeftSize(const Value: Double);
    procedure SetRightSize(const Value: Double);
    procedure SetLeftFill(const Value: TTMSFNCGraphicsFill);
    procedure SetLeftStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetRightFill(const Value: TTMSFNCGraphicsFill);
    procedure SetRightStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetLeftFont(const Value: TTMSFNCGraphicsFont);
    procedure SetRightFont(const Value: TTMSFNCGraphicsFont);
    procedure SetStretch(const Value: Boolean);
    procedure SetLeftSubUnitFontSize(const Value: Double);
    procedure SetRightSubUnitFontSize(const Value: Double);
    procedure SetCurrentTimeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetLeftHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetLeftSubHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetLeftSubVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetLeftVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetRightHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetRightSubHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetRightSubVerticalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetRightVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetLeftSubStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetRightSubStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetLeftSubVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetLeftVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetRightSubVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetRightVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Layouts: TTMSFNCPlannerTimeLineLayouts read FLayouts write SetLayouts default [ptlLeft];
    property Stretch: Boolean read FStretch write SetStretch default False;
    property LeftSize: Double read FLeftSize write SetLeftSize;
    property RightSize: Double read FRightSize write SetRightSize;
    property LeftFill: TTMSFNCGraphicsFill read FLeftFill write SetLeftFill;
    property RightFill: TTMSFNCGraphicsFill read FRightFill write SetRightFill;
    property LeftStroke: TTMSFNCGraphicsStroke read FLeftStroke write SetLeftStroke;
    property RightStroke: TTMSFNCGraphicsStroke read FRightStroke write SetRightStroke;
    property LeftSubStroke: TTMSFNCGraphicsStroke read FLeftSubStroke write SetLeftSubStroke;
    property RightSubStroke: TTMSFNCGraphicsStroke read FRightSubStroke write SetRightSubStroke;
    property LeftFont: TTMSFNCGraphicsFont read FLeftFont write SetLeftFont;
    property RightFont: TTMSFNCGraphicsFont read FRightFont write SetRightFont;
    property LeftSubUnitFontSize: Double read FLeftSubUnitFontSize write SetLeftSubUnitFontSize;
    property RightSubUnitFontSize: Double read FRightSubUnitFontSize write SetRightSubUnitFontSize;
    property CurrentTimeColor: TTMSFNCGraphicsColor read FCurrentTimeColor write SetCurrentTimeColor default gcOrange;
    property LeftHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FLeftHorizontalTextAlign write SetLeftHorizontalTextAlign default gtaLeading;
    property LeftVerticalTextAlign: TTMSFNCGraphicsTextAlign read FLeftVerticalTextAlign write SetLeftVerticalTextAlign default gtaLeading;
    property RightHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FRightHorizontalTextAlign write SetRightHorizontalTextAlign default gtaLeading;
    property RightVerticalTextAlign: TTMSFNCGraphicsTextAlign read FRightVerticalTextAlign write SetRightVerticalTextAlign default gtaLeading;
    property LeftSubHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FLeftSubHorizontalTextAlign write SetLeftSubHorizontalTextAlign default gtaTrailing;
    property LeftSubVerticalTextAlign: TTMSFNCGraphicsTextAlign read FLeftSubVerticalTextAlign write SetLeftSubVerticalTextAlign default gtaTrailing;
    property RightSubHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FRightSubHorizontalTextAlign write SetRightSubHorizontalTextAlign default gtaTrailing;
    property RightSubVerticalTextAlign: TTMSFNCGraphicsTextAlign read FRightSubVerticalTextAlign write SetRightSubVerticalTextAlign default gtaTrailing;
    property LeftVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FLeftVerticalTextMode write SetLeftVerticalTextMode default pvtmNone;
    property RightVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FRightVerticalTextMode write SetRightVerticalTextMode default pvtmNone;
    property LeftSubVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FLeftSubVerticalTextMode write SetLeftSubVerticalTextMode default pvtmNone;
    property RightSubVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FRightSubVerticalTextMode write SetRightSubVerticalTextMode default pvtmNone;
  end;

  TTMSFNCPlannerPositionsAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FLayouts: TTMSFNCPlannerPositionsLayouts;
    FStretch: Boolean;
    FBottomSize: Double;
    FTopSize: Double;
    FBottomFill: TTMSFNCGraphicsFill;
    FBottomStroke: TTMSFNCGraphicsStroke;
    FTopFill: TTMSFNCGraphicsFill;
    FTopStroke: TTMSFNCGraphicsStroke;
    FTopFont: TTMSFNCGraphicsFont;
    FBottomFont: TTMSFNCGraphicsFont;
    FTopVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTopHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FBottomHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FBottomVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FSize: Double;
    FTopVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FBottomVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FTopNavigationButtonStroke: TTMSFNCGraphicsStroke;
    FBottomNavigationButtonFill: TTMSFNCGraphicsFill;
    FBottomNavigationButtonStroke: TTMSFNCGraphicsStroke;
    FTopNavigationButtonFill: TTMSFNCGraphicsFill;
    FFillEmptySpaces: Boolean;
    FBottomNavigationButtonDownFill: TTMSFNCGraphicsFill;
    FTopNavigationButtonHoverFill: TTMSFNCGraphicsFill;
    FBottomNavigationButtonDownStroke: TTMSFNCGraphicsStroke;
    FTopNavigationButtonHoverStroke: TTMSFNCGraphicsStroke;
    FTopNavigationButtonDownFill: TTMSFNCGraphicsFill;
    FTopNavigationButtonDownStroke: TTMSFNCGraphicsStroke;
    FBottomNavigationButtonHoverFill: TTMSFNCGraphicsFill;
    FBottomNavigationButtonHoverStroke: TTMSFNCGraphicsStroke;
    FTopRightNavigationButtonSize: Double;
    FBottomLeftNavigationButtonSize: Double;
    FBottomRightNavigationButtonSize: Double;
    FTopLeftNavigationButtonSize: Double;
    procedure SetLayouts(const Value: TTMSFNCPlannerPositionsLayouts);
    procedure SetStretch(const Value: Boolean);
    procedure SetBottomSize(const Value: Double);
    procedure SetTopSize(const Value: Double);
    procedure SetBottomFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTopVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetBottomHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetBottomVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSize(const Value: Double);
    procedure SetTopVerticalTextMode(const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetBottomVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetBottomNavigationButtonFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomNavigationButtonStroke(
      const Value: TTMSFNCGraphicsStroke);
    procedure SetTopNavigationButtonFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopNavigationButtonStroke(
      const Value: TTMSFNCGraphicsStroke);
    procedure SetFillEmptySpaces(const Value: Boolean);
    procedure SetBottomNavigationButtonDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomNavigationButtonDownStroke(
      const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomNavigationButtonHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomNavigationButtonHoverStroke(
      const Value: TTMSFNCGraphicsStroke);
    procedure SetTopNavigationButtonDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopNavigationButtonDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopNavigationButtonHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopNavigationButtonHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomLeftNavigationButtonSize(const Value: Double);
    procedure SetBottomRightNavigationButtonSize(const Value: Double);
    procedure SetTopLeftNavigationButtonSize(const Value: Double);
    procedure SetTopRightNavigationButtonSize(const Value: Double);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Size: Double read FSize write SetSize;
    property Layouts: TTMSFNCPlannerPositionsLayouts read FLayouts write SetLayouts default [pplTop];
    property Stretch: Boolean read FStretch write SetStretch default True;
    property TopSize: Double read FTopSize write SetTopSize;
    property BottomSize: Double read FBottomSize write SetBottomSize;
    property TopFont: TTMSFNCGraphicsFont read FTopFont write SetTopFont;
    property BottomFont: TTMSFNCGraphicsFont read FBottomFont write SetBottomFont;
    property TopFill: TTMSFNCGraphicsFill read FTopFill write SetTopFill;
    property BottomFill: TTMSFNCGraphicsFill read FBottomFill write SetBottomFill;
    property TopStroke: TTMSFNCGraphicsStroke read FTopStroke write SetTopStroke;
    property BottomStroke: TTMSFNCGraphicsStroke read FBottomStroke write SetBottomStroke;
    property TopHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTopHorizontalTextAlign write SetTopHorizontalTextAlign default gtaCenter;
    property TopVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTopVerticalTextAlign write SetTopVerticalTextAlign default gtaCenter;
    property BottomHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FBottomHorizontalTextAlign write SetBottomHorizontalTextAlign default gtaCenter;
    property BottomVerticalTextAlign: TTMSFNCGraphicsTextAlign read FBottomVerticalTextAlign write SetBottomVerticalTextAlign default gtaCenter;
    property TopVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FTopVerticalTextMode write SetTopVerticalTextMode default pvtmAuto;
    property BottomVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FBottomVerticalTextMode write SetBottomVerticalTextMode default pvtmAuto;
    property TopNavigationButtonFill: TTMSFNCGraphicsFill read FTopNavigationButtonFill write SetTopNavigationButtonFill;
    property BottomNavigationButtonFill: TTMSFNCGraphicsFill read FBottomNavigationButtonFill write SetBottomNavigationButtonFill;
    property TopNavigationButtonStroke: TTMSFNCGraphicsStroke read FTopNavigationButtonStroke write SetTopNavigationButtonStroke;
    property BottomNavigationButtonStroke: TTMSFNCGraphicsStroke read FBottomNavigationButtonStroke write SetBottomNavigationButtonStroke;
    property TopNavigationButtonHoverFill: TTMSFNCGraphicsFill read FTopNavigationButtonHoverFill write SetTopNavigationButtonHoverFill;
    property BottomNavigationButtonHoverFill: TTMSFNCGraphicsFill read FBottomNavigationButtonHoverFill write SetBottomNavigationButtonHoverFill;
    property TopNavigationButtonHoverStroke: TTMSFNCGraphicsStroke read FTopNavigationButtonHoverStroke write SetTopNavigationButtonHoverStroke;
    property BottomNavigationButtonHoverStroke: TTMSFNCGraphicsStroke read FBottomNavigationButtonHoverStroke write SetBottomNavigationButtonHoverStroke;
    property TopNavigationButtonDownFill: TTMSFNCGraphicsFill read FTopNavigationButtonDownFill write SetTopNavigationButtonDownFill;
    property BottomNavigationButtonDownFill: TTMSFNCGraphicsFill read FBottomNavigationButtonDownFill write SetBottomNavigationButtonDownFill;
    property TopNavigationButtonDownStroke: TTMSFNCGraphicsStroke read FTopNavigationButtonDownStroke write SetTopNavigationButtonDownStroke;
    property BottomNavigationButtonDownStroke: TTMSFNCGraphicsStroke read FBottomNavigationButtonDownStroke write SetBottomNavigationButtonDownStroke;
    property TopLeftNavigationButtonSize: Double read FTopLeftNavigationButtonSize write SetTopLeftNavigationButtonSize;
    property TopRightNavigationButtonSize: Double read FTopRightNavigationButtonSize write SetTopRightNavigationButtonSize;
    property BottomLeftNavigationButtonSize: Double read FBottomLeftNavigationButtonSize write SetBottomLeftNavigationButtonSize;
    property BottomRightNavigationButtonSize: Double read FBottomRightNavigationButtonSize write SetBottomRightNavigationButtonSize;
    property FillEmptySpaces: Boolean read FFillEmptySpaces write SetFillEmptySpaces default True;
  end;

  TTMSFNCPlannerPositionEmptySpace = (ppesTopLeft, ppesTopRight, ppesBottomLeft, ppesBottomRight);
  TTMSFNCPlannerGroupEmptySpace = (pgesTopLeft, pgesTopRight, pgesBottomLeft, pgesBottomRight);
  TTMSFNCPlannerFullDayEmptySpace = (pfdesTopLeft, pfdesTopRight, pfdesBottomLeft, pfdesBottomRight);

  TTMSFNCPlannerPositions = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FCount: Integer;
    FFormat: String;
    FViewStart: Integer;
    procedure SetCount(const Value: Integer);
    procedure SetFormat(const Value: String);
    function GetViewStart: Integer;
    procedure SetViewStart(const Value: Integer);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    procedure Assign(Source: TPersistent); override;
  published
    property Count: Integer read FCount write SetCount default 3;
    property Format: String read FFormat write SetFormat;
    property ViewStart: Integer read GetViewStart write SetViewStart default 0;
  end;

  TTMSFNCPlannerGroupsAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FLayouts: TTMSFNCPlannerGroupsLayouts;
    FBottomSize: Double;
    FTopSize: Double;
    FBottomFill: TTMSFNCGraphicsFill;
    FBottomStroke: TTMSFNCGraphicsStroke;
    FTopFill: TTMSFNCGraphicsFill;
    FTopStroke: TTMSFNCGraphicsStroke;
    FTopFont: TTMSFNCGraphicsFont;
    FBottomFont: TTMSFNCGraphicsFont;
    FBottomHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTopVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTopHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FBottomVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTopVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FBottomVerticalTextMode: TTMSFNCPlannerVerticalTextMode;
    FFillEmptySpaces: Boolean;
    procedure SetLayouts(const Value: TTMSFNCPlannerGroupsLayouts);
    procedure SetBottomSize(const Value: Double);
    procedure SetTopSize(const Value: Double);
    procedure SetBottomFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBottomHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetBottomVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTopHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTopVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTopVerticalTextMode(const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetBottomVerticalTextMode(
      const Value: TTMSFNCPlannerVerticalTextMode);
    procedure SetFillEmptySpaces(const Value: Boolean);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Layouts: TTMSFNCPlannerGroupsLayouts read FLayouts write SetLayouts default [pglTop];
    property TopSize: Double read FTopSize write SetTopSize;
    property BottomSize: Double read FBottomSize write SetBottomSize;
    property TopFill: TTMSFNCGraphicsFill read FTopFill write SetTopFill;
    property BottomFill: TTMSFNCGraphicsFill read FBottomFill write SetBottomFill;
    property TopFont: TTMSFNCGraphicsFont read FTopFont write SetTopFont;
    property BottomFont: TTMSFNCGraphicsFont read FBottomFont write SetBottomFont;
    property TopStroke: TTMSFNCGraphicsStroke read FTopStroke write SetTopStroke;
    property BottomStroke: TTMSFNCGraphicsStroke read FBottomStroke write SetBottomStroke;
    property TopHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTopHorizontalTextAlign write SetTopHorizontalTextAlign default gtaCenter;
    property TopVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTopVerticalTextAlign write SetTopVerticalTextAlign default gtaCenter;
    property BottomHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FBottomHorizontalTextAlign write SetBottomHorizontalTextAlign default gtaCenter;
    property BottomVerticalTextAlign: TTMSFNCGraphicsTextAlign read FBottomVerticalTextAlign write SetBottomVerticalTextAlign default gtaCenter;
    property TopVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FTopVerticalTextMode write SetTopVerticalTextMode default pvtmAuto;
    property BottomVerticalTextMode: TTMSFNCPlannerVerticalTextMode read FBottomVerticalTextMode write SetBottomVerticalTextMode default pvtmAuto;
    property FillEmptySpaces: Boolean read FFillEmptySpaces write SetFillEmptySpaces default True;
  end;

  TTMSFNCPlannerFullDaysAppearance = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FLayouts: TTMSFNCPlannerFullDaysLayouts;
    FBottomSize: Double;
    FTopSize: Double;
    FBottomFill: TTMSFNCGraphicsFill;
    FBottomStroke: TTMSFNCGraphicsStroke;
    FTopFill: TTMSFNCGraphicsFill;
    FTopStroke: TTMSFNCGraphicsStroke;
    FFillEmptySpaces: Boolean;
    FTopFont: TTMSFNCGraphicsFont;
    FBottomFont: TTMSFNCGraphicsFont;
    FAutoSize: Boolean;
    FAutoItemHeight: Double;
    procedure SetLayouts(const Value: TTMSFNCPlannerFullDaysLayouts);
    procedure SetBottomSize(const Value: Double);
    procedure SetTopSize(const Value: Double);
    procedure SetBottomFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFillEmptySpaces(const Value: Boolean);
    procedure SetBottomFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopFont(const Value: TTMSFNCGraphicsFont);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetAutoItemHeight(const Value: Double);
  protected
    procedure Changed(Sender: TObject);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property AutoItemHeight: Double read FAutoItemHeight write SetAutoItemHeight;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default True;
    property Layouts: TTMSFNCPlannerFullDaysLayouts read FLayouts write SetLayouts default [pfdlTop];
    property TopSize: Double read FTopSize write SetTopSize;
    property BottomSize: Double read FBottomSize write SetBottomSize;
    property TopFill: TTMSFNCGraphicsFill read FTopFill write SetTopFill;
    property BottomFill: TTMSFNCGraphicsFill read FBottomFill write SetBottomFill;
    property TopStroke: TTMSFNCGraphicsStroke read FTopStroke write SetTopStroke;
    property BottomStroke: TTMSFNCGraphicsStroke read FBottomStroke write SetBottomStroke;
    property FillEmptySpaces: Boolean read FFillEmptySpaces write SetFillEmptySpaces default True;
    property TopFont: TTMSFNCGraphicsFont read FTopFont write SetTopFont;
    property BottomFont: TTMSFNCGraphicsFont read FBottomFont write SetBottomFont;
  end;

  TTMSFNCPlannerMode = (pmDay, pmDayPeriod, pmHalfDayPeriod, pmMultiDay, pmMultiResDay, pmMultiDayRes, pmMonth, pmMultiMonth, pmCustom);

  TTMSFNCPlannerDisplayUnitType = (pduMilliSecond, pduSecond, pduMinute, pduHour, pduDay);

  TTMSFNCPlannerCurrentTimeMode = (pctmNone, pctmLine, pctmText);

  TTMSFNCPlannerCurrentTimePosition = (pctpUnderItems, pctpOverItems);

  TTMSFNCPlannerTimeLine = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FDisplayUnit: Integer;
    FDisplayUnitSize: Double;
    FDisplayStart: Integer;
    FDisplayEnd: Integer;
    FDisplaySubUnitFormat: String;
    FDisplayUnitType: TTMSFNCPlannerDisplayUnitType;
    FDisplayOffsetType: TTMSFNCPlannerDisplayUnitType;
    FDisplayOffset: Integer;
    FDisplayUnitFormat: String;
    FViewStart: TDateTime;
    FCurrentTimeMode: TTMSFNCPlannerCurrentTimeMode;
    FActiveStart: Integer;
    FActiveEnd: Integer;
    FCurrentTimePosition: TTMSFNCPlannerCurrentTimePosition;
    procedure SetDisplayUnit(const Value: Integer);
    procedure SetDisplayUnitSize(const Value: Double);
    procedure SetDisplayEnd(const Value: Integer);
    procedure SetDisplayStart(const Value: Integer);
    procedure SetDisplaySubUnitFormat(const Value: String);
    procedure SetDisplayUnitType(const Value: TTMSFNCPlannerDisplayUnitType);
    procedure SetDisplayOffset(const Value: Integer);
    procedure SetDisplayOffsetType(
      const Value: TTMSFNCPlannerDisplayUnitType);
    procedure SetDisplayUnitFormat(const Value: String);
    procedure SetCurrentTimeMode(const Value: TTMSFNCPlannerCurrentTimeMode);
    function GetViewStart: TDateTime;
    procedure SetViewStart(const Value: TDateTime);
    procedure SetActiveEnd(const Value: Integer);
    procedure SetActiveStart(const Value: Integer);
    procedure SetCurrentTimePosition(
      const Value: TTMSFNCPlannerCurrentTimePosition);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    procedure Assign(Source: TPersistent); override;
  published
    property DisplayUnitFormat: String read FDisplayUnitFormat write SetDisplayUnitFormat;
    property DisplaySubUnitFormat: String read FDisplaySubUnitFormat write SetDisplaySubUnitFormat;
    property DisplayUnit: Integer read FDisplayUnit write SetDisplayUnit default 30;
    property DisplayUnitType: TTMSFNCPlannerDisplayUnitType read FDisplayUnitType write SetDisplayUnitType default pduMinute;
    property DisplayStart: Integer read FDisplayStart write SetDisplayStart default 0;
    property DisplayEnd: Integer read FDisplayEnd write SetDisplayEnd default 47;
    property ActiveStart: Integer read FActiveStart write SetActiveStart default 16;
    property ActiveEnd: Integer read FActiveEnd write SetActiveEnd default 40;
    property DisplayOffset: Integer read FDisplayOffset write SetDisplayOffset default 0;
    property DisplayOffsetType: TTMSFNCPlannerDisplayUnitType read FDisplayOffsetType write SetDisplayOffsetType default pduMinute;
    property DisplayUnitSize: Double read FDisplayUnitSize write SetDisplayUnitSize;
    property ViewStart: TDateTime read GetViewStart write SetViewStart;
    property CurrentTimeMode: TTMSFNCPlannerCurrentTimeMode read FCurrentTimeMode write SetCurrentTimeMode default pctmLine;
    property CurrentTimePosition: TTMSFNCPlannerCurrentTimePosition read FCurrentTimePosition write SetCurrentTimePosition default pctpUnderItems;
  end;

  TTMSFNCPlannerGetTextMode = (pgtmDrawing, pgtmEditing);
  TTMSFNCPlannerNavigationDirection = (pndPrevious, pndNext);
  TTMSFNCPlannerNavigationButton = (pnbPrevious, pnbNext);
  TTMSFNCPlannerNavigationButtons = set of TTMSFNCPlannerNavigationButton;
  TTMSFNCPlannerNavigationButtonState = (pnbsNormal, pnbsDown, pnbsHover);
  TTMSFNCPlannerItemDeleteMode = (pidmKeyboard, pidmDialog, pidmTouch);

  TTMSFNCPlannerAfterDrawTimeLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ALeft: Boolean; ARect: TRectF) of object;
  TTMSFNCPlannerAfterDrawGridEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCPlannerCustomizeItemRectEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; APosition: Integer; var AX: Double; var AY: Double; var AWidth: Double; var AHeight: Double) of object;
  TTMSFNCPlannerItemChangedEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeDrawPositionEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawPositionEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace) of object;
  TTMSFNCPlannerBeforeDrawGroupEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawGroupEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace) of object;
  TTMSFNCPlannerBeforeDrawFullDayEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawFullDayEmptySpaceEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace) of object;
  TTMSFNCPlannerBeforeDrawItemLink = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AItem, ALinkedItem: TTMSFNCPlannerItem; var ACanDrawLink: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemLink = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AItem, ALinkedItem: TTMSFNCPlannerItem) of object;

  TTMSFNCPlannerBeforeDrawTopNavigationButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerBeforeDrawBottomNavigationButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawTopNavigationButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState) of object;
  TTMSFNCPlannerAfterDrawBottomNavigationButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState) of object;
  TTMSFNCPlannerBeforeDrawCurrentTimeInTimeLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind; AValue: Double; ACurrentTime: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawCurrentTimeInTimeLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind; AValue: Double; ACurrentTime: TDateTime) of object;
  TTMSFNCPlannerBeforeDrawCurrentTimeInGridEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ACurrentTime: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawCurrentTimeInGridEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ACurrentTime: TDateTime) of object;
  TTMSFNCPlannerBeforeDrawCellEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawCellEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawCellVerticalLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawCellVerticalLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawCellHorizontalLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawCellHorizontalLineEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawPositionEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawPositionEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawGroupEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawGroupEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawFullDayEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawFullDayEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawTimeStrokeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ASubUnit: Boolean; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawTimeStrokeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ASubUnit: Boolean; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawTimeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawTimeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerBeforeDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeDrawMoveAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawMoveAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeDrawSizeAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawSizeAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeDrawDeleteAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawDeleteAreaEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeDrawItemTitleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemTitleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String) of object;
  TTMSFNCPlannerGetCustomContentPanelEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var AContentPanel: TTMSFNCPlannerEditingDialogContentPanel) of object;
  TTMSFNCPlannerInitializeCustomContentPanelEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel) of object;
  TTMSFNCPlannerItemToCustomContentPanelEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel) of object;
  TTMSFNCPlannerCustomContentPanelToItemEvent = procedure(Sender: TObject; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel; AItem: TTMSFNCPlannerItem) of object;

  TTMSFNCPlannerGetPositionTextEvent = procedure(Sender: TObject; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AText: String) of object;
  TTMSFNCPlannerBeforeDrawPositionTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawPositionTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String) of object;
  TTMSFNCPlannerGetGroupTextEvent = procedure(Sender: TObject; AGroup: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AText: String) of object;
  TTMSFNCPlannerBeforeDrawGroupTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawGroupTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String) of object;
  TTMSFNCPlannerGetTimeTextEvent = procedure(Sender: TObject; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; var AText: String) of object;
  TTMSFNCPlannerBeforeDrawTimeTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawTimeTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; AText: String) of object;
  TTMSFNCPlannerItemRightClickEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerItemPopupMenuPrepareEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; APopupMenu: TPopupMenu) of object;
  TTMSFNCPlannerItemAnchorClickEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AAnchor: String) of object;
  TTMSFNCPlannerGetItemHintEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var AHint: string) of object;
  TTMSFNCPlannerGetItemTextEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var AText: String) of object;
  TTMSFNCPlannerBeforeDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String) of object;
  TTMSFNCPlannerGetItemTitleTextEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var ATitle: String) of object;
  TTMSFNCPlannerBeforeDrawItemTitleTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemTitleTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String) of object;
  TTMSFNCPlannerColumnAnchorClickEvent = procedure(Sender: TObject; AIndex: Integer; AAnchor: String) of object;
  TTMSFNCPlannerColumnClickEvent = procedure(Sender: TObject; AIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerColumnDblClickEvent = procedure(Sender: TObject; AIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind) of object;
  TTMSFNCPlannerGetColumnHintEvent = procedure(Sender: TObject; AIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string) of object;

  TTMSFNCPlannerIsItemDeletableEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var ADeletable: Boolean) of object;
  TTMSFNCPlannerGetInplaceEditorEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var AInplaceEditorClass: TTMSFNCPlannerInplaceEditorClass) of object;
  TTMSFNCPlannerIsDateTimeDisabledEvent = procedure(Sender: TObject; ADateTime: TDateTime; APosition: Integer; var ADisabled: Boolean) of object;
  TTMSFNCPlannerIsDateTimeInActiveEvent = procedure(Sender: TObject; ADateTime: TDateTime; APosition: Integer; var AInActive: Boolean) of object;
  TTMSFNCPlannerIsDateTimeSubEvent = procedure(Sender: TObject; ADateTime: TDateTime; var AIsSub: Boolean) of object;
  TTMSFNCPlannerHasDateTimeSubEvent = procedure(Sender: TObject; var AHasSub: Boolean) of object;
  TTMSFNCPlannerSelectTimeEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer) of object;
  TTMSFNCPlannerSelectCellEvent = procedure(Sender: TObject; AStartCell, AEndCell: TTMSFNCPlannerCell) of object;
  TTMSFNCPlannerBeforeInsertItemEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; var ATitle: String; var AText: String; var ACanInsert: Boolean) of object;
  TTMSFNCPlannerAfterInsertItemEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeUpdateItemEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ATitle: String; var AText: String; var ACanUpdate: Boolean) of object;
  TTMSFNCPlannerAfterUpdateItemEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeOpenInsertDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; var ACanOpen: Boolean) of object;
  TTMSFNCPlannerAfterOpenInsertDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer) of object;
  TTMSFNCPlannerBeforeOpenUpdateDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ACanOpen: Boolean) of object;
  TTMSFNCPlannerAfterOpenUpdateDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerBeforeOpenInplaceEditorEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ACanOpen: Boolean) of object;
  TTMSFNCPlannerAfterOpenInplaceEditorEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; AInplaceEditor: TTMSFNCPlannerInplaceEditor; AInplaceEditorRect: TRectF) of object;
  TTMSFNCPlannerCloseInplaceEditorEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean) of object;
  TTMSFNCPlannerCloseInsertDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean) of object;
  TTMSFNCPlannerCloseUpdateDialogEvent = procedure(Sender: TObject; AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean) of object;
  TTMSFNCPlannerItemCustomDrawMarkEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AMarkType: TTMSFNCPlannerItemMarkType; AItem: TTMSFNCPlannerItem) of object;

  TTMSFNCPlannerBeforeDeleteItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerItemDeleteMode; var ACanDelete: Boolean) of object;
  TTMSFNCPlannerBeforeSelectItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var ACanSelect: Boolean) of object;
  TTMSFNCPlannerAfterSelectItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerItemClickEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerItemDblClickEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem) of object;
  TTMSFNCPlannerAfterDeleteItemEvent = procedure(Sender: TObject; AItemIndex: Integer; ADBKey: String; AMode: TTMSFNCPlannerItemDeleteMode) of object;
  TTMSFNCPlannerBeforeMoveItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanMove: Boolean) of object;
  TTMSFNCPlannerBeforeSizeItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanSize: Boolean) of object;
  TTMSFNCPlannerAfterMoveItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer) of object;
  TTMSFNCPlannerAfterSizeItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer) of object;
  TTMSFNCPlannerMoveItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer) of object;
  TTMSFNCPlannerSizeItemEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer) of object;
  TTMSFNCPlannerScrollEvent = procedure(Sender: TObject; APosition: Single) of object;
  TTMSFNCPlannerBeforeDrawItemHelperEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPlannerBeforeDrawItemHelperTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; AText: String; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterDrawItemHelperEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime) of object;
  TTMSFNCPlannerAfterDrawItemHelperTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; AText: String) of object;
  TTMSFNCPlannerGetItemHelperTextEvent = procedure(Sender: TObject; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; var AText: String) of object;

  TTMSFNCPlannerBeforeNavigateToDateTimeEvent = procedure(Sender: TObject; ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime: TDateTime; var ANewDateTime: TDateTime; var AAllow: Boolean) of object;
  TTMSFNCPlannerAfterNavigateToDateTimeEvent = procedure(Sender: TObject; ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime: TDateTime; ANewDateTime: TDateTime) of object;

  TTMSFNCPlannerInActiveDay = (padMonday, padTuesday, padWednesday, padThursday, padFriday, padSaturday, padSunday);
  TTMSFNCPlannerInActiveDays = set of TTMSFNCPlannerInActiveDay;

  TTMSFNCPlannerModeSettings = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FStartTime: TDateTime;
    FEndTime: TDateTime;
    FInActiveDays: TTMSFNCPlannerInActiveDays;
    FOverlappableItems: Boolean;
    procedure SetStartTime(const Value: TDateTime);
    procedure SetEndTime(const Value: TDateTime);
    procedure SetInActiveDays(const Value: TTMSFNCPlannerInActiveDays);
    procedure SetOverlappableItems(const Value: Boolean);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    procedure Assign(Source: TPersistent); override;
  published
    property StartTime: TDateTime read FStartTime write SetStartTime;
    property EndTime: TDateTime read FEndTime write SetEndTime;
    property InActiveDays: TTMSFNCPlannerInActiveDays read FInActiveDays write SetInActiveDays default [padSaturday, padSunday];
    property OverlappableItems: Boolean read FOverlappableItems write SetOverlappableItems default True;
  end;

  TTMSFNCPlannerMouseInsertMode = (pmimNone, pmimAfterSelection, pmimDialogAfterSelection);
  TTMSFNCPlannerKeyboardInsertMode = (pkimNone, pkimSelection, pkimSelectionDialog);
  TTMSFNCPlannerMouseEditMode = (pmemDoubleClick, pmemSingleClick, pmemSingleClickOnSelectedItem);
  TTMSFNCPlannerUpdateMode = (pumNone, pumInplace, pumDialog);
  TTMSFNCPlannerInplaceEditorMode = (piemText, piemTitle, piemItem);
  TTMSFNCPlannerSizeMode = (psmDesktop, psmMobile, psmAuto);
  TTMSFNCPlannerMoveMode = (pmmDesktop, pmmMobile, pmmAuto);
  TTMSFNCPlannerDeleteMode = (pdmDesktop, pdmMobile, pdmAuto);
  TTMSFNCPlannerMouseInteractionMode = (pmmMove, pmmSizeDown, pmmSizeUp);
  TTMSFNCPlannerKeyboardMode = (pkmDefault, pkmGrid);

  TTMSFNCPlannerInteraction = class(TPersistent)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FMouseInsertMode: TTMSFNCPlannerMouseInsertMode;
    FUpdateMode: TTMSFNCPlannerUpdateMode;
    FMouseEditMode: TTMSFNCPlannerMouseEditMode;
    FKeyboardDelete: Boolean;
    FKeyboardInsertMode: TTMSFNCPlannerKeyboardInsertMode;
    FMultiSelect: Boolean;
    FTouchScrolling: Boolean;
    FInplaceEditorMode: TTMSFNCPlannerInplaceEditorMode;
    FKeyboardEdit: Boolean;
    FSwipeToNextDateTime: Boolean;
    FSwipeToPreviousDateTime: Boolean;
    FKeepSelection: Boolean;
    FTopNavigationButtons: TTMSFNCPlannerNavigationButtons;
    FBottomNavigationButtons: TTMSFNCPlannerNavigationButtons;
    FSizeMode: TTMSFNCPlannerSizeMode;
    FMoveMode: TTMSFNCPlannerMoveMode;
    FShowSelection: Boolean;
    FReadOnly: Boolean;
    FDeleteMode: TTMSFNCPlannerDeleteMode;
    FAutoDeleteLinkedItems: Boolean;
    FAutoSelectLinkedItems: Boolean;
    FAutoOpenURL: Boolean;
    FKeyboardMode: TTMSFNCPlannerKeyboardMode;
    procedure SetMouseInsertMode(const Value: TTMSFNCPlannerMouseInsertMode);
    procedure SetUpdateMode(const Value: TTMSFNCPlannerUpdateMode);
    procedure SetMouseEditMode(const Value: TTMSFNCPlannerMouseEditMode);
    procedure SetKeyboardDelete(const Value: Boolean);
    procedure SetKeyboardInsertMode(const Value: TTMSFNCPlannerKeyboardInsertMode);
    procedure SetMultiSelect(const Value: Boolean);
    procedure SetTouchScrolling(const Value: Boolean);
    procedure SetInplaceEditorMode(const Value: TTMSFNCPlannerInplaceEditorMode);
    procedure SetKeyboardEdit(const Value: Boolean);
    procedure SetSwipeToNextDateTime(const Value: Boolean);
    procedure SetSwipeToPreviousDateTime(const Value: Boolean);
    procedure SetKeepSelection(const Value: Boolean);
    procedure SetSizeMode(const Value: TTMSFNCPlannerSizeMode);
    procedure SetTopNavigationButtons(const Value: TTMSFNCPlannerNavigationButtons);
    procedure SetBottomNavigationButtons(const Value: TTMSFNCPlannerNavigationButtons);
    procedure SetMoveMode(const Value: TTMSFNCPlannerMoveMode);
    procedure SetShowSelection(const Value: Boolean);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetDeleteMode(const Value: TTMSFNCPlannerDeleteMode);
    procedure SetKeyboardMode(const Value: TTMSFNCPlannerKeyboardMode);
  public
    constructor Create(APlanner: TTMSFNCCustomPlanner);
    procedure Assign(Source: TPersistent); override;
  published
    property AutoOpenURL: Boolean read FAutoOpenURL write FAutoOpenURL default True;
    property MouseInsertMode: TTMSFNCPlannerMouseInsertMode read FMouseInsertMode write SetMouseInsertMode default pmimNone;
    property MouseEditMode: TTMSFNCPlannerMouseEditMode read FMouseEditMode write SetMouseEditMode default pmemSingleClickOnSelectedItem;
    property KeyboardDelete: Boolean read FKeyboardDelete write SetKeyboardDelete default False;
    property KeyboardEdit: Boolean read FKeyboardEdit write SetKeyboardEdit default True;
    property KeyboardMode: TTMSFNCPlannerKeyboardMode read FKeyboardMode write SetKeyboardMode default pkmDefault;
    property KeyboardInsertMode: TTMSFNCPlannerKeyboardInsertMode read FKeyboardInsertMode write SetKeyboardInsertMode default pkimNone;
    property UpdateMode: TTMSFNCPlannerUpdateMode read FUpdateMode write SetUpdateMode default pumInplace;
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect default false;
    property TouchScrolling: Boolean read FTouchScrolling write SetTouchScrolling default True;
    property InplaceEditorMode: TTMSFNCPlannerInplaceEditorMode read FInplaceEditorMode write SetInplaceEditorMode default piemText;
    property SwipeToNextDateTime: Boolean read FSwipeToNextDateTime write SetSwipeToNextDateTime default True;
    property SwipeToPreviousDateTime: Boolean read FSwipeToPreviousDateTime write SetSwipeToPreviousDateTime default True;
    property KeepSelection: Boolean read FKeepSelection write SetKeepSelection default True;
    property ShowSelection: Boolean read FShowSelection write SetShowSelection default True;
    property SizeMode: TTMSFNCPlannerSizeMode read FSizeMode write SetSizeMode default psmAuto;
    property DeleteMode: TTMSFNCPlannerDeleteMode read FDeleteMode write SetDeleteMode default pdmAuto;
    property MoveMode: TTMSFNCPlannerMoveMode read FMoveMode write SetMoveMode default pmmAuto;
    property TopNavigationButtons: TTMSFNCPlannerNavigationButtons read FTopNavigationButtons write SetTopNavigationButtons default [];
    property BottomNavigationButtons: TTMSFNCPlannerNavigationButtons read FBottomNavigationButtons write SetBottomNavigationButtons default [];
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default False;
    property AutoSelectLinkedItems: Boolean read FAutoSelectLinkedItems write FAutoSelectLinkedItems default False;
    property AutoDeleteLinkedItems: Boolean read FAutoDeleteLinkedItems write FAutoDeleteLinkedITems default False;
  end;

  TTMSFNCPlannerSizeHandlerPanelKind = (pshpkStartTime, pshpkEndTime);

  TTMSFNCPlannerDeleteHandlerPanel = class(TTMSFNCCustomControl)
  private
    FPlanner: TTMSFNCCustomPlanner;
  protected
    {$IFDEF FMXLIB}
    function GetClipRect: TRectF; override;
    {$ENDIF}
    procedure RegisterRuntimeClasses; override;
    procedure Click; override;
  public
    constructor Create(AOwner: TComponent); override;
    property Planner: TTMSFNCCustomPlanner read FPlanner write FPlanner;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  end;

  TTMSFNCPlannerSizeHandlerPanel = class(TTMSFNCCustomControl)
  private
    FDownPoint: TPointF;
    FMouseDown: Boolean;
    FPlanner: TTMSFNCCustomPlanner;
    FKind: TTMSFNCPlannerSizeHandlerPanelKind;
  protected
    {$IFDEF FMXLIB}
    function GetClipRect: TRectF; override;
    {$ENDIF}
    procedure RegisterRuntimeClasses; override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
  public
    constructor Create(AOwner: TComponent); override;
    property Planner: TTMSFNCCustomPlanner read FPlanner write FPlanner;
    property Kind: TTMSFNCPlannerSizeHandlerPanelKind read FKind write FKind default pshpkStartTime;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  end;

  TTMSFNCPlannerDeleteHandler = record
    Background: TTMSFNCPlannerDeleteHandlerPanel;
  end;

  TTMSFNCPlannerSizeHandler = record
    Background: TTMSFNCPlannerSizeHandlerPanel;
  end;

  TTMSFNCPlannerHintPopup = record
    Panel: TTMSFNCCustomControl;
    TextLabel: TTMSFNCHTMLText;
  end;

  TTMSFNCPlannerEditingDialog = record
    Background: TTMSFNCImage;
    FullDayCheckBox: TCheckBox;
    ResourcesComboBox: TComboBox;
    Panel: TTMSFNCCustomControl;
    StartTimeEdit: TTMSFNCPlannerTimeEdit;
    StartDateEdit: TTMSFNCPlannerDateEdit;
    EndTimeEdit: TTMSFNCPlannerTimeEdit;
    EndDateEdit: TTMSFNCPlannerDateEdit;
    TitleEdit: TEdit;
    TextMemo: TMemo;
    TitleLabel: TLabel;
    TextLabel: TLabel;
    StartTimeLabel: TLabel;
    EndTimeLabel: TLabel;
    BottomPanel: TTMSFNCCustomControl;
    ButtonOK: TLabel;
    ButtonRemove: TLabel;
    ButtonCancel: TLabel;
    ResourceLabel: TLabel;
    ContentPanel: TTMSFNCPlannerEditingDialogContentPanel;
    CustomContentPanel: Boolean;
  end;

  TTMSFNCPlannerSceneDrawingScale = record
    SceneScale: Double;
    DrawingScale: TPointF;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerDateTimes = class(TList)
  private
    function GetItem(Index: Integer): TDateTime;
    procedure SetItem(Index: Integer; const Value: TDateTime);
  public
    property Items[Index: Integer]: TDateTime read GetItem write SetItem; default;
  end;
  TTMSFNCPlannerSelectedItems = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerItem);
  public
    property Items[Index: Integer]: TTMSFNCPlannerItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerDateTimes = class(TList<TDateTime>);
  TTMSFNCPlannerSelectedItems = class(TList<TTMSFNCPlannerItem>);
  {$ENDIF}

  TTMSFNCPlannerInteractionDirection = (idNone, idLeft, idRight, idUp, idDown, idLeftUp, idRightDown);

  TTMSFNCPlannerLinkedItemArray = array of TTMSFNCPlannerItem;

  TTMSFNCPlannerItemArray = array of integer;

  TTMSFNCPlannerArrowKind = (pakLeft, pakRight, pakUp, pakDown);

  TTMSFNCPlannerCustomToolBarPopup = class(TTMSFNCCustomToolBarPopup)
  private
    FPlanner: TTMSFNCCustomPlanner;
    FPlannerItem: TTMSFNCPlannerItem;
  protected
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    property Planner: TTMSFNCCustomPlanner read FPlanner write FPlanner;
    property PlannerItem: TTMSFNCPlannerItem read FPlannerItem write FPlannerItem;
  public
    constructor Create(AOwner: TComponent); override;
    property PlacementControl;
    property PlacementRectangle;
    property Placement;
  end;

  TTMSFNCCustomPlanner = class(TTMSFNCPlannerData, ITMSFNCBitmapContainer, ITMSFNCAppearanceGlobalFont)
  private
    FOnCustomizeItemRect: TTMSFNCPlannerCustomizeItemRectEvent;
    FToolBarPopup: TTMSFNCPlannerCustomToolBarPopup;
    FCustomItemEditorForm: TTMSFNCPlannerCustomItemEditorFormClass;
    FClosing: Boolean;
    FInplaceEditorClosed: Boolean;
    FAdapter: TTMSFNCPlannerAdapter;
    FCurrentPanel: TTMSFNCPlannerEditingDialogContentPanel;
    FCallItemIndex: Integer;
    FCallAfterMoveMouseEvent, FCallAfterMoveKeyboardEvent: Boolean;
    FCallAfterSizeMouseEvent, FCallAfterSizeKeyboardEvent: Boolean;
    FFindItemIndex: Integer;
    FNavigationButtonDown: Boolean;
    FTopLeftNavigationButtonState, FTopRightNavigationButtonState,
    FBottomLeftNavigationButtonState, FBottomRightNavigationButtonState: TTMSFNCPlannerNavigationButtonState;
    FDialogStartDate: TDate;
    FDialogEndDate: TDate;
    FDialogStartTime: TTime;
    FDialogEndTime: TTime;
    FDblClicked, FDblClickedMouseMove: Boolean;
    FCloseWithDialogKey: Boolean;
    FInplaceEditorActive, FEditorDialogActive: Boolean;
    FInplaceEditorClass: TTMSFNCPlannerInplaceEditorClass;
    FNeedsInitialization: Boolean;
    FSelectedItems: TTMSFNCPlannerSelectedItems;
    FDoItemAnchor: String;
    FDoItemSizeUp, FDoItemSizeDown, FDoItemMove, FDoItemDelete: Boolean;
    FInplaceEditor: TTMSFNCPlannerInplaceEditor;
    FDrawItemHelpers: Boolean;
    FCustomDatesList: TTMSFNCPlannerDateTimes;
    {$IFNDEF LCLWEBLIB}
    FCompareCustomDates: IComparer<TDateTime>;
    {$ENDIF}
    FNeedsConflictsUpdate: Boolean;
    FInsertResource: Integer;
    FUpdateItem: Integer;
    FEditingDialogCreated, FHintPopupCreated, FDeleteHandlerCreated,
    FStartTimeSizeHandlerCreated, FEndTimeSizeHandlerCreated: Boolean;
    FEditingBackground: TTMSFNCImage;
    FResourcesComboBox: TComboBox;
    FEditingDialog, FBottomPanel, FContentPanel: TTMSFNCControl;
    FStartTimeLabel, FResourceLabel: TLabel;
    FStartTimeEdit: TTMSFNCPlannerTimeEdit;
    FStartDateEdit: TTMSFNCPlannerDateEdit;
    FEndTimeLabel: TLabel;
    FEndTimeEdit: TTMSFNCPlannerTimeEdit;
    FEndDateEdit: TTMSFNCPlannerDateEdit;
    FTitleLabel: TLabel;
    FTitleEdit: TEdit;
    FTextLabel: TLabel;
    FFullDayCheckBox: TCheckBox;
    FTextMemo: TMemo;
    FDeletePanel: TTMSFNCPlannerDeleteHandlerPanel;
    FStartTimeSizePanel, FEndTimeSizePanel: TTMSFNCPlannerSizeHandlerPanel;
    FHintPanel: TTMSFNCControl;
    FHintLabel: TTMSFNCHTMLText;
    FButtonCancel, FButtonOK, FButtonRemove: TLabel;
    FDisplayStartTime, FDisplayEndTime, FActiveStartTime, FActiveEndTime: TDateTime;
    FDisplayStart, FDisplayEnd, FActiveStart, FActiveEnd: Integer;
    FPrevSelection: TTMSFNCPlannerSelection;
    FScrolling: Boolean;
    FSelection: TTMSFNCPlannerSelection;
    FDownOnPositions, FMoveOnPositions: Boolean;
    FDownCell, FSizeCell, FRangeCell: TTMSFNCPlannerCell;
    FDownItem, FHoverItem, FActiveItem: TTMSFNCPlannerItem;
    FPositionHover: TTMSFNCPlannerCacheItem;
    FGroupHover: TTMSFNCPlannerCacheItem;
    FFullDayHover: TTMSFNCPlannerCacheItem;
    FItemPopup: TPopupMenu;
    FOnAfterDrawGrid: TTMSFNCPlannerAfterDrawGridEvent;
    FOnAfterDrawTimeLine: TTMSFNCPlannerAfterDrawTimeLineEvent;
    FDownCacheItemIdx: Integer;
    FDoubleSelection, FRangeSelection: Boolean;
    FDownTime: Integer;
    FMouseUp, FAnimateVerticalPos, FAnimateHorizontalPos: Boolean;
    FAnimating: Boolean;
    FSpX, FSpY: Double;
    FMovePositionPrevious, FMovePositionNext: Boolean;
    FScrollX, FScrollY, FDownX, FDownY, FMouseX, FMouseY: Double;
    FScrollVTo, FScrollHTo: Double;
    FTimeStart, FTimeStop: Double;
    FAnimateTimer, FDownTimer: TTimer;
    FGridCache: TTMSFNCPlannerGridCache;
    FItemCache: TTMSFNCPlannerItemCache;
    FFullDaysItemTopCache: TTMSFNCPlannerFullDaysItemTopCache;
    FFullDaysItemBottomCache: TTMSFNCPlannerFullDaysItemBottomCache;
    FPositionsTopCache: TTMSFNCPlannerPositionsTopCache;
    FPositionsBottomCache: TTMSFNCPlannerPositionsBottomCache;
    FConflicts: TTMSFNCPlannerConflicts;
    FDisplayGroups: TTMSFNCPlannerDisplayGroups;
    FGroupsTopCache: TTMSFNCPlannerGroupsTopCache;
    FGroupsBottomCache: TTMSFNCPlannerGroupsBottomCache;
    FDisplayFullDays: TTMSFNCPlannerDisplayFullDays;
    FFullDaysTopCache: TTMSFNCPlannerFullDaysTopCache;
    FFullDaysBottomCache: TTMSFNCPlannerFullDaysBottomCache;
    FTimeLineLeftCache: TTMSFNCPlannerTimeLineLeftCache;
    FTimeLineRightCache: TTMSFNCPlannerTimeLineRightCache;
    FGridDisplay: TTMSFNCPlannerGridDisplayList;
    FItemDisplay: TTMSFNCPlannerItemDisplayList;
    FFullDaysItemTopDisplay: TTMSFNCPlannerFullDaysItemTopDisplayList;
    FFullDaysItemBottomDisplay: TTMSFNCPlannerFullDaysItemBottomDisplayList;
    FPositionsTopDisplay: TTMSFNCPlannerPositionsTopDisplayList;
    FPositionsBottomDisplay: TTMSFNCPlannerPositionsBottomDisplayList;
    FGroupsTopDisplay: TTMSFNCPlannerGroupsTopDisplayList;
    FGroupsBottomDisplay: TTMSFNCPlannerGroupsBottomDisplayList;
    FFullDaysTopDisplay: TTMSFNCPlannerFullDaysTopDisplayList;
    FFullDaysBottomDisplay: TTMSFNCPlannerFullDaysBottomDisplayList;
    FTimeLineLeftDisplay: TTMSFNCPlannerTimeLineLeftDisplayList;
    FTimeLineRightDisplay: TTMSFNCPlannerTimeLineRightDisplayList;
    FGridCaching: Boolean;
    FItemsAppearance: TTMSFNCPlannerItemsAppearance;
    FPositionsCaching: Boolean;
    FGroupsCaching: Boolean;
    FFullDaysCaching: Boolean;
    FTimeLineCaching: Boolean;
    FTimeLine: TTMSFNCPlannerTimeLine;
    FGroupsAppearance: TTMSFNCPlannerGroupsAppearance;
    FFullDaysAppearance: TTMSFNCPlannerFullDaysAppearance;
    FPositionsAppearance: TTMSFNCPlannerPositionsAppearance;
    FTimeLineAppearance: TTMSFNCPlannerTimeLineAppearance;
    FOnAfterDrawGroup: TTMSFNCPlannerAfterDrawGroupEvent;
    FOnAfterDrawFullDay: TTMSFNCPlannerAfterDrawFullDayEvent;
    FOnBeforeDrawPosition: TTMSFNCPlannerBeforeDrawPositionEvent;
    FOnBeforeDrawItem: TTMSFNCPlannerBeforeDrawItemEvent;
    FOnBeforeDrawTime: TTMSFNCPlannerBeforeDrawTimeEvent;
    FOnAfterDrawPosition: TTMSFNCPlannerAfterDrawPositionEvent;
    FOnBeforeDrawGroup: TTMSFNCPlannerBeforeDrawGroupEvent;
    FOnBeforeDrawFullDay: TTMSFNCPlannerBeforeDrawFullDayEvent;
    FOnAfterDrawItem: TTMSFNCPlannerAfterDrawItemEvent;
    FOnAfterDrawTime: TTMSFNCPlannerAfterDrawTimeEvent;
    FOnAfterDrawCell: TTMSFNCPlannerAfterDrawCellEvent;
    FOnBeforeDrawCell: TTMSFNCPlannerBeforeDrawCellEvent;
    FGridCellAppearance: TTMSFNCPlannerGridCellAppearance;
    FOnBeforeDrawItemText: TTMSFNCPlannerBeforeDrawItemTextEvent;
    FOnBeforeDrawTimeText: TTMSFNCPlannerBeforeDrawTimeTextEvent;
    FOnAfterDrawPositionText: TTMSFNCPlannerAfterDrawPositionTextEvent;
    FOnBeforeDrawGroupText: TTMSFNCPlannerBeforeDrawGroupTextEvent;
    FOnAfterDrawItemText: TTMSFNCPlannerAfterDrawItemTextEvent;
    FOnAfterDrawTimeText: TTMSFNCPlannerAfterDrawTimeTextEvent;
    FOnAfterDrawGroupText: TTMSFNCPlannerAfterDrawGroupTextEvent;
    FOnBeforeDrawPositionText: TTMSFNCPlannerBeforeDrawPositionTextEvent;
    FSelectionAppearance: TTMSFNCPlannerSelectionAppearance;
    FMode: TTMSFNCPlannerMode;
    FModeSettings: TTMSFNCPlannerModeSettings;
    FInteraction: TTMSFNCPlannerInteraction;
    FOnSelectTime: TTMSFNCPlannerSelectTimeEvent;
    FOnBeforeInsertItem: TTMSFNCPlannerBeforeInsertItemEvent;
    FOnAfterInsertItem: TTMSFNCPlannerAfterInsertItemEvent;
    FOnAfterUpdateItem: TTMSFNCPlannerAfterUpdateItemEvent;
    FOnBeforeUpdateItem: TTMSFNCPlannerBeforeUpdateItemEvent;
    FOnAfterDrawItemTitleText: TTMSFNCPlannerAfterDrawItemTitleTextEvent;
    FOnBeforeDrawItemTitleText: TTMSFNCPlannerBeforeDrawItemTitleTextEvent;
    FOnAfterDrawItemTitle: TTMSFNCPlannerAfterDrawItemTitleEvent;
    FOnBeforeDrawItemTitle: TTMSFNCPlannerBeforeDrawItemTitleEvent;
    FOnBeforeSelectItem: TTMSFNCPlannerBeforeSelectItemEvent;
    FOnAfterSelectItem: TTMSFNCPlannerAfterSelectItemEvent;
    FOnItemClick: TTMSFNCPlannerItemClickEvent;
    FOnItemDblClick: TTMSFNCPlannerItemDblClickEvent;
    FDefaultItem: TTMSFNCPlannerItem;
    FOnBeforeOpenUpdateDialog: TTMSFNCPlannerBeforeOpenUpdateDialogEvent;
    FOnBeforeOpenInsertDialog: TTMSFNCPlannerBeforeOpenInsertDialogEvent;
    FOnAfterOpenUpdateDialog: TTMSFNCPlannerAfterOpenUpdateDialogEvent;
    FOnAfterOpenInsertDialog: TTMSFNCPlannerAfterOpenInsertDialogEvent;
    FOnBeforeDrawCellVerticalLine: TTMSFNCPlannerBeforeDrawCellVerticalLineEvent;
    FOnBeforeDrawCellHorizontalLine: TTMSFNCPlannerBeforeDrawCellHorizontalLineEvent;
    FOnAfterDrawCellVerticalLine: TTMSFNCPlannerAfterDrawCellVerticalLineEvent;
    FOnAfterDrawCellHorizontalLine: TTMSFNCPlannerAfterDrawCellHorizontalLineEvent;
    FPositions: TTMSFNCPlannerPositions;
    FOnIsDateTimeDisabled: TTMSFNCPlannerIsDateTimeDisabledEvent;
    FCustomDateTimes: TTMSFNCPlannerDateTimes;
    FOnAfterMoveItem: TTMSFNCPlannerAfterMoveItemEvent;
    FOnAfterSizeItem: TTMSFNCPlannerAfterSizeItemEvent;
    FOnBeforeMoveItem: TTMSFNCPlannerBeforeMoveItemEvent;
    FOnBeforeSizeItem: TTMSFNCPlannerBeforeSizeItemEvent;
    FOnSelectingTime: TTMSFNCPlannerSelectTimeEvent;
    FOnSelectCell: TTMSFNCPlannerSelectCellEvent;
    FOnSelectingCell: TTMSFNCPlannerSelectCellEvent;
    FOnBeforeOpenInplaceEditor: TTMSFNCPlannerBeforeOpenInplaceEditorEvent;
    FOnAfterOpenInplaceEditor: TTMSFNCPlannerAfterOpenInplaceEditorEvent;
    FOnGetInplaceEditor: TTMSFNCPlannerGetInplaceEditorEvent;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FOnAfterDrawMoveArea: TTMSFNCPlannerAfterDrawMoveAreaEvent;
    FOnAfterDrawSizeArea: TTMSFNCPlannerAfterDrawSizeAreaEvent;
    FOnBeforeDrawMoveArea: TTMSFNCPlannerBeforeDrawMoveAreaEvent;
    FOnBeforeDrawSizeArea: TTMSFNCPlannerBeforeDrawSizeAreaEvent;
    FOnGetPositionHint: TTMSFNCPlannerGetColumnHintEvent;
    FOnGetGroupHint: TTMSFNCPlannerGetColumnHintEvent;
    FOnGetFullDayHint: TTMSFNCPlannerGetColumnHintEvent;
    FOnGetItemHint: TTMSFNCPlannerGetItemHintEvent;
    FOnGetItemText: TTMSFNCPlannerGetItemTextEvent;
    FOnGetTimeText: TTMSFNCPlannerGetTimeTextEvent;
    FOnGetPositionText: TTMSFNCPlannerGetPositionTextEvent;
    FOnGetGroupText: TTMSFNCPlannerGetGroupTextEvent;
    FOnGetItemTitleText: TTMSFNCPlannerGetItemTitleTextEvent;
    FOnItemAnchorClick: TTMSFNCPlannerItemAnchorClickEvent;
    FOnItemRightClick: TTMSFNCPlannerItemRightClickEvent;
    FOnItemPopupMenuPrepare: TTMSFNCPlannerItemPopupMenuPrepareEvent;
    FOnItemTitleAnchorClick: TTMSFNCPlannerItemAnchorClickEvent;
    FOnGroupAnchorClick: TTMSFNCPlannerColumnAnchorClickEvent;
    FOnPositionAnchorClick: TTMSFNCPlannerColumnAnchorClickEvent;
    FOnGroupClick: TTMSFNCPlannerColumnClickEvent;
    FOnFullDayClick: TTMSFNCPlannerColumnClickEvent;
    FOnPositionClick: TTMSFNCPlannerColumnClickEvent;
    FOnGroupDblClick: TTMSFNCPlannerColumnDblClickEvent;
    FOnFullDayDblClick: TTMSFNCPlannerColumnDblClickEvent;
    FOnPositionDblClick: TTMSFNCPlannerColumnDblClickEvent;
    FOnBeforeDrawCurrentTimeInGrid: TTMSFNCPlannerBeforeDrawCurrentTimeInGridEvent;
    FOnAfterDrawCurrentTimeInTimeLine: TTMSFNCPlannerAfterDrawCurrentTimeInTimeLineEvent;
    FOnAfterDrawCurrentTimeInGrid: TTMSFNCPlannerAfterDrawCurrentTimeInGridEvent;
    FOnBeforeDrawCurrentTimeInTimeLine: TTMSFNCPlannerBeforeDrawCurrentTimeInTimeLineEvent;
    FOnCloseUpdateDialog: TTMSFNCPlannerCloseUpdateDialogEvent;
    FOnCloseInsertDialog: TTMSFNCPlannerCloseInsertDialogEvent;
    FOnCloseInplaceEditor: TTMSFNCPlannerCloseInplaceEditorEvent;
    FOnVScroll: TTMSFNCPlannerScrollEvent;
    FOnHScroll: TTMSFNCPlannerScrollEvent;
    FOnIsDateTimeInActive: TTMSFNCPlannerIsDateTimeInActiveEvent;
    FOnAfterDrawItemHelperText: TTMSFNCPlannerAfterDrawItemHelperTextEvent;
    FOnAfterDrawItemHelper: TTMSFNCPlannerAfterDrawItemHelperEvent;
    FOnBeforeDrawItemHelperText: TTMSFNCPlannerBeforeDrawItemHelperTextEvent;
    FOnBeforeDrawItemHelper: TTMSFNCPlannerBeforeDrawItemHelperEvent;
    FOnGetItemHelperText: TTMSFNCPlannerGetItemHelperTextEvent;
    FOnBeforeDrawTimeStroke: TTMSFNCPlannerBeforeDrawTimeStrokeEvent;
    FOnAfterDrawTimeStroke: TTMSFNCPlannerAfterDrawTimeStrokeEvent;
    FOnAfterNavigateToDateTime: TTMSFNCPlannerAfterNavigateToDateTimeEvent;
    FOnBeforeNavigateToDateTime: TTMSFNCPlannerBeforeNavigateToDateTimeEvent;
    FOnBeforeDrawTopNavigationButton: TTMSFNCPlannerBeforeDrawTopNavigationButtonEvent;
    FOnAfterDrawBottomNavigationButton: TTMSFNCPlannerAfterDrawBottomNavigationButtonEvent;
    FOnAfterDrawTopNavigationButton: TTMSFNCPlannerAfterDrawTopNavigationButtonEvent;
    FOnBeforeDrawBottomNavigationButton: TTMSFNCPlannerBeforeDrawBottomNavigationButtonEvent;
    FOnBeforeDrawPositionEmptySpace: TTMSFNCPlannerBeforeDrawPositionEmptySpaceEvent;
    FOnAfterDrawPositionEmptySpace: TTMSFNCPlannerAfterDrawPositionEmptySpaceEvent;
    FOnBeforeDrawGroupEmptySpace: TTMSFNCPlannerBeforeDrawGroupEmptySpaceEvent;
    FOnAfterDrawGroupEmptySpace: TTMSFNCPlannerAfterDrawGroupEmptySpaceEvent;
    FOnBeforeDrawFullDayEmptySpace: TTMSFNCPlannerBeforeDrawFullDayEmptySpaceEvent;
    FOnAfterDrawFullDayEmptySpace: TTMSFNCPlannerAfterDrawFullDayEmptySpaceEvent;
    FOnAfterDeleteItem: TTMSFNCPlannerAfterDeleteItemEvent;
    FOnBeforeDeleteItem: TTMSFNCPlannerBeforeDeleteItemEvent;
    FOnMoveItem: TTMSFNCPlannerMoveItemEvent;
    FOnSizeItem: TTMSFNCPlannerSizeItemEvent;
    FOnItemChanged: TTMSFNCPlannerItemChangedEvent;
    FOnAfterItemChanged: TTMSFNCPlannerItemChangedEvent;
    FOnItemToCustomContentPanel: TTMSFNCPlannerItemToCustomContentPanelEvent;
    FOnCustomContentPanelToItem: TTMSFNCPlannerCustomContentPanelToItemEvent;
    FOnGetCustomContentPanel: TTMSFNCPlannerGetCustomContentPanelEvent;
    FOnInitializeCustomContentPanel: TTMSFNCPlannerInitializeCustomContentPanelEvent;
    FItemEditor: TTMSFNCPlannerCustomItemEditor;
    FOnIsDateTimeSub: TTMSFNCPlannerIsDateTimeSubEvent;
    FOnHasDateTimeSub: TTMSFNCPlannerHasDateTimeSubEvent;
    FOnIsItemDeletable: TTMSFNCPlannerIsItemDeletableEvent;
    FOnBeforeDrawDeleteArea: TTMSFNCPlannerBeforeDrawDeleteAreaEvent;
    FOnAfterDrawDeleteArea: TTMSFNCPlannerAfterDrawDeleteAreaEvent;
    FOnAfterDrawItemLink: TTMSFNCPlannerAfterDrawItemLink;
    FOnBeforeDrawItemLink: TTMSFNCPlannerBeforeDrawItemLink;
    FOnItemCustomDrawMark: TTMSFNCPlannerItemCustomDrawMarkEvent;
    FPopupPlannerItem: TTMSFNCPlannerItem;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    FEditorForm: TTMSFNCPlannerCustomItemEditorForm;
    procedure SetItemsAppearance(const Value: TTMSFNCPlannerItemsAppearance);
    procedure SetTimeLine(const Value: TTMSFNCPlannerTimeLine);
    procedure SetGroupsAppearance(const Value: TTMSFNCPlannerGroupsAppearance);
    procedure SetFullDaysAppearance(const Value: TTMSFNCPlannerFullDaysAppearance);
    procedure SetPositionsAppearance(const Value: TTMSFNCPlannerPositionsAppearance);
    procedure SetTimeLineAppearance(const Value: TTMSFNCPlannerTimeLineAppearance);
    procedure SetGridCellAppearance(const Value: TTMSFNCPlannerGridCellAppearance);
    procedure SetSelectionAppearance(const Value: TTMSFNCPlannerSelectionAppearance);
    procedure SetMode(const Value: TTMSFNCPlannerMode);
    procedure SetModeSettings(const Value: TTMSFNCPlannerModeSettings);
    procedure SetInteraction(const Value: TTMSFNCPlannerInteraction);
    procedure SetActiveItem(const Value: TTMSFNCPlannerItem);
    procedure SetDefaultItem(const Value: TTMSFNCPlannerItem);
    procedure SetPositions(const Value: TTMSFNCPlannerPositions);
    procedure SetAdapter(const Value: TTMSFNCPlannerAdapter);
    function GetViewRow: Integer;
    procedure SetViewRow(const Value: Integer);
    function GetViewCol: Integer;
    procedure SetViewCol(const Value: Integer);
    procedure SetItemEditor(const Value: TTMSFNCPlannerCustomItemEditor);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
    procedure ChangeRectForMark(AItem: TTMSFNCPlannerItem; var ARect: TRectF);
  protected
    function IsFullDayMode: Boolean; virtual;
    function CanDisplayFullDayTop: Boolean; virtual;
    function CanDisplayFullDayBottom: Boolean; virtual;
    function GetDocURL: string; override;
    function GetVersion: string; override;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure DestroyEditingDialog;
    procedure ResetToDefaultStyle; override;
    {$IFDEF FMXLIB}
    procedure DoAbsoluteChanged; override;
    procedure ApplyInplaceEditorStyleLookup(Sender: TObject);
    {$ENDIF}
    procedure FixStroke(AGraphics: TTMSFNCGraphics); virtual;
    procedure ProcessNavigationButtonsMove(X, Y: Single); virtual;
    procedure ProcessNavigationButtonsDown(X, Y: Single); virtual;
    procedure ProcessNavigationButtonsUp(X, Y: Single); virtual;
    procedure DrawArrow(AGraphics: TTMSFNCGraphics; ARect: TRectF; AArrowKind: TTMSFNCPlannerArrowKind); virtual;
    procedure UpdateScrollPosition(AForce: Boolean = True); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure DoBeforeDrawItemLink(AGraphics: TTMSFNCGraphics; AItem, ALinkedItem: TTMSFNCPlannerItem; var ACanDrawLink: Boolean); virtual;
    procedure DoAfterDrawItemLink(AGraphics: TTMSFNCGraphics; AItem, ALinkedItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeDrawPositionEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawPositionEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace);
    procedure DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace);
    procedure DoBeforeDrawFullDayEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawFullDayEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace);
    procedure DoBeforeDrawTopNavigationButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoBeforeDrawBottomNavigationButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawTopNavigationButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState);
    procedure DoAfterDrawBottomNavigationButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton; AButtonState: TTMSFNCPlannerNavigationButtonState);
    procedure DoBeforeDrawCurrentTimeInTimeLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind; AValue: Double; ACurrentTime: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCurrentTimeInTimeLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind; AValue: Double; ACurrentTime: TDateTime); virtual;
    procedure DoBeforeDrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ACurrentTime: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ACurrentTime: TDateTime); virtual;
    procedure DoBeforeDrawPosition(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawPosition(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawCellVerticalLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCellVerticalLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawCellHorizontalLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawCellHorizontalLine(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawTimeStroke(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ASubUnit: Boolean; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawTimeStroke(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ASubUnit: Boolean; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoBeforeDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeDrawMoveArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawMoveArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeDrawSizeArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSizeArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeDrawDeleteArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDeleteArea(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeDrawItemTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawItemTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String); virtual;

    procedure DoGetPositionText(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AText: String); virtual;
    procedure DoBeforeDrawPositionText(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawPositionText(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String); virtual;
    procedure DoGetGroupText(AGroup: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AText: String); virtual;
    procedure DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String); virtual;
    procedure DoGetTimeText(AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; var AText: String); virtual;
    procedure DoBeforeDrawTimeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawTimeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; AText: string); virtual;
    procedure DoGetGroupHint(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
    procedure DoGetFullDayHint(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
    procedure DoGetPositionHint(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
    procedure DoGetItemHint(AItem: TTMSFNCPlannerItem; var AHint: string); virtual;
    procedure DoGetItemText(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var AText: String); virtual;
    procedure DoBeforeDrawItemText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawItemText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String); virtual;
    procedure DoGetItemTitleText(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var ATitle: String); virtual;
    procedure DoBeforeDrawItemTitleText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawItemTitleText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String); virtual;
    procedure DoGetCustomContentPanel(AItem: TTMSFNCPlannerItem; var AContentPanel: TTMSFNCPlannerEditingDialogContentPanel); virtual;
    procedure DoInitializeCustomContentPanel(AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel); virtual;
    procedure DoItemToCustomContentPanel(AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel); virtual;
    procedure DoCustomContentPanelToItem(AContentPanel: TTMSFNCPlannerEditingDialogContentPanel; AItem: TTMSFNCPlannerItem); virtual;

    procedure DoSelectTime(AStartTime, AEndTime: TDateTime; APosition: Integer); virtual;
    procedure DoSelectingTime(AStartTime, AEndTime: TDateTime; APosition: Integer); virtual;
    procedure DoSelectCell(AStartCell, AEndCell: TTMSFNCPlannerCell); virtual;
    procedure DoSelectingCell(AStartCell, AEndCell: TTMSFNCPlannerCell); virtual;
    procedure DoIsItemDeletable(AItem: TTMSFNCPlannerItem; var ADeletable: Boolean); virtual;
    procedure DoIsDateTimeDisabled(ADateTime: TDateTime; APosition: Integer; var ADisabled: Boolean); virtual;
    procedure DoIsDateTimeInActive(ADateTime: TDateTime; APosition: Integer; var AInActive: Boolean); virtual;
    procedure DoIsDateTimeSub(ADateTime: TDateTime; var AIsSub: Boolean); virtual;
    procedure DoHasDateTimeSub(var AHasSub: Boolean); virtual;
    procedure DoBeforeInsertItem(AStartTime, AEndTime: TDateTime; APosition: Integer; var ATitle: String; var AText: String; var ACanInsert: Boolean); virtual;
    procedure DoAfterInsertItem(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeOpenInsertDialog(AStartTime, AEndTime: TDateTime; APosition: Integer; var ACanOpen: Boolean); virtual;
    procedure DoAfterOpenInsertDialog(AStartTime, AEndTime: TDateTime; APosition: Integer); virtual;
    procedure DoBeforeOpenUpdateDialog(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ACanOpen: Boolean); virtual;
    procedure DoAfterOpenUpdateDialog(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoCloseInsertDialog(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean); virtual;
    procedure DoCloseUpdateDialog(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean); virtual;
    procedure DoBeforeOpenInplaceEditor(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ACanOpen: Boolean); virtual;
    procedure DoAfterOpenInplaceEditor(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; AInplaceEditor: TTMSFNCPlannerInplaceEditor; AInplaceEditorRect: TRectF); virtual;
    procedure DoCloseInplaceEditor(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean); virtual;
    procedure DoBeforeUpdateItem(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ATitle: String; var AText: String; var ACanUpdate: Boolean); virtual;
    procedure DoAfterUpdateItem(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem); virtual;
    procedure DoItemPopupMenuPrepare(AItem: TTMSFNCPlannerItem; APopupMenu: TPopupMenu); virtual;
    procedure DoItemRightClick(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoItemAnchorClick(AItem: TTMSFNCPlannerItem; AAnchor: String); virtual;
    procedure DoItemTitleAnchorClick(AItem: TTMSFNCPlannerItem; AAnchor: String); virtual;
    procedure DoGroupAnchorClick(AGroupIndex: Integer; AAnchor: String); virtual;
    procedure DoPositionAnchorClick(APosition: Integer; AAnchor: String); virtual;
    procedure DoGroupClick(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoFullDayClick(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoPositionClick(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoGroupDblClick(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoFullDayDblClick(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DoPositionDblClick(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;

    procedure CallBeforeMoveLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer); virtual;
    procedure CallBeforeSizeLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer); virtual;
    procedure CallBeforeUpdateLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer); virtual;
    procedure CallMoveLinkedItems(AItem: TTMSFNCPlannerItem); virtual;
    procedure CallSizeLinkedItems(AItem: TTMSFNCPlannerItem); virtual;
    procedure CallAfterMoveLinkedItems(AItem: TTMSFNCPlannerItem); virtual;
    procedure CallAfterSizeLinkedItems(AItem: TTMSFNCPlannerItem); virtual;
    procedure CallAfterUpdateLinkedItems(AItem: TTMSFNCPlannerItem); virtual;

    procedure DoBeforeMoveItem(AItem: TTMSFNCPlannerItem; var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanMove: Boolean); virtual;
    procedure DoBeforeSizeItem(AItem: TTMSFNCPlannerItem; var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanSize: Boolean); virtual;
    procedure DoAfterMoveItem(AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer); virtual;
    procedure DoAfterSizeItem(AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer); virtual;
    procedure DoMoveItem(AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer); virtual;
    procedure DoSizeItem(AItem: TTMSFNCPlannerItem; ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer); virtual;
    procedure DoItemChanged(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoAfterItemChanged(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoItemClick(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoItemDblClick(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoBeforeSelectItem(AItem: TTMSFNCPlannerItem; var ACanSelect: Boolean); virtual;
    procedure DoBeforeDeleteItem(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerItemDeleteMode; var ACanDelete: Boolean); virtual;
    procedure DoBeforeNavigateToDateTime(ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime: TDateTime; var ANewDateTime: TDateTime; var AAllow: Boolean); virtual;
    procedure DoAfterNavigateToDateTime(ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime: TDateTime; ANewDateTime: TDateTime); virtual;
    procedure DoAfterSelectItem(AItem: TTMSFNCPlannerItem); virtual;
    procedure DoAfterDeleteItem(AItemIndex: Integer; ADBKey: String; AMode: TTMSFNCPlannerItemDeleteMode); virtual;
    procedure DoHScroll(APosition: Single); override;
    procedure DoVScroll(APosition: Single); override;
    procedure DoBeforeDrawItemHelper(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawItemHelperText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawItemHelper(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime); virtual;
    procedure DoAfterDrawItemHelperText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; AText: String); virtual;
    procedure DoGetItemHelperText(AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; var AText: String); virtual;
    procedure DoGetInplaceEditor(AStartTime, AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var AInplaceEditorClass: TTMSFNCPlannerInplaceEditorClass); virtual;
    procedure CloseInplaceEditor(ACancel: Boolean; AFlagClose: Boolean = False); virtual;
    procedure DoCustomizeItemRect(AItem: TTMSFNCPlannerItem; APosition: Integer; var AX: Double; var AY: Double; var AWidth: Double; var AHeight: Double); virtual;
    procedure DoItemCustomDrawMark(AGraphics: TTMSFNCGraphics; ARect: TRectF; AMarkType: TTMSFNCPlannerItemMarkType; AItem: TTMSFNCPlannerItem); virtual;

    procedure Animate(Sender: TObject);
    procedure DownTime(Sender: TObject);
    procedure StopAnimationTimer; override;
    procedure HandleDblClick(X, Y: Single); override;
    procedure HandleSelection(AStartCell, AEndCell: TTMSFNCPlannerCell); virtual;
    procedure HandleCellSelection(ASelecting: Boolean = False); virtual;
    procedure HandleDateTimeNavigation(ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime, ANewDateTime: TDateTime); virtual;
    procedure HandleItemInsert(ADialog: Boolean); virtual;
    procedure HandleItemDelete(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerItemDeleteMode); virtual;
    procedure HandleItemEditing(AItem: TTMSFNCPlannerItem; ACacheItem: TTMSFNCPlannerCacheItem = nil); virtual;
    procedure ProcessSelection(ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState; AStepCol, AStepRow: Integer); virtual;
    procedure BuildDisplay(ACache: TTMSFNCPlannerCache; ADisplay: TTMSFNCPlannerDisplayList); virtual;
    procedure UpdateCalculations(AForce: Boolean = False); override;
    procedure RemoveLinkedItem(AItem: TTMSFNCPlannerItem); override;
    procedure NeedsConflictsUpdate(AItem: TTMSFNCPlannerItem = nil); overload; override;
    procedure UpdateAutoSizing; override;
    procedure UpdateColumnRowCalculations; override;
    procedure UpdatePositionsCache; override;
    procedure UpdatePositionCache(ACache: TTMSFNCPlannerCache); overload; virtual;
    procedure UpdateGroupsCache; override;
    procedure UpdateGroupCache(ACache: TTMSFNCPlannerCache); overload; virtual;
    procedure UpdateFullDaysCache; override;
    procedure UpdateFullDayCache(ACache: TTMSFNCPlannerCache); overload; virtual;
    procedure UpdateTimeLinesCache; override;
    procedure UpdateTimeLineCache(ACache: TTMSFNCPlannerCache); overload; virtual;
    procedure UpdateGridCache; override;
    procedure UpdateItemCache; virtual;
    procedure UpdateFullDaysItemsCache; override;
    procedure UpdateFullDaysItemCache(ACache: TTMSFNCPlannerCache); overload; virtual;
    procedure RemoveItemsFromCache(AList: TTMSFNCPlannerCacheItemList; APosition: Integer = -1); override;
    procedure RemoveAllItemsFromCache; override;
    procedure UpdateDisplay; override;
    procedure UpdatePositionsDisplay; virtual;
    procedure UpdateGroupsDisplay; virtual;
    procedure UpdateFullDaysDisplay; virtual;
    procedure UpdateTimeLineDisplay; virtual;
    procedure UpdateGridDisplay; virtual;
    procedure UpdateItemDisplay; virtual;
    procedure UpdateFullDaysItemDisplay; virtual;
    procedure UpdateInplaceEditorPosition; virtual;
    procedure VerticalScrollPositionChanged; override;
    procedure HorizontalScrollPositionChanged; override;
    procedure DrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ACacheIndex: Integer; ACaching: Boolean = False); virtual;
    procedure DrawFullDaysItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ACacheIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawPosition(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind); virtual;
    procedure DrawSelection(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawItems(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawFullDaysItems(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawItemLinks(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawItemHelpers(AGraphics: TTMSFNCGraphics); virtual;
    procedure DoAfterDrawGrid(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoAfterDrawTimeLine(AGraphics: TTMSFNCGraphics; ALeft: Boolean; ARect: TRectF); virtual;
    procedure DrawGrid(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawPositions(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawGroups(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawFullDays(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawTimeLine(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawCurrentTimeInTimeLine(AGraphics: TTMSFNCGraphics; ALeft: Boolean); virtual;
    procedure DrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawBorders(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawNavigationButtons(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawEmptySpaces(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawDisplay(AGraphics: TTMSFNCGraphics; ADisplay: TTMSFNCPlannerDisplayList); virtual;
    procedure HandleMouseEnter; override;
    procedure HandleMouseLeave; override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure HandleDialogKey(var Key: Word; Shift: TShiftState); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure HandleMouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); override;
    procedure StartDateEditChanged(Sender: TObject); virtual;
    procedure EndDateEditChanged(Sender: TObject); virtual;
    procedure StartTimeEditChanged(Sender: TObject); virtual;
    procedure EndTimeEditChanged(Sender: TObject); virtual;
    procedure EditingDialogCancel(Sender: TObject); virtual;
    procedure EditingDialogRemove(Sender: TObject); virtual;
    procedure EditingDialogValidate(Sender: TObject); virtual;
    procedure DirtyItem(AItem: TTMSFNCPlannerItem); override;
    {$IFDEF WEBLIB}
    procedure ShowHintEx(AItem: TTMSFNCPlannerItem; X, Y: Double); virtual;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    procedure ShowHint(AItem: TTMSFNCPlannerItem; X, Y: Double); virtual;
    {$ENDIF}
    procedure UpdateLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartTime, ADiffEndTime: TDateTime; ADiffPosition: Integer); override;
    procedure ProcessNavigation(ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState; AStepCol, AStepRow: Integer); virtual;
    procedure HandleItemKeyboardInteraction(AItem: TTMSFNCPlannerItem; ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState; AColValue, ARowValue: Integer; AStartCell, AEndCell: TTMSFNCPlannerCell); virtual;
    procedure HandleSelectItem(AItem: TTMSFNCPlannerItem; AMultiSelect: Boolean = False); overload; virtual;
    procedure HandleUnselectItem(AItem: TTMSFNCPlannerItem); virtual;
    procedure HandleSelectLinkedItems(AItem: TTMSFNCPlannerItem; AMultiSelect: Boolean = False); virtual;
    procedure UpdateSizeHandlers; virtual;
    procedure UpdateDeleteHandler; virtual;
    procedure GetNewDateTimeAndResource(AItem: TTMSFNCPlannerItem; ALinkType: TTMSFNCPlannerItemLinkType; ADiffStartDateTime, ADiffEndDateTime: TDateTime; var AStartDateTime: TDateTime; var AEndDateTime: TDateTime);
    procedure SetEditingDialogTabOrder; virtual;
    function ColumnStretchingActive: Boolean; override;
    function AllowDesktopSize: Boolean; virtual;
    function HandleAfterMouseEvents: Boolean; virtual;
    function HandleAfterKeyboardEvents: Boolean; virtual;
    function IsMobile: Boolean; virtual;
    function AllowMobileSize: Boolean; virtual;
    function AllowDesktopMove: Boolean; virtual;
    function AllowMobileMove: Boolean; virtual;
    function AllowDesktopDelete: Boolean; virtual;
    function AllowMobileDelete: Boolean; virtual;
    function CanNavigate: Boolean; virtual;
    function GetNextDateTime: TDateTime; virtual;
    function GetPreviousDateTime: TDateTime; virtual;
    function CurrentTime: TDateTime; virtual;
    function HandleItemMouseInteraction(AItem: TTMSFNCPlannerItem; AStartCell, AEndCell, ANewStartCell, ANewEndCell: TTMSFNCPlannerCell; AMode: TTMSFNCPlannerMouseInteractionMode): Boolean; virtual;
    function GetModeName(AMode: TTMSFNCPlannerMode): String; virtual;
    function GetInplaceEditorRect(ACacheItem: TTMSFNCPlannerCacheItem; AItem: TTMSFNCPlannerItem): TRectF; virtual;
    function GetFirstRect(AItem: TTMSFNCPlannerItem): TRectF; virtual;
    function GetLastRect(AItem: TTMSFNCPlannerItem): TRectF; virtual;
    function CanMoveCacheItem({%H-}ACacheItem: TTMSFNCPlannerCacheItem): Boolean; virtual;
    function CanSizeCacheItemEndTime(ACacheItem: TTMSFNCPlannerCacheItem): Boolean; virtual;
    function CanSizeCacheItemStartTime(ACacheItem: TTMSFNCPlannerCacheItem): Boolean; virtual;
    function CanDeleteCacheItem(ACacheItem: TTMSFNCPlannerCacheItem): Boolean; virtual;
    function GetCacheItemEndTimeSizeRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemStartTimeSizeRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemMoveRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemTextRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemTitleRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetAnchorItemTitleRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemDeleteRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetCacheItemRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF; virtual;
    function GetMaxPositionResources: Integer; virtual;
    function CreateAndPrepareBitmap(var ABitmap: TBitmap; AWidth, AHeight: Double): Boolean; virtual;
    {$IFDEF FMXLIB}
    function GetSceneDrawingScale: TTMSFNCPlannerSceneDrawingScale; virtual;
    {$ENDIF}
    function GetDefaultItem: TTMSFNCPlannerItem; override;
    function GetPositionFormat: String; virtual;
    function GetPositionText(APosition: Integer): String; virtual;
    function GetPositionResourceText(APosition: Integer): String; virtual;
    function GetPositionResourceHint(APosition: Integer): string; virtual;
    function GetGroupText(AGroup: Integer): String; virtual;
    function GetGroupHint(AGroup: Integer): string; virtual;
    function GetFullDayHint(AFullDay: Integer): string; virtual;
    function IsDateTimeSub(ADateTime: TDateTime): Boolean; virtual;
    function HasDateTimeSub: Boolean; virtual;
    function GetDateTimeText(ADateTime: TDateTime; ASub: Boolean): String; virtual;
    function GetResourcesAvailable: Boolean; virtual;
    function GetResources: TStringList; virtual;
    function GetMaxDisplayUnitValue: Double; virtual;
    function GetDisplayUnitValue: TDateTime; virtual;
    function GetDisplayOffsetValue: TDateTime; virtual;
    function GetPositionsTopSize: Double; virtual;
    function GetPositionsBottomSize: Double; virtual;
    function GetGroupsTopSize: Double; virtual;
    function GetGroupsBottomSize: Double; virtual;
    function GetFullDaysTopSize: Double; virtual;
    function GetFullDaysBottomSize: Double; virtual;
    function GetTimeLineLeftSize: Double; virtual;
    function GetTimeLineRightSize: Double; virtual;
    function GetCalculationRect: TRectF; override;
    function GetGroupsTopRect: TRectF; virtual;
    function GetFullDaysTopRect: TRectF; virtual;
    function XYToGroupAnchorCache(AX, AY: Single; ACache: TTMSFNCPlannerGroupsCache; var AIndex: Integer): string;
    function XYToPositionAnchorCache(AX, AY: Single; ACache: TTMSFNCPlannerPositionsCache; var AIndex: Integer): string;
    function XYToGroupCache(AX, AY: Single; ACache: TTMSFNCPlannerGroupsCache): TTMSFNCPlannerCacheItem;
    function XYToFullDayCache(AX, AY: Single; ACache: TTMSFNCPlannerFullDaysCache): TTMSFNCPlannerCacheItem;
    function XYToPositionCache(AX, AY: Single; ACache: TTMSFNCPlannerPositionsCache): TTMSFNCPlannerCacheItem;
    function MaxPositionDateTime(ADateTime: TDatetime; AEndDatetime: Boolean; APosition: Integer): TDateTime; virtual;
    function CalculatePositionDateTime(ADateTime: TDatetime; APosition: Integer): TDateTime; virtual;
    function GetGroupsBottomRect: TRectF; virtual;
    function GetFullDaysBottomRect: TRectF; virtual;
    function GetPositionsTopRect: TRectF; virtual;
    function GetPositionTopLeftEmptyRect: TRectF; virtual;
    function GetPositionBottomLeftEmptyRect: TRectF; virtual;
    function GetPositionBottomRightEmptyRect: TRectF; virtual;
    function GetPositionTopRightEmptyRect: TRectF; virtual;
    function GetGroupTopLeftEmptyRect: TRectF; virtual;
    function GetGroupBottomLeftEmptyRect: TRectF; virtual;
    function GetGroupBottomRightEmptyRect: TRectF; virtual;
    function GetGroupTopRightEmptyRect: TRectF; virtual;
    function GetFullDayTopLeftEmptyRect: TRectF; virtual;
    function GetFullDayBottomLeftEmptyRect: TRectF; virtual;
    function GetFullDayBottomRightEmptyRect: TRectF; virtual;
    function GetFullDayTopRightEmptyRect: TRectF; virtual;
    function GetTopLeftNavigationButtonRect: TRectF; virtual;
    function GetTopRightNavigationButtonRect: TRectF; virtual;
    function GetBottomLeftNavigationButtonRect: TRectF; virtual;
    function GetBottomRightNavigationButtonRect: TRectF; virtual;
    function GetPositionsBottomRect: TRectF; virtual;
    function GetTimeLineLeftRect: TRectF; virtual;
    function GetTimeLineRightRect: TRectF; virtual;
    function GetCacheWidth: Integer; virtual;
    function GetCacheHeight: Integer; virtual;
    function GetDisplaySubUnitFormat: String; virtual;
    function GetDisplayUnitFormat: String; virtual;
    function GetNumDays: Integer; virtual;
    function GetDisplayMode: TTMSFNCPlannerMode; virtual;
    function GetActiveItem: TTMSFNCPlannerItem; override;
    procedure UpdateActiveItem(AItem: TTMSFNCPlannerItem); override;
    procedure DrawItemMarks(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem); virtual;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property Adapter: TTMSFNCPlannerAdapter read FAdapter write SetAdapter;
    property ItemEditor: TTMSFNCPlannerCustomItemEditor read FItemEditor write SetItemEditor;
    property SelectedItems: TTMSFNCPlannerSelectedItems read FSelectedItems;
    property ActiveItem: TTMSFNCPlannerItem read FActiveItem write SetActiveItem;
    property Selection: TTMSFNCPlannerSelection read FSelection;
    property NeedsInitialization: Boolean read FNeedsInitialization write FNeedsInitialization;
    property GridCaching: Boolean read FGridCaching write FGridCaching default False;
    property PositionsCaching: Boolean read FPositionsCaching write FPositionsCaching default False;
    property GroupsCaching: Boolean read FGroupsCaching write FGroupsCaching default False;
    property FullDaysCaching: Boolean read FFullDaysCaching write FFullDaysCaching default False;
    property TimeLineCaching: Boolean read FTimeLineCaching write FTimeLineCaching default False;
    property ItemsAppearance: TTMSFNCPlannerItemsAppearance read FItemsAppearance write SetItemsAppearance;
    property GridCellAppearance: TTMSFNCPlannerGridCellAppearance read FGridCellAppearance write SetGridCellAppearance;
    property TimeLineAppearance: TTMSFNCPlannerTimeLineAppearance read FTimeLineAppearance write SetTimeLineAppearance;
    property SelectionAppearance: TTMSFNCPlannerSelectionAppearance read FSelectionAppearance write SetSelectionAppearance;
    property PositionsAppearance: TTMSFNCPlannerPositionsAppearance read FPositionsAppearance write SetPositionsAppearance;
    property Positions: TTMSFNCPlannerPositions read FPositions write SetPositions;
    property GroupsAppearance: TTMSFNCPlannerGroupsAppearance read FGroupsAppearance write SetGroupsAppearance;
    property FullDaysAppearance: TTMSFNCPlannerFullDaysAppearance read FFullDaysAppearance write SetFullDaysAppearance;
    property TimeLine: TTMSFNCPlannerTimeLine read FTimeLine write SetTimeLine;
    property ModeSettings: TTMSFNCPlannerModeSettings read FModeSettings write SetModeSettings;
    property ItemPopup: TPopupMenu read FItemPopup write FItemPopup;
    property OnAfterDrawTimeLine: TTMSFNCPlannerAfterDrawTimeLineEvent read FOnAfterDrawTimeLine write FOnAfterDrawTimeLine;
    property OnAfterDrawGrid: TTMSFNCPlannerAfterDrawGridEvent read FOnAfterDrawGrid write FOnAfterDrawGrid;
    property OnBeforeDrawItemLink: TTMSFNCPlannerBeforeDrawItemLink read FOnBeforeDrawItemLink write FOnBeforeDrawItemLink;
    property OnAfterDrawItemLink: TTMSFNCPlannerAfterDrawItemLink read FOnAfterDrawItemLink write FOnAfterDrawItemLink;

    property OnBeforeDrawPositionEmptySpace: TTMSFNCPlannerBeforeDrawPositionEmptySpaceEvent read FOnBeforeDrawPositionEmptySpace write FOnBeforeDrawPositionEmptySpace;
    property OnAfterDrawPositionEmptySpace: TTMSFNCPlannerAfterDrawPositionEmptySpaceEvent read FOnAfterDrawPositionEmptySpace write FOnAfterDrawPositionEmptySpace;
    property OnBeforeDrawGroupEmptySpace: TTMSFNCPlannerBeforeDrawGroupEmptySpaceEvent read FOnBeforeDrawGroupEmptySpace write FOnBeforeDrawGroupEmptySpace;
    property OnAfterDrawGroupEmptySpace: TTMSFNCPlannerAfterDrawGroupEmptySpaceEvent read FOnAfterDrawGroupEmptySpace write FOnAfterDrawGroupEmptySpace;
    property OnBeforeDrawFullDayEmptySpace: TTMSFNCPlannerBeforeDrawFullDayEmptySpaceEvent read FOnBeforeDrawFullDayEmptySpace write FOnBeforeDrawFullDayEmptySpace;
    property OnAfterDrawFullDayEmptySpace: TTMSFNCPlannerAfterDrawFullDayEmptySpaceEvent read FOnAfterDrawFullDayEmptySpace write FOnAfterDrawFullDayEmptySpace;
    property OnItemChanged: TTMSFNCPlannerItemChangedEvent read FOnItemChanged write FOnItemChanged;
    property OnAfterItemChanged: TTMSFNCPlannerItemChangedEvent read FOnAfterItemChanged write FOnAfterItemChanged;
    property OnCustomContentPanelToItem: TTMSFNCPlannerCustomContentPanelToItemEvent read FOnCustomContentPanelToItem write FOnCustomContentPanelToItem;
    property OnItemToCustomContentPanel: TTMSFNCPlannerItemToCustomContentPanelEvent read FOnItemToCustomContentPanel write FOnItemToCustomContentPanel;
    property OnGetCustomContentPanel: TTMSFNCPlannerGetCustomContentPanelEvent read FOnGetCustomContentPanel write FOnGetCustomContentPanel;
    property OnInitializeCustomContentPanel: TTMSFNCPlannerInitializeCustomContentPanelEvent read FOnInitializeCustomContentPanel write FOnInitializeCustomContentPanel;

    property OnBeforeDrawTopNavigationButton: TTMSFNCPlannerBeforeDrawTopNavigationButtonEvent read FOnBeforeDrawTopNavigationButton write FOnBeforeDrawTopNavigationButton;
    property OnBeforeDrawBottomNavigationButton: TTMSFNCPlannerBeforeDrawBottomNavigationButtonEvent read FOnBeforeDrawBottomNavigationButton write FOnBeforeDrawBottomNavigationButton;
    property OnAfterDrawTopNavigationButton: TTMSFNCPlannerAfterDrawTopNavigationButtonEvent read FOnAfterDrawTopNavigationButton write FOnAfterDrawTopNavigationButton;
    property OnAfterDrawBottomNavigationButton: TTMSFNCPlannerAfterDrawBottomNavigationButtonEvent read FOnAfterDrawBottomNavigationButton write FOnAfterDrawBottomNavigationButton;
    property OnBeforeDrawCurrentTimeInTimeLine: TTMSFNCPlannerBeforeDrawCurrentTimeInTimeLineEvent read FOnBeforeDrawCurrentTimeInTimeLine write FOnBeforeDrawCurrentTimeInTimeLine;
    property OnAfterDrawCurrentTimeInTimeLine: TTMSFNCPlannerAfterDrawCurrentTimeInTimeLineEvent read FOnAfterDrawCurrentTimeInTimeLine write FOnAfterDrawCurrentTimeInTimeLine;
    property OnBeforeDrawCurrentTimeInGrid: TTMSFNCPlannerBeforeDrawCurrentTimeInGridEvent read FOnBeforeDrawCurrentTimeInGrid write FOnBeforeDrawCurrentTimeInGrid;
    property OnAfterDrawCurrentTimeInGrid: TTMSFNCPlannerAfterDrawCurrentTimeInGridEvent read FOnAfterDrawCurrentTimeInGrid write FOnAfterDrawCurrentTimeInGrid;
    property OnBeforeDrawPosition: TTMSFNCPlannerBeforeDrawPositionEvent read FOnBeforeDrawPosition write FOnBeforeDrawPosition;
    property OnAfterDrawPosition: TTMSFNCPlannerAfterDrawPositionEvent read FOnAfterDrawPosition write FOnAfterDrawPosition;
    property OnBeforeDrawCell: TTMSFNCPlannerBeforeDrawCellEvent read FOnBeforeDrawCell write FOnBeforeDrawCell;
    property OnAfterDrawCell: TTMSFNCPlannerAfterDrawCellEvent read FOnAfterDrawCell write FOnAfterDrawCell;
    property OnBeforeDrawCellVerticalLine: TTMSFNCPlannerBeforeDrawCellVerticalLineEvent read FOnBeforeDrawCellVerticalLine write FOnBeforeDrawCellVerticalLine;
    property OnAfterDrawCellVerticalLine: TTMSFNCPlannerAfterDrawCellVerticalLineEvent read FOnAfterDrawCellVerticalLine write FOnAfterDrawCellVerticalLine;
    property OnBeforeDrawCellHorizontalLine: TTMSFNCPlannerBeforeDrawCellHorizontalLineEvent read FOnBeforeDrawCellHorizontalLine write FOnBeforeDrawCellHorizontalLine;
    property OnAfterDrawCellHorizontalLine: TTMSFNCPlannerAfterDrawCellHorizontalLineEvent read FOnAfterDrawCellHorizontalLine write FOnAfterDrawCellHorizontalLine;
    property OnBeforeDrawGroup: TTMSFNCPlannerBeforeDrawGroupEvent read FOnBeforeDrawGroup write FOnBeforeDrawGroup;
    property OnAfterDrawGroup: TTMSFNCPlannerAfterDrawGroupEvent read FOnAfterDrawGroup write FOnAfterDrawGroup;
    property OnBeforeDrawFullDay: TTMSFNCPlannerBeforeDrawFullDayEvent read FOnBeforeDrawFullDay write FOnBeforeDrawFullDay;
    property OnAfterDrawFullDay: TTMSFNCPlannerAfterDrawFullDayEvent read FOnAfterDrawFullDay write FOnAfterDrawFullDay;
    property OnBeforeDrawTime: TTMSFNCPlannerBeforeDrawTimeEvent read FOnBeforeDrawTime write FOnBeforeDrawTime;
    property OnAfterDrawTime: TTMSFNCPlannerAfterDrawTimeEvent read FOnAfterDrawTime write FOnAfterDrawTime;
    property OnBeforeDrawTimeStroke: TTMSFNCPlannerBeforeDrawTimeStrokeEvent read FOnBeforeDrawTimeStroke write FOnBeforeDrawTimeStroke;
    property OnAfterDrawTimeStroke: TTMSFNCPlannerAfterDrawTimeStrokeEvent read FOnAfterDrawTimeStroke write FOnAfterDrawTimeStroke;
    property OnBeforeDrawItem: TTMSFNCPlannerBeforeDrawItemEvent read FOnBeforeDrawItem write FOnBeforeDrawItem;
    property OnAfterDrawItem: TTMSFNCPlannerAfterDrawItemEvent read FOnAfterDrawItem write FOnAfterDrawItem;
    property OnBeforeDrawMoveArea: TTMSFNCPlannerBeforeDrawMoveAreaEvent read FOnBeforeDrawMoveArea write FOnBeforeDrawMoveArea;
    property OnAfterDrawMoveArea: TTMSFNCPlannerAfterDrawMoveAreaEvent read FOnAfterDrawMoveArea write FOnAfterDrawMoveArea;
    property OnBeforeDrawSizeArea: TTMSFNCPlannerBeforeDrawSizeAreaEvent read FOnBeforeDrawSizeArea write FOnBeforeDrawSizeArea;
    property OnAfterDrawSizeArea: TTMSFNCPlannerAfterDrawSizeAreaEvent read FOnAfterDrawSizeArea write FOnAfterDrawSizeArea;
    property OnBeforeDrawDeleteArea: TTMSFNCPlannerBeforeDrawDeleteAreaEvent read FOnBeforeDrawDeleteArea write FOnBeforeDrawDeleteArea;
    property OnAfterDrawDeleteArea: TTMSFNCPlannerAfterDrawDeleteAreaEvent read FOnAfterDrawDeleteArea write FOnAfterDrawDeleteArea;
    property OnBeforeDrawItemTitle: TTMSFNCPlannerBeforeDrawItemTitleEvent read FOnBeforeDrawItemTitle write FOnBeforeDrawItemTitle;
    property OnAfterDrawItemTitle: TTMSFNCPlannerAfterDrawItemTitleEvent read FOnAfterDrawItemTitle write FOnAfterDrawItemTitle;
    property OnBeforeDrawPositionText: TTMSFNCPlannerBeforeDrawPositionTextEvent read FOnBeforeDrawPositionText write FOnBeforeDrawPositionText;
    property OnGetPositionText: TTMSFNCPlannerGetPositionTextEvent read FOnGetPositionText write FOnGetPositionText;
    property OnAfterDrawPositionText: TTMSFNCPlannerAfterDrawPositionTextEvent read FOnAfterDrawPositionText write FOnAfterDrawPositionText;
    property OnBeforeDrawGroupText: TTMSFNCPlannerBeforeDrawGroupTextEvent read FOnBeforeDrawGroupText write FOnBeforeDrawGroupText;
    property OnGetGroupText: TTMSFNCPlannerGetGroupTextEvent read FOnGetGroupText write FOnGetGroupText;
    property OnAfterDrawGroupText: TTMSFNCPlannerAfterDrawGroupTextEvent read FOnAfterDrawGroupText write FOnAfterDrawGroupText;
    property OnBeforeDrawTimeText: TTMSFNCPlannerBeforeDrawTimeTextEvent read FOnBeforeDrawTimeText write FOnBeforeDrawTimeText;
    property OnGetTimeText: TTMSFNCPlannerGetTimeTextEvent read FOnGetTimeText write FOnGetTimeText;
    property OnAfterDrawTimeText: TTMSFNCPlannerAfterDrawTimeTextEvent read FOnAfterDrawTimeText write FOnAfterDrawTimeText;
    property OnBeforeDrawItemText: TTMSFNCPlannerBeforeDrawItemTextEvent read FOnBeforeDrawItemText write FOnBeforeDrawItemText;
    property OnGetItemHint: TTMSFNCPlannerGetItemHintEvent read FOnGetItemHint write FOnGetItemHint;
    property OnGetPositionHint: TTMSFNCPlannerGetColumnHintEvent read FOnGetPositionHint write FOnGetPositionHint;
    property OnGetGroupHint: TTMSFNCPlannerGetColumnHintEvent read FOnGetGroupHint write FOnGetGroupHint;
    property OnGetFullDayHint: TTMSFNCPlannerGetColumnHintEvent read FOnGetFullDayHint write FOnGetFullDayHint;
    property OnGetItemText: TTMSFNCPlannerGetItemTextEvent read FOnGetItemText write FOnGetItemText;
    property OnAfterDrawItemText: TTMSFNCPlannerAfterDrawItemTextEvent read FOnAfterDrawItemText write FOnAfterDrawItemText;
    property OnBeforeDrawItemTitleText: TTMSFNCPlannerBeforeDrawItemTitleTextEvent read FOnBeforeDrawItemTitleText write FOnBeforeDrawItemTitleText;
    property OnGetItemTitleText: TTMSFNCPlannerGetItemTitleTextEvent read FOnGetItemTitleText write FOnGetItemTitleText;
    property OnAfterDrawItemTitleText: TTMSFNCPlannerAfterDrawItemTitleTextEvent read FOnAfterDrawItemTitleText write FOnAfterDrawItemTitleText;
    property OnSelectTime: TTMSFNCPlannerSelectTimeEvent read FOnSelectTime write FOnSelectTime;
    property OnSelectingTime: TTMSFNCPlannerSelectTimeEvent read FOnSelectingTime write FOnSelectingTime;
    property OnSelectCell: TTMSFNCPlannerSelectCellEvent read FOnSelectCell write FOnSelectCell;
    property OnSelectingCell: TTMSFNCPlannerSelectCellEvent read FOnSelectingCell write FOnSelectingCell;
    property OnBeforeInsertItem: TTMSFNCPlannerBeforeInsertItemEvent read FOnBeforeInsertItem write FOnBeforeInsertItem;
    property OnAfterInsertItem: TTMSFNCPlannerAfterInsertItemEvent read FOnAfterInsertItem write FOnAfterInsertItem;
    property OnBeforeUpdateItem: TTMSFNCPlannerBeforeUpdateItemEvent read FOnBeforeUpdateItem write FOnBeforeUpdateItem;
    property OnAfterUpdateItem: TTMSFNCPlannerAfterUpdateItemEvent read FOnAfterUpdateItem write FOnAfterUpdateItem;
    property OnBeforeOpenInsertDialog: TTMSFNCPlannerBeforeOpenInsertDialogEvent read FOnBeforeOpenInsertDialog write FOnBeforeOpenInsertDialog;
    property OnAfterOpenInsertDialog: TTMSFNCPlannerAfterOpenInsertDialogEvent read FOnAfterOpenInsertDialog write FOnAfterOpenInsertDialog;
    property OnBeforeOpenUpdateDialog: TTMSFNCPlannerBeforeOpenUpdateDialogEvent read FOnBeforeOpenUpdateDialog write FOnBeforeOpenUpdateDialog;
    property OnAfterOpenUpdateDialog: TTMSFNCPlannerAfterOpenUpdateDialogEvent read FOnAfterOpenUpdateDialog write FOnAfterOpenUpdateDialog;
    property OnBeforeOpenInplaceEditor: TTMSFNCPlannerBeforeOpenInplaceEditorEvent read FOnBeforeOpenInplaceEditor write FOnBeforeOpenInplaceEditor;
    property OnBeforeNavigateToDateTime: TTMSFNCPlannerBeforeNavigateToDateTimeEvent read FOnBeforeNavigateToDateTime write FOnBeforeNavigateToDateTime;
    property OnAfterNavigateToDateTime: TTMSFNCPlannerAfterNavigateToDateTimeEvent read FOnAfterNavigateToDateTime write FOnAfterNavigateToDateTime;
    property OnCloseInplaceEditor: TTMSFNCPlannerCloseInplaceEditorEvent read FOnCloseInplaceEditor write FOnCloseInplaceEditor;
    property OnCloseUpdateDialog: TTMSFNCPlannerCloseUpdateDialogEvent read FOnCloseUpdateDialog write FOnCloseUpdateDialog;
    property OnCloseInsertDialog: TTMSFNCPlannerCloseInsertDialogEvent read FOnCloseInsertDialog write FOnCloseInsertDialog;
    property OnAfterOpenInplaceEditor: TTMSFNCPlannerAfterOpenInplaceEditorEvent read FOnAfterOpenInplaceEditor write FOnAfterOpenInplaceEditor;
    property OnBeforeSelectItem: TTMSFNCPlannerBeforeSelectItemEvent read FOnBeforeSelectItem write FOnBeforeSelectItem;
    property OnBeforeDeleteItem: TTMSFNCPlannerBeforeDeleteItemEvent read FOnBeforeDeleteItem write FOnBeforeDeleteItem;
    property OnBeforeMoveItem: TTMSFNCPlannerBeforeMoveItemEvent read FOnBeforeMoveItem write FOnBeforeMoveItem;
    property OnBeforeSizeItem: TTMSFNCPlannerBeforeSizeItemEvent read FOnBeforeSizeItem write FOnBeforeSizeItem;
    property OnAfterMoveItem: TTMSFNCPlannerAfterMoveItemEvent read FOnAfterMoveItem write FOnAfterMoveItem;
    property OnAfterSizeItem: TTMSFNCPlannerAfterSizeItemEvent read FOnAfterSizeItem write FOnAfterSizeItem;
    property OnMoveItem: TTMSFNCPlannerMoveItemEvent read FOnMoveItem write FOnMoveItem;
    property OnSizeItem: TTMSFNCPlannerSizeItemEvent read FOnSizeItem write FOnSizeItem;
    property OnAfterSelectItem: TTMSFNCPlannerAfterSelectItemEvent read FOnAfterSelectItem write FOnAfterSelectItem;
    property OnAfterDeleteItem: TTMSFNCPlannerAfterDeleteItemEvent read FOnAfterDeleteItem write FOnAfterDeleteItem;
    property OnIsItemDeletable: TTMSFNCPlannerIsItemDeletableEvent read FOnIsItemDeletable write FOnIsItemDeletable;
    property OnIsDateTimeDisabled: TTMSFNCPlannerIsDateTimeDisabledEvent read FOnIsDateTimeDisabled write FOnIsDateTimeDisabled;
    property OnIsDateTimeInActive: TTMSFNCPlannerIsDateTimeInActiveEvent read FOnIsDateTimeInActive write FOnIsDateTimeInActive;
    property OnIsDateTimeSub: TTMSFNCPlannerIsDateTimeSubEvent read FOnIsDateTimeSub write FOnIsDateTimeSub;
    property OnHasDateTimeSub: TTMSFNCPlannerHasDateTimeSubEvent read FOnHasDateTimeSub write FOnHasDateTimeSub;
    property OnItemPopupMenuPrepare: TTMSFNCPlannerItemPopupMenuPrepareEvent read FOnItemPopupMenuPrepare write FOnItemPopupMenuPrepare;
    property OnItemRightClick: TTMSFNCPlannerItemRightClickEvent read FOnItemRightClick write FOnItemRightClick;
    property OnItemDblClick: TTMSFNCPlannerItemDblClickEvent read FOnItemDblClick write FOnItemDblClick;
    property OnItemClick: TTMSFNCPlannerItemClickEvent read FOnItemClick write FOnItemClick;
    property OnItemAnchorClick: TTMSFNCPlannerItemAnchorClickEvent read FOnItemAnchorClick write FOnItemAnchorClick;
    property OnItemTitleAnchorClick: TTMSFNCPlannerItemAnchorClickEvent read FOnItemTitleAnchorClick write FOnItemTitleAnchorClick;
    property OnGroupAnchorClick: TTMSFNCPlannerColumnAnchorClickEvent read FOnGroupAnchorClick write FOnGroupAnchorClick;
    property OnPositionAnchorClick: TTMSFNCPlannerColumnAnchorClickEvent read FOnPositionAnchorClick write FOnPositionAnchorClick;
    property OnGroupClick: TTMSFNCPlannerColumnClickEvent read FOnGroupClick write FOnGroupClick;
    property OnFullDayClick: TTMSFNCPlannerColumnClickEvent read FOnFullDayClick write FOnFullDayClick;
    property OnPositionClick: TTMSFNCPlannerColumnClickEvent read FOnPositionClick write FOnPositionClick;
    property OnGroupDblClick: TTMSFNCPlannerColumnDblClickEvent read FOnGroupDblClick write FOnGroupDblClick;
    property OnFullDayDblClick: TTMSFNCPlannerColumnDblClickEvent read FOnFullDayDblClick write FOnFullDayDblClick;
    property OnPositionDblClick: TTMSFNCPlannerColumnDblClickEvent read FOnPositionDblClick write FOnPositionDblClick;
    property OnGetInplaceEditor: TTMSFNCPlannerGetInplaceEditorEvent read FOnGetInplaceEditor write FOnGetInplaceEditor;
    property Mode: TTMSFNCPlannerMode read FMode write SetMode default pmMultiDay;
    property Interaction: TTMSFNCPlannerInteraction read FInteraction write SetInteraction;
    property DefaultItem: TTMSFNCPlannerItem read FDefaultItem write SetDefaultItem;
    property CustomDateTimes: TTMSFNCPlannerDateTimes read FCustomDateTimes write FCustomDateTimes;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property OnVScroll: TTMSFNCPlannerScrollEvent read FOnVScroll write FOnVScroll;
    property OnHScroll: TTMSFNCPlannerScrollEvent read FOnHScroll write FOnHScroll;
    property OnBeforeDrawItemHelper: TTMSFNCPlannerBeforeDrawItemHelperEvent read FOnBeforeDrawItemHelper write FOnBeforeDrawItemHelper;
    property OnBeforeDrawItemHelperText: TTMSFNCPlannerBeforeDrawItemHelperTextEvent read FOnBeforeDrawItemHelperText write FOnBeforeDrawItemHelperText;
    property OnAfterDrawItemHelper: TTMSFNCPlannerAfterDrawItemHelperEvent read FOnAfterDrawItemHelper write FOnAfterDrawItemHelper;
    property OnAfterDrawItemHelperText: TTMSFNCPlannerAfterDrawItemHelperTextEvent read FOnAfterDrawItemHelperText write FOnAfterDrawItemHelperText;
    property OnGetItemHelperText: TTMSFNCPlannerGetItemHelperTextEvent read FOnGetItemHelperText write FOnGetItemHelperText;
    property Version: string read GetVersion;
    property ToolBarPopup: TTMSFNCPlannerCustomToolBarPopup read FToolBarPopup write FToolBarPopup;
    property OnCustomizeItemRect: TTMSFNCPlannerCustomizeItemRectEvent read FOnCustomizeItemRect write FOnCustomizeItemRect;
    property OnItemCustomDrawMark: TTMSFNCPlannerItemCustomDrawMarkEvent read FOnItemCustomDrawMark write FOnItemCustomDrawMark;

    property CustomItemEditorForm: TTMSFNCPlannerCustomItemEditorFormClass read FCustomItemEditorForm write FCustomItemEditorForm;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure SelectCells(AStartCell, AEndCell: TTMSFNCPlannerCell); virtual;
    procedure Navigate(ACell: TTMSFNCPlannerCell; AForceScroll: Boolean = False; AIgnoreColumn: Boolean = False; AIgnoreRow: Boolean = False); virtual;
    procedure InitSample;
    procedure CloseEditingDialogAndRemoveItem; virtual;
    procedure CloseEditingDialog(ACancel: Boolean); virtual;
    procedure OpenEditingDialog(AStartTime, AEndTime: TDateTime; AResource: Integer; ATitle, AText: String; AUpdateItem: Integer = -1; ACustomParent: TTMSFNCPlannerCustomParent = nil); virtual;
    procedure SelectItem(AItemIndex: Integer); overload; virtual;
    procedure SelectItems(AItems: TTMSFNCPlannerItemArray); virtual;
    procedure SelectItem(AItem: TTMSFNCPlannerItem); overload; virtual;
    procedure UnselectItem(AItem: TTMSFNCPlannerItem); virtual;
    procedure UnselectAllItems; virtual;
    procedure NavigateToNextDateTime(ADateTime: TDateTime = -1); virtual;
    procedure NavigateToPreviousDateTime(ADateTime: TDateTime = -1); virtual;
    procedure EditItem(AItem: TTMSFNCPlannerItem); overload; virtual;
    procedure EditItem(AItemIndex: Integer); overload; virtual;
    procedure StopEditing; virtual;
    procedure CancelEditing; virtual;
    procedure LinkItems(AItems: TTMSFNCPlannerLinkedItemArray; ACircular: Boolean = False; ALinkType: TTMSFNCPlannerItemLinkType = iltNone);
    procedure UnlinkItems(AItems: TTMSFNCPlannerLinkedItemArray);
    procedure SelectLinkedItems(AItem: TTMSFNCPlannerItem);
    procedure UpdateConflicts; override;
    procedure UpdateFullDayConflicts; override;
    procedure UpdateItemsCache; override;
    procedure UpdatePlannerCache(ADirtyItems: Boolean = True); override;
    procedure ClearSelection; virtual;
    function IsFullDayAutoSize: Boolean; override;
    function FindItemLinkedTo(AItem: TTMSFNCPlannerItem): TTMSFNCPlannerItem;
    function GetContentClipRect: TRectF; virtual;
    function GetContentRect: TRectF; override;
    function IsEditing: Boolean; virtual;
    function GetDeleteHandler: TTMSFNCPlannerDeleteHandler; virtual;
    function GetStartTimeSizeHandler: TTMSFNCPlannerSizeHandler; virtual;
    function GetEndTimeSizeHandler: TTMSFNCPlannerSizeHandler; virtual;
    function GetHintPopup: TTMSFNCPlannerHintPopup; virtual;
    function GetEditingDialog(AItemIndex: Integer = -1): TTMSFNCPlannerEditingDialog; virtual;
    function AddItemAtSelection: TTMSFNCPlannerItem; virtual;
    function AddItem(AStartTime, AEndTime: TDateTime): TTMSFNCPlannerItem; virtual;
    function AddOrUpdateItem(AStartTime, AEndTime: TDateTime; ATitle: String; AText: String; AItemIndex: Integer = -1): TTMSFNCPlannerItem; overload; virtual;
    function AddOrUpdateItem(AResource: Integer; AStartTime, AEndTime: TDateTime; ATitle: String = ''; AText: String = ''; AItemIndex: Integer = -1): TTMSFNCPlannerItem; overload; virtual;
    function AddOrUpdateItem(AResourceName: String; AStartTime, AEndTime: TDateTime; ATitle: String = ''; AText: String = ''; AItemIndex: Integer = -1): TTMSFNCPlannerItem; overload; virtual;
    function XYToCacheItem(X, Y: Double): TTMSFNCPlannerCacheItem; virtual;
    function XYToFullDayItem(X, Y: Double): TTMSFNCPlannerItem; virtual;
    function XYToItem(X, Y: Double): TTMSFNCPlannerItem; virtual;
    function XYToCell(X, Y: Double): TTMSFNCPlannerCell; override;
    function XYToTime(X, Y: Double): TTMSFNCPlannerTime; virtual;
    function XYToItemAnchor(AItem: TTMSFNCPlannerItem; AX, AY: Single): String; virtual;
    function XYToItemTitleAnchor(AItem: TTMSFNCPlannerItem; AX, AY: Single): String; virtual;

    function XYToGroupAnchor(AX, AY: Single; var AIndex: Integer): string; virtual;
    function XYToPositionAnchor(AX, AY: Single; var AIndex: Integer): string; virtual;
    function XYToGroup(AX, AY: Single): TTMSFNCPlannerCacheItem; virtual;
    function XYToFullDay(AX, AY: Single): TTMSFNCPlannerCacheItem; virtual;
    function XYToPosition(AX, AY: Single): TTMSFNCPlannerCacheItem; virtual;
    function PositionToResource(APosition: Integer): Integer; virtual;
    function ResourceToPosition(AResource: Integer): Integer; virtual;
    function IsDateTimeDisabled(ADateTime: TDateTime; APosition: Integer = -1): Boolean; virtual;
    function IsDateTimeInActive(ADateTime: TDateTime; APosition: Integer = -1): Boolean; virtual;
    function IsCellDisabled(ACell: TTMSFNCPlannerCell): Boolean; virtual;
    function IsCellInActive(ACell: TTMSFNCPlannerCell): Boolean; virtual;
    function CellToDateTime(ACell: TTMSFNCPlannerCell): TDateTime; virtual;
    function CellToStartDatetime(ACell: TTMSFNCPlannerCell): TDateTime; virtual;
    function CellToEndDateTime(ACell: TTMSFNCPlannerCell): TDateTime; virtual;
    function SelectedStartDateTime: TDateTime; virtual;
    function SelectedEndDateTime: TDateTime; virtual;
    function SelectedResource: Integer; virtual;
    function DateTimeToCell(ADateTime: TDateTime; AEndDateTime: Boolean = false): TTMSFNCPlannerCell; virtual;
    function ItemToStartCell(AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCell; virtual;
    function ItemToEndCell(AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCell; virtual;
    function HasItem(AStartTime, AEndTime: TDateTime; APosition: Integer; ACompareWithItemIndex: Integer = -1; ACheckOverlap: Boolean = True): Boolean; virtual;
    function FindFirstItem(AStartTime, AEndTime: TDateTime; APosition: Integer): TTMSFNCPlannerItem; virtual;
    function FindNextItem(AStartTime, AEndTime: TDateTime; APosition: Integer): TTMSFNCPlannerItem; virtual;
    function FindItemWithDBKey(ADBKey: String): TTMSFNCPlannerItem; virtual;
    function DateTimeToValue(ADateTime: TDateTime; AEndDateTime: Boolean = False; ACheckBounds: Boolean = True): Double; virtual;
    function ValueToDateTime(AValue: Double; APosition: Integer = -1; ARow: Integer = -1): TDateTime; virtual;
    function DateTimeToPosition(ADateTime: TDateTime; AEndDateTime: Boolean = False; ACheckBounds: Boolean = True): Integer; virtual;
    function PositionToDateTime(APosition: Integer): TDateTime; virtual;
    function HasFullDayItemPosition(ALayout: TTMSFNCPlannerFullDayLayout; APosition: Integer; AItem: TTMSFNCPlannerItem): Boolean; virtual;
    function SelectNextItem: TTMSFNCPlannerItem; virtual;
    function SelectPreviousItem: TTMSFNCPlannerItem; virtual;
    function IsValidItem(AItem: TTMSFNCPlannerItem): Boolean; virtual;
    function IsFullDayItem(AItem: TTMSFNCPlannerItem): Boolean; virtual;
    function GetInplaceEditor: TTMSFNCPlannerInplaceEditor; virtual;
    function DisplayStartDateTime: TDateTime; virtual;
    function DisplayEndDateTime: TDateTime; virtual;
    property ViewCol: Integer read GetViewCol write SetViewCol;
    property ViewRow: Integer read GetViewRow write SetViewRow;
    property PopupPlannerItem: TTMSFNCPlannerItem read FPopupPlannerItem;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCPlanner = class(TTMSFNCCustomPlanner)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    property ItemCachingMode;
    property CustomDateTimes;
    property CustomItemEditorForm;
    property Selection;
    property ActiveItem;
    property SelectedItems;
    property ItemPopup;
  published
    property Adapter;
    property ToolBarPopup;
    property ItemEditor;
    property BitmapContainer;
    property HorizontalScrollBarVisible;
    property VerticalScrollBarVisible;
    property DefaultItem;
    property Fill;
    property GridCellAppearance;
    property Groups;
    property GroupsAppearance;
    property FullDaysAppearance;
    property Interaction;
    property Items;
    property Mode;
    property ModeSettings;
    property OrientationMode;
    property Positions;
    property PositionsAppearance;
    property Resources;
    property SelectionAppearance;
    property StretchScrollBars;
    property Stroke;
    property TimeLine;
    property TimeLineAppearance;
    property ItemsAppearance;
    property Version;
    property GlobalFont;
    property OnAfterDrawTimeLine;
    property OnAfterDrawGrid;
    property OnBeforeDrawTopNavigationButton;
    property OnBeforeDrawBottomNavigationButton;
    property OnAfterDrawTopNavigationButton;
    property OnAfterDrawBottomNavigationButton;
    property OnBeforeDrawCell;
    property OnAfterDrawCell;
    property OnBeforeDrawItemLink;
    property OnAfterDrawItemLink;
    property OnBeforeDrawCellVerticalLine;
    property OnAfterDrawCellVerticalLine;
    property OnBeforeDrawCellHorizontalLine;
    property OnAfterDrawCellHorizontalLine;
    property OnBeforeDrawCurrentTimeInTimeLine;
    property OnAfterDrawCurrentTimeInTimeLine;
    property OnBeforeDrawCurrentTimeInGrid;
    property OnAfterDrawCurrentTimeInGrid;
    property OnBeforeDrawSizeArea;
    property OnBeforeDrawMoveArea;
    property OnBeforeDrawDeleteArea;
    property OnAfterDrawSizeArea;
    property OnAfterDrawMoveArea;
    property OnAfterDrawDeleteArea;
    property OnBeforeDrawPosition;
    property OnAfterDrawPosition;
    property OnBeforeDrawGroup;
    property OnAfterDrawGroup;
    property OnBeforeDrawFullDay;
    property OnAfterDrawFullDay;
    property OnBeforeDrawTime;
    property OnAfterDrawTime;
    property OnBeforeDrawTimeStroke;
    property OnAfterDrawTimeStroke;
    property OnBeforeDrawItem;
    property OnAfterDrawItem;
    property OnBeforeDrawItemTitle;
    property OnAfterDrawItemTitle;
    property OnBeforeDrawPositionText;
    property OnGetPositionText;
    property OnAfterDrawPositionText;
    property OnBeforeDrawGroupText;
    property OnGetGroupText;
    property OnAfterDrawGroupText;
    property OnBeforeDrawTimeText;
    property OnBeforeNavigateToDateTime;
    property OnAfterNavigateToDateTime;
    property OnGetTimeText;
    property OnAfterDrawTimeText;
    property OnBeforeDrawItemText;
    property OnGetItemText;
    property OnGetItemHint;
    property OnAfterDrawItemText;
    property OnBeforeDrawItemTitleText;
    property OnGetItemTitleText;
    property OnAfterDrawItemTitleText;
    property OnSelectTime;
    property OnSelectingTime;
    property OnSelectCell;
    property OnSelectingCell;
    property OnIsItemDeletable;
    property OnIsDateTimeDisabled;
    property OnItemChanged;
    property OnAfterItemChanged;
    property OnIsDateTimeInActive;
    property OnItemAnchorClick;
    property OnItemRightClick;
    property OnItemClick;
    property OnItemDblClick;
    property OnItemPopupMenuPrepare;
    property OnItemTitleAnchorClick;
    property OnGroupAnchorClick;
    property OnPositionAnchorClick;
    property OnGroupDblClick;
    property OnFullDayDblClick;
    property OnPositionDblClick;
    property OnGroupClick;
    property OnFullDayClick;
    property OnGetPositionHint;
    property OnGetGroupHint;
    property OnGetFullDayHint;
    property OnPositionClick;
    property OnIsDateTimeSub;
    property OnHasDateTimeSub;
    property OnBeforeUpdateItem;
    property OnBeforeInsertItem;
    property OnAfterUpdateItem;
    property OnAfterInsertItem;
    property OnBeforeOpenInsertDialog;
    property OnAfterOpenInsertDialog;
    property OnBeforeOpenUpdateDialog;
    property OnAfterOpenUpdateDialog;
    property OnBeforeOpenInplaceEditor;
    property OnAfterOpenInplaceEditor;
    property OnCloseInplaceEditor;
    property OnCloseUpdateDialog;
    property OnCloseInsertDialog;
    property OnGetInplaceEditor;
    property OnBeforeSelectItem;
    property OnAfterSelectItem;
    property OnBeforeDeleteItem;
    property OnAfterDeleteItem;
    property OnBeforeMoveItem;
    property OnMoveItem;
    property OnAfterMoveItem;
    property OnBeforeSizeItem;
    property OnSizeItem;
    property OnAfterSizeItem;
    property OnHScroll;
    property OnVScroll;
    property OnBeforeDrawPositionEmptySpace;
    property OnAfterDrawPositionEmptySpace;
    property OnBeforeDrawGroupEmptySpace;
    property OnAfterDrawGroupEmptySpace;
    property OnBeforeDrawFullDayEmptySpace;
    property OnAfterDrawFullDayEmptySpace;
    property OnBeforeDrawItemHelper;
    property OnBeforeDrawItemHelperText;
    property OnAfterDrawItemHelper;
    property OnAfterDrawItemHelperText;
    property OnGetItemHelperText;
    property OnCustomContentPanelToItem;
    property OnItemToCustomContentPanel;
    property OnGetCustomContentPanel;
    property OnInitializeCustomContentPanel;
    property OnCustomizeItemRect;
  end;

function MakeCell(ACol, ARow: Integer): TTMSFNCPlannerCell;
function DateTimeInRangeEx(ADateTime: TDateTime; AStartDateTime, AEndDateTime: TDateTime; aInclusive: Boolean = True): Boolean;

implementation

uses
  {$IFNDEF WEBLIB}
  SysUtils,
  {$ENDIF}
  DateUtils, Math, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles
  {$IFDEF FMXLIB}
  ,FMX.Styles.Objects, FMX.Layouts
  {$ENDIF}
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,Rtti
  {$ENDIF}
  {$ENDIF}
  ;

{$R TMSFNCPlanner.res}

type
  TTMSFNCPlannerItemOpen = class(TTMSFNCPlannerItem);
  TTMSFNCPlannerResourceOpen = class(TTMSFNCPlannerResource);
  TTMSFNCPlannerGroupOpen = class(TTMSFNCPlannerGroup);

{$IFDEF LCLWEBLIB}
function CompareRes({$IFDEF LCLLIB}const {$ENDIF}Item1, Item2: TTMSFNCPlannerResourceDate): Integer;
begin
  Result := CompareDateTime(Item1.DateTime, Item2.DateTime);
end;

function CompareDT({$IFDEF LCLLIB}const {$ENDIF}Item1, Item2: TDateTime): Integer;
begin
  Result := CompareDateTime(Item1, Item2);
end;
{$ENDIF}

function DateTimeToMillisecondsEx(const ADateTime: TDateTime): Int64;
var
  LTimeStamp: TTimeStamp;
begin
  LTimeStamp := DateTimeToTimeStamp(ADateTime);
  Result := LTimeStamp.Date;
  Result := (Result * MSecsPerDay) + LTimeStamp.Time;
end;

function DateTimeInRangeEx(ADateTime: TDateTime; AStartDateTime, AEndDateTime: TDateTime; aInclusive: Boolean = True): Boolean;
var
  vs, ve: TValueRelationship;
begin
  vs := CompareDateTime(AStartDateTime, ADateTime);
  ve := CompareDateTime(AEndDateTime, ADateTime);
  if aInclusive then
    Result := ((vs = EqualsValue) or (vs = LessThanValue)) and ((ve = EqualsValue) or (ve = GreaterThanValue))
  else
    Result := ((vs = LessThanValue)) and ((ve = GreaterThanValue))
end;

function IsValidCell(ACol, ARow: Integer): Boolean;
begin
  Result := (ACol <> -1) and (ARow <> -1);
end;

function MakeCell(ACol, ARow: Integer): TTMSFNCPlannerCell;
begin
  Result.Col := ACol;
  Result.Row := ARow;
end;

function GetTickCountX: DWORD;
var
  h, m, s, ms: Word;
begin
  DecodeTime(Now, h, m, s, ms);
  Result := ms + s * 1000 + m * 60 * 1000 + h * 60 * 60 * 1000;
end;

function AnimateDouble(var Start, Stop: Double; Delta, Margin: Double): Boolean;
begin
  Result := true;
  if (Start > Stop - Margin) and (Start < Stop + Margin) then
  begin
    Start := Stop;
    Result := false;
  end
  else
  begin
    Delta := Max(Margin, Delta);
    if Start < Stop then
      Start := Start + Delta
    else
      Start := Start - Delta;
  end;
end;

{ TTMSFNCPlannerCustomToolBarPopup }

constructor TTMSFNCPlannerCustomToolBarPopup.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCCustomPlanner) then
      begin
        Planner := AOwner.Components[i] as TTMSFNCCustomPlanner;
        Planner.ToolBarPopup := Self;
        Break;
      end;
    end;
  end;
end;

procedure TTMSFNCPlannerCustomToolBarPopup.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = Planner) then
    Planner := nil;
end;

{ TTMSFNCCustomPlanner }

procedure TTMSFNCCustomPlanner.SelectCells(AStartCell, AEndCell: TTMSFNCPlannerCell);
begin
  inherited;
  HandleSelection(AStartCell, AEndCell);
end;

function TTMSFNCCustomPlanner.SelectedEndDateTime: TDateTime;
begin
  Result := CellToEndDateTime(Selection.EndCell);
end;

function TTMSFNCCustomPlanner.SelectedResource: Integer;
begin
  Result := PositionToResource(Selection.StartCell.Col);
end;

function TTMSFNCCustomPlanner.SelectedStartDateTime: TDateTime;
begin
  Result := CellToStartDatetime(Selection.StartCell);
end;

procedure TTMSFNCCustomPlanner.SelectItem(AItemIndex: Integer);
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    HandleSelectItem(Items[AItemIndex])
  else
    HandleSelectItem(nil);
end;

procedure TTMSFNCCustomPlanner.SelectItems(AItems: TTMSFNCPlannerItemArray);
var
  I: Integer;
  it: Integer;
begin
  for I := 0 to Length(AItems) - 1 do
  begin
    it := AItems[I];
    if (it >= 0) and (it <= Items.Count - 1) then
      HandleSelectItem(Items[it], True);
  end;
end;

procedure TTMSFNCCustomPlanner.SelectLinkedItems(AItem: TTMSFNCPlannerItem);
begin
  HandleSelectLinkedItems(AItem);
end;

procedure TTMSFNCCustomPlanner.UnselectAllItems;
begin
  SelectItem(nil);
end;

procedure TTMSFNCCustomPlanner.UnSelectItem(AItem: TTMSFNCPlannerItem);
begin
  HandleUnselectItem(AItem);
end;

procedure TTMSFNCCustomPlanner.SelectItem(AItem: TTMSFNCPlannerItem);
begin
  HandleSelectItem(AItem);
end;

procedure TTMSFNCCustomPlanner.Navigate(ACell: TTMSFNCPlannerCell; AForceScroll: Boolean = False; AIgnoreColumn: Boolean = False; AIgnoreRow: Boolean = False);
var
  hs, vs, rhs, rvs, vss, hss: Double;
  cw, ch: Double;
  hscroll, vscroll: TScrollbar;
  totalh, totalw: Double;
  cs, rs: Integer;
  toth, totw: Double;
  cl: TTMSFNCPlannerCell;
  cr: TRectF;
begin
  hscroll := HorizontalScrollBar;
  vscroll := VerticalScrollBar;

  if Assigned(hscroll) and Assigned(vscroll) then
  begin
    cr := GetContentRect;
    case OrientationMode of
      pomHorizontal:
      begin
        cw := cr.Bottom - cr.Top;
        ch := cr.Right - cr.Left;
        vs := GetHScrollValue;
        hs := GetVScrollValue;

        vss := vs;
        hss := hs;

        if ScrollMode = smCellScrolling then
        begin
          hss := GetVerticalScrollPosition;
          vss := GetHorizontalScrollPosition;
        end;
      end;
      pomVertical:
      begin
        cw := cr.Right -  cr.Left;
        ch := cr.Bottom - cr.Top;
        vs := GetVScrollValue;
        hs := GetHScrollValue;

        vss := vs;
        hss := hs;

        if ScrollMode = smCellScrolling then
        begin
          vss := GetVerticalScrollPosition;
          hss := GetHorizontalScrollPosition;
        end;
      end;
      else
      begin
        hs := 0;
        vs := 0;
        vss := 0;
        hss := 0;
        cw := 0;
        ch := 0;
      end;
    end;

    totalw := ColumnWidths[ACell.Col];
    totalh := RowHeights[ACell.Row];

    rvs := RowPositions[ACell.Row];
    if AForceScroll then
    begin
      case ScrollMode of
        smPixelScrolling: vs := rvs;
        smCellScrolling: vs := ACell.Row;
      end;
    end
    else
    begin
      if (rvs < vss) then
      begin
        case ScrollMode of
          smPixelScrolling: vs := rvs;
          smCellScrolling: vs := ACell.Row;
        end;
      end
      else
      begin
        case ScrollMode of
          smPixelScrolling:
          begin
            if (rvs + totalh > vss + ch) then
              vs := rvs + totalh - ch + 1;
          end;
          smCellScrolling:
          begin
            while (rvs + totalh > vss + ch) do
            begin
              vs := vs + 1;
              cl := MakeCell(ACell.Col, Round(vs));
              rs := 1;
              if rs > 0 then
                toth := RowPositions[cl.Row + rs - 1] - RowPositions[cl.Row]
              else
              begin
                rs := 1;
                toth := RowHeights[cl.Row];
              end;

              vs := vs + rs - 1;
              vss := vss + toth;
            end;
          end;
        end;
      end;
    end;

    rhs := ColumnPositions[ACell.Col];
    if AForceScroll then
    begin
      case ScrollMode of
        smPixelScrolling: hs := rhs;
        smCellScrolling: hs := ACell.Col;
      end;
    end
    else
    begin
      if (rhs < hss) then
      begin
        case ScrollMode of
          smPixelScrolling: hs := rhs;
          smCellScrolling: hs := ACell.Col;
        end;
      end
      else
      begin
        case ScrollMode of
          smPixelScrolling:
          begin
            if (rhs + totalw > hss + cw) then
              hs := rhs + totalw - cw + 1;
          end;
          smCellScrolling:
          begin
            while (rhs + totalw > hss + cw) do
            begin
              hs := hs + 1;
              cl := MakeCell(Round(hs), ACell.Row);
              cs := 1;
              if cs > 0 then
                totw := ColumnPositions[cl.Col] - ColumnPositions[cl.Col + cs - 1]
              else
              begin
                cs := 1;
                totw := ColumnWidths[cl.Col];
              end;

              hs := hs + cs - 1;
              hss := hss + totw;
            end;
          end;
        end;
      end;
    end;

    case OrientationMode of
      pomHorizontal:
      begin
        if AIgnoreRow then
          Scroll(GetHorizontalScrollPosition, hs)
        else if AIgnoreColumn then
          Scroll(vs, GetVerticalScrollPosition)
        else
          Scroll(vs, hs);
      end;
      pomVertical:
      begin
        if AIgnoreColumn then
          Scroll(GetHorizontalScrollPosition, vs)
        else if AIgnoreRow then
          Scroll(hs, GetVerticalScrollPosition)
        else
          Scroll(hs, vs);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.NavigateToNextDateTime(ADateTime: TDateTime = -1);
var
  stdt: TDateTime;
begin
  BeginUpdate;
  SaveScrollPosition;
  stdt := ModeSettings.StartTime;

  if ADateTime <> -1 then
    ModeSettings.StartTime := ADateTime
  else
    ModeSettings.StartTime := GetNextDateTime;

  ModeSettings.EndTime := ModeSettings.EndTime + (ModeSettings.StartTime - stdt);
  RestoreScrollPosition;
  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.NavigateToPreviousDateTime(ADateTime: TDateTime = -1);
var
  stdt: TDateTime;
begin
  BeginUpdate;
  SaveScrollPosition;
  stdt := ModeSettings.StartTime;

  if ADateTime <> -1 then
    ModeSettings.StartTime := ADateTime
  else
    ModeSettings.StartTime := GetPreviousDateTime;

  ModeSettings.EndTime := ModeSettings.EndTime + (ModeSettings.StartTime - stdt);
  RestoreScrollPosition;
  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.NeedsConflictsUpdate(AItem: TTMSFNCPlannerItem = nil);
var
  I: Integer;
  pos: Integer;
  lst: TTMSFNCPlannerIntegerList;
begin
  if csDestroying in ComponentState then
    Exit;

  if UpdateCount > 0 then
  begin
    FNeedsConflictsUpdate := True;
    Exit;
  end;

  if Assigned(AItem) then
  begin
    lst := TTMSFNCPlannerItemOpen(AItem).OldPositionsList;
    for I := 0 to lst.Count - 1 do
    begin
      pos := lst[I];
      if (pos >= 0) and (pos <= FConflicts.Count - 1) then
        FConflicts[pos].NeedsConflictsUpdate := True;
    end;

    lst := TTMSFNCPlannerItemOpen(AItem).PositionsList;
    for I := 0 to lst.Count - 1 do
    begin
      pos := lst[I];
      if (pos >= 0) and (pos <= FConflicts.Count - 1) then
        FConflicts[pos].NeedsConflictsUpdate := True;
    end;
  end
  else
  begin
    for I := 0 to FConflicts.Count - 1 do
      FConflicts[I].NeedsConflictsUpdate := True;
  end;
end;

procedure TTMSFNCCustomPlanner.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (AComponent = FItemPopup) and (Operation = opRemove) then
    FItemPopup := nil;

  if (AComponent = FBitmapContainer) and (Operation = opRemove) then
    FBitmapContainer := nil;

  if (AComponent = FAdapter) and (Operation = opRemove) then
    FAdapter := nil;

  if (AComponent = FItemEditor) and (Operation = opRemove) then
    FItemEditor := nil;

  if (AComponent = FToolBarPopup) and (Operation = opRemove) then
    FToolBarPopup := nil;
end;

procedure TTMSFNCCustomPlanner.OpenEditingDialog(AStartTime, AEndTime: TDateTime; AResource: Integer; ATitle, AText: String; AUpdateItem: Integer = -1; ACustomParent: TTMSFNCPlannerCustomParent = nil);
var
  p: TTMSFNCPlannerEditingDialog;
  s: TStringList;
  fr, fd: Boolean;
  it, itf: TTMSFNCPlannerItem;
  d: Boolean;
  itd: TTMSFNCPlannerItem;
  {$IFDEF CMNWEBLIB}
  {$IFDEF WEBLIB}
  prnt: TControl;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  prnt: TWinControl;
  {$ENDIF}
  {$ENDIF}

  procedure InitializeEditorForm;
  begin
    FEditorForm.PlannerStartTime := AStartTime;
    FEditorForm.PlannerEndTime := AEndTime;
    FEditorForm.PlannerResource := AResource;
    FEditorForm.PlannerTitle := ATitle;
    FEditorForm.PlannerText := AText;
    FEditorForm.PlannerFullDay := False;

    itf := nil;
    if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    begin
      itf := Items[FUpdateItem];
      FEditorForm.PlannerFullDay := itf.FullDay;
    end;

    FEditorForm.FPlannerItem := itf;

    if not Assigned(itf) then
      FEditorForm.FPlannerMode := pemInsert
    else
      FEditorForm.FPlannerMode := pemUpdate;

    FEditorForm.FPlanner := Self;

    FEditorForm.Initialize;
  end;
begin
  FInsertResource := AResource;
  FUpdateItem := AUpdateItem;

  if Assigned(CustomItemEditorForm) then
  begin
    {$IFDEF WEBLIB}
    FEditorForm := CustomItemEditorForm.CreateNew(procedure(AForm: TObject)
    begin
      InitializeEditorForm;
    end);
    FEditorForm.Caption := 'Planner Item Editor';
    FEditorForm.Popup := True;
    FEditorForm.Border := fbDialog;
    {$ELSE}
    FEditorForm := CustomItemEditorForm.Create(Self);
    FEditorForm.Caption := 'Planner Item Editor';

    InitializeEditorForm;
    {$ENDIF}

    FEditorDialogActive := True;

    FEditorForm.ShowModal;
  end
  else
  begin
    p := GetEditingDialog(FUpdateItem);

    FDialogStartDate := Int(AStartTime);
    if Assigned(p.StartDateEdit) then
      p.StartDateEdit.Date := FDialogStartDate;

    FDialogEndDate := Int(AEndTime);
    if Assigned(p.EndDateEdit) then
      p.EndDateEdit.Date := FDialogEndDate;

    FDialogStartTime := Frac(AStartTime);
    if Assigned(p.StartTimeEdit) then
      p.StartTimeEdit.Time := FDialogStartTime;

    FDialogEndTime := Frac(AEndTime);
    if Assigned(p.EndTimeEdit) then
      p.EndTimeEdit.Time := FDialogEndTime;

    if Assigned(p.TitleEdit) then
      p.TitleEdit.Text := ATitle;

    if Assigned(p.TextMemo) then
      p.TextMemo.Text := AText;

    fr := false;
    fd := false;
    if (AUpdateItem >= 0) and (AUpdateItem <= Items.Count - 1) then
    begin
      fr := Items[AUpdateItem].FixedResource;
      fd := Items[AUpdateItem].FullDay;
    end;

    if Assigned(p.FullDayCheckBox) then
    begin
      {$IFDEF FMXLIB}
      p.FullDayCheckBox.IsChecked := fd;
      {$ELSE}
      p.FullDayCheckBox.Checked := fd;
      {$ENDIF}
    end;

    if Assigned(p.ResourcesComboBox) then
    begin
      {$IFDEF CMNWEBLIB}
      prnt := p.ResourcesComboBox.Parent;
      if not Assigned(ACustomParent) then
        p.ResourcesComboBox.Parent := Self
      else
        p.ResourcesComboBox.Parent := ACustomParent;
      {$ENDIF}
      p.ResourcesComboBox.Clear;
      s := GetResources;
      p.ResourcesComboBox.Items.Assign(s);
      p.ResourcesComboBox.ItemIndex := PositionToResource(AResource);
      p.ResourcesComboBox.Visible := GetResourcesAvailable and not fr;
      {$IFDEF CMNWEBLIB}
      p.ResourcesComboBox.Parent := prnt;
      {$ENDIF}
      s.Free;
    end;

    if Assigned(p.ResourceLabel) then
      p.ResourceLabel.Visible := GetResourcesAvailable and not fr;

    if Assigned(p.TitleLabel) then
    begin
      {$IFDEF FMXLIB}
      if Assigned(p.ResourceLabel) and p.ResourceLabel.Visible then
        p.TitleLabel.Position.Y := p.ResourceLabel.Position.Y + p.ResourceLabel.Height + 15
      else if Assigned(p.StartTimeLabel) then
        p.TitleLabel.Position.Y := p.EndTimeLabel.Position.Y + p.EndTimeLabel.Height + 15;

      if Assigned(p.TitleEdit) then
        p.TitleEdit.Position.Y := p.TitleLabel.Position.Y + Int((p.TitleLabel.Height - p.TitleEdit.Height) / 2);

      if Assigned(p.TextLabel) then
        p.TextLabel.Position.Y := p.TitleLabel.Position.Y + p.TitleLabel.Height + 15;

      if Assigned(p.TextMemo) then
        p.TextMemo.Margins.Top := p.TextLabel.Position.Y + p.TextLabel.Height + 15;

      if Assigned(p.FullDayCheckBox) then
        p.FullDayCheckBox.Position.Y := p.TextLabel.Position.Y + Int((p.TextLabel.Height - p.FullDayCheckBox.Height) / 2);
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      if Assigned(p.ResourceLabel) and p.ResourceLabel.Visible then
        p.TitleLabel.Top := p.ResourceLabel.Top + p.ResourceLabel.Height + 15
      else if Assigned(p.StartTimeLabel) then
        p.TitleLabel.Top := p.EndTimeLabel.Top + p.EndTimeLabel.Height + 15;

      if Assigned(p.TitleEdit) then
        p.TitleEdit.Top := p.TitleLabel.Top + (p.TitleLabel.Height - p.TitleEdit.Height) div 2;

      if Assigned(p.TextLabel) then
        p.TextLabel.Top := p.TitleLabel.Top + p.TitleLabel.Height + 15;

      {$IFNDEF LCLLIB}
      if Assigned(p.TextMemo) then
        p.TextMemo.Margins.Top := p.TextLabel.Top + p.TextLabel.Height + 15;
      {$ENDIF}
      {$IFDEF LCLLIB}
      if Assigned(p.TextMemo) then
        p.TextMemo.BorderSpacing.Top := p.TextLabel.Top + p.TextLabel.Height + 15;
      {$ENDIF}

      if Assigned(p.FullDayCheckBox) then
        p.FullDayCheckBox.Top := p.TextLabel.Top + (p.TextLabel.Height - p.FullDayCheckBox.Height) div 2;
      {$ENDIF}
    end;

    if Assigned(p.ButtonRemove) then
    begin
      if (AUpdateItem >= 0) and (AUpdateItem <= Items.Count - 1) then
      begin
        itd := Items[AUpdateItem];
        d := itd.Deletable;
        DoIsItemDeletable(itd, d);
        p.ButtonRemove.Visible := d;
      end;
    end;

    if Assigned(p.Background) and not Assigned(ACustomParent) then
      p.Background.Parent := Self;

    if Assigned(p.Panel) then
    begin
      if Assigned(ACustomParent) then
        p.Panel.Parent := ACustomParent
      else
        p.Panel.Parent := Self;
    end;

    if p.CustomContentPanel and Assigned(p.ContentPanel) then
    begin
      it := nil;
      if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
        it := Items[FUpdateItem];

      DoInitializeCustomContentPanel(it, p.ContentPanel);
      DoItemToCustomContentPanel(it, p.ContentPanel);
    end;

    if Assigned(p.StartDateEdit) then
      p.StartDateEdit.Date := p.StartDateEdit.Date;

    if Assigned(p.EndDateEdit) then
      p.EndDateEdit.Date := p.EndDateEdit.Date;

    if Assigned(p.StartTimeEdit) then
      p.StartTimeEdit.Time := p.StartTimeEdit.Time;

    if Assigned(p.EndTimeEdit) then
      p.EndTimeEdit.Time := p.EndTimeEdit.Time;

    if Assigned(p.StartDateEdit) and not p.CustomContentPanel and p.StartDateEdit.CanFocus and not Assigned(ACustomParent) then
      p.StartDateEdit.SetFocus;

    if Assigned(p.Panel) then
      p.Panel.Invalidate;

    FEditorDialogActive := True;
  end;
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawGroup) then
    OnAfterDrawGroup(Self, AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace);
begin
  if Assigned(OnAfterDrawGroupEmptySpace) then
    OnAfterDrawGroupEmptySpace(Self, AGraphics, ARect, ASpace);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawFullDay) then
    OnAfterDrawFullDay(Self, AGraphics, ARect, AFullDay, AStartPosition, AEndPosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawFullDayEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace);
begin
  if Assigned(OnAfterDrawFullDayEmptySpace) then
    OnAfterDrawFullDayEmptySpace(Self, AGraphics, ARect, ASpace);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String);
begin
  if Assigned(OnAfterDrawGroupText) then
    OnAfterDrawGroupText(Self, AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterDrawItem) then
    OnAfterDrawItem(Self, AGraphics, ARect, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemHelper(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem;
  AIsStartTime: Boolean; AValue: TDateTime);
begin
  if Assigned(OnAfterDrawItemHelper) then
    OnAfterDrawItemHelper(Self, AGraphics, ARect, AItem, AIsStartTime, AValue);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemLink(AGraphics: TTMSFNCGraphics; AItem,
  ALinkedItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterDrawItemLink) then
    OnAfterDrawItemLink(Self, AGraphics, AItem, ALinkedItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemHelperText(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime;
  AText: String);
begin
  if Assigned(OnAfterDrawItemHelperText) then
    OnAfterDrawItemHelperText(Self, AGraphics, ARect, AItem, AIsStartTime, AValue, AText);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawMoveArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterDrawMoveArea) then
    OnAfterDrawMoveArea(Self, AGraphics, ARect, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawSizeArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterDrawSizeArea) then
    OnAfterDrawSizeArea(Self, AGraphics, ARect, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String);
begin
  if Assigned(OnAfterDrawItemText) then
    OnAfterDrawItemText(Self, AGraphics, ARect, AItem, AText);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemTitleText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String);
begin
  if Assigned(OnAfterDrawItemTitleText) then
    OnAfterDrawItemTitleText(Self, AGraphics, ARect, AItem, ATitle);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPlanner.ApplyInplaceEditorStyleLookup(Sender: TObject);
var
  obj: TFmxObject;
begin
  if Sender = FInplaceEditor then
  begin
    obj := FInplaceEditor.FindStyleResource('background');
    if Assigned(obj) and (obj is TActiveStyleObject) then
      TActiveStyleObject(obj).Source := nil;
  end;
end;

procedure TTMSFNCCustomPlanner.DoAbsoluteChanged;
begin
  inherited;
  if Parent is TScaledLayout then
    UpdatePlannerCache;
end;
{$ENDIF}

procedure TTMSFNCCustomPlanner.DoAfterDrawBottomNavigationButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton;
  AButtonState: TTMSFNCPlannerNavigationButtonState);
begin
  if Assigned(OnAfterDrawBottomNavigationButton) then
    OnAfterDrawBottomNavigationButton(Self, AGraphics, ARect, AButton, AButtonState);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawCell) then
    OnAfterDrawCell(Self, AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawCellHorizontalLine(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime;
  APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawCellHorizontalLine) then
    OnAfterDrawCellHorizontalLine(Self, AGraphics, ARect, ASubUnit, ACol, ARow, ADateTime, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawCellVerticalLine(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime;
  APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawCellVerticalLine) then
    OnAfterDrawCellVerticalLine(Self, AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AValue: Double; ACurrentTime: TDateTime);
begin
  if Assigned(OnAfterDrawCurrentTimeInGrid) then
    OnAfterDrawCurrentTimeInGrid(Self, AGraphics, ARect, AValue, ACurrentTime);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawCurrentTimeInTimeLine(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind;
  AValue: Double; ACurrentTime: TDateTime);
begin
  if Assigned(OnAfterDrawCurrentTimeInTimeLine) then
    OnAfterDrawCurrentTimeInTimeLine(Self, AGraphics, ARect, AKind, AValue, ACurrentTime);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawDeleteArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterDrawDeleteArea) then
    OnAfterDrawDeleteArea(Self, AGraphics, ARect, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawPosition(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawPosition) then
    OnAfterDrawPosition(Self, AGraphics, ARect, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawPositionEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace);
begin
  if Assigned(OnAfterDrawPositionEmptySpace) then
    OnAfterDrawPositionEmptySpace(Self, AGraphics, ARect, ASpace);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawPositionText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String);
begin
  if Assigned(OnAfterDrawPositionText) then
    OnAfterDrawPositionText(Self, AGraphics, ARect, APosition, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawTime) then
    OnAfterDrawTime(Self, AGraphics, ARect, AValue, ARow, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawTimeStroke(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AValue: Double; ASubUnit: Boolean; ARow: Integer;
  AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnAfterDrawTimeStroke) then
    OnAfterDrawTimeStroke(Self, AGraphics, ARect, AValue, ASubUnit, ARow, AKind);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawTimeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind;
  AText: string);
begin
  if Assigned(OnAfterDrawTimeText) then
    OnAfterDrawTimeText(Self, AGraphics, ARect, AValue, ARow, ASubUnit, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawTopNavigationButton(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton;
  AButtonState: TTMSFNCPlannerNavigationButtonState);
begin
  if Assigned(OnAfterDrawTopNavigationButton) then
    OnAfterDrawTopNavigationButton(Self, AGraphics, ARect, AButton, AButtonState);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawItemTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItem: TTMSFNCPlannerItem; ATitle: String);
begin
  if Assigned(OnAfterDrawItemTitle) then
    OnAfterDrawItemTitle(Self, AGraphics, ARect, AItem, ATitle);
end;

procedure TTMSFNCCustomPlanner.DoAfterInsertItem(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(Adapter) then
    Adapter.InsertItem(AItem);

  if Assigned(OnAfterInsertItem) then
    OnAfterInsertItem(Self, AStartTime, AEndTime, APosition, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterOpenInplaceEditor(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; AInplaceEditor: TTMSFNCPlannerInplaceEditor; AInplaceEditorRect: TRectF);
begin
  if Assigned(OnAfterOpenInplaceEditor) then
    OnAfterOpenInplaceEditor(Self, AStartTime, AEndTime, APosition, AItem, AInplaceEditor, AInplaceEditorRect);
end;

procedure TTMSFNCCustomPlanner.DoAfterOpenInsertDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer);
begin
  if Assigned(OnAfterOpenInsertDialog) then
    OnAfterOpenInsertDialog(Self, AStartTime, AEndTime, APosition);
end;

procedure TTMSFNCCustomPlanner.DoAfterOpenUpdateDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterOpenUpdateDialog) then
    OnAfterOpenUpdateDialog(Self, AStartTime, AEndTime, APosition, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterUpdateItem(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnAfterUpdateItem) then
    OnAfterUpdateItem(Self, AStartTime, AEndTime, APosition, AItem);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawGroup) then
    OnBeforeDrawGroup(Self, AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerGroupEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawGroupEmptySpace) then
    OnBeforeDrawGroupEmptySpace(Self, AGraphics, ARect, ASpace, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AFullDay, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFullDay) then
    OnBeforeDrawFullDay(Self, AGraphics, ARect, AFullDay, AStartPosition, AEndPosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawFullDayEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerFullDayEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFullDayEmptySpace) then
    OnBeforeDrawFullDayEmptySpace(Self, AGraphics, ARect, ASpace, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawGroupText) then
    OnBeforeDrawGroupText(Self, AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind, AText, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItem: TTMSFNCPlannerItem; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItem) then
    OnBeforeDrawItem(Self, AGraphics, ARect, AItem, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemHelper(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem;
  AIsStartTime: Boolean; AValue: TDateTime; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItemHelper) then
    OnBeforeDrawItemHelper(Self, AGraphics, ARect, AItem, AIsStartTime, AValue, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemHelperText(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AItem: TTMSFNCPlannerItem; AIsStartTime: Boolean; AValue: TDateTime; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemHelperText) then
    OnBeforeDrawItemHelperText(Self, AGraphics, ARect, AItem, AIsStartTime, AValue, AText, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemLink(AGraphics: TTMSFNCGraphics; AItem,
  ALinkedItem: TTMSFNCPlannerItem; var ACanDrawLink: Boolean);
begin
  if Assigned(OnBeforeDrawItemLink) then
    OnBeforeDrawItemLink(Self, AGraphics, AItem, ALinkedItem, ACanDrawLink);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawMoveArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawMoveArea) then
    OnBeforeDrawMoveArea(Self, AGraphics, ARect, AItem, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawSizeArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSizeArea) then
    OnBeforeDrawSizeArea(Self, AGraphics, ARect, AItem, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemText) then
    OnBeforeDrawItemText(Self, AGraphics, ARect, AItem, AText, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemTitleText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemTitleText) then
    OnBeforeDrawItemTitleText(Self, AGraphics, ARect, AItem, ATitle, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDeleteItem(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerItemDeleteMode;
  var ACanDelete: Boolean);
begin
  if Assigned(OnBeforeDeleteItem) then
    OnBeforeDeleteItem(Self, AItem, AMode, ACanDelete);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawBottomNavigationButton(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton;
  AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBottomNavigationButton) then
    OnBeforeDrawBottomNavigationButton(Self, AGraphics, ARect, AButton, AButtonState, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime: TDateTime; AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCell) then
    OnBeforeDrawCell(Self, AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawCellHorizontalLine(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASubUnit: Boolean; ACol, ARow: Integer; ADateTime: TDateTime;
  APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCellHorizontalLine) then
    OnBeforeDrawCellHorizontalLine(Self, AGraphics, ARect, ASubUnit, ACol, ARow, ADateTime, APosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawCellVerticalLine(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime;
  APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCellVerticalLine) then
    OnBeforeDrawCellVerticalLine(Self, AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AValue: Double; ACurrentTime: TDateTime; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCurrentTimeInGrid) then
    OnBeforeDrawCurrentTimeInGrid(Self, AGraphics, ARect, AValue, ACurrentTime, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawCurrentTimeInTimeLine(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AKind: TTMSFNCPlannerCacheItemKind;
  AValue: Double; ACurrentTime: TDateTime; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawCurrentTimeInTimeLine) then
    OnBeforeDrawCurrentTimeInTimeLine(Self, AGraphics, ARect, AKind, AValue, ACurrentTime, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawDeleteArea(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDeleteArea) then
    OnBeforeDrawDeleteArea(Self, AGraphics, ARect, AItem, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawPosition(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPosition) then
    OnBeforeDrawPosition(Self, AGraphics, ARect, APosition, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawPositionEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCPlannerPositionEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPositionEmptySpace) then
    OnBeforeDrawPositionEmptySpace(Self, AGraphics, ARect, ASpace, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawPositionText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawPositionText) then
    OnBeforeDrawPositionText(Self, AGraphics, ARect, APosition, AKind, AText, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTime) then
    OnBeforeDrawTime(Self, AGraphics, ARect, AValue, ARow, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawTimeStroke(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AValue: Double; ASubUnit: Boolean; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTimeStroke) then
    OnBeforeDrawTimeStroke(Self, AGraphics, ARect, AValue, ASubUnit, ARow, AKind, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawTimeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AValue: Double; ARow: Integer; ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind;
  AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawTimeText) then
    OnBeforeDrawTimeText(Self, AGraphics, ARect, AValue, ARow, ASubUnit, AKind, AText, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawTopNavigationButton(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AButton: TTMSFNCPlannerNavigationButton;
  AButtonState: TTMSFNCPlannerNavigationButtonState; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTopNavigationButton) then
    OnBeforeDrawTopNavigationButton(Self, AGraphics, ARect, AButton, AButtonState, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeDrawItemTitle(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItem: TTMSFNCPlannerItem; ATitle: String; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItemTitle) then
    OnBeforeDrawItemTitle(Self, AGraphics, ARect, AItem, ATitle, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPlanner.DoBeforeInsertItem(AStartTime,
  AEndTime: TDateTime; APosition: Integer; var ATitle: String; var AText: String; var ACanInsert: Boolean);
begin
  if Assigned(OnBeforeInsertItem) then
    OnBeforeInsertItem(Self, AStartTime, AEndTime, APosition, ATitle, AText, ACanInsert);
end;

procedure TTMSFNCCustomPlanner.DoBeforeMoveItem(AItem: TTMSFNCPlannerItem;
  var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanMove: Boolean);
begin
  if Assigned(OnBeforeMoveItem) then
    OnBeforeMoveItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition, ACanMove);
end;

procedure TTMSFNCCustomPlanner.DoBeforeNavigateToDateTime(ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime: TDateTime; var ANewDateTime: TDateTime; var AAllow: Boolean);
begin
  if Assigned(OnBeforeNavigateToDateTime) then
    OnBeforeNavigateToDateTime(Self, ADirection, ACurrentDateTime, ANewDateTime, AAllow);
end;

procedure TTMSFNCCustomPlanner.DoBeforeOpenInplaceEditor(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ACanOpen: Boolean);
begin
  if Assigned(OnBeforeOpenInplaceEditor) then
    OnBeforeOpenInplaceEditor(Self, AStartTime, AEndTime, APosition, AItem, ACanOpen);
end;

procedure TTMSFNCCustomPlanner.DoBeforeOpenInsertDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer; var ACanOpen: Boolean);
begin
  if Assigned(OnBeforeOpenInsertDialog) then
    OnBeforeOpenInsertDialog(Self, AStartTime, AEndTime, APosition, ACanOpen);
end;

procedure TTMSFNCCustomPlanner.DoBeforeOpenUpdateDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem;
  var ACanOpen: Boolean);
begin
  if Assigned(OnBeforeOpenUpdateDialog) then
    OnBeforeOpenUpdateDialog(Self, AStartTime, AEndTime, APosition, AItem, ACanOpen);
end;

procedure TTMSFNCCustomPlanner.DoBeforeSelectItem(AItem: TTMSFNCPlannerItem;
  var ACanSelect: Boolean);
begin
  if Assigned(OnBeforeSelectItem) then
    OnBeforeSelectItem(Self, AItem, ACanSelect);
end;

procedure TTMSFNCCustomPlanner.DoBeforeSizeItem(AItem: TTMSFNCPlannerItem;
  var ANewStartTime: TDateTime; var ANewEndTime: TDateTime; var ANewPosition: Integer; var ACanSize: Boolean);
begin
  if Assigned(OnBeforeSizeItem) then
    OnBeforeSizeItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition, ACanSize);
end;

procedure TTMSFNCCustomPlanner.DoBeforeUpdateItem(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; var ATitle: String; var AText: String;
  var ACanUpdate: Boolean);
begin
  if Assigned(OnBeforeUpdateItem) then
    OnBeforeUpdateItem(Self, AStartTime, AEndTime, APosition, AItem, ATitle, AText, ACanUpdate);
end;

procedure TTMSFNCCustomPlanner.DoCloseInplaceEditor(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean);
begin
  if Assigned(OnCloseInplaceEditor) then
    OnCloseInplaceEditor(Self, AStartTime, AEndTime, APosition, AItem, ACancelled, ACanClose);
end;

procedure TTMSFNCCustomPlanner.DoCloseInsertDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean; var ACanClose: Boolean);
begin
  if Assigned(OnCloseInsertDialog) then
    OnCloseInsertDialog(Self, AStartTime, AEndTime, APosition, AItem, ACancelled, ACanClose);
end;

procedure TTMSFNCCustomPlanner.DoCloseUpdateDialog(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem; ACancelled: Boolean;
  var ACanClose: Boolean);
begin
  if Assigned(OnCloseUpdateDialog) then
    OnCloseUpdateDialog(Self, AStartTime, AEndTime, APosition, AItem, ACancelled, ACanClose);
end;

procedure TTMSFNCCustomPlanner.DoCustomContentPanelToItem(
  AContentPanel: TTMSFNCPlannerEditingDialogContentPanel; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(ItemEditor) then
    ItemEditor.CustomContentPanelToItem(AContentPanel, AItem);

  if Assigned(OnCustomContentPanelToItem) then
    OnCustomContentPanelToItem(Self, AContentPanel, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterDeleteItem(AItemIndex: Integer; ADBKey: String; AMode: TTMSFNCPlannerItemDeleteMode);
begin
  if Assigned(OnAfterDeleteItem) then
    OnAfterDeleteItem(Self, AItemIndex, ADBKey, AMode);
end;

procedure TTMSFNCCustomPlanner.DoGetCustomContentPanel(
  AItem: TTMSFNCPlannerItem; var AContentPanel: TTMSFNCPlannerEditingDialogContentPanel);
begin
  if Assigned(ItemEditor) then
  begin
    if not ItemEditor.Created then
    begin
      ItemEditor.CreateCustomContentPanel;
      ItemEditor.Created := True;
    end;
    ItemEditor.GetCustomContentPanel(AItem, AContentPanel);
  end;

  if Assigned(OnGetCustomContentPanel) then
    OnGetCustomContentPanel(Self, AItem, AContentPanel);
end;

procedure TTMSFNCCustomPlanner.DoInitializeCustomContentPanel(
  AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel);
begin
  if Assigned(ItemEditor) then
  begin
    if not ItemEditor.Initialized then
    begin
      ItemEditor.InitializeCustomContentPanel;
      ItemEditor.Initialized := True;
    end;
  end;

  if Assigned(OnInitializeCustomContentPanel) then
    OnInitializeCustomContentPanel(Self, AItem, AContentPanel);
end;

procedure TTMSFNCCustomPlanner.DoGetGroupText(AGroup: Integer;
  AKind: TTMSFNCPlannerCacheItemKind; var AText: String);
begin
  if Assigned(OnGetGroupText) then
    OnGetGroupText(Self, AGroup, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoGetInplaceEditor(AStartTime,
  AEndTime: TDateTime; APosition: Integer; AItem: TTMSFNCPlannerItem;
  var AInplaceEditorClass: TTMSFNCPlannerInplaceEditorClass);
begin
  if Assigned(OnGetInplaceEditor) then
    OnGetInplaceEditor(Self, AStartTime, AEndTime, APosition, AItem, AInplaceEditorClass);
end;

procedure TTMSFNCCustomPlanner.DoGetItemHelperText(AItem: TTMSFNCPlannerItem;
  AIsStartTime: Boolean; AValue: TDateTime; var AText: String);
begin
  if Assigned(OnGetItemHelperText) then
    OnGetItemHelperText(Self, AItem, AIsStartTime, AValue, AText);
end;

procedure TTMSFNCCustomPlanner.DoGetItemHint(AItem: TTMSFNCPlannerItem; var AHint: string);
begin
  if Assigned(OnGetItemHint) then
    OnGetItemHint(Self, AItem, AHint);
end;

procedure TTMSFNCCustomPlanner.DoGetPositionHint(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
begin
  if Assigned(OnGetPositionHint) then
    OnGetPositionHint(Self, APosition, AKind, AHint);
end;

procedure TTMSFNCCustomPlanner.DoGetGroupHint(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
begin
  if Assigned(OnGetGroupHint) then
    OnGetGroupHint(Self, AGroupIndex, AKind, AHint);
end;

procedure TTMSFNCCustomPlanner.DoGetFullDayHint(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind; var AHint: string);
begin
  if Assigned(OnGetFullDayHint) then
    OnGetFullDayHint(Self, AFullDayIndex, AKind, AHint);
end;

procedure TTMSFNCCustomPlanner.DoGetItemText(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var AText: String);
begin
  if Assigned(OnGetItemText) then
    OnGetItemText(Self, AItem, AMode, AText);
end;

procedure TTMSFNCCustomPlanner.DoGetItemTitleText(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerGetTextMode; var ATitle: String);
begin
  if Assigned(OnGetItemTitleText) then
    OnGetItemTitleText(Self, AItem, AMode, ATitle);
end;

procedure TTMSFNCCustomPlanner.DoGetPositionText(APosition: Integer;
  AKind: TTMSFNCPlannerCacheItemKind; var AText: String);
begin
  if Assigned(OnGetPositionText) then
    OnGetPositionText(Self, APosition, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoGetTimeText(AValue: Double; ARow: Integer;
  ASubUnit: Boolean; AKind: TTMSFNCPlannerCacheItemKind; var AText: String);
begin
  if Assigned(OnGetTimeText) then
    OnGetTimeText(Self, AValue, ARow, ASubUnit, AKind, AText);
end;

procedure TTMSFNCCustomPlanner.DoHScroll(APosition: Single);
begin
  if Assigned(OnHScroll) then
    OnHScroll(Self, APosition);
end;

procedure TTMSFNCCustomPlanner.CloseInplaceEditor(ACancel: Boolean; AFlagClose: Boolean = False);
var
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  AContext: TRttiContext;
  rt: TRttiType;
  prop: TRttiProperty;
  {$ENDIF}
  {$ENDIF}
  str: String;
  it: TTMSFNCPlannerItem;
  sta, ste: TDateTime;
  p: Integer;
  b, c: Boolean;
  t, n: String;
begin
  if FClosing then
  begin
    if AFlagClose then
      FInplaceEditorClosed := True;
    Exit;
  end;

  FClosing := True;
  if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
  begin
    it := Items[FUpdateItem];
    sta := it.StartTime;
    ste := it.EndTime;
    t := it.Title;
    n := it.Text;
    p := ItemToStartCell(it).Col;

    if not ACancel then
    begin
      str := '';
      {$IFNDEF LCLLIB}
      {$IFNDEF WEBLIB}
      AContext := TRttiContext.Create;
      try
        rt := AContext.GetType(FInplaceEditor.ClassInfo);
        prop := rt.GetProperty('Text');
        if Assigned(Prop) then
          str := prop.GetValue(FInplaceEditor).AsString;
      finally
        AContext.Free;
      end;
      {$ENDIF}
      {$ENDIF}
      {$IFDEF LCLLIB}
      if FInplaceEditor is TEdit then
        str := (FInplaceEditor as TEdit).Text;
      if FInplaceEditor is TComboBox then
        str := (FInplaceEditor as TComboBox).Text;
      if FInplaceEditor is TMemo then
        str := (FInplaceEditor as TMemo).Text;
      {$ENDIF}
      {$IFDEF WEBLIB}
      if FInplaceEditor is TEdit then
        str := (FInplaceEditor as TEdit).Text;
      if FInplaceEditor is TComboBox then
        str := (FInplaceEditor as TComboBox).Text;
      if FInplaceEditor is TMemo then
        str := (FInplaceEditor as TMemo).Text;
      {$ENDIF}

      case Interaction.InplaceEditorMode of
        piemText, piemItem: n := str;
        piemTitle: t := str;
      end;

      b := True;
      DoBeforeUpdateItem(sta, ste, p, it, t, n, b);
      if b then
      begin
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := True;
        CallBeforeUpdateLinkedItems(it, sta - it.StartTime, ste - it.EndTime, PositionToResource(p) - it.Resource);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := False;
        it := AddOrUpdateItem(PositionToResource(p), sta, ste, t, n, FUpdateItem);
        DoAfterUpdateItem(sta, ste, p, it);
        DoItemChanged(it);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := True;
        CallAfterUpdateLinkedItems(it);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := False;
      end;
    end;

    c := True;
    DoCloseInplaceEditor(sta, ste, p, it, ACancel, c);
    if c then
    begin
      if Assigned(FInplaceEditor) then
      begin
        FInplaceEditor.Parent := nil;
        {$IFDEF FMXLIB}
        FInplaceEditor.DisposeOf;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        FInplaceEditor.Free;
        {$ENDIF}
        FInplaceEditor := nil;
        FInplaceEditorActive := False;
        it.UpdateItem;
      end;

      if CanFocus then
        SetFocus;
    end;
  end;

  FClosing := False;

  if AFlagClose then
    FInplaceEditorClosed := True;
end;

procedure TTMSFNCCustomPlanner.DoIsDateTimeDisabled(ADateTime: TDateTime;
  APosition: Integer; var ADisabled: Boolean);
begin
  if Assigned(OnIsDateTimeDisabled) then
    OnIsDateTimeDisabled(Self, ADateTime, APosition, ADisabled);
end;

procedure TTMSFNCCustomPlanner.DoIsDateTimeInActive(ADateTime: TDateTime;
  APosition: Integer; var AInActive: Boolean);
begin
  if Assigned(OnIsDateTimeInActive) then
    OnIsDateTimeInActive(Self, ADateTime, APosition, AInActive);
end;

procedure TTMSFNCCustomPlanner.DoIsDateTimeSub(ADateTime: TDateTime;
  var AIsSub: Boolean);
begin
  if Assigned(OnIsDateTimeSub) then
    OnIsDateTimeSub(Self, ADateTime, AIsSub);
end;

procedure TTMSFNCCustomPlanner.DoIsItemDeletable(AItem: TTMSFNCPlannerItem;
  var ADeletable: Boolean);
begin
  if Assigned(OnIsItemDeletable) then
    OnIsItemDeletable(Self, AItem, ADeletable);
end;

procedure TTMSFNCCustomPlanner.DoItemCustomDrawMark(AGraphics: TTMSFNCGraphics; ARect: TRectF; AMarkType: TTMSFNCPlannerItemMarkType; AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnItemCustomDrawMark) then
    OnItemCustomDrawMark(Self, AGraphics, ARect, AMarkType, AItem);
end;

procedure TTMSFNCCustomPlanner.DoItemPopupMenuPrepare(AItem: TTMSFNCPlannerItem; APopupMenu: TPopupMenu);
begin
  if Assigned(OnItemPopupMenuPrepare) then
    OnItemPopupMenuPrepare(Self, AItem, APopupMenu);
end;

procedure TTMSFNCCustomPlanner.DoItemRightClick(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnItemRightClick) then
    OnItemRightClick(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.DoItemAnchorClick(AItem: TTMSFNCPlannerItem;
  AAnchor: String);
begin
  if Assigned(OnItemAnchorClick) then
    OnItemAnchorClick(Self, AItem, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPlanner.DoItemTitleAnchorClick(AItem: TTMSFNCPlannerItem;
  AAnchor: String);
begin
  if Assigned(OnItemTitleAnchorClick) then
    OnItemTitleAnchorClick(Self, AItem, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPlanner.DoGroupAnchorClick(AGroupIndex: Integer;
  AAnchor: String);
begin
  if Assigned(OnGroupAnchorClick) then
    OnGroupAnchorClick(Self, AGroupIndex, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPlanner.DoPositionAnchorClick(APosition: Integer;
  AAnchor: String);
begin
  if Assigned(OnPositionAnchorClick) then
    OnPositionAnchorClick(Self, APosition, AAnchor)
  else if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPlanner.DoGroupClick(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnGroupClick) then
    OnGroupClick(Self, AGroupIndex, AKind);
end;

procedure TTMSFNCCustomPlanner.DoFullDayClick(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnFullDayClick) then
    OnFullDayClick(Self, AFullDayIndex, AKind);
end;

procedure TTMSFNCCustomPlanner.DoPositionClick(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnPositionClick) then
    OnPositionClick(Self, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoGroupDblClick(AGroupIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnGroupDblClick) then
    OnGroupDblClick(Self, AGroupIndex, AKind);
end;

procedure TTMSFNCCustomPlanner.DoFullDayDblClick(AFullDayIndex: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnFullDayDblClick) then
    OnFullDayDblClick(Self, AFullDayIndex, AKind);
end;

procedure TTMSFNCCustomPlanner.DoPositionDblClick(APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
begin
  if Assigned(OnPositionDblClick) then
    OnPositionDblClick(Self, APosition, AKind);
end;

procedure TTMSFNCCustomPlanner.DoItemChanged(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(Adapter) then
    Adapter.UpdateItem(AItem);

  if Assigned(OnItemChanged) then
    OnItemChanged(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.DoAfterItemChanged(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(Adapter) then
    Adapter.AfterUpdateItem(AItem);

  if Assigned(OnAfterItemChanged) then
    OnAfterItemChanged(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.DoItemToCustomContentPanel(
  AItem: TTMSFNCPlannerItem; AContentPanel: TTMSFNCPlannerEditingDialogContentPanel);
begin
  if Assigned(ItemEditor) then
    ItemEditor.ItemToCustomContentPanel(AItem, AContentPanel);

  if Assigned(OnItemToCustomContentPanel) then
    OnItemToCustomContentPanel(Self, AItem, AContentPanel);
end;

procedure TTMSFNCCustomPlanner.HandleMouseEnter;
begin
  inherited;
  ProcessNavigationButtonsMove(-1, -1);
end;

procedure TTMSFNCCustomPlanner.HandleMouseLeave;
var
  p: TPointF;
begin
  inherited;
  if Assigned(ToolBarPopup) and ToolBarPopup.Activated then
  begin
    p := TTMSFNCUtils.GetMousePos;
    if not ToolBarPopup.PointInPopup(p) and not ToolBarPopup.DropDownActive then
      ToolBarPopup.Deactivate;
  end;

  ProcessNavigationButtonsMove(-1, -1);
end;

procedure TTMSFNCCustomPlanner.DoMoveItem(AItem: TTMSFNCPlannerItem;
  ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer);
begin
  if Assigned(OnMoveItem) then
    OnMoveItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition);
end;

procedure TTMSFNCCustomPlanner.DoAfterMoveItem(AItem: TTMSFNCPlannerItem;
  ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer);
begin
  if Assigned(OnAfterMoveItem) then
    OnAfterMoveItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition);
end;

procedure TTMSFNCCustomPlanner.DoAfterNavigateToDateTime(ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime, ANewDateTime: TDateTime);
begin
  if Assigned(OnAfterNavigateToDateTime) then
    OnAfterNavigateToDateTime(Self, ADirection, ACurrentDateTime, ANewDateTime);
end;

procedure TTMSFNCCustomPlanner.DoSelectTime(AStartTime, AEndTime: TDateTime;
  APosition: Integer);
begin
  if Assigned(OnSelectTime) then
    OnSelectTime(Self, AStartTime, AEndTime, APosition);
end;

procedure TTMSFNCCustomPlanner.DoSizeItem(AItem: TTMSFNCPlannerItem;
  ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer);
begin
  if Assigned(OnSizeItem) then
    OnSizeItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition);
end;

procedure TTMSFNCCustomPlanner.DoSelectCell(AStartCell, AEndCell: TTMSFNCPlannerCell);
begin
  if Assigned(OnSelectCell) then
    OnSelectCell(Self, AStartCell, AEndCell);
end;

procedure TTMSFNCCustomPlanner.DoSelectingCell(AStartCell, AEndCell: TTMSFNCPlannerCell);
begin
  if Assigned(OnSelectingCell) then
    OnSelectingCell(Self, AStartCell, AEndCell);
end;

procedure TTMSFNCCustomPlanner.DoSelectingTime(AStartTime, AEndTime: TDateTime;
  APosition: Integer);
begin
  if Assigned(OnSelectingTime) then
    OnSelectingTime(Self, AStartTime, AEndTime, APosition);
end;

procedure TTMSFNCCustomPlanner.DoAfterSizeItem(AItem: TTMSFNCPlannerItem;
  ANewStartTime, ANewEndTime: TDateTime; ANewPosition: Integer);
begin
  if Assigned(OnAfterSizeItem) then
    OnAfterSizeItem(Self, AItem, ANewStartTime, ANewEndTime, ANewPosition);
end;

procedure TTMSFNCCustomPlanner.DoVScroll(APosition: Single);
begin
  if Assigned(OnVScroll) then
    OnVScroll(Self, APosition);
end;

procedure TTMSFNCCustomPlanner.DoAfterSelectItem(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(Adapter) then
    Adapter.SelectItem(AItem);

  if Assigned(OnAfterSelectItem) then
    OnAfterSelectItem(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.DownTime(Sender: TObject);
var
  b: Boolean;
begin
  Inc(FDownTime);
  if Assigned(FDownItem) then
  begin
    if (FDownTime = DOWNCOUNT) then
    begin
      FDownTimer.Enabled := False;
      FDoItemMove := FDownItem.Movable and AllowMobileMove and not Interaction.ReadOnly;
      FDrawItemHelpers := True;

      b := FDownItem.Selectable and FDownItem.Enabled;

      DoBeforeSelectItem(FDownItem, b);
      if b then
      begin
        if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
          HandleSelectLinkedItems(FDownItem)
        else
          HandleSelectItem(FDownItem);

        DoAfterSelectItem(FDownItem);
      end;
    end;
  end
  else if not Assigned(FDownItem) then
  begin
    if (FDownTime = DOWNCOUNT) or not Interaction.TouchScrolling then
    begin
      FRangeSelection := True;
      FDownTimer.Enabled := False;
      if IsValidCell(FDownCell.Col, FDownCell.Row) then
      begin
        HandleSelection(FDownCell, FDownCell);
        HandleCellSelection(True);
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.AddOrUpdateItem(AStartTime, AEndTime: TDateTime;
  ATitle, AText: String; AItemIndex: Integer): TTMSFNCPlannerItem;
begin
  Result := AddOrUpdateItem(0, AStartTime, AEndTime, ATitle, AText, AItemIndex);
end;

function TTMSFNCCustomPlanner.AddOrUpdateItem(AResource: Integer; AStartTime,
  AEndTime: TDateTime; ATitle, AText: String;
  AItemIndex: Integer): TTMSFNCPlannerItem;
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    Result := Items[AItemIndex]
  else
    Result := Items.Add;

  Result.BeginUpdate;
  Result.StartTime := AStartTime;
  Result.EndTime := AEndTime;
  Result.Resource := AResource;
  Result.Text := AText;
  Result.Title := ATitle;
  Result.EndUpdate;
end;

function TTMSFNCCustomPlanner.AddItem(AStartTime,
  AEndTime: TDateTime): TTMSFNCPlannerItem;
begin
  Result := Items.Add;
  Result.BeginUpdate;
  Result.StartTime := AStartTime;
  Result.EndTime := AEndTime;
  Result.EndUpdate;
end;

function TTMSFNCCustomPlanner.AddItemAtSelection: TTMSFNCPlannerItem;
begin
  Result := Items.Add;
  Result.BeginUpdate;
  Result.StartTime := CellToDateTime(Selection.StartCell);
  Result.EndTime := CellToDateTime(MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + 1));
  Result.Resource := SelectedResource;
  Result.EndUpdate;
end;

function TTMSFNCCustomPlanner.AddOrUpdateItem(AResourceName: String; AStartTime,
  AEndTime: TDateTime; ATitle, AText: String;
  AItemIndex: Integer): TTMSFNCPlannerItem;
var
  res: TTMSFNCPlannerResource;
begin
  res := FindResourceByName(AResourceName);
  Result := nil;
  if Assigned(res) then
    Result := AddOrUpdateItem(res.Index, AStartTime, AEndTime, ATitle, AText, AItemIndex);
end;

function TTMSFNCCustomPlanner.AllowDesktopMove: Boolean;
begin
  if IsMobile then
    Result := Interaction.MoveMode in [pmmDesktop]
  else
    Result := Interaction.MoveMode in [pmmDesktop, pmmAuto];
end;

function TTMSFNCCustomPlanner.AllowMobileMove: Boolean;
begin
  if IsMobile then
    Result := Interaction.MoveMode in [pmmMobile, pmmAuto]
  else
    Result := Interaction.MoveMode in [pmmMobile];
end;

function TTMSFNCCustomPlanner.AllowDesktopDelete: Boolean;
begin
  if IsMobile then
    Result := Interaction.DeleteMode in [pdmDesktop]
  else
    Result := Interaction.DeleteMode in [pdmDesktop, pdmAuto];
end;

function TTMSFNCCustomPlanner.AllowMobileDelete: Boolean;
begin
  if IsMobile then
    Result := Interaction.DeleteMode in [pdmMobile, pdmAuto]
  else
    Result := Interaction.DeleteMode in [pdmMobile];
end;

function TTMSFNCCustomPlanner.AllowDesktopSize: Boolean;
begin
  if IsMobile then
    Result := Interaction.SizeMode in [psmDesktop]
  else
    Result := Interaction.SizeMode in [psmDesktop, psmAuto];
end;

function TTMSFNCCustomPlanner.AllowMobileSize: Boolean;
begin
  if IsMobile then
    Result := Interaction.SizeMode in [psmMobile, psmAuto]
  else
    Result := Interaction.SizeMode in [psmMobile];
end;

procedure TTMSFNCCustomPlanner.Animate(Sender: TObject);
var
  dx, dy, posx, posy: Double;
  animh, animv: Boolean;
begin
  posy := GetVScrollValue;
  posx := GetHScrollValue;
  dx := Abs(FScrollHTo - posx) / Max(1, Abs(FSpX) * 6);
  dy := Abs(FScrollVTo - posy) / Max(1, Abs(FSpY) * 6);
  animv := False;
  if FAnimateVerticalPos then
    animv := AnimateDouble(posy, FScrollVTo, dy, 0.01);

  animh := False;
  if FAnimateHorizontalPos then
    animh := AnimateDouble(posx, FScrollHTo, dx, 0.01);

  FAnimating := animv or animh;
  if FAnimating then
    Scroll(posx, posy)
  else
  begin
    FAnimateVerticalPos := False;
    FAnimateTimer.Enabled := False;
    FAnimateHorizontalPos := False;
  end;
end;

procedure TTMSFNCCustomPlanner.Assign(Source: TPersistent);
var
  I: Integer;
begin
  inherited;
  if Source is TTMSFNCCustomPlanner then
  begin
    FItemsAppearance.Assign((Source as TTMSFNCCustomPlanner).ItemsAppearance);
    FTimeLineAppearance.Assign((Source as TTMSFNCCustomPlanner).TimeLineAppearance);
    FPositionsAppearance.Assign((Source as TTMSFNCCustomPlanner).PositionsAppearance);
    FPositions.Assign((Source as TTMSFNCCustomPlanner).Positions);
    FGroupsAppearance.Assign((Source as TTMSFNCCustomPlanner).GroupsAppearance);
    FFullDaysAppearance.Assign((Source as TTMSFNCCustomPlanner).FullDaysAppearance);
    FTimeLine.Assign((Source as TTMSFNCCustomPlanner).TimeLine);
    FModeSettings.Assign((Source as TTMSFNCCustomPlanner).ModeSettings);
    FMode := (Source as TTMSFNCCustomPlanner).Mode;
    FGridCellAppearance.Assign((Source as TTMSFNCCustomPlanner).GridCellAppearance);
    FSelectionAppearance.Assign((Source as TTMSFNCCustomPlanner).SelectionAppearance);
    FCustomDateTimes.Clear;
    for I := 0 to (Source as TTMSFNCCustomPlanner).CustomDateTimes.Count - 1 do
      FCustomDateTimes.Add((Source as TTMSFNCCustomPlanner).CustomDateTimes[I]);
  end;
end;

procedure TTMSFNCCustomPlanner.BuildDisplay(ACache: TTMSFNCPlannerCache; ADisplay: TTMSFNCPlannerDisplayList);
var
  x, y: Double;
  I: Integer;
  cache: TTMSFNCPlannerCacheItem;
  r, rrt, rrb, trl, trr, rg, grt, grb, fdrt, fdrb: TRectF;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(ACache) or not Assigned(ADisplay) then
    Exit;

  ADisplay.Clear;

  x := -GetHorizontalScrollPosition;
  y := -GetVerticalScrollPosition;
  r := GetContentRect;
  grt := GetGroupsTopRect;
  grb := GetGroupsBottomRect;
  fdrt := GetFullDaysTopRect;
  fdrb := GetFullDaysBottomRect;
  rrt := GetPositionsTopRect;
  rrb := GetPositionsBottomRect;
  trl := GetTimeLineLeftRect;
  trr := GetTimeLineRightRect;
  for I := 0 to ACache.Count - 1 do
  begin
    cache := ACache[I];
    rg := cache.Rect;
    {$IFDEF FMXWEBLIB}
    case cache.Kind of
      ikGroupTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, grt.Left, int(y) + grt.Top);
          pomVertical: OffsetRectEx(rg, int(x) + grt.Left, grt.Top);
        end;
      end;
      ikGroupBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, grb.Left - 1, int(y) + grb.Top);
          pomVertical: OffsetRectEx(rg, int(x) + grb.Left, grb.Top - 1);
        end;
      end;
      ikFullDayTop, ikFullDayItemTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, fdrt.Left, int(y) + fdrt.Top);
          pomVertical: OffsetRectEx(rg, int(x) + fdrt.Left, fdrt.Top);
        end;
      end;
      ikFullDayBottom, ikFullDayItemBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, fdrb.Left - 1, int(y) + fdrb.Top);
          pomVertical: OffsetRectEx(rg, int(x) + fdrb.Left, fdrb.Top - 1);
        end;
      end;
      ikPositionTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, rrt.Left, int(y) + rrt.Top);
          pomVertical: OffsetRectEx(rg, int(x) + rrt.Left, rrt.Top);
        end;
      end;
      ikPositionBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, rrb.Left - 1, int(y) + rrb.Top);
          pomVertical: OffsetRectEx(rg, int(x) + rrb.Left, rrb.Top - 1);
        end;
      end;
      ikTimeLineLeft:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, int(x) + trl.Left, trl.Top);
          pomVertical: OffsetRectEx(rg, trl.Left, int(y) + trl.Top);
        end;
      end;
      ikTimeLineRight:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, int(x) + trr.Left, trr.Top - 1);
          pomVertical: OffsetRectEx(rg, trr.Left - 1, int(y) + trr.Top);
        end;
      end
      else
        OffsetRectEx(rg, int(x) + r.Left, int(y) + r.Top);
    end;
    {$ENDIF}
    {$IFDEF CMNLIB}
    case cache.Kind of
      ikGroupTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, grt.Left, y + grt.Top);
          pomVertical: OffsetRectEx(rg, x + grt.Left, grt.Top);
        end;
      end;
      ikGroupBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, grb.Left - 1, y + grb.Top);
          pomVertical: OffsetRectEx(rg, x + grb.Left, grb.Top - 1);
        end;
      end;
      ikFullDayTop, ikFullDayItemTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, fdrt.Left, y + fdrt.Top);
          pomVertical: OffsetRectEx(rg, x + fdrt.Left, fdrt.Top);
        end;
      end;
      ikFullDayBottom, ikFullDayItemBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, fdrb.Left - 1, y + fdrb.Top);
          pomVertical: OffsetRectEx(rg, x + fdrb.Left, fdrb.Top - 1);
        end;
      end;
      ikPositionTop:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, rrt.Left, y + rrt.Top);
          pomVertical: OffsetRectEx(rg, x + rrt.Left, rrt.Top);
        end;
      end;
      ikPositionBottom:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, rrb.Left - 1, y + rrb.Top);
          pomVertical: OffsetRectEx(rg, x + rrb.Left, rrb.Top - 1);
        end;
      end;
      ikTimeLineLeft:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, x + trl.Left, trl.Top);
          pomVertical: OffsetRectEx(rg, trl.Left, y + trl.Top);
        end;
      end;
      ikTimeLineRight:
      begin
        case OrientationMode of
          pomHorizontal: OffsetRectEx(rg, x + trr.Left, trr.Top - 1);
          pomVertical: OffsetRectEx(rg, trr.Left - 1, y + trr.Top);
        end;
      end
      else
        OffsetRectEx(rg, x + r.Left, y + r.Top);
    end;
    {$ENDIF}

    cache.DrawRect := rg;
    case cache.Kind of
      ikFullDayItemTop:
      begin
        if RectIntersectsWithEx(rg, fdrt) then
          ADisplay.Add(cache);
      end;
      ikFullDayItemBottom:
      begin
        if RectIntersectsWithEx(rg, fdrb) then
          ADisplay.Add(cache);
      end;
      ikItem, ikCell:
      begin
        if RectIntersectsWithEx(rg, r) then
          ADisplay.Add(cache);
      end;
      ikPositionTop:
      begin
        if RectIntersectsWithEx(rg, rrt) then
          ADisplay.Add(cache);
      end;
      ikPositionBottom:
      begin
        if RectIntersectsWithEx(rg, rrb) then
          ADisplay.Add(cache);
      end;
      ikGroupTop:
      begin
        if RectIntersectsWithEx(rg, grt) then
          ADisplay.Add(cache);
      end;
      ikGroupBottom:
      begin
        if RectIntersectsWithEx(rg, grb) then
          ADisplay.Add(cache);
      end;
      ikFullDayTop:
      begin
        if RectIntersectsWithEx(rg, fdrt) then
          ADisplay.Add(cache);
      end;
      ikFullDayBottom:
      begin
        if RectIntersectsWithEx(rg, fdrb) then
          ADisplay.Add(cache);
      end;
      ikTimeLineLeft:
      begin
        if RectIntersectsWithEx(rg, trl) then
          ADisplay.Add(cache);
      end;
      ikTimeLineRight:
      begin
        if RectIntersectsWithEx(rg, trr) then
          ADisplay.Add(cache);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState;
  X, Y: Single);
var
  dc, c: TTMSFNCPlannerCacheItem;
  it: TTMSFNCPlannerItemOpen;
  d: Boolean;
  itr: TTMSFNCPlannerItem;
  {$IFNDEF WEBLIB}
  sp: TPointF;
  {$ENDIF}
begin
  inherited;

  FPopupPlannerItem := nil;

  if Button = TTMSFNCMouseButton.mbRight then
  begin
    {$IFNDEF WEBLIB}
    ShowHint(nil, -1, -1);
    {$ENDIF}
    {$IFDEF WEBLIB}
    ShowHintEx(nil, -1, -1);
    {$ENDIF}

    itr := XYToItem(X, Y);
    FPopupPlannerItem := itr;

    if Assigned(itr) then
    begin
      DoItemRightClick(itr);

      if Assigned(FItemPopup) then
      begin
        DoItemPopupMenuPrepare(itr, FItemPopup);
        {$IFNDEF WEBLIB}
        ItemPopup.PopupComponent := Self;
        sp := ConvertClientToScreen(PointF(X, Y));
        ItemPopup.Popup(Round(sp.X), Round(sp.Y));
        {$ENDIF}
      end;
    end;

    Exit;
  end;

  if not FDblClicked then
    CaptureEx;

  ProcessNavigationButtonsDown(X, Y);
  if FNavigationButtonDown then
    Exit;

  if not FDblClicked then
  begin
    if CanFocus then
      SetFocus;

    if FInplaceEditorActive then
      CloseInplaceEditor(False);
  end;

  FDblClicked := False;
  dc := XYToCacheItem(X, Y);
  FDownCacheItemIdx := -1;
  FDownItem := nil;
  if Assigned(dc) then
  begin
    FDownItem := dc.Item;
    FDownCacheItemIdx := TTMSFNCPlannerItemOpen(FDownItem).CacheList.IndexOf(dc);
  end;

  if Assigned(FDownItem) then
  begin
    it := TTMSFNCPlannerItemOpen(FDownItem);
    if (FDownCacheItemIdx >= 0) and (FDownCacheItemIdx <= it.CacheList.Count - 1) then
    begin
      c := it.CacheList[FDownCacheItemIdx];
      if (FDownItem = FActiveItem) then
      begin
        FDoItemSizeUp := PtInRectEx(GetCacheItemStartTimeSizeRect(c), PointF(X, Y)) and FDownItem.Sizeable and CanSizeCacheItemStartTime(c) and AllowDesktopSize and not Interaction.ReadOnly;
        FDoItemSizeDown := PtInRectEx(GetCacheItemEndTimeSizeRect(c), PointF(X, Y)) and FDownItem.Sizeable and CanSizeCacheItemEndTime(c) and AllowDesktopSize and not Interaction.ReadOnly;
        FDoItemMove := PtInRectEx(GetCacheItemMoveRect(c), PointF(X, Y)) and FDownItem.Movable and CanMoveCacheItem(c) and AllowDesktopMove and not Interaction.ReadOnly;
      end;

      d := FDownItem.Deletable;
      DoIsItemDeletable(FDownItem, d);
      FDoItemDelete := PtInRectEx(GetCacheItemDeleteRect(c), PointF(X, Y)) and d and CanDeleteCacheItem(c) and AllowDesktopDelete and ItemsAppearance.ShowDeleteArea and not Interaction.ReadOnly;
    end;

    FDoItemAnchor := XYToItemAnchor(FDownItem, X, Y);
    if FDoItemAnchor = '' then
      FDoItemAnchor := XYToItemTitleAnchor(FDownItem, X, Y);
  end;

  FDownOnPositions := PtInRectEx(GetPositionsTopRect, PointF(X, Y))
    or PtInRectEx(GetPositionsBottomRect, PointF(X, Y)) or PtInRectEx(GetGroupsTopRect, PointF(X, Y))
    or PtInRectEx(GetGroupsBottomRect, PointF(X, Y)) or PtInRectEx(GetFullDaysTopRect, PointF(X, Y))
    or PtInRectEx(GetFullDaysBottomRect, PointF(X, Y));

  FDownOnPositions := FDownOnPositions and not Assigned(FDownItem);

  FMoveOnPositions := FDownOnPositions;

  FDownCell := XYToCell(X, Y);
  FRangeCell := FDownCell;
  FSizeCell := FDownCell;
  IsMouseDown := True;
  FTimeStart := GetTickCountX;
  FTimeStop := FTimeStart;
  FScrollVTo := GetVScrollValue;
  FScrollHTo := GetHScrollValue;
  FScrollX := X;
  FScrollY := Y;
  FDownX := X;
  FDownY := Y;
  FMouseX := X;
  FMouseY := Y;
  FMouseUp := False;
  FMovePositionPrevious := False;
  FMovePositionNext := False;
  FDownTimer.Enabled := not (FDoItemSizeUp or FDoItemSizeDown or FDoItemMove or FDoItemDelete or (FDoItemAnchor <> ''));
  FDoubleSelection := not FAnimateTimer.Enabled;
  FRangeSelection := False;
  FScrolling := False;
  FDownTime := 0;
  case OrientationMode of
    pomHorizontal:
    begin
      FMovePositionPrevious := ((GetVScrollValue = 0) or not VerticalScrollBar.Visible) and Interaction.SwipeToPreviousDateTime and CanNavigate;
      FMovePositionNext := ((GetVScrollValue = VerticalScrollBar.Max - GetVViewPortSize) or not VerticalScrollBar.Visible) and Interaction.SwipeToNextDateTime and CanNavigate;
    end;
    pomVertical:
    begin
      FMovePositionPrevious := ((GetHScrollValue = 0) or not HorizontalScrollBar.Visible) and Interaction.SwipeToPreviousDateTime and CanNavigate;
      FMovePositionNext := ((GetHScrollValue = HorizontalScrollBar.Max - GetHViewPortSize) or not HorizontalScrollBar.Visible) and Interaction.SwipeToNextDateTime and CanNavigate;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  f: Double;
  cls, cle, clsn, clen, cl: TTMSFNCPlannerCell;
  it: TTMSFNCPlannerItem;
  doscroll: Boolean;
  dsp: TTMSFNCPlannerCacheItem;
  m: TTMSFNCPlannerMouseInteractionMode;
  domove: Boolean;
  dtc, dtn: TDateTime;
  d: TTMSFNCPlannerNavigationDirection;
  del: Boolean;
  r: TRectF;
  pt: TPointF;
  gi, pi: Integer;
  cg, cp, cfd: TTMSFNCPlannerCacheItem;
  ch: Boolean;
begin
  inherited;

  if FDblClickedMouseMove then
  begin
    FRangeSelection := False;
    FDoItemSizeUp := False;
    FDoItemSizeDown := False;
    FDoItemDelete := False;
    FDoItemAnchor := '';
    FDoItemMove := False;
    if FDrawItemHelpers then
    begin
      FDrawItemHelpers := False;
      Invalidate;
    end;

    IsMouseDown := False;
    FMouseUp := False;
    FDblClickedMouseMove := False;
    Exit;
  end;

  ProcessNavigationButtonsMove(X, Y);

  cg := nil;
  cp := nil;
  cfd := nil;
  gi := -1;
  pi := -1;

  if IsMouseDown and not (FDoItemAnchor <> '') then
  begin
    {$IFNDEF WEBLIB}
    ShowHint(nil, -1, -1);
    {$ENDIF}
    {$IFDEF WEBLIB}
    ShowHintEx(nil, -1, -1);
    {$ENDIF}

    doscroll := not FDoItemMove and not FDoItemSizeUp and not FDoItemSizeDown and not FDoItemDelete;
    if doscroll then
    begin
      f := 1;
      case ScrollMode of
        smCellScrolling: f := 0.1;
      end;

      domove := False;
      if FDownOnPositions and (Interaction.SwipeToNextDateTime or Interaction.SwipeToPreviousDateTime) and CanNavigate then
      begin
        domove := True;
        FDownOnPositions := False;
        case OrientationMode of
          pomHorizontal:
          begin
            FMovePositionNext := FMovePositionNext and (Y - FMouseY < 0);
            FMovePositionPrevious := FMovePositionPrevious and (Y - FMouseY > 0);
          end;
          pomVertical:
          begin
            FMovePositionNext := FMovePositionNext and (X - FMouseX < 0);
            FMovePositionPrevious := FMovePositionPrevious and (X - FMouseX > 0);
          end;
        end;
      end
      else
      begin
        FMovePositionPrevious := False;
        FMovePositionNext := False;
      end;

      if not FMovePositionNext and not FMovePositionPrevious then
      begin
        if (FScrolling or (Abs(FMouseX - X) > 3) or (Abs(FMouseY - Y) > 3)) and not FRangeSelection and Interaction.TouchScrolling then
        begin
          if (Abs(X - FDownX) > SCROLLINGDELAY) or (Abs(Y - FDownY) > SCROLLINGDELAY) then
          begin
            FScrolling := True;
            FDownTimer.Enabled := False;
            FDoubleSelection := False;
            if IsMouseDown and not FMouseUp then
            begin
              if FMoveOnPositions then
              begin
                case OrientationMode of
                  pomHorizontal: Scroll(GetHScrollValue, GetVScrollValue - (Y - FDownY) * f);
                  pomVertical: Scroll(GetHScrollValue - (X - FDownX) * f, GetVScrollValue);
                end;
              end
              else
                Scroll(GetHScrollValue - (X - FDownX) * f, GetVScrollValue - (Y - FDownY) * f);

              FDownY := Y;
              FDownX := X;
            end;
          end;
        end
        else if FRangeSelection then
        begin
          cl := XYToCell(X, Y);
          if IsValidCell(cl.Col, cl.Row) and IsValidCell(FDownCell.Col, FDownCell.Row) then
          begin
            SelectCells(MakeCell(cl.Col, FDownCell.Row), cl);
            if (cl.Col <> FRangeCell.Col) or (cl.Row <> FRangeCell.Row) then
              HandleCellSelection(True);

            FRangeCell := cl;
          end;
        end;
      end
      else if (FMovePositionNext or FMovePositionPrevious) and domove then
      begin
        if FMovePositionPrevious or FMovePositionNext then
        begin
          dtc := FDisplayStartTime;

          if FMovePositionPrevious then
          begin
            dtn := GetPreviousDateTime;
            d := pndPrevious;
          end
          else
          begin
            dtn := GetNextDateTime;
            d := pndNext;
          end;

          HandleDateTimeNavigation(d, dtc, dtn);
        end
      end;
    end
    else if (FDoItemSizeUp or FDoItemSizeDown or FDoItemMove) and Assigned(FDownItem) then
    begin
      FDrawItemHelpers := True;
      cl := XYToCell(X, Y);
      if (cl.Col <> -1) and (cl.Row <> -1) then
      begin
        cls := ItemToStartCell(FDownItem);
        cle := ItemToEndCell(FDownItem);

        clsn.Col := cls.Col;
        clen.Col := cle.Col;
        clsn.Row := cls.Row;
        clen.Row := cle.Row;

        if FDoItemSizeUp then
        begin
          if ((cls.Row >= cle.Row) and (cle.Col - cl.Col > 0)) or ((cls.Row < cle.Row) and (cle.Col - cl.Col >= 0)) then
            clsn.Col := cl.Col;

          if clsn.Col = cle.Col then
            clsn.Row := Min(cle.Row - 1, cl.Row)
          else
            clsn.Row := cl.Row;
        end
        else if FDoItemSizeDown then
        begin
          if ((cle.Row <= cls.Row) and (cl.Col - cls.Col > 0)) or ((cle.Row > cls.Row) and (cl.Col - cls.Col >= 0)) then
            clen.Col := cl.Col;

          if clen.Col = cls.Col then
            clen.Row := Max(cls.Row + 1, cl.Row + 1)
          else
            clen.Row := cl.Row + 1;
        end
        else
        begin
          clsn.Col := clsn.Col + (cl.Col - FSizeCell.Col);
          clsn.Row := clsn.Row + (cl.Row - FSizeCell.Row);
          clen.Col := clen.Col + (cl.Col - FSizeCell.Col);
          clen.Row := clen.Row + (cl.Row - FSizeCell.Row);

          if (clsn.Row < 0) or (clen.Row > RowCount) then
          begin
            clsn.Row := cls.Row;
            clen.Row := cle.Row;
          end;

          if (clsn.Col < 0) or (clen.Col > ColumnCount - 1) then
          begin
            clsn.Col := cls.Col;
            clen.Col := cle.Col;
          end;
        end;

        if FDoItemSizeUp then
          m := pmmSizeUp
        else if FDoItemSizeDown then
          m := pmmSizeDown
        else
          m := pmmMove;

        if HandleItemMouseInteraction(FDownItem, cls, cle, clsn, clen, m) then
          FSizeCell := cl;
      end;
    end
  end
  else
  begin
    it := nil;
    dsp := XYToCacheItem(X, Y);
    if Assigned(dsp) then
      it := dsp.Item;

    if Assigned(ToolBarPopup) and Assigned(FActiveItem) and not IsFullDayItem(FActiveItem) and not ToolBarPopup.Activated then
    begin
      if it = FActiveItem then
      begin
        ToolBarPopup.PlacementControl := Self;
        ToolBarPopup.Placement := ppAbsolute;
        ToolBarPopup.Planner := Self;
        ToolBarPopup.PlannerItem := it;
        ToolBarPopup.Activate;
        r := GetFirstRect(it);
        pt := PointF(r.Left + ((r.Right - r.Left) - ToolBarPopup.ToolBar.Width) / 2, r.Top - ToolBarPopup.ToolBar.Height + 1);
        pt := ConvertClientToScreen(pt);
        ToolBarPopup.PlacementRectangle.Left := pt.X;
        ToolBarPopup.PlacementRectangle.Top := pt.Y;
        ToolBarPopup.PlacementRectangle.Right := pt.X;
        ToolBarPopup.PlacementRectangle.Bottom := pt.Y;
        Exit;
      end;
    end;

    if Assigned(ToolBarPopup) and ToolBarPopup.Activated and not ToolBarPopup.DropDownActive and (it <> FActiveItem) then
      ToolBarPopup.Deactivate;

    if Assigned(it) and Assigned(dsp) then
    begin
      del := it.Deletable;
      DoIsItemDeletable(it, del);
      if (FActiveItem = it) then
      begin
        if ((PtInRectEx(GetCacheItemStartTimeSizeRect(dsp), PointF(X, Y)) and CanSizeCacheItemStartTime(dsp)) or (PtInRectEx(GetCacheItemEndTimeSizeRect(dsp), PointF(X, Y))
          and CanSizeCacheItemEndTime(dsp))) and not IsFullDayItem(it) and it.Sizeable and AllowDesktopSize and not Interaction.ReadOnly then
        begin
          case OrientationMode of
            pomHorizontal: Cursor := crSizeWE;
            pomVertical: Cursor := crSizeNS;
          end;
        end
        else if PtInRectEx(GetCacheItemMoveRect(dsp), PointF(X, Y)) and CanMoveCacheItem(dsp) and not IsFullDayItem(it) and it.Movable and AllowDesktopMove and not Interaction.ReadOnly then
          Cursor := crSize
        else if PtInRectEx(GetCacheItemDeleteRect(dsp), PointF(X, Y)) and CanDeleteCacheItem(dsp) and not IsFullDayItem(it) and del and AllowDesktopDelete and ItemsAppearance.ShowDeleteArea and not Interaction.ReadOnly then
          Cursor := crHandPoint
        else if (XYToItemAnchor(it, X, Y) <> '') or (XYToItemTitleAnchor(it, X, Y) <> '') then
          Cursor := crHandPoint
        else
          Cursor := crDefault;
      end
      else
      begin
        if PtInRectEx(GetCacheItemDeleteRect(dsp), PointF(X, Y)) and CanDeleteCacheItem(dsp) and not IsFullDayItem(it) and del and AllowDesktopDelete and ItemsAppearance.ShowDeleteArea and not Interaction.ReadOnly then
          Cursor := crHandPoint
        else if (XYToItemAnchor(it, X, Y) <> '') or (XYToItemTitleAnchor(it, X, Y) <> '') then
          Cursor := crHandPoint
        else
          Cursor := crDefault;
      end;
    end
    else
    begin
      if (XYToGroupAnchor(X,Y, gi) <> '') or (XYToPositionAnchor(X,Y, pi) <> '') then
        Cursor := crHandPoint
      else
        Cursor := crDefault;
    end;

    if (gi = -1) and (pi = -1) then
    begin
      cg := XYToGroup(X, Y);
      if not Assigned(cg) then
      begin
        cfd := XYToFullDay(X, Y);
        if not Assigned(cfd) then
          cp := XYToPosition(X, Y)
        else
        begin
          if Assigned(XYToFullDayItem(X, Y)) then
            cfd := nil;
        end;
      end;
    end;

    {$IFDEF WEBLIB}
    ShowHintEx(it, X, Y);
    {$ENDIF}
    {$IFNDEF WEBLIB}
    ShowHint(it, X, Y);
    {$ENDIF}

    ch := False;

    if (FHoverItem <> it) and not Assigned(cg) and not Assigned(cfd) and not Assigned(cp) and not ItemsAppearance.AlternativeHints then
    begin
      FHoverItem := it;
      ch := True;
    end;

    if FGroupHover <> cg then
    begin
      FGroupHover := cg;
      ch := True;
    end;

    if FFullDayHover <> cfd then
    begin
      FFullDayHover := cfd;
      ch := True;
    end;

    if FPositionHover <> cp then
    begin
      FPositionHover := cp;
      ch := True;
    end;

    if ch then
      CancelHint;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState;
  X, Y: Single);
var
  f: Double;
  b: Boolean;
  prev: TTMSFNCPlannerItem;
  a: String;
  c: TTMSFNCPlannerCacheItem;
  edc: TTMSFNCPlannerCacheItem;
  it: TTMSFNCPlannerItemOpen;
  itc: TTMSFNCPlannerItem;
  gi, pi: Integer;
  ctrl: Boolean;
begin
  inherited;

  if not FDblClicked then
    ReleaseCaptureEx;

  HandleAfterMouseEvents;

  ProcessNavigationButtonsUp(X, Y);

  if FNavigationButtonDown then
  begin
    FNavigationButtonDown := False;
    Invalidate;
    Exit;
  end;

  if not IsMouseDown then
    Exit;

  f := 1;
  case ScrollMode of
    smCellScrolling: f := 0.1;
  end;

  IsMouseDown := False;
  FMouseUp := True;
  FScrolling := False;
  FDownTimer.Enabled := False;

  if (not FMovePositionPrevious and not FMovePositionNext) or not FDownOnPositions then
  begin
    if not FDoItemSizeUp and not FDoItemSizeDown and not FDoItemMove and not FDoItemDelete and not (FDoItemAnchor <> '') then
    begin
      if not FDoubleSelection and Interaction.TouchScrolling then
      begin
        FTimeStop := GetTickCountX;
        if ((FTimeStop - FTimeStart) < SWIPECOUNT) and ((FTimeStop - FTimeStart) > 0) then
        begin
          FSpY := Abs(Y - FScrollY) / (FTimeStop - FTimeStart);
          if (FSpY > 0) and (not FMoveOnPositions or ((OrientationMode = pomHorizontal) and FMoveOnPositions)) then
          begin
            if (Y - FScrollY) > 0 then
              FScrollVTo := Max(0, Min(VerticalScrollBar.Max - GetVViewPortSize, FScrollVTo - Round(Abs(Y - FScrollY) * FSpY * f * 3)))
            else
              FScrollVTo := Max(0, Min(VerticalScrollBar.Max - GetVViewPortSize, FScrollVTo + Round(Abs(Y - FScrollY) * FSpY * f * 3)));

            FAnimateVerticalPos := True;
            FAnimateTimer.Enabled := True;
          end;

          FSpX := Abs(X - FScrollX) / (FTimeStop - FTimeStart);
          if (FSpX > 0) and (not FMoveOnPositions or ((OrientationMode = pomVertical) and FMoveOnPositions)) then
          begin
            if (X - FScrollX) > 0 then
              FScrollHTo := Max(0, Min(HorizontalScrollBar.Max - GetHViewPortSize, FScrollHTo - Round(Abs(X - FScrollX) * FSpX * f * 3)))
            else
              FScrollHTo := Max(0, Min(HorizontalScrollBar.Max - GetHViewPortSize, FScrollHTo + Round(Abs(X - FScrollX) * FSpX * f * 3)));

            FAnimateHorizontalPos := True;
            FAnimateTimer.Enabled := True;
          end;
        end;
      end
      else
      begin
        if Assigned(FDownItem) then
        begin
          b := FDownItem.Selectable and FDownItem.Enabled;
          prev := FActiveItem;
          DoBeforeSelectItem(FDownItem, b);
          if b then
          begin
            if not IsMobile then
              ctrl := (ssCtrl in Shift) and Interaction.MultiSelect
            else
              ctrl := Interaction.MultiSelect;

            if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
              HandleSelectLinkedItems(FDownItem, ctrl)
            else
              HandleSelectItem(FDownItem, ctrl);

            DoAfterSelectItem(FDownItem);
          end;

          if ((Interaction.MouseEditMode = pmemSingleClick) or ((Interaction.MouseEditMode = pmemSingleClickOnSelectedItem) and (FDownItem = prev))) and not Interaction.ReadOnly then
          begin
            if Assigned(FDownItem) then
            begin
              it := TTMSFNCPlannerItemOpen(FDownItem);
              edc := nil;
              if (FDownCacheItemIdx >= 0) and (FDownCacheItemIdx <= it.CacheList.Count - 1) then
                edc := it.CacheList[FDownCacheItemIdx];

              HandleItemEditing(FDownItem, edc);
            end;
          end;
        end
        else if IsValidCell(FDownCell.Col, FDownCell.Row) then
        begin
          if not FRangeSelection then
          begin
            if ssShift in Shift then
              SelectCells(Selection.StartCell, FDownCell)
            else
              SelectCells(FDownCell, FDownCell);
          end;

          HandleCellSelection;
          if not Interaction.ReadOnly then
          begin
            case Interaction.MouseInsertMode of
              pmimAfterSelection: HandleItemInsert(False);
              pmimDialogAfterSelection: HandleItemInsert(True);
            end;
          end;
        end;
      end;
    end
    else if not FDoItemSizeUp and not FDoItemSizeDown and not FDoItemMove then
    begin
      if FDoItemDelete then
        HandleItemDelete(FDownItem, pidmTouch)
      else
      begin
        a := XYToItemAnchor(FDownItem, X, Y);
        if a <> '' then
          DoItemAnchorClick(FDownItem, a)
        else
        begin
          a := XYToItemTitleAnchor(FDownItem, X, Y);
          if a <> '' then
            DoItemTitleAnchorClick(FDownItem, a)
        end;
      end;
    end;
  end;

  gi := -1;
  pi := -1;

  a := XYToGroupAnchor(X, Y, gi);
  if (a <> '') and (gi > -1) then
    DoGroupAnchorClick(gi, a)
  else
  begin
    a := XYToPositionAnchor(X, Y, pi);
    if (a <> '') and (pi > -1) then
      DoPositionAnchorClick(pi, a)
  end;

  if (a = '') and (pi = - 1) and (gi = -1) then
  begin
    c := XYToGroup(X, Y);
    if Assigned(c) then
      DoGroupClick(c.Group, c.Kind)
    else
    begin
      c := XYToFullDay(X, Y);
      if Assigned(c) then
      begin
        itc := XYToFullDayItem(X, Y);
        if Assigned(itc) then
          DoItemClick(itc)
        else
          DoFullDayClick(c.FullDay, c.Kind)
      end
      else
      begin
        c := XYToPosition(X, Y);
        if Assigned(c) then
        begin
          DoPositionClick(c.Position, c.Kind);
        end
        else
        begin
          itc := XYToItem(X, Y);
          if Assigned(itc) then
            DoItemClick(itc);
        end;
      end;
    end;
  end;

  if FDoItemSizeUp or FDoItemSizeDown or FDoItemMove or FDoItemDelete then
    DoAfterItemChanged(FDownItem);

  FRangeSelection := False;
  FDoItemSizeUp := False;
  FDoItemSizeDown := False;
  FDoItemDelete := False;
  FDoItemAnchor := '';
  FDoItemMove := False;
  if FDrawItemHelpers then
  begin
    FDrawItemHelpers := False;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleMouseWheel(Shift: TShiftState; WheelDelta: Integer;
  var Handled: Boolean);
var
  vpos, hpos: Double;
  sz: Single;
begin
  inherited;
  if Handled then
    Exit;

  vpos := GetVScrollValue;
  hpos := GetHScrollValue;

  case OrientationMode of
    pomHorizontal: sz := DefaultColumnWidth;
    pomVertical: sz := DefaultRowHeight;
    else
      sz := 0;
  end;

  if WheelDelta > 0 then
    Scroll(hpos, vpos - sz)
  else
    Scroll(hpos, vpos + sz);

  Handled := True;
end;

procedure TTMSFNCCustomPlanner.UpdateGridDisplay;
begin
  BuildDisplay(FGridCache, FGridDisplay);
end;

procedure TTMSFNCCustomPlanner.UpdateGroupCache(ACache: TTMSFNCPlannerCache);
var
  w, h: Double;
  I: Integer;
  grp: TTMSFNCPlannerDisplayGroup;
  cr: TRectF;
  x, y, bw, bh: Double;
  rt, rc: TRectF;
  bmp: TBitmap;
  g: TTMSFNCGraphics;
  cache: TTMSFNCPlannerCacheItem;
  bmpvalid: Boolean;
begin
  inherited;
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (ColumnCount = 0) or (FDisplayGroups.Count = 0) or not Assigned(ACache) then
    Exit;

  ACache.Clear;

  if (ACache is TTMSFNCPlannerGroupsTopCache) and (not (pglTop in GroupsAppearance.Layouts) or (GroupsAppearance.TopSize <= 0)) then
    Exit;

  if (ACache is TTMSFNCPlannerGroupsBottomCache) and (not (pglBottom in GroupsAppearance.Layouts) or (GroupsAppearance.BottomSize <= 0)) then
    Exit;

  bmpvalid := False;

  cr := GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      h := cr.Bottom - cr.Top;
      if ACache is TTMSFNCPlannerGroupsTopCache then
        w := GetGroupsTopSize + 1
      else
        w := GetGroupsBottomSize + 1;
    end;
    pomVertical:
    begin
      w := cr.Right - cr.Left;
      if ACache is TTMSFNCPlannerGroupsTopCache then
        h := GetGroupsTopSize + 1
      else
        h := GetGroupsBottomSize + 1;
    end;
    else
    begin
      w := 0;
      h := 0;
    end;
  end;

  for I := 0 to FDisplayGroups.Count - 1 do
  begin
    grp := FDisplayGroups[I];

    {$IFDEF FMXWEBLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        x := 0;
        y := Int(ColumnPositions[grp.StartPosition]);
        bw := w;
        bh := Int(ColumnPositions[grp.EndPosition + 1] - y);
      end;
      pomVertical:
      begin
        y := 0;
        x := Int(ColumnPositions[grp.StartPosition]);
        bh := h;
        bw := Int(ColumnPositions[grp.EndPosition + 1] - x);
      end;
      else
      begin
        bw := 0;
        bh := 0;
        x := 0;
        y := 0;
      end;
    end;
    {$ENDIF}
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        x := 0;
        y := ColumnPositions[grp.StartPosition];
        bw := w;
        bh := ColumnPositions[grp.EndPosition + 1] - y;
      end;
      pomVertical:
      begin
        y := 0;
        x := ColumnPositions[grp.StartPosition];
        bh := h;
        bw := ColumnPositions[grp.EndPosition + 1] - x;
      end
      else
      begin
        bw := 0;
        bh := 0;
        x := 0;
        y := 0;
      end;
    end;
    {$ENDIF}

    if (bw <= 0) or (bh <= 0) then
      Continue;

    rt := RectF(0, 0, bw, bh);
    bmp := nil;
    if GroupsCaching then
      bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

    g := nil;
    if bmpvalid then
      g := TTMSFNCGraphics.Create(bmp.Canvas);

    {$IFDEF FMXWEBLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        rc.Left := int(rt.Left) + 0.5;
        rc.Right := int(rt.Right) - 0.5;
        rc.Top := int(rt.Top) + 0.5;
        rc.Bottom := int(rt.Bottom) + 0.5;
      end;
      pomVertical:
      begin
        rc.Top := int(rt.Top) + 0.5;
        rc.Bottom := int(rt.Bottom) - 0.5;
        rc.Left := int(rt.Left) + 0.5;
        rc.Right := int(rt.Right) + 0.5;
      end;
    end;
    {$ENDIF}
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        rc.Left := rt.Left;
        rc.Right := rt.Right;
        rc.Top := rt.Top;
        rc.Bottom := rt.Bottom + 1;
      end;
      pomVertical:
      begin
        rc.Top := rt.Top;
        rc.Bottom := rt.Bottom;
        rc.Left := rt.Left;
        rc.Right := rt.Right + 1;
      end;
    end;
    {$ENDIF}

    if GroupsCaching then
    begin
      if bmpvalid then
      begin
        if ACache is TTMSFNCPlannerGroupsTopCache then
          DrawGroup(g, rc, I, grp.StartPosition, grp.EndPosition, ikGroupTop)
        else
          DrawGroup(g, rc, I, grp.StartPosition, grp.EndPosition, ikGroupBottom);

        {$IFDEF FMXLIB}
        bmp.Canvas.EndScene;
        {$ENDIF}
        if Assigned(g) then
          g.Free;
      end;

      OffsetRectEx(rt, x, y);
      if ACache is TTMSFNCPlannerGroupsTopCache then
        cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikGroupTop)
      else
        cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikGroupBottom);

      cache.Group := I;
      ACache.Add(cache);
    end
    else
    begin
      OffsetRectEx(rc, x, y);
      if ACache is TTMSFNCPlannerGroupsTopCache then
        cache := TTMSFNCPlannerCacheItem.CreateGroupTop(rc, I, grp.StartPosition, grp.EndPosition)
      else
        cache := TTMSFNCPlannerCacheItem.CreateGroupBottom(rc, I, grp.StartPosition, grp.EndPosition);

      ACache.Add(cache);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateGroupsCache;
begin
  UpdateGroupCache(FGroupsTopCache);
  UpdateGroupCache(FGroupsBottomCache);
  UpdateGroupsDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateGroupsDisplay;
begin
  BuildDisplay(FGroupsTopCache, FGroupsTopDisplay);
  BuildDisplay(FGroupsBottomCache, FGroupsBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.UpdateFullDayCache(ACache: TTMSFNCPlannerCache);
var
  w, h: Double;
  I: Integer;
  grp: TTMSFNCPlannerDisplayFullDay;
  cr: TRectF;
  x, y, bw, bh: Double;
  rt, rc: TRectF;
  bmp: TBitmap;
  g: TTMSFNCGraphics;
  cache: TTMSFNCPlannerCacheItem;
  bmpvalid: Boolean;
begin
  inherited;
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (ColumnCount = 0) or (FDisplayFullDays.Count = 0) or not Assigned(ACache) then
    Exit;

  ACache.Clear;

  if (ACache is TTMSFNCPlannerFullDaysTopCache) and (GetFullDaysTopSize <= 0) then
    Exit;

  if (ACache is TTMSFNCPlannerFullDaysBottomCache) and (GetFullDaysBottomSize <= 0) then
    Exit;

  bmpvalid := False;

  cr := GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      h := cr.Bottom - cr.Top;
      if ACache is TTMSFNCPlannerFullDaysTopCache then
        w := GetFullDaysTopSize + 1
      else
        w := GetFullDaysBottomSize + 1;
    end;
    pomVertical:
    begin
      w := cr.Right - cr.Left;
      if ACache is TTMSFNCPlannerFullDaysTopCache then
        h := GetFullDaysTopSize + 1
      else
        h := GetFullDaysBottomSize + 1;
    end;
    else
    begin
      w := 0;
      h := 0;
    end;
  end;

  for I := 0 to FDisplayFullDays.Count - 1 do
  begin
    grp := FDisplayFullDays[I];

    {$IFDEF FMXWEBLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        x := 0;
        y := Int(ColumnPositions[grp.StartPosition]);
        bw := w;
        bh := Int(ColumnPositions[grp.EndPosition + 1] - y);
      end;
      pomVertical:
      begin
        y := 0;
        x := Int(ColumnPositions[grp.StartPosition]);
        bh := h;
        bw := Int(ColumnPositions[grp.EndPosition + 1] - x);
      end;
      else
      begin
        bw := 0;
        bh := 0;
        x := 0;
        y := 0;
      end;
    end;
    {$ENDIF}
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        x := 0;
        y := ColumnPositions[grp.StartPosition];
        bw := w;
        bh := ColumnPositions[grp.EndPosition + 1] - y;
      end;
      pomVertical:
      begin
        y := 0;
        x := ColumnPositions[grp.StartPosition];
        bh := h;
        bw := ColumnPositions[grp.EndPosition + 1] - x;
      end
      else
      begin
        bw := 0;
        bh := 0;
        x := 0;
        y := 0;
      end;
    end;
    {$ENDIF}

    if (bw <= 0) or (bh <= 0) then
      Continue;

    rt := RectF(0, 0, bw, bh);
    bmp := nil;
    if FullDaysCaching then
      bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

    g := nil;
    if bmpvalid then
      g := TTMSFNCGraphics.Create(bmp.Canvas);

    {$IFDEF FMXWEBLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        rc.Left := int(rt.Left) + 0.5;
        rc.Right := int(rt.Right) - 0.5;
        rc.Top := int(rt.Top) + 0.5;
        rc.Bottom := int(rt.Bottom) + 0.5;
      end;
      pomVertical:
      begin
        rc.Top := int(rt.Top) + 0.5;
        rc.Bottom := int(rt.Bottom) - 0.5;
        rc.Left := int(rt.Left) + 0.5;
        rc.Right := int(rt.Right) + 0.5;
      end;
    end;
    {$ENDIF}
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        rc.Left := rt.Left;
        rc.Right := rt.Right;
        rc.Top := rt.Top;
        rc.Bottom := rt.Bottom + 1;
      end;
      pomVertical:
      begin
        rc.Top := rt.Top;
        rc.Bottom := rt.Bottom;
        rc.Left := rt.Left;
        rc.Right := rt.Right + 1;
      end;
    end;
    {$ENDIF}

    if FullDaysCaching then
    begin
      if bmpvalid then
      begin
        if ACache is TTMSFNCPlannerFullDaysTopCache then
          DrawFullDay(g, rc, I, grp.StartPosition, grp.EndPosition, ikFullDayTop)
        else
          DrawFullDay(g, rc, I, grp.StartPosition, grp.EndPosition, ikFullDayBottom);

        {$IFDEF FMXLIB}
        bmp.Canvas.EndScene;
        {$ENDIF}
        if Assigned(g) then
          g.Free;
      end;

      OffsetRectEx(rt, x, y);
      if ACache is TTMSFNCPlannerFullDaysTopCache then
        cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikFullDayTop)
      else
        cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikFullDayBottom);

      cache.FullDay := I;
      ACache.Add(cache);
    end
    else
    begin
      OffsetRectEx(rc, x, y);
      if ACache is TTMSFNCPlannerFullDaysTopCache then
        cache := TTMSFNCPlannerCacheItem.CreateFullDayTop(rc, I, grp.StartPosition, grp.EndPosition)
      else
        cache := TTMSFNCPlannerCacheItem.CreateFullDayBottom(rc, I, grp.StartPosition, grp.EndPosition);

      ACache.Add(cache);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateFullDaysCache;
begin
  UpdateFullDayCache(FFullDaysTopCache);
  UpdateFullDayCache(FFullDaysBottomCache);
  UpdateFullDaysDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateFullDaysDisplay;
begin
  BuildDisplay(FFullDaysTopCache, FFullDaysTopDisplay);
  BuildDisplay(FFullDaysBottomCache, FFullDaysBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.UpdateInplaceEditorPosition;
var
  it: TTMSFNCPlannerItemOpen;
  c: TTMSFNCPlannerCacheItem;
  r: TRectF;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  if Assigned(FInplaceEditor) and FInplaceEditorActive then
  begin
    if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    begin
      it := TTMSFNCPlannerItemOpen(Items[FUpdateItem]);
      c := nil;
      if (FDownCacheItemIdx >= 0) and (FDownCacheItemIdx <= it.CacheList.Count - 1) then
        c := it.CacheList[FDownCacheItemIdx];

      r := GetInplaceEditorRect(c, it);
      {$IFDEF FMXLIB}
      FInplaceEditor.BoundsRect := r;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      FInplaceEditor.BoundsRect := Bounds(Round(r.Left), Round(r.Top), Round(r.Right - r.Left), Round(r.Bottom - r.Top));
      {$ENDIF}
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateFullDayConflicts;
var
  I, J: Integer;
  itc, it: TTMSFNCPlannerItemOpen;
  sp, ep: Integer;
  C: Integer;
  sta, ste, mxa, mxe: TDateTime;
  l: TTMSFNCPlannerSelectedItems;
  K: Integer;
  cnt, idx: Integer;

  function ContainsIndex(AIndex: Integer; ALayout: TTMSFNCPlannerFullDayLayout; AList: TTMSFNCPlannerIntegerList): Boolean;
  var
    L: Integer;
  begin
    Result := False;
    for L := 0 to AList.Count - 1 do
    begin
      itc := TTMSFNCPlannerItemOpen(Items[AList[L]]);
      if ((ALayout = pfdlTop) and (itc.FullDayMaxListTopIndex = idx)) or ((ALayout = pfdlBottom) and (itc.FullDayMaxListBottomIndex = idx)) then
      begin
        Result := True;
        Break;
      end;
    end;
  end;
begin
  l := TTMSFNCPlannerSelectedItems.Create;
  try
    for I := 0 to Items.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(Items[I]);
      it.FullDayCacheList.Clear;
      it.FullDayPosListTop.Clear;
      it.FullDayPosListBottom.Clear;
      it.FullDayMaxListTop.Clear;
      it.FullDayMaxListBottom.Clear;
      it.FullDayMaxListTopIndex := -1;
      it.FullDayMaxListBottomIndex := -1;
      it.FullDayMaxListTopCount := 0;
      it.FullDayMaxListBottomCount := 0;
    
      if IsFullDayItem(it) and IsValidItem(it) then
      begin
        l.Add(it);
        case GetDisplayMode of
          pmDay:
          begin
            sp := it.Resource;
            ep := it.Resource;
          end;
          pmMultiDay, pmMultiResDay, pmMultiDayRes:
          begin
            sp := DateTimeToPosition(Int(it.StartTime));
            ep := DateTimeToPosition(Int(it.EndTime) + 1, True);
            case GetDisplayMode of
              pmMultiResDay:
              begin
                sp := sp + it.Resource;
                ep := ep + it.Resource;
              end;
              pmMultiDayRes:
              begin
                sp := sp + (it.Resource * Max(1, GetNumDays));
                ep := ep + (it.Resource * Max(1, GetNumDays));
              end;
            end;
          end
          else
          begin
            sp := -1;
            ep := -1;
          end;
        end;

        for C := sp to ep do
        begin
          if (((GetDisplayMode in [pmMultiResDay, pmMultiDayRes]) and (PositionToResource(C) = it.Resource)) or not (GetDisplayMode in [pmMultiResDay, pmMultiDayRes])) then
          begin
            sta := MaxPositionDateTime(Int(it.StartTime), False, C);
            ste := MaxPositionDateTime(Int(it.EndTime) + 1, True, C);
            mxa := CalculatePositionDateTime(FDisplayStartTime, C);
            mxe := CalculatePositionDateTime(FDisplayEndTime, C);
            if (((CompareDateTime(sta, mxa) >= EqualsValue) and (CompareDateTime(sta, mxe) <= EqualsValue))
              or ((CompareDateTime(ste, mxa) >= EqualsValue) and (CompareDateTime(ste, mxe) <= EqualsValue)))
                and (ste - sta > 0) then
                begin
                  if pfdlTop in it.FullDayLayout then
                    it.FullDayPosListTop.Add(C);

                  if pfdlBottom in it.FullDayLayout then
                    it.FullDayPosListBottom.Add(C);
                end;
          end;
        end;
      end;
    end;

    for I := 0 to l.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(l[I]);
      for J := 0 to l.Count - 1 do
      begin
        itc := TTMSFNCPlannerItemOpen(l[J]);
        if itc <> it then
        begin
          for K := 0 to it.FullDayPosListTop.Count - 1 do
          begin
            if it.FullDayMaxListTop.IndexOf(itc.Index) = -1 then
            begin
              if HasFullDayItemPosition(pfdlTop, it.FullDayPosListTop[K], itc) then
              begin
                it.FullDayMaxListTop.Add(itc.Index);
                itc.FullDayMaxListTop.Add(it.Index);
              end;
            end;
          end;

          for K := 0 to it.FullDayPosListBottom.Count - 1 do
          begin
            if it.FullDayMaxListBottom.IndexOf(itc.Index) = -1 then
            begin
              if HasFullDayItemPosition(pfdlBottom, it.FullDayPosListBottom[K], itc) then
              begin
                it.FullDayMaxListBottom.Add(itc.Index);
                itc.FullDayMaxListBottom.Add(it.Index);
              end;
            end;
          end;
        end;
      end;
    end;

    for I := 0 to l.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(l[I]);

      idx := 0;

      while ContainsIndex(idx, pfdlTop, it.FullDayMaxListTop) do
        Inc(idx);

      it.FullDayMaxListTopIndex := idx;

      idx := 0;

      while ContainsIndex(idx, pfdlBottom, it.FullDayMaxListBottom) do
        Inc(idx);

      it.FullDayMaxListBottomIndex := idx;
    end;

    for I := 0 to l.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(l[I]);

      cnt := it.FullDayMaxListTopIndex;
      for K := 0 to it.FullDayMaxListTop.Count - 1 do
      begin
        itc := TTMSFNCPlannerItemOpen(Items[it.FullDayMaxListTop[K]]);
        cnt := Max(cnt, itc.FullDayMaxListTopIndex);
      end;

      it.FullDayMaxListTopCount := cnt + 1;

      cnt := it.FullDayMaxListBottomIndex;
      for K := 0 to it.FullDayMaxListBottom.Count - 1 do
      begin
        itc := TTMSFNCPlannerItemOpen(Items[it.FullDayMaxListBottom[K]]);
        cnt := Max(cnt, itc.FullDayMaxListBottomIndex);
      end;

      it.FullDayMaxListBottomCount := cnt + 1;
    end;

    for I := 0 to l.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(l[I]);

      cnt := it.FullDayMaxListTopCount;
      for K := 0 to it.FullDayMaxListTop.Count - 1 do
      begin
        itc := TTMSFNCPlannerItemOpen(Items[it.FullDayMaxListTop[K]]);
        cnt := Max(cnt, itc.FullDayMaxListTopCount);
      end;

      it.FullDayMaxListTopCount := cnt;

      cnt := it.FullDayMaxListBottomCount;
      for K := 0 to it.FullDayMaxListBottom.Count - 1 do
      begin
        itc := TTMSFNCPlannerItemOpen(Items[it.FullDayMaxListBottom[K]]);
        cnt := Max(cnt, itc.FullDayMaxListBottomCount);
      end;

      it.FullDayMaxListBottomCount := cnt;
    end;

      
  finally
    l.Free;
  end;  
end;

procedure TTMSFNCCustomPlanner.UpdateFullDaysItemCache(ACache: TTMSFNCPlannerCache);
var
  it: TTMSFNCPlannerItemOpen;
  frt, cr: TRectF;
  x, y, bw, bh: Double;
  I, J: Integer;
  P: Integer;
  K: Integer;
  rt, rc: TRectF;
  cache: TTMSFNCPlannerCacheItem;
  l: TTMSFNCPlannerIntegerList;
  n, idx: Integer;
  lcn: Integer;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(ACache) then
    Exit;

  ACache.Clear;

  if (ACache is TTMSFNCPlannerFullDaysItemTopCache) and (GetFullDaysTopSize <= 0) then
    Exit;

  if (ACache is TTMSFNCPlannerFullDaysItemBottomCache) and (GetFullDaysBottomSize <= 0) then
    Exit;

  if ACache is TTMSFNCPlannerFullDaysItemTopCache then
    frt := GetFullDaysTopRect
  else
    frt := GetFullDaysBottomRect;

  cr := frt;

  for I := 0 to Items.Count - 1 do
  begin
    it := TTMSFNCPlannerItemOpen(Items[I]);

    if IsFullDayItem(it) and IsValidItem(it) then
    begin
      l := nil;
      if (ACache is TTMSFNCPlannerFullDaysItemTopCache) then
        l := it.FullDayPosListTop;

      if (ACache is TTMSFNCPlannerFullDaysItemBottomCache) then
        l := it.FullDayPosListBottom;

      if Assigned(l) and (l.Count > 0) then
      begin
        n := 0;
        idx := 0;

        if (ACache is TTMSFNCPlannerFullDaysItemTopCache) then
        begin
          n := it.FullDayMaxListTopCount;
          idx := it.FullDayMaxListTopIndex;
        end;

        if (ACache is TTMSFNCPlannerFullDaysItemBottomCache) then
        begin
          n := it.FullDayMaxListBottomCount;
          idx := it.FullDayMaxListBottomIndex;
        end;

        if GetDisplayMode <> pmMultiResDay then
          lcn := 0
        else
          lcn := l.Count - 1;

        for K := 0 to lcn do
        begin
          p := l[K];

          case OrientationMode of
            pomHorizontal:
            begin
              x := 0;
              bw := 0;
              if n > 0 then
              begin
                bw := (frt.Right - frt.Left) / n;
                x := bw * idx;
              end;

              y := ColumnPositions[p];
              bh := ColumnWidths[p];
              if GetDisplayMode <> pmMultiResDay then
              begin
                for J := 1 to l.Count - 1 do
                  bh := bh + ColumnWidths[l[J]];
              end;
            end;
            pomVertical:
            begin
              y := 0;
              bh := 0;
              if n > 0 then
              begin
                bh := (frt.Bottom - frt.Top) / n;
                y := bh * idx;
              end;

              x := ColumnPositions[p];
              bw := ColumnWidths[p];

              if GetDisplayMode <> pmMultiResDay then
              begin
                for J := 1 to l.Count - 1 do
                  bw := bw + ColumnWidths[l[J]];
              end;
            end;
            else
            begin
              x := 0;
              y := 0;
              bw := 0;
              bh := 0;
            end;
          end;

          if (bw <= 0) or (bh <= 0) then
          begin
            Continue;
          end;

          rt := RectF(0, 0, bw, bh);
          rc := rt;

          InflateRectEx(rc, -3, -3);

          if (ACache is TTMSFNCPlannerFullDaysItemTopCache) and (it.FullDayMaxListTopCount > 0) then
          begin
            if (it.FullDayMaxListTopIndex = 0) then
            begin
              case OrientationMode of
                pomHorizontal: rc.Right := rc.Right + 1.5;
                pomVertical: rc.Bottom := rc.Bottom + 1.5;
              end;
            end
            else
            begin
              case OrientationMode of
                pomHorizontal:
                begin
                  rc.Left := rc.Left - 1.5;
                  rc.Right := rc.Right + 1.5;
                end;
                pomVertical:
                begin
                  rc.Top := rc.Top - 1.5;
                  rc.Bottom := rc.Bottom + 1.5;
                end;
              end;
            end;
          end;

          if (ACache is TTMSFNCPlannerFullDaysItemBottomCache) and (it.FullDayMaxListBottomCount > 0) then
          begin
            if (it.FullDayMaxListBottomIndex = 0) then
            begin
              case OrientationMode of
                pomHorizontal: rc.Right := rc.Right + 1.5;
                pomVertical: rc.Bottom := rc.Bottom + 1.5;
              end;
            end
            else
            begin
              case OrientationMode of
                pomHorizontal:
                begin
                  rc.Left := rc.Left - 1.5;
                  rc.Right := rc.Right + 1.5;
                end;
                pomVertical:
                begin
                  rc.Top := rc.Top - 1.5;
                  rc.Bottom := rc.Bottom + 1.5;
                end;
              end;
            end;
          end;

          OffsetRectEx(rc, x, y);

          cache := nil;
          if (ACache is TTMSFNCPlannerFullDaysItemTopCache) then
            cache := TTMSFNCPlannerCacheItem.CreateFullDayItemTop(rc, p, it);

          if (ACache is TTMSFNCPlannerFullDaysItemBottomCache) then
            cache := TTMSFNCPlannerCacheItem.CreateFullDayItemBottom(rc, p, it);

          if Assigned(cache) then
            ACache.Add(cache);

          TTMSFNCPlannerItemOpen(it).FullDayCacheList.Add(cache);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateItemCache;
var
  ri: TRectF;
  bmp: TBitmap;
  rt: TRectF;
  cp: Integer;
  bw, bh, isz, iszo, ispos, isposo: Double;
  x, y: Double;
  it: TTMSFNCPlannerItemOpen;
  rc: TRectF;
  cache: TTMSFNCPlannerCacheItem;
  cw, ch: Double;
  I: Integer;
  cr: TRectF;
  hs, vs: Double;
  bmpvalid: Boolean;
  std, etd: Double;
  sta, ste, stadr, stedr: TDateTime;
  K: Integer;
  g: TTMSFNCGraphics;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(FItemCache) then
    Exit;

  if ColumnCount > 0 then
  begin
    cr := GetContentRect;
    hs := GetHorizontalScrollPosition;
    vs := GetVerticalScrollPosition;
    bmpvalid := False;
    case OrientationMode of
      pomHorizontal:
      begin
        cw := GetTotalRowHeight;
        ch := GetTotalColumnWidth;
      end;
      pomVertical:
      begin
        ch := GetTotalRowHeight;
        cw := GetTotalColumnWidth;
      end;
      else
      begin
        cw := 0;
        ch := 0;
      end;
    end;

    for I := 0 to Items.Count - 1 do
    begin
      it := TTMSFNCPlannerItemOpen(Items[I]);

      for K := it.CleanupList.Count - 1 downto 0 do
      begin
        RemoveItemsFromCache(it.CacheList, it.CleanupList[K]);
        it.CleanupList.Delete(K);
      end;

      if not IsValidItem(it) or IsFullDayItem(it) then
      begin
        RemoveItemsFromCache(it.CacheList);
        Continue;
      end;

      if not (ItemCachingMode = picmDelayedCaching) then
      begin
        if it.DirtyList.Count = 0 then
          Continue
        else
          RemoveItemsFromCache(it.CacheList);
      end;

      sta := it.StartTime;
      ste := it.EndTime;

      for K := it.DirtyList.Count - 1 downto 0 do
      begin
        cp := it.DirtyList[K];
        if (cp >= 0) and (cp <= ColumnCount - 1) then
        begin
          ispos := ColumnPositions[cp];
          isposo := ColumnPositions[cp];
          iszo := ColumnPositions[cp + 1] - ispos;
          isz := iszo;

          if (K >= 0) and (K <= it.ConflictsList.Count - 1) then
          begin
            if it.ConflictsList[K] > 0 then
              isz := isz / it.ConflictsList[K]
          end;

          if (K >= 0) and (K <= it.ConflictsPosList.Count - 1) then
            ispos := ispos + isz * it.ConflictsPosList[K];

          stadr := MaxPositionDateTime(sta, False, cp);
          stedr := MaxPositionDateTime(ste, True, cp);
          std := DateTimeToValue(stadr);
          if GetDisplayMode = pmMultiMonth then
          begin
            if Abs(stedr - stadr) >= 1 then
              etd := DateTimeToValue(stedr, True)
            else
              etd := DateTimeToValue(stedr);
          end
          else
            etd := DateTimeToValue(stedr, True);

          case OrientationMode of
            pomHorizontal:
            begin
              x := Round(std);
              bw := Round(etd - std);
              if it.Background then
              begin
                y := Floor(isposo);
                bh := iszo
              end
              else
              begin
                y := Floor(ispos);
                bh := Max(1, Round(isz - ItemsAppearance.Gap));
              end;
            end;
            pomVertical:
            begin
              y := Round(std);
              bh := Round(etd - std);
              if it.Background then
              begin
                x := Floor(isposo);
                bw := iszo;
              end
              else
              begin
                x := Floor(ispos);
                bw := Max(1, Round(isz - ItemsAppearance.Gap))
              end;
            end;
            else
            begin
              x := 0;
              y := 0;
              bw := 0;
              bh := 0;
            end;
          end;

          case OrientationMode of
            pomHorizontal:
            begin
              x := x + it.Margins.Top;
              y := y + it.Margins.Left;
              bw := bw - it.Margins.Top - it.Margins.Bottom;
              bh := bh - it.Margins.Left - it.Margins.Right;
            end;
            pomVertical:
            begin
              x := x + it.Margins.Left;
              y := y + it.Margins.Top;
              bw := bw - it.Margins.Left - it.Margins.Right;
              bh := bh - it.Margins.Top - it.Margins.Bottom;
            end;
          end;

          DoCustomizeItemRect(it, K, x, y, bw, bh);

          if (bw <= 0) or (bh <= 0) then
          begin
            RemoveItemsFromCache(it.CacheList, cp);
            Continue;
          end;

          ri := RectF(x, y, x + bw, y + bh);
          OffsetRectEx(ri, cr.Left, cr.Top);
          OffsetRectEx(ri, -hs, -vs);

          if ItemCachingMode = picmDelayedCaching then
          begin
            if not IntersectRectEx(ri, cr) then
            begin
              if not it.DirtyList.IndexOf(cp) > -1 then
                it.DirtyList.Add(cp);
              RemoveItemsFromCache(it.CacheList, cp);
              Continue;
            end
            else if not it.DirtyList.IndexOf(cp) > -1 then
              Continue
            else
              RemoveItemsFromCache(it.CacheList, cp);
          end;

          rt := RectF(0, 0, bw, bh);
          rc := rt;
          bmp := nil;
          if ItemCachingMode <> picmNoCaching then
            bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

          g := nil;
          if bmpvalid then
            g := TTMSFNCGraphics.Create(bmp.Canvas);

          {$IFDEF FMXWEBLIB}
          InflateRectEx(rc, -0.5, -0.5);
          {$ENDIF}

          rc.Left := rc.Left + 1;
          rc.Top := rc.Top + 1;
          case OrientationMode of
            pomHorizontal:
            begin
              if y + bh = ch then
                rc.Bottom := rc.Bottom - 1;
              if x + bw = cw then
                rc.Right := rc.Right - 1
            end;
            pomVertical:
            begin
              if y + bh = ch then
                rc.Bottom := rc.Bottom - 1;
              if x + bw = cw then
                rc.Right := rc.Right - 1;
            end;
          end;

          if ItemCachingMode <> picmNoCaching then
          begin
            if bmpvalid then
            begin
              DrawItem(g, rc, it, K, True);
              {$IFDEF FMXLIB}
              bmp.Canvas.EndScene;
              {$ENDIF}
              if Assigned(g) then
                g.Free;
            end;

            OffsetRectEx(rt, x, y);
            cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikItem, cp);
            cache.Item := it;
            cache.Idx := K;
            if Assigned(it) and it.Background then
              FItemCache.Insert(0, cache)
            else
              FItemCache.Add(cache);
          end
          else
          begin
            OffsetRectEx(rc, x, y);
            cache := TTMSFNCPlannerCacheItem.CreateItem(rc, cp, it, K);
            if Assigned(it) and it.Background then
              FItemCache.Insert(0, cache)
            else
              FItemCache.Add(cache);
          end;

          it.DirtyList.Remove(cp);

          if Assigned(it) and it.Background then
            it.CacheList.Insert(0, cache)
          else
            it.CacheList.Add(cache);
        end;
      end;
    end;
  end
  else
    FItemCache.Clear;
end;

procedure TTMSFNCCustomPlanner.UpdateFullDaysItemDisplay;
begin
  BuildDisplay(FFullDaysItemTopCache, FFullDaysItemTopDisplay);
  BuildDisplay(FFullDaysItemBottomCache, FFullDaysItemBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.UpdateItemDisplay;
begin
  BuildDisplay(FItemCache, FItemDisplay);
end;

function TTMSFNCCustomPlanner.MaxPositionDateTime(ADateTime: TDatetime; AEndDateTime: Boolean; APosition: Integer): TDateTime;
var
  dtmx: TDateTime;
begin
  case GetDisplayMode of
    pmMultiDayRes:
    begin
      if AEndDatetime then
        Result := Min(IncDay(FDisplayEndTime, APosition mod Max(1, GetNumDays)), ADateTime)
      else
        Result := Max(IncDay(FDisplayStartTime, APosition mod Max(1, GetNumDays)), ADateTime);
    end;
    pmMultiResDay:
    begin
      if AEndDatetime then
        Result := Min(IncDay(FDisplayEndTime, APosition div Max(1, Resources.Count)), ADateTime)
      else
        Result := Max(IncDay(FDisplayStartTime, APosition div Max(1, Resources.Count)), ADateTime);
    end;
    pmMultiDay:
    begin
      if AEndDatetime then
        Result := Min(IncDay(FDisplayEndTime, APosition), ADateTime)
      else
        Result := Max(IncDay(FDisplayStartTime, APosition), ADateTime);
    end;
    pmMultiMonth:
    begin
      dtmx := IncMonth(FDisplayStartTime, APosition);
      if AEndDatetime then
        Result := Min(EncodeDate(YearOf(dtmx), MonthOf(dtmx), DaysInMonth(dtmx)) + 1, ADateTime)
      else
        Result := Max(dtmx, ADateTime);
    end
    else
    begin
      if AEndDatetime then
        Result := Min(FDisplayEndTime, ADateTime)
      else
        Result := Max(FDisplayStartTime, ADateTime);
    end;
  end;
end;

function TTMSFNCCustomPlanner.CalculatePositionDateTime(ADateTime: TDatetime;
  APosition: Integer): TDateTime;
begin
  Result := ADateTime;
  case GetDisplayMode of
    pmMultiDayRes: Result := IncDay(Result, APosition mod Max(1, GetNumDays));
    pmMultiResDay: Result := IncDay(Result, APosition div Max(1, Resources.Count));
    pmMultiDay: Result := IncDay(Result, APosition);
    pmMultiMonth: Result := IncMonth(Result, APosition);
  end;
end;

procedure TTMSFNCCustomPlanner.CallAfterMoveLinkedItems(AItem: TTMSFNCPlannerItem);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if (AItem.LinkType <> iltNone) and lio.CanUpdate then
  begin
    DoAfterMoveItem(li, li.StartTime, li.EndTime, TTMSFNCPlannerItemOpen(li).NewPosition);
    DoItemChanged(li);
  end;

  lio.CanUpdate := False;
  CallAfterMoveLinkedItems(li);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallAfterSizeLinkedItems(AItem: TTMSFNCPlannerItem);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;
  if (AItem.LinkType <> iltNone) and lio.CanUpdate then
  begin
    DoAfterSizeItem(li, li.StartTime, li.EndTime, TTMSFNCPlannerItemOpen(li).NewPosition);
    DoItemChanged(li);
  end;

  lio.CanUpdate := False;
  CallAfterSizeLinkedItems(li);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallAfterUpdateLinkedItems(
  AItem: TTMSFNCPlannerItem);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;
  if (AItem.LinkType <> iltNone) and (lio.CanUpdate) then
  begin
    DoAfterUpdateItem(li.StartTime, li.EndTime, TTMSFNCPlannerItemOpen(li).NewPosition, li);
    DoItemChanged(li);
  end;

  lio.CanUpdate := False;
  CallAfterUpdateLinkedItems(li);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallBeforeMoveLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
  b: Boolean;
  nst, net: TDateTime;
  nrs: Integer;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if AItem.LinkType <> iltNone then
  begin
    nst := lio.NewStartTime;
    net := lio.NewEndTime;
    GetNewDateTimeAndResource(li, AItem.LinkType, ADiffStartDateTime, ADiffEndDateTime, nst, net);
    nrs := ResourceToPosition(li.Resource + ADiffResource);
    lio.CanUpdate := True;
    b := True;
    DoBeforeMoveItem(li, nst, net, nrs, b);
    lio.NewStartTime := nst;
    lio.NewEndTime := net;
    lio.NewPosition := nrs;
    lio.CanUpdate := b;
  end;

  CallBeforeMoveLinkedItems(li, ADiffStartDateTime, ADiffEndDateTime, ADiffResource);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallBeforeSizeLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
  b: Boolean;
  nst, net: TDateTime;
  nrs: Integer;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if AItem.LinkType <> iltNone then
  begin
    nst := lio.NewStartTime;
    net := lio.NewEndTime;
    GetNewDateTimeAndResource(li, AItem.LinkType, ADiffStartDateTime, ADiffEndDateTime, nst, net);
    nrs := ResourceToPosition(li.Resource + ADiffResource);
    lio.CanUpdate := True;
    b := True;
    DoBeforeSizeItem(li, nst, net, nrs, b);
    lio.NewStartTime := nst;
    lio.NewEndTime := net;
    lio.NewPosition := nrs;
    lio.CanUpdate := b;
  end;

  CallBeforeSizeLinkedItems(li, ADiffStartDateTime, ADiffEndDateTime, ADiffResource);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallBeforeUpdateLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartDateTime, ADiffEndDateTime: TDateTime; ADiffResource: Integer);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
  b: Boolean;
  nst, net: TDateTime;
  nrs: Integer;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if AItem.LinkType <> iltNone then
  begin
    nst := lio.NewStartTime;
    net := lio.NewEndTime;
    GetNewDateTimeAndResource(li, AItem.LinkType, ADiffStartDateTime, ADiffEndDateTime, nst, net);
    nrs := ResourceToPosition(li.Resource + ADiffResource);
    lio.CanUpdate := True;
    b := True;
    DoBeforeMoveItem(li, nst, net, nrs, b);
    lio.NewStartTime := nst;
    lio.NewEndTime := net;
    lio.NewPosition := nrs;
    lio.CanUpdate := b;
  end;

  CallBeforeUpdateLinkedItems(li, ADiffStartDateTime, ADiffEndDateTime, ADiffResource);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallMoveLinkedItems(AItem: TTMSFNCPlannerItem);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if (AItem.LinkType <> iltNone) and lio.CanUpdate then
    DoMoveItem(li, li.StartTime, li.EndTime, TTMSFNCPlannerItemOpen(li).NewPosition);

  CallMoveLinkedItems(li);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CallSizeLinkedItems(AItem: TTMSFNCPlannerItem);
var
  li: TTMSFNCPlannerItem;
  lio: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  lio := TTMSFNCPlannerItemOpen(li);
  if lio.UpdatingLinked then
    Exit;

  lio.UpdatingLinked := True;

  if (AItem.LinkType <> iltNone) and lio.CanUpdate then
    DoSizeItem(li, li.StartTime, li.EndTime, TTMSFNCPlannerItemOpen(li).NewPosition);

  CallSizeLinkedItems(li);
  lio.UpdatingLinked := False;
end;

procedure TTMSFNCCustomPlanner.CancelEditing;
begin
  if FEditorDialogActive then
    CloseEditingDialog(True)
  else if FInplaceEditorActive then
    CloseInplaceEditor(True);
end;

function TTMSFNCCustomPlanner.CanDeleteCacheItem(
  ACacheItem: TTMSFNCPlannerCacheItem): Boolean;
begin
  Result := Assigned(ACacheItem) and (ACacheItem.Idx = 0);
end;

function TTMSFNCCustomPlanner.CanMoveCacheItem(
  ACacheItem: TTMSFNCPlannerCacheItem): Boolean;
begin
  Result := True;
end;

function TTMSFNCCustomPlanner.CanNavigate: Boolean;
begin
  Result := True;
end;

function TTMSFNCCustomPlanner.CanSizeCacheItemEndTime(
  ACacheItem: TTMSFNCPlannerCacheItem): Boolean;
begin
  Result := False;
  if Assigned(ACacheItem) and Assigned(ACacheItem.Item) then
    Result := ACacheItem.Idx = TTMSFNCPlannerItemOpen(ACacheItem.Item).CacheList.Count - 1;
end;

function TTMSFNCCustomPlanner.CanSizeCacheItemStartTime(
  ACacheItem: TTMSFNCPlannercacheItem): Boolean;
begin
  Result := Assigned(ACacheItem) and (ACacheItem.Idx = 0);
end;

function TTMSFNCCustomPlanner.CellToDateTime(ACell: TTMSFNCPlannerCell): TDateTime;
begin
  Result := ValueToDateTime(RowPositions[ACell.Row], ACell.Col, ACell.Row);
end;

function TTMSFNCCustomPlanner.CellToEndDateTime(
  ACell: TTMSFNCPlannerCell): TDateTime;
begin
  Result := CellToDateTime(MakeCell(ACell.Col, ACell.Row + 1));
end;

function TTMSFNCCustomPlanner.CellToStartDatetime(
  ACell: TTMSFNCPlannerCell): TDateTime;
begin
  Result := CellToDateTime(ACell);
end;

procedure TTMSFNCCustomPlanner.CloseEditingDialog(ACancel: Boolean);
var
  p: TTMSFNCPlannerEditingDialog;
  sta: TDateTime;
  ste: TDateTime;
  t: String;
  n: String;
  b: Boolean;
  it: TTMSFNCPlannerItem;
  res: Integer;
  s, c: Boolean;
  sc: TTMSFNCPlannerCell;
  f: Boolean;
  {$IFDEF CMNWEBLIB}
  {$IFDEF WEBLIB}
  prtn: TControl;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  prtn: TWinControl;
  {$ENDIF}
  {$ENDIF}
begin
  it := nil;
  if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    it := Items[FUpdateItem];

  sta := 0;
  ste := 0;

  if not ACancel then
  begin
    if Assigned(CustomItemEditorForm) and Assigned(FEditorForm) then
    begin
      sta := FEditorForm.PlannerStartTime;
      ste := FEditorForm.PlannerEndTime;

      t := FEditorForm.PlannerTitle;
      n := FEditorForm.PlannerText;

      f := FEditorForm.PlannerFullDay;

      res := FEditorForm.PlannerResource;
    end
    else
    begin
      p := GetEditingDialog(FUpdateItem);
      sta := Int(Now);
      if Assigned(p.StartDateEdit) then
        sta := Int(p.StartDateEdit.Date);

      if Assigned(p.StartTimeEdit) then
        sta := sta + Frac(p.StartTimeEdit.Time);

      ste := Int(Now);
      if Assigned(p.EndDateEdit) then
        ste := Int(p.EndDateEdit.Date);

      if Assigned(p.EndTimeEdit) then
        ste := ste + Frac(p.EndTimeEdit.Time);

      t := DefaultItem.Title;
      if Assigned(p.TitleEdit) then
        t := p.TitleEdit.Text;

      n := DefaultItem.Text;
      if Assigned(p.TextMemo) then
        n := p.TextMemo.Text;

      f := DefaultItem.FullDay;
      if Assigned(p.FullDayCheckBox) then
      begin
        {$IFDEF FMXLIB}
        f := p.FullDayCheckBox.IsChecked;
        {$ELSE}
        f := p.FullDayCheckBox.Checked;
        {$ENDIF}
      end;

      res := FInsertResource;
      if Assigned(p.ResourcesComboBox) then
      begin
        {$IFDEF CMNWEBLIB}
        prtn := FResourcesComboBox.Parent;
        {$IFNDEF WEBLIB}
        if not p.ResourcesComboBox.HandleAllocated then
          p.ResourcesComboBox.Parent := Application.MainForm;
        {$ENDIF}
        {$ENDIF}
        res := p.ResourcesComboBox.ItemIndex;
        {$IFDEF CMNWEBLIB}
        p.ResourcesComboBox.Parent := prtn;
        {$ENDIF}
      end;
    end;

    if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    begin
      it := Items[FUpdateItem];
      b := True;
      DoBeforeUpdateItem(sta, ste, res, it, t, n, b);
      if b then
      begin
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := True;
        CallBeforeUpdateLinkedItems(it, sta - it.StartTime, ste - it.EndTime, res - it.Resource);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := False;
        it := AddOrUpdateItem(res, sta, ste, t, n, FUpdateItem);
        it.FullDay := f;
        if not Assigned(CustomItemEditorForm) and not Assigned(FEditorForm) then
        begin
          if p.CustomContentPanel and Assigned(p.ContentPanel) and not ACancel then
            DoCustomContentPanelToItem(p.ContentPanel, it);
        end;

        DoAfterUpdateItem(sta, ste, res, it);
        DoItemChanged(it);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := True;
        CallAfterUpdateLinkedItems(it);
        TTMSFNCPlannerItemOpen(it).UpdatingLinked := False;
      end;
    end
    else
    begin
      b := True;
      DoBeforeInsertItem(sta, ste, res, t, n,  b);
      if b then
      begin
        it := AddOrUpdateItem(res, sta, ste, t, n);
        it.FullDay := f;
        if not Assigned(CustomItemEditorForm) and not Assigned(FEditorForm) then
        begin
          if p.CustomContentPanel and Assigned(p.ContentPanel) and not ACancel then
            DoCustomContentPanelToItem(p.ContentPanel, it);
        end;
        DoAfterInsertItem(sta, ste, res, it);
        s := it.Selectable and it.Enabled;
        DoBeforeSelectItem(it, s);
        if s then
        begin
          if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
            HandleSelectLinkedItems(it)
          else
            HandleSelectItem(it);

          DoAfterSelectItem(it);
        end;
      end;
    end;
  end;

  c := True;
  if Assigned(it) then
    sc := ItemToStartCell(it);

  if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    DoCloseUpdateDialog(sta, ste, sc.Col, it, ACancel, c)
  else
    DoCloseInsertDialog(sta, ste, sc.Col, it, ACancel, c);

  if c then
  begin
    if Assigned(CustomItemEditorForm) and Assigned(FEditorForm) then
    begin
      FEditorForm.Close;
      FEditorForm := nil;
    end
    else
    begin
      if ACancel then
        p := GetEditingDialog(FUpdateItem);

      if Assigned(p.Background) then
        p.Background.Parent := nil;
      if Assigned(p.Panel) then
        p.Panel.Parent := nil;
    end;

    if CanFocus then
      SetFocus;

    FEditorDialogActive := False;
  end;
end;

procedure TTMSFNCCustomPlanner.CloseEditingDialogAndRemoveItem;
begin
  if (FUpdateItem >= 0) and (FUpdateItem <= Items.Count - 1) then
    HandleItemDelete(Items[FUpdateItem], pidmDialog);
  CloseEditingDialog(True);
end;

constructor TTMSFNCCustomPlanner.Create(AOwner: TComponent);
begin
  inherited;
  FFindItemIndex := 0;
  FNeedsInitialization := True;
  FSelectedItems := TTMSFNCPlannerSelectedItems.Create;
  FCustomDatesList := TTMSFNCPlannerDateTimes.Create;
  {$IFNDEF LCLWEBLIB}
  FCompareCustomDates := TDelegatedComparer<TDateTime>.Create(
    function(const Item1, Item2: TDateTime): Integer
    begin
      Result := CompareDateTime(Item1, Item2);
    end
    );
  {$ENDIF}
  FGroupsCaching := False;
  FFullDaysCaching := False;
  FGridCaching := False;
  FPositionsCaching := False;
  FTimeLineCaching := False;
  FMode := pmMultiDay;

  FCustomDateTimes := TTMSFNCPlannerDateTimes.Create;
  FDisplayGroups := TTMSFNCPlannerDisplayGroups.Create;
  FDisplayFullDays := TTMSFNCPlannerDisplayFullDays.Create;
  FConflicts := TTMSFNCPlannerConflicts.Create;
  FGridCache := TTMSFNCPlannerGridCache.Create;
  FItemCache := TTMSFNCPlannerItemCache.Create;
  FFullDaysItemTopCache := TTMSFNCPlannerFullDaysItemTopCache.Create;
  FFullDaysItemBottomCache := TTMSFNCPlannerFullDaysItemBottomCache.Create;
  FPositionsTopCache := TTMSFNCPlannerPositionsTopCache.Create;
  FTimeLineLeftCache := TTMSFNCPlannerTimeLineLeftCache.Create;
  FGroupsTopCache := TTMSFNCPlannerGroupsTopCache.Create;
  FFullDaysTopCache := TTMSFNCPlannerFullDaysTopCache.Create;
  FPositionsBottomCache := TTMSFNCPlannerPositionsBottomCache.Create;
  FTimeLineRightCache := TTMSFNCPlannerTimeLineRightCache.Create;
  FGroupsBottomCache := TTMSFNCPlannerGroupsBottomCache.Create;
  FFullDaysBottomCache := TTMSFNCPlannerFullDaysBottomCache.Create;

  FGridDisplay := TTMSFNCPlannerGridDisplayList.Create;
  FItemDisplay := TTMSFNCPlannerItemDisplayList.Create;
  FFullDaysItemTopDisplay := TTMSFNCPlannerFullDaysItemTopDisplayList.Create;
  FFullDaysItemBottomDisplay := TTMSFNCPlannerFullDaysItemBottomDisplayList.Create;
  FPositionsTopDisplay := TTMSFNCPlannerPositionsTopDisplayList.Create;
  FTimeLineLeftDisplay := TTMSFNCPlannerTimeLineLeftDisplayList.Create;
  FGroupsTopDisplay := TTMSFNCPlannerGroupsTopDisplayList.Create;
  FFullDaysTopDisplay := TTMSFNCPlannerFullDaysTopDisplayList.Create;
  FPositionsBottomDisplay := TTMSFNCPlannerPositionsBottomDisplayList.Create;
  FTimeLineRightDisplay := TTMSFNCPlannerTimeLineRightDisplayList.Create;
  FGroupsBottomDisplay := TTMSFNCPlannerGroupsBottomDisplayList.Create;
  FFullDaysBottomDisplay := TTMSFNCPlannerFullDaysBottomDisplayList.Create;

  FSelectionAppearance := TTMSFNCPlannerSelectionAppearance.Create(Self);
  FGridCellAppearance := TTMSFNCPlannerGridCellAppearance.Create(Self);
  FItemsAppearance := TTMSFNCPlannerItemsAppearance.Create(Self);
  FTimeLine := TTMSFNCPlannerTimeLine.Create(Self);
  FModeSettings := TTMSFNCPlannerModeSettings.Create(Self);
  FTimeLineAppearance := TTMSFNCPlannerTimeLineAppearance.Create(Self);
  FPositionsAppearance := TTMSFNCPlannerPositionsAppearance.Create(Self);
  FPositions := TTMSFNCPlannerPositions.Create(Self);
  FGroupsAppearance := TTMSFNCPlannerGroupsAppearance.Create(Self);
  FFullDaysAppearance := TTMSFNCPlannerFullDaysAppearance.Create(Self);
  FInteraction := TTMSFNCPlannerInteraction.Create(Self);
  FDefaultItem := TTMSFNCPlannerItem.Create(nil);

  FAnimateTimer := TTimer.Create(Self);
  FAnimateTimer.Interval := 1;
  FAnimateTimer.Enabled := False;
  FAnimateTimer.OnTimer := Animate;

  FDownTimer := TTimer.Create(Self);
  FDownTimer.Interval := 1;
  FDownTimer.Enabled := False;
  FDownTimer.OnTimer := DownTime;

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);

  Width := 600;
  Height := 450;
  if (csDesigning in ComponentState) and not ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState)) then
    InitSample;
end;

procedure TTMSFNCCustomPlanner.FixStroke(AGraphics: TTMSFNCGraphics);
begin
  if (AGraphics.Stroke.Color = gcNull) or (AGraphics.Stroke.Kind = gskNone) then
  begin
    AGraphics.Stroke.Kind := gskSolid;
    AGraphics.Stroke.Color := AGraphics.Fill.Color;
  end;
end;

function TTMSFNCCustomPlanner.ColumnStretchingActive: Boolean;
begin
  Result := PositionsAppearance.Stretch;
end;

procedure TTMSFNCCustomPlanner.SetEditingDialogTabOrder;
begin
  if Assigned(FStartDateEdit) then
    FStartDateEdit.TabOrder := 0;

  if Assigned(FStartTimeEdit) then
    FStartTimeEdit.TabOrder := 1;

  if Assigned(FEndDateEdit) then
    FEndDateEdit.TabOrder := 2;

  if Assigned(FEndTimeEdit) then
    FEndTimeEdit.TabOrder := 3;
end;

function TTMSFNCCustomPlanner.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

procedure TTMSFNCCustomPlanner.SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomPlanner.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  BeginUpdate;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
    Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundStrokeColor(c) then
    Stroke.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
  begin
    GroupsAppearance.TopFill.Kind := gfkSolid;
    GroupsAppearance.BottomFill.Kind := gfkSolid;
    FullDaysAppearance.TopFill.Kind := gfkSolid;
    FullDaysAppearance.BottomFill.Kind := gfkSolid;
    TimeLineAppearance.LeftFill.Kind := gfkSolid;
    TimeLineAppearance.RightFill.Kind := gfkSolid;
    PositionsAppearance.TopFill.Kind := gfkSolid;
    PositionsAppearance.BottomFill.Kind := gfkSolid;
    PositionsAppearance.TopNavigationButtonFill.Kind := gfkSolid;
    PositionsAppearance.BottomNavigationButtonFill.Kind := gfkSolid;
    GroupsAppearance.TopFill.Color := c;
    GroupsAppearance.BottomFill.Color := c;
    FullDaysAppearance.TopFill.Color := c;
    FullDaysAppearance.BottomFill.Color := c;
    TimeLineAppearance.LeftFill.Color := c;
    TimeLineAppearance.RightFill.Color := c;
    PositionsAppearance.TopFill.Color := c;
    PositionsAppearance.BottomFill.Color := c;
    PositionsAppearance.TopNavigationButtonFill.Color := c;
    PositionsAppearance.BottomNavigationButtonFill.Color := c;
    PositionsAppearance.TopNavigationButtonHoverFill.Assign(SelectionAppearance.Fill);
    PositionsAppearance.BottomNavigationButtonHoverFill.Assign(SelectionAppearance.Fill);
    PositionsAppearance.TopNavigationButtonDownFill.Assign(GridCellAppearance.Fill);
    PositionsAppearance.BottomNavigationButtonDownFill.Assign(GridCellAppearance.Fill);
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColorTo(c) then
  begin
    GroupsAppearance.TopFill.Kind := gfkGradient;
    GroupsAppearance.BottomFill.Kind := gfkGradient;
    FullDaysAppearance.TopFill.Kind := gfkGradient;
    FullDaysAppearance.BottomFill.Kind := gfkGradient;
    TimeLineAppearance.LeftFill.Kind := gfkGradient;
    TimeLineAppearance.RightFill.Kind := gfkGradient;
    PositionsAppearance.TopFill.Kind := gfkGradient;
    PositionsAppearance.BottomFill.Kind := gfkGradient;
    PositionsAppearance.TopNavigationButtonFill.Kind := gfkGradient;
    PositionsAppearance.BottomNavigationButtonFill.Kind := gfkGradient;
    GroupsAppearance.TopFill.ColorTo := c;
    GroupsAppearance.BottomFill.ColorTo := c;
    FullDaysAppearance.TopFill.ColorTo := c;
    FullDaysAppearance.BottomFill.ColorTo := c;
    TimeLineAppearance.LeftFill.ColorTo := c;
    TimeLineAppearance.RightFill.ColorTo := c;
    PositionsAppearance.TopFill.ColorTo := c;
    PositionsAppearance.BottomFill.ColorTo := c;
    PositionsAppearance.TopNavigationButtonFill.ColorTo := c;
    PositionsAppearance.BottomNavigationButtonFill.ColorTo := c;
    PositionsAppearance.TopNavigationButtonHoverFill.Assign(SelectionAppearance.Fill);
    PositionsAppearance.BottomNavigationButtonHoverFill.Assign(SelectionAppearance.Fill);
    PositionsAppearance.TopNavigationButtonDownFill.Assign(GridCellAppearance.Fill);
    PositionsAppearance.BottomNavigationButtonDownFill.Assign(GridCellAppearance.Fill);
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    TimeLineAppearance.LeftStroke.Color := c;
    TimeLineAppearance.RightStroke.Color := c;
    PositionsAppearance.TopStroke.Color := c;
    PositionsAppearance.BottomStroke.Color := c;
    PositionsAppearance.TopNavigationButtonStroke.Color := c;
    PositionsAppearance.BottomNavigationButtonStroke.Color := c;
    PositionsAppearance.TopNavigationButtonHoverStroke.Color := c;
    PositionsAppearance.BottomNavigationButtonHoverStroke.Color := c;
    PositionsAppearance.TopNavigationButtonDownStroke.Color := c;
    PositionsAppearance.BottomNavigationButtonDownStroke.Color := c;
    GroupsAppearance.TopStroke.Color := c;
    GroupsAppearance.BottomStroke.Color := c;
    FullDaysAppearance.TopStroke.Color := c;
    FullDaysAppearance.BottomStroke.Color := c;
    GridCellAppearance.VerticalStroke.Color := c;
    GridCellAppearance.HorizontalStroke.Color := c;
    GridCellAppearance.HorizontalSubStroke.Color := c;
    TimeLineAppearance.LeftSubStroke.Color := c;
    TimeLineAppearance.RightSubStroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    SelectionAppearance.Fill.Kind := gfkSolid;
    SelectionAppearance.Fill.Color := c;
    SelectionAppearance.Stroke.Kind := gskNone;
    SelectionAppearance.Stroke.Color := c;
    GridCellAppearance.Fill.Assign(SelectionAppearance.Fill);
    GridCellAppearance.Fill.Color := Blend(c, Fill.Color, 25);
    GridCellAppearance.Fill.Kind := gfkSolid;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColorTo(c) then
  begin
    SelectionAppearance.Fill.ColorTo := c;
    SelectionAppearance.Fill.Kind := gfkGradient;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    TimeLineAppearance.LeftFont.Color := c;
    TimeLineAppearance.RightFont.Color := c;
    PositionsAppearance.TopFont.Color := c;
    PositionsAppearance.BottomFont.Color := c;
    GroupsAppearance.TopFont.Color := c;
    GroupsAppearance.BottomFont.Color := c;
  end;

  GridCellAppearance.InactiveFill.Assign(Fill);
  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.ResetToDefaultStyle;
begin
  BeginUpdate;
  Fill.Kind := gfkSolid;
  Stroke.Kind := gskSolid;
  Fill.Color := gcWhite;
  Stroke.Color := gcDarkGray;

  TTMSFNCUtils.SetFontSize(PositionsAppearance.TopFont, 14);
  PositionsAppearance.TopFont.Style := [TFontStyle.fsBold];

  GridCellAppearance.VerticalStroke.Color := PositionsAppearance.TopStroke.Color;
  GridCellAppearance.HorizontalStroke.Color := PositionsAppearance.TopStroke.Color;
  GridCellAppearance.Fill.Kind := gfkSolid;
  GridCellAppearance.DisabledFill.Color := MakeGraphicsColor(230, 230, 230);
  PositionsAppearance.TopFill.Color := gcWhite;
  PositionsAppearance.BottomNavigationButtonFill.Color := gcWhite;
  PositionsAppearance.BottomNavigationButtonStroke.Color := gcDarkGray;
  PositionsAppearance.TopNavigationButtonFill.Color := gcWhite;
  PositionsAppearance.TopNavigationButtonStroke.Color := gcDarkGray;
  PositionsAppearance.BottomNavigationButtonHoverFill.Color := MakeGraphicsColor(225, 245, 255);
  PositionsAppearance.BottomNavigationButtonHoverStroke.Color := gcDarkGray;
  PositionsAppearance.TopNavigationButtonHoverFill.Color := MakeGraphicsColor(225, 245, 255);
  PositionsAppearance.TopNavigationButtonHoverStroke.Color := gcDarkGray;
  PositionsAppearance.BottomNavigationButtonDownFill.Color := MakeGraphicsColor(149, 213, 246);
  PositionsAppearance.BottomNavigationButtonDownStroke.Color := gcDarkGray;
  PositionsAppearance.TopNavigationButtonDownFill.Color := MakeGraphicsColor(149, 213, 246);
  PositionsAppearance.TopNavigationButtonDownStroke.Color := gcDarkGray;
  GroupsAppearance.TopFill.Color := gcWhite;
  GroupsAppearance.BottomFill.Color := gcWhite;
  GroupsAppearance.TopStroke.Color := gcDarkGray;
  GroupsAppearance.BottomStroke.Color := gcDarkgray;
  GroupsAppearance.TopFont.Color := gcGray;
  GroupsAppearance.BottomFont.Color := gcGray;
  FullDaysAppearance.TopFill.Color := gcWhite;
  FullDaysAppearance.BottomFill.Color := gcWhite;
  FullDaysAppearance.TopStroke.Color := gcDarkGray;
  FullDaysAppearance.BottomStroke.Color := gcDarkgray;

  TimeLineAppearance.LeftFill.Color := gcWhite;
  TimeLineAppearance.RightFill.Color := gcWhite;
  TimeLineAppearance.LeftStroke.Color := gcDarkGray;
  TimeLineAppearance.RightStroke.Color := gcDarkgray;
  TimeLineAppearance.LeftSubStroke.Color := gcLightgray;
  TimeLineAppearance.RightSubStroke.Color := gcLightgray;
  SelectionAppearance.Fill.Color := MakeGraphicsColor(149, 213, 246);

  SelectionAppearance.Fill.Kind := gfkSolid;
  GridCellAppearance.VerticalStroke.Kind := gskSolid;
  GridCellAppearance.HorizontalStroke.Kind := gskSolid;
  GridCellAppearance.HorizontalSubStroke.Kind := gskSolid;
  GridCellAppearance.Fill.Kind := gfkNone;
  GridCellAppearance.DisabledFill.Kind := gfkSolid;
  GridCellAppearance.InActiveFill.Kind := gfkSolid;
  PositionsAppearance.TopFill.Kind := gfkNone;
  PositionsAppearance.BottomFill.Kind := gfkNone;
  PositionsAppearance.TopStroke.Kind := gskSolid;
  PositionsAppearance.BottomStroke.Kind := gskSolid;
  PositionsAppearance.TopNavigationButtonFill.Kind := gfkSolid;
  PositionsAppearance.BottomNavigationButtonFill.Kind := gfkSolid;
  PositionsAppearance.TopNavigationButtonStroke.Kind := gskSolid;
  PositionsAppearance.BottomNavigationButtonStroke.Kind := gskSolid;
  PositionsAppearance.TopNavigationButtonHoverFill.Kind := gfkSolid;
  PositionsAppearance.BottomNavigationButtonHoverFill.Kind := gfkSolid;
  PositionsAppearance.TopNavigationButtonHoverStroke.Kind := gskSolid;
  PositionsAppearance.BottomNavigationButtonHoverStroke.Kind := gskSolid;
  PositionsAppearance.TopNavigationButtonDownFill.Kind := gfkSolid;
  PositionsAppearance.BottomNavigationButtonDownFill.Kind := gfkSolid;
  PositionsAppearance.TopNavigationButtonDownStroke.Kind := gskSolid;
  PositionsAppearance.BottomNavigationButtonDownStroke.Kind := gskSolid;
  GroupsAppearance.TopFill.Kind := gfkNone;
  GroupsAppearance.BottomFill.Kind := gfkNone;
  GroupsAppearance.TopStroke.Kind := gskSolid;
  GroupsAppearance.BottomStroke.Kind := gskSolid;
  FullDaysAppearance.TopFill.Kind := gfkNone;
  FullDaysAppearance.BottomFill.Kind := gfkNone;
  FullDaysAppearance.TopStroke.Kind := gskSolid;
  FullDaysAppearance.BottomStroke.Kind := gskSolid;
  TimeLineAppearance.LeftFill.Kind := gfkNone;
  TimeLineAppearance.RightFill.Kind := gfkNone;
  TimeLineAppearance.LeftStroke.Kind := gskSolid;
  TimeLineAppearance.RightStroke.Kind := gskSolid;

  {$IFDEF FMXLIB}
  Color := $FFEEF2F9;
  GridCellAppearance.Fill.Color := $FFF6F8FC;
  SelectionAppearance.Stroke.Color := $FF2D9BEF;
  PositionsAppearance.TopFont.Color := $FF454545;
  PositionsAppearance.TopStroke.Color := $FFA9A9A9;
  TimeLineAppearance.LeftFont.Color := $FF454545;
  GridCellAppearance.HorizontalSubStroke.Color := $FFCFD0D2;
  GridCellAppearance.InActiveFill.Color := $FFFFFFFE;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  Color := $F9F2EE;
  GridCellAppearance.Fill.Color := $FCF8F6;
  SelectionAppearance.Stroke.Color := $EF9B2D;
  PositionsAppearance.TopFont.Color := $454545;
  PositionsAppearance.TopStroke.Color := $A9A9A9;
  TimeLineAppearance.LeftFont.Color := $454545;
  GridCellAppearance.HorizontalSubStroke.Color := $D2D0CF;
  GridCellAppearance.InActiveFill.Color := $FEFFFF;
  {$ENDIF}
  TimeLineAppearance.CurrentTimeColor := SelectionAppearance.Stroke.Color;

  SelectionAppearance.Stroke.Width := Trunc(ScalePaintValue(2.0));

  ItemsAppearance.Fill.Kind := gfkSolid;
  ItemsAppearance.Stroke.Kind := gskSolid;
  ItemsAppearance.TitleStroke.Color := gcNull;

  ItemsAppearance.ActiveStroke.Kind := gskSolid;
  ItemsAppearance.ActiveTitleStroke.Color := gcNull;

  PositionsAppearance.BottomFont.Assign(PositionsAppearance.TopFont);
  PositionsAppearance.BottomStroke.Assign(PositionsAppearance.TopStroke);
  TimeLineAppearance.RightFont.Assign(TimeLineAppearance.LeftFont);
  TimeLineAppearance.RightFill.Assign(TimeLineAppearance.LeftFill);

  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.DestroyEditingDialog;
begin
  if Assigned(FItemEditor) then
  begin
    FItemEditor.FCreated := False;
    FItemEditor.FInitialized := False;
  end;

  FCurrentPanel := nil;
  FEditingDialogCreated := False;
  if Assigned(FEditingBackground) then
    FEditingBackground.Free;

  FEditingBackground := nil;

  if Assigned(FEditingDialog) then
    FEditingDialog.Free;

  FEditingDialog := nil;
end;

procedure TTMSFNCCustomPlanner.DoCustomizeItemRect(AItem: TTMSFNCPlannerItem; APosition: Integer; var AX: Double; var AY: Double; var AWidth: Double; var AHeight: Double);
begin
  if Assigned(OnCustomizeItemRect) then
    OnCustomizeItemRect(Self, AItem, APosition, AX, AY, AWidth, AHeight);
end;

procedure TTMSFNCCustomPlanner.ClearSelection;
begin
  FSelection.StartCell := MakeCell(-1, -1);
  FSelection.EndCell := MakeCell(-1, -1);
  Invalidate;
end;

function TTMSFNCCustomPlanner.XYToPositionAnchorCache(AX, AY: Single; ACache: TTMSFNCPlannerPositionsCache; var AIndex: Integer): string;
var
  I: Integer;
  txtr: TRectF;
  a: String;
  str: String;
  g: TTMSFNCGraphics;
  c: TTMSFNCPlannerCacheItem;
begin
  Result := '';
  AIndex := -1;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    g.BitmapContainer := BitmapContainer;
    for I := 0 to ACache.Count - 1 do
    begin
      c := ACache[I];
      txtr := c.DrawRect;
      InflateRectEx(txtr, -2, -2);
      if PtInRectEx(txtr, PointF(AX, AY)) then
      begin
        case c.Kind of
          ikPositionTop: g.Font.Assign(PositionsAppearance.TopFont);
          ikPositionBottom: g.Font.Assign(PositionsAppearance.BottomFont);
        end;

        str := GetPositionText(c.Position);
        DoGetPositionText(c.Position, c.Kind, str);
        case c.Kind of
          ikPositionTop:
          begin
            case PositionsAppearance.TopVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, -90, -1, -1, True, True, AX, AY);
                  pomVertical: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
                end;
              end;
              pvtmAlways: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, -90, -1, -1, True, True, AX, AY);
              pvtmNone: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
            end;
          end;
          ikPositionBottom:
          begin
            case PositionsAppearance.BottomVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 90, -1, -1, True, True, AX, AY);
                  pomVertical: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
                end;
              end;
              pvtmAlways: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 90, -1, -1, True, True, AX, AY);
              pvtmNone: a := g.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
            end;
          end;
        end;

        if a <> '' then
        begin
          Result := a;
          AIndex := c.Position;
          Break;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.XYToGroupAnchorCache(AX, AY: Single; ACache: TTMSFNCPlannerGroupsCache; var AIndex: Integer): string;
var
  I: Integer;
  txtr: TRectF;
  a: String;
  str: String;
  g: TTMSFNCGraphics;
  c: TTMSFNCPlannerCacheItem;
begin
  Result := '';
  AIndex := -1;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    g.BitmapContainer := BitmapContainer;
    for I := 0 to ACache.Count - 1 do
    begin
      c := ACache[I];

      txtr := c.DrawRect;
      InflateRectEx(txtr, -2, -2);
      if PtInRectEx(txtr, PointF(AX, AY)) then
      begin
        case c.Kind of
          ikGroupTop: g.Font.Assign(GroupsAppearance.TopFont);
          ikGroupBottom: g.Font.Assign(GroupsAppearance.BottomFont);
        end;

        str := GetGroupText(c.Group);
        DoGetGroupText(c.Group, c.Kind, str);
        case c.Kind of
          ikGroupTop:
          begin
            case GroupsAppearance.TopVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, -90, -1, -1, True, True, AX, AY);
                  pomVertical: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
                end;
              end;
              pvtmAlways: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, -90, -1, -1, True, True, AX, AY);
              pvtmNone: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
            end;
          end;
          ikGroupBottom:
          begin
            case GroupsAppearance.BottomVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 90, -1, -1, True, True, AX, AY);
                  pomVertical: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
                end;
              end;
              pvtmAlways: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 90, -1, -1, True, True, AX, AY);
              pvtmNone: a := g.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
            end;
          end;
        end;

        if a <> '' then
        begin
          Result := a;
          AIndex := c.Group;
          Break;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.XYToPositionCache(AX, AY: Single; ACache: TTMSFNCPlannerPositionsCache): TTMSFNCPlannerCacheItem;
var
  I: Integer;
  txtr: TRectF;
  g: TTMSFNCGraphics;
  c: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    g.BitmapContainer := BitmapContainer;
    for I := 0 to ACache.Count - 1 do
    begin
      c := ACache[I];
      txtr := c.DrawRect;
      if PtInRectEx(txtr, PointF(AX, AY)) then
      begin
        Result := c;
        Break;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.XYToGroupCache(AX, AY: Single; ACache: TTMSFNCPlannerGroupsCache): TTMSFNCPlannerCacheItem;
var
  I: Integer;
  txtr: TRectF;
  g: TTMSFNCGraphics;
  c: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    g.BitmapContainer := BitmapContainer;
    for I := 0 to ACache.Count - 1 do
    begin
      c := ACache[I];
      txtr := c.DrawRect;
      if PtInRectEx(txtr, PointF(AX, AY)) then
      begin
        Result := c;
        Break;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.XYToFullDayCache(AX, AY: Single; ACache: TTMSFNCPlannerFullDaysCache): TTMSFNCPlannerCacheItem;
var
  I: Integer;
  txtr: TRectF;
  g: TTMSFNCGraphics;
  c: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    g.BitmapContainer := BitmapContainer;
    for I := 0 to ACache.Count - 1 do
    begin
      c := ACache[I];
      txtr := c.DrawRect;
      if PtInRectEx(txtr, PointF(AX, AY)) then
      begin
        Result := c;
        Break;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.GetHintString: string;
begin
  Result := inherited GetHintString;
  if Assigned(FGroupHover) then
  begin
    Result := GetGroupHint(FGroupHover.Group);
    DoGetGroupHint(FGroupHover.Group, FGroupHover.Kind, Result);
  end
  else if Assigned(FFullDayHover) then
  begin
    Result := GetFullDayHint(FFullDayHover.FullDay);
    DoGetFullDayHint(FFullDayHover.FullDay, FFullDayHover.Kind, Result);
  end
  else if Assigned(FPositionHover) then
  begin
    Result := GetPositionResourceHint(FPositionHover.Position);
    DoGetPositionHint(FPositionHover.Position, FPositionHover.Kind, Result);
  end
  else if Assigned(FHoverItem) then
  begin
    Result := FHoverItem.Hint;
    DoGetItemHint(FHoverItem, Result);
  end;
end;

function TTMSFNCCustomPlanner.HasHint: Boolean;
var
  r: string;
begin
  Result := inherited HasHint;
  if Assigned(FGroupHover) then
  begin
    r := GetGroupHint(FGroupHover.Group);
    DoGetGroupHint(FGroupHover.Group, FGroupHover.Kind, r);
    Result := r <> '';
  end
  else if Assigned(FFullDayHover) then
  begin
    r := GetFullDayHint(FFullDayHover.FullDay);
    DoGetFullDayHint(FFullDayHover.FullDay, FFullDayHover.Kind, r);
    Result := r <> '';
  end
  else if Assigned(FPositionHover) then
  begin
    r := GetPositionResourceHint(FPositionHover.Position);
    DoGetPositionHint(FPositionHover.Position, FPositionHover.Kind, r);
    Result := r <> '';
  end
  else if Assigned(FHoverItem) then
  begin
    r := FHoverItem.Hint;
    DoGetItemHint(FHoverItem, r);
    Result := r <> '';
  end;
end;

function TTMSFNCCustomPlanner.GetGroupHint(AGroup: Integer): string;
begin
  Result := '';
  case GetDisplayMode of
    pmMultiResDay:
    begin
    end;
    pmMultiDayRes:
    begin
      if (AGroup >= 0) and (AGroup <= Resources.Count - 1) then
        Result := TTMSFNCPlannerResourceOpen(Resources[AGroup]).Hint;
    end
    else
    begin
      if (AGroup >= 0) and (AGroup <= Groups.Count - 1) then
        Result := TTMSFNCPlannerGroupOpen(Groups[AGroup]).Hint;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDayHint(AFullDay: Integer): string;
begin
  Result := '';
end;

function TTMSFNCCustomPlanner.GetPositionResourceHint(APosition: Integer): string;
begin
  Result := '';
  case GetDisplayMode of
    pmDay, pmHalfDayPeriod, pmDayPeriod, pmMonth, pmMultiResDay, pmCustom:
    begin
      if (GetDisplayMode = pmMultiResDay) then
        APosition := APosition mod Max(1, Resources.Count);

      if (APosition >= 0) and (APosition <= Resources.Count - 1) then
        Result := Resources[APosition].Hint;
    end;
    pmMultiDayRes:
    begin
    end;
    pmMultiDay:
    begin
    end;
    pmMultiMonth:
    begin
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawGrid(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawGrid) then
    OnAfterDrawGrid(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomPlanner.DoAfterDrawTimeLine(AGraphics: TTMSFNCGraphics; ALeft: Boolean; ARect: TRectF);
begin
  if Assigned(OnAfterDrawTimeLine) then
    OnAfterDrawTimeLine(Self, AGraphics, ALeft, ARect);
end;

procedure TTMSFNCCustomPlanner.DoItemClick(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.DoItemDblClick(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(OnItemDblClick) then
    OnItemDblClick(Self, AItem);
end;

procedure TTMSFNCCustomPlanner.ChangeDPIScale(M: Integer; D: Integer);
begin
  inherited;
  BeginUpdate;
  ItemsAppearance.Gap := TTMSFNCUtils.MulDivSingle(ItemsAppearance.Gap, M, D);
  ItemsAppearance.SizeAreaSize := TTMSFNCUtils.MulDivSingle(ItemsAppearance.SizeAreaSize, M, D);
  ItemsAppearance.MoveAreaSize := TTMSFNCUtils.MulDivSingle(ItemsAppearance.MoveAreaSize, M, D);
  ItemsAppearance.DeleteAreaSize := TTMSFNCUtils.MulDivSingle(ItemsAppearance.DeleteAreaSize, M, D);
  ItemsAppearance.SizeHandlerHeight := TTMSFNCUtils.MulDivSingle(ItemsAppearance.SizeHandlerHeight, M, D);
  ItemsAppearance.SizeHandlerWidth := TTMSFNCUtils.MulDivSingle(ItemsAppearance.SizeHandlerWidth, M, D);
  ItemsAppearance.DeleteHandlerHeight := TTMSFNCUtils.MulDivSingle(ItemsAppearance.DeleteHandlerHeight, M, D);
  ItemsAppearance.DeleteHandlerWidth := TTMSFNCUtils.MulDivSingle(ItemsAppearance.DeleteHandlerWidth, M, D);
  ItemsAppearance.LinkArrowSize := TTMSFNCUtils.MulDivSingle(ItemsAppearance.LinkArrowSize, M, D);
  ItemsAppearance.Font.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.Font.Height, M, D);
  ItemsAppearance.TitleFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.TitleFont.Height, M, D);
  ItemsAppearance.DisabledFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.DisabledFont.Height, M, D);
  ItemsAppearance.SelectedFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.SelectedFont.Height, M, D);
  ItemsAppearance.ActiveFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.ActiveFont.Height, M, D);
  ItemsAppearance.DisabledTitleFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.DisabledTitleFont.Height, M, D);
  ItemsAppearance.SelectedTitleFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.SelectedTitleFont.Height, M, D);
  ItemsAppearance.ActiveTitleFont.Height := TTMSFNCUtils.MulDivInt(ItemsAppearance.ActiveTitleFont.Height, M, D);
  TimeLineAppearance.LeftSize := TTMSFNCUtils.MulDivSingle(TimeLineAppearance.LeftSize, M, D);
  TimeLineAppearance.RightSize := TTMSFNCUtils.MulDivSingle(TimeLineAppearance.RightSize, M, D);
  TimeLineAppearance.LeftFont.Height := TTMSFNCUtils.MulDivInt(TimeLineAppearance.LeftFont.Height, M, D);
  TimeLineAppearance.RightFont.Height := TTMSFNCUtils.MulDivInt(TimeLineAppearance.RightFont.Height, M, D);
  TimeLineAppearance.LeftSubUnitFontSize := TTMSFNCUtils.MulDivSingle(TimeLineAppearance.LeftSubUnitFontSize, M, D);
  TimeLineAppearance.RightSubUnitFontSize := TTMSFNCUtils.MulDivSingle(TimeLineAppearance.RightSubUnitFontSize, M, D);
  PositionsAppearance.TopLeftNavigationButtonSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.TopLeftNavigationButtonSize, M, D);
  PositionsAppearance.TopRightNavigationButtonSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.TopRightNavigationButtonSize, M, D);
  PositionsAppearance.BottomLeftNavigationButtonSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.BottomLeftNavigationButtonSize, M, D);
  PositionsAppearance.BottomRightNavigationButtonSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.BottomRightNavigationButtonSize, M, D);
  PositionsAppearance.TopSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.TopSize, M, D);
  PositionsAppearance.BottomSize := TTMSFNCUtils.MulDivSingle(PositionsAppearance.BottomSize, M, D);
  PositionsAppearance.TopFont.Height := TTMSFNCUtils.MulDivInt(PositionsAppearance.TopFont.Height, M, D);
  PositionsAppearance.BottomFont.Height := TTMSFNCUtils.MulDivInt(PositionsAppearance.BottomFont.Height, M, D);
  GroupsAppearance.TopSize := TTMSFNCUtils.MulDivSingle(GroupsAppearance.TopSize, M, D);
  GroupsAppearance.BottomSize := TTMSFNCUtils.MulDivSingle(GroupsAppearance.BottomSize, M, D);
  GroupsAppearance.TopFont.Height := TTMSFNCUtils.MulDivInt(GroupsAppearance.TopFont.Height, M, D);
  GroupsAppearance.BottomFont.Height := TTMSFNCUtils.MulDivInt(GroupsAppearance.BottomFont.Height, M, D);
  FullDaysAppearance.AutoItemHeight := TTMSFNCUtils.MulDivSingle(FullDaysAppearance.AutoItemHeight, M, D);
  FullDaysAppearance.TopSize := TTMSFNCUtils.MulDivSingle(FullDaysAppearance.TopSize, M, D);
  FullDaysAppearance.BottomSize := TTMSFNCUtils.MulDivSingle(FullDaysAppearance.BottomSize, M, D);
  TimeLine.DisplayUnitSize := TTMSFNCUtils.MulDivSingle(TimeLine.DisplayUnitSize, M, D);
  EndUpdate;
end;

function TTMSFNCCustomPlanner.CanDisplayFullDayTop: Boolean;
begin
  Result := IsFullDayMode;
  Result := Result and (pfdlTop in FullDaysAppearance.Layouts) and ((FullDaysAppearance.TopSize > 0) or FullDaysAppearance.AutoSize);
end;

function TTMSFNCCustomPlanner.CanDisplayFullDayBottom: Boolean;
begin
  Result := IsFullDayMode;
  Result := Result and (pfdlBottom in FullDaysAppearance.Layouts) and ((FullDaysAppearance.BottomSize > 0) or FullDaysAppearance.AutoSize);
end;

function TTMSFNCCustomPlanner.IsMobile: Boolean;
begin
  {$IFDEF FMXMOBILE}
  Result := True;
  {$ELSE}
  {$IFDEF WEBLIB}
  Result := Application.IsMobile;
  {$ELSE}
  Result := False;
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCCustomPlanner.IsFullDayMode: Boolean;
begin
  Result := (GetDisplayMode in [pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes]) and (FullDaysAppearance.Layouts <> []);
end;

function TTMSFNCCustomPlanner.HasFullDayItemPosition(ALayout: TTMSFNCPlannerFullDayLayout; APosition: Integer; AItem: TTMSFNCPlannerItem): Boolean;
begin
  Result := False;
  if Assigned(AItem) then
  begin
    case ALayout of
      pfdlTop: Result := TTMSFNCPlannerItemOpen(AItem).FullDayPosListTop.IndexOf(APosition) <> -1;
      pfdlBottom: Result := TTMSFNCPlannerItemOpen(AItem).FullDayPosListBottom.IndexOf(APosition) <> -1;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.ChangeRectForMark(AItem: TTMSFNCPlannerItem; var ARect: TRectF);
begin
  if imtLeft in AItem.MarkType then
    ARect.Left := ARect.Left + AItem.MarkSizeLeft;
  if imtTop in AItem.MarkType then
    ARect.Top := ARect.Top + AItem.MarkSizeTop;
  if imtRight in AItem.MarkType then
    ARect.Right := ARect.Right - AItem.MarkSizeRight;
  if imtBottom in AItem.MarkType then
    ARect.Bottom := ARect.Bottom + AItem.MarkSizeBottom;
end;

function TTMSFNCCustomPlanner.IsFullDayAutoSize: Boolean;
begin
  Result := FullDaysAppearance.AutoSize;
end;

function TTMSFNCCustomPlanner.CreateAndPrepareBitmap(var ABitmap: TBitmap; AWidth, AHeight: Double): Boolean;
{$IFDEF FMXLIB}
var
  ds: TTMSFNCPlannerSceneDrawingScale;
  bw, bh: Integer;
  mxs: Integer;
begin
  ds := GetSceneDrawingScale;
  bw := Round(AWidth * ds.SceneScale * ds.DrawingScale.X);
  bh := Round(AHeight * ds.SceneScale * ds.DrawingScale.Y);
  mxs := TCanvasManager.DefaultCanvas.GetAttribute(TCanvasAttribute.MaxBitmapSize);
  Result := False;
  try
    ABitmap := TBitmap.Create(Min(mxs, bw), Min(mxs, bh));
    ABitmap.BitmapScale := ds.SceneScale;
    Result := ABitmap.Canvas.BeginScene;
    if Result then
    begin
      ABitmap.Canvas.SetMatrix(ABitmap.Canvas.Matrix.CreateScaling(ds.DrawingScale.X, ds.DrawingScale.Y));
      ABitmap.Canvas.Clear(0);
    end;
  except
  end;
{$ENDIF}
{$IFDEF CMNWEBLIB}
begin
  ABitmap := TBitmap.Create;
  ABitmap.Width := Round(AWidth);
  ABitmap.Height := Round(AHeight);
  Result := True;
{$ENDIF}
end;

function TTMSFNCCustomPlanner.GetEditingDialog(AItemIndex: Integer = -1): TTMSFNCPlannerEditingDialog;
var
  cp: TTMSFNCPlannerEditingDialogContentPanel;
  it: TTMSFNCPlannerItem;
  {$IFDEF CMNWEBLIB}
  bmp: TTMSFNCBitmap;
  {$ENDIF}
begin
  if not FEditingDialogCreated then
  begin
    FEditingBackground := TTMSFNCImage.Create(Self);
    FEditingBackground.AllowFocus := False;
    {$IFDEF FMXLIB}
    FEditingBackground.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FEditingBackground.Align := alClient;
    {$ENDIF}
    FEditingBackground.DisableBackground;
    FEditingBackground.OnClick := EditingDialogCancel;

    FEditingDialog := TTMSFNCControl.Create(Self);
    FEditingDialog.AdaptToStyle := AdaptToStyle;
    FEditingDialog.AllowFocus := False;
    FEditingDialog.Width := 350;
    FEditingDialog.Height := 300;
    {$IFDEF FMXLIB}
    FEditingDialog.Align := TAlignLayout.Center;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FEditingDialog.Left := (Width - FEditingDialog.Width) div 2;
    FEditingDialog.Top := (Height - FEditingDialog.Height) div 2;
    {$IFNDEF WEBLIB}
    FEditingDialog.Anchors := [];
    {$ENDIF}
    {$ENDIF}

    FBottomPanel := TTMSFNCControl.Create(FEditingDialog);
    FBottomPanel.AdaptToStyle := AdaptToStyle;
    FBottomPanel.Height := 37;
    FBottomPanel.Parent := FEditingDialog;
    FBottomPanel.Width := 200;
    FBottomPanel.AllowFocus := False;

    FButtonOK := TLabel.Create(FBottomPanel);
    FButtonOK.Visible := False;
    FButtonOK.Parent := Self;
    {$IFDEF FMXLIB}
    FButtonOK.Text := TranslateTextEx(sTMSFNCPlannerOK);
    FButtonOK.HitTest := True;
    FButtonOK.Align := TAlignLayout.Right;
    FButtonOK.StyledSettings := FButtonOK.StyledSettings - [TStyledSetting.Size];
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF WEBLIB}
    FButtonOK.Layout := tlCenter;
    {$ENDIF}
    FButtonOK.Caption := TranslateTextEx(sTMSFNCPlannerOK);
    FButtonOK.Align := alRight;
    {$IFNDEF LCLLIB}
    FButtonOK.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    FButtonOK.WordWrap := False;
    FButtonOK.AutoSize := True;
    FButtonOK.Cursor := crHandPoint;
    TTMSFNCUtils.SetFontSize(FButtonOK.Font, ScalePaintValue(16));
    {$IFNDEF LCLLIB}
    FButtonOK.Margins.Right := 5;
    FButtonOK.Margins.Top := 5;
    FButtonOK.Margins.Bottom := 5;
    FButtonOK.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonOK.BorderSpacing.Right := 5;
    FButtonOK.BorderSpacing.Top := 5;
    FButtonOK.BorderSpacing.Bottom := 5;
    FButtonOK.BorderSpacing.Left := 5;
    {$ENDIF}

    FButtonOK.Font.Style := [TFontStyle.fsBold];
    FButtonOK.OnClick := EditingDialogValidate;
    FButtonOK.Parent := FBottomPanel;
    FButtonOK.Visible := True;

    FButtonCancel := TLabel.Create(FBottomPanel);
    FButtonCancel.Visible := False;
    FButtonCancel.Parent := Self;
    TTMSFNCUtils.SetFontSize(FButtonCancel.Font, ScalePaintValue(16));
    {$IFDEF FMXLIB}
    FButtonCancel.Text := TranslateTextEx(sTMSFNCPlannerCancel);
    FButtonCancel.Align := TAlignLayout.Right;
    FButtonCancel.StyledSettings := FButtonCancel.StyledSettings - [TStyledSetting.Size];
    FButtonCancel.HitTest := True;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF WEBLIB}
    FButtonCancel.Layout := tlCenter;
    {$ENDIF}
    FButtonCancel.Caption := TranslateTextEx(sTMSFNCPlannerCancel);
    FButtonCancel.Align := alRight;
    {$IFNDEF LCLLIB}
    FButtonCancel.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    FButtonCancel.WordWrap := False;
    FButtonCancel.AutoSize := True;
    FButtonCancel.Cursor := crHandPoint;
    {$IFDEF LCLLIB}
    FButtonCancel.BorderSpacing.Right := 5;
    FButtonCancel.BorderSpacing.Top := 5;
    FButtonCancel.BorderSpacing.Bottom := 5;
    FButtonCancel.BorderSpacing.Left := 5;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonCancel.Margins.Right := 5;
    FButtonCancel.Margins.Top := 5;
    FButtonCancel.Margins.Bottom := 5;
    FButtonCancel.Margins.Left := 5;
    {$ENDIF}
    FButtonCancel.OnClick := EditingDialogCancel;
    FButtonCancel.Parent := FBottomPanel;
    FButtonCancel.Visible := True;

    FButtonRemove := TLabel.Create(FBottomPanel);
    FButtonRemove.Visible := False;
    FButtonRemove.Parent := Self;
    TTMSFNCUtils.SetFontSize(FButtonRemove.Font, ScalePaintValue(16));
    {$IFDEF FMXLIB}
    FButtonRemove.Text := TranslateTextEx(sTMSFNCPlannerRemove);
    FButtonRemove.Align := TAlignLayout.Left;
    FButtonRemove.StyledSettings := FButtonRemove.StyledSettings - [TStyledSetting.FontColor, TStyledSetting.Size];
    FButtonRemove.FontColor := gcRed;
    FButtonRemove.HitTest := True;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF WEBLIB}
    FButtonRemove.Layout := tlCenter;
    {$ENDIF}
    FButtonRemove.Align := alLeft;
    FButtonRemove.Caption := TranslateTextEx(sTMSFNCPlannerRemove);
    FButtonRemove.Font.Color := gcRed;
    {$IFNDEF LCLLIB}
    FButtonRemove.AlignWithMargins := True;
    {$ENDIF}
    {$ENDIF}
    FButtonRemove.WordWrap := False;
    FButtonRemove.AutoSize := True;
    FButtonRemove.Cursor := crHandPoint;
    {$IFDEF LCLLIB}
    FButtonRemove.BorderSpacing.Right := 5;
    FButtonRemove.BorderSpacing.Top := 5;
    FButtonRemove.BorderSpacing.Bottom := 5;
    FButtonRemove.BorderSpacing.Left := 5;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FButtonRemove.Margins.Right := 5;
    FButtonRemove.Margins.Top := 5;
    FButtonRemove.Margins.Bottom := 5;
    FButtonRemove.Margins.Left := 5;
    {$ENDIF}
    FButtonRemove.Font.Style := [TFontStyle.fsBold];
    FButtonRemove.OnClick := EditingDialogRemove;
    FButtonRemove.Parent := FBottomPanel;
    FButtonRemove.Visible := True;

    FContentPanel := TTMSFNCControl.Create(FEditingDialog);
    FContentPanel.AdaptToStyle := AdaptToStyle;
    FContentPanel.AllowFocus := False;
    FContentPanel.Height := 37;
    {$IFDEF FMXLIB}
    FContentPanel.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FContentPanel.Align := alClient;
    {$ENDIF}
    FContentPanel.Parent := FEditingDialog;

    FStartTimeLabel := TLabel.Create(FContentPanel);
    {$IFDEF WEBLIB}
    FStartTimeLabel.Width := 100;
    FStartTimeLabel.Height := 15;
    {$ENDIF}
    {$IFDEF FMXLIB}
    FStartTimeLabel.Text := TranslateTextEx(sTMSFNCPlannerStartTime);
    FStartTimeLabel.Position.X := 10;
    FStartTimeLabel.Position.Y := 10;
    FStartTimeLabel.FontColor := gcDarkgray;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FStartTimeLabel.Caption := TranslateTextEx(sTMSFNCPlannerStartTime);
    FStartTimeLabel.Left := 10;
    FStartTimeLabel.Top := 10;
    FStartTimeLabel.Font.Color := gcDarkgray;
    {$ENDIF}
    FStartTimeLabel.WordWrap := False;
    {$IFNDEF WEBLIB}
    FStartTimeLabel.AutoSize := True;
    {$ENDIF}
    FStartTimeLabel.Parent := FContentPanel;

    FStartTimeEdit := TTMSFNCPlannerTimeEdit.Create(FContentPanel);
    FStartTimeEdit.OnChange := @StartTimeEditChanged;
    FStartTimeEdit.Width := 100;
    {$IFDEF FMXLIB}
    FStartTimeEdit.Position.X := FEditingDialog.Width - FStartTimeEdit.Width - 10;
    FStartTimeEdit.Position.Y := FStartTimeLabel.Position.Y + int((FStartTimeLabel.Height - FStartTimeEdit.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FStartTimeEdit.Kind := dtkTime;
    FStartTimeEdit.Left := FEditingDialog.Width - FStartTimeEdit.Width - 10;
    FStartTimeEdit.Top := FStartTimeLabel.Top + (FStartTimeLabel.Height - FStartTimeEdit.Height) div 2;
    {$ENDIF}
    FStartTimeEdit.Parent := FContentPanel;

    FStartDateEdit := TTMSFNCPlannerDateEdit.Create(FContentPanel);
    FStartDateEdit.OnChange := @StartDateEditChanged;
    FStartDateEdit.Width := 100;
    {$IFDEF FMXLIB}
    FStartDateEdit.Position.X := FStartTimeEdit.Position.X - FStartDateEdit.Width - 10;
    FStartDateEdit.Position.Y := FStartTimeEdit.Position.Y + int((FStartTimeEdit.Height - FStartDateEdit.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF WEBLIB}
    FStartDateEdit.Width := 125;
    {$ENDIF}
    FStartDateEdit.Left := FStartTimeEdit.Left - FStartDateEdit.Width - 10;
    FStartDateEdit.Top := FStartTimeEdit.Top + (FStartTimeEdit.Height - FStartDateEdit.Height) div 2;
    {$ENDIF}
    FStartDateEdit.Parent := FContentPanel;

    FEndTimeLabel := TLabel.Create(FContentPanel);
    FEndTimeLabel.Width := 100;
    {$IFDEF WEBLIB}
    FEndTimeLabel.Height := 15;
    {$ENDIF}
    {$IFDEF FMXLIB}
    FEndTimeLabel.Text := TranslateTextEx(sTMSFNCPlannerEndTime);
    FEndTimeLabel.Position.X := 10;
    FEndTimeLabel.Position.Y := FStartTimeLabel.Position.Y + FStartTimeLabel.Height + 15;
    FEndTimeLabel.FontColor := gcDarkgray;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FEndTimeLabel.Caption := TranslateTextEx(sTMSFNCPlannerEndTime);
    FEndTimeLabel.Left := 10;
    FEndTimeLabel.Top := FStartTimeLabel.Top + FStartTimeLabel.Height + 15;
    FEndTimeLabel.Font.Color := gcDarkgray;
    {$ENDIF}
    FEndTimeLabel.WordWrap := False;
    {$IFNDEF WEBLIB}
    FEndTimeLabel.AutoSize := True;
    {$ENDIF}
    FEndTimeLabel.Parent := FContentPanel;

    FEndTimeEdit := TTMSFNCPlannerTimeEdit.Create(FContentPanel);
    FEndTimeEdit.OnChange := @EndTimeEditChanged;
    FEndTimeEdit.Width := 100;
    {$IFDEF FMXLIB}
    FEndTimeEdit.Position.X := FEditingDialog.Width - FEndTimeEdit.Width - 10;
    FEndTimeEdit.Position.Y := FEndTimeLabel.Position.Y + int((FEndTimeLabel.Height - FEndTimeEdit.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FEndTimeEdit.Kind := dtkTime;
    FEndTimeEdit.Left := FEditingDialog.Width - FEndTimeEdit.Width - 10;
    FEndTimeEdit.Top := FEndTimeLabel.Top + (FEndTimeLabel.Height - FEndTimeEdit.Height) div 2;
    {$ENDIF}
    FEndTimeEdit.Parent := FContentPanel;

    FEndDateEdit := TTMSFNCPlannerDateEdit.Create(FContentPanel);
    FEndDateEdit.OnChange := @EndDateEditChanged;
    FEndDateEdit.Width := 100;
    {$IFDEF FMXLIB}
    FEndDateEdit.Position.X := FEndTimeEdit.Position.X - FEndDateEdit.Width - 10;
    FEndDateEdit.Position.Y := FEndTimeEdit.Position.Y + int((FEndTimeEdit.Height - FEndDateEdit.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF WEBLIB}
    FEndDateEdit.Width := 125;
    {$ENDIF}
    FEndDateEdit.Left := FEndTimeEdit.Left - FEndDateEdit.Width - 10;
    FEndDateEdit.Top := FEndTimeEdit.Top + (FEndTimeEdit.Height - FEndDateEdit.Height) div 2;
    {$ENDIF}
    FEndDateEdit.Parent := FContentPanel;

    FResourceLabel := TLabel.Create(FContentPanel);
    FResourceLabel.Width := 100;
    {$IFDEF WEBLIB}
    FResourceLabel.Height := 15;
    {$ENDIF}
    {$IFDEF FMXLIB}
    FResourceLabel.Text := TranslateTextEx(sTMSFNCPlannerResource);
    FResourceLabel.Position.X := 10;
    FResourceLabel.Position.Y := FEndTimeLabel.Position.Y + FEndTimeLabel.Height + 15;
    FResourceLabel.FontColor := gcDarkgray;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FResourceLabel.Caption := TranslateTextEx(sTMSFNCPlannerResource);
    FResourceLabel.Left := 10;
    FResourceLabel.Top := FEndTimeLabel.Top + FEndTimeLabel.Height + 15;
    FResourceLabel.Font.Color := gcDarkgray;
    {$ENDIF}
    FResourceLabel.WordWrap := False;
    {$IFNDEF WEBLIB}
    FResourceLabel.AutoSize := True;
    {$ENDIF}
    FResourceLabel.Parent := FContentPanel;

    FResourcesComboBox := TComboBox.Create(FContentPanel);
    {$IFDEF FMXLIB}
    FResourcesComboBox.Width := FEditingDialog.Width - FStartDateEdit.Position.X - 10;
    FResourcesComboBox.Position.X := FStartDateEdit.Position.X;
    FResourcesComboBox.Position.Y := FResourceLabel.Position.Y + Int((FResourceLabel.Height - FResourcesComboBox.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FResourcesComboBox.Width := FEditingDialog.Width - FStartDateEdit.Left - 10;
    FResourcesComboBox.Left := FStartDateEdit.Left;
    FResourcesComboBox.Top := FResourceLabel.Top + (FResourceLabel.Height - FResourcesComboBox.Height) div 2;
    {$ENDIF}
    FResourcesComboBox.Parent := FContentPanel;

    FTitleLabel := TLabel.Create(FContentPanel);
    FTitleLabel.Width := 100;
    {$IFDEF WEBLIB}
    FTitleLabel.Height := 15;
    {$ENDIF}
    {$IFDEF FMXLIB}
    FTitleLabel.Text := TranslateTextEx(sTMSFNCPlannerTitle);
    FTitleLabel.Position.X := 10;
    FTitleLabel.Position.Y := FResourceLabel.Position.Y + FResourceLabel.Height + 15;
    FTitleLabel.FontColor := gcDarkgray;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FTitleLabel.Caption := TranslateTextEx(sTMSFNCPlannerTitle);
    FTitleLabel.Left := 10;
    FTitleLabel.Top := FResourceLabel.Top + FResourceLabel.Height + 15;
    FTitleLabel.Font.Color := gcDarkgray;
    {$ENDIF}
    FTitleLabel.WordWrap := False;
    {$IFNDEF WEBLIB}
    FTitleLabel.AutoSize := True;
    {$ENDIF}
    FTitleLabel.Parent := FContentPanel;

    FTitleEdit := TEdit.Create(FContentPanel);
    {$IFDEF FMXLIB}
    FTitleEdit.Width := FEditingDialog.Width - FStartDateEdit.Position.X - 10;
    FTitleEdit.Position.X := FStartDateEdit.Position.X;
    FTitleEdit.Position.Y := FTitleLabel.Position.Y + Int((FTitleLabel.Height - FTitleEdit.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FTitleEdit.Width := FEditingDialog.Width - FStartDateEdit.Left - 10;
    FTitleEdit.Left := FStartDateEdit.Left;
    FTitleEdit.Top := FTitleLabel.Top + (FTitleLabel.Height - FTitleEdit.Height) div 2;
    {$ENDIF}
    FTitleEdit.Parent := FContentPanel;

    FTextLabel := TLabel.Create(FContentPanel);
    FTextLabel.Width := 100;
    {$IFDEF WEBLIB}
    FTextLabel.Height := 15;
    {$ENDIF}
    {$IFDEF FMXLIB}
    FTextLabel.Text := TranslateTextEx(sTMSFNCPlannerText);
    FTextLabel.Position.X := 10;
    FTextLabel.Position.Y := FTitleLabel.Position.Y + FTitleLabel.Height + 15;
    FTextLabel.FontColor := gcDarkgray;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FTextLabel.Caption := TranslateTextEx(sTMSFNCPlannerText);
    FTextLabel.Left := 10;
    FTextLabel.Top := FTitleLabel.Top + FTitleLabel.Height + 15;
    FTextLabel.Font.Color := gcDarkgray;
    {$ENDIF}
    FTextLabel.WordWrap := False;
    {$IFNDEF WEBLIB}
    FTextLabel.AutoSize := True;
    {$ENDIF}
    FTextLabel.Parent := FContentPanel;

    FFullDayCheckBox := TCheckBox.Create(FContentPanel);
    FFullDayCheckBox.Width := 70;
    {$IFDEF FMXLIB}
    FFullDayCheckBox.Text := TranslateTextEx(sTMSFNCPlannerFullDay);
    FFullDayCheckBox.Position.X := FEditingDialog.Width - FFullDayCheckBox.Width - 10;
    FFullDayCheckBox.Position.Y := FTextLabel.Position.Y + int((FTextLabel.Height - FFullDayCheckBox.Height) / 2);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FFullDayCheckBox.Caption := TranslateTextEx(sTMSFNCPlannerFullDay);
    FFullDayCheckBox.Left := FEditingDialog.Width - FFullDayCheckBox.Width - 10;
    FFullDayCheckBox.Top := FTextLabel.Top + (FTextLabel.Height - FFullDayCheckBox.Height) div 2;
    {$ENDIF}
    FFullDayCheckBox.Parent := FContentPanel;

    FTextMemo := TMemo.Create(FContentPanel);
    {$IFDEF FMXLIB}
    FTextMemo.Align := TAlignLayout.Client;
    FTextMemo.Margins.Top := FTextLabel.Position.Y + FTextLabel.Height + 15;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FTextMemo.Align := alClient;
    {$IFDEF LCLLIB}
    FTextMemo.BorderSpacing.Top := FTextLabel.Top + FTextLabel.Height + 15;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FTextMemo.AlignWithMargins := True;
    FTextMemo.Margins.Top := FTextLabel.Top + FTextLabel.Height + 15;
    {$ENDIF}
    {$ENDIF}
    {$IFDEF LCLLIB}
    FTextMemo.BorderSpacing.Right := 10;
    FTextMemo.BorderSpacing.Bottom := 10;
    FTextMemo.BorderSpacing.Left := 10;
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FTextMemo.Margins.Right := 10;
    FTextMemo.Margins.Bottom := 10;
    FTextMemo.Margins.Left := 10;
    {$ENDIF}
    FTextMemo.Parent := FContentPanel;

    FEditingDialogCreated := True;

    SetEditingDialogTabOrder;
  end;

  Result.Background := FEditingBackground;
  Result.Panel := FEditingDialog;
  Result.FullDayCheckBox := FFullDayCheckBox;
  Result.StartTimeEdit := FStartTimeEdit;
  Result.EndTimeEdit := FEndTimeEdit;
  Result.StartDateEdit := FStartDateEdit;
  Result.EndDateEdit := FEndDateEdit;
  Result.TitleEdit := FTitleEdit;
  Result.TextMemo := FTextMemo;
  Result.TitleLabel := FTitleLabel;
  Result.TextLabel := FTextLabel;
  Result.StartTimeLabel := FStartTimeLabel;
  Result.EndTimeLabel := FEndTimeLabel;
  Result.BottomPanel := FBottomPanel;
  Result.ButtonOK := FButtonOK;
  Result.ButtonRemove := FButtonRemove;
  Result.ButtonCancel := FButtonCancel;
  Result.ResourcesComboBox := FResourcesComboBox;
  Result.ResourceLabel := FResourceLabel;
  Result.ContentPanel := FContentPanel;
  Result.ContentPanel.Visible := True;
  Result.CustomContentPanel := False;
  {$IFDEF FMXLIB}
  Result.BottomPanel.Align := TAlignLayout.None;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result.BottomPanel.Align := alNone;
  {$ENDIF}
  Result.BottomPanel.Width := 200;

  cp := nil;
  it := nil;
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    it := Items[AItemIndex];

  if Assigned(FCurrentPanel) then
  begin
    FCurrentPanel.Visible := False;
    {$IFDEF FMXLIB}
    FCurrentPanel.Align := TAlignLayout.None;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCurrentPanel.Align := alNone;
    {$ENDIF}
    FCurrentPanel.Parent := nil;
    FCurrentPanel := nil;
  end;

  DoGetCustomContentPanel(it, cp);
  if Assigned(cp) then
  begin
    if Assigned(Result.ContentPanel) then
    begin
      Result.ContentPanel.Visible := False;
      {$IFDEF FMXLIB}
      Result.ContentPanel.Align := TAlignLayout.None;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      Result.ContentPanel.Align := alNone;
      {$ENDIF}
    end;

    cp.Parent := Result.Panel;
    cp.Visible := True;
    Result.ContentPanel := cp;
    Result.CustomContentPanel := True;
    FCurrentPanel := cp;
  end;

  {$IFDEF VCLLIB}
  if Assigned(Result.Panel) then
    Result.Panel.Parent := Self;
  {$ENDIF}

  if not Result.CustomContentPanel then
  begin
    {$IFDEF FMXLIB}
    Result.ContentPanel.Align := TAlignLayout.None;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Result.ContentPanel.Align := alNone;
    {$ENDIF}
    Result.ContentPanel.Width := ScalePaintValue(350);
    Result.ContentPanel.Height := ScalePaintValue(300);
  end
  else
  begin
    {$IFNDEF FMXLIB}
    FEditingDialog.Left := (Width - Result.ContentPanel.Width) div 2;
    FEditingDialog.Top := (Height - Result.ContentPanel.Height) div 2;
    {$ENDIF}
  end;

  if Assigned(Result.Panel) and Assigned(Result.ContentPanel) and Assigned(Result.BottomPanel) then
  begin
    Result.Panel.Height := Max(Result.BottomPanel.Height, Result.ContentPanel.Height + Result.BottomPanel.Height);
    Result.Panel.Width := Max(Result.BottomPanel.Width, Result.ContentPanel.Width);
    {$IFDEF FMXLIB}
    Result.BottomPanel.Align := TAlignLayout.Bottom;
    Result.ContentPanel.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Result.BottomPanel.Align := alBottom;
    Result.ContentPanel.Align := alClient;
    {$ENDIF}
  end;

  {$IFDEF VCLLIB}
  if Assigned(Result.Panel) then
    Result.Panel.Parent := nil;
  {$ENDIF}

  {$IFDEF CMNLIB}
  if Assigned(Result.Background) then
  begin
    Result.Background.Bitmaps.Clear;
    bmp := MakeScreenshot;
    try
      Result.Background.Bitmaps.AddBitmap(bmp);
    finally
      bmp.Free;
    end;
  end;
  {$ENDIF}
end;

function TTMSFNCCustomPlanner.GetEndTimeSizeHandler: TTMSFNCPlannerSizeHandler;
begin
  if not FEndTimeSizeHandlerCreated then
  begin
    FEndTimeSizePanel := TTMSFNCPlannerSizeHandlerPanel.Create(Self);
    FEndTimeSizePanel.Planner := Self;
    FEndTimeSizePanel.Kind := pshpkEndTime;
    FEndTimeSizeHandlerCreated := True;
  end;

  Result.Background := FEndTimeSizePanel;
end;

function TTMSFNCCustomPlanner.DateTimeToCell(
  ADateTime: TDateTime; AEndDateTime: Boolean = False): TTMSFNCPlannerCell;
var
  v: Double;
  ch: Double;
begin
  v := DateTimeToValue(ADateTime, AEndDatetime, False);
  ch := GetTotalRowHeight;
  Result.Row := 0;
  if ch > 0 then
    Result.Row := Round((RowCount / ch) * v);
  Result.Col := DateTimeToPosition(ADateTime, AEndDatetime, False);
end;

function TTMSFNCCustomPlanner.DateTimeToPosition(ADateTime: TDateTime; AEndDateTime: Boolean = False; ACheckBounds: Boolean = True): Integer;
begin
  Result := 0;
  case GetDisplayMode of
    pmMultiDay, pmMultiDayRes, pmMultiResDay:
    begin
      Result := DaysBetween(ADateTime, FDisplayStartTime);
      if CompareDateTime(FDisplayStartTime, ADateTime) = GreaterThanValue then
        Result := -Result;

      if AEndDateTime and (CompareDateTime(ADateTime, IncDay(FDisplayStartTime, Result)) = EqualsValue) then
        Result := Result - 1;

      case GetDisplayMode of
        pmMultiResDay: Result := Result * Max(1, Resources.Count);
      end;
    end;
    pmMultiMonth:
    begin
      if AEndDateTime then
        ADateTime := IncDay(ADateTime, -1);

      Result := MonthOf(ADateTime) - MonthOf(FDisplayStartTime) + (12 * (YearOf(ADateTime) - YearOf(FDisplayStartTime)));
    end;
  end;

  if ACheckBounds then
    Result := Max(0, Min(Result, ColumnCount -1));
end;

function TTMSFNCCustomPlanner.DateTimeToValue(ADateTime: TDateTime; AEndDateTime: Boolean = False; ACheckBounds: Boolean = True): Double;
var
  st, et, str, etr: Double;
  s: Double;
  c: Double;
  v: Double;
  ps: Integer;
  d: Integer;
  crh: Double;

  {$IFDEF LCLWEBLIB}
  function BinarySearch(const Values: TTMSFNCPlannerDateTimes; const Item: TDateTime; out FoundIndex: Integer; Index, Count: Integer): Boolean;
  var
    L, H: Integer;
    mid, cmp: Integer;
  begin
    if (Index < 0) or ((Index > Values.Count - 1) and (Count > 0))
      or (Index + Count - 1 > Values.Count - 1) or (Count < 0)
      or (Index + Count < 0) then
      raise EArgumentOutOfRangeException.Create('Argument out of range');
    if Count = 0 then
    begin
      FoundIndex := Index;
      Exit(False);
    end;

    Result := False;
    L := Index;
    H := Index + Count - 1;
    while L <= H do
    begin
      mid := L + (H - L) shr 1;
      cmp := CompareDateTime(Values[mid], Item);
      if cmp < 0 then
        L := mid + 1
      else
      begin
        H := mid - 1;
        if cmp = 0 then
          Result := True;
      end;
    end;
    FoundIndex := L;
  end;
  {$ENDIF}
begin
  Result := 0;
  st := FDisplayStartTime;
  et := FDisplayEndTime;
  c := GetTotalRowHeight;
  if GetDisplayMode = pmCustom then
  begin
    {$IFDEF LCLWEBLIB}
    BinarySearch(FCustomDatesList, ADatetime, ps, 0, FCustomDatesList.Count);
    {$ENDIF}
    {$IFNDEF LCLWEBLIB}
    FCustomDatesList.BinarySearch(ADatetime, ps);
    {$ENDIF}
    ps := ps - 1;
    if ps = -1 then
      ps := ps + 1;

    if (ps >= 0) and (ps < FCustomDatesList.Count - 1) then
    begin
      str := FCustomDatesList[ps];
      etr := FCustomDatesList[ps + 1];

      crh := RowHeights[ps];
      if ACheckBounds then
        v := Max(st, Min(et, ADateTime))
      else
        v := ADateTime;

      if (crh > 0) then
        Result := RowPositions[ps] + (crh / (etr - str)) * (v - str);
    end;
  end
  else
  begin
    case GetDisplayMode of
      pmMultiDay, pmMultiResDay, pmMultiDayRes:
      begin
        ps := DateTimeToPosition(ADateTime, AEndDateTime);
        if GetDisplayMode = pmMultiResDay then
          ps := ps div Max(1, Resources.Count);

        st := IncDay(st, ps);
        et := IncDay(et, ps);
      end;
      pmMultiMonth:
      begin
        st := FDisplayStart;
        et := FDisplayEnd;
      end;
    end;

    case GetDisplayMode of
      pmMultiMonth:
      begin
        if AEndDateTime then
          d := DayOf(IncDay(ADateTime, -1)) + 1
        else
          d := DayOf(ADateTime);

        if ACheckBounds then
          v := Max(st, Min(et, d - 1 + Frac(ADateTime)))
        else
          v := d -1 + Frac(ADateTime);
      end
      else
      begin
        if ACheckBounds then
          v := Max(st, Min(et, ADateTime))
        else
          v := ADateTime;
      end;
    end;

    if (et - st) > 0 then
    begin
      s := c / (et - st);
      Result := (v - st) * s;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleDblClick(X, Y: Single);
var
  pf: TPointF;
  c: TTMSFNCPlannerCacheItem;
  gi, pi: Integer;
  a: string;
  it: TTMSFNCPlannerItem;
begin
  inherited;
  FDblClicked := True;
  FDblClickedMouseMove := True;
  pf := PointF(X, Y);
  FDownItem := nil;
  FDownCacheItemIdx := -1;
  c := XYToCacheItem(pf.X, pf.Y);
  if Assigned(c) then
  begin
    FDownItem := c.Item;
    FDownCacheItemIdx := TTMSFNCPlannerItemOpen(FDownItem).CacheList.IndexOf(c);
  end;

  if Assigned(FDownItem) and FDownItem.Enabled and (Interaction.MouseEditMode = pmemDoubleClick) and not Interaction.ReadOnly then
    HandleItemEditing(FDownItem, c);

  gi := -1;
  pi := -1;

  a := XYToGroupAnchor(X, Y, gi);
  if not ((a <> '') and (gi > -1)) then
    a := XYToPositionAnchor(X, Y, pi);

  if (a = '') and (pi = - 1) and (gi = -1) then
  begin
    c := XYToGroup(X, Y);
    if Assigned(c) then
      DoGroupDblClick(c.Group, c.Kind)
    else
    begin
      c := XYToFullDay(X, Y);
      if Assigned(c) then
      begin
        it := XYToFullDayItem(X, Y);
        if Assigned(it) then
          DoItemDblClick(it)
        else
          DoFullDayDblClick(c.FullDay, c.Kind)
      end
      else
      begin
        c := XYToPosition(X, Y);
        if Assigned(c) then
          DoPositionDblClick(c.Position, c.Kind)
        else
        begin
          it := XYToItem(X, Y);
          if Assigned(it) then
            DoItemDblClick(it);
        end;
      end;
    end;
  end;
end;

destructor TTMSFNCCustomPlanner.Destroy;
begin
  FGlobalFont.Free;

  FActiveItem := nil;
  if Assigned(FEditingBackground) then
    FEditingBackground.Free;

  FEditingBackground := nil;

  if Assigned(FEditingDialog) then
    FEditingDialog.Free;

  FEditingDialog := nil;

  if Assigned(FHintPanel) then
    FHintPanel.Free;

  FHintPanel := nil;

  if Assigned(FStartTimeSizePanel) then
    FStartTimeSizePanel.Free;

  FStartTimeSizePanel := nil;

  if Assigned(FEndTimeSizePanel) then
    FEndTimeSizePanel.Free;

  FEndTimeSizePanel := nil;

  FAnimateTimer.Free;
  FDownTimer.Free;
  FItemsAppearance.Free;
  FDefaultItem.Free;
  FModeSettings.Free;
  FTimeLine.Free;
  FGridCellAppearance.Free;
  FGroupsAppearance.Free;
  FFullDaysAppearance.Free;
  FTimeLineAppearance.Free;
  FInteraction.Free;
  FPositions.Free;
  FPositionsAppearance.Free;
  FSelectionAppearance.Free;
  FGroupsTopDisplay.Free;
  FFullDaysTopDisplay.Free;
  FPositionsTopDisplay.Free;
  FTimeLineLeftDisplay.Free;
  FGroupsBottomDisplay.Free;
  FFullDaysBottomDisplay.Free;
  FPositionsBottomDisplay.Free;
  FTimeLineRightDisplay.Free;
  FCustomDatesList.Free;
  FSelectedItems.Free;
  FCustomDateTimes.Free;
  FItemDisplay.Free;
  FFullDaysItemTopDisplay.Free;
  FFullDaysItemBottomDisplay.Free;
  FGridDisplay.Free;
  FItemCache.Free;
  FFullDaysItemTopCache.Free;
  FFullDaysItemBottomCache.Free;
  FGridCache.Free;
  FConflicts.Free;
  FDisplayGroups.Free;
  FGroupsTopCache.Free;
  FDisplayFullDays.Free;
  FFullDaysTopCache.Free;
  FPositionsTopCache.Free;
  FTimeLineLeftCache.Free;
  FGroupsBottomCache.Free;
  FFullDaysBottomCache.Free;
  FPositionsBottomCache.Free;
  FTimeLineRightCache.Free;
  inherited;
end;

function TTMSFNCCustomPlanner.SelectNextItem: TTMSFNCPlannerItem;
var
  Idx: Integer;
  s: Boolean;
begin
  if FActiveItem <> nil then
  begin
    Idx := FActiveItem.Index;
    while Idx + 1 < Items.Count do
    begin
      s := Items[Idx + 1].Selectable and Items[Idx + 1].Enabled;
      DoBeforeSelectItem(Items[Idx + 1], s);
      if (IsValidItem(Items[Idx + 1]) and not IsFullDayItem(Items[Idx + 1])) and s then
      begin
        if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
          HandleSelectLinkedItems(Items[idx + 1])
        else
          HandleSelectItem(Items[idx + 1]);

        DoAfterSelectItem(Items[idx + 1]);
        Break;
      end
      else
        Inc(Idx);
    end;

    if (Idx + 1 = Items.Count) then
      HandleSelectItem(nil);
  end
  else
  begin
    if (Items.Count > 0) then
    begin
      Idx := 0;
      while Idx < Items.Count do
      begin
        s := Items[Idx].Selectable and Items[Idx].Enabled;
        DoBeforeSelectItem(Items[Idx], s);
        if (IsValidItem(Items[Idx]) and not IsFullDayItem(Items[Idx])) and s then
        begin
          if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
            HandleSelectLinkedItems(Items[idx])
          else
            HandleSelectItem(Items[idx]);

          DoAfterSelectItem(Items[idx]);
          Break;
        end
        else
          Inc(Idx);
      end;
    end;
  end;

  Result := FActiveItem;
end;

function TTMSFNCCustomPlanner.SelectPreviousItem: TTMSFNCPlannerItem;
var
  Idx: Integer;
  s: Boolean;
begin
  if FActiveItem <> nil then
  begin
    Idx := FActiveItem.Index;
    while Idx > 0 do
    begin
      s := Items[Idx - 1].Selectable and Items[Idx - 1].Enabled;
      DoBeforeSelectItem(Items[Idx - 1], s);
      if IsValidItem(Items[Idx - 1]) and not IsFullDayItem(Items[Idx - 1]) and s then
      begin
        if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
          HandleSelectLinkedItems(Items[Idx - 1])
        else
          HandleSelectItem(Items[Idx - 1]);

        DoAfterSelectItem(Items[Idx - 1]);
        Break;
      end
      else
        Dec(Idx);
    end;

    if (Idx <= 0) then
      HandleSelectItem(nil);
  end
  else
  begin
    if Items.Count > 0 then
    begin
      Idx := Items.Count - 1;
      while Idx >= 0 do
      begin
        s := Items[Idx].Selectable and Items[Idx].Enabled;
        DoBeforeSelectItem(Items[Idx], s);
        if IsValidItem(Items[Idx]) and not IsFullDayItem(Items[Idx]) and s then
        begin
          if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
            HandleSelectLinkedItems(Items[idx])
          else
            HandleSelectItem(Items[idx]);

          DoAfterSelectItem(Items[idx]);
          Break;
        end
        else
          Dec(Idx);
      end;
    end;
  end;

  Result := FActiveItem;
end;

procedure TTMSFNCCustomPlanner.HandleDialogKey(var Key: Word; Shift: TShiftState);
var
  it: TTMSFNCPlannerItem;
begin
  if FInplaceEditorClosed then
  begin
    inherited;
    Exit;
  end;

  if ((Key = KEY_ESCAPE) or (Key = KEY_TAB) or (Key = KEY_F2)) and Assigned(FInplaceEditor) and FInplaceEditorActive then
  begin
    FCloseWithDialogKey := True;
    CloseInplaceEditor(Key = KEY_ESCAPE);
  end;

  if (Key = KEY_ESCAPE) and FEditorDialogActive then
    CloseEditingDialog(True);

  if (Key = KEY_RETURN) and FEditorDialogActive and Assigned(FTextMemo) and not FTextMemo.IsFocused then
    CloseEditingDialog(False);

  if (Key = KEY_TAB) and FInplaceEditorActive then
  begin
    Key := 0;
    Exit;
  end;

  it := nil;

  if (Key = KEY_TAB) and IsFocused then
  begin
    if ssShift in Shift then
      it := SelectPreviousItem
    else
      it := SelectNextItem;
  end;

  if Assigned(it) then
    Key := 0;

  inherited;
end;

procedure TTMSFNCCustomPlanner.DirtyItem(AItem: TTMSFNCPlannerItem);
var
  rse, rsa: Integer;
  I: Integer;
  it: TTMSFNCPlannerItemOpen;
  svdirty: TTMSFNCPlannerIntegerList;
  sta, ste: TDateTime;
  mxa, mxe: TDateTime;
begin
  if Assigned(AItem) then
  begin
    it := TTMSFNCPlannerItemOpen(AItem);
    case GetDisplayMode of
      pmDay, pmDayPeriod, pmHalfDayPeriod, pmMonth, pmCustom:
      begin
        rsa := AItem.Resource;
        rse := AItem.Resource;
      end;
      pmMultiDay, pmMultiMonth, pmMultiResDay, pmMultiDayRes:
      begin
        rsa := DateTimeToPosition(it.StartTime);
        rse := DateTimeToPosition(it.EndTime, True);
        case GetDisplayMode of
          pmMultiMonth:
          begin
            if Abs(it.EndTime - it.StartTime) < 1 then
              rse := DateTimeToPosition(it.EndTime);
          end;
          pmMultiResDay:
          begin
            rsa := rsa + AItem.Resource;
            rse := rse + AItem.Resource;
          end;
          pmMultiDayRes:
          begin
            rsa := rsa + (AItem.Resource * Max(1, GetNumDays));
            rse := rse + (AItem.Resource * Max(1, GetNumDays));
          end;
        end;
      end;
      else
      begin
        rsa := -1;
        rse := -1;
      end;
    end;

    svdirty := TTMSFNCPlannerIntegerList.Create;
    for I := 0 to it.CacheList.Count - 1 do
      svdirty.Add(it.CacheList[I].Position);

    it.DirtyList.Clear;

    for I := rsa to rse do
    begin
      if (((GetDisplayMode in [pmMultiResDay, pmMultiDayRes]) and (PositionToResource(I) = AItem.Resource)) or not (GetDisplayMode in [pmMultiResDay, pmMultiDayRes])) then
      begin
        sta := MaxPositionDateTime(it.StartTime, False, I);
        ste := MaxPositionDateTime(it.EndTime, True, I);
        mxa := CalculatePositionDateTime(FDisplayStartTime, I);
        mxe := CalculatePositionDateTime(FDisplayEndTime, I);
        if (((CompareDateTime(sta, mxa) >= EqualsValue) and (CompareDateTime(sta, mxe) <= EqualsValue))
          or ((CompareDateTime(ste, mxa) >= EqualsValue) and (CompareDateTime(ste, mxe) <= EqualsValue)))
            and (ste - sta > 0) then
              it.DirtyList.Add(I);
      end;
    end;

    it.CleanupList.Clear;
    for I := 0 to svdirty.Count - 1 do
    begin
      if not it.DirtyList.IndexOf(svdirty[I]) > -1 then
        it.CleanupList.Add(svdirty[I]);
    end;

    it.OldPositionsList.Clear;
    for I := 0 to it.PositionsList.Count - 1 do
      it.OldPositionsList.Add(it.PositionsList[I]);
    it.PositionsList.Clear;
    for I := 0 to it.DirtyList.Count - 1 do
      it.PositionsList.Add(it.DirtyList[I]);

    svdirty.Free;
  end;
end;

function TTMSFNCCustomPlanner.DisplayEndDateTime: TDateTime;
begin
  Result := CellToDateTime(MakeCell(ColumnCount - 1, RowCount));
end;

function TTMSFNCCustomPlanner.DisplayStartDateTime: TDateTime;
begin
  Result := CellToDateTime(MakeCell(0, 0));
end;

procedure TTMSFNCCustomPlanner.DrawArrow(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AArrowKind: TTMSFNCPlannerArrowKind);
var
  pth: TTMSFNCGraphicsPath;
  s: Single;
begin
  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Fill.Color := gcGray;
  AGraphics.Stroke.Color := gcGray;

  s := 7;
  pth := TTMSFNCGraphicsPath.Create;
  case AArrowKind of
    pakLeft:
    begin
      pth.MoveTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s));
    end;
    pakRight:
    begin
      pth.MoveTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s));
    end;
    pakUp:
    begin
      pth.MoveTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s));
    end;
    pakDown:
    begin
      pth.MoveTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s / 2, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2 + s));
      pth.LineTo(PointF(ARect.Left + ((ARect.Right - ARect.Left) - s) / 2 + s, ARect.Top + ((ARect.Bottom - ARect.Top) - s) / 2));
    end;
  end;

  pth.ClosePath;
  AGraphics.DrawPath(pth);
  pth.Free;
end;

procedure TTMSFNCCustomPlanner.DrawBorders(AGraphics: TTMSFNCGraphics);
var
  rrt, rrb, trl, trr, grt, grb, fdrt, fdrb: TRectF;
begin
  grt := GetGroupsTopRect;
  grb := GetGroupsBottomRect;
  fdrt := GetFullDaysTopRect;
  fdrb := GetFullDaysBottomRect;
  rrt := GetPositionsTopRect;
  rrb := GetPositionsBottomRect;
  trl := GetTimeLineLeftRect;
  trr := GetTimeLineRightRect;

  {$IFDEF CMNLIB}
  case OrientationMode of
    pomHorizontal:
    begin
      grt.Bottom := grt.Bottom - 1;
      grb.Bottom := grb.Bottom - 1;
      fdrt.Bottom := fdrt.Bottom - 1;
      fdrb.Bottom := fdrb.Bottom - 1;
      rrt.Bottom := rrt.Bottom - 1;
      rrb.Bottom := rrb.Bottom - 1;
      trl.Right := trl.Right - 1;
      trr.Right := trr.Right - 1;
    end;
    pomVertical:
    begin
      grt.Right := grt.Right - 1;
      grb.Right := grb.Right - 1;
      fdrt.Right := fdrt.Right - 1;
      fdrb.Right := fdrb.Right - 1;
      rrt.Right := rrt.Right - 1;
      rrb.Right := rrb.Right - 1;
      trl.Bottom := trl.Bottom - 1;
      trr.Bottom := trr.Bottom - 1;
    end;
  end;
  {$ENDIF}

  case OrientationMode of
    pomHorizontal:
    begin
      AGraphics.Stroke.Assign(PositionsAppearance.TopStroke);
      if (pplTop in PositionsAppearance.Layouts) and (PositionsAppearance.TopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(rrt.Left, rrt.Top), PointF(rrt.Right, rrt.Top));
        AGraphics.DrawLine(PointF(rrt.Left, rrt.Bottom), PointF(rrt.Right, rrt.Bottom), gcpmRightUp, gcpmRightUp);
      end
      else if not (pglTop in GroupsAppearance.Layouts) or (GroupsAppearance.TopSize <= 0) or (FDisplayGroups.Count = 0) then
        AGraphics.DrawLine(PointF(rrt.Right, rrt.Top), PointF(rrt.Right, rrt.Bottom), gcpmRightDown, gcpmRightUp);

      AGraphics.Stroke.Assign(PositionsAppearance.BottomStroke);
      if (pplBottom in PositionsAppearance.Layouts) and (PositionsAppearance.BottomSize > 0) then
      begin
        AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Right, rrb.Top), gcpmLeftDown, gcpmLeftDown);
        AGraphics.DrawLine(PointF(rrb.Left, rrb.Bottom), PointF(rrb.Right, rrb.Bottom), gcpmLeftUp, gcpmLeftUp);
      end
      else if not (pglBottom in GroupsAppearance.Layouts) or (GroupsAppearance.BottomSize <= 0) or (FDisplayGroups.Count = 0) then
        AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Left, rrb.Bottom), gcpmLeftDown, gcpmLeftUp);

      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);
      if (pglTop in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (GroupsAppearance.TopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(grt.Left, grt.Top), PointF(grt.Right, grt.Top));
        AGraphics.DrawLine(PointF(grt.Left, grt.Bottom), PointF(grt.Right, grt.Bottom), gcpmRightUp, gcpmRightUp);
      end;

      if not (pplTop in PositionsAppearance.Layouts) or (PositionsAppearance.TopSize <= 0) then
        AGraphics.DrawLine(PointF(grt.Right, grt.Top), PointF(grt.Right, grt.Bottom), gcpmRightDown, gcpmRightUp);

      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);
      if (pglBottom in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (GroupsAppearance.BottomSize > 0) then
      begin
        AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Right, grb.Top), gcpmLeftDown, gcpmLeftDown);
        AGraphics.DrawLine(PointF(grb.Left, grb.Bottom), PointF(grb.Right, grb.Bottom), gcpmLeftUp, gcpmLeftUp);
      end;

      if not (pplBottom in PositionsAppearance.Layouts) or (PositionsAppearance.BottomSize <= 0) then
        AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Left, grb.Bottom), gcpmLeftDown, gcpmLeftUp);

      AGraphics.Stroke.Assign(FullDaysAppearance.TopStroke);
      if (pfdlTop in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (GetFullDaysTopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(fdrt.Left, fdrt.Top), PointF(fdrt.Right, fdrt.Top));
        AGraphics.DrawLine(PointF(fdrt.Left, fdrt.Bottom), PointF(fdrt.Right, fdrt.Bottom), gcpmRightUp, gcpmRightUp);
      end;

      AGraphics.Stroke.Assign(FullDaysAppearance.BottomStroke);
      if (pfdlBottom in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (GetFullDaysBottomSize > 0) then
      begin
        AGraphics.DrawLine(PointF(fdrb.Left, fdrb.Top), PointF(fdrb.Right, fdrb.Top), gcpmLeftDown, gcpmLeftDown);
        AGraphics.DrawLine(PointF(fdrb.Left, fdrb.Bottom), PointF(fdrb.Right, fdrb.Bottom), gcpmLeftUp, gcpmLeftUp);
      end;

      AGraphics.Stroke.Assign(TimeLineAppearance.LeftStroke);
      if (ptlLeft in TimeLineAppearance.Layouts) and (TimeLineAppearance.LeftSize > 0) then
      begin
        AGraphics.DrawLine(PointF(trl.Left, trl.Top), PointF(trl.Left, trl.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(trl.Right, trl.Top), PointF(trl.Right, trl.Bottom), gcpmLeftDown, gcpmLeftUp);
      end
      else
        AGraphics.DrawLine(PointF(trl.Left, trl.Bottom), PointF(trl.Right, trl.Bottom), gcpmRightDown, gcpmLeftDown);

      AGraphics.Stroke.Assign(TimeLineAppearance.RightStroke);
      if (ptlRight in TimeLineAppearance.Layouts) and (TimeLineAppearance.RightSize > 0) then
      begin
        AGraphics.DrawLine(PointF(trr.Left, trr.Top), PointF(trr.Left, trr.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(trr.Right, trr.Top), PointF(trr.Right, trr.Bottom), gcpmLeftDown, gcpmLeftUp);
      end
      else
        AGraphics.DrawLine(PointF(trr.Left, trr.Top), PointF(trr.Right, trr.Top), gcpmRightUp, gcpmLeftUp);
    end;
    pomVertical:
    begin
      AGraphics.Stroke.Assign(TimeLineAppearance.LeftStroke);
      if (ptlLeft in TimeLineAppearance.Layouts) and (TimeLineAppearance.LeftSize > 0) then
      begin
        AGraphics.DrawLine(PointF(trl.Left, trl.Top), PointF(trl.Right, trl.Top));
        AGraphics.DrawLine(PointF(trl.Left, trl.Bottom), PointF(trl.Right, trl.Bottom), gcpmRightUp, gcpmRightUp);
      end
      else
        AGraphics.DrawLine(PointF(trl.Right, trl.Top), PointF(trl.Right, trl.Bottom), gcpmRightDown, gcpmRightUp);

      AGraphics.Stroke.Assign(TimeLineAppearance.RightStroke);
      if (ptlRight in TimeLineAppearance.Layouts) and (TimeLineAppearance.RightSize > 0) then
      begin
        AGraphics.DrawLine(PointF(trr.Left, trr.Top), PointF(trr.Right, trr.Top), gcpmLeftDown, gcpmLeftDown);
        AGraphics.DrawLine(PointF(trr.Left, trr.Bottom), PointF(trr.Right, trr.Bottom), gcpmLeftUp, gcpmLeftUp);
      end
      else
        AGraphics.DrawLine(PointF(trr.Left, trr.Top), PointF(trr.Left, trr.Bottom), gcpmLeftDown, gcpmLeftUp);

      AGraphics.Stroke.Assign(PositionsAppearance.TopStroke);
      if (pplTop in PositionsAppearance.Layouts) and (PositionsAppearance.TopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(rrt.Left, rrt.Top), PointF(rrt.Left, rrt.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(rrt.Right, rrt.Top), PointF(rrt.Right, rrt.Bottom), gcpmLeftDown, gcpmLeftUp);
      end
      else if not (pglTop in GroupsAppearance.Layouts) or (GroupsAppearance.TopSize <= 0) or (FDisplayGroups.Count = 0) then
        AGraphics.DrawLine(PointF(rrt.Left, rrt.Bottom), PointF(rrt.Right, rrt.Bottom), gcpmRightDown, gcpmLeftDown);

      AGraphics.Stroke.Assign(PositionsAppearance.BottomStroke);
      if (pplBottom in PositionsAppearance.Layouts) and (PositionsAppearance.Bottomsize > 0) then
      begin
        AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Left, rrb.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(rrb.Right, rrb.Top), PointF(rrb.Right, rrb.Bottom), gcpmLeftDown, gcpmLeftUp);
      end
      else if not (pglBottom in GroupsAppearance.Layouts) or (GroupsAppearance.BottomSize <= 0) or (FDisplayGroups.Count = 0) then
        AGraphics.DrawLine(PointF(rrb.Left, rrb.Top), PointF(rrb.Right, rrb.Top), gcpmRightUp, gcpmLeftUp);

      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);
      if (pglTop in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (GroupsAppearance.TopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(grt.Left, grt.Top), PointF(grt.Left, grt.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(grt.Right, grt.Top), PointF(grt.Right, grt.Bottom), gcpmLeftDown, gcpmLeftUp);
      end;

      if not (pplTop in PositionsAppearance.Layouts) or (PositionsAppearance.TopSize <= 0) then
        AGraphics.DrawLine(PointF(grt.Left, grt.Bottom), PointF(grt.Right, grt.Bottom), gcpmRightDown, gcpmLeftDown);

      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);
      if (pglBottom in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (GroupsAppearance.BottomSize > 0) then
      begin
        AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Left, grb.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(grb.Right, grb.Top), PointF(grb.Right, grb.Bottom), gcpmLeftDown, gcpmLeftUp);
      end;

      if not (pplBottom in PositionsAppearance.Layouts) or (PositionsAppearance.BottomSize <= 0) then
        AGraphics.DrawLine(PointF(grb.Left, grb.Top), PointF(grb.Right, grb.Top), gcpmRightUp, gcpmLeftUp);

      AGraphics.Stroke.Assign(FullDaysAppearance.TopStroke);
      if (pfdlTop in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (GetFullDaysTopSize > 0) then
      begin
        AGraphics.DrawLine(PointF(fdrt.Left, fdrt.Top), PointF(fdrt.Left, fdrt.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(fdrt.Right, fdrt.Top), PointF(fdrt.Right, fdrt.Bottom), gcpmLeftDown, gcpmLeftUp);
      end;

      AGraphics.Stroke.Assign(FullDaysAppearance.BottomStroke);
      if (pfdlBottom in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (GetFullDaysBottomSize > 0) then
      begin
        AGraphics.DrawLine(PointF(fdrb.Left, fdrb.Top), PointF(fdrb.Left, fdrb.Bottom), gcpmRightDown, gcpmRightUp);
        AGraphics.DrawLine(PointF(fdrb.Right, fdrb.Top), PointF(fdrb.Right, fdrb.Bottom), gcpmLeftDown, gcpmLeftUp);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawDisplay(AGraphics: TTMSFNCGraphics; ADisplay: TTMSFNCPlannerDisplayList);
var
  I: Integer;
  bmp: TBitmap;
  cache: TTMSFNCPlannerCacheItem;
  st: TTMSFNCGraphicsSaveState;
  r: TRectF;
  b: TTMSFNCBitmap;
begin
  if ADisplay.Count = 0 then
    Exit;

  st := AGraphics.SaveState;
  if (ADisplay is TTMSFNCPlannerGridDisplayList) or (ADisplay is TTMSFNCPlannerItemDisplayList) then
  begin
    r := GetContentClipRect;
    {$IFDEF CMNLIB}
    r.Bottom := r.Bottom - 1;
    {$ENDIF}
  end
  else if ADisplay is TTMSFNCPlannerFullDaysItemTopDisplayList then
  begin
    r := GetFullDaysTopRect;
    case OrientationMode of
      pomHorizontal: r.Right := r.Right + 1;
      pomVertical: r.Bottom := r.Bottom + 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerFullDaysItemBottomDisplayList then
  begin
    r := GetFullDaysBottomRect;
    case OrientationMode of
      pomHorizontal: r.Left := r.Left - 1;
      pomVertical: r.Top := r.Top - 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerGroupsTopDisplayList then
  begin
    r := GetGroupsTopRect;
    case OrientationMode of
      pomHorizontal: r.Right := r.Right + 1;
      pomVertical: r.Bottom := r.Bottom + 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerGroupsBottomDisplayList then
  begin
    r := GetGroupsBottomRect;
    case OrientationMode of
      pomHorizontal: r.Left := r.Left - 1;
      pomVertical: r.Top := r.Top - 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerFullDaysTopDisplayList then
  begin
    r := GetFullDaysTopRect;
    case OrientationMode of
      pomHorizontal: r.Right := r.Right + 1;
      pomVertical: r.Bottom := r.Bottom + 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerFullDaysBottomDisplayList then
  begin
    r := GetFullDaysBottomRect;
    case OrientationMode of
      pomHorizontal: r.Left := r.Left - 1;
      pomVertical: r.Top := r.Top - 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerPositionsTopDisplayList then
  begin
    r := GetPositionsTopRect;
    case OrientationMode of
      pomHorizontal: r.Right := r.Right + 1;
      pomVertical: r.Bottom := r.Bottom + 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerPositionsBottomDisplayList then
  begin
    r := GetPositionsBottomRect;
    case OrientationMode of
      pomHorizontal: r.Left := r.Left - 1;
      pomVertical: r.Top := r.Top - 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerTimeLineLeftDisplayList then
  begin
    r := GetTimeLineLeftRect;
    case OrientationMode of
      pomHorizontal: r.Bottom := r.Bottom + 1;
      pomVertical: r.Right := r.Right + 1;
    end;
  end
  else if ADisplay is TTMSFNCPlannerTimeLineRightDisplayList then
  begin
    r := GetTimeLineRightRect;
    case OrientationMode of
      pomHorizontal: r.Top := r.Top - 1;
      pomVertical: r.Left := r.Left - 1;
    end;
  end;

  AGraphics.ClipRect(r);

  for I := 0 to ADisplay.Count - 1 do
  begin
    cache := ADisplay[I];
    bmp := cache.Bitmap;
    if Assigned(bmp) then
    begin
      b := TTMSFNCBitmap.Create;
      try
        b.Assign(bmp);
        AGraphics.DrawBitmap(cache.DrawRect, b);
      finally
        b.Free;
      end;
    end
    else if ADisplay is TTMSFNCPlannerItemDisplayList then
      DrawItem(AGraphics, cache.DrawRect, cache.Item, cache.Idx)
    else if ADisplay is TTMSFNCPlannerFullDaysItemTopDisplayList then
      DrawFullDaysItem(AGraphics, cache.DrawRect, cache.Item, cache.Idx, cache.Kind)
    else if ADisplay is TTMSFNCPlannerFullDaysItemBottomDisplayList then
      DrawFullDaysItem(AGraphics, cache.DrawRect, cache.Item, cache.Idx, cache.Kind)
    else if ADisplay is TTMSFNCPlannerGridDisplayList then
      DrawCell(AGraphics, cache.DrawRect, cache.Col, cache.Row, cache.StartTime, cache.EndTime, cache.Position, cache.Kind)
    else if ADisplay is TTMSFNCPlannerPositionsDisplayList then
      DrawPosition(AGraphics, cache.DrawRect, cache.Position, cache.Kind)
    else if ADisplay is TTMSFNCPlannerGroupsDisplayList then
      DrawGroup(AGraphics, cache.DrawRect, cache.Group, cache.StartPosition, cache.EndPosition, cache.Kind)
    else if ADisplay is TTMSFNCPlannerFullDaysDisplayList then
      DrawFullDay(AGraphics, cache.DrawRect, cache.FullDay, cache.StartPosition, cache.EndPosition, cache.Kind)
    else if ADisplay is TTMSFNCPlannerTimeLineDisplayList then
      DrawTime(AGraphics, cache.DrawRect, cache.Value, cache.Row, cache.Kind);
  end;

  if ADisplay is TTMSFNCPlannerItemDisplayList then
    DrawItemLinks(AGraphics);

  if ADisplay is TTMSFNCPlannerTimeLineDisplayList then
    DrawCurrentTimeInTimeLine(AGraphics, ADisplay is TTMSFNCPlannerTimeLineLeftDisplayList);

  if ADisplay is TTMSFNCPlannerGridDisplayList then
    DoAfterDrawGrid(AGraphics, r)
  else if ADisplay is TTMSFNCPlannerTimeLineDisplayList then
    DoAfterDrawTimeLine(AGraphics, ADisplay is TTMSFNCPlannerTimeLineLeftDisplayList, r);

  AGraphics.RestoreState(st);
end;

procedure TTMSFNCCustomPlanner.DrawEmptySpaces(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  b, df: Boolean;
  rm: TTMSFNCGraphicsModifyRectMode;
begin
  if PositionsAppearance.FillEmptySpaces then
  begin
    //position top left
    if (pplTop in PositionsAppearance.Layouts) and (PositionsAppearance.TopSize > 0) then
    begin
      r := GetPositionTopLeftEmptyRect;
      {$IFDEF CMNLIB}
      r.Right := r.Right + 1;
      r.Bottom := r.Bottom + 1;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(PositionsAppearance.TopFill);
      AGraphics.Stroke.Assign(PositionsAppearance.TopStroke);

      DoBeforeDrawPositionEmptySpace(AGraphics, r, ppesTopLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftRightDown);

        DoAfterDrawPositionEmptySpace(AGraphics, r, ppesTopLeft);
      end;

      //position top right
      r := GetPositionTopRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Right := r.Right + 1;
          r.Bottom := r.Bottom + 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal: rm := gcrmShiftRightUp;
        pomVertical: rm := gcrmShiftDownAndExpandWidth;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(PositionsAppearance.TopFill);
      AGraphics.Stroke.Assign(PositionsAppearance.TopStroke);

      DoBeforeDrawPositionEmptySpace(AGraphics, r, ppesTopRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawPositionEmptySpace(AGraphics, r, ppesTopRight);
      end;
    end;

    if (pplBottom in PositionsAppearance.Layouts) and (PositionsAppearance.BottomSize > 0) then
    begin
      //position bottom left
      r := GetPositionBottomLeftEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Bottom := r.Bottom + 1;
        end;
        pomVertical:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal: rm := gcrmShiftLeftDown;
        pomVertical: rm := gcrmShiftRightUp;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(PositionsAppearance.BottomFill);
      AGraphics.Stroke.Assign(PositionsAppearance.BottomStroke);

      DoBeforeDrawPositionEmptySpace(AGraphics, r, ppesBottomLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawPositionEmptySpace(AGraphics, r, ppesBottomLeft);
      end;

      //position bottom right
      r := GetPositionBottomRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal: rm := gcrmShiftLeftUp;
        pomVertical: rm := gcrmShiftUpAndExpandWidth;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(PositionsAppearance.BottomFill);
      AGraphics.Stroke.Assign(PositionsAppearance.BottomStroke);

      DoBeforeDrawPositionEmptySpace(AGraphics, r, ppesBottomRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawPositionEmptySpace(AGraphics, r, ppesBottomRight);
      end;
    end;
  end;

  if GroupsAppearance.FillEmptySpaces then
  begin
    if (pglTop in GroupsAppearance.Layouts) and (GroupsAppearance.TopSize > 0) then
    begin
      //Group top left
      r := GetGroupTopLeftEmptyRect;
      {$IFDEF CMNLIB}
      r.Right := r.Right + 1;
      r.Bottom := r.Bottom + 1;
      {$ENDIF}
      rm := gcrmShiftRightDown;
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.TopFill);
      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, pgesTopLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawGroupEmptySpace(AGraphics, r, pgesTopLeft);
      end;

      //Group top right
      r := GetGroupTopRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Bottom := r.Bottom + 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal:rm := gcrmShiftRightUp;
        pomVertical: rm := gcrmShiftLeftDown;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.TopFill);
      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, pgesTopRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawGroupEmptySpace(AGraphics, r, pgesTopRight);
      end;
    end;

    if (pglBottom in GroupsAppearance.Layouts) and (GroupsAppearance.BottomSize > 0) then
    begin
      //Group bottom left
      r := GetGroupBottomLeftEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Bottom := r.Bottom + 1;
        end;
        pomVertical:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal: rm := gcrmShiftLeftDown;
        pomVertical: rm := gcrmShiftRightUp;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.BottomFill);
      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, pgesBottomLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawGroupEmptySpace(AGraphics, r, pgesBottomLeft);
      end;

      //Group bottom right
      r := GetGroupBottomRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(GroupsAppearance.BottomFill);
      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);

      DoBeforeDrawGroupEmptySpace(AGraphics, r, pgesBottomRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftLeftUp);

        DoAfterDrawGroupEmptySpace(AGraphics, r, pgesBottomRight);
      end;
    end;
  end;

  if FullDaysAppearance.FillEmptySpaces then
  begin
    if (GetFullDaysTopSize <= 0) then
    begin
      //FullDay top left
      r := GetFullDayTopLeftEmptyRect;
      {$IFDEF CMNLIB}
      r.Right := r.Right + 1;
      r.Bottom := r.Bottom + 1;
      {$ENDIF}
      rm := gcrmShiftRightDown;
      b := True;
      df := True;

      AGraphics.Fill.Assign(FullDaysAppearance.TopFill);
      AGraphics.Stroke.Assign(FullDaysAppearance.TopStroke);

      DoBeforeDrawFullDayEmptySpace(AGraphics, r, pfdesTopLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawFullDayEmptySpace(AGraphics, r, pfdesTopLeft);
      end;

      //FullDay top right
      r := GetFullDayTopRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Bottom := r.Bottom + 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal:rm := gcrmShiftRightUp;
        pomVertical: rm := gcrmShiftLeftDown;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(FullDaysAppearance.TopFill);
      AGraphics.Stroke.Assign(FullDaysAppearance.TopStroke);

      DoBeforeDrawFullDayEmptySpace(AGraphics, r, pfdesTopRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawFullDayEmptySpace(AGraphics, r, pfdesTopRight);
      end;
    end;

    if (GetFullDaysBottomSize <= 0) then
    begin
      //FullDay bottom left
      r := GetFullDayBottomLeftEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Bottom := r.Bottom + 1;
        end;
        pomVertical:
        begin
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      case OrientationMode of
        pomHorizontal: rm := gcrmShiftLeftDown;
        pomVertical: rm := gcrmShiftRightUp;
        else
          rm := gcrmNone;
      end;
      b := True;
      df := True;

      AGraphics.Fill.Assign(FullDaysAppearance.BottomFill);
      AGraphics.Stroke.Assign(FullDaysAppearance.BottomStroke);

      DoBeforeDrawFullDayEmptySpace(AGraphics, r, pfdesBottomLeft, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, rm);

        DoAfterDrawFullDayEmptySpace(AGraphics, r, pfdesBottomLeft);
      end;

      //FullDay bottom right
      r := GetFullDayBottomRightEmptyRect;
      {$IFDEF CMNLIB}
      case OrientationMode of
        pomHorizontal:
        begin
          r.Left := r.Left - 1;
          r.Top := r.Top - 1;
        end;
        pomVertical:
        begin
          r.Left := r.Left - 1;
          r.Right := r.Right + 1;
          r.Top := r.Top - 1;
        end;
      end;
      {$ENDIF}
      b := True;
      df := True;

      AGraphics.Fill.Assign(FullDaysAppearance.BottomFill);
      AGraphics.Stroke.Assign(FullDaysAppearance.BottomStroke);

      DoBeforeDrawFullDayEmptySpace(AGraphics, r, pfdesBottomRight, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(r, gcrmShiftLeftUp);

        DoAfterDrawFullDayEmptySpace(AGraphics, r, pfdesBottomRight);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawGrid(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FGridDisplay);
end;

procedure TTMSFNCCustomPlanner.DrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
var
  b, df: Boolean;
  str: String;
  txtr: TRectF;
begin
  case AKind of
    ikGroupTop:
    begin
      AGraphics.Stroke.Assign(GroupsAppearance.TopStroke);
      AGraphics.Fill.Assign(GroupsAppearance.TopFill);
    end;
    ikGroupBottom:
    begin
      AGraphics.Stroke.Assign(GroupsAppearance.BottomStroke);
      AGraphics.Fill.Assign(GroupsAppearance.BottomFill);
    end;
  end;

  FixStroke(AGraphics);

  b := True;
  df := True;
  DoBeforeDrawGroup(AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(ARect, gcrmNone);

    case AKind of
      ikGroupTop: AGraphics.Font.Assign(GroupsAppearance.TopFont);
      ikGroupBottom: AGraphics.Font.Assign(GroupsAppearance.BottomFont);
    end;

    txtr := ARect;
    InflateRectEx(txtr, -2, -2);

    str := GetGroupText(AGroup);
    DoGetGroupText(AGroup, AKind, str);
    b := True;
    DoBeforeDrawGroupText(AGraphics, txtr, AGroup, AStartPosition, AEndPosition, AKind, str, b);

    if b then
    begin
      case AKind of
        ikGroupTop:
        begin
          case GroupsAppearance.TopVerticalTextMode of
            pvtmAuto:
            begin
              case OrientationMode of
                pomHorizontal: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, -90);
                pomVertical: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign);
              end;
            end;
            pvtmAlways: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, -90);
            pvtmNone: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign);
          end;
        end;
        ikGroupBottom:
        begin
          case GroupsAppearance.BottomVerticalTextMode of
            pvtmAuto:
            begin
              case OrientationMode of
                pomHorizontal: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 90);
                pomVertical: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign);
              end;
            end;
            pvtmAlways: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign, gttNone, 90);
            pvtmNone: AGraphics.DrawText(txtr, str, False, GroupsAppearance.TopHorizontalTextAlign, GroupsAppearance.TopVerticalTextAlign);
          end;
        end;
      end;
      DoAfterDrawGroupText(AGraphics, txtr, AGroup, AStartPosition, AEndPosition, AKind, str);
    end;

    DoAfterDrawGroup(AGraphics, ARect, AGroup, AStartPosition, AEndPosition, AKind);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawGroups(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FGroupsTopDisplay);
  DrawDisplay(AGraphics, FGroupsBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.DrawFullDay(AGraphics: TTMSFNCGraphics; ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
var
  b, df: Boolean;
begin
  case AKind of
    ikFullDayTop:
    begin
      AGraphics.Stroke.Assign(FullDaysAppearance.TopStroke);
      AGraphics.Fill.Assign(FullDaysAppearance.TopFill);
      AGraphics.Font.Assign(FullDaysAppearance.TopFont);
    end;
    ikFullDayBottom:
    begin
      AGraphics.Stroke.Assign(FullDaysAppearance.BottomStroke);
      AGraphics.Fill.Assign(FullDaysAppearance.BottomFill);
      AGraphics.Font.Assign(FullDaysAppearance.BottomFont);
    end;
  end;

  FixStroke(AGraphics);

  b := True;
  df := True;
  DoBeforeDrawFullDay(AGraphics, ARect, AFullDay, AStartPosition, AEndPosition, AKind, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(ARect, gcrmNone);

    DoAfterDrawFullDay(AGraphics, ARect, AFullDay, AStartPosition, AEndPosition, AKind);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawFullDays(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FFullDaysTopDisplay);
  DrawDisplay(AGraphics, FFullDaysBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.DrawFullDaysItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ACacheIndex: Integer; AKind:TTMSFNCPlannerCacheItemKind);
var
  strt: String;
  b, df: Boolean;
  txtr, txtrt: TRectF;
  ithat, itvat: TTMSFNCGraphicsTextAlign;
  ir: Integer;
begin
  if not Assigned(AItem) then
    Exit;

  if AItem.Enabled then
  begin
    if FSelectedItems.IndexOf(AItem) > -1 then
    begin
      if AItem = FActiveItem then
      begin
        AGraphics.Fill.Assign(ItemsAppearance.ActiveFill);
        AGraphics.Fill.Color := AItem.ActiveColor;
        AGraphics.Stroke.Assign(ItemsAppearance.ActiveStroke);
        AGraphics.Stroke.Color := AItem.ActiveStrokeColor;
      end
      else
      begin
        AGraphics.Fill.Assign(ItemsAppearance.SelectedFill);
        AGraphics.Fill.Color := AItem.SelectedColor;
        AGraphics.Stroke.Assign(ItemsAppearance.SelectedStroke);
        AGraphics.Stroke.Color := AItem.SelectedStrokeColor;
      end;
    end
    else
    begin
      AGraphics.Fill.Assign(ItemsAppearance.Fill);
      AGraphics.Fill.Color := AItem.Color;
      AGraphics.Stroke.Assign(ItemsAppearance.Stroke);
      AGraphics.Stroke.Color := AItem.StrokeColor;
    end;
  end
  else
  begin
    AGraphics.Fill.Assign(ItemsAppearance.DisabledFill);
    AGraphics.Fill.Color := AItem.DisabledColor;
    AGraphics.Stroke.Assign(ItemsAppearance.DisabledStroke);
    AGraphics.Stroke.Color := AItem.DisabledStrokeColor;
  end;

  FixStroke(AGraphics);

  df := True;
  b := True;
  DoBeforeDrawItem(AGraphics, ARect, AItem, b, df);

  if b then
  begin
    if df then
    begin
      if AItem.Rounding > 0 then
      begin
        ir := Min(Trunc(Min((ARect.Right - ARect.Left), (ARect.Bottom - ARect.Top))/2), AItem.Rounding);
        AGraphics.DrawRoundRectangle(Arect, ir, AItem.RoundingCorners, gcrmNone);
      end
      else
        AGraphics.DrawRectangle(ARect, gcrmNone);
    end;

    strt := AItem.Title;
    DoGetItemTitleText(AItem, pgtmDrawing, strt);

    if (strt <> '') and AItem.ShowTitle then
    begin
      txtr := ARect;

      if AItem.Enabled then
      begin
        if FSelectedItems.IndexOf(AItem) > -1 then
        begin
          if AItem = FActiveItem then
          begin
            AGraphics.Fill.Assign(ItemsAppearance.ActiveTitleFill);
            AGraphics.Fill.Color := AItem.ActiveTitleColor;
            AGraphics.Stroke.Assign(ItemsAppearance.ActiveTitleStroke);
            AGraphics.Stroke.Color := AItem.ActiveTitleStrokeColor;
          end
          else
          begin
            AGraphics.Fill.Assign(ItemsAppearance.SelectedTitleFill);
            AGraphics.Fill.Color := AItem.SelectedTitleColor;
            AGraphics.Stroke.Assign(ItemsAppearance.SelectedTitleStroke);
            AGraphics.Stroke.Color := AItem.SelectedTitleStrokeColor;
          end;
        end
        else
        begin
          AGraphics.Fill.Assign(ItemsAppearance.TitleFill);
          AGraphics.Fill.Color := AItem.TitleColor;
          AGraphics.Stroke.Assign(ItemsAppearance.TitleStroke);
          AGraphics.Stroke.Color := AItem.TitleStrokeColor;
        end;
      end
      else
      begin
        AGraphics.Fill.Assign(ItemsAppearance.DisabledTitleFill);
        AGraphics.Fill.Color := AItem.DisabledTitleColor;
        AGraphics.Stroke.Assign(ItemsAppearance.DisabledTitleStroke);
        AGraphics.Stroke.Color := AItem.DisabledTitleStrokeColor;
      end;

      FixStroke(AGraphics);

      if AItem.Enabled then
      begin
        if FSelectedItems.IndexOf(AItem) > -1 then
        begin
          if AItem = FActiveItem then
          begin
            AGraphics.Font.Assign(ItemsAppearance.ActiveTitleFont);
            if not AItem.UseDefaultAppearance then
            begin
              if AItem.ActiveTitleFontName <> '' then
                AGraphics.Font.Name := AItem.ActiveTitleFontName;

              {$IFDEF FMXLIB}
              if AItem.ActiveTitleFontSize <>  -1 then
                AGraphics.Font.Size := AItem.ActiveTitleFontSize;
              {$ENDIF}
              {$IFDEF CMNWEBLIB}
              if AItem.ActiveTitleFontSize <>  -1 then
                AGraphics.Font.Size := Round(AItem.ActiveTitleFontSize);
              {$ENDIF}

              AGraphics.Font.Style := AItem.ActiveTitleFontStyle;
              AGraphics.Font.Color := AItem.ActiveTitleFontColor;
            end;
          end
          else
          begin
            AGraphics.Font.Assign(ItemsAppearance.SelectedTitleFont);
            if not AItem.UseDefaultAppearance then
            begin
              if AItem.SelectedTitleFontName <> '' then
                AGraphics.Font.Name := AItem.SelectedTitleFontName;

              {$IFDEF FMXLIB}
              if AItem.SelectedTitleFontSize <>  -1 then
                AGraphics.Font.Size := AItem.SelectedTitleFontSize;
              {$ENDIF}
              {$IFDEF CMNWEBLIB}
              if AItem.SelectedTitleFontSize <>  -1 then
                AGraphics.Font.Size := Round(AItem.SelectedTitleFontSize);
              {$ENDIF}

              AGraphics.Font.Style := AItem.SelectedTitleFontStyle;
              AGraphics.Font.Color := AItem.SelectedTitleFontColor;
            end;
          end;
        end
        else
        begin
          AGraphics.Font.Assign(ItemsAppearance.TitleFont);
          if not AItem.UseDefaultAppearance then
          begin
            if AItem.TitleFontName <> '' then
              AGraphics.Font.Name := AItem.TitleFontName;

            {$IFDEF FMXLIB}
            if AItem.TitleFontSize <>  -1 then
              AGraphics.Font.Size := AItem.TitleFontSize;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            if AItem.TitleFontSize <>  -1 then
              AGraphics.Font.Size := Round(AItem.TitleFontSize);
            {$ENDIF}

            AGraphics.Font.Style := AItem.TitleFontStyle;
            AGraphics.Font.Color := AItem.TitleFontColor;
          end;
        end;
      end
      else
      begin
        AGraphics.Font.Assign(ItemsAppearance.DisabledTitleFont);
        if not AItem.UseDefaultAppearance then
        begin
          if AItem.DisabledTitleFontName <> '' then
            AGraphics.Font.Name := AItem.DisabledTitleFontName;

          {$IFDEF FMXLIB}
          if AItem.DisabledTitleFontSize <>  -1 then
            AGraphics.Font.Size := AItem.DisabledTitleFontSize;
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          if AItem.DisabledTitleFontSize <>  -1 then
            AGraphics.Font.Size := Round(AItem.DisabledTitleFontSize);
          {$ENDIF}

          AGraphics.Font.Style := AItem.DisabledTitleFontStyle;
          AGraphics.Font.Color := AItem.DisabledTitleFontColor;
        end;
      end;

      if AItem.UseDefaultAppearance then
      begin
        ithat := ItemsAppearance.TitleHorizontalTextAlign;
        itvat := ItemsAppearance.TitleVerticalTextAlign;
      end
      else
      begin
        ithat := AItem.TitleHorizontalTextAlign;
        itvat := AItem.TitleVerticalTextAlign;
      end;

      b := True;
      txtrt := txtr;
      InflateRectEx(txtrt, -2, -2);
      DoBeforeDrawItemTitleText(AGraphics, txtrt, AItem, strt, b);
      if b then
      begin
        if OrientationMode = pomHorizontal then
          AGraphics.DrawText(txtrt, strt, True, ithat, itvat, gttNone, -90)
        else
          AGraphics.DrawText(txtrt, strt, True, ithat, itvat);

        DoAfterDrawItemTitleText(AGraphics, txtrt, AItem, strt);
      end;
    end;

    DoAfterDrawItem(AGraphics, ARect, AItem);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawItem(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem; ACacheIndex: Integer; ACaching: Boolean = False);
var
  str, strt: String;
  b, df: Boolean;
  txtr, txtrt: TRectF;
  th: Double;
  mr: TRectF;
  sr: TRectF;
  sz: Double;
  bm, dfm, d: Boolean;
  itha, itva, ithat, itvat: TTMSFNCGraphicsTextAlign;
  ir: Integer;
begin
  if not Assigned(AItem) then
    Exit;

  if AItem.Enabled then
  begin
    if FSelectedItems.IndexOf(AItem) > -1 then
    begin
      if AItem = FActiveItem then
      begin
        AGraphics.Fill.Assign(ItemsAppearance.ActiveFill);
        AGraphics.Fill.Color := AItem.ActiveColor;
        AGraphics.Stroke.Assign(ItemsAppearance.ActiveStroke);
        AGraphics.Stroke.Color := AItem.ActiveStrokeColor;
      end
      else
      begin
        AGraphics.Fill.Assign(ItemsAppearance.SelectedFill);
        AGraphics.Fill.Color := AItem.SelectedColor;
        AGraphics.Stroke.Assign(ItemsAppearance.SelectedStroke);
        AGraphics.Stroke.Color := AItem.SelectedStrokeColor;
      end;
    end
    else
    begin
      AGraphics.Fill.Assign(ItemsAppearance.Fill);
      AGraphics.Fill.Color := AItem.Color;
      AGraphics.Stroke.Assign(ItemsAppearance.Stroke);
      AGraphics.Stroke.Color := AItem.StrokeColor;
    end;
  end
  else
  begin
    AGraphics.Fill.Assign(ItemsAppearance.DisabledFill);
    AGraphics.Fill.Color := AItem.DisabledColor;
    AGraphics.Stroke.Assign(ItemsAppearance.DisabledStroke);
    AGraphics.Stroke.Color := AItem.DisabledStrokeColor;
  end;

  FixStroke(AGraphics);

  df := True;
  b := True;
  DoBeforeDrawItem(AGraphics, ARect, AItem, b, df);

  if b then
  begin
    if df then
    begin
      if AItem.Rounding > 0 then
      begin
        ir := Min(Trunc(Min((ARect.Right - ARect.Left), (ARect.Bottom - ARect.Top))/2), AItem.Rounding);
        AGraphics.DrawRoundRectangle(Arect, ir, AItem.RoundingCorners, gcrmNone);
      end
      else
        AGraphics.DrawRectangle(ARect, gcrmNone);

      if AItem = FActiveItem then
      begin
        if AItem.Movable and ItemsAppearance.ShowMoveArea and AllowDesktopMove and not Interaction.ReadOnly then
        begin
          AGraphics.Fill.Color := ItemsAppearance.MoveAreaColor;
          AGraphics.Stroke.Color := ItemsAppearance.MoveAreaColor;
          AGraphics.Fill.Kind := gfkSolid;
          AGraphics.Stroke.Kind := gskSolid;
          sz := ItemsAppearance.MoveAreaSize;
          case OrientationMode of
            pomHorizontal:
            begin
              mr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
              ARect.Top := mr.Bottom + 1;
            end;
            pomVertical:
            begin
              mr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
              ARect.Left := mr.Right + 1;
            end;
          end;

          bm := True;
          dfm := True;
          DoBeforeDrawMoveArea(AGraphics, mr, AItem, bm, dfm);
          if bm then
          begin
            if dfm then
              AGraphics.DrawRectangle(mr, gcrmNone);

            DoAfterDrawMoveArea(AGraphics, mr, AItem);
          end;
        end;

        if ACacheIndex = 0 then
        begin
          if AItem.Sizeable and ItemsAppearance.ShowSizeArea and AllowDesktopSize and not Interaction.ReadOnly then
          begin
            AGraphics.Fill.Color := ItemsAppearance.SizeAreaColor;
            AGraphics.Stroke.Color := ItemsAppearance.SizeAreaColor;
            AGraphics.Fill.Kind := gfkSolid;
            AGraphics.Stroke.Kind := gskSolid;
            sz := ItemsAppearance.SizeAreaSize;
            case OrientationMode of
              pomHorizontal:
              begin
                sr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
                ARect.Left := sr.Right + 1;
              end;
              pomVertical:
              begin
                sr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
                ARect.Top := sr.Bottom + 1;
              end;
            end;

            bm := True;
            dfm := True;
            DoBeforeDrawSizeArea(AGraphics, sr, AItem, bm, dfm);
            if bm then
            begin
              if dfm then
                AGraphics.DrawRectangle(sr, gcrmNone);

              DoAfterDrawSizeArea(AGraphics, sr, AItem);
            end;
          end;
        end;

        if ((ACacheIndex = TTMSFNCPlannerItemOpen(AItem).PositionsList.Count - 1) and ACaching)
          or ((ACacheIndex = TTMSFNCPlannerItemOpen(AItem).CacheList.Count - 1) and not ACaching) then
        begin
          if AItem.Sizeable and ItemsAppearance.ShowSizeArea and AllowDesktopSize and not Interaction.ReadOnly then
          begin
            AGraphics.Fill.Color := ItemsAppearance.SizeAreaColor;
            AGraphics.Stroke.Color := ItemsAppearance.SizeAreaColor;
            AGraphics.Fill.Kind := gfkSolid;
            AGraphics.Stroke.Kind := gskSolid;
            sz := ItemsAppearance.SizeAreaSize;
            case OrientationMode of
              pomHorizontal:
              begin
                sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Bottom);
                ARect.Right := sr.Left - 1;
              end;
              pomVertical:
              begin
                sr := RectF(ARect.Left, ARect.Bottom - sz, ARect.Right, ARect.Bottom);
                ARect.Bottom := sr.Top - 1;
              end;
            end;

            bm := True;
            dfm := True;
            DoBeforeDrawSizeArea(AGraphics, sr, AItem, bm, dfm);
            if bm then
            begin
              if dfm then
                AGraphics.DrawRectangle(sr, gcrmNone);

              DoAfterDrawSizeArea(AGraphics, sr, AItem);
            end;
          end;
        end;
      end;
    end;

    strt := AItem.Title;
    DoGetItemTitleText(AItem, pgtmDrawing, strt);
    th := 0;
    if (strt <> '') and AItem.ShowTitle then
    begin
      if AItem.Enabled then
      begin
        if FSelectedItems.IndexOf(AItem) > -1 then
        begin
          if AItem = FActiveItem then
          begin
            AGraphics.Font.Assign(ItemsAppearance.ActiveTitleFont);
            if not AItem.UseDefaultAppearance then
            begin
              if AItem.ActiveTitleFontName <> '' then
                AGraphics.Font.Name := AItem.ActiveTitleFontName;

              {$IFDEF FMXLIB}
              if AItem.ActiveTitleFontSize <>  -1 then
                AGraphics.Font.Size := AItem.ActiveTitleFontSize;
              {$ENDIF}
              {$IFDEF CMNWEBLIB}
              if AItem.ActiveTitleFontSize <>  -1 then
                AGraphics.Font.Size := Round(AItem.ActiveTitleFontSize);
              {$ENDIF}

              AGraphics.Font.Style := AItem.ActiveTitleFontStyle;
            end;
          end
          else
          begin
            AGraphics.Font.Assign(ItemsAppearance.SelectedTitleFont);
            if not AItem.UseDefaultAppearance then
            begin
              if AItem.SelectedTitleFontName <> '' then
                AGraphics.Font.Name := AItem.SelectedTitleFontName;

              {$IFDEF FMXLIB}
              if AItem.SelectedTitleFontSize <>  -1 then
                AGraphics.Font.Size := AItem.SelectedTitleFontSize;
              {$ENDIF}
              {$IFDEF CMNWEBLIB}
              if AItem.SelectedTitleFontSize <>  -1 then
                AGraphics.Font.Size := Round(AItem.SelectedTitleFontSize);
              {$ENDIF}

              AGraphics.Font.Style := AItem.SelectedTitleFontStyle;
            end;
          end;
        end
        else
        begin
          AGraphics.Font.Assign(ItemsAppearance.TitleFont);
          if not AItem.UseDefaultAppearance then
          begin
            if AItem.TitleFontName <> '' then
              AGraphics.Font.Name := AItem.TitleFontName;

            {$IFDEF FMXLIB}
            if AItem.TitleFontSize <>  -1 then
              AGraphics.Font.Size := AItem.TitleFontSize;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            if AItem.TitleFontSize <>  -1 then
              AGraphics.Font.Size := Round(AItem.TitleFontSize);
            {$ENDIF}

            AGraphics.Font.Style := AItem.TitleFontStyle;
          end;
        end;
      end
      else
      begin
        AGraphics.Font.Assign(ItemsAppearance.DisabledTitleFont);
        if not AItem.UseDefaultAppearance then
        begin
          if AItem.DisabledTitleFontName <> '' then
            AGraphics.Font.Name := AItem.DisabledTitleFontName;

          {$IFDEF FMXLIB}
          if AItem.DisabledTitleFontSize <>  -1 then
            AGraphics.Font.Size := AItem.DisabledTitleFontSize;
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          if AItem.DisabledTitleFontSize <>  -1 then
            AGraphics.Font.Size := Round(AItem.DisabledTitleFontSize);
          {$ENDIF}

          AGraphics.Font.Style := AItem.DisabledTitleFontStyle;
        end;
      end;

      th := Min(AGraphics.CalculateTextHeight(strt) + 5, ARect.Bottom - ARect.Top);
    end;

    if AItem.Enabled then
    begin
      if FSelectedItems.IndexOf(AItem) > -1 then
      begin
        if AItem = FActiveItem then
        begin
          AGraphics.Font.Assign(ItemsAppearance.ActiveFont);
          if not AItem.UseDefaultAppearance then
          begin
            if AItem.ActiveFontName <> '' then
              AGraphics.Font.Name := AItem.ActiveFontName;

            {$IFDEF FMXLIB}
            if AItem.ActiveFontSize <>  -1 then
              AGraphics.Font.Size := AItem.ActiveFontSize;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            if AItem.ActiveFontSize <>  -1 then
              AGraphics.Font.Size := Round(AItem.ActiveFontSize);
            {$ENDIF}

            AGraphics.Font.Style := AItem.ActiveFontStyle;
            AGraphics.Font.Color := AItem.ActiveFontColor;
          end;
        end
        else
        begin
          AGraphics.Font.Assign(ItemsAppearance.SelectedFont);
          if not AItem.UseDefaultAppearance then
          begin
            if AItem.SelectedFontName <> '' then
              AGraphics.Font.Name := AItem.SelectedFontName;

            {$IFDEF FMXLIB}
            if AItem.SelectedFontSize <>  -1 then
              AGraphics.Font.Size := AItem.SelectedFontSize;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            if AItem.SelectedFontSize <>  -1 then
              AGraphics.Font.Size := Round(AItem.SelectedFontSize);
            {$ENDIF}

            AGraphics.Font.Style := AItem.SelectedFontStyle;
            AGraphics.Font.Color := AItem.SelectedFontColor;
          end;
        end;
      end
      else
      begin
        AGraphics.Font.Assign(ItemsAppearance.Font);
        if not AItem.UseDefaultAppearance then
        begin
          if AItem.FontName <> '' then
            AGraphics.Font.Name := AItem.FontName;

          {$IFDEF FMXLIB}
          if AItem.FontSize <>  -1 then
            AGraphics.Font.Size := AItem.FontSize;
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          if AItem.FontSize <>  -1 then
            AGraphics.Font.Size := Round(AItem.FontSize);
          {$ENDIF}

          AGraphics.Font.Style := AItem.FontStyle;
          AGraphics.Font.Color := AItem.FontColor;
        end;
      end;
    end
    else
    begin
      AGraphics.Font.Assign(ItemsAppearance.DisabledFont);
      if not AItem.UseDefaultAppearance then
      begin
        if AItem.DisabledFontName <> '' then
          AGraphics.Font.Name := AItem.DisabledFontName;

        {$IFDEF FMXLIB}
        if AItem.DisabledFontSize <>  -1 then
          AGraphics.Font.Size := AItem.DisabledFontSize;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        if AItem.DisabledFontSize <>  -1 then
          AGraphics.Font.Size := Round(AItem.DisabledFontSize);
        {$ENDIF}

        AGraphics.Font.Style := AItem.DisabledFontStyle;
        AGraphics.Font.Color := AItem.DisabledFontColor;
      end;
    end;

    if AItem.UseDefaultAppearance then
    begin
      itha := ItemsAppearance.TextHorizontalTextAlign;
      itva := ItemsAppearance.TextVerticalTextAlign;
    end
    else
    begin
      itha := AItem.TextHorizontalTextAlign;
      itva := AItem.TextVerticalTextAlign;
    end;

    b := True;
    str := AItem.Text;
    DoGetItemText(AItem, pgtmDrawing, str);
    txtr := ARect;

    ChangeRectForMark(AItem, txtr);

    txtr.Top := txtr.Top + th;

    InflateRectEx(txtr, -2, -2);
    DoBeforeDrawItemText(AGraphics, txtr, AItem, str, b);
    if b then
    begin
      if (FInplaceEditorActive and (Interaction.InplaceEditorMode = piemTitle) and (FActiveItem = AItem)) or ((not FInplaceEditorActive) or (FActiveItem <> AItem)) then
        AGraphics.DrawText(txtr, str, True, itha, itva);
      DoAfterDrawItemText(AGraphics, txtr, AItem, str);
    end;

    if (strt <> '') and AItem.ShowTitle then
    begin
      txtr := ARect;

      ChangeRectForMark(AItem, txtr);

      txtr.Bottom := txtr.Top + int(th);

      if AItem.Enabled then
      begin
        if FSelectedItems.IndexOf(AItem) > -1 then
        begin
          if AItem = FActiveItem then
          begin
            AGraphics.Fill.Assign(ItemsAppearance.ActiveTitleFill);
            AGraphics.Fill.Color := AItem.ActiveTitleColor;
            AGraphics.Stroke.Assign(ItemsAppearance.ActiveTitleStroke);
            AGraphics.Stroke.Color := AItem.ActiveTitleStrokeColor;
          end
          else
          begin
            AGraphics.Fill.Assign(ItemsAppearance.SelectedTitleFill);
            AGraphics.Fill.Color := AItem.SelectedTitleColor;
            AGraphics.Stroke.Assign(ItemsAppearance.SelectedTitleStroke);
            AGraphics.Stroke.Color := AItem.SelectedTitleStrokeColor;
          end;
        end
        else
        begin
          AGraphics.Fill.Assign(ItemsAppearance.TitleFill);
          AGraphics.Fill.Color := AItem.TitleColor;
          AGraphics.Stroke.Assign(ItemsAppearance.TitleStroke);
          AGraphics.Stroke.Color := AItem.TitleStrokeColor;
        end;
      end
      else
      begin
        AGraphics.Fill.Assign(ItemsAppearance.DisabledTitleFill);
        AGraphics.Fill.Color := AItem.DisabledTitleColor;
        AGraphics.Stroke.Assign(ItemsAppearance.DisabledTitleStroke);
        AGraphics.Stroke.Color := AItem.DisabledTitleStrokeColor;
      end;

      FixStroke(AGraphics);

      df := True;
      b := True;
      DoBeforeDrawItemTitle(AGraphics, txtr, AItem, strt, b, df);

      if b then
      begin
        if df then
          AGraphics.DrawRectangle(txtr, gcrmNone);

        if AItem.Enabled then
        begin
          if FSelectedItems.IndexOf(AItem) > -1 then
          begin
            if AItem = FActiveItem then
            begin
              AGraphics.Font.Assign(ItemsAppearance.ActiveTitleFont);
              if not AItem.UseDefaultAppearance then
              begin
                if AItem.ActiveTitleFontName <> '' then
                  AGraphics.Font.Name := AItem.ActiveTitleFontName;

                {$IFDEF FMXLIB}
                if AItem.ActiveTitleFontSize <>  -1 then
                  AGraphics.Font.Size := AItem.ActiveTitleFontSize;
                {$ENDIF}
                {$IFDEF CMNWEBLIB}
                if AItem.ActiveTitleFontSize <>  -1 then
                  AGraphics.Font.Size := Round(AItem.ActiveTitleFontSize);
                {$ENDIF}

                AGraphics.Font.Style := AItem.ActiveTitleFontStyle;
                AGraphics.Font.Color := AItem.ActiveTitleFontColor;
              end;
            end
            else
            begin
              AGraphics.Font.Assign(ItemsAppearance.SelectedTitleFont);
              if not AItem.UseDefaultAppearance then
              begin
                if AItem.SelectedTitleFontName <> '' then
                  AGraphics.Font.Name := AItem.SelectedTitleFontName;

                {$IFDEF FMXLIB}
                if AItem.SelectedTitleFontSize <>  -1 then
                  AGraphics.Font.Size := AItem.SelectedTitleFontSize;
                {$ENDIF}
                {$IFDEF CMNWEBLIB}
                if AItem.SelectedTitleFontSize <>  -1 then
                  AGraphics.Font.Size := Round(AItem.SelectedTitleFontSize);
                {$ENDIF}

                AGraphics.Font.Style := AItem.SelectedTitleFontStyle;
                AGraphics.Font.Color := AItem.SelectedTitleFontColor;
              end;
            end;
          end
          else
          begin
            AGraphics.Font.Assign(ItemsAppearance.TitleFont);
            if not AItem.UseDefaultAppearance then
            begin
              if AItem.TitleFontName <> '' then
                AGraphics.Font.Name := AItem.TitleFontName;

              {$IFDEF FMXLIB}
              if AItem.TitleFontSize <>  -1 then
                AGraphics.Font.Size := AItem.TitleFontSize;
              {$ENDIF}
              {$IFDEF CMNWEBLIB}
              if AItem.TitleFontSize <>  -1 then
                AGraphics.Font.Size := Round(AItem.TitleFontSize);
              {$ENDIF}

              AGraphics.Font.Style := AItem.TitleFontStyle;
              AGraphics.Font.Color := AItem.TitleFontColor;
            end;
          end;
        end
        else
        begin
          AGraphics.Font.Assign(ItemsAppearance.DisabledTitleFont);
          if not AItem.UseDefaultAppearance then
          begin
            if AItem.DisabledTitleFontName <> '' then
              AGraphics.Font.Name := AItem.DisabledTitleFontName;

            {$IFDEF FMXLIB}
            if AItem.DisabledTitleFontSize <>  -1 then
              AGraphics.Font.Size := AItem.DisabledTitleFontSize;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            if AItem.DisabledTitleFontSize <>  -1 then
              AGraphics.Font.Size := Round(AItem.DisabledTitleFontSize);
            {$ENDIF}

            AGraphics.Font.Style := AItem.DisabledTitleFontStyle;
            AGraphics.Font.Color := AItem.DisabledTitleFontColor;
          end;
        end;

        if AItem.UseDefaultAppearance then
        begin
          ithat := ItemsAppearance.TitleHorizontalTextAlign;
          itvat := ItemsAppearance.TitleVerticalTextAlign;
        end
        else
        begin
          ithat := AItem.TitleHorizontalTextAlign;
          itvat := AItem.TitleVerticalTextAlign;
        end;

        b := True;
        txtrt := txtr;
        InflateRectEx(txtrt, -2, -2);
        DoBeforeDrawItemTitleText(AGraphics, txtrt, AItem, strt, b);
        if b then
        begin
          if (FInplaceEditorActive and (Interaction.InplaceEditorMode = piemText) and (FActiveItem = AItem)) or ((not FInplaceEditorActive) or (FActiveItem <> AItem)) then
            AGraphics.DrawText(txtrt, strt, False, ithat, itvat);
          DoAfterDrawItemTitleText(AGraphics, txtrt, AItem, strt);
        end;

        DoAfterDrawItemTitle(AGraphics, txtr, AItem, strt);
      end;
    end;

    if ACacheIndex = 0 then
    begin
      d := AItem.Deletable;
      DoIsItemDeletable(AItem, d);
      if d and ItemsAppearance.ShowDeleteArea and AllowDesktopDelete and not Interaction.ReadOnly then
      begin
        if AItem = FActiveItem then
          AGraphics.Stroke.Color := gcWhite
        else
          AGraphics.Stroke.Color := ItemsAppearance.DeleteAreaColor;

        AGraphics.Stroke.Width := 2;
        AGraphics.Stroke.Kind := gskSolid;
        sz := ItemsAppearance.DeleteAreaSize;
        case OrientationMode of
          pomHorizontal:
          begin
            sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Top + sz);
          end;
          pomVertical:
          begin
            sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Top + sz);
          end;
        end;

        bm := True;
        dfm := True;
        DoBeforeDrawDeleteArea(AGraphics, sr, AItem, bm, dfm);
        if bm then
        begin
          if dfm then
          begin
            InflateRectEx(sr, -3, -3);
            AGraphics.DrawLine(PointF(sr.Left, sr.Top), PointF(sr.Right, sr.Bottom), gcpmNone);
            AGraphics.DrawLine(PointF(sr.Right, sr.Top), PointF(sr.Left, sr.Bottom), gcpmNone);
          end;
          DoAfterDrawDeleteArea(AGraphics, sr, AItem);
        end;
      end;
    end;

    DrawItemMarks(AGraphics, ARect, AItem);
    DoAfterDrawItem(AGraphics, ARect, AItem);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawItemMarks(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCPlannerItem);
var
  r: TRectF;
  c: TTMSFNCGraphicsColor;
  mr: Integer;
begin
  c := AItem.MarkColor;

  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Stroke.Color := c;
  AGraphics.Fill.Color :=  c;

  if (imtLeft in AItem.MarkType) and (AItem.MarkSizeLeft > 0) then
  begin
    if AItem.MarkColorLeft <> gcNull then
    begin
      AGraphics.Stroke.Color := AItem.MarkColorLeft;
      AGraphics.Fill.Color :=  AItem.MarkColorLeft;
    end;

    r := RectF(ARect.Left, ARect.Top, ARect.Left + AItem.MarkSizeLeft, ARect.Bottom);
    if Assigned(OnItemCustomDrawMark) then
      DoItemCustomDrawMark(AGraphics, r, imtLeft, AItem)
    else
    begin
      mr := Min(Trunc(AItem.MarkSizeLeft / 2), AItem.MarkRounding);
      AGraphics.DrawRoundRectangle(r, mr, AItem.MarkCorners, gcrmNone);
    end;

    if AItem.MarkColorLeft <> gcNull then
    begin
      AGraphics.Stroke.Color := c;
      AGraphics.Fill.Color := c;
    end;
  end;

  if (imtTop in AItem.MarkType) and (AItem.MarkSizeTop > 0)  then
  begin
    if AItem.MarkColorTop <> gcNull then
    begin
      AGraphics.Stroke.Color := AItem.MarkColorTop;
      AGraphics.Fill.Color :=  AItem.MarkColorTop;
    end;

    r := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + AItem.MarkSizeTop);
    if Assigned(OnItemCustomDrawMark) then
      DoItemCustomDrawMark(AGraphics, r, imtTop, AItem)
    else
    begin
      mr := Min(Trunc(AItem.MarkSizeTop / 2), AItem.MarkRounding);
      AGraphics.DrawRoundRectangle(r, mr, AItem.MarkCorners, gcrmNone);
    end;

    if AItem.MarkColorTop <> gcNull then
    begin
      AGraphics.Stroke.Color := c;
      AGraphics.Fill.Color := c;
    end;
  end;

  if (imtRight in AItem.MarkType) and (AItem.MarkSizeRight > 0)  then
  begin
    if AItem.MarkColorRight <> gcNull then
    begin
      AGraphics.Stroke.Color := AItem.MarkColorRight;
      AGraphics.Fill.Color :=  AItem.MarkColorRight;
    end;
    r := RectF(ARect.Right - AItem.MarkSizeRight, ARect.Top, ARect.Right, ARect.Bottom);
    if Assigned(OnItemCustomDrawMark) then
      DoItemCustomDrawMark(AGraphics, r, imtRight, AItem)
    else
    begin
      mr := Min(Trunc(AItem.MarkSizeRight / 2), AItem.MarkRounding);
      AGraphics.DrawRoundRectangle(r, mr, AItem.MarkCorners, gcrmNone);
    end;

    if AItem.MarkColorRight <> gcNull then
    begin
      AGraphics.Stroke.Color := c;
      AGraphics.Fill.Color := c;
    end;
  end;

  if (imtBottom in AItem.MarkType) and (AItem.MarkSizeBottom > 0)  then
  begin
    if AItem.MarkColorBottom <> gcNull then
    begin
      AGraphics.Stroke.Color := AItem.MarkColorBottom;
      AGraphics.Fill.Color :=  AItem.MarkColorBottom;
    end;

    r := RectF(ARect.Left, ARect.Bottom - AItem.MarkSizeBottom, ARect.Right, ARect.Bottom);
    if Assigned(OnItemCustomDrawMark) then
      DoItemCustomDrawMark(AGraphics, r, imtBottom, AItem)
    else
    begin
      mr := Min(Trunc(AItem.MarkSizeBottom / 2), AItem.MarkRounding);
      AGraphics.DrawRoundRectangle(r, mr, AItem.MarkCorners, gcrmNone);
    end;

    if AItem.MarkColorBottom <> gcNull then
    begin
      AGraphics.Stroke.Color := c;
      AGraphics.Fill.Color := c;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawItemHelpers(AGraphics: TTMSFNCGraphics);
var
  it: TTMSFNCPlannerItem;
  drs: TRectF;
  cr: TRectF;
  txt: String;
  tw, th: Double;
  ctr: TRectF;
  y: Single;
  st: TTMSFNCGraphicsSaveState;
  dt: TDateTime;
  b, df: Boolean;
  sz: TSizeF;
begin
  if Assigned(FActiveItem) and FDrawItemHelpers and ItemsAppearance.ShowItemHelpers then
  begin
    it := FActiveItem;
    ctr := GetContentClipRect;
    st := AGraphics.SaveState;
    AGraphics.Fill.Kind := gfkSolid;
    AGraphics.Stroke.Kind := gskSolid;
    AGraphics.Fill.Color := gcWhitesmoke;
    AGraphics.Stroke.Color := gcDarkGray;
    AGraphics.Font.Color := gcDarkGray;

    AGraphics.ClipRect(ctr);
    //top time
    cr := GetFirstRect(it);
    if IntersectRectEx(cr, ctr) then
    begin
      dt := it.StartTime;
      txt := FormatDateTime('hh:nn', dt);
      DoGetItemHelperText(it, true, dt, txt);
      sz := AGraphics.CalculateTextSize(txt);
      tw := sz.cx + 5;
      th := sz.cy + 5;

      case OrientationMode of
        pomHorizontal:
        begin
          drs := RectF(cr.Left, cr.Bottom + 10, cr.Left + tw, cr.Bottom + 10 + th);
          if drs.Bottom > ctr.Bottom then
          begin
            y := drs.Bottom - drs.Top;
            drs.Top := cr.Top - 10 - y;
            drs.Bottom := cr.Top - 10;
          end;
        end;
        pomVertical:
        begin
          drs := RectF(cr.Right + 10, cr.Top, cr.Right + 10 + tw, cr.Top + th);
          if drs.Right > ctr.Right then
          begin
            y := drs.Right - drs.Left;
            drs.Left := cr.Left - 10 - y;
            drs.Right := cr.Left - 10;
          end;
        end;
      end;

      b := True;
      df := True;
      DoBeforeDrawItemHelper(AGraphics, drs, it, True, dt, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(drs, gcrmShiftRightDown);

        b := True;
        DoBeforeDrawItemHelperText(AGraphics, drs, it, true, dt, txt, b);
        if b then
        begin
          AGraphics.DrawText(drs, txt, False, gtaCenter, gtaCenter);
          DoAfterDrawItemHelperText(AGraphics, drs, it, true, dt, txt);
        end;

        DoAfterDrawItemHelper(AGraphics, drs, it, True, dt);
      end;
    end;

    AGraphics.Fill.Color := gcNull;
    AGraphics.Fill.Color := gcWhitesmoke;

    //bottom time
    cr := GetLastRect(it);
    if IntersectRectEx(cr, ctr) then
    begin
      dt := it.EndTime;
      txt := FormatDateTime('hh:nn', dt);
      DoGetItemHelperText(it, False, dt, txt);
      sz := AGraphics.CalculateTextSize(txt);
      tw := sz.cx + 5;
      th := sz.cy + 5;

      case OrientationMode of
        pomHorizontal:
        begin
          drs := RectF(cr.Right - tw, cr.Bottom + 10, cr.Right, cr.Bottom + 10 + th);
          if drs.Bottom > ctr.Bottom then
          begin
            y := drs.Bottom - drs.Top;
            drs.Top := cr.Top - 10 - y;
            drs.Bottom := cr.Top - 10;
          end;
        end;
        pomVertical:
        begin
          drs := RectF(cr.Right + 10, cr.Bottom - th, cr.Right + 10 + tw, cr.Bottom);
          if drs.Right > ctr.Right then
          begin
            y := drs.Right - drs.Left;
            drs.Left := cr.Left - 10 - y;
            drs.Right := cr.Left - 10;
          end;
        end;
      end;

      b := True;
      df := True;
      DoBeforeDrawItemHelper(AGraphics, drs, it, False, dt, b, df);
      if b then
      begin
        if df then
          AGraphics.DrawRectangle(drs, gcrmShiftRightDown);

        b := True;
        DoBeforeDrawItemHelperText(AGraphics, drs, it, False, dt, txt, b);
        if b then
        begin
          AGraphics.DrawText(drs, txt, False, gtaCenter, gtaCenter);
          DoAfterDrawItemHelperText(AGraphics, drs, it, False, dt, txt);
        end;
        DoAfterDrawItemHelper(AGraphics, drs, it, False, dt);
      end;
    end;

    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawItemLinks(AGraphics: TTMSFNCGraphics);
var
  i: Integer;
  m: Double;
  LR1F, LR1T: TRectF;
  AItem: TTMSFNCPlannerItem;
  pol: TTMSFNCGraphicsPathPolygon;
  lasz: Double;
  las: TTMSFNCPlannerLinkArrowShape;
  stk: TTMSFNCGraphicsStrokeKind;
  b: Boolean;
begin
  if ItemsAppearance.ShowLinks then
  begin
    AGraphics.Stroke.Assign(ItemsAppearance.LinkStroke);
    lasz := ItemsAppearance.LinkArrowSize;
    las := ItemsAppearance.LinkArrowShape;
    SetLength(pol, 4);
    for i := 0 to FItemDisplay.Count - 1 do
    begin
      AItem := FItemDisplay[I].Item;
      if Assigned(AItem) and Assigned(AItem.LinkedItem) and IsValidItem(AItem.LinkedItem) and not IsFullDayItem(AItem.LinkedItem) then
      begin
        LR1F := AItem.GetLastRect;
        LR1T := AItem.LinkedItem.GetFirstRect;

        if (FSelectedItems.IndexOf(AItem) > -1) and (AItem.SelectedLinkColor <> gcNull) then
          AGraphics.Stroke.Color := AItem.SelectedLinkColor
        else if (AItem.LinkColor <> gcNull) then
          AGraphics.Stroke.Color := AItem.LinkColor;

        AGraphics.Fill.Kind := gfkSolid;
        AGraphics.Fill.Color := AGraphics.Stroke.Color;

        if (lr1f.Top = 0) and (lr1t.Top = 0) then
          Continue;

        if (lr1f.Left = 0) and (lr1t.Left = 0) then
          Continue;

        if (LR1F.Right - LR1F.Left = 0) or (LR1F.Bottom - LR1F.Top = 0) or (LR1T.Right - LR1T.Left = 0) or (LR1T.Bottom - LR1T.Top = 0) then
          Continue;


        b := True;
        DoBeforeDrawItemLink(AGraphics, AItem, AItem.LinkedItem, b);
        if b then
        begin
          if (LR1F.Right < LR1T.Left) then
          begin
            m := LR1F.Right + (LR1T.Left - LR1F.Right) / 2;

            AGraphics.DrawLine(PointF(m, Min(LR1F.Bottom, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2)), PointF(m, Min(LR1T.Bottom, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2)));

            if LR1F.Bottom > 0 then
            begin
              AGraphics.DrawLine(PointF(LR1F.Right, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2), PointF(m, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2));

              if (AItem.LinkArrow in [ilaToFrom, ilaBoth]) then
              begin
                if ItemsAppearance.LinkArrowShape = lasNormal then
                begin
                  AGraphics.DrawLine(PointF(LR1F.Right, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2), PointF(LR1F.Right + lasz, lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2));
                  AGraphics.DrawLine(PointF(LR1F.Right, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2), PointF(LR1F.Right + lasz, -lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2));
                end;

                if las = lasFilled then
                begin
                  pol[0].X := LR1F.Right;
                  pol[0].Y := LR1F.Top + (LR1F.Bottom - LR1F.Top) / 2;
                  pol[1].X := LR1F.Right + lasz;
                  pol[1].Y := lasz + LR1F.Top + (LR1F.Bottom - LR1F.Top) / 2;
                  pol[2].X := LR1F.Right + lasz;
                  pol[2].Y := -lasz + LR1F.Top + (LR1F.Bottom - LR1F.Top) / 2;
                  pol[3].X := pol[0].X;
                  pol[3].Y := pol[0].Y;
                  stk := AGraphics.Stroke.Kind;
                  AGraphics.Stroke.Kind := gskNone;
                  AGraphics.DrawPolygon(pol);
                  AGraphics.Stroke.Kind := stk;
                end;
              end;
            end;

            if LR1T.Bottom > 0 then
            begin
              AGraphics.DrawLine(PointF(LR1T.Left, Min(LR1T.Bottom, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2)), PointF(m, Min(LR1T.Bottom, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2)));

              if (AItem.LinkArrow in [ilaFromTo, ilaBoth]) then
              begin
                if las = lasNormal then
                begin
                  AGraphics.DrawLine(PointF(LR1T.Left, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2), PointF(LR1T.Left - lasz, lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2));
                  AGraphics.DrawLine(PointF(LR1T.Left, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2), PointF(LR1T.Left - lasz, -lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2));
                end;

                if las = lasFilled then
                begin
                  pol[0].X := LR1T.Left;
                  pol[0].Y := LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[1].X := LR1T.Left - lasz;
                  pol[1].Y := lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[2].X := LR1T.Left - lasz;
                  pol[2].Y := -lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[3].X := pol[0].X;
                  pol[3].Y := pol[0].Y;
                  stk := AGraphics.Stroke.Kind;
                  AGraphics.Stroke.Kind := gskNone;
                  AGraphics.DrawPolygon(pol);
                  AGraphics.Stroke.Kind := stk;
                end;
              end;
            end;
          end
          else
          if (LR1F.Left > LR1T.Right) then
          begin
            m := LR1F.Left - (LR1F.Left - LR1T.Right) / 2;

            AGraphics.DrawLine(PointF(m, Min(LR1F.Bottom, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2)), PointF(m, Min(LR1T.Bottom, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2)));

            if LR1F.Bottom > 0 then
            begin
              AGraphics.DrawLine(PointF(LR1F.Left, Min(LR1F.Bottom, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2)), PointF(m, Min(LR1F.Bottom, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2)));

              if (AItem.LinkArrow in [ilaToFrom, ilaBoth]) then
              begin
                if las = lasNormal then
                begin
                  AGraphics.DrawLine(PointF(LR1F.Left, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2), PointF(LR1F.Left - lasz, lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2));
                  AGraphics.DrawLine(PointF(LR1F.Left, LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2), PointF(LR1F.Left - lasz, -lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2));
                end;

                if las = lasFilled then
                begin
                  pol[0].X := LR1F.Left;
                  pol[0].Y := LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2;
                  pol[1].X := LR1F.Left - lasz;
                  pol[1].Y := lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2;
                  pol[2].X := LR1F.Left - lasz;
                  pol[2].Y := -lasz + LR1F.Top + (LR1F.Bottom -  LR1F.Top) / 2;
                  pol[3].X := pol[0].X;
                  pol[3].Y := pol[0].Y;
                  stk := AGraphics.Stroke.Kind;
                  AGraphics.Stroke.Kind := gskNone;
                  AGraphics.DrawPolygon(pol);
                  AGraphics.Stroke.Kind := stk;
                end;
              end;
            end;

            if LR1T.Bottom > 0 then
            begin
              AGraphics.DrawLine(PointF(LR1T.Right, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2), PointF(m, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2));
              if (AItem.LinkArrow in [ilaFromTo, ilaBoth]) then
              begin
                if las = lasNormal then
                begin
                  AGraphics.DrawLine(PointF(LR1T.Right, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2), PointF(LR1T.Right + lasz, lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2));
                  AGraphics.DrawLine(PointF(LR1T.Right, LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2), PointF(LR1T.Right + lasz, -lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2));
                end;

                if las = lasFilled then
                begin
                  pol[0].X := LR1T.Right;
                  pol[0].Y := LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[1].X := LR1T.Right + lasz;
                  pol[1].Y := lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[2].X := LR1T.Right + lasz;
                  pol[2].Y := -lasz + LR1T.Top + (LR1T.Bottom -  LR1T.Top) / 2;
                  pol[3].X := pol[0].X;
                  pol[3].Y := pol[0].Y;
                  stk := AGraphics.Stroke.Kind;
                  AGraphics.Stroke.Kind := gskNone;
                  AGraphics.DrawPolygon(pol);
                  AGraphics.Stroke.Kind := stk;
                end;
              end;
            end;
          end
          else
          if (LR1F.Bottom < LR1T.Top) then
          begin
            m := LR1F.Bottom + (LR1T.Top - LR1F.Bottom) / 2;

            AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, m), PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, m));
            AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, m), PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Bottom));

            if (AItem.LinkArrow in [ilaToFrom, ilaBoth]) then
            begin
              if las = lasNormal then
              begin
                AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Bottom), PointF(LR1F.Left + lasz + (LR1F.Right - LR1F.Left) / 2, LR1F.Bottom + lasz));
                AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Bottom), PointF(LR1F.Left - lasz + (LR1F.Right - LR1F.Left) / 2, LR1F.Bottom + lasz));
              end;

              if las = lasFilled then
              begin
                pol[0].X := LR1F.Left + (LR1F.Right - LR1F.Left) / 2;
                pol[0].Y := LR1F.Bottom;
                pol[1].X := LR1F.Left + lasz + (LR1F.Right - LR1F.Left) / 2;
                pol[1].Y := LR1F.Bottom + lasz;
                pol[2].X := LR1F.Left - lasz + (LR1F.Right - LR1F.Left) / 2;
                pol[2].Y := LR1F.Bottom + lasz;
                pol[3].X := pol[0].X;
                pol[3].Y := pol[0].Y;
                stk := AGraphics.Stroke.Kind;
                AGraphics.Stroke.Kind := gskNone;
                AGraphics.DrawPolygon(pol);
                AGraphics.Stroke.Kind := stk;
              end;
            end;

            AGraphics.DrawLine(PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, m), PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, LR1T.Top));
            if (AItem.LinkArrow in [ilaFromTo, ilaBoth]) then
            begin
              if las = lasNormal then
              begin
                AGraphics.DrawLine(PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, LR1T.Top), PointF(LR1T.Left + lasz + (LR1T.Right - LR1T.Left) / 2, LR1T.Top - lasz));
                AGraphics.DrawLine(PointF(LR1T.Left  + (LR1T.Right - LR1T.Left) / 2, LR1T.Top), PointF(LR1T.Left - lasz + (LR1T.Right - LR1T.Left) / 2, LR1T.Top - lasz));
              end;

              if las = lasFilled then
              begin
                pol[0].X := LR1T.Left + (LR1T.Right - LR1T.Left) / 2;
                pol[0].Y := LR1T.Top;
                pol[1].X := LR1T.Left + lasz + (LR1T.Right - LR1T.Left) / 2;
                pol[1].Y := LR1T.Top - lasz;
                pol[2].X := LR1T.Left - lasz + (LR1T.Right - LR1T.Left) / 2;
                pol[2].Y := LR1T.Top - lasz;
                pol[3].X := pol[0].X;
                pol[3].Y := pol[0].Y;
                stk := AGraphics.Stroke.Kind;
                AGraphics.Stroke.Kind := gskNone;
                AGraphics.DrawPolygon(pol);
                AGraphics.Stroke.Kind := stk;
              end;
            end;
          end
          else
          if (LR1F.Top > LR1T.Bottom) then
          begin
            m := LR1T.Bottom  + (LR1F.Top - LR1T.Bottom) / 2;

            AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, m), PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, m));
            AGraphics.DrawLine(PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, m), PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, LR1T.Bottom));

            if (AItem.LinkArrow in [ilaFromTo, ilaBoth]) then
            begin
              if las = lasNormal then
              begin
                AGraphics.DrawLine(PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, LR1T.Bottom), PointF(LR1T.Left + lasz + (LR1T.Right - LR1T.Left) / 2, LR1T.Bottom + lasz));
                AGraphics.DrawLine(PointF(LR1T.Left + (LR1T.Right - LR1T.Left) / 2, LR1T.Bottom), PointF(LR1T.Left - lasz + (LR1T.Right - LR1T.Left) / 2, LR1T.Bottom + lasz));
              end;

              if las = lasFilled then
              begin
                pol[0].X := LR1T.Left + (LR1T.Right - LR1T.Left) / 2;
                pol[0].Y := LR1T.Bottom;
                pol[1].X := LR1T.Left + lasz + (LR1T.Right - LR1T.Left) / 2;
                pol[1].Y := LR1T.Bottom + lasz;
                pol[2].X := LR1T.Left - lasz + (LR1T.Right - LR1T.Left) / 2;
                pol[2].Y := LR1T.Bottom + lasz;
                pol[3].X := pol[0].X;
                pol[3].Y := pol[0].Y;
                stk := AGraphics.Stroke.Kind;
                AGraphics.Stroke.Kind := gskNone;
                AGraphics.DrawPolygon(pol);
                AGraphics.Stroke.Kind := stk;
              end;
            end;

            AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, m), PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Top));
            if (AItem.LinkArrow in [ilaToFrom, ilaBoth]) then
            begin
              if las = lasNormal then
              begin
                AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Top), PointF(LR1F.Left + lasz + (LR1F.Right - LR1F.Left) / 2, LR1F.Top - lasz));
                AGraphics.DrawLine(PointF(LR1F.Left + (LR1F.Right - LR1F.Left) / 2, LR1F.Top), PointF(LR1F.Left - lasz + (LR1F.Right - LR1F.Left) / 2, LR1F.Top - lasz));
              end;

              if las = lasFilled then
              begin
                pol[0].X := LR1F.Left + (LR1F.Right - LR1F.Left) / 2;
                pol[0].Y := LR1F.Top;
                pol[1].X := LR1F.Left + lasz + (LR1F.Right - LR1F.Left) / 2;
                pol[1].Y := LR1F.Top - lasz;
                pol[2].X := LR1F.Left - lasz + (LR1F.Right - LR1F.Left) / 2;
                pol[2].Y := LR1F.Top - lasz;
                pol[3].X := pol[0].X;
                pol[3].Y := pol[0].Y;
                stk := AGraphics.Stroke.Kind;
                AGraphics.Stroke.Kind := gskNone;
                AGraphics.DrawPolygon(pol);
                AGraphics.Stroke.Kind := stk;
              end;
            end;

            DoAfterDrawItemLink(AGraphics, AItem, AItem.LinkedItem);
          end;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawFullDaysItems(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FFullDaysItemTopDisplay);
  DrawDisplay(AGraphics, FFullDaysItemBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.DrawItems(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FItemDisplay);
  DrawItemHelpers(AGraphics);
end;

procedure TTMSFNCCustomPlanner.DrawNavigationButtons(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  b, df: Boolean;
  rm: TTMSFNCGraphicsModifyRectMode;
begin
  if (pnbPrevious in Interaction.TopNavigationButtons) and (pplTop in PositionsAppearance.Layouts) and (PositionsAppearance.TopSize > 0) then
  begin
    r := GetTopLeftNavigationButtonRect;
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        r.Right := r.Right + 1;
        r.Bottom := r.Bottom + 1;
      end;
      pomVertical:
      begin
        r.Right := r.Right + 1;
        r.Bottom := r.Bottom + 1;
      end;
    end;
    {$ENDIF}
    rm := gcrmShiftRightDown;

    case FTopLeftNavigationButtonState of
      pnbsNormal:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonFill);
      end;
      pnbsDown:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonDownStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonDownFill);
      end;
      pnbsHover:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonHoverStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonHoverFill);
      end;
    end;

    b := True;
    df := True;
    DoBeforeDrawTopNavigationButton(AGraphics, r, pnbPrevious, FTopLeftNavigationButtonState, b, df);
    if b then
    begin
      if df then
      begin
        AGraphics.DrawRectangle(r, rm);

        case OrientationMode of
          pomHorizontal: DrawArrow(AGraphics, r, pakUp);
          pomVertical: DrawArrow(AGraphics, r, pakLeft);
        end;
      end;

      DoAfterDrawTopNavigationButton(AGraphics, r, pnbPrevious, FTopLeftNavigationButtonState);
    end;
  end;

  if (pnbNext in Interaction.TopNavigationButtons) and (pplTop in PositionsAppearance.Layouts) and (PositionsAppearance.TopSize > 0) then
  begin
    r := GetTopRightNavigationButtonRect;
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        r.Right := r.Right + 1;
        r.Top := r.Top - 1;
        r.Bottom := r.Bottom + 1;
      end;
      pomVertical:
      begin
        r.Right := r.Right + 1;
        r.Bottom := r.Bottom + 1;
      end;
    end;
    {$ENDIF}
    case OrientationMode of
      pomHorizontal: rm := gcrmShiftRightAndExpandHeight;
      pomVertical: rm := gcrmShiftDownAndExpandWidth;
      else
        rm := gcrmNone;
    end;

    case FTopRightNavigationButtonState of
      pnbsNormal:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonFill);
      end;
      pnbsDown:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonDownStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonDownFill);
      end;
      pnbsHover:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.TopNavigationButtonHoverStroke);
        AGraphics.Fill.Assign(PositionsAppearance.TopNavigationButtonHoverFill);
      end;
    end;

    b := True;
    df := True;
    DoBeforeDrawTopNavigationButton(AGraphics, r, pnbNext, FTopRightNavigationButtonState, b, df);
    if b then
    begin
      if df then
      begin
        AGraphics.DrawRectangle(r, rm);

        case OrientationMode of
          pomHorizontal: DrawArrow(AGraphics, r, pakDown);
          pomVertical: DrawArrow(AGraphics, r, pakRight);
        end;
      end;
      DoAfterDrawTopNavigationButton(AGraphics, r, pnbNext, FTopRightNavigationButtonState);
    end;
  end;

  if (pnbPrevious in Interaction.BottomNavigationButtons) and (pplBottom in PositionsAppearance.Layouts) and (PositionsAppearance.BottomSize > 0) then
  begin
    r := GetBottomLeftNavigationButtonRect;
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        r.Left := r.Left - 1;
        r.Bottom := r.Bottom + 1;
      end;
      pomVertical:
      begin
        r.Right := r.Right + 1;
        r.Top := r.Top - 1;
      end;
    end;
    {$ENDIF}
    case OrientationMode of
      pomHorizontal: rm := gcrmShiftLeftDown;
      pomVertical: rm := gcrmShiftRightUp;
      else
        rm := gcrmNone;
    end;

    case FBottomLeftNavigationButtonState of
      pnbsNormal:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonFill);
      end;
      pnbsDown:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonDownStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonDownFill);
      end;
      pnbsHover:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonHoverStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonHoverFill);
      end;
    end;

    b := True;
    df := True;
    DoBeforeDrawBottomNavigationButton(AGraphics, r, pnbPrevious, FBottomLeftNavigationButtonState, b, df);
    if b then
    begin
      if df then
      begin
        AGraphics.DrawRectangle(r, rm);

        case OrientationMode of
          pomHorizontal: DrawArrow(AGraphics, r, pakUp);
          pomVertical: DrawArrow(AGraphics, r, pakLeft);
        end;
      end;
      DoAfterDrawBottomNavigationButton(AGraphics, r, pnbPrevious, FBottomLeftNavigationButtonState);
    end;
  end;

  if (pnbNext in Interaction.BottomNavigationButtons) and (pplBottom in PositionsAppearance.Layouts) and (PositionsAppearance.BottomSize > 0) then
  begin
    r := GetBottomRightNavigationButtonRect;
    {$IFDEF CMNLIB}
    case OrientationMode of
      pomHorizontal:
      begin
        r.Left := r.Left - 1;
        r.Top := r.Top - 1;
        r.Bottom := r.Bottom + 1;
      end;
      pomVertical:
      begin
        r.Right := r.Right + 1;
        r.Top := r.Top - 1;
      end;
    end;
    {$ENDIF}
    case OrientationMode of
      pomHorizontal: rm := gcrmShiftLeftAndExpandHeight;
      pomVertical: rm := gcrmShiftUpAndExpandWidth;
      else
        rm := gcrmNone;
    end;

    case FBottomRightNavigationButtonState of
      pnbsNormal:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonFill);
      end;
      pnbsDown:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonDownStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonDownFill);
      end;
      pnbsHover:
      begin
        AGraphics.Stroke.Assign(PositionsAppearance.BottomNavigationButtonHoverStroke);
        AGraphics.Fill.Assign(PositionsAppearance.BottomNavigationButtonHoverFill);
      end;
    end;

    b := True;
    df := True;
    DoBeforeDrawBottomNavigationButton(AGraphics, r, pnbNext, FBottomRightNavigationButtonState, b, df);
    if b then
    begin
      if df then
      begin
        AGraphics.DrawRectangle(r, rm);

        case OrientationMode of
          pomHorizontal: DrawArrow(AGraphics, r, pakDown);
          pomVertical: DrawArrow(AGraphics, r, pakRight);
        end;
      end;
      DoAfterDrawBottomNavigationButton(AGraphics, r, pnbNext, FBottomRightNavigationButtonState);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawCell(AGraphics: TTMSFNCGraphics; ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
var
  b: Boolean;
  df, ds, ac, isSub: Boolean;
begin
  b := True;
  df := True;

  ds := IsDateTimeDisabled(AStartTime, APosition);
  if ds then
    AGraphics.Fill.Assign(GridCellAppearance.DisabledFill)
  else
  begin
    ac := IsDateTimeInactive(AStartTime, APosition);
    if ac then
      AGraphics.Fill.Assign(GridCellAppearance.InActiveFill)
    else
      AGraphics.Fill.Assign(GridCellAppearance.Fill);
  end;

  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.Stroke.Color := AGraphics.Fill.Color;

  DoBeforeDrawCell(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind, b, df);
  if b then
  begin
    if df then
    begin
      AGraphics.DrawRectangle(ARect, gcrmNone);
    end;

    case OrientationMode of
      pomHorizontal:
      begin
        b := True;
        df := True;

        isSub := HasDateTimeSub and IsDateTimeSub(AStartTime);
        if isSub then
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalSubStroke)
        else
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalStroke);

        DoBeforeDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AStartTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Bottom), gcpmNone);

          DoAfterDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AEndTime, APosition, AKind);
        end;

        isSub := HasDateTimeSub and IsDateTimeSub(AEndTime);
        if isSub then
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalSubStroke)
        else
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalStroke);

        b := True;
        df := True;
        DoBeforeDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AStartTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawLine(PointF(ARect.Right, ARect.Top), PointF(ARect.Right, ARect.Bottom), gcpmNone);

          DoAfterDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AStartTime, APosition, AKind);
        end;

        b := True;
        df := True;

        AGraphics.Stroke.Assign(GridCellAppearance.VerticalStroke);

        DoBeforeDrawCellVerticalLine(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Right, ARect.Top), gcpmNone);
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Bottom), PointF(ARect.Right, ARect.Bottom), gcpmNone);
          end;
          DoAfterDrawCellVerticalLine(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind);
        end;
      end;
      pomVertical:
      begin
        b := True;
        df := True;

        AGraphics.Stroke.Assign(GridCellAppearance.VerticalStroke);

        DoBeforeDrawCellVerticalLine(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Bottom), gcpmNone);
            AGraphics.DrawLine(PointF(ARect.Right, ARect.Top), PointF(ARect.Right, ARect.Bottom), gcpmNone);
          end;
          DoAfterDrawCellVerticalLine(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind);
        end;

        b := True;
        df := True;

        isSub := HasDateTimeSub and IsDateTimeSub(AStartTime);
        if isSub then
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalSubStroke)
        else
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalStroke);

        DoBeforeDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AStartTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Right, ARect.Top), gcpmNone);
          DoAfterDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AStartTime, APosition, AKind);
        end;

        isSub := HasDateTimeSub and IsDateTimeSub(AEndTime);
        if isSub then
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalSubStroke)
        else
          AGraphics.Stroke.Assign(GridCellAppearance.HorizontalStroke);

        b := True;
        df := True;
        DoBeforeDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AEndTime, APosition, AKind, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawLine(PointF(ARect.Left, ARect.Bottom), PointF(ARect.Right, ARect.Bottom), gcpmNone);
          DoAfterDrawCellHorizontalLine(AGraphics, ARect, isSub, ACol, ARow, AEndTime, APosition, AKind);
        end;
      end;
    end;

    DoAfterDrawCell(AGraphics, ARect, ACol, ARow, AStartTime, AEndTime, APosition, AKind);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawCurrentTimeInGrid(AGraphics: TTMSFNCGraphics);
var
  st: TTMSFNCGraphicsSaveState;
  dt: TDateTime;
  v: Double;
  cr, crt: TRectF;
  b, df: Boolean;
begin
  if TimeLine.CurrentTimeMode = pctmLine then
  begin
    dt := CurrentTime;
    if DateTimeInRangeEx(dt, FDisplayStartTime, FDisplayEndTime, True) then
    begin
      st := AGraphics.SaveState;
      cr := GetContentClipRect;
      AGraphics.ClipRect(cr);
      begin
        AGraphics.Fill.Color := TimeLineAppearance.CurrentTimeColor;
        AGraphics.Stroke.Color := TimeLineAppearance.CurrentTimeColor;
        AGraphics.Fill.Kind := gfkSolid;
        AGraphics.Stroke.Kind := gskSolid;

        b := True;
        df := True;
        crt := GetContentRect;

        case OrientationMode of
          pomHorizontal: v := DateTimeToValue(dt, True) - GetHorizontalScrollPosition + crt.Left;
          pomVertical: v := DateTimeToValue(dt, True) - GetVerticalScrollPosition + crt.Top;
          else
            v := 0;
        end;

        DoBeforeDrawCurrentTimeInGrid(AGraphics, cr, v, dt, b, df);

        if b then
        begin
          if df then
          begin
            case OrientationMode of
              pomHorizontal: AGraphics.DrawLine(PointF(v, cr.Top), PointF(v, cr.Bottom));
              pomVertical: AGraphics.DrawLine(PointF(cr.Left, v), PointF(cr.Right, v));
            end;
          end;

          DoAfterDrawCurrentTimeInGrid(AGraphics, cr, v, dt);
        end;
      end;
      AGraphics.RestoreState(st);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawCurrentTimeInTimeLine(AGraphics: TTMSFNCGraphics; ALeft: Boolean);
var
  st: TTMSFNCGraphicsSaveState;
  dt: TDateTime;
  v: Double;
  cr, crt, txtr: TRectF;
  b, df: Boolean;
  str: String;
  th, tw: Double;
  sz: TSizeF;
begin
  if ALeft and not (ptlLeft in TimeLineAppearance.Layouts) then
    Exit;

  if not ALeft and not (ptlRight in TimeLineAppearance.Layouts) then
    Exit;

  if (TimeLine.CurrentTimeMode <> pctmNone) then
  begin
    dt := CurrentTime;
    if DateTimeInRangeEx(dt, FDisplayStartTime, FDisplayEndTime, True) then
    begin
      st := AGraphics.SaveState;
      if ALeft then
        cr := GetTimeLineLeftRect
      else
        cr := GetTimeLineRightRect;

      AGraphics.ClipRect(cr);
      begin
        if ALeft then
          AGraphics.Font.Assign(TimeLineAppearance.LeftFont)
        else
          AGraphics.Font.Assign(TimeLineAppearance.RightFont);

        {$IFDEF FMXLIB}
        AGraphics.Font.Size := AGraphics.Font.Size * 0.75;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        AGraphics.Font.Size := Round(AGraphics.Font.Size * 0.75);
        {$ENDIF}

        AGraphics.Fill.Color := TimeLineAppearance.CurrentTimeColor;
        AGraphics.Stroke.Color := TimeLineAppearance.CurrentTimeColor;
        AGraphics.Fill.Kind := gfkSolid;
        AGraphics.Stroke.Kind := gskSolid;

        b := True;
        df := True;
        crt := GetContentRect;

        case OrientationMode of
          pomHorizontal: v := DateTimeToValue(dt, True) - GetHorizontalScrollPosition + crt.Left;
          pomVertical: v := DateTimeToValue(dt, True) - GetVerticalScrollPosition + crt.Top;
          else
            v := 0;
        end;

        if ALeft then
          DoBeforeDrawCurrentTimeInTimeLine(AGraphics, cr, ikTimeLineLeft, v, dt, b, df)
        else
          DoBeforeDrawCurrentTimeInTimeLine(AGraphics, cr, ikTimeLineRight, v, dt, b, df);

        if b then
        begin
          if df then
          begin
            case TimeLine.CurrentTimeMode of
              pctmLine:
              begin
                case OrientationMode of
                  pomHorizontal:
                  begin
                    if ALeft then
                    begin
                      AGraphics.DrawLine(PointF(v, cr.Bottom - 20), PointF(v, cr.Bottom));
                      AGraphics.DrawEllipse(RectF(v - 5, cr.Bottom - 30, v + 5, cr.Bottom - 20));
                    end
                    else
                    begin
                      AGraphics.DrawLine(PointF(v, cr.Top), PointF(v, cr.Top + 20));
                      AGraphics.DrawEllipse(RectF(v - 5, cr.Top + 20, v + 5, cr.Top + 30));
                    end;
                  end;
                  pomVertical:
                  begin
                    if ALeft then
                    begin
                      AGraphics.DrawLine(PointF(cr.Right - 20, v), PointF(cr.Right, v), gcpmNone);
                      AGraphics.DrawEllipse(RectF(cr.Right - 30, v - 5, cr.Right - 20, v + 5), gcrmNone);
                    end
                    else
                    begin
                      AGraphics.DrawLine(PointF(cr.Left, v), PointF(cr.Left + 20, v), gcpmNone);
                      AGraphics.DrawEllipse(RectF(cr.Left + 20, v - 5, cr.Left + 30, v + 5), gcrmNone);
                    end;
                  end;
                end;
              end;
              pctmText:
              begin
                str := FormatDateTime('hh:nn', dt);
                sz := AGraphics.CalculateTextSize(str);
                th := sz.cx + 4;
                tw := sz.cy + 4;
                case OrientationMode of
                  pomHorizontal: txtr := RectF(v - tw / 2, cr.Top + ((cr.Bottom - cr.Top) - th) / 2, v + tw / 2, cr.Top + ((cr.Bottom - cr.Top) - th) / 2 + th);
                  pomVertical: txtr := RectF(cr.Left + ((cr.Right - cr.Left) - tw) / 2, v - th / 2, cr.Left + ((cr.Right - cr.Left) - tw) / 2 + tw, v + th / 2);
                end;
                AGraphics.DrawRectangle(txtr, gcrmNone);
                AGraphics.DrawText(txtr, str, False, gtaCenter, gtaCenter);
              end;
            end;
          end;

          if ALeft then
            DoAfterDrawCurrentTimeInTimeLine(AGraphics, cr, ikTimeLineLeft, v, dt)
          else
            DoAfterDrawCurrentTimeInTimeLine(AGraphics, cr, ikTimeLineRight, v, dt);
        end;
      end;
      AGraphics.RestoreState(st);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.DrawPosition(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: Integer; AKind: TTMSFNCPlannerCacheItemKind);
var
  b: Boolean;
  str: String;
  df: Boolean;
  txtr: TRectF;
begin
  case AKind of
    ikPositionTop:
    begin
      AGraphics.Stroke.Assign(PositionsAppearance.TopStroke);
      AGraphics.Fill.Assign(PositionsAppearance.TopFill);
    end;
    ikPositionBottom:
    begin
      AGraphics.Stroke.Assign(PositionsAppearance.BottomStroke);
      AGraphics.Fill.Assign(PositionsAppearance.BottomFill);
    end;
  end;

  FixStroke(AGraphics);

  b := True;
  df := True;
  DoBeforeDrawPosition(AGraphics, ARect, APosition, AKind, b, df);

  if b then
  begin
    if df then
      AGraphics.DrawRectangle(ARect, gcrmNone);

    case AKind of
      ikPositionTop: AGraphics.Font.Assign(PositionsAppearance.TopFont);
      ikPositionBottom: AGraphics.Font.Assign(PositionsAppearance.BottomFont);
    end;

    str := GetPositionText(APosition);
    DoGetPositionText(APosition, AKind, str);
    b := True;
    txtr := ARect;
    InflateRectEx(txtr, -2, -2);
    DoBeforeDrawPositionText(AGraphics, txtr, APosition, AKind, str, b);

    if b then
    begin
      case AKind of
        ikPositionTop:
        begin
          case PositionsAppearance.TopVerticalTextMode of
            pvtmAuto:
            begin
              case OrientationMode of
                pomHorizontal: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, -90);
                pomVertical: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign);
              end;
            end;
            pvtmAlways: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, -90);
            pvtmNone: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign);
          end;
        end;
        ikPositionBottom:
        begin
          case PositionsAppearance.BottomVerticalTextMode of
            pvtmAuto:
            begin
              case OrientationMode of
                pomHorizontal: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 90);
                pomVertical: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign);
              end;
            end;
            pvtmAlways: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign, gttNone, 90);
            pvtmNone: AGraphics.DrawText(txtr, str, False, PositionsAppearance.TopHorizontalTextAlign, PositionsAppearance.TopVerticalTextAlign);
          end;
        end;
      end;
      DoAfterDrawPositionText(AGraphics, txtr, APosition, AKind, str);
    end;
    DoAfterDrawPosition(AGraphics, ARect, APosition, AKind);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawPositions(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FPositionsTopDisplay);
  DrawDisplay(AGraphics, FPositionsBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.DrawSelection(AGraphics: TTMSFNCGraphics);
var
  st: TTMSFNCGraphicsSaveState;
  c, r: Integer;
  sc, stc: TTMSFNCPlannerCell;
  x, y, w, h: Double;
  rc: TRectF;
  cr: TRectF;
begin
  if not Interaction.ShowSelection then
    Exit;

  sc := Selection.StartCell;
  stc := Selection.EndCell;

  cr := GetContentClipRect;
  InflateRectEx(cr, -1, -1);
  st := AGraphics.SaveState;
  AGraphics.ClipRect(cr);
  AGraphics.Fill.Assign(SelectionAppearance.Fill);
  AGraphics.Stroke.Assign(SelectionAppearance.Stroke);

  x := StartX;
  for c := StartCol to stc.Col do
  begin
    w := ColumnWidths[c];
    y := StartY;
    for r := StartRow to stc.Row do
    begin
      h := RowHeights[r];
      if (c >= sc.Col) and (c <= stc.Col) and (r >= sc.Row) and (r <= stc.Row) then
      begin
        case OrientationMode of
          pomHorizontal: rc := RectF(y, x, y + h, x + w);
          pomVertical: rc := RectF(x, y, x + w, y + h);
        end;

        {$IFDEF FMXWEBLIB}
        rc.Left := int(rc.Left) + 1;
        rc.Top := int(rc.Top) + 1;
        case OrientationMode of
          pomHorizontal:
          begin
            if rc.Bottom = StopX then
              rc.Bottom := int(rc.Bottom) - 1
            else
              rc.Bottom := int(rc.Bottom);

            if rc.Right = StopY then
              rc.Right := int(rc.Right) - 1
            else
              rc.Right := int(rc.Right);
          end;
          pomVertical:
          begin
            if rc.Bottom = StopY then
              rc.Bottom := int(rc.Bottom) - 1
            else
              rc.Bottom := int(rc.Bottom);

            if rc.Right = StopX then
              rc.Right := int(rc.Right) - 1
            else
              rc.Right := int(rc.Right);
          end;
        end;
        {$ENDIF}
        {$IFDEF CMNLIB}
        rc.Left := rc.Left + 1;
        rc.Top := rc.Top + 1;
        case OrientationMode of
          pomHorizontal:
          begin
            if rc.Bottom = StopX then
              rc.Bottom := rc.Bottom - 1
            else
              rc.Bottom := rc.Bottom;

            if rc.Right = StopY then
              rc.Right := rc.Right - 1
            else
              rc.Right := rc.Right;
          end;
          pomVertical:
          begin
            if rc.Bottom = StopY then
              rc.Bottom := rc.Bottom - 1
            else
              rc.Bottom := rc.Bottom;

            if rc.Right = StopX then
              rc.Right := rc.Right - 1
            else
              rc.Right := rc.Right;
          end;
        end;
        {$ENDIF}
        AGraphics.DrawRectangle(rc, gcrmNone);
      end;
      y := y + h;
    end;
    x := x + w;
  end;

  AGraphics.RestoreState(st);
end;

procedure TTMSFNCCustomPlanner.DrawTime(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: Double; ARow: Integer; AKind: TTMSFNCPlannerCacheItemKind);
var
  b, df: Boolean;
  str: String;
  txtr: TRectF;
  isSub: Boolean;
  sk: TTMSFNCGraphicsStrokeKind;
begin
  case AKind of
    ikTimeLineLeft:
    begin
      AGraphics.Stroke.Assign(TimeLineAppearance.LeftStroke);
      AGraphics.Fill.Assign(TimeLineAppearance.LeftFill);
    end;
    ikTimeLineRight:
    begin
      AGraphics.Stroke.Assign(TimeLineAppearance.RightStroke);
      AGraphics.Fill.Assign(TimeLineAppearance.RightFill);
    end;
  end;

  FixStroke(AGraphics);

  b := True;
  df := True;
  DoBeforeDrawTime(AGraphics, ARect, AValue, ARow, AKind, b, df);
  if b then
  begin
    if df then
    begin
      sk := AGraphics.Stroke.Kind;
      AGraphics.Stroke.Kind := gskNone;
      AGraphics.DrawRectangle(ARect, gcrmNone);
      AGraphics.Stroke.Kind := sk;
      case OrientationMode of
        pomHorizontal:
        begin
          b := True;
          df := True;
          DoBeforeDrawTimeStroke(AGraphics, ARect, AValue, False, ARow, AKind, b, df);
          if b then
          begin
            if df then
            begin
              AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Right, ARect.Top), gcpmNone);
              AGraphics.DrawLine(PointF(ARect.Left, ARect.Bottom), PointF(ARect.Right, ARect.Bottom), gcpmNone);
            end;
            DoAfterDrawTimeStroke(AGraphics, ARect, AValue, False, ARow, AKind);
          end;

          issub := HasDateTimeSub and IsDateTimeSub(AValue);
          if isSub then
          begin
            case AKind of
              ikTimeLineLeft: AGraphics.Stroke.Assign(TimeLineAppearance.LeftSubStroke);
              ikTimeLineRight: AGraphics.Stroke.Assign(TimeLineAppearance.RightSubStroke);
            end;
          end;

          b := True;
          df := True;
          DoBeforeDrawTimeStroke(AGraphics, ARect, AValue, isSub, ARow, AKind, b, df);
          if b then
          begin
            if df then
            begin
              if isSub then
              begin
                case AKind of
                  ikTimeLineLeft: AGraphics.DrawLine(PointF(ARect.Left, ARect.Bottom - 20), PointF(ARect.Left, ARect.Bottom), gcpmNone);
                  ikTimeLineRight: AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Top + 20), gcpmNone);
                end;
              end
              else
                AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Bottom), gcpmNone)
            end;
            DoAfterDrawTimeStroke(AGraphics, ARect, AValue, isSub, ARow, AKind);
          end;
        end;
        pomVertical:
        begin
          b := True;
          df := True;
          DoBeforeDrawTimeStroke(AGraphics, ARect, AValue, False, ARow, AKind, b, df);
          if b then
          begin
            if df then
            begin
              AGraphics.DrawLine(PointF(ARect.Right, ARect.Top), PointF(ARect.Right, ARect.Bottom), gcpmNone);
              AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Bottom), gcpmNone);
            end;
            DoAfterDrawTimeStroke(AGraphics, ARect, AValue, False, ARow, AKind);
          end;

          issub := HasDateTimeSub and IsDateTimeSub(AValue);
          if isSub then
          begin
            case AKind of
              ikTimeLineLeft: AGraphics.Stroke.Assign(TimeLineAppearance.LeftSubStroke);
              ikTimeLineRight: AGraphics.Stroke.Assign(TimeLineAppearance.RightSubStroke);
            end;
          end;

          b := True;
          df := True;
          DoBeforeDrawTimeStroke(AGraphics, ARect, AValue, isSub, ARow, AKind, b, df);
          if b then
          begin
            if df then
            begin
              if isSub then
              begin
                case AKind of
                  ikTimeLineLeft: AGraphics.DrawLine(PointF(ARect.Right - 20, ARect.Top), PointF(ARect.Right, ARect.Top), gcpmNone);
                  ikTimeLineRight: AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left + 20, ARect.Top), gcpmNone);
                end;
              end
              else
                AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Right, ARect.Top), gcpmNone)
            end;
            DoAfterDrawTimeStroke(AGraphics, ARect, AValue, isSub, ARow, AKind);
          end;
        end;
      end;
    end;

    case AKind of
      ikTimeLineLeft: AGraphics.Font.Assign(TimeLineAppearance.LeftFont);
      ikTimeLineRight: AGraphics.Font.Assign(TimeLineAppearance.RightFont);
    end;

    if HasDateTimeSub then
    begin
      if IsDateTimeSub(AValue) then
      begin
        {$IFDEF FMXLIB}
        case AKind of
          ikTimeLineLeft: AGraphics.Font.Size := TimeLineAppearance.LeftSubUnitFontSize;
          ikTimeLineRight: AGraphics.Font.Size := TimeLineAppearance.RightSubUnitFontSize;
        end;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        case AKind of
          ikTimeLineLeft: AGraphics.Font.Size := Round(TimeLineAppearance.LeftSubUnitFontSize);
          ikTimeLineRight: AGraphics.Font.Size := Round(TimeLineAppearance.RightSubUnitFontSize);
        end;
        {$ENDIF}

        str := GetDateTimeText(AValue, True);
        DoGetTimeText(AValue, ARow, True, AKind, str);
        txtr := ARect;
        InflateRectEx(txtr, -2, -2);
        b := True;
        DoBeforeDrawTimeText(AGraphics, txtr, AValue, ARow, True, AKind, str, b);
        if b then
        begin
          case AKind of
            ikTimeLineLeft:
            begin
              case TimeLineAppearance.LeftSubVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign);
              end;
            end;
            ikTimeLineRight:
            begin
              case TimeLineAppearance.RightSubVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign);
              end;
            end;
          end;
          DoAfterDrawTimeText(AGraphics, txtr, AValue, ARow, True, AKind, str);
        end;
      end
      else
      begin
        str := GetDateTimeText(AValue, False);
        DoGetTimeText(AValue, ARow, False, AKind, str);
        txtr := ARect;
        InflateRectEx(txtr, -2, -2);
        b := True;
        DoBeforeDrawTimeText(AGraphics, txtr, AValue, ARow, False, AKind, str, b);
        if b then
        begin
          case AKind of
            ikTimeLineLeft:
            begin
              case TimeLineAppearance.LeftVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign);
              end;
            end;
            ikTimeLineRight:
            begin
              case TimeLineAppearance.RightVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign);
              end;
            end;
          end;
          DoAfterDrawTimeText(AGraphics, txtr, AValue, ARow, False, AKind, str);
        end;

        {$IFDEF FMXLIB}
        case AKind of
          ikTimeLineLeft: AGraphics.Font.Size := TimeLineAppearance.LeftSubUnitFontSize;
          ikTimeLineRight: AGraphics.Font.Size := TimeLineAppearance.RightSubUnitFontSize;
        end;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        case AKind of
          ikTimeLineLeft: AGraphics.Font.Size := Round(TimeLineAppearance.LeftSubUnitFontSize);
          ikTimeLineRight: AGraphics.Font.Size := Round(TimeLineAppearance.RightSubUnitFontSize);
        end;
        {$ENDIF}

        str := GetDateTimeText(AValue, True);
        DoGetTimeText(AValue, ARow, True, AKind, str);
        txtr := ARect;
        InflateRectEx(txtr, -2, -2);
        b := True;
        DoBeforeDrawTimeText(AGraphics, txtr, AValue, ARow, True, AKind, str, b);
        if b then
        begin
          case AKind of
            ikTimeLineLeft:
            begin
              case TimeLineAppearance.LeftSubVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftSubHorizontalTextAlign, TimeLineAppearance.LeftSubVerticalTextAlign);
              end;
            end;
            ikTimeLineRight:
            begin
              case TimeLineAppearance.RightSubVerticalTextMode of
                pvtmAuto:
                begin
                  case OrientationMode of
                    pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign, gttNone, -90);
                    pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign)
                  end;
                end;
                pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign, gttNone, -90);
                pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightSubHorizontalTextAlign, TimeLineAppearance.RightSubVerticalTextAlign);
              end;
            end;
          end;
          DoAfterDrawTimeText(AGraphics, txtr, AValue, ARow, True, AKind, str);
        end;
      end;
    end
    else
    begin
      str := GetDateTimeText(AValue, False);
      DoGetTimeText(AValue, ARow, False, AKind, str);
      txtr := ARect;
      InflateRectEx(txtr, -2, -2);
      b := True;
      DoBeforeDrawTimeText(AGraphics, txtr, AValue, ARow, False, AKind, str, b);
      if b then
      begin
        case AKind of
          ikTimeLineLeft:
          begin
            case TimeLineAppearance.LeftVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign, gttNone, -90);
                  pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign)
                end;
              end;
              pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign, gttNone, -90);
              pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.LeftHorizontalTextAlign, TimeLineAppearance.LeftVerticalTextAlign);
            end;
          end;
          ikTimeLineRight:
          begin
            case TimeLineAppearance.RightVerticalTextMode of
              pvtmAuto:
              begin
                case OrientationMode of
                  pomHorizontal: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign, gttNone, -90);
                  pomVertical: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign)
                end;
              end;
              pvtmAlways: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign, gttNone, -90);
              pvtmNone: AGraphics.DrawText(txtr, str, False, TimeLineAppearance.RightHorizontalTextAlign, TimeLineAppearance.RightVerticalTextAlign);
            end;
          end;
        end;
        DoAfterDrawTimeText(AGraphics, txtr, AValue, ARow, False, AKind, str);
      end;
    end;
    DoAfterDrawTime(AGraphics, ARect, AValue, ARow, AKind);
  end;
end;

procedure TTMSFNCCustomPlanner.DrawTimeLine(AGraphics: TTMSFNCGraphics);
begin
  DrawDisplay(AGraphics, FTimeLineLeftDisplay);
  DrawDisplay(AGraphics, FTimeLineRightDisplay);
end;

function TTMSFNCCustomPlanner.GetPositionBottomLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetPositionsBottomRect;
  if (pnbPrevious in Interaction.BottomNavigationButtons) then
    nr := GetBottomLeftNavigationButtonRect
  else
    nr := LocalRect;

  case OrientationMode of
    pomHorizontal:
    begin
      if (pnbPrevious in Interaction.BottomNavigationButtons) then
        Result.Top := nr.Bottom
      else
        Result.Top := nr.Top;

      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      if (pnbPrevious in Interaction.BottomNavigationButtons) then
        Result.Left := nr.Right
      else
        Result.Left := nr.Left;

      Result.Right := ptr.Left;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetActiveItem: TTMSFNCPlannerItem;
begin
  Result := ActiveItem;
end;

function TTMSFNCCustomPlanner.GetBottomLeftNavigationButtonRect: TRectF;
var
  s: Single;
  cr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not (pnbPrevious in Interaction.BottomNavigationButtons) then
    Exit;

  Result := GetPositionsBottomRect;
  cr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      s := PositionsAppearance.BottomLeftNavigationButtonSize;
      Result.Top := cr.Top;
      Result.Bottom := Result.Top + s + 1;
    end;
    pomVertical:
    begin
      s := PositionsAppearance.BottomLeftNavigationButtonSize;
      Result.Left := cr.Left;
      Result.Right := Result.Left + s + 1;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionBottomRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetPositionsBottomRect;
  if (pnbNext in Interaction.BottomNavigationButtons) then
    nr := GetBottomRightNavigationButtonRect
  else
    nr := LocalRect;

  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      if (pnbNext in Interaction.BottomNavigationButtons) then
        Result.Bottom := nr.Top
      else
        Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      if (pnbNext in Interaction.BottomNavigationButtons) then
        Result.Right := nr.Left
      else
        Result.Right := nr.Right - 1;

      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetBottomRightNavigationButtonRect: TRectF;
var
  s: Single;
  cr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not (pnbNext in Interaction.BottomNavigationButtons) then
    Exit;

  Result := GetPositionsBottomRect;
  cr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      s := PositionsAppearance.BottomRightNavigationButtonSize;
      Result.Top := cr.Bottom - s - 1;
      Result.Bottom := cr.Bottom - 1;
    end;
    pomVertical:
    begin
      s := PositionsAppearance.BottomRightNavigationButtonSize;
      Result.Left := cr.Right - s - 1;
      Result.Right := cr.Right - 1;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetCacheHeight: Integer;
begin
  Result := CACHEHEIGHT;
end;

function TTMSFNCCustomPlanner.GetCacheItemDeleteRect(
  ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  sz: Double;
begin
  if not Assigned(ACacheItem) then
    Exit;

  Result := ACacheItem.DrawRect;
  sz := ItemsAppearance.DeleteAreaSize;
  case OrientationMode of
    pomHorizontal: Result := RectF(Result.Right - sz, Result.Top, Result.Right, Result.Top + sz);
    pomVertical: Result := RectF(Result.Right - sz, Result.Top, Result.Right, Result.Top + sz);
  end;
end;

function TTMSFNCCustomPlanner.GetCacheItemEndTimeSizeRect(
  ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  sz: Double;
begin
  if not Assigned(ACacheItem) then
    Exit;

  Result := ACacheItem.DrawRect;
  sz := ItemsAppearance.SizeAreaSize;
  case OrientationMode of
    pomHorizontal: Result := RectF(Result.Right - sz, Result.Top, Result.Right, Result.Bottom);
    pomVertical: Result := RectF(Result.Left, Result.Bottom - sz, Result.Right, Result.Bottom);
  end;
end;

function TTMSFNCCustomPlanner.GetCacheItemMoveRect(
  ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  sz: Double;
begin
  if not Assigned(ACacheItem) then
    Exit;

  Result := ACacheItem.DrawRect;
  sz := ItemsAppearance.MoveAreaSize;
  case OrientationMode of
    pomHorizontal: Result := RectF(Result.Left, Result.Top, Result.Right, Result.Top + sz);
    pomVertical: Result := RectF(Result.Left, Result.Top, Result.Left + sz, Result.Bottom);
  end;
end;

function TTMSFNCCustomPlanner.GetCacheItemRect(
  ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  ARect: TRectF;
  it: TTMSFNCPlannerItem;
  sz: Double;
  sr, mr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not Assigned(ACacheItem) then
    Exit;

  if not Assigned(ACacheItem.Item) then
    Exit;

  it := ACacheItem.Item;
  ARect := ACacheItem.DrawRect;

  if it.Movable and CanMoveCacheItem(ACacheItem) and ItemsAppearance.ShowMoveArea and AllowDesktopMove and not Interaction.ReadOnly then
  begin
    sz := ItemsAppearance.MoveAreaSize;
    case OrientationMode of
      pomHorizontal:
      begin
        mr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
        ARect.Top := mr.Bottom + 1;
      end;
      pomVertical:
      begin
        mr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
        ARect.Left := mr.Right + 1;
      end;
    end;
  end;

  if (ACacheItem.Idx = 0) and CanSizeCacheItemStartTime(ACacheItem) and ItemsAppearance.ShowSizeArea and AllowDesktopSize and it.Sizeable and not Interaction.ReadOnly then
  begin
    sz := ItemsAppearance.SizeAreaSize;
    case OrientationMode of
      pomHorizontal:
      begin
        sr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
        ARect.Left := sr.Right + 1;
      end;
      pomVertical:
      begin
        sr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
        ARect.Top := sr.Bottom + 1;
      end;
    end;
  end;

  if (ACacheItem.Idx = TTMSFNCPlannerItemOpen(it).CacheList.Count - 1) and CanSizeCacheItemEndTime(ACacheItem) and it.Sizeable and ItemsAppearance.ShowSizeArea and AllowDesktopSize and not Interaction.ReadOnly then
  begin
    sz := ItemsAppearance.SizeAreaSize;
    case OrientationMode of
      pomHorizontal:
      begin
        sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Bottom);
        ARect.Right := sr.Left - 1;
      end;
      pomVertical:
      begin
        sr := RectF(ARect.Left, ARect.Bottom - sz, ARect.Right, ARect.Bottom);
        ARect.Bottom := sr.Top - 1;
      end;
    end;
  end;

  ChangeRectForMark(it, ARect);

  Result := ARect;
end;

function TTMSFNCCustomPlanner.GetCacheItemStartTimeSizeRect(
  ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  sz: Double;
begin
  if not Assigned(ACacheItem) then
    Exit;

  Result := ACacheItem.DrawRect;
  sz := ItemsAppearance.SizeAreaSize;
  case OrientationMode of
    pomHorizontal: Result := RectF(Result.Left, Result.Top, Result.Left + sz, Result.Bottom);
    pomVertical: Result := RectF(Result.Left, Result.Top, Result.Right, Result.Top + sz);
  end;
end;

function TTMSFNCCustomPlanner.GetCacheWidth: Integer;
begin
  Result := CACHEWIDTH
end;

function TTMSFNCCustomPlanner.GetCalculationRect: TRectF;
begin
  Result := inherited GetCalculationRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Left + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Top := Result.Top + GetTimeLineLeftSize;
      Result.Right := Result.Right - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize;
      Result.Bottom := Result.Bottom - GetTimeLineRightSize;
    end;
    pomVertical:
    begin
      Result.Top := Result.Top + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Left := Result.Left + GetTimeLineLeftSize;
      Result.Bottom := Result.Bottom - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize;
      Result.Right := Result.Right - GetTimeLineRightSize;
    end;
  end;

  Result.Right := Result.Left + Max(0, Result.Right - Result.Left);
  Result.Bottom := Result.Top + Max(0, Result.Bottom - Result.Top);
end;

function TTMSFNCCustomPlanner.GetContentClipRect: TRectF;
begin
  Result := GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Bottom := Result.Top + Min(GetTotalColumnWidth, Result.Bottom - Result.Top);
      Result.Right := Result.Left + Min(GetTotalRowHeight, Result.Right - Result.Left);
    end;
    pomVertical:
    begin
      Result.Bottom := Result.Top + Min(GetTotalRowHeight, Result.Bottom - Result.Top);
      Result.Right := Result.Left + Min(GetTotalColumnWidth, Result.Right - Result.Left);
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetContentRect: TRectF;
begin
  Result := inherited GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Left + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Top := Result.Top + GetTimeLineLeftSize;
      Result.Right := Result.Right - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize;
      Result.Bottom := Result.Bottom - GetTimeLineRightSize;
    end;
    pomVertical:
    begin
      Result.Top := Result.Top + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Left := Result.Left + GetTimeLineLeftSize;
      Result.Bottom := Result.Bottom - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize;
      Result.Right := Result.Right - GetTimeLineRightSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.CurrentTime: TDateTime;
begin
  case Mode of
    pmMonth, pmMultiMonth: Result := EncodeDate(YearOf(FDisplayStartTime), MonthOf(FDisplayStartTime), Min(DaysInMonth(FDisplayStartTime), DayOf(Now))) + Frac(Now);
    pmDayPeriod, pmHalfDayPeriod, pmCustom: Result := Now
    else
      Result := Int(FDisplayStartTime) + Frac(Now);
  end;
end;

function TTMSFNCCustomPlanner.GetDateTimeText(ADateTime: TDateTime; ASub: Boolean): String;
begin
  case GetDisplayMode of
    pmMultiMonth: Result := FloatToStr(ADateTime);
    else
    begin
      if ASub then
        Result := FormatDateTime(GetDisplaySubUnitFormat, ADateTime)
      else
        Result := FormatDateTime(GetDisplayUnitFormat, ADateTime)
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetDefaultItem: TTMSFNCPlannerItem;
begin
  Result := DefaultItem;
end;

function TTMSFNCCustomPlanner.GetDeleteHandler: TTMSFNCPlannerDeleteHandler;
begin
  if not FDeleteHandlerCreated then
  begin
    FDeletePanel := TTMSFNCPlannerDeleteHandlerPanel.Create(Self);
    FDeletePanel.Planner := Self;
    FDeleteHandlerCreated := True;
  end;

  Result.Background := FDeletePanel;
end;

function TTMSFNCCustomPlanner.GetDisplaySubUnitFormat: String;
begin
  if TimeLine.DisplaySubUnitFormat <> '' then
    Result := TimeLine.DisplaySubUnitFormat
  else
  begin
    case GetDisplayMode of
      pmHalfDayPeriod: Result := 'hh:nn';
      pmDay, pmDayPeriod, pmMultiDay, pmMultiResDay, pmMultiDayRes: Result := 'nn';
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetDisplayMode: TTMSFNCPlannerMode;
begin
  Result := Mode;
  case Result of
    pmMultiResDay:
    begin
      if Resources.Count = 0 then
        Result := pmDay;
    end;
    pmMultiDayRes:
    begin
      if Resources.Count = 0 then
        Result := pmMultiDay;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetDisplayOffsetValue: TDateTime;
var
  du: Integer;
begin
  du := TimeLine.DisplayOffset;
  case TimeLine.DisplayOffsetType of
    pduMilliSecond: Result := IncMilliSecond(0, du);
    pduSecond: Result := IncSecond(0, du);
    pduMinute: Result := IncMinute(0, du);
    pduHour: Result := IncHour(0, du);
    pduDay: Result := IncDay(0, du);
    else
      Result := 0;
  end
end;

function TTMSFNCCustomPlanner.GetDisplayUnitFormat: String;
begin
  if TimeLine.DisplayUnitFormat <> '' then
    Result := TimeLine.DisplayUnitFormat
  else
  begin
    case GetDisplayMode of
      pmCustom: Result := FormatSettings.ShortDateFormat + ' ' + FormatSettings.ShortTimeFormat;
      pmDayPeriod, pmHalfDayPeriod: Result := FormatSettings.ShortDateFormat;
      pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes: Result := 'h';
      pmMonth: Result := FormatSettings.ShortDateFormat;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetDisplayUnitValue: TDateTime;
var
  du: Integer;
begin
  du := TimeLine.DisplayUnit;
  case TimeLine.DisplayUnitType of
    pduMilliSecond: Result := IncMilliSecond(0, du);
    pduSecond: Result := IncSecond(0, du);
    pduMinute: Result := IncMinute(0, du);
    pduHour: Result := IncHour(0, du);
    pduDay: Result := IncDay(0, du);
    else
      Result := 0;
  end;
end;

function TTMSFNCCustomPlanner.GetFirstRect(AItem: TTMSFNCPlannerItem): TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if Assigned(AItem) and (TTMSFNCPlannerItemOpen(AItem).CacheList.Count > 0) then
    Result := TTMSFNCPlannerItemOpen(AItem).CacheList[TTMSFNCPlannerItemOpen(AItem).CacheList.Count - 1].DrawRect;
end;

function TTMSFNCCustomPlanner.GetGroupBottomLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetGroupsBottomRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := nr.Top;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := nr.Left;
      Result.Right := ptr.Left;
      Result.Bottom := nr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupBottomRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetGroupsBottomRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      Result.Right := nr.Right;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupsBottomRect: TRectF;
begin
  Result := GetPositionsBottomRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Right;
      Result.Right := Result.Left + GetGroupsBottomSize
    end;
    pomVertical:
    begin
      Result.Top := Result.Bottom;
      Result.Bottom := Result.Top + GetGroupsBottomSize
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupsBottomSize: Double;
begin
  Result := 0;
  if (pglBottom in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (ColumnCount > 0) then
    Result := GroupsAppearance.BottomSize;
end;

function TTMSFNCCustomPlanner.GetGroupsTopRect: TRectF;
begin
  Result := GetPositionsTopRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Right := Result.Left;
      Result.Left := Result.Right - GetGroupsTopSize;
    end;
    pomVertical:
    begin
      Result.Bottom := Result.Top;
      Result.Top := Result.Top - GetGroupsTopSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupsTopSize: Double;
begin
  Result := 0;
  if (pglTop in GroupsAppearance.Layouts) and (FDisplayGroups.Count > 0) and (ColumnCount > 0) then
    Result := GroupsAppearance.TopSize;
end;

function TTMSFNCCustomPlanner.GetFullDayBottomLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetFullDaysBottomRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := nr.Top;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := nr.Left;
      Result.Right := ptr.Left;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDayBottomRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetFullDaysBottomRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      Result.Right := nr.Right;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDaysBottomRect: TRectF;
begin
  Result := GetPositionsBottomRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Right := Result.Left;
      Result.Left := Result.Right - GetFullDaysBottomSize
    end;
    pomVertical:
    begin
      Result.Bottom := Result.Top;
      Result.Top := Result.Bottom - GetFullDaysBottomSize
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDaysBottomSize: Double;
var
  I, C: Integer;
begin
  Result := 0;
  if (pfdlBottom in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (ColumnCount > 0) then
  begin
    if IsFullDayAutoSize then
    begin
      C := 0;
      for I := 0 to Items.Count - 1 do
        C := Max(TTMSFNCPlannerItemOpen(Items[I]).FullDayMaxListBottomCount, C);

      Result := FullDaysAppearance.AutoItemHeight * c;
    end
    else
      Result := FullDaysAppearance.BottomSize;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDaysTopRect: TRectF;
begin
  Result := GetPositionsTopRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Right;
      Result.Right := Result.Left + GetFullDaysTopSize;
    end;
    pomVertical:
    begin
      Result.Top := Result.Bottom;
      Result.Bottom := Result.Top + GetFullDaysTopSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDaysTopSize: Double;
var
  I, C: Integer;
begin
  Result := 0;
  if (pfdlTop in FullDaysAppearance.Layouts) and (FDisplayFullDays.Count > 0) and (ColumnCount > 0) then
  begin
    if IsFullDayAutoSize then
    begin
      C := 0;
      for I := 0 to Items.Count - 1 do
        C := Max(TTMSFNCPlannerItemOpen(Items[I]).FullDayMaxListTopCount, C);

      Result := FullDaysAppearance.AutoItemHeight * c;
    end
    else
      Result := FullDaysAppearance.TopSize;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupText(AGroup: Integer): String;
var
  p: Double;
begin
  Result := TranslateTextEx(sTMSFNCPlannerGroup) + ' ' + inttostr(AGroup);
  case GetDisplayMode of
    pmMultiResDay:
    begin
      if ColumnCount > 0 then
      begin
        p := IncDay(FDisplayStartTime, AGroup mod ColumnCount);
        if Positions.Format <> '' then
          Result := FormatDateTime(Positions.Format, p)
        else
          Result := FormatDateTime('dddd', p)
      end;
    end;
    pmMultiDayRes:
    begin
      if (AGroup >= 0) and (AGroup <= Resources.Count - 1) then
        Result := TTMSFNCPlannerResourceOpen(Resources[AGroup]).GetText;
    end
    else
    begin
      if (AGroup >= 0) and (AGroup <= Groups.Count - 1) then
        Result := TTMSFNCPlannerGroupOpen(Groups[AGroup]).GetText;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupTopLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetGroupsTopRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := nr.Top;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := nr.Left;
      Result.Right := ptr.Left;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetGroupTopRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetGroupsTopRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      Result.Right := nr.Right;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDayTopLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetFullDaysTopRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := nr.Top;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := nr.Left;
      Result.Right := ptr.Left;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetFullDayTopRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetFullDaysTopRect;
  nr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      Result.Right := nr.Right;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetInplaceEditor: TTMSFNCPlannerInplaceEditor;
begin
  Result := FInplaceEditor;
end;

function TTMSFNCCustomPlanner.GetInplaceEditorRect(ACacheItem: TTMSFNCPlannerCacheItem; AItem: TTMSFNCPlannerItem): TRectF;
var
  it: TTMSFNCPlannerItemOpen;
  cr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  it := nil;
  if Assigned(AItem) then
    it := TTMSFNCPlannerItemOpen(AItem);

  case Interaction.InplaceEditorMode of
    piemText:
    begin
      if Assigned(ACacheItem) then
        Result := GetCacheItemTextRect(ACacheItem)
      else if Assigned(it) and (it.CacheList.Count > 0) then
        Result := GetCacheItemTextRect(it.CacheList[it.CacheList.Count - 1]);
    end;
    piemTitle:
    begin
      if Assigned(ACacheItem) then
        Result := GetCacheItemTitleRect(ACacheItem)
      else if Assigned(it) and (it.CacheList.Count > 0) then
        Result := GetCacheItemTitleRect(it.CacheList[it.CacheList.Count - 1]);
    end;
    piemItem:
    begin
      if Assigned(ACacheItem) then
        Result := GetCacheItemRect(ACacheItem)
      else if Assigned(it) and (it.CacheList.Count > 0) then
        Result := GetCacheItemRect(it.CacheList[it.CacheList.Count - 1]);
    end;
  end;

  cr := GetContentClipRect;
  Result.Top := Max(Result.Top, cr.Top);
  Result.Bottom := Min(Result.Bottom, cr.Bottom);
  Result.Left := Max(Result.Left, cr.Left);
  Result.Right := Min(Result.Right, cr.Right);
  Result := RectF(Floor(Result.Left), Floor(Result.Top), Floor(Result.Right), Floor(Result.Bottom));
end;

function TTMSFNCCustomPlanner.GetCacheItemTextRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  ARect: TRectF;
  it: TTMSFNCPlannerItem;
  g: TTMSFNCGraphics;
  th: Double;
  str: String;
  sz: Double;
  sr, mr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not Assigned(ACacheItem) then
    Exit;

  if not Assigned(ACacheItem.Item) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    it := ACacheItem.Item;
    ARect := ACacheItem.DrawRect;

    if it.Movable and CanMoveCacheItem(ACacheItem) and ItemsAppearance.ShowMoveArea and AllowDesktopMove and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.MoveAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          mr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
          ARect.Top := mr.Bottom + 1;
        end;
        pomVertical:
        begin
          mr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
          ARect.Left := mr.Right + 1;
        end;
      end;
    end;

    if (ACacheItem.Idx = 0) and CanSizeCacheItemStartTime(ACacheItem) and ItemsAppearance.ShowSizeArea and AllowDesktopSize and it.Sizeable and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.SizeAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          sr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
          ARect.Left := sr.Right + 1;
        end;
        pomVertical:
        begin
          sr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
          ARect.Top := sr.Bottom + 1;
        end;
      end;
    end;

    if (ACacheItem.Idx = TTMSFNCPlannerItemOpen(it).CacheList.Count - 1) and CanSizeCacheItemEndTime(ACacheItem) and it.Sizeable and ItemsAppearance.ShowSizeArea and AllowDesktopSize and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.SizeAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Bottom);
          ARect.Right := sr.Left - 1;
        end;
        pomVertical:
        begin
          sr := RectF(ARect.Left, ARect.Bottom - sz, ARect.Right, ARect.Bottom);
          ARect.Bottom := sr.Top - 1;
        end;
      end;
    end;

    ChangeRectForMark(it, ARect);

    Result := ARect;

    str := it.Title;
    DoGetItemTitleText(it, pgtmDrawing, str);
    if (str <> '') and it.ShowTitle then
    begin
      if it.Enabled then
      begin
        if FSelectedItems.IndexOf(it) > -1 then
        begin
          if it = FActiveItem then
            g.Font.Assign(ItemsAppearance.ActiveTitleFont)
          else
            g.Font.Assign(ItemsAppearance.SelectedTitleFont)
        end
        else
          g.Font.Assign(ItemsAppearance.TitleFont);
      end
      else
        g.Font.Assign(ItemsAppearance.DisabledTitleFont);

      th := Min(g.CalculateTextHeight(str) + 5, ARect.Bottom - ARect.Top);

      Result.Top := Result.Top + th;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.GetCacheItemTitleRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  ARect: TRectF;
  it: TTMSFNCPlannerItem;
  th: Double;
  str: String;
  sz: Double;
  sr, mr: TRectF;
  g: TTMSFNCGraphics;
begin
  Result := RectF(0, 0, 0, 0);
  if not Assigned(ACacheItem) then
    Exit;

  if not Assigned(ACacheItem.Item) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    it := ACacheItem.Item;
    ARect := ACacheItem.DrawRect;

    if it.Movable and CanMoveCacheItem(ACacheItem) and ItemsAppearance.ShowMoveArea and AllowDesktopMove and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.MoveAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          mr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
          ARect.Top := mr.Bottom + 1;
        end;
        pomVertical:
        begin
          mr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
          ARect.Left := mr.Right + 1;
        end;
      end;
    end;

    if (ACacheItem.Idx = 0) and CanSizeCacheItemStartTime(ACacheItem) and ItemsAppearance.ShowSizeArea and AllowDesktopSize and it.Sizeable and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.SizeAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          sr := RectF(ARect.Left, ARect.Top, ARect.Left + sz, ARect.Bottom);
          ARect.Left := sr.Right + 1;
        end;
        pomVertical:
        begin
          sr := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + sz);
          ARect.Top := sr.Bottom + 1;
        end;
      end;
    end;

    if (ACacheItem.Idx = TTMSFNCPlannerItemOpen(it).CacheList.Count - 1) and CanSizeCacheItemEndTime(ACacheItem) and it.Sizeable and ItemsAppearance.ShowSizeArea and AllowDesktopSize and not Interaction.ReadOnly then
    begin
      sz := ItemsAppearance.SizeAreaSize;
      case OrientationMode of
        pomHorizontal:
        begin
          sr := RectF(ARect.Right - sz, ARect.Top, ARect.Right, ARect.Bottom);
          ARect.Right := sr.Left - 1;
        end;
        pomVertical:
        begin
          sr := RectF(ARect.Left, ARect.Bottom - sz, ARect.Right, ARect.Bottom);
          ARect.Bottom := sr.Top - 1;
        end;
      end;
    end;

    ChangeRectForMark(it, ARect);

    Result := ARect;

    str := it.Title;
    DoGetItemTitleText(it, pgtmDrawing, str);
    if (str <> '') and it.ShowTitle then
    begin
      if it.Enabled then
      begin
        if FSelectedItems.IndexOf(it) > -1 then
        begin
          if it = FActiveItem then
            g.Font.Assign(ItemsAppearance.ActiveTitleFont)
          else
            g.Font.Assign(ItemsAppearance.SelectedTitleFont)
        end
        else
          g.Font.Assign(ItemsAppearance.TitleFont);
      end
      else
        g.Font.Assign(ItemsAppearance.DisabledTitleFont);

      th := Min(g.CalculateTextHeight(str) + 5, ARect.Bottom - ARect.Top);

      Result.Bottom := Result.Top + th;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomPlanner.GetAnchorItemTitleRect(ACacheItem: TTMSFNCPlannerCacheItem): TRectF;
var
  r: TRectF;
  it: TTMSFNCPlannerItem;
  th: Double;
  g: TTMSFNCGraphics;
begin
  Result := RectF(0, 0, 0, 0);
  if not Assigned(ACacheItem) then
    Exit;

  if not Assigned(ACacheItem.Item) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    it := ACacheItem.Item;
    r := ACacheItem.DrawRect;

    if (it.Title <> '') and it.ShowTitle then
    begin
      if not IsFullDayItem(it) then
      begin
        if it.Enabled then
        begin
          if FSelectedItems.IndexOf(it) > -1 then
          begin
            if it = FActiveItem then
              g.Font.Assign(ItemsAppearance.ActiveTitleFont)
            else
              g.Font.Assign(ItemsAppearance.SelectedTitleFont)
          end
          else
            g.Font.Assign(ItemsAppearance.TitleFont);
        end
        else
          g.Font.Assign(ItemsAppearance.DisabledTitleFont);

        th := Round(Min(g.CalculateTextHeight(it.Title) + 5, r.Bottom - r.Top));

        r.Bottom := r.Top + th;
      end;
    end;

    InflateRectEx(r, -2, -2);

    Result := r;
  finally
    g.EndScene;
    g.Free;
  end;
end;


function TTMSFNCCustomPlanner.GetLastRect(AItem: TTMSFNCPlannerItem): TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if Assigned(AItem) and (TTMSFNCPlannerItemOpen(AItem).CacheList.Count > 0) then
    Result := TTMSFNCPlannerItemOpen(AItem).CacheList[0].DrawRect;
end;

function TTMSFNCCustomPlanner.GetMaxDisplayUnitValue: Double;
var
  du: Double;
begin
  Result := 0;
  du := TimeLine.DisplayUnit;
  if du > 0 then
  begin
    case GetDisplayMode of
      pmMultiMonth: Result := 31;
      pmMultiDay, pmMultiResDay, pmMultiDayRes:
      begin
        case TimeLine.DisplayUnitType of
          pduMilliSecond: Result := MSecsPerDay / du;
          pduSecond: Result := SecsPerDay / du;
          pduMinute: Result := MinsPerDay / du;
          pduHour: Result := HoursPerDay / du;
          pduDay: Result := 1;
        end;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetMaxPositionResources: Integer;
begin
  case GetDisplayMode of
    pmMultiDayRes: Result := Resources.Count;
    pmMultiResDay: Result := Resources.Count;
    else
      Result := ColumnCount;
  end;
end;

function TTMSFNCCustomPlanner.GetModeName(AMode: TTMSFNCPlannerMode): String;
begin
  case AMode of
    pmDay: Result := 'Day';
    pmDayPeriod: Result := 'Day Period';
    pmHalfDayPeriod: Result := 'Half Day Period';
    pmMultiDay: Result := 'Multi Day';
    pmMultiResDay: Result := 'Multi Resource Day';
    pmMultiDayRes: Result := 'Multi Day Resource';
    pmMonth: Result := 'Month';
    pmMultiMonth: Result := 'Multi Month';
    pmCustom: Result := 'Custom';
  end;
end;

procedure TTMSFNCCustomPlanner.GetNewDateTimeAndResource(
  AItem: TTMSFNCPlannerItem; ALinkType: TTMSFNCPlannerItemLinkType;
  ADiffStartDateTime, ADiffEndDateTime: TDateTime; var AStartDateTime, AEndDateTime: TDateTime);
begin
  case ALinkType of
    iltFull:
    begin
      AStartDateTime := AItem.StartTime + ADiffStartDateTime;
      AEndDateTime := AItem.EndTime + ADiffEndDateTime;
    end;
    iltStartEnd:
    begin
     AStartDateTime := AItem.StartTime + ADiffStartDateTime;
     AEndDateTime := AItem.EndTime + ADiffStartDateTime;
    end;
    iltEndStart:
    begin
      AStartDateTime := AItem.StartTime + ADiffEndDateTime;
      AEndDateTime := AItem.EndTime + ADiffEndDateTime;
    end;
    iltEndEnd:
    begin
      AStartDateTime := AItem.StartTime;
      AEndDateTime := AItem.EndTime + ADiffEndDateTime;
    end;
    iltStartStart:
    begin
      AStartDateTime := AItem.StartTime + ADiffStartDateTime;
      AEndDateTime := AItem.EndTime;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetNextDateTime: TDateTime;
begin
  Result := ModeSettings.StartTime;
  case GetDisplayMode of
    pmMultiMonth: Result := IncMonth(Result, 1);
    else
      Result := IncDay(Result, 1)
  end;
end;

function TTMSFNCCustomPlanner.GetNumDays: Integer;
begin
  Result := Trunc(0.5 + (ColumnCount / Max(1, Resources.Count)));
end;

function TTMSFNCCustomPlanner.GetPositionResourceText(APosition: Integer): String;
var
  p: Double;
begin
  Result := TranslateTextEx(sTMSFNCPlannerPosition) + ' ' + inttostr(APosition);
  case GetDisplayMode of
    pmDay, pmHalfDayPeriod, pmDayPeriod, pmMonth, pmMultiResDay, pmCustom:
    begin
      if (GetDisplayMode = pmMultiResDay) then
        APosition := APosition mod Max(1, Resources.Count);

      if (APosition >= 0) and (APosition <= Resources.Count - 1) then
      begin
        Result := Resources[APosition].Text;
        if (Result = '') or (Pos('</', Result) > 0) or (Pos('/>', Result) > 0) or (Pos('<BR>', UpperCase(Result)) > 0) then
        begin
          Result := Resources[APosition].Name;
          if Result = '' then
            Result := TranslateTextEx(sTMSFNCPlannerPosition) + ' ' + inttostr(APosition);
        end;
      end;
    end;
    pmMultiDayRes:
    begin
      p := IncDay(FDisplayStartTime, APosition mod Max(1, GetNumDays));
      Result := FormatDateTime(GetPositionFormat, p);
    end;
    pmMultiDay:
    begin
      p := IncDay(FDisplayStartTime, APosition);
      Result := FormatDateTime(GetPositionFormat, p);
    end;
    pmMultiMonth:
    begin
      p := IncMonth(FDisplayStartTime, APosition);
      Result := FormatDateTime(GetPositionFormat, p);
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionFormat: String;
begin
  if Positions.Format <> '' then
    Result := Positions.Format
  else
  begin
    case GetDisplayMode of
      pmMultiDay, pmMultiResDay, pmMultiDayRes: Result := 'dddd';
      pmMultiMonth: Result := 'mmmmm';
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionsBottomRect: TRectF;
var
  tlr, trr: TRectF;
begin
  tlr := GetTimeLineLeftRect;
  trr := GetTimeLineRightRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := tlr.Right + GetFullDaysBottomSize;
      Result.Top := tlr.Bottom;
      Result.Right := Result.Left + GetPositionsBottomSize;
      Result.Bottom := trr.Top;
    end;
    pomVertical:
    begin
      Result.Left := tlr.Right;
      Result.Top := tlr.Bottom + GetFullDaysBottomSize;
      Result.Right := trr.Left;
      Result.Bottom := Result.Top + GetPositionsBottomSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionsBottomSize: Double;
begin
  Result := 0;
  if (pplBottom in PositionsAppearance.Layouts) and (ColumnCount > 0) then
    Result := PositionsAppearance.BottomSize;
end;

function TTMSFNCCustomPlanner.GetTimeLineRightRect: TRectF;
begin
  Result := inherited GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Left + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Right := Result.Left + Min(GetTotalRowHeight, (Result.Right - Result.Left) - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize);
      Result.Top := Result.Top + Min(GetTotalColumnWidth, (Result.Bottom - Result.Top) - GetTimeLineLeftSize - GetTimeLineRightSize) + GetTimeLineLeftSize;
      Result.Bottom := Result.Top + GetTimeLineRightSize;
    end;
    pomVertical:
    begin
      Result.Top := Result.Top + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Bottom := Result.Top + Min(GetTotalRowHeight, (Result.Bottom - Result.Top) - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize);
      Result.Left := Result.Left + Min(GetTotalColumnWidth, (Result.Right - Result.Left) - GetTimeLineLeftSize - GetTimeLineRightSize) + GetTimeLineLeftSize;
      Result.Right := Result.Left + GetTimeLineRightSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetTimeLineRightSize: Double;
begin
  Result := 0;
  if ptlRight in TimeLineAppearance.Layouts then
    Result := TimeLineAppearance.RightSize;
end;

function TTMSFNCCustomPlanner.GetPositionTopLeftEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetPositionsTopRect;
  if (pnbPrevious in Interaction.TopNavigationButtons) then
    nr := GetTopLeftNavigationButtonRect
  else
    nr := LocalRect;

  case OrientationMode of
    pomHorizontal:
    begin
      if (pnbPrevious in Interaction.TopNavigationButtons) then
        Result.Top := nr.Bottom
      else
        Result.Top := nr.Top;

      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      Result.Bottom := ptr.Top;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      if (pnbPrevious in Interaction.TopNavigationButtons) then
        Result.Left := nr.Right
      else
        Result.Left := nr.Left;

      Result.Right := ptr.Left;
      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetTopLeftNavigationButtonRect: TRectF;
var
  cr: TRectF;
  s: Single;
begin
  Result := RectF(0, 0, 0, 0);
  if not (pnbPrevious in Interaction.TopNavigationButtons) then
    Exit;

  Result := GetPositionsTopRect;
  cr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      s := PositionsAppearance.TopLeftNavigationButtonSize;
      Result.Top := cr.Top;
      Result.Bottom := cr.Top + s + 1;
    end;
    pomVertical:
    begin
      s := PositionsAppearance.TopLeftNavigationButtonSize;
      Result.Left := cr.Left;
      Result.Right := Result.Left + s + 1;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionTopRightEmptyRect: TRectF;
var
  ptr, nr: TRectF;
begin
  ptr := GetPositionsTopRect;
  if (pnbNext in Interaction.TopNavigationButtons) then
    nr := GetTopRightNavigationButtonRect
  else
    nr := LocalRect;

  case OrientationMode of
    pomHorizontal:
    begin
      Result.Top := ptr.Bottom;
      Result.Left := ptr.Left;
      Result.Right := ptr.Right;
      if (pnbNext in Interaction.TopNavigationButtons) then
        Result.Bottom := nr.Top
      else
        Result.Bottom := nr.Bottom;
    end;
    pomVertical:
    begin
      Result.Top := ptr.Top;
      Result.Left := ptr.Right;
      if (pnbNext in Interaction.TopNavigationButtons) then
        Result.Right := nr.Left
      else
        Result.Right := nr.Right - 1;

      Result.Bottom := ptr.Bottom;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetTopRightNavigationButtonRect: TRectF;
var
  s: Single;
  cr: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not (pnbNext in Interaction.TopNavigationButtons) then
    Exit;

  Result := GetPositionsTopRect;
  cr := LocalRect;
  case OrientationMode of
    pomHorizontal:
    begin
      s := PositionsAppearance.TopRightNavigationButtonSize;
      Result.Top := cr.Bottom - s - 1;
      Result.Bottom := cr.Bottom - 1;
    end;
    pomVertical:
    begin
      s := PositionsAppearance.TopRightNavigationButtonSize;
      Result.Left := cr.Right - s - 1;
      Result.Right := cr.Right - 1;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetDocURL: string;
begin
  Result := TTMSFNCPlannerDocURL;
end;

function TTMSFNCCustomPlanner.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomPlanner.GetViewCol: Integer;
begin
  Result := StartCol;
end;

function TTMSFNCCustomPlanner.GetViewRow: Integer;
begin
  Result := StartRow;
end;

function TTMSFNCCustomPlanner.GetPositionsTopRect: TRectF;
var
  tlr, trr: TRectF;
begin
  tlr := GetTimeLineLeftRect;
  trr := GetTimeLineRightRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := tlr.Left - GetPositionsTopSize - GetFullDaysTopSize;
      Result.Top := tlr.Bottom;
      Result.Right := tlr.Left - GetFullDaysTopSize;
      Result.Bottom := trr.Top;
    end;
    pomVertical:
    begin
      Result.Left := tlr.Right;
      Result.Top := tlr.Top - GetPositionsTopSize - GetFullDaysTopSize;
      Result.Right := trr.Left;
      Result.Bottom := tlr.Top - GetFullDaysTopSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPositionsTopSize: Double;
begin
  Result := 0;
  if (pplTop in PositionsAppearance.Layouts) and (ColumnCount > 0) then
    Result := PositionsAppearance.TopSize;
end;

function TTMSFNCCustomPlanner.GetPositionText(APosition: Integer): String;
var
  p: Double;
begin
  Result := TranslateTextEx(sTMSFNCPlannerPosition) + ' ' + inttostr(APosition);
  case GetDisplayMode of
    pmDay, pmHalfDayPeriod, pmDayPeriod, pmMonth, pmMultiResDay, pmCustom:
    begin
      if (GetDisplayMode = pmMultiResDay) then
        APosition := APosition mod Max(1, Resources.Count);

      if (APosition >= 0) and (APosition <= Resources.Count - 1) then
      begin
        Result := Resources[APosition].Text;
        if Result = '' then
        begin
          Result := Resources[APosition].Name;
          if Result = '' then
            Result := TranslateTextEx(sTMSFNCPlannerPosition) + ' ' + inttostr(APosition);
        end;
      end;
    end;
    pmMultiDayRes:
    begin
      p := IncDay(FDisplayStartTime, APosition mod Max(1, GetNumDays));
      Result := FormatDateTime(GetPositionFormat, p);
    end;
    pmMultiDay:
    begin
      p := IncDay(FDisplayStartTime, APosition);
      Result := FormatDateTime(GetPositionFormat, p);
    end;
    pmMultiMonth:
    begin
      p := IncMonth(FDisplayStartTime, APosition);
      Result := FormatDateTime(GetPositionFormat, p);
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetPreviousDateTime: TDateTime;
begin
  Result := ModeSettings.StartTime;
  case GetDisplayMode of
    pmMultiMonth: Result := IncMonth(Result, -1);
    else
      Result := IncDay(Result, -1)
  end;
end;

function TTMSFNCCustomPlanner.GetResources: TStringList;
var
  I: Integer;
begin
  Result := TStringList.Create;
  case GetDisplayMode of
    pmDay, pmDayPeriod, pmHalfDayPeriod, pmMonth, pmCustom:
    begin
      for I := 0 to ColumnCount - 1 do
        Result.Add(GetPositionResourceText(I));
    end;
    pmMultiResDay, pmMultiDayRes:
    begin
      for I := 0 to Resources.Count - 1 do
        Result.Add(TTMSFNCPlannerResourceOpen(Resources[I]).GetResourceText);
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetResourcesAvailable: Boolean;
begin
  Result := (GetDisplayMode <> pmMultiDay) and (GetDisplayMode <> pmMultiMonth);
end;

{$IFDEF FMXLIB}
function TTMSFNCCustomPlanner.GetSceneDrawingScale: TTMSFNCPlannerSceneDrawingScale;
const
  BaseVector: TPointF = (X: 0; Y: 0);
begin
  if Assigned(Scene) then
    Result.SceneScale := Scene.GetSceneScale
  else
    Result.SceneScale := 1;

  Result.DrawingScale.X := (PointF(1, 0) * AbsoluteMatrix).Distance(BaseVector * AbsoluteMatrix);
  Result.DrawingScale.Y := (PointF(0, 1) * AbsoluteMatrix).Distance(BaseVector * AbsoluteMatrix);
end;
{$ENDIF}

function TTMSFNCCustomPlanner.GetStartTimeSizeHandler: TTMSFNCPlannerSizeHandler;
begin
  if not FStartTimeSizeHandlerCreated then
  begin
    FStartTimeSizePanel := TTMSFNCPlannerSizeHandlerPanel.Create(Self);
    FStartTimeSizePanel.Planner := Self;
    FStartTimeSizePanel.Kind := pshpkStartTime;
    FStartTimeSizeHandlerCreated := True;
  end;

  Result.Background := FStartTimeSizePanel;
end;

function TTMSFNCCustomPlanner.GetTimeLineLeftRect: TRectF;
begin
  Result := inherited GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      Result.Left := Result.Left + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Right := Result.Left + Min(GetTotalRowHeight, (Result.Right - Result.Left) - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize);
      Result.Bottom := Result.Top + GetTimeLineLeftSize;
    end;
    pomVertical:
    begin
      Result.Top := Result.Top + GetPositionsTopSize + GetGroupsTopSize + GetFullDaysTopSize;
      Result.Bottom := Result.Top + Min(GetTotalRowHeight, (Result.Bottom - Result.Top) - GetPositionsBottomSize - GetGroupsBottomSize - GetFullDaysBottomSize);
      Result.Right := Result.Left + GetTimeLineLeftSize;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetTimeLineLeftSize: Double;
begin
  Result := 0;
  if ptlLeft in TimeLineAppearance.Layouts then
    Result := TimeLineAppearance.LeftSize;
end;

function TTMSFNCCustomPlanner.HandleAfterKeyboardEvents: Boolean;
var
  itc: TTMSFNCPlannerItemOpen;
begin
  Result := False;
  if FCallAfterMoveKeyboardEvent then
  begin
    FCallAfterMoveKeyboardEvent := False;
    if (FCallItemIndex >= 0) and (FCallItemIndex <= Items.Count - 1) then
    begin
      itc := TTMSFNCPlannerItemOpen(Items[FCallItemIndex]);
      DoAfterMoveItem(itc, itc.StartTime, itc.EndTime, itc.CallPosition);
      DoItemChanged(itc);
      itc.UpdatingLinked := True;
      CallAfterMoveLinkedItems(itc);
      itc.UpdatingLinked := False;
      Result := True;
    end;
    FCallItemIndex := -1;
  end
  else if FCallAfterSizeKeyboardEvent then
  begin
    FCallAfterSizeKeyboardEvent := False;
    if (FCallItemIndex >= 0) and (FCallItemIndex <= Items.Count - 1) then
    begin
      itc := TTMSFNCPlannerItemOpen(Items[FCallItemIndex]);
      DoAfterSizeItem(itc, itc.StartTime, itc.EndTime, itc.CallPosition);
      DoItemChanged(itc);
      itc.UpdatingLinked := True;
      CallAfterSizeLinkedItems(itc);
      itc.UpdatingLinked := False;
      Result := True;
    end;
    FCallItemIndex := -1;
  end;
end;

function TTMSFNCCustomPlanner.HandleAfterMouseEvents: Boolean;
var
  itc: TTMSFNCPlannerItemOpen;
begin
  Result := False;
  if FCallAfterMoveMouseEvent then
  begin
    FCallAfterMoveMouseEvent := False;
    if (FCallItemIndex >= 0) and (FCallItemIndex <= Items.Count - 1) then
    begin
      itc := TTMSFNCPlannerItemOpen(Items[FCallItemIndex]);
      DoAfterMoveItem(itc, itc.StartTime, itc.EndTime, itc.CallPosition);
      DoItemChanged(itc);
      itc.UpdatingLinked := True;
      CallAfterMoveLinkedItems(itc);
      itc.UpdatingLinked := False;
      Result := True;
    end;
    FCallItemIndex := -1;
  end
  else if FCallAfterSizeMouseEvent then
  begin
    FCallAfterSizeMouseEvent := False;
    if (FCallItemIndex >= 0) and (FCallItemIndex <= Items.Count - 1) then
    begin
      itc := TTMSFNCPlannerItemOpen(Items[FCallItemIndex]);
      DoAfterSizeItem(itc, itc.StartTime, itc.EndTime, itc.CallPosition);
      DoItemChanged(itc);
      itc.UpdatingLinked := True;
      CallAfterSizeLinkedItems(itc);
      itc.UpdatingLinked := False;
      Result := True;
    end;
    FCallItemIndex := -1;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleCellSelection(ASelecting: Boolean = False);
var
  p: Integer;
  sta, ste: TDateTime;
begin
  p := Selection.StartCell.Col;
  sta := ValueToDateTime(RowPositions[Selection.StartCell.Row], p, Selection.StartCell.Row);
  ste := ValueToDateTime(RowPositions[Selection.EndCell.Row + 1], p, Selection.EndCell.Row + 1);
  if ASelecting then
  begin
    DoSelectingCell(Selection.StartCell, Selection.EndCell);
    DoSelectingTime(sta, ste, p);
  end
  else
  begin
    DoSelectCell(Selection.StartCell, Selection.EndCell);
    DoSelectTime(sta, ste, p);
  end;
end;

procedure TTMSFNCCustomPlanner.HandleDateTimeNavigation(
  ADirection: TTMSFNCPlannerNavigationDirection; ACurrentDateTime, ANewDateTime: TDateTime);
var
  b: Boolean;
begin
  b := True;
  DoBeforeNavigateToDateTime(ADirection, ACurrentDateTime, ANewDateTime, b);
  if b then
  begin
    case ADirection of
      pndPrevious:
      begin
        NavigateToPreviousDateTime(ANewDateTime);
        case OrientationMode of
          pomHorizontal: SetVScrollValue(0);
          pomVertical: SetHScrollValue(0);
        end;
      end;
      pndNext:
      begin
        NavigateToNextDateTime(ANewDateTime);
        case OrientationMode of
          pomHorizontal: SetVScrollValue(VerticalScrollBar.Max);
          pomVertical: SetHScrollValue(HorizontalScrollBar.Max);
        end;
      end;
    end;

    DoAfterNavigateToDateTime(ADirection, ACurrentDateTime, ANewDateTime);
  end;
end;

procedure TTMSFNCCustomPlanner.HandleItemDelete(AItem: TTMSFNCPlannerItem; AMode: TTMSFNCPlannerItemDeleteMode);
var
  b: Boolean;
  idx: Integer;
  k: String;
  i: TTMSFNCPlannerItem;
begin
  if Assigned(AItem) then
  begin
    i := AItem.LinkedItem;
    b := AItem.Deletable;
    DoIsItemDeletable(AItem, b);
    DoBeforeDeleteItem(AItem, AMode, b);
    if b then
    begin
      FActiveItem := nil;
      if Assigned(Adapter) then
        Adapter.DeleteItem(AItem);

      FSelectedItems.Remove(AItem);
      idx := AItem.Index;
      k := AItem.DBKey;
      Items.Delete(AItem.Index);
      UpdateSizeHandlers;
      UpdateDeleteHandler;
      DoAfterDeleteItem(idx, k, AMode);
    end;

    if Assigned(i) and Interaction.AutoDeleteLinkedItems then
      HandleItemDelete(i, AMode);
  end;
end;

procedure TTMSFNCCustomPlanner.HandleItemEditing(AItem: TTMSFNCPlannerItem; ACacheItem: TTMSFNCPlannerCacheItem = nil);
var
  ins: Boolean;
  sta, ste: TDateTime;
  p: Integer;
  {$IFNDEF LCLWEBLIB}
  AContext: TRttiContext;
  rt: TRttiType;
  prop: TRttiProperty;
  {$ENDIF}
  t, n: String;
  r: TRectF;
begin
  if Assigned(AItem) and AItem.Editable then
  begin
    sta := AItem.StartTime;
    ste := AItem.EndTime;
    p := ItemToStartCell(AItem).Col;
    case Interaction.UpdateMode of
      pumInplace:
      begin
        if IsFullDayItem(AItem) then
          Exit;

        ins := True;
        DoBeforeOpenInplaceEditor(sta, ste, p, AItem, ins);
        if ins then
        begin
          FInplaceEditorClass := nil;
          DoGetInplaceEditor(sta, ste, p, AItem, FInplaceEditorClass);
          if Assigned(FInplaceEditor) then
          begin
            FInplaceEditor.Free;
            FInplaceEditor := nil;
          end;

          if Assigned(FInplaceEditorClass) then
            FInplaceEditor := FInplaceEditorClass.Create(Self)
          else
          begin
            FInplaceEditor := TTMSFNCPlannerMemo.Create(Self);
            {$IFDEF FMXLIB}
            FInplaceEditor.DisableFocusEffect := True;
            FInplaceEditor.OnApplyStyleLookup := ApplyInplaceEditorStyleLookup;
            {$ENDIF}
            {$IFDEF WEBLIB}
            FInplaceEditor.ShowFocus := False;
            {$ENDIF}
          end;

          r := GetInplaceEditorRect(ACacheItem, AItem);
          if Assigned(FInplaceEditor) then
          begin
            FUpdateItem := AItem.Index;
            FInplaceEditor.Parent := Self;
            {$IFDEF FMXLIB}
            FInplaceEditor.BoundsRect := r;
            {$ENDIF}
            {$IFDEF CMNWEBLIB}
            FInplaceEditor.BoundsRect := Bounds(Round(r.Left), Round(r.Top), Round(r.Right - r.Left), Round(r.Bottom - r.Top));
            {$ENDIF}
            if FInplaceEditor is TMemo then
            begin
              {$IFDEF FMXLIB}
              (FInplaceEditor as TMemo).StyledSettings := [];
              {$ENDIF}
              case Interaction.InplaceEditorMode of
                piemText, piemItem:
                begin
                  (FInplaceEditor as TMemo).Font.Assign(ItemsAppearance.ActiveFont);
                  {$IFDEF FMXLIB}
                  if not AItem.UseDefaultAppearance then
                    (FInplaceEditor as TMemo).FontColor := AItem.ActiveFontColor
                  else
                    (FInplaceEditor as TMemo).FontColor := ItemsAppearance.ActiveFont.Color;
                  {$ENDIF}
                end;
                piemTitle:
                begin
                  (FInplaceEditor as TMemo).Font.Assign(ItemsAppearance.ActiveTitleFont);
                  {$IFDEF FMXLIB}
                  if not AItem.UseDefaultAppearance then
                    (FInplaceEditor as TMemo).FontColor := AItem.ActiveTitleFontColor
                  else
                    (FInplaceEditor as TMemo).FontColor := ItemsAppearance.ActiveTitleFont.Color;
                  {$ENDIF}
                end;
              end;
            end;

            {$IFNDEF LCLWEBLIB}
            AContext := TRttiContext.Create;
            try
              rt := AContext.GetType(FInplaceEditor.ClassInfo);
              prop := rt.GetProperty('Text');
            {$ENDIF}
              case Interaction.InplaceEditorMode of
                piemText, piemItem:
                begin
                  n := AItem.Text;
                  DoGetItemText(AItem, pgtmEditing, n);
                  {$IFNDEF LCLWEBLIB}
                  if Assigned(Prop) then
                    prop.SetValue(FInplaceEditor, n);
                  {$ENDIF}
                  {$IFDEF LCLWEBLIB}
                  if FInplaceEditor is TEdit then
                    (FInplaceEditor as TEdit).Text := n;
                  if FInplaceEditor is TComboBox then
                    (FInplaceEditor as TComboBox).Text := n;
                  if FInplaceEditor is TMemo then
                    (FInplaceEditor as TMemo).Text := n;
                  {$ENDIF}
                end;
                piemTitle:
                begin
                  t := AItem.Title;
                  DoGetItemTitleText(AItem, pgtmEditing, t);
                  {$IFNDEF LCLWEBLIB}
                  if Assigned(Prop) then
                    prop.SetValue(FInplaceEditor, t);
                  {$ENDIF}
                  {$IFDEF LCLWEBLIB}
                  if FInplaceEditor is TEdit then
                    (FInplaceEditor as TEdit).Text := n;
                  if FInplaceEditor is TComboBox then
                    (FInplaceEditor as TComboBox).Text := n;
                  if FInplaceEditor is TMemo then
                    (FInplaceEditor as TMemo).Text := n;
                  {$ENDIF}
                end;
              end;
            {$IFNDEF LCLWEBLIB}
            finally
              AContext.Free;
            end;
            {$ENDIF}

            if FInplaceEditor.CanFocus then
              FInplaceEditor.SetFocus;

            if FInplaceEditor is TMemo then
              (FInplaceEditor as TMemo).SelStart := Length((FInplaceEditor as TMemo).Text);
            FInplaceEditorActive := True;
            AItem.UpdateItem;
          end;

          DoAfterOpenInplaceEditor(sta, ste, p, AItem, FInplaceEditor, r);
        end;
      end;
      pumDialog:
      begin
        ins := True;
        DoBeforeOpenUpdateDialog(sta, ste, p, AItem, ins);
        if ins then
        begin
          t := AItem.Title;
          DoGetItemTitleText(AItem, pgtmEditing, t);
          n := AItem.Text;
          DoGetItemText(AItem, pgtmEditing, n);
          OpenEditingDialog(sta, ste, p, t, n, AItem.Index);
          DoAfterOpenUpdateDialog(sta, ste, p, AItem);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleItemInsert(ADialog: Boolean);
var
  p: Integer;
  sta, ste: TDateTime;
  ins, s: Boolean;
  it: TTMSFNCPlannerItem;
  t, n: String;
begin
  p := Selection.StartCell.Col;
  sta := ValueToDateTime(RowPositions[Selection.StartCell.Row], p, Selection.StartCell.Row);
  ste := ValueToDateTime(RowPositions[Selection.EndCell.Row + 1], p, Selection.EndCell.Row + 1);
  if not ADialog then
  begin
    ins := True;
    t := DefaultItem.Title;
    n := DefaultItem.Text;

    if not HasItem(sta, ste, p) then
    begin
      DoBeforeInsertItem(sta, ste, p, t, n, ins);
      if ins then
      begin
        it := AddOrUpdateItem(PositionToResource(p), sta, ste, t, n);
        DoAfterInsertItem(sta, ste, p, it);
        s := it.Selectable and it.Enabled;
        DoBeforeSelectItem(it, s);
        if s then
        begin
          if Interaction.AutoSelectLinkedItems and Interaction.MultiSelect then
            HandleSelectLinkedItems(it)
          else
            HandleSelectItem(it);

          DoAfterSelectItem(it);
        end;
      end;
    end;
  end
  else
  begin
    ins := True;
    DoBeforeOpenInsertDialog(sta, ste, p, ins);
    if ins then
    begin
      OpenEditingDialog(sta, ste, PositionToResource(p), DefaultItem.Title, DefaultItem.Text);
      DoAfterOpenInsertDialog(sta, ste, p);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleItemKeyboardInteraction(AItem: TTMSFNCPlannerItem; ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState;
  AColValue, ARowValue: Integer; AStartCell, AEndCell: TTMSFNCPlannerCell);
var
  cls, cle, clssave: TTMSFNCPlannerCell;
  res: Integer;
  nst, net: TDateTime;
  c: Boolean;
  ps, pe, difc: Integer;
  rs: Integer;
  b: Boolean;
  duration, deltae, deltas: Double;
  dt: TDateTime;
begin
  if Assigned(AItem) and (ADirection <> idNone) and not IsFullDayItem(AItem) and not Interaction.ReadOnly then
  begin
    cls := AStartCell;
    cle := AEndCell;
    clssave := cls;

    deltae := AItem.EndTime - CellToDateTime(cle);
    deltas := AItem.StartTime - CellToDateTime(cls);

    duration := AItem.EndTime - AItem.StartTime;

    case ADirection of
      idLeft:
      begin
        if (ssShift in AShift) then
        begin
          if (ssCtrl in AShift) and (cls.Col > 0) then
            cls.Col := cls.Col + AColValue
          else if not (ssCtrl in AShift) and (((cle.Row > cls.Row) and (cle.Col - cls.Col > 0)) or ((cle.Row <= cls.Row) and (cle.Col - cls.Col > 1))) then
            cle.Col := cle.Col + AColValue;
        end
        else if (cls.Col > 0) then
        begin
          cls.Col := cls.Col + AColValue;
          cle.Col := cle.Col + AColValue;
        end;
      end;
      idRight:
      begin
        if (ssShift in AShift) then
        begin
          if (ssCtrl in AShift) and (((cle.Row > cls.Row) and (cle.Col - cls.Col > 0)) or ((cle.Row <= cls.Row) and (cle.Col - cls.Col > 1))) then
            cls.Col := cls.Col + AColValue
          else if not (ssCtrl in AShift) and (cle.Col < ColumnCount - 1) then
            cle.Col := cle.Col + AColValue;
        end
        else if (cle.Col < ColumnCount - 1) then
        begin
          cls.Col := cls.Col + AColValue;
          cle.Col := cle.Col + AColValue;
        end;
      end;
      idUp:
      begin
        if ssShift in AShift then
        begin
          if (ssCtrl in AShift) and (cls.Row > 0) then
            cls.Row := cls.Row + ARowValue
          else if not (ssCtrl in AShift) and (cle.Row > 0) and ((cle.Row - cls.Row > 1) or (cle.Col - cls.Col > 0)) then
            cle.Row := cle.Row + ARowValue;
        end
        else if cls.Row > 0 then
        begin
          cls.Row := cls.Row + ARowValue;
          cle.Row := cle.Row + ARowValue;
        end;
      end;
      idDown:
      begin
        if ssShift in AShift then
        begin
          if (ssCtrl in AShift) and (cls.Row < RowCount) and ((cle.Row - cls.Row > 1) or (cle.Col - cls.Col > 0)) then
            cls.Row := cls.Row + ARowValue
          else if not (ssCtrl in AShift) and (cle.Row < RowCount) then
            cle.Row := cle.Row + ARowValue;
        end
        else if cle.Row < RowCount then
        begin
          cls.Row := cls.Row + ARowValue;
          cle.Row := cle.Row + ARowValue;
        end;
      end;
      idRightDown:
      begin
        if (ssShift in AShift) then
        begin
          if (ssCtrl in AShift) and (((cle.Row > cls.Row) and (cle.Col - cls.Col > 0)) or ((cle.Row <= cls.Row) and (cle.Col - cls.Col > 1))) then
            cls.Col := cls.Col + AColValue
          else if not (ssCtrl in AShift) and (cle.Col < ColumnCount - 1) then
            cle.Col := cle.Col + AColValue;
        end
        else if (cle.Col < ColumnCount - 1) then
        begin
          cls.Col := cls.Col + AColValue;
          cle.Col := cle.Col + AColValue;
        end;

        if ssShift in AShift then
        begin
          if (ssCtrl in AShift) and (cls.Row < RowCount) and ((cle.Row - cls.Row > 1) or (cle.Col - cls.Col > 0)) then
            cls.Row := cls.Row + ARowValue
          else if not (ssCtrl in AShift) and (cle.Row < RowCount) then
            cle.Row := cle.Row + ARowValue;
        end
        else if cle.Row < RowCount then
        begin
          cls.Row := cls.Row + ARowValue;
          cle.Row := cle.Row + ARowValue;
        end;
      end;
      idLeftUp:
      begin
        if (ssShift in AShift) then
        begin
          if (ssCtrl in AShift) and (cls.Col > 0) then
            cls.Col := cls.Col + AColValue
          else if not (ssCtrl in AShift) and (((cle.Row > cls.Row) and (cle.Col - cls.Col > 0)) or ((cle.Row <= cls.Row) and (cle.Col - cls.Col > 1))) then
            cle.Col := cle.Col + AColValue;
        end
        else if (cls.Col > 0) then
        begin
          cls.Col := cls.Col + AColValue;
          cle.Col := cle.Col + AColValue;
        end;

        if ssShift in AShift then
        begin
          if (ssCtrl in AShift) and (cls.Row > 0) then
            cls.Row := cls.Row + ARowValue
          else if not (ssCtrl in AShift) and (cle.Row > 0) and ((cle.Row - cls.Row > 1) or (cle.Col - cls.Col > 0)) then
            cle.Row := cle.Row + ARowValue;
        end
        else if cls.Row > 0 then
        begin
          cls.Row := cls.Row + ARowValue;
          cle.Row := cle.Row + ARowValue;
        end;
      end;
    end;

    if (GetDisplayMode = pmMultiDayRes) then
    begin
      ps := PositionToResource(cls.col);
      pe := PositionToResource(cle.col);
      difc := cle.Col - cls.Col;

      if pe <> ps then
      begin
        case ADirection of
          idLeft, idUp, idLeftUp:
          begin
            cls.Col := cls.Col - difc;
            cle.Col := cle.Col - difc;
          end;
          idRight, idDown, idRightDown:
          begin
            cls.Col := cls.Col + difc;
            cle.Col := cle.Col + difc;
          end;
        end;
      end;
    end;

    nst := CellToDateTime(cls);
    net := CellToDateTime(cle);

    res := 0;
    if not AItem.FixedResource then
    begin
      case ADirection of
        idLeft, idUp, idLeftUp:
        begin
          if (ssShift in AShift) and not (ssCtrl in AShift) then
            res := cle.Col
          else
            res := cls.Col;
        end;
        idRight, idDown, idRightDown:
        begin
          if (ssShift in AShift) and (ssCtrl in AShift) then
            res := cls.Col
          else
            res := cle.Col;
        end;
      end;
    end;

    if GetDisplayMode <> pmCustom then
    begin
      if not (ssShift in AShift) then
      begin
        nst := nst + deltas;
        net := nst + duration;
      end
      else
      begin
        if ssCtrl in AShift then
        begin
          dt := CellToDateTime(clssave);

          if (DateTimeToMillisecondsEx(deltas) <> DateTimeToMillisecondsEx(0)) then
            nst := dt;

          net := net + deltae;
        end
        else
        begin
          if (net - nst) < GetDisplayUnitValue then
            net := nst + GetDisplayUnitValue;

          nst := nst + deltas;
        end;
      end;
    end;

    c := False;
    if not HasItem(nst, net, res, AItem.Index) then
    begin
      c := True;
      if not (ssShift in AShift) then
      begin
        c := AItem.Movable;
        DoBeforeMoveItem(AItem, nst, net, res, c);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
        CallBeforeMoveLinkedItems(AItem, nst - AItem.StartTime, net - AItem.EndTime, PositionToResource(res) - AItem.Resource);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
      end
      else
      begin
        c := AItem.Sizeable;
        DoBeforeSizeItem(AItem, nst, net, res, c);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
        CallBeforeSizeLinkedItems(AItem, nst - AItem.StartTime, net - AItem.EndTime, PositionToResource(res) - AItem.Resource);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
      end;
    end;

    if c then
    begin
      rs := PositionToResource(res);
      b := (CompareDateTime(nst, AItem.StartTime) <> EqualsValue) or (CompareDateTime(net, AItem.EndTime) <> EqualsValue) or
        (AItem.Resource <> rs);

      if b then
      begin
        AItem.BeginUpdate;
        AItem.StartTime := nst;
        AItem.EndTime := net;
        if not AItem.FixedResource then
          AItem.Resource := rs;
        AItem.EndUpdate(False);

        if not (ssShift in AShift) then
        begin
          FCallAfterMoveKeyboardEvent := True;
          FCallItemIndex := AItem.Index;
          TTMSFNCPlannerItemOpen(AItem).CallPosition := res;
          DoMoveItem(AItem, nst, net, res);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
          CallMoveLinkedItems(AItem);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
        end
        else
        begin
          FCallAfterSizeKeyboardEvent := True;
          FCallItemIndex := AItem.Index;
          TTMSFNCPlannerItemOpen(AItem).CallPosition := res;
          DoSizeItem(AItem, nst, net, res);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
          CallSizeLinkedItems(AItem);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
        end;
      end;

      case ADirection of
        idLeft, idUp:
        begin
          if (ssShift in AShift) and not (ssCtrl in AShift) then
            Navigate(MakeCell(cle.Col, cle.Row - 1))
          else
            Navigate(cls);
        end;
        idLeftUp:
        begin
          if (ssShift in AShift) and not (ssCtrl in AShift) then
            Navigate(MakeCell(cle.Col - 1, cle.Row - 1))
          else
            Navigate(cls);
        end;
        idRight, idDown:
        begin
          if (ssShift in AShift) and (ssCtrl in AShift) then
            Navigate(cls)
          else
            Navigate(MakeCell(cle.Col, cle.Row - 1))
        end;
        idRightDown:
        begin
          if (ssShift in AShift) and (ssCtrl in AShift) then
            Navigate(cls)
          else
            Navigate(MakeCell(cle.Col - 1, cle.Row - 1))
        end;
      end;

      UpdateSizeHandlers;
      UpdateDeleteHandler;
    end;
  end;
end;

function TTMSFNCCustomPlanner.HandleItemMouseInteraction(
  AItem: TTMSFNCPlannerItem; AStartCell, AEndCell, ANewStartCell,
  ANewEndCell: TTMSFNCPlannerCell; AMode: TTMSFNCPlannerMouseInteractionMode): Boolean;
var
  cls, cle, clssave, clesave: TTMSFNCPlannerCell;
  res: Integer;
  nst, net: TDateTime;
  c: Boolean;
  ps, pe, difc: Integer;
  rs: Integer;
  deltas, deltae, duration: Double;
  dt: TDateTime;
begin
  Result := False;
  if Assigned(AItem) then
  begin
    cls := ANewStartCell;
    cle := ANewEndCell;
    clssave := AStartCell;
    clesave := AEndCell;

    deltas := AItem.StartTime - CellToDateTime(clssave);
    deltae := AItem.EndTime - CellToDateTime(clesave);
    duration := AItem.EndTime - AItem.StartTime;

    if (GetDisplayMode = pmMultiDayRes) then
    begin
      ps := PositionToResource(cls.col);
      pe := PositionToResource(cle.col);
      difc := cle.Col - cls.Col;

      if pe <> ps then
      begin
        if cls.Col <> clssave.Col then
        begin
          cls.Col := cls.Col - difc;
          cle.Col := cle.Col - difc;
        end
        else
        begin
          cls.Col := cls.Col + difc;
          cle.Col := cle.Col + difc;
        end;
      end;
    end;

    nst := CellToDateTime(cls);
    net := CellToDateTime(cle);

    res := 0;
    if not AItem.FixedResource then
    begin
      if cls.Col <> clssave.Col then
        res := cls.Col
      else
        res := cle.Col;
    end;

    if GetDisplayMode <> pmCustom then
    begin
      case AMode of
        pmmMove:
        begin
          nst := nst + deltas;
          net := nst + duration;
        end;
        pmmSizeDown:
        begin
          if (net - nst) < GetDisplayUnitValue then
            net := nst + GetDisplayUnitValue;

          nst := nst + deltas;
        end;
        pmmSizeUp:
        begin
          dt := CellToDateTime(clssave);
          if (DateTimeToMillisecondsEx(deltas) <> DateTimeToMillisecondsEx(0)) then
            nst := dt;

          net := net + deltae;
        end;
      end;
    end;

    c := False;
    if not HasItem(nst, net, res, AItem.Index) then
    begin
      c := True;
      if AMode = pmmMove then
      begin
        c := AItem.Movable;
        DoBeforeMoveItem(AItem, nst, net, res, c);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
        CallBeforeMoveLinkedItems(AItem, nst - AItem.StartTime, net - AItem.EndTime, PositionToResource(res) - AItem.Resource);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
      end
      else
      begin
        c := AItem.Sizeable;
        DoBeforeSizeItem(AItem, nst, net, res, c);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
        CallBeforeSizeLinkedItems(AItem, nst - AItem.StartTime, net - AItem.EndTime, PositionToResource(res) - AItem.Resource);
        TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
      end;
    end;

    if c then
    begin
      rs := PositionToResource(res);
      Result := (CompareDateTime(nst, AItem.StartTime) <> EqualsValue) or (CompareDateTime(net, AItem.EndTime) <> EqualsValue) or
        (AItem.Resource <> rs);

      if Result then
      begin
        AItem.BeginUpdate;
        AItem.StartTime := nst;
        AItem.EndTime := net;
        if not AItem.FixedResource then
          AItem.Resource := rs;
        AItem.EndUpdate(False);

        if AMode = pmmMove then
        begin
          FCallAfterMoveMouseEvent := True;
          FCallItemIndex := AItem.Index;
          TTMSFNCPlannerItemOpen(AItem).CallPosition := res;
          DoMoveItem(AItem, nst, net, res);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
          CallMoveLinkedItems(AItem);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
        end
        else
        begin
          FCallAfterSizeMouseEvent := True;
          FCallItemIndex := AItem.Index;
          TTMSFNCPlannerItemOpen(AItem).CallPosition := res;
          DoSizeItem(AItem, nst, net, res);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := True;
          CallSizeLinkedItems(AItem);
          TTMSFNCPlannerItemOpen(AItem).UpdatingLinked := False;
        end;
      end;
    end;

    if cls.Row < clssave.Row then
      Navigate(cls)
    else
      Navigate(cle);

    UpdateSizeHandlers;
    UpdateDeleteHandler;
  end;
end;

procedure TTMSFNCCustomPlanner.ProcessSelection(ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState; AStepCol, AStepRow: Integer);
var
  prevk, k: TTMSFNCPlannerInteractionDirection;
  prev: TTMSFNCPlannerSelection;
  function CompareSelection(ASelPrev, ASel: TTMSFNCPlannerSelection): Boolean;
  begin
    Result := (ASelPrev.StartCell.Col = ASel.StartCell.Col) and
              (ASelPrev.StartCell.Row = ASel.StartCell.Row) and
              (ASelPrev.EndCell.Col = ASel.EndCell.Col) and
              (ASelPrev.EndCell.Row = ASel.EndCell.Row);
  end;
begin
  k := ADirection;
  if k <> idNone then
  begin
    if not IsCellDisabled(Selection.StartCell) and not IsCellDisabled(Selection.EndCell) then
    begin
      prevk := ADirection;
      ProcessNavigation(ADirection, AShift, AStepCol, AStepRow);
      while (IsCellDisabled(Selection.StartCell) or IsCellDisabled(Selection.EndCell)) do
      begin
        prev := Selection;
        ProcessNavigation(k, AShift, 1, 1);
        if CompareSelection(prev, Selection) then
        begin
          case prevk of
            idLeftUp: k := idRightDown;
            idRightDown: k := idLeftUp;
            idLeft: k := idRight;
            idRight: k := idLeft;
            idUp: k := idDown;
            idDown: k := idUp;
          end;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleSelection(AStartCell,
  AEndCell: TTMSFNCPlannerCell);
begin
  HandleSelectItem(nil);

  AStartCell.Row := Max(0, Min(AStartCell.Row, RowCount - 1));
  AStartCell.Col := Max(0, Min(AStartCell.Col, ColumnCount - 1));

  AEndCell.Row := Max(0, Min(AEndCell.Row, RowCount - 1));
  AEndCell.Col := Max(0, Min(AEndCell.Col, ColumnCount - 1));

  FPrevSelection := FSelection;

  if AStartCell.Row > AEndCell.Row then
  begin
    FSelection.StartCell := AEndCell;
    FSelection.EndCell := AStartCell;
  end
  else
  begin
    FSelection.StartCell := AStartCell;
    FSelection.EndCell := AEndCell;
  end;

  if FPrevSelection.StartCell.Row = Selection.StartCell.Row then
    Navigate(Selection.EndCell)
  else
    Navigate(Selection.StartCell);
end;

procedure TTMSFNCCustomPlanner.HandleSelectItem(AItem: TTMSFNCPlannerItem; AMultiSelect: Boolean = False);
var
  I: Integer;
  it: TTMSFNCPlannerItem;
begin
  if Assigned(AItem) then
  begin
    if (AItem.Collection = nil) then
      Exit;
  end;

  if not AMultiSelect then
    FActiveItem := AItem;

  for I := FSelectedItems.Count - 1 downto 0 do
  begin
    it := FSelectedItems[I];
    if not AMultiSelect then
      FSelectedItems.Remove(it);
    it.UpdateItem;
  end;

  if Assigned(AItem) then
  begin
    if FSelectedItems.IndexOf(AItem) > -1 then
    begin
      if AItem <> FActiveItem then
        FSelectedItems.Remove(AItem)
    end
    else
      FSelectedItems.Add(AItem);

    AItem.UpdateItem;
  end;

  if not Interaction.KeepSelection then
  begin
    FSelection.StartCell := MakeCell(-1, -1);
    FSelection.EndCell := MakeCell(-1, -1);
  end;

  UpdateSizeHandlers;
  UpdateDeleteHandler;
end;

procedure TTMSFNCCustomPlanner.HandleUnselectItem(AItem: TTMSFNCPlannerItem);
begin
  if Assigned(AItem) then
  begin
    if AItem.Collection = nil then
      Exit;
  end;

  FSelectedItems.Remove(AItem);
  if FActiveItem = AItem then
    FActiveItem := nil;
  AItem.UpdateItem;

  UpdateSizeHandlers;
  UpdateDeleteHandler;
end;

procedure TTMSFNCCustomPlanner.HandleSelectLinkedItems(
  AItem: TTMSFNCPlannerItem; AMultiSelect: Boolean);
var
  Item: TTMSFNCPlannerItem;
begin
  Item := AItem;
  if Assigned(Item) then
  begin
    HandleSelectItem(Item, AMultiSelect);
    Item := Item.LinkedItem;
    while Assigned(Item) and (AItem <> Item) do
    begin
      HandleSelectItem(Item, True);
      Item := Item.LinkedItem;
    end;
  end;
end;

function TTMSFNCCustomPlanner.HasDateTimeSub: Boolean;
begin
  Result := False;
  case GetDisplayMode of
    pmDay, pmHalfDayPeriod, pmMultiDay, pmMultiResDay, pmMultiDayRes: Result := True;
  end;

  DoHasDateTimeSub(Result);
end;

procedure TTMSFNCCustomPlanner.DoHasDateTimeSub(var AHasSub: Boolean);
begin
  if Assigned(OnHasDateTimeSub) then
    OnHasDateTimeSub(Self, AHasSub);
end;

function TTMSFNCCustomPlanner.HasItem(AStartTime, AEndTime: TDateTime;
  APosition: Integer; ACompareWithItemIndex: Integer = -1; ACheckOverlap: Boolean = True): Boolean;
var
  I: Integer;
  it: TTMSFNCPlannerItem;
begin
  Result := False;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    if ((ACheckOverlap and (not it.Overlappable or not ModeSettings.OverlappableItems)) or not ACheckOverlap) and IsValidItem(it) and not IsFullDayItem(it) and (it.Index <> ACompareWithItemIndex) and (it.Resource = PositionToResource(APosition)) then
    begin
      if DateTimeInRangeEx(AStartTime, it.StartTime, it.EndTime, False) or DateTimeInRangeEx(AEndTime, it.StartTime, it.EndTime, False)
      or DateTimeInRangeEx(it.StartTime, AStartTime, AEndTime, False) or DateTimeInRangeEx(it.EndTime, AStartTime, AEndTime, False) or
      ((CompareDateTime(it.StartTime, AStartTime) = EqualsValue) and (CompareDateTime(it.EndTime, AEndTime) = EqualsValue)) then
      begin
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.GetHintPopup: TTMSFNCPlannerHintPopup;
begin
  if not FHintPopupCreated then
  begin
    FHintPanel := TTMSFNCControl.Create(Self);
    FHintPanel.AdaptToStyle := AdaptToStyle;
    FHintPanel.AllowFocus := False;
    {$IFDEF FMXLIB}
    FHintPanel.Align := TAlignLayout.Center;
    FHintPanel.HitTest := False;
    {$ENDIF}
    FHintPanel.Width := 350;
    FHintPanel.Height := 300;

    FHintLabel := TTMSFNCHTMLText.Create(Self);
    FHintLabel.Width := 10000;
    FHintLabel.WordWrapping := False;
    FHintLabel.AutoSize := True;
    {$IFDEF FMXLIB}
    FHintLabel.Align := TAlignLayout.Center;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFNDEF LCLLIB}
    FHintLabel.AlignWithMargins := True;
    FHintLabel.Margins.Left := 5;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FHintLabel.BorderSpacing.Left := 5;
    {$ENDIF}
    FHintLabel.Left := 5;
    FHintLabel.Top := 5;
    {$ENDIF}
    FHintLabel.Parent := FHintPanel;

    FHintPopupCreated := True;
  end;

  Result.TextLabel := FHintLabel;
  Result.Panel := FHintPanel;
end;

procedure TTMSFNCCustomPlanner.HorizontalScrollPositionChanged;
begin
  BlockScrollingUpdate := True;
  UpdateDisplay;
  BlockScrollingUpdate := False;
  DoHScroll(GetHScrollValue);
end;

procedure TTMSFNCCustomPlanner.InitSample;
var
  it: TTMSFNCPlannerItem;
begin
  BeginUpdate;
  Items.Clear;

  TimeLine.ActiveStart := 17;
  TimeLine.ActiveEnd := 34;

  ResetToDefaultStyle;

  it := AddOrUpdateItem(Int(Now) + EncodeTime(8, 30, 0, 0), Int(Now) + EncodeTime(11, 00, 0, 0), 'QA', 'Test performance of the new feature.<br><i>And with remote connection.</i>');
  it.UseDefaultAppearance := False;
  {$IFDEF FMXLIB}
  it.Color := $FFFFFFFE;
  it.SelectedColor := $FFA8BCF0;
  it.ActiveFontColor := $FF454545;
  it.FontColor := $FF7A7A7A;
  it.MarkColor := $FF5A81E6;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  it.SelectedColor := $F0BCA8;
  it.FontColor := $7A7A7A;
  it.ActiveFontColor := $454545;
  it.MarkColor := $E6815A;
  it.SelectedColor := $F0BCA8;
  {$ENDIF}
  it.ActiveColor := it.SelectedColor;
  it.SelectedStrokeColor := it.MarkColor;
  it.ActiveStrokeColor := it.MarkColor;
  it.SelectedTitleStrokeColor := it.MarkColor;
  it.ActiveTitleStrokeColor := it.MarkColor;
  it.TitleFontColor := it.ActiveFontColor;
  it.ActiveTitleFontColor := it.ActiveFontColor;
  it.MarkType := [imtTop];
  it.MarkSizeTop := ScalePaintValue(8);
  it.MarkRounding := ScalePaintValue(3);
  it.MarkCorners := [gcTopLeft, gcTopRight];
  it.Rounding := ScalePaintValue(3);

  DefaultItem.StrokeColor := gcDarkgray;
  DefaultItem.Title := 'Item Title';
  DefaultItem.Text := 'Sample text for this item.';
  DefaultItem.TitleFontColor := it.TitleFontColor;

  DefaultItem.Color := it.Color;
  DefaultItem.FontColor := it.FontColor;
  DefaultItem.ActiveFontColor := it.ActiveFontColor;
  DefaultItem.SelectedColor := it.SelectedColor;
  DefaultItem.ActiveColor := it.SelectedColor;
  DefaultItem.SelectedStrokeColor := it.MarkColor;
  DefaultItem.ActiveStrokeColor := it.MarkColor;
  DefaultItem.TitleFontColor := it.ActiveFontColor;
  DefaultItem.ActiveTitleFontColor := it.ActiveFontColor;
  DefaultItem.MarkColor := it.MarkColor;
  DefaultItem.MarkType := [imtTop];
  DefaultItem.MarkSizeTop := ScalePaintValue(8);
  DefaultItem.MarkRounding := ScalePaintValue(3);
  DefaultItem.MarkCorners := [gcTopLeft, gcTopRight];
  DefaultItem.Rounding := ScalePaintValue(3);

  it := AddOrUpdateItem(Int(Now) + EncodeTime(12, 30, 0, 0), Int(Now) + EncodeTime(14, 15, 0, 0), 'Documentation', 'Write the developers guide and <a href="https://www.tmssoftware.com">FAQ</a>');
  it.UseDefaultAppearance := False;
  {$IFDEF FMXLIB}
  it.Color := $FFFFFFFE;
  it.SelectedColor := $FFD3F2D2;
  it.ActiveFontColor := $FF454545;
  it.FontColor := $FF7A7A7A;
  it.MarkColor := $FFB0EDA8;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  it.Color := $FEFFFF;
  it.FontColor := $7A7A7A;
  it.ActiveFontColor := $454545;
  it.MarkColor := $A8EDB0;
  it.SelectedColor := $D2F2D3;
  {$ENDIF}
  it.ActiveColor := it.SelectedColor;
  it.SelectedStrokeColor := it.MarkColor;
  it.ActiveStrokeColor := it.MarkColor;
  it.SelectedTitleStrokeColor := it.MarkColor;
  it.ActiveTitleStrokeColor := it.MarkColor;
  it.TitleFontColor := it.ActiveFontColor;
  it.ActiveTitleFontColor := it.ActiveFontColor;
  it.MarkType := [imtTop];
  it.MarkSizeTop := ScalePaintValue(8);
  it.MarkRounding := ScalePaintValue(3);
  it.MarkCorners := [gcTopLeft, gcTopRight];
  it.Rounding := ScalePaintValue(3);

  it := AddOrUpdateItem(Int(Now) + 1 + EncodeTime(11, 00, 0, 0), Int(Now) + 1 + EncodeTime(15, 30, 0, 0), 'Bugfix 376', 'Fix <u>high priority</u> issues documented by customer.');
  it.UseDefaultAppearance := False;
  {$IFDEF FMXLIB}
  it.Color := $FFFFFFFE;
  it.SelectedColor := $FFF19DA7;
  it.ActiveFontColor := $FF454545;
  it.FontColor := $FF7A7A7A;
  it.MarkColor := $FFEE4353;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  it.Color := $FEFFFF;
  it.FontColor := $7A7A7A;
  it.ActiveFontColor := $454545;
  it.MarkColor := $5343EE;
  it.SelectedColor := $A79DF1;
  {$ENDIF}
  it.ActiveColor := it.SelectedColor;
  it.SelectedStrokeColor := it.MarkColor;
  it.ActiveStrokeColor := it.MarkColor;
  it.SelectedTitleStrokeColor := it.MarkColor;
  it.ActiveTitleStrokeColor := it.MarkColor;
  it.TitleFontColor := it.ActiveFontColor;
  it.ActiveTitleFontColor := it.ActiveFontColor;
  it.MarkType := [imtTop];
  it.MarkSizeTop := ScalePaintValue(8);
  it.MarkRounding := ScalePaintValue(3);
  it.MarkCorners := [gcTopLeft, gcTopRight];
  it.Rounding := ScalePaintValue(3);

  it := AddOrUpdateItem(Int(Now) + 2 + EncodeTime(8, 30, 0, 0), Int(Now) + 2 + EncodeTime(11, 0, 0, 0), 'Customer Update', 'Get in contact with the customer to let him know how far we are on developing the newly requested items.');
  it.UseDefaultAppearance := False;
  {$IFDEF FMXLIB}
  it.Color := $FFFFFFFE;
  it.SelectedColor := $FFAAE9F5;
  it.ActiveFontColor := $FF454545;
  it.FontColor := $FF7A7A7A;
  it.MarkColor := $FF5FDAEF;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  it.Color := $FEFFFF;
  it.FontColor := $7A7A7A;
  it.ActiveFontColor := $454545;
  it.MarkColor := $EFDA5F;
  it.SelectedColor := $F5E9AA;
  {$ENDIF}
  it.ActiveColor := it.SelectedColor;
  it.SelectedStrokeColor := it.MarkColor;
  it.ActiveStrokeColor := it.MarkColor;
  it.SelectedTitleStrokeColor := it.MarkColor;
  it.ActiveTitleStrokeColor := it.MarkColor;
  it.TitleFontColor := it.ActiveFontColor;
  it.ActiveTitleFontColor := it.ActiveFontColor;
  it.MarkType := [imtTop];
  it.MarkSizeTop := ScalePaintValue(8);
  it.MarkRounding := ScalePaintValue(3);
  it.MarkCorners := [gcTopLeft, gcTopRight];
  it.Rounding := ScalePaintValue(3);


  it := AddOrUpdateItem(Int(Now) + 2 + EncodeTime(12, 30, 0, 0), Int(Now) + 2 + EncodeTime(16, 0, 0, 0), 'Bugfix 374', 'Issue when using non-alphanumeric characters.');
  it.UseDefaultAppearance := False;
  {$IFDEF FMXLIB}
  it.Color := $FFFFFFFE;
  it.SelectedColor := $FFF2E198;
  it.ActiveFontColor := $FF454545;
  it.FontColor := $FF7A7A7A;
  it.MarkColor := $FFF0CA35;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  it.Color := $FEFFFF;
  it.FontColor := $7A7A7A;
  it.ActiveFontColor := $454545;
  it.MarkColor := $35CAF0;
  it.SelectedColor := $98E1F2;
  {$ENDIF}
  it.ActiveColor := it.SelectedColor;
  it.SelectedStrokeColor := it.MarkColor;
  it.ActiveStrokeColor := it.MarkColor;
  it.SelectedTitleStrokeColor := it.MarkColor;
  it.ActiveTitleStrokeColor := it.MarkColor;
  it.TitleFontColor := it.ActiveFontColor;
  it.ActiveTitleFontColor := it.ActiveFontColor;
  it.MarkType := [imtTop];
  it.MarkSizeTop := ScalePaintValue(8);
  it.MarkRounding := ScalePaintValue(3);
  it.MarkCorners := [gcTopLeft, gcTopRight];
  it.Rounding := ScalePaintValue(3);

  Resources.Add.Text := 'David';
  Resources.Add.Text := 'Marie';
  Resources.Add.Text := 'Carol';

  EndUpdate;
  TimeLine.ViewStart := Int(Now) + EncodeTime(8, 0, 0, 0);
end;

procedure TTMSFNCCustomPlanner.EditingDialogCancel(Sender: TObject);
begin
  CloseEditingDialog(True);
end;

procedure TTMSFNCCustomPlanner.EditingDialogRemove(Sender: TObject);
begin
  CloseEditingDialogAndRemoveItem;
end;

procedure TTMSFNCCustomPlanner.EditingDialogValidate(Sender: TObject);
begin
  CloseEditingDialog(False);
end;

procedure TTMSFNCCustomPlanner.EditItem(AItemIndex: Integer);
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    HandleItemEditing(Items[AItemIndex]);
end;

procedure TTMSFNCCustomPlanner.EditItem(AItem: TTMSFNCPlannerItem);
begin
  HandleItemEditing(AItem);
end;

procedure TTMSFNCCustomPlanner.EndDateEditChanged(Sender: TObject);
var
  dt, dte: TDateTime;
begin
  if Assigned(FStartDateEdit) and Assigned(FStartTimeEdit) and Assigned(FEndDateEdit) and Assigned(FStartDateEdit) then
  begin
    dt := FStartDateEdit.Date + FStartTimeEdit.Time;
    dte := FEndDateEdit.Date + FEndTimeEdit.Time;
    if CompareDateTime(dt + IncMilliSecond(0, 1), dte) = GreaterThanValue then
      FEndDateEdit.Date := FDialogEndDate
    else
      FDialogEndDate := FEndDateEdit.Date;
  end;
end;

procedure TTMSFNCCustomPlanner.EndTimeEditChanged(Sender: TObject);
var
  dt, dte: TDateTime;
begin
  if Assigned(FStartDateEdit) and Assigned(FStartTimeEdit) and Assigned(FEndDateEdit) and Assigned(FStartDateEdit) then
  begin
    dt := FStartDateEdit.Date + FStartTimeEdit.Time;
    dte := FEndDateEdit.Date + FEndTimeEdit.Time;
    if CompareDateTime(dt + IncMilliSecond(0, 1), dte) = GreaterThanValue then
      FEndTimeEdit.Time := FDialogEndTime
    else
      FDialogEndTime := FEndTimeEdit.Time;
  end;
end;

function TTMSFNCCustomPlanner.FindFirstItem(AStartTime, AEndTime: TDateTime;
  APosition: Integer): TTMSFNCPlannerItem;
var
  I: Integer;
  it: TTMSFNCPlannerItem;
begin
  Result := nil;
  FFindItemIndex := 0;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    if IsValidItem(it) and not IsFullDayItem(it) and (it.Resource = PositionToResource(APosition)) then
    begin
      if DateTimeInRangeEx(AStartTime, it.StartTime, it.EndTime, False) or DateTimeInRangeEx(AEndTime, it.StartTime, it.EndTime, False)
      or DateTimeInRangeEx(it.StartTime, AStartTime, AEndTime, False) or DateTimeInRangeEx(it.EndTime, AStartTime, AEndTime, False) or
      ((CompareDateTime(it.StartTime, AStartTime) = EqualsValue) and (CompareDateTime(it.EndTime, AEndTime) = EqualsValue)) then
      begin
        FFindItemIndex := it.Index;
        Result := it;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.FindItemLinkedTo(AItem: TTMSFNCPlannerItem): TTMSFNCPlannerItem;
var
  i: integer;
begin
  Result := nil;
  for I := 0 to Items.Count - 1 do
  begin
    if (Items[I].LinkedItem = AItem) then
    begin
      Result := Items[I];
      Break;
    end;
  end;
end;

function TTMSFNCCustomPlanner.FindItemWithDBKey(
  ADBKey: String): TTMSFNCPlannerItem;
var
  i: Integer;
begin
  Result := nil;
  for i := 0 to Items.Count - 1 do
  begin
    if Items[i].DBKey = ADBKey then
    begin
      Result := Items[i];
      Break;
    end;
  end;
end;

function TTMSFNCCustomPlanner.FindNextItem(AStartTime, AEndTime: TDateTime;
  APosition: Integer): TTMSFNCPlannerItem;
var
  I: Integer;
  it: TTMSFNCPlannerItem;
begin
  Result := nil;
  for I := FFindItemIndex + 1 to Items.Count - 1 do
  begin
    it := Items[I];
    if IsValidItem(it) and not IsFullDayItem(it) and (it.Resource = PositionToResource(APosition)) then
    begin
      if DateTimeInRangeEx(AStartTime, it.StartTime, it.EndTime, False) or DateTimeInRangeEx(AEndTime, it.StartTime, it.EndTime, False)
      or DateTimeInRangeEx(it.StartTime, AStartTime, AEndTime, False) or DateTimeInRangeEx(it.EndTime, AStartTime, AEndTime, False) or
      ((CompareDateTime(it.StartTime, AStartTime) = EqualsValue) and (CompareDateTime(it.EndTime, AEndTime) = EqualsValue)) then
      begin
        FFindItemIndex := it.Index;
        Result := it;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.IsCellDisabled(
  ACell: TTMSFNCPlannerCell): Boolean;
begin
  Result := IsDateTimeDisabled(CellToDateTime(ACell), ACell.Col);
end;

function TTMSFNCCustomPlanner.IsCellInActive(
  ACell: TTMSFNCPlannerCell): Boolean;
begin
  Result := IsDateTimeInActive(CellToDateTime(ACell), ACell.Col);
end;

function TTMSFNCCustomPlanner.IsDateTimeDisabled(ADateTime: TDateTime;
  APosition: Integer): Boolean;
begin
  Result := False;
  case GetDisplayMode of
    pmMultiDay, pmMultiMonth: Result := not (DateTimeToPosition(ADateTime, False, False) = APosition);
    pmMultiDayRes: Result := not (DateTimeToPosition(ADateTime, False, False) = APosition mod Max(1, GetNumDays));
    pmMultiResDay: Result := not (DateTimeToPosition(ADateTime, False, False) div Max(1, Resources.Count) = APosition div Max(1, Resources.Count));
  end;

  DoIsDateTimeDisabled(ADateTime, APosition, Result);
end;

function TTMSFNCCustomPlanner.IsDateTimeInActive(ADateTime: TDateTime;
  APosition: Integer): Boolean;
begin
  Result := TTMSFNCPlannerInActiveDay(DayOfTheWeek(ADateTime) - 1) in ModeSettings.InActiveDays;
  if not Result then
  begin
    case GetDisplayMode of
      pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes:
      begin
        Result := (CompareDateTime(ADateTime, CalculatePositionDateTime(FActiveStartTime, APosition)) = LessThanValue) or
          (CompareDateTime(ADateTime, CalculatePositionDateTime(FActiveEndTime, APosition)) in [EqualsValue, GreaterThanValue]);
      end;
    end;
  end;
  DoIsDateTimeInActive(ADateTime, APosition, Result);
end;

function TTMSFNCCustomPlanner.IsDateTimeSub(ADateTime: TDateTime): Boolean;
begin
  Result := False;
  case GetDisplayMode of
    pmDay, pmMonth, pmDayPeriod, pmHalfDayPeriod: ADateTime := ADateTime - GetDisplayOffsetValue;
  end;
  case GetDisplayMode of
    pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes: Result := MinuteOf(ADateTime) + SecondOf(ADateTime) + MilliSecondOf(ADateTime) > 0;
    pmHalfDayPeriod, pmMonth: Result := HourOf(ADateTime) + MinuteOf(ADateTime) + SecondOf(ADateTime) + MilliSecondOf(ADateTime) > 0;
  end;

  DoIsDateTimeSub(ADateTime, Result);
end;

function TTMSFNCCustomPlanner.IsEditing: Boolean;
begin
  Result := FInplaceEditorActive or FEditorDialogActive;
end;

function TTMSFNCCustomPlanner.IsFullDayItem(AItem: TTMSFNCPlannerItem): Boolean;
begin
  Result := AItem.FullDay and (AItem.FullDayLayout <> []) and IsFullDayMode
   and ((Int(AItem.EndTime + 1)) - Int(AItem.StartTime) >= 1);
end;

function TTMSFNCCustomPlanner.IsValidItem(AItem: TTMSFNCPlannerItem): Boolean;
var
  rest, rese: Integer;
  st, et: TDateTime;
begin
  Result := False;
  if Assigned(AItem) then
  begin
    if IsFullDayItem(AItem) then
    begin
      st := Int(AItem.StartTime);
      et := Int(AItem.EndTime) + 1;
    end
    else
    begin
      st := AItem.StartTime;
      et := AItem.EndTime;
    end;

    case GetDisplayMode of
      pmDay, pmDayPeriod, pmHalfDayPeriod, pmMonth, pmCustom:
      begin
        rest := AItem.Resource;
        rese := AItem.Resource;
        Result := AItem.Visible and (et - st > 0) and (rest >= 0)
          and (rest <= ColumnCount - 1) and (rese >= 0) and (rese <= ColumnCount - 1);
      end;
      pmMultiDay, pmMultiMonth, pmMultiDayRes, pmMultiResDay:
      begin
        rest := DateTimeToPosition(st, False, False);
        rese := DateTimeToPosition(et, True, False);
        case GetDisplayMode of
          pmMultiResDay:
          begin
            rest := rest + AItem.Resource;
            rese := rese + AItem.Resource;
          end;
          pmMultiDayRes:
          begin
            rest := rest + (AItem.Resource * Max(1, GetNumDays));
            rese := rese + (AItem.Resource * Max(1, GetNumDays));
          end;
        end;
        Result := AItem.Visible and (et - st > 0) and not (((rest < 0) and (rese < 0)) or ((rest > ColumnCount - 1) and (rese > ColumnCount -1)));
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.ItemToStartCell(
  AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCell;
begin
  if Assigned(AItem) then
  begin
    Result := DateTimeToCell(AItem.StartTime);
    case GetDisplayMode of
      pmMultiMonth, pmMultiDay:;
      pmMultiDayRes: Result.Col := Result.Col + (AItem.Resource * Max(1, GetNumDays))
      else
        Result.Col := Result.Col + AItem.Resource;
    end;
  end;
end;

function TTMSFNCCustomPlanner.ItemToEndCell(
  AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCell;
begin
  if Assigned(AItem) then
  begin
    Result := DateTimeToCell(AItem.EndTime, True);
    case GetDisplayMode of
      pmMultiMonth, pmMultiDay:;
      pmMultiDayRes: Result.Col := Result.Col + (AItem.Resource * Max(1, GetNumDays))
      else
        Result.Col := Result.Col + AItem.Resource;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  k: TTMSFNCPlannerInteractionDirection;
  sr, sc: Integer;
  cls, cle: TTMSFNCPlannerCell;
  I: Integer;
  r: TRectF;
begin
  inherited;

  if FInplaceEditorClosed then
    Exit;

  if Assigned(FActiveItem) then
  begin
    k := idNone;
    case OrientationMode of
      pomHorizontal:
      begin
        case Key of
          KEY_UP: k := idLeft;
          KEY_DOWN: k := idRight;
          KEY_LEFT: k := idUp;
          KEY_RIGHT: k := idDown;
        end;
      end;
      pomVertical:
      begin
        case Key of
          KEY_LEFT: k := idLeft;
          KEY_RIGHT: k := idRight;
          KEY_UP: k := idUp;
          KEY_DOWN: k := idDown;
        end;
      end;
    end;

    cls := ItemToStartCell(FActiveItem);
    cle := ItemToEndCell(FActiveItem);

    case k of
      idLeft: HandleItemKeyboardInteraction(FActiveItem, k, Shift, -1, 0, cls, cle);
      idRight: HandleItemKeyboardInteraction(FActiveItem, k, Shift, 1, 0, cls, cle);
      idUp: HandleItemKeyboardInteraction(FActiveItem, k, Shift, 0, -1, cls, cle);
      idDown: HandleItemKeyboardInteraction(FActiveItem, k, Shift, 0, 1, cls, cle);
    end;

    case Key of
      KEY_DELETE:
      begin
        if Interaction.KeyboardDelete and not Interaction.ReadOnly then
        begin
          for I := FSelectedItems.Count - 1 downto 0 do
            HandleItemDelete(FSelectedItems[I], pidmKeyboard);
        end;
      end;
    end;
  end
  else
  begin
    k := idNone;

    if Interaction.KeyboardMode = pkmDefault then
    begin
      case OrientationMode of
        pomHorizontal:
        begin
          case Key of
            KEY_UP, KEY_PRIOR: k := idLeft;
            KEY_DOWN, KEY_NEXT: k := idRight;
            KEY_LEFT, KEY_HOME: k := idUp;
            KEY_RIGHT, KEY_END: k := idDown;
          end;
        end;
        pomVertical:
        begin
          case Key of
            KEY_LEFT, KEY_HOME: k := idLeft;
            KEY_RIGHT, KEY_END: k := idRight;
            KEY_UP, KEY_PRIOR: k := idUp;
            KEY_DOWN, KEY_NEXT: k := idDown;
          end;
        end;
      end;

      sr := 1;
      sc := 1;
      case Key of
        KEY_PRIOR, KEY_NEXT: sr := StopRow - StartRow;
        KEY_HOME: sc := Selection.StartCell.Col;
        KEY_END: sc := ColumnCount - 1 - Selection.StartCell.Col;
      end;

      ProcessSelection(k, Shift, sc, sr);

      case k of
        idLeft, idRight, idUp, idDown: HandleCellSelection(True);
      end;
    end
    else
    begin
      if ssCtrl in Shift then
      begin
        r := GetContentRect;

        sc := StartCol;
        sr := StartRow;

        case OrientationMode of
          pomHorizontal:
          begin
            case Key of
              KEY_UP: Navigate(MakeCell(sc - 1, sr), True, False, True);
              KEY_DOWN: Navigate(MakeCell(sc + 1, sr), True, False, True);
              KEY_LEFT: Navigate(MakeCell(sc, sr - 1), True, True, False);
              KEY_RIGHT: Navigate(MakeCell(sc, sr + 1), True, True, False);
              KEY_PRIOR: Navigate(MakeCell(sc - (StopCol - StartCol), sr), True, False, True);
              KEY_NEXT: Navigate(MakeCell(sc + (StopCol - StartCol), sr), True, False, True);
              KEY_HOME: Navigate(MakeCell(0, 0), True);
              KEY_END: Navigate(MakeCell(ColumnCount - 1, RowCount - 1), True);
            end;
          end;
          pomVertical:
          begin
            case Key of
              KEY_UP: Navigate(MakeCell(sc, sr - 1), True, True, False);
              KEY_DOWN: Navigate(MakeCell(sc, sr + 1), True, True, False);
              KEY_LEFT: Navigate(MakeCell(sc - 1, sr), True, False, True);
              KEY_RIGHT: Navigate(MakeCell(sc + 1, sr), True, False, True);
              KEY_PRIOR: Navigate(MakeCell(sc, sr - (StopRow - StartRow)), True, True, False);
              KEY_NEXT: Navigate(MakeCell(sc, sr + (StopRow - StartRow)), True, True, False);
              KEY_HOME: Navigate(MakeCell(0, 0), True);
              KEY_END: Navigate(MakeCell(ColumnCount - 1, RowCount - 1), True);
            end;
          end;
        end;
      end
      else
      begin

        case OrientationMode of
          pomHorizontal:
          begin
            case Key of
              KEY_LEFT: k := idUp;
              KEY_HOME: k := idLeftUp;
              KEY_END: k := idRightDown;
              KEY_RIGHT: k := idDown;
              KEY_UP, KEY_PRIOR: k := idLeft;
              KEY_DOWN, KEY_NEXT: k := idRight;
            end;
          end;
          pomVertical:
          begin
            case Key of
              KEY_LEFT: k := idLeft;
              KEY_HOME: k := idLeftUp;
              KEY_END: k := idRightDown;
              KEY_RIGHT: k := idRight;
              KEY_UP, KEY_PRIOR: k := idUp;
              KEY_DOWN, KEY_NEXT: k := idDown;
            end;
          end;
        end;

        sr := 1;
        sc := 1;

        case OrientationMode of
          pomHorizontal:
          begin
            case Key of
              KEY_PRIOR, KEY_NEXT: sc := StopCol - StartCol;
              KEY_HOME:
              begin
                sr := Selection.StartCell.Row;
                sc := Selection.StartCell.Col;
              end;
              KEY_END:
              begin
                sr := RowCount - 1 - Selection.StartCell.Row;
                sc := ColumnCount - 1 - Selection.StartCell.Col;
              end;
            end;
          end;
          pomVertical:
          begin
            case Key of
              KEY_PRIOR, KEY_NEXT: sr := StopRow - StartRow;
              KEY_HOME:
              begin
                sr := Selection.StartCell.Row;
                sc := Selection.StartCell.Col;
              end;
              KEY_END:
              begin
                sr := RowCount - 1 - Selection.StartCell.Row;
                sc := ColumnCount - 1 - Selection.StartCell.Col;
              end;
            end;

          end;
        end;

        ProcessSelection(k, Shift, sc, sr);

        case k of
          idLeft, idRight, idUp, idDown, idLeftUp, idRightDown: HandleCellSelection(True);
        end;
      end;
    end;

    case Key of
      KEY_INSERT:
      begin
        if not Interaction.ReadOnly then
        begin
          case Interaction.KeyboardInsertMode of
            pkimSelection: HandleItemInsert(False);
          end;
        end;
      end;
    end;
  end;

  if ((ssShift in Shift) or (ssCtrl in Shift)) and not FDrawItemHelpers then
  begin
    FDrawItemHelpers := True;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPlanner.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if FInplaceEditorClosed then
  begin
    FInplaceEditorClosed := False;
    Exit;
  end;

  if HandleAfterKeyboardEvents and Assigned(FActiveItem) then
    DoAfterItemChanged(FActiveItem);

  if FCloseWithDialogKey then
  begin
    FCloseWithDialogKey := False;
    Exit;
  end;

  if Assigned(FActiveItem) then
  begin
    if Interaction.KeyboardEdit and not Interaction.ReadOnly then
    begin
      case Key of
        KEY_F2, KEY_RETURN, KEY_SPACE: HandleItemEditing(FActiveItem);
      end;
    end;
  end
  else
  begin
    case Key of
      KEY_UP, KEY_DOWN, KEY_RIGHT, KEY_LEFT, KEY_HOME, KEY_PRIOR, KEY_NEXT, KEY_END:
      begin
        if (not (ssCtrl in Shift) and (Interaction.KeyboardMode = pkmGrid)) or (Interaction.KeyboardMode = pkmDefault) then
          HandleCellSelection;
      end;
      KEY_INSERT:
      begin
        if not Interaction.ReadOnly then
        begin
          case Interaction.KeyboardInsertMode of
            pkimSelectionDialog: HandleItemInsert(True);
          end;
        end;
      end;
    end;
  end;

  if FDrawItemHelpers and (Shift = []) then
  begin
    FDrawItemHelpers := False;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPlanner.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  if GetUpdateCount > 0 then
    Exit;

  AGraphics.BitmapContainer := BitmapContainer;
  DrawEmptySpaces(AGraphics);
  DrawGrid(AGraphics);
  DrawSelection(AGraphics);
  if TimeLine.CurrentTimePosition = pctpUnderItems then
    DrawCurrentTimeInGrid(AGraphics);
  DrawItems(AGraphics);
  if TimeLine.CurrentTimePosition = pctpOverItems then
    DrawCurrentTimeInGrid(AGraphics);
  DrawPositions(AGraphics);
  DrawTimeLine(AGraphics);
  DrawGroups(AGraphics);
  DrawFullDays(AGraphics);
  DrawFullDaysItems(AGraphics);
  DrawBorders(AGraphics);
  DrawNavigationButtons(AGraphics);
end;

procedure TTMSFNCCustomPlanner.RemoveAllItemsFromCache;
begin
  if Assigned(FItemCache) then
    FItemCache.Clear;
end;

procedure TTMSFNCCustomPlanner.RemoveLinkedItem(
  AItem: TTMSFNCPlannerItem);
var
  I: Integer;
begin
  if csDestroying in ComponentState then
    Exit;

  for I := 0 to Items.Count - 1 do
  begin
    if Items[I].LinkedItem = AItem then
      Items[I].LinkedItem := nil;
  end;
end;

procedure TTMSFNCCustomPlanner.RemoveItemsFromCache(AList: TTMSFNCPlannerCacheItemList; APosition: Integer = -1);
var
  I: Integer;
  it: TTMSFNCPlannerCacheItem;
begin
  if Assigned(AList) then
  begin
    for I := AList.Count - 1 downto 0 do
    begin
      it := AList[I];
      if Assigned(it) and ((APosition = -1) or (APosition = it.Position)) then
      begin
        if Assigned(FItemDisplay) then
          FItemDisplay.Remove(it);
        if Assigned(FItemCache) then
          FItemCache.Remove(it);
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.ResourceToPosition(AResource: Integer): Integer;
begin
  case GetDisplayMode of
    pmMultiDayRes:
    begin
      Result := AResource * Max(1, GetNumDays);
    end;
    else
      Result := AResource;
  end;
end;

function TTMSFNCCustomPlanner.PositionToDateTime(APosition: Integer): TDateTime;
begin
  Result := FDisplayStartTime;
  case GetDisplayMode of
    pmMultiDay: Result := IncDay(Result, APosition);
    pmMultiMonth: Result := IncMonth(Result, APosition);
    pmMultiDayRes: Result := IncDay(Result, APosition mod Max(1, GetNumDays));
    pmMultiResDay: Result := IncDay(Result, APosition div Max(1, Resources.Count));
  end;
end;

function TTMSFNCCustomPlanner.PositionToResource(APosition: Integer): Integer;
begin
  case GetDisplayMode of
    pmMultiDayRes: Result := APosition div Max(1, GetNumDays);
    pmMultiResDay: Result := APosition mod Max(1, Resources.Count);
    else
      Result := APosition;
  end;
end;

procedure TTMSFNCCustomPlanner.ProcessNavigation(ADirection: TTMSFNCPlannerInteractionDirection; AShift: TShiftState; AStepCol, AStepRow: Integer);
begin
  case ADirection of
    idLeftUp:
    begin
      if (Selection.StartCell.Row > 0) then
      begin
        if (FPrevSelection.StartCell.Row = Selection.StartCell.Row) then
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row - AStepRow))
          else
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.EndCell.Col, Selection.StartCell.Row - AStepRow));
        end
        else
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.StartCell.Col, Selection.EndCell.Row))
          else
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow));
        end;
      end;

      if Selection.StartCell.Col > 0 then
      begin
        if ssShift in AShift then
          SelectCells(MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row))
        else
        begin
          if FPrevSelection.EndCell.Row = Selection.EndCell.Row then
            SelectCells(MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row), MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row), MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row));
        end;
      end;
    end;
    idLeft:
    begin
      if Selection.StartCell.Col > 0 then
      begin
        if ssShift in AShift then
          SelectCells(MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row))
        else
        begin
          if FPrevSelection.EndCell.Row = Selection.EndCell.Row then
            SelectCells(MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row), MakeCell(Selection.StartCell.Col - AStepCol, Selection.StartCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row), MakeCell(Selection.EndCell.Col - AStepCol, Selection.EndCell.Row));
        end;
      end;
    end;
    idRight:
    begin
      if Selection.EndCell.Col < ColumnCount - 1 then
      begin
        if ssShift in AShift then
          SelectCells(MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row))
        else
        begin
          if FPrevSelection.EndCell.Row = Selection.EndCell.Row then
            SelectCells(MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row), MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row), MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row))
        end;
      end;
    end;
    idUp:
    begin
      if (Selection.StartCell.Row > 0) then
      begin
        if (FPrevSelection.StartCell.Row = Selection.StartCell.Row) then
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row - AStepRow))
          else
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.EndCell.Col, Selection.StartCell.Row - AStepRow));
        end
        else
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.StartCell.Col, Selection.EndCell.Row))
          else
            SelectCells(MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow), MakeCell(Selection.StartCell.Col, Selection.StartCell.Row - AStepRow));
        end;
      end;
    end;
    idRightDown:
    begin
      if (Selection.EndCell.Row < RowCount - 1) then
      begin
        if (FPrevSelection.EndCell.Row = Selection.EndCell.Row) then
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.StartCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow));
        end
        else
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow))
          else
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow));
        end;
      end;

      if Selection.EndCell.Col < ColumnCount - 1 then
      begin
        if ssShift in AShift then
          SelectCells(MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row))
        else
        begin
          if FPrevSelection.EndCell.Row = Selection.EndCell.Row then
            SelectCells(MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row), MakeCell(Selection.StartCell.Col + AStepCol, Selection.StartCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row), MakeCell(Selection.EndCell.Col + AStepCol, Selection.EndCell.Row))
        end;
      end;
    end;
    idDown:
    begin
      if (Selection.EndCell.Row < RowCount - 1) then
      begin
        if (FPrevSelection.EndCell.Row = Selection.EndCell.Row) then
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.StartCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row))
          else
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow));
        end
        else
        begin
          if ssShift in AShift then
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.StartCell.Row), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow))
          else
            SelectCells(MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow), MakeCell(Selection.EndCell.Col, Selection.EndCell.Row + AStepRow));
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.ProcessNavigationButtonsDown(X, Y: Single);
begin
  FNavigationButtonDown := False;
  if PtInRectEx(GetTopLeftNavigationButtonRect, PointF(X, Y)) then
  begin
    FNavigationButtonDown := True;
    FTopLeftNavigationButtonState := pnbsDown;
  end;

  if PtInRectEx(GetTopRightNavigationButtonRect, PointF(X, Y)) then
  begin
    FNavigationButtonDown := True;
    FTopRightNavigationButtonState := pnbsDown;
  end;

  if PtInRectEx(GetBottomLeftNavigationButtonRect, PointF(X, Y)) then
  begin
    FNavigationButtonDown := True;
    FBottomLeftNavigationButtonState := pnbsDown;
  end;

  if PtInRectEx(GetBottomRightNavigationButtonRect, PointF(X, Y)) then
  begin
    FNavigationButtonDown := True;
    FBottomRightNavigationButtonState := pnbsDown;
  end;

  if FNavigationButtonDown then
    Invalidate;
end;

procedure TTMSFNCCustomPlanner.ProcessNavigationButtonsMove(X, Y: Single);
var
  ch: Boolean;
  tlnb, trnb, blnb, brnb: TTMSFNCPlannerNavigationButtonState;
begin
  inherited;

  tlnb := FTopLeftNavigationButtonState;
  if PtInRectEx(GetTopLeftNavigationButtonRect, PointF(X, Y)) then
  begin
    if FNavigationButtonDown then
      FTopLeftNavigationButtonState := pnbsDown
    else
      FTopLeftNavigationButtonState := pnbsHover;
  end
  else if not PtInRectEx(GetTopLeftNavigationButtonRect, PointF(X, Y)) then
    FTopLeftNavigationButtonState := pnbsNormal;

  trnb := FTopRightNavigationButtonState;
  if PtInRectEx(GetTopRightNavigationButtonRect, PointF(X, Y)) then
  begin
    if FNavigationButtonDown then
      FTopRightNavigationButtonState := pnbsDown
    else
      FTopRightNavigationButtonState := pnbsHover;
  end
  else if not PtInRectEx(GetTopRightNavigationButtonRect, PointF(X, Y)) then
    FTopRightNavigationButtonState := pnbsNormal;

  blnb := FBottomLeftNavigationButtonState;
  if PtInRectEx(GetBottomLeftNavigationButtonRect, PointF(X, Y)) then
  begin
    if FNavigationButtonDown then
      FBottomLeftNavigationButtonState := pnbsDown
    else
      FBottomLeftNavigationButtonState := pnbsHover;
  end
  else if not PtInRectEx(GetBottomLeftNavigationButtonRect, PointF(X, Y)) then
    FBottomLeftNavigationButtonState := pnbsNormal;

  brnb := FBottomRightNavigationButtonState;
  if PtInRectEx(GetBottomRightNavigationButtonRect, PointF(X, Y)) then
  begin
    if FNavigationButtonDown then
      FBottomRightNavigationButtonState := pnbsDown
    else
      FBottomRightNavigationButtonState := pnbsHover;
  end
  else if not PtInRectEx(GetBottomRightNavigationButtonRect, PointF(X, Y)) then
    FBottomRightNavigationButtonState := pnbsNormal;

  ch := (tlnb <> FTopLeftNavigationButtonState) or (trnb <> FTopRightNavigationButtonState) or
    (blnb <> FBottomLeftNavigationButtonState) or (brnb <> FBottomRightNavigationButtonState);

  if ch then
    Invalidate;
end;

procedure TTMSFNCCustomPlanner.ProcessNavigationButtonsUp(X, Y: Single);
var
  dtc, dtn: TDateTime;
begin
  if FNavigationButtonDown then
  begin
    if PtInRectEx(GetTopLeftNavigationButtonRect, PointF(X, Y)) or PtInRectEx(GetBottomLeftNavigationButtonRect, PointF(X, Y)) then
    begin
      dtc := FDisplayStartTime;
      dtn := GetPreviousDateTime;
      HandleDateTimeNavigation(pndPrevious, dtc, dtn);
    end;

    if PtInRectEx(GetTopRightNavigationButtonRect, PointF(X, Y)) or PtInRectEx(GetBottomRightNavigationButtonRect, PointF(X, Y)) then
    begin
      dtc := FDisplayStartTime;
      dtn := GetNextDateTime;
      HandleDateTimeNavigation(pndNext, dtc, dtn);
    end;

    FTopLeftNavigationButtonState := pnbsNormal;
    FTopRightNavigationButtonState := pnbsNormal;
    FBottomLeftNavigationButtonState := pnbsNormal;
    FBottomRightNavigationButtonState := pnbsNormal;
  end;
end;

procedure TTMSFNCCustomPlanner.SetDefaultItem(const Value: TTMSFNCPlannerItem);
begin
  FDefaultItem.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetItemEditor(
  const Value: TTMSFNCPlannerCustomItemEditor);
begin
  if Assigned(Value) then
    Value.Planner := Self;
  FItemEditor := Value;
end;

procedure TTMSFNCCustomPlanner.SetItemsAppearance(
  const Value: TTMSFNCPlannerItemsAppearance);
begin
  if FItemsAppearance <> Value then
    FItemsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetGridCellAppearance(
  const Value: TTMSFNCPlannerGridCellAppearance);
begin
  if FGridCellAppearance <> Value then
    FGridCellAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetMode(const Value: TTMSFNCPlannerMode);
var
  sc: Single;
begin
  if FMode <> Value then
  begin
    FMode := Value;
    case GetDisplayMode of
      pmDay, pmMultiDay, pmMultiDayRes, pmMultiResDay:
      begin
        FTimeLine.FDisplayUnit := 30;
        FTimeLine.FDisplayUnitType := pduMinute;
        FTimeLine.FDisplayStart := 0;
        FTimeLine.FDisplayEnd := 47;
      end;
      pmHalfDayPeriod:
      begin
        FTimeLine.FDisplayUnit := 12;
        FTimeLine.FDisplayUnitType := pduHour;
      end;
      pmDayPeriod:
      begin
        FTimeLine.FDisplayUnit := 1;
        FTimeLine.FDisplayUnitType := pduDay;
      end;
      pmMultiMonth, pmMonth:
      begin
        FTimeLine.FDisplayUnit := 1;
        FTimeLine.FDisplayUnitType := pduDay;
        FTimeLine.FDisplayStart := 0;
        FTimeLine.FDisplayEnd := 30;
      end;
    end;
    {$IFDEF VCLLIB}
    {$IF COMPILERVERSION > 34}
    sc := TTMSFNCUtils.GetDPIScale(Self, 96);
    {$ELSE}
    sc := PaintScaleFactor;
    {$IFEND}
    {$ELSE}
    sc := PaintScaleFactor;
    {$ENDIF}

    case GetDisplayMode of
      pmMonth:
      begin
        FTimeLineAppearance.FLeftSize := 110 * sc;
        FTimeLineAppearance.FRightSize := 110 * sc;
      end;
      pmDayPeriod, pmHalfDayPeriod, pmCustom:
      begin
        FTimeLineAppearance.FLeftSize := 150 * sc;
        FTimeLineAppearance.FRightSize := 150 * sc;
      end
      else
      begin
        FTimeLineAppearance.FLeftSize := 50 * sc;
        FTimeLineAppearance.FRightSize := 50 * sc;
      end;
    end;

    FNeedsConflictsUpdate := True;
    FNeedsInitialization := True;
    UpdatePlannerCache;

    case GetDisplayMode of
      pmDay, pmMultiDay, pmMultiDayRes, pmMultiResDay: FTimeLine.ViewStart := Int(FModeSettings.FStartTime);
      pmHalfDayPeriod: FTimeLine.ViewStart := Int(FModeSettings.FStartTime);
      pmDayPeriod: FTimeLine.ViewStart := Int(FModeSettings.FStartTime);
      pmMultiMonth, pmMonth: TimeLine.ViewStart := EncodeDate(YearOf(FModeSettings.FStartTime), MonthOf(FModeSettings.FStartTime), 1);
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.SetModeSettings(
  const Value: TTMSFNCPlannerModeSettings);
begin
  if FModeSettings <> Value then
    FModeSettings.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetInteraction(const Value: TTMSFNCPlannerInteraction);
begin
  if FInteraction <> Value then
    FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetGroupsAppearance(
  const Value: TTMSFNCPlannerGroupsAppearance);
begin
  if FGroupsAppearance <> Value then
    FGroupsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(ItemsAppearance.Font, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.TitleFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.SelectedFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.ActiveFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.SelectedTitleFont, ASetType);
  GlobalFont.ApplyChange(ItemsAppearance.ActiveTitleFont, ASetType);

  GlobalFont.ApplyChange(FullDaysAppearance.TopFont, ASetType);
  GlobalFont.ApplyChange(FullDaysAppearance.BottomFont, ASetType);

  GlobalFont.ApplyChange(TimeLineAppearance.LeftFont, ASetType);
  GlobalFont.ApplyChange(TimeLineAppearance.RightFont, ASetType);

  GlobalFont.ApplyChange(PositionsAppearance.TopFont, ASetType);
  GlobalFont.ApplyChange(PositionsAppearance.BottomFont, ASetType);

  GlobalFont.ApplyChange(GroupsAppearance.TopFont, ASetType);
  GlobalFont.ApplyChange(GroupsAppearance.BottomFont, ASetType);

  if ASetType = aftColor then
  begin
    for I := 0 to Items.Count - 1 do
    begin
      Items[I].FontColor := GlobalFont.Color;
      Items[I].TitleFontColor := GlobalFont.Color;
      Items[I].SelectedFontColor := GlobalFont.Color;
      Items[I].SelectedTitleFontColor := GlobalFont.Color;
      Items[I].ActiveFontColor := GlobalFont.Color;
      Items[I].ActiveTitleFontColor := GlobalFont.Color;
    end;
  end
  else
  begin
    GlobalFont.ApplyChange(ItemsAppearance.DisabledFont, ASetType);
    GlobalFont.ApplyChange(ItemsAppearance.DisabledTitleFont, ASetType);

    if ASetType = aftScale then
    begin
      TimeLineAppearance.LeftSubUnitFontSize := Round(TimeLineAppearance.LeftSubUnitFontSize * GlobalFont.Scale / GlobalFont.GetOldScale);
      TimeLineAppearance.RightSubUnitFontSize := Round(TimeLineAppearance.RightSubUnitFontSize * GlobalFont.Scale / GlobalFont.GetOldScale);
    end
    else if ASetType = aftSize then
    begin
      TimeLineAppearance.LeftSubUnitFontSize := GlobalFont.Size;
      TimeLineAppearance.RightSubUnitFontSize := GlobalFont.Size;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetFullDaysAppearance(
  const Value: TTMSFNCPlannerFullDaysAppearance);
begin
  if FFullDaysAppearance <> Value then
    FFullDaysAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetPositions(
  const Value: TTMSFNCPlannerPositions);
begin
  if FPositions <> Value then
    FPositions.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetPositionsAppearance(
  const Value: TTMSFNCPlannerPositionsAppearance);
begin
  if FPositionsAppearance <> Value then
    FPositionsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetActiveItem(const Value: TTMSFNCPlannerItem);
begin
  HandleSelectItem(Value);
end;

procedure TTMSFNCCustomPlanner.SetAdapter(const Value: TTMSFNCPlannerAdapter);
begin
  if Assigned(Value) then
    Value.Planner := Self;
  FAdapter := Value;
end;

procedure TTMSFNCCustomPlanner.SetSelectionAppearance(
  const Value: TTMSFNCPlannerSelectionAppearance);
begin
  if FSelectionAppearance <> Value then
    FSelectionAppearance := Value;
end;

procedure TTMSFNCCustomPlanner.SetTimeLine(const Value: TTMSFNCPlannerTimeLine);
begin
  if FTimeLine <> Value then
    FTimeLine.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetTimeLineAppearance(
  const Value: TTMSFNCPlannerTimeLineAppearance);
begin
  if FTimeLineAppearance <> Value then
    FTimeLineAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPlanner.SetViewCol(const Value: Integer);
begin
  Scroll(ColumnPositions[Max(0, Min(Value, ColumnCount - 1))], RowPositions[Max(0, Min(ViewRow, RowCount - 1))]);
end;

procedure TTMSFNCCustomPlanner.SetViewRow(const Value: Integer);
begin
  Scroll(ColumnPositions[Max(0, Min(ViewCol, ColumnCount - 1))], RowPositions[Max(0, Min(Value, RowCount - 1))]);
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomPlanner.ShowHintEx(AItem: TTMSFNCPlannerItem; X, Y: Double);
{$ENDIF}
{$IFNDEF WEBLIB}
procedure TTMSFNCCustomPlanner.ShowHint(AItem: TTMSFNCPlannerItem; X, Y: Double);
{$ENDIF}
var
  hp: TTMSFNCPlannerHintPopup;
  h: string;
begin
  if not ItemsAppearance.AlternativeHints then
    Exit;

  hp := GetHintPopup;
  if Assigned(hp.Panel) then
  begin
    h := '';
    if Assigned(AItem) then
      h := AItem.Hint;

    DoGetItemHint(AItem, h);
    if Assigned(AItem) and (h <> '') and not FAnimating then
    begin
      hp.Panel.Parent := Self;
      if Assigned(hp.TextLabel) then
      begin
        hp.TextLabel.Text := h;
        hp.Panel.Width := hp.TextLabel.Width + 10;
        hp.Panel.Height := hp.TextLabel.Height + 10;
      end;

      {$IFDEF FMXLIB}
      hp.Panel.Position.X := X;
      hp.Panel.Position.Y := Y + 20;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      hp.Panel.Left := Round(X);
      hp.Panel.Top := Round(Y + 20);
      {$ENDIF}
    end
    else
      hp.Panel.Parent := nil;
  end;
end;

procedure TTMSFNCCustomPlanner.StartDateEditChanged(Sender: TObject);
var
  dt, dte: TDateTime;
begin
  if Assigned(FStartDateEdit) and Assigned(FStartTimeEdit) and Assigned(FEndDateEdit) and Assigned(FStartDateEdit) then
  begin
    dt := FStartDateEdit.Date + FStartTimeEdit.Time;
    dte := FEndDateEdit.Date + FEndTimeEdit.Time;
    if CompareDateTime(dt + IncMilliSecond(0, 1), dte)  = GreaterThanValue then
      FStartDateEdit.Date := FDialogStartDate
    else
      FDialogStartDate := FStartDateEdit.Date;
  end;
end;

procedure TTMSFNCCustomPlanner.StartTimeEditChanged(Sender: TObject);
var
  dt, dte: TDateTime;
begin
  if Assigned(FStartDateEdit) and Assigned(FStartTimeEdit) and Assigned(FEndDateEdit) and Assigned(FStartDateEdit) then
  begin
    dt := FStartDateEdit.Date + FStartTimeEdit.Time;
    dte := FEndDateEdit.Date + FEndTimeEdit.Time;
    if CompareDateTime(dt + IncMilliSecond(0, 1), dte) = GreaterThanValue then
      FStartTimeEdit.Time := FDialogStartTime
    else
      FDialogStartTime := FStartTimeEdit.Time;
  end;
end;

procedure TTMSFNCCustomPlanner.StopAnimationTimer;
begin
  FAnimateTimer.Enabled := False;
  FAnimating := False;
end;

procedure TTMSFNCCustomPlanner.StopEditing;
begin
  if FEditorDialogActive then
    CloseEditingDialog(False)
  else if FInplaceEditorActive then
    CloseInplaceEditor(False);
end;

procedure TTMSFNCCustomPlanner.UnlinkItems(AItems: TTMSFNCPlannerLinkedItemArray);
var
  I: Integer;
begin
  BeginUpdate;
  for I := 0 to Length(AItems) - 1 do
  begin
    AItems[I].LinkedItem := nil;
    AItems[I].LinkType := iltNone;
  end;
  EndUpdate;
end;

procedure TTMSFNCCustomPlanner.UpdateActiveItem(AItem: TTMSFNCPlannerItem);
begin
  ActiveItem := AItem;
end;

procedure TTMSFNCCustomPlanner.UpdateAutoSizing;
begin
  if PositionsAppearance.Stretch then
    StretchColumn
  else
    ColumnW.Clear;

  if TimeLineAppearance.Stretch then
    StretchRow
  else
    RowH.Clear;
end;

procedure TTMSFNCCustomPlanner.UpdateCalculations(AForce: Boolean = False);
var
  du, duoff: TDateTime;
  di: Double;
  y, m, d, h, mn, s, z: Word;
  ye, me, de, he, mne, se, ze: Word;
  dt, dte: TDateTime;
  I, p, cnt: Integer;
  grp: TTMSFNCPlannerDisplayGroup;
  fdrp: TTMSFNCPlannerDisplayFullDay;
  g: TTMSFNCPlannerGroup;
  conf: TTMSFNCPlannerConflict;
begin
  if ((UpdateCount > 0) or (csDestroying in ComponentState) or (csLoading in ComponentState)) and not AForce then
    Exit;

  FCustomDatesList.Clear;
  if GetDisplayMode = pmCustom then
  begin
    for I := 0 to CustomDateTimes.Count - 1 do
      FCustomDatesList.Add(CustomDateTimes[I]);

    {$IFNDEF LCLWEBLIB}
    FCustomDatesList.Sort(FCompareCustomDates);
    {$ENDIF}
    {$IFDEF LCLWEBLIB}
    {$IFDEF LCLLIB}
    FCustomDatesList.Sort(@CompareDT);
    {$ENDIF}
    {$IFNDEF LCLLIB}
    FCustomDatesList.Sort(TListSortCompare(@CompareDT));
    {$ENDIF}
    {$ENDIF}
  end;

  ColumnCount := Positions.Count;
  dt := ModeSettings.StartTime;
  dte := ModeSettings.EndTime;
  du := GetDisplayUnitValue;
  duoff := GetDisplayOffsetValue;
  DecodeDateTime(dt, y, m, d, h, mn, s, z);
  DecodeDateTime(dte, ye, me, de, he, mne, se, ze);
  case GetDisplayMode of
    pmCustom:
    begin
      if FCustomDatesList.Count > 0 then
      begin
        FDisplayStartTime := FCustomDatesList[0];
        FDisplayEndTime := FCustomDatesList[FCustomDatesList.Count - 1];
      end;
    end;
    pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes:
    begin
      FDisplayStartTime := EncodeDate(y, m, d);
      FDisplayEndTime := EncodeDate(y, m, d);
    end;
    pmDayPeriod, pmHalfDayPeriod:
    begin
      FDisplayStartTime := EncodeDate(y, m, d);
      FDisplayEndTime := EncodeDate(ye, me, de);
    end;
    pmMonth, pmMultiMonth:
    begin
      FDisplayStartTime := EncodeDate(y, m, 1);
      FDisplayEndTime := EncodeDate(y, m, 1);
    end;
  end;

  FDisplayStart := TimeLine.DisplayStart;
  FDisplayEnd := TimeLine.DisplayEnd + 1;
  FActiveStart := TimeLine.ActiveStart;
  FActiveEnd := TimeLine.ActiveEnd;

  di := GetMaxDisplayUnitValue;
  if du > 0 then
  begin
    case GetDisplayMode of
      pmMultiMonth, pmMultiDay, pmMultiResDay, pmMultiDayRes:
      begin
        FDisplayStart := Max(0, FDisplayStart);
        FDisplayEnd := Round(Min(di, FDisplayEnd));
        FActiveStart := Max(0, FActiveStart);
        FActiveEnd := Round(Min(di, FActiveEnd));
        if GetDisplayMode = pmMultiMonth then
          du := 1;
      end;
    end;
  end;

  case GetDisplayMode of
    pmDay, pmMultiDay, pmMultiResDay, pmMultiDayRes, pmMonth, pmMultiMonth:
    begin
      FActiveStartTime := FDisplayStartTime + (du * FActiveStart);
      FActiveEndTime := FDisplayEndTime + (du * FActiveEnd);
      FDisplayStartTime := FDisplayStartTime + (du * FDisplayStart);
      FDisplayEndTime := FDisplayEndTime + (du * FDisplayEnd);
    end;
  end;

  case GetDisplayMode of
    pmDay, pmMonth, pmDayPeriod, pmHalfDayPeriod:
    begin
     FDisplayStartTime := FDisplayStartTime + duoff;
     FDisplayEndTime := FDisplayEndTime + duoff;
     FActiveStartTime := FActiveStartTime + duoff;
     FActiveEndTime := FActiveEndTime + duoff;
    end;
  end;

  DefaultRowHeight := TimeLine.DisplayUnitSize;
  DefaultColumnWidth := PositionsAppearance.Size;

  if GetDisplayMode = pmCustom then
    RowCount := FCustomDatesList.Count - 1
  else
  begin
    if du > 0 then
    begin
      case GetDisplayMode of
        pmMultiMonth: RowCount := FDisplayEnd - FDisplayStart;
        else
          RowCount := Round((FDisplayEndTime - FDisplayStartTime) / du);
      end;
    end
    else
      RowCount := 0;
  end;

  FDisplayFullDays.Clear;
  if CanDisplayFullDayTop or CanDisplayFullDayBottom then
  begin
    for I := 0 to ColumnCount - 1 do
    begin
      fdrp.StartPosition := I;
      fdrp.EndPosition := I;
      FDisplayFullDays.Add(fdrp);
    end;
  end;

  FDisplayGroups.Clear;
  case GetDisplayMode of
    pmDay, pmHalfDayPeriod, pmDayPeriod, pmMultiDay, pmMonth, pmMultiMonth, pmCustom:
    begin
      for I := 0 to Groups.Count - 1 do
      begin
        g := Groups[I];
        grp.StartPosition := Max(0, Min(g.StartPosition, ColumnCount - 1));
        grp.EndPosition := Max(0, Min(g.EndPosition, ColumnCount - 1));
        FDisplayGroups.Add(grp);
      end;
    end;
    pmMultiResDay, pmMultiDayRes:
    begin
      if GetDisplayMode = pmMultiResDay then
      begin
        cnt := Resources.Count;
        p := GetNumDays;
      end
      else
      begin
        cnt := GetNumDays;
        p := Resources.Count;
      end;

      for I := 0 to p - 1 do
      begin
        grp.StartPosition := Max(0, Min(cnt * I, ColumnCount - 1));
        grp.EndPosition := Max(0, Min(grp.StartPosition + cnt - 1, ColumnCount - 1));
        FDisplayGroups.Add(grp);
      end;
    end;
  end;

  FConflicts.Clear;
  for I := 0 to ColumnCount - 1 do
  begin
    conf := TTMSFNCPlannerConflict.Create(Self);
    conf.NeedsConflictsUpdate := False;
    conf.Position := I;
    FConflicts.Add(conf);
  end;

  if FNeedsInitialization then
  begin
    FNeedsInitialization := False;
    FSelectedItems.Clear;
    FActiveItem := nil;
    FSelection.StartCell.Col := Max(0, Min(Selection.StartCell.Col, ColumnCount - 1));
    FSelection.StartCell.Row := Max(0, Min(Selection.StartCell.Row, RowCount - 1));
    FSelection.EndCell.Col := Max(0, Min(Selection.EndCell.Col, ColumnCount - 1));
    FSelection.EndCell.Row := Max(0, Min(Selection.EndCell.Row, RowCount - 1));
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateColumnRowCalculations;
var
  I: Integer;
  r, c: Double;
begin
  RowP.Clear;
  r := 0;
  RowPositions[0] := r;
  for I := 0 to RowCount - 1 do
  begin
    r := r + RowHeights[I];
    RowPositions[I + 1] := r;
  end;

  ColumnP.Clear;
  c := 0;
  ColumnPositions[0] := c;
  for I := 0 to ColumnCount - 1 do
  begin
    c := c + ColumnWidths[I];
    ColumnPositions[I + 1] := c;
  end;

  TotalColumnWidth := c;
  TotalRowHeight := r;
end;

procedure TTMSFNCCustomPlanner.UpdateConflicts;
var
  I, J, K, L, M, N: Integer;
  conf: TTMSFNCPlannerConflict;
  it, itemA, itemB: TTMSFNCPlannerItemOpen;
  res: TTMSFNCPlannerResourceDate;
  firsttime,lasttime: TDateTime;
  check,found: boolean;

  function ExtendOverlap(itemA,itemB: TTMSFNCPlannerItemOpen): boolean;
  var
    ms,me: TDateTime;
  begin
    ms := Max(itemA.StartTimeExt, itemB.StartTimeExt);
    me := Min(itemA.EndTimeExt, itemB.EndTimeExt);

    Result := CompareDateTime(me, ms) = GreaterThanValue;

    if Result then
    begin
      itemA.StartTimeExt := Min(itemA.StartTimeExt, itemB.StartTimeExt);
      itemA.EndTimeExt := Max(itemA.EndTimeExt, itemB.EndTimeExt);
      itemB.StartTimeExt := itemA.StartTimeExt;
      itemB.EndTimeExt := itemA.EndTimeExt;
    end;
  end;

begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (csLoading in ComponentState) then
    Exit;

  if FNeedsConflictsUpdate then
  begin
    NeedsConflictsUpdate;
    FNeedsConflictsUpdate := False;
  end;

  for I := 0 to FConflicts.Count - 1 do
  begin
    conf := FConflicts[I];

    if conf.NeedsConflictsUpdate then
    begin
      conf.UpdateItems;
      conf.UpdateDates;

      firsttime := 0;
      lasttime := 0;

      // get position start & end time
      if conf.Dates.Count > 0 then
      begin
        firsttime := TTMSFNCPlannerResourceDate(conf.Dates[0]).DateTime;
        lasttime := TTMSFNCPlannerResourceDate(conf.Dates[conf.Dates.Count - 1]).DateTime;
      end;

      // limit item time to position start & end time
      for K := 0 to conf.Items.Count - 1 do
      begin
        with TTMSFNCPlannerItemOpen(conf.Items[K]) do
        begin
          StartTimeExt := Max(StartTime, firsttime);
          EndTimeExt := Min(EndTime, lasttime);
        end;
      end;

      // extend item start & end time with overlapping item start & end time
      for K := 0 to conf.Items.Count - 1 do
      begin
        for L := 0 to conf.Items.Count - 1 do
        begin
          if (K <> L) then
            ExtendOverlap(TTMSFNCPlannerItemOpen(conf.Items[K]), TTMSFNCPlannerItemOpen(conf.Items[L]));
        end;

        it := TTMSFNCPlannerItemOpen(conf.Items[K]);
        it.DirtyItem;
      end;

      // initialize conflicts counters
      for J := 0 to conf.Dates.Count - 1 do
      begin
        res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
        res.X := 0;
        res.Y := 0;
        conf.Dates[J] := res;
      end;

      // set nr. of items per slot
      for K := 0 to conf.Items.Count - 1 do
      begin
        it := TTMSFNCPlannerItemOpen(conf.Items[K]);

        for J := 0 to conf.Dates.Count - 1 do
        begin
          if (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, it.StartTimeExt) in [GreaterThanValue, EqualsValue])
            and (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, it.EndTimeExt) = LessThanValue) then
          begin
            res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
            res.Y := res.Y + 1;
            conf.Dates[J] := res;
          end;
        end;
      end;

      // set max. nr. of conflicts found per item
      for K := 0 to conf.Items.Count - 1 do
      begin
        it := TTMSFNCPlannerItemOpen(conf.Items[K]);

        it.ConflictsExt := 1;

        for J := 0 to conf.Dates.Count - 1 do
        begin
          if (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, it.StartTime) in [GreaterThanValue, EqualsValue])
            and (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, it.EndTime) = LessThanValue) then
          begin
            if TTMSFNCPlannerResourceDate(conf.Dates[J]).Y > it.ConflictsExt then
              it.ConflictsExt := TTMSFNCPlannerResourceDate(conf.Dates[J]).Y;
          end;
        end;
      end;

      // initialize conflicts position placeholders per timeslot
      for J := 0 to conf.Dates.Count - 1 do
      begin
        res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
        SetLength(res.Z, res.Y);

        // initialize slots to unoccupied
        for L := 0 to res.Y - 1 do
          res.Z[L] := false;

        conf.Dates[J] := res;
      end;

      for K := 0 to conf.Items.Count - 1 do
      begin
        it := TTMSFNCPlannerItemOpen(conf.Items[K]);

        for N := 0 to it.ConflictsExt - 1 do
        begin
          found := true;
          check := false;

          for J := 0 to conf.Dates.Count - 1 do
          begin
            if CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Max(it.StartTime, firsttime)) = EqualsValue then
              check := true;

            if CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Min(it.EndTime, lasttime)) = EqualsValue then
              check := false;

            if check then
            begin
              res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
              if res.Z[N] then
              begin
                found := false;
                break;
              end;
            end;
          end;

          if found then
          begin
            it.ConflictsPosExt := N;
            check := false;

            for J := 0 to conf.Dates.Count - 1 do
            begin
              if CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Max(it.StartTime, firsttime)) = EqualsValue then
                check := true;

              if CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Min(it.EndTime, lasttime)) = EqualsValue then
                check := false;

              if check then
              begin
                res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
                res.Z[N] := true;
                if res.X < N then
                  res.X := N;

                conf.Dates[J] := res;
              end;

            end;
            break;
          end;
        end;
      end;

      (*
      for J := 0 to conf.Dates.Count - 1 do
      begin
        for K := 0 to conf.Items.Count - 1 do
        begin

          it := TTMSFNCPlannerItemOpen(conf.Items[K]);

          if CompareDateTime(conf.Dates[J].DateTime, Max(it.StartTime, firsttime)) = EqualsValue then
          begin
            res := conf.Dates[J];

            MM := 0;
            for L := 0 to Length(res.Z) - 1 do
            begin
              if not res.Z[L] then
              begin
                res.Z[L] := true;
                MM := L;
                if MM > res.X then
                  res.X := MM;
                conf.Dates[J] := res;

                Break;
              end;
            end;

            it.ConflictsPosExt := MM;

            L := J + 1;
            while (L < conf.Dates.Count) do
            begin
              if CompareDateTime(Min(it.EndTime, lasttime), conf.Dates[L].DateTime) = EqualsValue then
                Break
              else
              begin
                res := conf.Dates[L];
                if MM < Length(res.Z)  then
                begin
                  res.Z[MM] := true;
                  if MM > res.X then
                    res.X := MM;
                end;
                conf.Dates[L] := res;
              end;

              inc(L);
            end;
          end;
        end;
      end;
      *)

      // calculate nr. of conflicts after resolution
      for K := 0 to conf.Items.Count - 1 do
      begin
        it := TTMSFNCPlannerItemOpen(conf.Items[K]);

        M := 1;

        for J := 0 to conf.Dates.Count - 1 do
        begin
          res := TTMSFNCPlannerResourceDate(conf.Dates[J]);
          if (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Max(it.StartTime, firsttime)) in [GreaterThanValue, EqualsValue])
            and (CompareDateTime(TTMSFNCPlannerResourceDate(conf.Dates[J]).DateTime, Min(it.EndTime, lasttime)) = LessThanValue) then
          begin
            if res.X > M then
              M := res.X;
          end;
        end;

        if it.ConflictsExt > M + 1 then
          it.ConflictsExt := M + 1;
      end;

      // set nr. of conflicts
      for K := 0 to conf.Items.Count - 1 do
      begin
        for L := 0 to conf.Items.Count - 1 do
        begin
          if (K <> L) then
          begin
            itemA := TTMSFNCPlannerItemOpen(conf.Items[K]);
            itemB := TTMSFNCPlannerItemOpen(conf.Items[L]);
            if ExtendOverlap(itemA, itemB) then
            begin
              if itemA.ConflictsExt <> itemB.ConflictsExt then
              begin
                M := Max(itemA.ConflictsExt, itemB.ConflictsExt);
                itemA.ConflictsExt := M;
                itemB.ConflictsExt := M;
              end;
            end;
          end;
        end;
      end;

      // set conflictpos
      for K := 0 to conf.Items.Count - 1 do
      begin
        it := TTMSFNCPlannerItemOpen(conf.Items[K]);
        it.DirtyItem;
        for J := 0 to it.PositionsList.Count - 1 do
        begin
          if it.PositionsList[J] = conf.Position then
          begin
            if (J >= 0) and (J <= it.ConflictsList.Count - 1) then
              it.ConflictsList[J] := it.ConflictsExt
            else
              it.ConflictsList.Add(it.ConflictsExt);

            if (J >= 0) and (J <= it.ConflictsPosList.Count - 1) then
              it.ConflictsPosList[J] := it.ConflictsPosExt
            else
              it.ConflictsPosList.Add(it.ConflictsPosExt);
          end;
        end;
      end;

      conf.NeedsConflictsUpdate := False;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateDeleteHandler;
var
  delh: TTMSFNCPlannerDeleteHandler;
  bkg: TTMSFNCPlannerDeleteHandlerPanel;
  r: TRectF;
  w, h: Single;
  d: Boolean;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (csLoading in ComponentState) then
    Exit;

  if AllowMobileDelete and not Interaction.ReadOnly then
  begin
    delh := GetDeleteHandler;
    bkg := delh.Background;
    if Assigned(bkg) then
    begin
      if Assigned(FActiveItem) then
      begin
        d := FActiveItem.Deletable;
        DoIsItemDeletable(FActiveItem, d);
        if d then
        begin
          bkg.Parent := Self;
          r := GetFirstRect(FActiveItem);
          w := ItemsAppearance.DeleteHandlerWidth;
          h := ItemsAppearance.DeleteHandlerHeight;
          {$IFDEF FMXLIB}
          case OrientationMode of
            pomHorizontal: bkg.BoundsRect := RectF(Int(r.Right), Int(r.Top), Int(r.Right + w), Int(r.Top + h));
            pomVertical: bkg.BoundsRect := RectF(Int(r.Right), Int(r.Top), Int(r.Right + w), Int(r.Top + h));
          end;
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          case OrientationMode of
            pomHorizontal: bkg.BoundsRect := Rect(Round(r.Right), Round(r.Top), Round(r.Right + w), Round(r.Top + h));
            pomVertical: bkg.BoundsRect := Rect(Round(r.Right), Round(r.Top), Round(r.Right + w), Round(r.Top + h));
          end;
          {$ENDIF}
        end
      end
      else
        bkg.Parent := nil;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateDisplay;
begin
  inherited;
  UpdateGridDisplay;
  UpdateGroupsDisplay;
  UpdatePositionsDisplay;
  UpdateFullDaysDisplay;
  UpdateFullDaysItemDisplay;
  UpdateTimeLineDisplay;
  UpdateItemDisplay;
  UpdateInplaceEditorPosition;
  UpdateSizeHandlers;
  UpdateDeleteHandler;
  Invalidate;
end;

procedure TTMSFNCCustomPlanner.UpdateGridCache;
var
  w, h, bmpw, bmph, bw, bh, offh, offw: Double;
  bmp: TBitmap;
  rt: TRectF;
  c, r: Integer;
  dx, dy, sdx: Double;
  offsetx, offsety: Double;
  ac, ar: Integer;
  rc: TRectF;
  rcnt, ccnt: Integer;
  bmpvalid: Boolean;
  dt, dte: TDateTime;
  p: Integer;
  g: TTMSFNCGraphics;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(FGridCache) then
    Exit;

  FGridCache.Clear;

  if ColumnCount > 0 then
  begin
    case OrientationMode of
      pomHorizontal:
      begin
        h := GetTotalColumnWidth;
        w := GetTotalRowHeight;
      end;
      pomVertical:
      begin
        w := GetTotalColumnWidth;
        h := GetTotalRowHeight;
      end;
      else
      begin
        w := 0;
        h := 0;
      end;
    end;

    bmpvalid := False;
    offsetx := 0;
    offsety := 0;
    bmpw := 0;
    bmph := 0;
    ac := 0;
    ar := 0;
    dx := 0;
    dy := 0;
    c := 0;
    r := 0;

    case OrientationMode of
      pomHorizontal:
      begin
        ccnt := RowCount - 1;
        rcnt := ColumnCount - 1;
      end;
      pomVertical:
      begin
        rcnt := RowCount - 1;
        ccnt := ColumnCount - 1;
      end;
      else
      begin
        ccnt := 0;
        rcnt := 0;
      end;
    end;

    while bmpw < w do
    begin
      if GridCaching then
      begin
        bw := Min(w - bmpw, GetCacheWidth);
        offw := bmpw;
      end
      else
      begin
        bw := w;
        offw := 0;
      end;

      while bmph < h do
      begin
        if GridCaching then
        begin
          bh := Min(h - bmph, GetCacheHeight);
          offh := bmph;
        end
        else
        begin
          bh := h;
          offh := 0;
        end;

        bmp := nil;
        if GridCaching then
          bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

        g := nil;
        if bmpvalid then
          g := TTMSFNCGraphics.Create(bmp.Canvas);

        rt := RectF(0, 0, bw, bh);

        dx := rt.Left + offsetx;
        for c := ac to ccnt do
        begin
          sdx := dx;
          {$IFDEF FMXWEBLIB}
          rc.Left := int(dx) + 0.5;
          case OrientationMode of
            pomHorizontal: dx := dx + RowHeights[c];
            pomVertical: dx := dx + ColumnWidths[c];
          end;
          rc.Right := int(dx) + 0.5;
          {$ENDIF}
          {$IFDEF CMNLIB}
          rc.Left := dx;
          case OrientationMode of
            pomHorizontal: dx := dx + RowHeights[c];
            pomVertical: dx := dx + ColumnWidths[c];
          end;
          rc.Right := dx;
          {$ENDIF}

          dy := rt.Top + offsety;
          for r := ar to rcnt do
          begin
            case OrientationMode of
              pomHorizontal: dt := ValueToDateTime(sdx + offw, r, c);
              pomVertical: dt := ValueToDateTime(dy + offh, c, r);
              else
                dt := 0;
            end;

            {$IFDEF FMXWEBLIB}
            rc.Top := int(dy) + 0.5;
            case OrientationMode of
              pomHorizontal: dy := dy + ColumnWidths[r];
              pomVertical: dy := dy + RowHeights[r];
            end;
            rc.Bottom := int(dy) + 0.5;
            {$ENDIF}

            {$IFDEF CMNLIB}
            rc.Top := dy;
            case OrientationMode of
              pomHorizontal: dy := dy + ColumnWidths[r];
              pomVertical: dy := dy + RowHeights[r];
            end;
            rc.Bottom := dy;
            {$ENDIF}

            case OrientationMode of
              pomHorizontal: dte := ValueToDateTime(dx + offw, r, c);
              pomVertical: dte := ValueToDateTime(dy + offh, c, r);
              else
                dte := 0;
            end;

            case OrientationMode of
              pomHorizontal: p := r;
              pomVertical: p := c;
              else
                p := 0;
            end;

            if GridCaching then
            begin
              if bmpvalid then
              begin
                case OrientationMode of
                  pomHorizontal: DrawCell(g, rc, r, c, dt, dte , p, ikCell);
                  pomVertical: DrawCell(g, rc, c, r, dt, dte , p, ikCell);
                end;
              end;
            end
            else
            begin
              OffsetRectEx(rc, bmpw, bmph);
              case OrientationMode of
                pomHorizontal: FGridCache.Add(TTMSFNCPlannerCacheItem.CreateCell(rc, r, c, dt, dte, p));
                pomVertical: FGridCache.Add(TTMSFNCPlannerCacheItem.CreateCell(rc, c, r, dt, dte, p));
              end;
            end;

            if dy > (rt.Bottom - rt.Top) then
              Break;
          end;

          if dx > (rt.Right - rt.Left) then
             Break;
        end;

        if GridCaching then
        begin
          OffsetRectEx(rt, bmpw, bmph);
          if bmpvalid then
          begin
            {$IFDEF FMXLIB}
            bmp.Canvas.EndScene;
            {$ENDIF}
            if Assigned(g) then
              g.Free;
          end;

          FGridCache.Add(TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikCell));
        end;
        bmph := bmph + bh;
        ar := r;
        case OrientationMode of
          pomHorizontal: offsety := -ColumnWidths[r] - (bh - dy);
          pomVertical:offsety := -RowHeights[r] - (bh - dy);
        end;
      end;
      bmpw := bmpw + bw;
      bmph := 0;
      ar := 0;
      ac := c;
      offsety := 0;
      case OrientationMode of
        pomHorizontal: offsetx := -RowHeights[c] - (bw - dx);
        pomVertical: offsetx := -ColumnWidths[c] - (bw - dx);
      end;
    end;
  end;

  UpdateGridDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateItemsCache;
begin
  UpdateItemCache;
  UpdateItemDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateFullDaysItemsCache;
begin
  UpdateFullDayConflicts;
  UpdateFullDaysItemCache(FFullDaysItemTopCache);
  UpdateFullDaysItemCache(FFullDaysItemBottomCache);
  UpdateFullDaysItemDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartTime, ADiffEndTime: TDateTime; ADiffPosition: Integer);
var
  li: TTMSFNCPlannerItem;
  nst, net: TDateTime;
begin
  if not Assigned(AItem) or not Assigned(AItem.LinkedItem) then
     Exit;

  li := AItem.LinkedItem;
  if TTMSFNCPlannerItemOpen(li).CanUpdate and (AItem.LinkType <> iltNone) then
  begin
    li.BeginUpdate;
    nst := li.StartTime;
    net := li.EndTime;
    GetNewDateTimeAndResource(li, AItem.LinkType, ADiffStartTime, ADiffEndTime, nst, net);
    li.StartTime := nst;
    li.EndTime := net;
    if not li.FixedResource then
      li.Resource := li.Resource + ADiffPosition;
    li.EndUpdate(False, False);
  end;
end;

procedure TTMSFNCCustomPlanner.UpdatePlannerCache(ADirtyItems: Boolean = True);
begin
  if (UpdateCount > 0) then
  begin
    UpdateCalculations(True);
    if ADirtyItems then
      DirtyItems;
    Exit;
  end;

  inherited;
  UpdateInplaceEditorPosition;
  UpdateSizeHandlers;
  UpdateDeleteHandler;
  Invalidate;
end;

procedure TTMSFNCCustomPlanner.UpdatePositionsCache;
begin
  UpdatePositionCache(FPositionsTopCache);
  UpdatePositionCache(FPositionsBottomCache);
  UpdatePositionsDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdatePositionCache(ACache: TTMSFNCPlannerCache);
var
  w, h, bmpw, bmph, bw, bh: Double;
  bmp: TBitmap;
  rt: TRectF;
  c: Integer;
  dx: Double;
  offsetx: Double;
  ac: Integer;
  rc: TRectF;
  cnt: Integer;
  cache: TTMSFNCPlannerCacheItem;
  bmpvalid: Boolean;
  g: TTMSFNCGraphics;
begin
  inherited;
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(ACache) then
    Exit;

  ACache.Clear;

  if (ACache is TTMSFNCPlannerPositionsTopCache) and (not (pplTop in PositionsAppearance.Layouts) or (PositionsAppearance.TopSize <= 0)) then
    Exit;

  if (ACache is TTMSFNCPlannerPositionsBottomCache) and (not (pplBottom in PositionsAppearance.Layouts) or (PositionsAppearance.BottomSize <= 0)) then
    Exit;

  case OrientationMode of
    pomHorizontal:
    begin
      h := GetTotalColumnWidth;
      if ACache is TTMSFNCPlannerPositionsTopCache then
        w := GetPositionsTopSize + 1
      else
        w := GetPositionsBottomSize + 1;
    end;
    pomVertical:
    begin
      w := GetTotalColumnWidth;
      if ACache is TTMSFNCPlannerPositionsTopCache then
        h := GetPositionsTopSize + 1
      else
        h := GetPositionsBottomSize + 1;
    end;
    else
    begin
      w := 0;
      h := 0;
    end;
  end;

  offsetx := 0;
  bmpw := 0;
  bmph := 0;
  ac := 0;
  c := 0;
  dx := 0;
  bmpvalid := False;

  cnt := ColumnCount - 1;

  while bmpw < w do
  begin
    if PositionsCaching then
      bw := Min(w - bmpw, GetCacheWidth)
    else
      bw := w;

    while bmph < h do
    begin
      if PositionsCaching then
        bh := Min(h - bmph, GetCacheHeight)
      else
        bh := h;

      bmp := nil;
      if PositionsCaching then
        bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

      g := nil;
      if bmpvalid then
        g := TTMSFNCGraphics.Create(bmp.Canvas);

      rt := RectF(0, 0, bw, bh);

      case OrientationMode of
        pomHorizontal: dx := rt.Top + offsetx;
        pomVertical: dx := rt.Left + offsetx;
      end;

      for c := ac to cnt do
      begin
        {$IFDEF FMXWEBLIB}
        case OrientationMode of
          pomHorizontal:
          begin
            rc.Left := int(rt.Left) + 0.5;
            rc.Right := int(rt.Right) - 0.5;
            rc.Top := int(dx) + 0.5;
            dx := dx + ColumnWidths[c];
            rc.Bottom := int(dx) + 0.5;
          end;
          pomVertical:
          begin
            rc.Top := int(rt.Top) + 0.5;
            rc.Bottom := int(rt.Bottom) - 0.5;
            rc.Left := int(dx) + 0.5;
            dx := dx + ColumnWidths[c];
            rc.Right := int(dx) + 0.5;
          end;
        end;
        {$ENDIF}
        {$IFDEF CMNLIB}
        case OrientationMode of
          pomHorizontal:
          begin
            rc.Left := rt.Left;
            rc.Right := rt.Right;
            rc.Top := dx;
            dx := dx + ColumnWidths[c];
            rc.Bottom := dx + 1;
          end;
          pomVertical:
          begin
            rc.Top := rt.Top;
            rc.Bottom := rt.Bottom;
            rc.Left := dx;
            dx := dx + ColumnWidths[c];
            rc.Right := dx + 1;
          end;
        end;
        {$ENDIF}

        if PositionsCaching then
        begin
          if bmpvalid then
          begin
            if ACache is TTMSFNCPlannerPositionsTopCache then
              DrawPosition(g, rc, c, ikPositionTop)
            else
              DrawPosition(g, rc, c, ikPositionBottom);
          end;
        end
        else
        begin
          OffsetRectEx(rc, bmpw, bmph);
          if ACache is TTMSFNCPlannerPositionsTopCache then
            ACache.Add(TTMSFNCPlannerCacheItem.CreatePositionTop(rc, c))
          else
            ACache.Add(TTMSFNCPlannerCacheItem.CreatePositionBottom(rc, c));
        end;

        case OrientationMode of
          pomHorizontal:
          begin
            if dx > (rt.Bottom - rt.Top) then
            begin
              ac := c;
              offsetx := -ColumnWidths[c] - (bh - dx);
              Break;
            end;
          end;
          pomVertical:
          begin
            if dx > (rt.Right - rt.Left) then
              Break;
          end;
        end;
      end;

      if PositionsCaching then
      begin
        OffsetRectEx(rt, bmpw, bmph);
        if bmpvalid then
        begin
          {$IFDEF FMXLIB}
          bmp.Canvas.EndScene;
          {$ENDIF}
          if Assigned(g) then
            g.Free;
        end;

        if ACache is TTMSFNCPlannerPositionsTopCache then
          cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikPositionTop)
        else
          cache := TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikPositionBottom);

        ACache.Add(cache);
      end;
      bmph := bmph + bh;
    end;
    bmpw := bmpw + bw;
    bmph := 0;
    case OrientationMode of
      pomVertical:
      begin
        ac := c;
        offsetx := -ColumnWidths[c] - (bw - dx);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdatePositionsDisplay;
begin
  BuildDisplay(FPositionsTopCache, FPositionsTopDisplay);
  BuildDisplay(FPositionsBottomCache, FPositionsBottomDisplay);
end;

procedure TTMSFNCCustomPlanner.UpdateScrollPosition(AForce: Boolean = True);
var
  cl: TTMSFNCPlannerCell;
begin
  if not (csLoading in ComponentState) and not AForce then
    Exit;

  cl := DateTimeToCell(TimeLine.ViewStart);
  case GetDisplayMode of
    pmMultiMonth, pmMultiDay:;
    else
      cl.Col := cl.Col + PositionToResource(Positions.ViewStart);
  end;

  BlockScrollingUpdate := True;
  case OrientationMode of
    pomHorizontal:
    begin
      SetVScrollValue(ColumnPositions[cl.Col]);
      SetHScrollValue(RowPositions[cl.Row]);
    end;
    pomVertical:
    begin
      SetHScrollValue(ColumnPositions[cl.Col]);
      SetVScrollValue(RowPositions[cl.Row]);
    end;
  end;
  BlockScrollingUpdate := False;
end;

procedure TTMSFNCCustomPlanner.UpdateSizeHandlers;
var
  startsz, endsz: TTMSFNCPlannerSizeHandler;
  bkgst, bkget: TTMSFNCPlannerSizeHandlerPanel;
  r: TRectF;
  w, h: Single;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (csLoading in ComponentState) then
    Exit;

  if AllowMobileSize and not Interaction.ReadOnly then
  begin
    startsz := GetStartTimeSizeHandler;
    endsz := GetEndTimeSizeHandler;
    bkgst := startsz.Background;
    bkget := endsz.Background;

    if Assigned(bkgst) and Assigned(bkget) then
    begin
      //show or hide handlers
      if Assigned(FActiveItem) then
      begin
        if FActiveItem.Sizeable then
        begin
          bkgst.Parent := Self;
          bkget.Parent := Self;

          w := ItemsAppearance.SizeHandlerWidth;
          h := ItemsAppearance.SizeHandlerHeight;
          r := GetFirstRect(FActiveItem);
          {$IFDEF FMXLIB}
          case OrientationMode of
            pomHorizontal: bkgst.BoundsRect := RectF(Int(r.Left - h) + 1, Int(r.Top + (r.Height - w) / 2), Int(r.Left) + 1, Int(r.Top + (r.Height - w) / 2 + w));
            pomVertical: bkgst.BoundsRect := RectF(Int(r.Left + (r.Width - w) / 2), Int(r.Top - h) + 1, Int(r.Left + (r.Width - w) / 2 + w), Int(r.Top) + 1);
          end;
          r := GetLastRect(FActiveItem);
          case OrientationMode of
            pomHorizontal: bkget.BoundsRect := RectF(Int(r.Right), Int(r.Top + (r.Height - w) / 2), Int(r.Right + h), Int(r.Top + (r.Height - w) / 2 + w));
            pomVertical: bkget.BoundsRect := RectF(Int(r.Left + (r.Width - w) / 2), Int(r.Bottom), Int(r.Left + (r.Width - w) / 2 + w), Int(r.Bottom + h));
          end;
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          case OrientationMode of
            pomHorizontal: bkgst.BoundsRect := Rect(Round(r.Left - h) + 1, Round(r.Top + ((r.Bottom - r.Top) - w) / 2), Round(r.Left) + 1, Round(r.Top + ((r.Bottom - r.Top) - w) / 2 + w));
            pomVertical: bkgst.BoundsRect := Rect(Round(r.Left + ((r.Right - r.Left) - w) / 2), Round(r.Top - h) + 1, Round(r.Left + ((r.Right - r.Left) - w) / 2 + w), Round(r.Top) + 1);
          end;
          r := GetLastRect(FActiveItem);
          case OrientationMode of
            pomHorizontal: bkget.BoundsRect := Rect(Round(r.Right), Round(r.Top + ((r.Bottom - r.Top) - w) / 2), Round(r.Right + h), Round(r.Top + ((r.Bottom - r.Top) - w) / 2 + w));
            pomVertical: bkget.BoundsRect := Rect(Round(r.Left + ((r.Right - r.Left) - w) / 2), Round(r.Bottom), Round(r.Left + ((r.Right - r.Left) - w) / 2 + w), Round(r.Bottom + h));
          end;
          {$ENDIF}
        end;
      end
      else
      begin
        bkgst.Parent := nil;
        bkget.Parent := nil;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateTimeLinesCache;
begin
  UpdateTimeLineCache(FTimeLineLeftCache);
  UpdateTimeLineCache(FTimeLineRightCache);
  UpdateTimeLineDisplay;
end;

procedure TTMSFNCCustomPlanner.UpdateTimeLineCache(ACache: TTMSFNCPlannerCache);
var
  w, h, bmpw, bmph, bw, bh: Double;
  bmp: TBitmap;
  rt: TRectF;
  r: Integer;
  dy: Double;
  offsety: Double;
  ar: Integer;
  rc: TRectF;
  cnt: Integer;
  dt: Double;
  bmpvalid: Boolean;
  du: TDateTime;
  g: TTMSFNCGraphics;
begin
  inherited;
  if (UpdateCount > 0) or (csDestroying in ComponentState) or not Assigned(ACache) then
    Exit;

  ACache.Clear;

  if (ACache is TTMSFNCPlannerTimeLineLeftCache) and (not (ptlLeft in TimeLineAppearance.Layouts) or (TimeLineAppearance.LeftSize <= 0)) then
    Exit;

  if (ACache is TTMSFNCPlannerTimeLineRightCache) and (not (ptlRight in TimeLineAppearance.Layouts) or (TimeLineAppearance.RightSize <= 0)) then
    Exit;

  case OrientationMode of
    pomHorizontal:
    begin
      w := GetTotalRowHeight;
      if ACache is TTMSFNCPlannerTimeLineLeftCache then
        h := GetTimeLineLeftSize + 1
      else
        h := GetTimeLineRightSize + 1;
    end;
    pomVertical:
    begin
      h := GetTotalRowHeight;
      if ACache is TTMSFNCPlannerTimeLineLeftCache then
        w := GetTimeLineLeftSize + 1
      else
        w := GetTimeLineRightSize + 1;
    end;
    else
    begin
      w := 0;
      h := 0;
    end;
  end;

  bmpvalid := False;
  offsety := 0;
  bmpw := 0;
  bmph := 0;
  ar := 0;
  r := 0;
  dy := 0;

  cnt := RowCount - 1;

  while bmpw < w do
  begin
    if TimeLineCaching then
      bw := Min(w - bmpw, GetCacheWidth)
    else
      bw := w;

    while bmph < h do
    begin
      if TimeLineCaching then
        bh := Min(h - bmph, GetCacheHeight)
      else
        bh := h;

      bmp := nil;
      if TimeLineCaching then
        bmpvalid := CreateAndPrepareBitmap(bmp, bw, bh);

      g := nil;
      if bmpvalid then
        g := TTMSFNCGraphics.Create(bmp.Canvas);

      rt := RectF(0, 0, bw, bh);

      case OrientationMode of
        pomHorizontal: dy := rt.Left + offsety;
        pomVertical: dy := rt.Top + offsety;
      end;

      case GetDisplayMode of
        pmMultiMonth:
        begin
          dt := (FDisplayStart + 1);
          du := 1;
        end;
        else
        begin
          dt := FDisplayStartTime;
          du := GetDisplayUnitValue;
        end;
      end;

      dt := dt + ar* du;

      for r := ar to cnt do
      begin
        if (GetDisplayMode = pmCustom) and (r >= 0) and (r <= FCustomDatesList.Count - 1) then
          dt := FCustomDatesList[r];

        {$IFDEF FMXWEBLIB}
        case OrientationMode of
          pomHorizontal:
          begin
            rc.Top := int(rt.Top) + 0.5;
            rc.Bottom := int(rt.Bottom) - 0.5;
            rc.Left := int(dy) + 0.5;
            dy := dy + RowHeights[r];
            if r = cnt then
              rc.Right := int(dy) - 0.5
            else
              rc.Right := int(dy) + 0.5;
          end;
          pomVertical:
          begin
            rc.Left := int(rt.Left) + 0.5;
            rc.Right := int(rt.Right) - 0.5;
            rc.Top := int(dy) + 0.5;
            dy := dy + RowHeights[r];
            if r = cnt then
              rc.Bottom := int(dy) - 0.5
            else
              rc.Bottom := int(dy) + 0.5;
          end;
        end;
        {$ENDIF}
        {$IFDEF CMNLIB}
        case OrientationMode of
          pomHorizontal:
          begin
            rc.Top := rt.Top;
            rc.Bottom := rt.Bottom;
            rc.Left := dy;
            dy := dy + RowHeights[r];
            if r = cnt then
              rc.Right := dy
            else
              rc.Right := dy;
          end;
          pomVertical:
          begin
            rc.Left := rt.Left;
            rc.Right := rt.Right;
            rc.Top := dy;
            dy := dy + RowHeights[r];
            if r = cnt then
              rc.Bottom := dy
            else
              rc.Bottom := dy;
          end;
        end;
        {$ENDIF}

        if TimeLineCaching then
        begin
          if bmpvalid then
          begin
            if ACache is TTMSFNCPlannerTimeLineLeftCache then
              DrawTime(g, rc, dt, r, ikTimeLineLeft)
            else
              DrawTime(g, rc, dt, r, ikTimeLineRight);
          end;
        end
        else
        begin
          OffsetRectEx(rc, bmpw, bmph);
          if ACache is TTMSFNCPlannerTimeLineLeftCache then
            ACache.Add(TTMSFNCPlannerCacheItem.CreateTimeLineLeft(rc, dt, r))
          else
            ACache.Add(TTMSFNCPlannerCacheItem.CreateTimeLineRight(rc, dt, r));
        end;

        case OrientationMode of
          pomHorizontal:
          begin
            if dy > rt.Right - rt.Left then
              Break;
          end;
          pomVertical:
          begin
            if dy > rt.Bottom - rt.Top then
            begin
              ar := r;
              offsety := -RowHeights[r] - (bh - dy);
              Break;
            end;
          end;
        end;

        if GetDisplayMode <> pmCustom then
          dt := dt + du;
      end;

      if TimeLineCaching then
      begin
        OffsetRectEx(rt, bmpw, bmph);
        if bmpvalid then
        begin
          {$IFDEF FMXLIB}
          bmp.Canvas.EndScene;
          {$ENDIF}
          if Assigned(g) then
            g.Free;
        end;

        if ACache is TTMSFNCPlannerTimeLineLeftCache then
          ACache.Add(TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikTimeLineLeft))
        else
          ACache.Add(TTMSFNCPlannerCacheItem.CreateCache(rt, bmp, ikTimeLineRight));
      end;
      bmph := bmph + bh;
    end;
    bmpw := bmpw + bw;
    bmph := 0;
    case OrientationMode of
      pomHorizontal:
      begin
        ar := r;
        offsety := -RowHeights[r] - (bw - dy);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.UpdateTimeLineDisplay;
begin
  BuildDisplay(FTimeLineLeftCache, FTimeLineLeftDisplay);
  BuildDisplay(FTimeLineRightCache, FTimeLineRightDisplay);
end;

function TTMSFNCCustomPlanner.ValueToDateTime(AValue: Double; APosition: Integer = -1; ARow: Integer = -1): TDateTime;
var
  st, et: Double;
  s: Double;
  c: Double;
  v: Double;
  cr: Integer;
  crh: Double;
begin
  Result := 0;
  v := AValue;
  c := GetTotalRowHeight;

  if GetDisplayMode = pmCustom then
  begin
    if ARow = -1 then
    begin
      if c > 0 then
      begin
        cr := Floor((v / c) * RowCount);
        cr := cr - 1;
        if cr = -1 then
          cr := cr + 1;

        crh := RowHeights[cr];
        if (cr >= 0) and (cr < FCustomDatesList.Count) and (crh > 0) then
        begin
          st := FCustomDatesList[cr];
          et := FCustomDatesList[cr + 1];
          s := (et - st) / crh;
          Result := st + ((v - RowPositions[cr]) * s);
        end;
      end;
    end
    else
    begin
      if (ARow >= 0) and (ARow < FCustomDatesList.Count) then
        Result := FCustomDatesList[ARow];
    end;
  end
  else
  begin
    case GetDisplayMode of
      pmMultiMonth:
      begin
        st := FDisplayStart;
        et := FDisplayEnd;
        Result := FDisplayStartTime;
        Result := IncMonth(Result, APosition);
      end
      else
      begin
        st := FDisplayStartTime;
        et := FDisplayEndTime;
        Result := st;
      end;
    end;

    if c > 0 then
    begin
      s := (et - st) / c;
      Result := Result + (v * s);
    end;

    case GetDisplayMode of
      pmMultiDay: Result := IncDay(Result, APosition);
      pmMultiDayRes: Result := IncDay(Result, APosition mod Max(1, GetNumDays));
      pmMultiResDay: Result := IncDay(Result, APosition div Max(1, Resources.Count));
    end;
  end;
end;

procedure TTMSFNCCustomPlanner.VerticalScrollPositionChanged;
begin
  BlockScrollingUpdate := True;
  UpdateDisplay;
  BlockScrollingUpdate := False;
  DoVScroll(GetVScrollValue);
end;

function TTMSFNCCustomPlanner.XYToItemAnchor(AItem: TTMSFNCPlannerItem; AX, AY: Single): String;
var
  I: Integer;
  it: TTMSFNCPlannerItemOpen;
  txtr: TRectF;
  a: String;
  str: String;
  g: TTMSFNCGraphics;
begin
  Result := '';
  if Assigned(AItem) then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    try
      g.BitmapContainer := BitmapContainer;
      it := TTMSFNCPlannerItemOpen(AItem);
      for I := 0 to it.CacheList.Count - 1 do
      begin
        txtr := GetCacheItemTextRect(it.CacheList[I]);
        if PtInRectEx(txtr, PointF(AX, AY)) then
        begin
          if FSelectedItems.IndexOf(it) > -1 then
          begin
            if it = FActiveItem then
              g.Font.Assign(ItemsAppearance.ActiveFont)
            else
              g.Font.Assign(ItemsAppearance.SelectedFont)
           end
          else
            g.Font.Assign(ItemsAppearance.Font);

          str := AItem.Text;
          DoGetItemText(AItem, pgtmDrawing, str);
          a := g.DrawText(txtr, str, True, AItem.TextHorizontalTextAlign, AItem.TextVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
          if a <> '' then
          begin
            Result := a;
            Break;
          end;
        end;
      end;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

function TTMSFNCCustomPlanner.XYToItemTitleAnchor(AItem: TTMSFNCPlannerItem; AX, AY: Single): String;
var
  I: Integer;
  it: TTMSFNCPlannerItemOpen;
  txtr: TRectF;
  a: String;
  str: String;
  g: TTMSFNCGraphics;
  cnt: Integer;
  f: Boolean;
begin
  Result := '';

  if Assigned(AItem) then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.BitmapContainer := BitmapContainer;
    try
      it := TTMSFNCPlannerItemOpen(AItem);

      f := IsFullDayItem(it);

      if f then
        cnt := it.FullDayCacheList.Count - 1
      else
        cnt := it.CacheList.Count - 1;

      for I := 0 to cnt do
      begin
        if f then
          txtr := GetAnchorItemTitleRect(it.FullDayCacheList[I])
        else
          txtr := GetAnchorItemTitleRect(it.CacheList[I]);

        str := AItem.Title;
        DoGetItemTitleText(AItem, pgtmDrawing, str);

        if PtInRectEx(txtr, PointF(AX, AY)) then
        begin
          if FSelectedItems.IndexOf(it) > -1 then
          begin
            if it = FActiveItem then
              g.Font.Assign(ItemsAppearance.ActiveTitleFont)
            else
              g.Font.Assign(ItemsAppearance.SelectedTitleFont)
           end
          else
            g.Font.Assign(ItemsAppearance.TitleFont);

          a := g.DrawText(txtr, str, False, AItem.TitleHorizontalTextAlign, AItem.TitleVerticalTextAlign, gttNone, 0, -1, -1, True, True, AX, AY);
          if a <> '' then
          begin
            Result := a;
            Break;
          end;
        end;
      end;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

function TTMSFNCCustomPlanner.XYToGroupAnchor(AX, AY: Single; var AIndex: Integer): String;
begin
  Result := XYToGroupAnchorCache(AX, AY, FGroupsTopCache, AIndex);
  if (Result = '') and (AIndex = -1) then
    Result := XYToGroupAnchorCache(AX, AY, FGroupsBottomCache, AIndex);
end;

function TTMSFNCCustomPlanner.XYToPositionAnchor(AX, AY: Single; var AIndex: Integer): String;
begin
  Result := XYToPositionAnchorCache(AX, AY, FPositionsTopCache, AIndex);
  if (Result = '') and (AIndex = -1) then
    Result := XYToPositionAnchorCache(AX, AY, FPositionsBottomCache, AIndex);
end;

function TTMSFNCCustomPlanner.XYToGroup(AX, AY: Single): TTMSFNCPlannerCacheItem;
begin
  Result := XYToGroupCache(AX, AY, FGroupsTopCache);
  if not Assigned(Result) then
    Result := XYToGroupCache(AX, AY, FGroupsBottomCache);
end;

function TTMSFNCCustomPlanner.XYToFullDayItem(X, Y: Double): TTMSFNCPlannerItem;
var
  I: Integer;
  dsp: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  if not Assigned(FFullDaysItemTopDisplay) or not Assigned(FFullDaysItemBottomDisplay) then
    Exit;

  for I := FFullDaysItemTopDisplay.Count - 1 downto 0 do
  begin
    dsp := FFullDaysItemTopDisplay[I];
    if PtInRectEx(dsp.DrawRect, PointF(X, Y)) then
    begin
      Result := dsp.Item;
      Break;
    end;
  end;

  if not Assigned(Result) then
  begin
    for I := FFullDaysItemBottomDisplay.Count - 1 downto 0 do
    begin
      dsp := FFullDaysItemBottomDisplay[I];
      if PtInRectEx(dsp.DrawRect, PointF(X, Y)) then
      begin
        Result := dsp.Item;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.XYToFullDay(AX, AY: Single): TTMSFNCPlannerCacheItem;
begin
  Result := XYToFullDayCache(AX, AY, FFullDaysTopCache);
  if not Assigned(Result) then
    Result := XYToFullDayCache(AX, AY, FFullDaysBottomCache);
end;

function TTMSFNCCustomPlanner.XYToPosition(AX, AY: Single): TTMSFNCPlannerCacheItem;
begin
  Result := XYToPositionCache(AX, AY, FPositionsTopCache);
  if not Assigned(Result) then
    Result := XYToPositionCache(AX, AY, FPositionsBottomCache);
end;

function TTMSFNCCustomPlanner.XYToTime(X, Y: Double): TTMSFNCPlannerTime;
var
  cl: TTMSFNCPlannerCell;
begin
  cl := XYToCell(X, Y);
  if (cl.Col <> -1) and (cl.Row <> -1) then
  begin
    Result.StartTime := CellToDateTime(cl);
    Result.EndTime := CellToDateTime(MakeCell(cl.Col, cl.Row + 1))
  end
  else
  begin
    Result.StartTime := -1;
    Result.EndTime := -1;
  end;
end;

function TTMSFNCCustomPlanner.XYToCacheItem(X,
  Y: Double): TTMSFNCPlannerCacheItem;
var
  I: Integer;
  dsp: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  if not Assigned(FItemDisplay) or not Assigned(FFullDaysItemTopDisplay) or not Assigned(FFullDaysItemBottomDisplay) then
    Exit;

  for I := FItemDisplay.Count - 1 downto 0 do
  begin
    dsp := FItemDisplay[I];
    if PtInRectEx(dsp.DrawRect, PointF(X, Y)) then
    begin
      Result := dsp;
      Break;
    end;
  end;

  if not Assigned(Result) then
  begin
    for I := FFullDaysItemTopDisplay.Count - 1 downto 0 do
    begin
      dsp := FFullDaysItemTopDisplay[I];
      if PtInRectEx(dsp.DrawRect, PointF(X, Y)) then
      begin
        Result := dsp;
        Break;
      end;
    end;
  end;

  if not Assigned(Result) then
  begin
    for I := FFullDaysItemBottomDisplay.Count - 1 downto 0 do
    begin
      dsp := FFullDaysItemBottomDisplay[I];
      if PtInRectEx(dsp.DrawRect, PointF(X, Y)) then
      begin
        Result := dsp;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomPlanner.XYToCell(X, Y: Double): TTMSFNCPlannerCell;
begin
  Result := inherited XYToCell(X, Y);
  if (Result.Col >= 0) and (Result.Col <= ColumnCount - 1) and (Result.Row >= 0) and (Result.Row <= RowCount - 1) then
  begin
    if IsCellDisabled(Result) then
    begin
      Result.Col := -1;
      Result.Row := -1;
    end;
  end;
end;

function TTMSFNCCustomPlanner.XYToItem(X, Y: Double): TTMSFNCPlannerItem;
var
  dsp: TTMSFNCPlannerCacheItem;
begin
  Result := nil;
  dsp := XYToCacheItem(X, Y);
  if Assigned(dsp) then
    Result := dsp.Item;
end;

{ TTMSFNCPlanner }

procedure TTMSFNCPlanner.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCPlanner, TTMSFNCPlannerItem, TTMSFNCPlannerResource]);
end;

{ TTMSFNCPlannerItemsAppearance }

procedure TTMSFNCPlannerItemsAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerItemsAppearance then
  begin
    FGap := (Source as TTMSFNCPlannerItemsAppearance).Gap;
    FMoveAreaSize := (Source as TTMSFNCPlannerItemsAppearance).MoveAreaSize;
    FMoveAreaColor := (Source as TTMSFNCPlannerItemsAppearance).MoveAreaColor;
    FSizeAreaSize := (Source as TTMSFNCPlannerItemsAppearance).SizeAreaSize;
    FDeleteAreaSize := (Source as TTMSFNCPlannerItemsAppearance).DeleteAreaSize;
    FSizeAreaColor := (Source as TTMSFNCPlannerItemsAppearance).SizeAreaColor;
    FDeleteAreaColor := (Source as TTMSFNCPlannerItemsAppearance).DeleteAreaColor;
    FFill.Assign((Source as TTMSFNCPlannerItemsAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).Stroke);
    FFont.Assign((Source as TTMSFNCPlannerItemsAppearance).Font);
    FTitleFill.Assign((Source as TTMSFNCPlannerItemsAppearance).TitleFill);
    FTitleStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).TitleStroke);
    FTitleFont.Assign((Source as TTMSFNCPlannerItemsAppearance).TitleFont);
    FSelectedFill.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedFill);
    FSelectedStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedStroke);
    FSelectedFont.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedFont);
    FSelectedTitleFill.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedTitleFill);
    FSelectedTitleStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedTitleStroke);
    FSelectedTitleFont.Assign((Source as TTMSFNCPlannerItemsAppearance).SelectedTitleFont);
    FActiveFill.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveFill);
    FActiveStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveStroke);
    FActiveFont.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveFont);
    FActiveTitleFill.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveTitleFill);
    FActiveTitleStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveTitleStroke);
    FActiveTitleFont.Assign((Source as TTMSFNCPlannerItemsAppearance).ActiveTitleFont);
    FDisabledFill.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledFill);
    FDisabledStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledStroke);
    FDisabledFont.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledFont);
    FDisabledTitleFill.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledTitleFill);
    FDisabledTitleStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledTitleStroke);
    FDisabledTitleFont.Assign((Source as TTMSFNCPlannerItemsAppearance).DisabledTitleFont);
    FShowItemHelpers := (Source as TTMSFNCPlannerItemsAppearance).ShowItemHelpers;
    FSizeHandlerHeight := (Source as TTMSFNCPlannerItemsAppearance).SizeHandlerHeight;
    FSizeHandlerWidth := (Source as TTMSFNCPlannerItemsAppearance).SizeHandlerWidth;
    FDeleteHandlerHeight := (Source as TTMSFNCPlannerItemsAppearance).DeleteHandlerHeight;
    FDeleteHandlerWidth := (Source as TTMSFNCPlannerItemsAppearance).DeleteHandlerWidth;
    FSizeHandlerUpBitmap.Assign((Source as TTMSFNCPlannerItemsAppearance).SizeHandlerUpBitmap);
    FSizeHandlerDownBitmap.Assign((Source as TTMSFNCPlannerItemsAppearance).SizeHandlerDownBitmap);
    FSizeHandlerLeftBitmap.Assign((Source as TTMSFNCPlannerItemsAppearance).SizeHandlerLeftBitmap);
    FSizeHandlerRightBitmap.Assign((Source as TTMSFNCPlannerItemsAppearance).SizeHandlerRightBitmap);
    FDeleteHandlerBitmap.Assign((Source as TTMSFNCPlannerItemsAppearance).DeleteHandlerBitmap);
    FShowLinks := (Source as TTMSFNCPlannerItemsAppearance).ShowLinks;
    FLinkArrowSize := (Source as TTMSFNCPlannerItemsAppearance).LinkArrowSize;
    FLinkArrowShape := (Source as TTMSFNCPlannerItemsAppearance).LinkArrowShape;
    FLinkStroke.Assign((Source as TTMSFNCPlannerItemsAppearance).LinkStroke);
    FTextVerticalTextAlign := (Source as TTMSFNCPlannerItemsAppearance).TextVerticalTextAlign;
    FTextHorizontalTextAlign := (Source as TTMSFNCPlannerItemsAppearance).TextHorizontalTextAlign;
    FTitleVerticalTextAlign := (Source as TTMSFNCPlannerItemsAppearance).TitleVerticalTextAlign;
    FTitleHorizontalTextAlign := (Source as TTMSFNCPlannerItemsAppearance).TitleHorizontalTextAlign;
    FAlternativeHints := (Source as TTMSFNCPlannerItemsAppearance).AlternativeHints;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

constructor TTMSFNCPlannerItemsAppearance.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FAlternativeHints := True;
  FGap := 10;
  FSizeAreaSize := 4;
  FMoveAreaSize := 4;
  FDeleteAreaSize := 16;
  FShowItemSizeHandlers := True;
  FShowItemHelpers := True;
  FSizeHandlerWidth := 50;
  FSizeHandlerHeight := 30;
  FDeleteHandlerWidth := 30;
  FDeleteHandlerHeight := 30;
  FShowLinks := False;
  FLinkArrowShape := lasNormal;
  FLinkArrowSize := 7;
  FTitleHorizontalTextAlign := gtaLeading;
  FTitleVerticalTextAlign := gtaLeading;
  FTextHorizontalTextAlign := gtaLeading;
  FTextVerticalTextAlign := gtaLeading;
  FSizeHandlerUpBitmap := TTMSFNCBitmap.Create;
  FSizeHandlerUpBitmap.LoadFromResource('', HInstance);
  FSizeHandlerUpBitmap.OnChange := @Changed;
  FSizeHandlerRightBitmap := TTMSFNCBitmap.Create;
  FSizeHandlerRightBitmap.LoadFromResource('', HInstance);
  FSizeHandlerRightBitmap.OnChange := @Changed;
  FDeleteHandlerBitmap := TTMSFNCBitmap.Create;
  FDeleteHandlerBitmap.LoadFromResource('', HInstance);
  FDeleteHandlerBitmap.OnChange := @Changed;
  FSizeHandlerLeftBitmap := TTMSFNCBitmap.Create;
  FSizeHandlerLeftBitmap.LoadFromResource('', HInstance);
  FSizeHandlerLeftBitmap.OnChange := @Changed;
  FSizeHandlerDownBitmap := TTMSFNCBitmap.Create;
  FSizeHandlerDownBitmap.LoadFromResource('', HInstance);
  FSizeHandlerDownBitmap.OnChange := @Changed;
  FMoveAreaColor := TMSFNCPlannerAreaColor;
  FSizeAreaColor := TMSFNCPlannerAreaColor;
  FDeleteAreaColor := gcSteelBlue;
  FShowMoveArea := True;
  FShowSizeArea := True;
  FShowDeleteArea := False;
  FLinkStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FLinkStroke.Width := 2;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcDarkGray);
  FStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FTitleFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FTitleStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FSelectedFill := TTMSFNCGraphicsFill.Create(gfkSolid, TMSFNCPlannerSelectedColor);
  FSelectedStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FSelectedTitleFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FSelectedTitleStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FActiveFill := TTMSFNCGraphicsFill.Create(gfkSolid, TMSFNCPlannerSelectedColor);
  FActiveStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FActiveTitleFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FActiveTitleStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcDarkGray);
  FDisabledStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);
  FDisabledTitleFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcDarkGray);
  FDisabledTitleStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcDarkGray);

  FFont := TTMSFNCGraphicsFont.Create;
  FTitleFont := TTMSFNCGraphicsFont.Create;
  FTitleFont.Style := [TFontStyle.fsBold];
  FSelectedFont := TTMSFNCGraphicsFont.Create;
  FSelectedTitleFont := TTMSFNCGraphicsFont.Create;
  FSelectedTitleFont.Style := [TFontStyle.fsBold];
  FActiveFont := TTMSFNCGraphicsFont.Create;
  FActiveTitleFont := TTMSFNCGraphicsFont.Create;
  FActiveTitleFont.Style := [TFontStyle.fsBold];
  FDisabledFont := TTMSFNCGraphicsFont.Create;
  FDisabledTitleFont := TTMSFNCGraphicsFont.Create;
  FDisabledTitleFont.Style := [TFontStyle.fsBold];

  TTMSFNCUtils.SetFontSize(FTitleFont, 12);
  TTMSFNCUtils.SetFontSize(FSelectedTitleFont, 12);
  TTMSFNCUtils.SetFontSize(FActiveTitleFont, 12);
  TTMSFNCUtils.SetFontSize(FDisabledTitleFont, 12);

  FFill.OnChanged := @Changed;
  FStroke.OnChanged := @Changed;
  FFont.OnChanged := @Changed;

  FTitleFill.OnChanged := @Changed;
  FTitleStroke.OnChanged := @Changed;
  FTitleFont.OnChanged := @Changed;

  FSelectedFill.OnChanged := @Changed;
  FSelectedStroke.OnChanged := @Changed;
  FSelectedFont.OnChanged := @Changed;

  FSelectedTitleFill.OnChanged := @Changed;
  FSelectedTitleStroke.OnChanged := @Changed;
  FSelectedTitleFont.OnChanged := @Changed;

  FActiveFill.OnChanged := @Changed;
  FActiveStroke.OnChanged := @Changed;
  FActiveFont.OnChanged := @Changed;

  FActiveTitleFill.OnChanged := @Changed;
  FActiveTitleStroke.OnChanged := @Changed;
  FActiveTitleFont.OnChanged := @Changed;

  FDisabledFill.OnChanged := @Changed;
  FDisabledStroke.OnChanged := @Changed;
  FDisabledFont.OnChanged := @Changed;

  FDisabledTitleFill.OnChanged := @Changed;
  FDisabledTitleStroke.OnChanged := @Changed;
  FDisabledTitleFont.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerItemsAppearance.Destroy;
begin
  FDeleteHandlerBitmap.Free;
  FSizeHandlerUpBitmap.Free;
  FSizeHandlerLeftBitmap.Free;
  FSizeHandlerDownBitmap.Free;
  FSizeHandlerRightBitmap.Free;

  FFill.Free;
  FStroke.Free;
  FLinkStroke.Free;
  FFont.Free;
  FTitleFill.Free;
  FTitleStroke.Free;
  FTitleFont.Free;

  FSelectedFill.Free;
  FSelectedStroke.Free;
  FSelectedFont.Free;

  FSelectedTitleFill.Free;
  FSelectedTitleStroke.Free;
  FSelectedTitleFont.Free;

  FActiveFill.Free;
  FActiveStroke.Free;
  FActiveFont.Free;

  FActiveTitleFill.Free;
  FActiveTitleStroke.Free;
  FActiveTitleFont.Free;

  FDisabledFill.Free;
  FDisabledStroke.Free;
  FDisabledFont.Free;

  FDisabledTitleFill.Free;
  FDisabledTitleStroke.Free;
  FDisabledTitleFont.Free;
  inherited;
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveFill(const Value: TTMSFNCGraphicsFill);
begin
  if FActiveFill <> Value then
    FActiveFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveFont(const Value: TTMSFNCGraphicsFont);
begin
  if FActiveFont <> Value then
    FActiveFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveTitleFill(const Value: TTMSFNCGraphicsFill);
begin
  if FActiveTitleFill <> Value then
    FActiveTitleFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveTitleFont(const Value: TTMSFNCGraphicsFont);
begin
  if FActiveTitleFont <> Value then
    FActiveTitleFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveTitleStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FActiveTitleStroke <> Value then
    FActiveTitleStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetAlternativeHints(
  const Value: Boolean);
begin
  if FAlternativeHints <> Value then
    FAlternativeHints := Value;
end;

procedure TTMSFNCPlannerItemsAppearance.SetActiveStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FActiveStroke <> Value then
    FActiveStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedFill(const Value: TTMSFNCGraphicsFill);
begin
  if FSelectedFill <> Value then
    FSelectedFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedFont(const Value: TTMSFNCGraphicsFont);
begin
  if FSelectedFont <> Value then
    FSelectedFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedTitleFill(const Value: TTMSFNCGraphicsFill);
begin
  if FSelectedTitleFill <> Value then
    FSelectedTitleFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedTitleFont(const Value: TTMSFNCGraphicsFont);
begin
  if FSelectedTitleFont <> Value then
    FSelectedTitleFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedTitleStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FSelectedTitleStroke <> Value then
    FSelectedTitleStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetShowDeleteArea(const Value: Boolean);
begin
  if FShowDeleteArea <> Value then
  begin
    FShowDeleteArea := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetShowItemHelpers(
  const Value: Boolean);
begin
  if FShowItemHelpers <> Value then
    FShowItemHelpers := Value;
end;

procedure TTMSFNCPlannerItemsAppearance.SetShowLinks(const Value: Boolean);
begin
  if FShowLinks <> Value then
  begin
    FShowLinks := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetShowMoveArea(const Value: Boolean);
begin
  if FShowMoveArea <> Value then
  begin
    FShowMoveArea := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetShowSizeArea(const Value: Boolean);
begin
  if FShowSizeArea <> Value then
  begin
    FShowSizeArea := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSelectedStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FSelectedStroke <> Value then
    FSelectedStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
    FFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetTextHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextHorizontalTextAlign <> Value then
  begin
    FTextHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetTextVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextVerticalTextAlign <> Value then
  begin
    FTextVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetTitleFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTitleFill <> Value then
    FTitleFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetTitleFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTitleFont <> Value then
    FTitleFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetTitleHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleHorizontalTextAlign <> Value then
  begin
    FTitleHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetTitleStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FTitleStroke <> Value then
    FTitleStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetTitleVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleVerticalTextAlign <> Value then
  begin
    FTitleVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDeleteAreaColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDeleteAreaColor <> Value then
  begin
    FDeleteAreaColor := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetDeleteAreaSize(const Value: Double);
begin
  if FDeleteAreaSize <> Value then
  begin
    FDeleteAreaSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetDeleteHandlerBitmap(
  const Value: TTMSFNCBitmap);
begin
  if FDeleteHandlerBitmap <> Value then
  begin
    FDeleteHandlerBitmap.Assign(Value);
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetDeleteHandlerHeight(
  const Value: Double);
begin
  if FDeleteHandlerHeight <> Value then
  begin
    FDeleteHandlerHeight := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetDeleteHandlerWidth(
  const Value: Double);
begin
  if FDeleteHandlerWidth <> Value then
  begin
    FDeleteHandlerWidth := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledFill(const Value: TTMSFNCGraphicsFill);
begin
  if FDisabledFill <> Value then
    FDisabledFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledFont(const Value: TTMSFNCGraphicsFont);
begin
  if FDisabledFont <> Value then
    FDisabledFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledTitleFill(const Value: TTMSFNCGraphicsFill);
begin
  if FDisabledTitleFill <> Value then
    FDisabledTitleFill.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledTitleFont(const Value: TTMSFNCGraphicsFont);
begin
  if FDisabledTitleFont <> Value then
    FDisabledTitleFont.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledTitleStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FDisabledTitleStroke <> Value then
    FDisabledTitleStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FDisabledStroke <> Value then
    FDisabledStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetGap(const Value: Double);
begin
  if FGap <> Value then
  begin
    FGap := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetLinkArrowShape(
  const Value: TTMSFNCPlannerLinkArrowShape);
begin
  if FLinkArrowShape <> Value then
  begin
    FLinkArrowShape := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetLinkArrowSize(const Value: Double);
begin
  if FLinkArrowSize <> Value then
  begin
    FLinkArrowSize := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetLinkStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FLinkStroke <> Value then
    FLinkStroke.Assign(Value);
end;

procedure TTMSFNCPlannerItemsAppearance.SetMoveAreaSize(const Value: Double);
begin
  if FMoveAreaSize <> Value then
  begin
    FMoveAreaSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetMoveAreaColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMoveAreaColor <> Value then
  begin
    FMoveAreaColor := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeAreaSize(const Value: Double);
begin
  if FSizeAreaSize <> Value then
  begin
    FSizeAreaSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeAreaColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSizeAreaColor <> Value then
  begin
    FSizeAreaColor := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerDownBitmap(
  const Value: TTMSFNCBitmap);
begin
  if FSizeHandlerDownBitmap <> Value then
  begin
    FSizeHandlerDownBitmap.Assign(Value);
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerLeftBitmap(
  const Value: TTMSFNCBitmap);
begin
  if FSizeHandlerLeftBitmap <> Value then
  begin
    FSizeHandlerLeftBitmap.Assign(Value);
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerHeight(
  const Value: Double);
begin
  if FSizeHandlerHeight <> Value then
  begin
    FSizeHandlerHeight := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerRightBitmap(
  const Value: TTMSFNCBitmap);
begin
  if FSizeHandlerRightBitmap <> Value then
  begin
    FSizeHandlerRightBitmap.Assign(Value);
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerWidth(
  const Value: Double);
begin
  if FSizeHandlerHeight <> Value then
  begin
    FSizeHandlerWidth := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerItemsAppearance.SetSizeHandlerUpBitmap(
  const Value: TTMSFNCBitmap);
begin
  if FSizeHandlerUpBitmap <> Value then
  begin
    FSizeHandlerUpBitmap.Assign(Value);
    FPlanner.UpdatePlannerCache;
  end;
end;

{ TTMSFNCPlannerDisplay }

procedure TTMSFNCPlannerTimeLine.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerTimeLine then
  begin
    FCurrentTimeMode := (Source as TTMSFNCPlannerTimeLine).CurrentTimeMode;
    FCurrentTimePosition := (Source as TTMSFNCPlannerTimeLine).CurrentTimePosition;
    FDisplayUnit := (Source as TTMSFNCPlannerTimeLine).DisplayUnit;
    FDisplayUnitSize := (Source as TTMSFNCPlannerTimeLine).DisplayUnitSize;
    FDisplaySubUnitFormat := (Source as TTMSFNCPlannerTimeLine).DisplaySubUnitFormat;
    FDisplayUnitFormat := (Source as TTMSFNCPlannerTimeLine).DisplayUnitFormat;
    FDisplayUnitType := (Source as TTMSFNCPlannerTimeLine).DisplayUnitType;
    FDisplayOffsetType := (Source as TTMSFNCPlannerTimeLine).DisplayOffsetType;
    FDisplayOffset := (Source as TTMSFNCPlannerTimeLine).DisplayOffset;
    FDisplayStart := (Source as TTMSFNCPlannerTimeLine).DisplayStart;
    FDisplayEnd := (Source as TTMSFNCPlannerTimeLine).DisplayEnd;
    FActiveStart := (Source as TTMSFNCPlannerTimeLine).ActiveStart;
    FActiveEnd := (Source as TTMSFNCPlannerTimeLine).ActiveEnd;
    FViewStart := (Source as TTMSFNCPlannerTimeLine).ViewStart;
  end;
end;

constructor TTMSFNCPlannerTimeLine.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FCurrentTimeMode := pctmLine;
  FCurrentTimePosition := pctpUnderItems;
  FDisplayUnit := 30;
  FDisplayUnitSize := 25;
  FDisplayStart := 0;
  FDisplayEnd := 47;
  FActiveStart := 16;
  FActiveEnd := 40;
  FDisplaySubUnitFormat := '';
  FDisplayUnitType := pduMinute;
  FDisplayOffsetType := pduMinute;
  FDisplayOffset := 0;
  FViewStart := Int(Now);
end;

procedure TTMSFNCPlannerTimeLine.SetViewStart(const Value: TDateTime);
begin
  if FViewStart <> Value then
  begin
    FViewStart := Value;
    FPlanner.UpdateScrollPosition;
    FPlanner.UpdateDisplay;
  end;
end;

function TTMSFNCPlannerTimeLine.GetViewStart: TDateTime;
begin
  Result := FViewStart;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayUnit(const Value: Integer);
begin
  if FDisplayUnit <> Value then
  begin
    FDisplayUnit := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplaySubUnitFormat(const Value: String);
begin
  if FDisplaySubUnitFormat <> Value then
  begin
    FDisplaySubUnitFormat := Value;
    FPlanner.UpdatePlannerCache(False);
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetActiveEnd(const Value: Integer);
begin
  if FActiveEnd <> Value then
  begin
    FActiveEnd := Value;
    FPlanner.UpdatePlannerCache(False);
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetActiveStart(const Value: Integer);
begin
  if FActiveStart <> Value then
  begin
    FActiveStart := Value;
    FPlanner.UpdatePlannerCache(False);
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetCurrentTimeMode(
  const Value: TTMSFNCPlannerCurrentTimeMode);
begin
  if FCurrentTimeMode <> Value then
  begin
    FCurrentTimeMode := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetCurrentTimePosition(
  const Value: TTMSFNCPlannerCurrentTimePosition);
begin
  if FCurrentTimePosition <> Value then
  begin
    FCurrentTimePosition := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayEnd(const Value: Integer);
begin
  if FDisplayEnd <> Value then
  begin
    FDisplayEnd := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayUnitFormat(const Value: String);
begin
  if FDisplayUnitFormat <> Value then
  begin
    FDisplayUnitFormat := Value;
    FPlanner.UpdatePlannerCache(False);
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayOffset(const Value: Integer);
begin
  if FDisplayOffset <> Value then
  begin
    FDisplayOffset := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayOffsetType(
  const Value: TTMSFNCPlannerDisplayUnitType);
begin
  if FDisplayOffsetType <> Value then
  begin
    FDisplayOffsetType := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayStart(const Value: Integer);
begin
  if FDisplayStart <> Value then
  begin
    FDisplayStart := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayUnitSize(const Value: Double);
begin
  if FDisplayUnitSize <> Value then
  begin
    FDisplayUnitSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLine.SetDisplayUnitType(
  const Value: TTMSFNCPlannerDisplayUnitType);
begin
  if FDisplayUnitType <> Value then
  begin
    FDisplayUnitType := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

{ TTMSFNCPlannerTimeLineAppearance }

procedure TTMSFNCPlannerTimeLineAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerTimeLineAppearance then
  begin
    FLayouts := (Source as TTMSFNCPlannerTimeLineAppearance).Layouts;
    FRightSize := (Source as TTMSFNCPlannerTimeLineAppearance).RightSize;
    FLeftSize := (Source as TTMSFNCPlannerTimeLineAppearance).LeftSize;
    FRightFill.Assign((Source as TTMSFNCPlannerTimeLineAppearance).RightFill);
    FRightStroke.Assign((Source as TTMSFNCPlannerTimeLineAppearance).RightStroke);
    FRightSubStroke.Assign((Source as TTMSFNCPlannerTimeLineAppearance).RightSubStroke);
    FLeftFill.Assign((Source as TTMSFNCPlannerTimeLineAppearance).LeftFill);
    FLeftFont.Assign((Source as TTMSFNCPlannerTimeLineAppearance).LeftFont);
    FLeftStroke.Assign((Source as TTMSFNCPlannerTimeLineAppearance).LeftStroke);
    FLeftSubStroke.Assign((Source as TTMSFNCPlannerTimeLineAppearance).LeftSubStroke);
    FRightFont.Assign((Source as TTMSFNCPlannerTimeLineAppearance).RightFont);
    FRightSubUnitFontSize := (Source as TTMSFNCPlannerTimeLineAppearance).RightSubUnitFontSize;
    FLeftSubUnitFontSize := (Source as TTMSFNCPlannerTimeLineAppearance).LeftSubUnitFontSize;
    FCurrentTimeColor := (Source as TTMSFNCPlannerTimeLineAppearance).CurrentTimeColor;
    FStretch := (Source as TTMSFNCPlannerTimeLineAppearance).Stretch;
    FLeftVerticalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).LeftVerticalTextAlign;
    FLeftSubVerticalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).LeftSubVerticalTextAlign;
    FLeftHorizontalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).LeftHorizontalTextAlign;
    FLeftSubHorizontalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).LeftSubHorizontalTextAlign;
    FRightVerticalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).RightVerticalTextAlign;
    FRightSubVerticalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).RightSubVerticalTextAlign;
    FRightHorizontalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).RightHorizontalTextAlign;
    FRightSubHorizontalTextAlign := (Source as TTMSFNCPlannerTimeLineAppearance).RightSubHorizontalTextAlign;
    FLeftSubVerticalTextMode := (Source as TTMSFNCPlannerTimeLineAppearance).LeftSubVerticalTextMode;
    FRightSubVerticalTextMode := (Source as TTMSFNCPlannerTimeLineAppearance).RightSubVerticalTextMode;
    FLeftVerticalTextMode := (Source as TTMSFNCPlannerTimeLineAppearance).LeftVerticalTextMode;
    FRightVerticalTextMode := (Source as TTMSFNCPlannerTimeLineAppearance).RightVerticalTextMode;
  end;
end;

constructor TTMSFNCPlannerTimeLineAppearance.Create(
  APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FStretch := False;
  FCurrentTimeColor := gcOrange;
  FLayouts := [ptlLeft];
  FLeftSize := 50;
  FRightSize := 50;
  FLeftVerticalTextMode := pvtmNone;
  FRightVerticalTextMode := pvtmNone;
  FLeftSubVerticalTextMode := pvtmNone;
  FRightSubVerticalTextMode := pvtmNone;
  FLeftHorizontalTextAlign := gtaLeading;
  FLeftVerticalTextAlign := gtaLeading;
  FRightHorizontalTextAlign := gtaLeading;
  FRightVerticalTextAlign := gtaLeading;
  FLeftSubHorizontalTextAlign := gtaTrailing;
  FLeftSubVerticalTextAlign := gtaTrailing;
  FRightSubHorizontalTextAlign := gtaTrailing;
  FRightSubVerticalTextAlign := gtaTrailing;
  FRightFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FRightStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FRightSubStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightGray);
  FLeftFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FLeftStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FLeftSubStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightGray);

  FLeftFont := TTMSFNCGraphicsFont.Create;
  FLeftFont.Color := gcGray;
  FRightFont := TTMSFNCGraphicsFont.Create;
  FRightFont.Color := gcGray;

  TTMSFNCUtils.SetFontSize(FLeftFont, 18);
  TTMSFNCUtils.SetFontSize(FRightFont, 18);
  FLeftSubUnitFontSize := (FLeftFont.Size * 90) / 100;
  FRightSubUnitFontSize := (FRightFont.Size * 90) / 100;

  {$IFDEF CMNWEBLIB}
  FLeftSubUnitFontSize := Round(FLeftSubUnitFontSize / 96 * 72);
  FRightSubUnitFontSize := Round(FRightSubUnitFontSize / 96 * 72);
  {$ENDIF}

  FRightFont.OnChanged := @Changed;
  FLeftFont.OnChanged := @Changed;
  FRightFill.OnChanged := @Changed;
  FLeftFill.OnChanged := @Changed;
  FRightStroke.OnChanged := @Changed;
  FRightSubStroke.OnChanged := @Changed;
  FLeftStroke.OnChanged := @Changed;
  FLeftSubStroke.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerTimeLineAppearance.Destroy;
begin
  FLeftFont.Free;
  FRightFont.Free;
  FRightStroke.Free;
  FRightSubStroke.Free;
  FRightFill.Free;
  FLeftStroke.Free;
  FLeftSubStroke.Free;
  FLeftFill.Free;
  inherited;
end;

procedure TTMSFNCPlannerTimeLineAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSize(const Value: Double);
begin
  if FLeftSize <> Value then
  begin
    FLeftSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetCurrentTimeColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FCurrentTimeColor <> Value then
  begin
    FCurrentTimeColor := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLayouts(
  const Value: TTMSFNCPlannerTimeLineLayouts);
begin
  if FLayouts <> Value then
  begin
    FLayouts := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSize(const Value: Double);
begin
  if FRightSize <> Value then
  begin
    FRightSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftFill(const Value: TTMSFNCGraphicsFill);
begin
  if FLeftFill <> Value then
    FLeftFill.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftFont(const Value: TTMSFNCGraphicsFont);
begin
  if FLeftFont <> Value then
    FLeftFont.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FLeftHorizontalTextAlign <> Value then
  begin
    FLeftHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSubHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FLeftSubHorizontalTextAlign <> Value then
  begin
    FLeftSubHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSubStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FLeftSubStroke <> Value then
    FLeftSubStroke.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSubUnitFontSize(
  const Value: Double);
begin
  if FLeftSubUnitFontSize <> Value then
  begin
    FLeftSubUnitFontSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSubVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FLeftSubVerticalTextAlign <> Value then
  begin
    FLeftSubVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftSubVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FLeftSubVerticalTextMode <> Value then
  begin
    FLeftSubVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FLeftVerticalTextAlign <> Value then
  begin
    FLeftVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FLeftVerticalTextMode <> Value then
  begin
    FLeftVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetLeftStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FLeftStroke <> Value then
    FLeftStroke.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightFill(const Value: TTMSFNCGraphicsFill);
begin
  if FRightFill <> Value then
    FRightFill.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FRightHorizontalTextAlign <> Value then
  begin
    FRightHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSubStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FRightSubStroke <> Value then
    FRightSubStroke.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSubUnitFontSize(
  const Value: Double);
begin
  if FRightSubUnitFontSize <> Value then
  begin
    FRightSubUnitFontSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSubHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FRightSubHorizontalTextAlign <> Value then
  begin
    FRightSubHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSubVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FRightSubVerticalTextAlign <> Value then
  begin
    FRightSubVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightSubVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FRightSubVerticalTextMode <> Value then
  begin
    FRightSubVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FRightVerticalTextAlign <> Value then
  begin
    FRightVerticalTextAlign := Value;
    Changed(Self);
  end;
end;
procedure TTMSFNCPlannerTimeLineAppearance.SetRightVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FRightVerticalTextMode <> Value then
  begin
    FRightVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightFont(const Value: TTMSFNCGraphicsFont);
begin
  if FRightFont <> Value then
    FRightFont.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetRightStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FRightStroke <> Value then
    FRightStroke.Assign(Value);
end;

procedure TTMSFNCPlannerTimeLineAppearance.SetStretch(const Value: Boolean);
begin
  if FStretch <> Value then
  begin
    FStretch := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

{ TTMSFNCPlannerPositionsAppearance }

procedure TTMSFNCPlannerPositionsAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerPositionsAppearance then
  begin
    FLayouts := (Source as TTMSFNCPlannerPositionsAppearance).Layouts;
    FTopSize := (Source as TTMSFNCPlannerPositionsAppearance).TopSize;
    FSize := (Source as TTMSFNCPlannerPositionsAppearance).Size;
    FBottomSize := (Source as TTMSFNCPlannerPositionsAppearance).BottomSize;
    FBottomFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomFill);
    FBottomFont.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomFont);
    FBottomStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomStroke);
    FTopFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopFill);
    FTopStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopStroke);
    FTopNavigationButtonFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonFill);
    FTopNavigationButtonStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonStroke);
    FBottomNavigationButtonFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonFill);
    FBottomNavigationButtonStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonStroke);
    FTopNavigationButtonHoverFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonHoverFill);
    FTopNavigationButtonHoverStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonHoverStroke);
    FBottomNavigationButtonHoverFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonHoverFill);
    FBottomNavigationButtonHoverStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonHoverStroke);
    FTopNavigationButtonDownFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonDownFill);
    FTopNavigationButtonDownStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopNavigationButtonDownStroke);
    FBottomNavigationButtonDownFill.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonDownFill);
    FBottomNavigationButtonDownStroke.Assign((Source as TTMSFNCPlannerPositionsAppearance).BottomNavigationButtonDownStroke);
    FTopFont.Assign((Source as TTMSFNCPlannerPositionsAppearance).TopFont);
    FStretch := (Source as TTMSFNCPlannerPositionsAppearance).Stretch;
    FTopHorizontalTextAlign := (Source as TTMSFNCPlannerPositionsAppearance).TopHorizontalTextAlign;
    FTopVerticalTextAlign := (Source as TTMSFNCPlannerPositionsAppearance).TopVerticalTextAlign;
    FBottomHorizontalTextAlign := (Source as TTMSFNCPlannerPositionsAppearance).BottomHorizontalTextAlign;
    FBottomVerticalTextAlign := (Source as TTMSFNCPlannerPositionsAppearance).BottomVerticalTextAlign;
    FTopVerticalTextMode := (Source as TTMSFNCPlannerPositionsAppearance).TopVerticalTextMode;
    FBottomVerticalTextMode := (Source as TTMSFNCPlannerPositionsAppearance).BottomVerticalTextMode;
    FFillEmptySpaces := (Source as TTMSFNCPlannerPositionsAppearance).FillEmptySpaces;
  end;
end;

constructor TTMSFNCPlannerPositionsAppearance.Create(
  APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FLayouts := [pplTop];
  FFillEmptySpaces := True;
  FStretch := True;
  FBottomSize := 50;
  FTopVerticalTextMode := pvtmAuto;
  FBottomVerticalTextMode := pvtmAuto;
  FTopSize := 50;
  FSize := 100;
  FTopHorizontalTextAlign := gtaCenter;
  FTopVerticalTextAlign := gtaCenter;
  FBottomHorizontalTextAlign := gtaCenter;
  FBottomVerticalTextAlign := gtaCenter;
  FBottomFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FBottomStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FTopStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FBottomNavigationButtonFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FBottomNavigationButtonStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopNavigationButtonFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FTopNavigationButtonStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FBottomNavigationButtonHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(225, 245, 255));
  FBottomNavigationButtonHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopNavigationButtonHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(225, 245, 255));
  FTopNavigationButtonHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FBottomNavigationButtonDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(149, 213, 246));
  FBottomNavigationButtonDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopNavigationButtonDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(149, 213, 246));
  FTopNavigationButtonDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFont := TTMSFNCGraphicsFont.Create;
  FTopFont.Color := gcGray;
  FBottomFont := TTMSFNCGraphicsFont.Create;
  FBottomFont.Color := gcGray;

  FTopLeftNavigationButtonSize := 18;
  FTopRightNavigationButtonSize := 18;
  FBottomLeftNavigationButtonSize := 18;
  FBottomRightNavigationButtonSize := 18;

  FBottomFill.OnChanged := @Changed;
  FTopFill.OnChanged := @Changed;
  FBottomStroke.OnChanged := @Changed;
  FTopStroke.OnChanged := @Changed;
  FBottomNavigationButtonFill.OnChanged := @Changed;
  FTopNavigationButtonFill.OnChanged := @Changed;
  FBottomNavigationButtonStroke.OnChanged := @Changed;
  FTopNavigationButtonStroke.OnChanged := @Changed;
  FBottomNavigationButtonHoverFill.OnChanged := @Changed;
  FTopNavigationButtonHoverFill.OnChanged := @Changed;
  FBottomNavigationButtonHoverStroke.OnChanged := @Changed;
  FTopNavigationButtonHoverStroke.OnChanged := @Changed;
  FBottomNavigationButtonDownFill.OnChanged := @Changed;
  FTopNavigationButtonDownFill.OnChanged := @Changed;
  FBottomNavigationButtonDownStroke.OnChanged := @Changed;
  FTopNavigationButtonDownStroke.OnChanged := @Changed;
  FTopFont.OnChanged := @Changed;
  FBottomFont.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerPositionsAppearance.Destroy;
begin
  FTopNavigationButtonStroke.Free;
  FTopNavigationButtonFill.Free;
  FBottomNavigationButtonStroke.Free;
  FBottomNavigationButtonFill.Free;
  FBottomNavigationButtonHoverFill.Free;
  FTopNavigationButtonHoverFill.Free;
  FBottomNavigationButtonHoverStroke.Free;
  FTopNavigationButtonHoverStroke.Free;
  FBottomNavigationButtonDownFill.Free;
  FTopNavigationButtonDownFill.Free;
  FBottomNavigationButtonDownStroke.Free;
  FTopNavigationButtonDownStroke.Free;
  FTopFont.Free;
  FBottomFont.Free;
  FBottomFill.Free;
  FTopFill.Free;
  FBottomStroke.Free;
  FTopStroke.Free;
  inherited;
end;

procedure TTMSFNCPlannerPositionsAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FBottomNavigationButtonDownFill <> Value then
    FBottomNavigationButtonDownFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomNavigationButtonDownStroke <> Value then
    FBottomNavigationButtonDownStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomNavigationButtonFill <> Value then
    FBottomNavigationButtonFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FBottomNavigationButtonHoverFill <> Value then
    FBottomNavigationButtonHoverFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomNavigationButtonStroke <> Value then
    FBottomNavigationButtonHoverStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomFill <> Value then
    FBottomFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomFont(const Value: TTMSFNCGraphicsFont);
begin
  if FBottomFont <> Value then
    FBottomFont.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FBottomHorizontalTextAlign <> Value then
  begin
    FBottomHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomLeftNavigationButtonSize(
  const Value: Double);
begin
  if FBottomLeftNavigationButtonSize <> Value then
  begin
    FBottomLeftNavigationButtonSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomSize(const Value: Double);
begin
  if FBottomSize <> Value then
  begin
    FBottomSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomNavigationButtonStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomNavigationButtonStroke <> Value then
    FBottomNavigationButtonStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomRightNavigationButtonSize(
  const Value: Double);
begin
  if FBottomRightNavigationButtonSize <> Value then
  begin
    FBottomRightNavigationButtonSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomStroke <> Value then
    FBottomStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FBottomVerticalTextAlign <> Value then
  begin
    FBottomVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetBottomVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FBottomVerticalTextMode <> Value then
  begin
    FBottomVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetFillEmptySpaces(
  const Value: Boolean);
begin
  if FFillEmptySpaces <> Value then
  begin
    FFillEmptySpaces := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTopHorizontalTextAlign <> Value then
  begin
    FTopHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopLeftNavigationButtonSize(
  const Value: Double);
begin
  if FTopLeftNavigationButtonSize <> Value then
  begin
    FTopLeftNavigationButtonSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetLayouts(
  const Value: TTMSFNCPlannerPositionsLayouts);
begin
  if FLayouts <> Value then
  begin
    FLayouts := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetSize(const Value: Double);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetStretch(const Value: Boolean);
begin
  if FStretch <> Value then
  begin
    FStretch := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopFill <> Value then
    FTopFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FTopNavigationButtonDownFill <> Value then
    FTopNavigationButtonDownFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopNavigationButtonDownStroke <> Value then
    FTopNavigationButtonDownStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopNavigationButtonFill <> Value then
    FTopNavigationButtonFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FTopNavigationButtonHoverFill <> Value then
    FTopNavigationButtonHoverFill.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopNavigationButtonHoverStroke <> Value then
    FTopNavigationButtonHoverStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTopFont <> Value then
    FTopFont.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopSize(const Value: Double);
begin
  if FTopSize <> Value then
  begin
    FTopSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopStroke <> Value then
    FTopStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopNavigationButtonStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopNavigationButtonStroke <> Value then
    FTopNavigationButtonStroke.Assign(Value);
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopRightNavigationButtonSize(
  const Value: Double);
begin
  if FTopRightNavigationButtonSize <> Value then
  begin
    FTopRightNavigationButtonSize := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTopVerticalTextAlign <> Value then
  begin
    FTopVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerPositionsAppearance.SetTopVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FTopVerticalTextMode <> Value then
  begin
    FTopVerticalTextMode := Value;
    Changed(Self);
  end;
end;

{ TTMSFNCPlannerGroupsAppearance }

procedure TTMSFNCPlannerGroupsAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerGroupsAppearance then
  begin
    FLayouts := (Source as TTMSFNCPlannerGroupsAppearance).Layouts;
    FTopSize := (Source as TTMSFNCPlannerGroupsAppearance).TopSize;
    FBottomSize := (Source as TTMSFNCPlannerGroupsAppearance).BottomSize;
    FBottomFill.Assign((Source as TTMSFNCPlannerGroupsAppearance).BottomFill);
    FBottomFont.Assign((Source as TTMSFNCPlannerGroupsAppearance).BottomFont);
    FBottomStroke.Assign((Source as TTMSFNCPlannerGroupsAppearance).BottomStroke);
    FTopFill.Assign((Source as TTMSFNCPlannerGroupsAppearance).TopFill);
    FTopStroke.Assign((Source as TTMSFNCPlannerGroupsAppearance).TopStroke);
    FTopFont.Assign((Source as TTMSFNCPlannerGroupsAppearance).TopFont);
    FTopHorizontalTextAlign := (Source as TTMSFNCPlannerGroupsAppearance).TopHorizontalTextAlign;
    FTopVerticalTextAlign := (Source as TTMSFNCPlannerGroupsAppearance).TopVerticalTextAlign;
    FBottomHorizontalTextAlign := (Source as TTMSFNCPlannerGroupsAppearance).BottomHorizontalTextAlign;
    FBottomVerticalTextAlign := (Source as TTMSFNCPlannerGroupsAppearance).BottomVerticalTextAlign;
    FTopVerticalTextMode := (Source as TTMSFNCPlannerGroupsAppearance).TopVerticalTextMode;
    FBottomVerticalTextMode := (Source as TTMSFNCPlannerGroupsAppearance).BottomVerticalTextMode;
    FFillEmptySpaces := (Source as TTMSFNCPlannerGroupsAppearance).FillEmptySpaces;
  end;
end;

constructor TTMSFNCPlannerGroupsAppearance.Create(
  APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FLayouts := [pglTop];
  FFillEmptySpaces := True;
  FTopSize := 50;
  FBottomSize := 50;
  FTopVerticalTextMode := pvtmAuto;
  FBottomVerticalTextMode := pvtmAuto;
  FTopHorizontalTextAlign := gtaCenter;
  FTopVerticalTextAlign := gtaCenter;
  FBottomHorizontalTextAlign := gtaCenter;
  FBottomVerticalTextAlign := gtaCenter;
  FBottomFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FBottomStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FTopStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);

  FTopFont := TTMSFNCGraphicsFont.Create;
  FTopFont.Color := gcGray;
  FBottomFont := TTMSFNCGraphicsFont.Create;
  FBottomFont.Color := gcGray;

  FTopFont.OnChanged := @Changed;
  FBottomFont.OnChanged := @Changed;
  FBottomFill.OnChanged := @Changed;
  FTopFill.OnChanged := @Changed;
  FBottomStroke.OnChanged := @Changed;
  FTopStroke.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerGroupsAppearance.Destroy;
begin
  FBottomFont.Free;
  FTopFont.Free;
  FBottomFill.Free;
  FBottomStroke.Free;
  FTopFill.Free;
  FTopStroke.Free;
  inherited;
end;

procedure TTMSFNCPlannerGroupsAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomFill <> Value then
    FBottomFill.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FBottomHorizontalTextAlign <> Value then
  begin
    FBottomHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomFont(const Value: TTMSFNCGraphicsFont);
begin
  if FBottomFont <> Value then
    FBottomFont.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomSize(const Value: Double);
begin
  if FBottomSize <> Value then
  begin
    FBottomSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomStroke <> Value then
    FBottomStroke.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FBottomVerticalTextAlign <> Value then
  begin
    FBottomVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetBottomVerticalTextMode(
  const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FBottomVerticalTextMode <> Value then
  begin
    FBottomVerticalTextMode := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetFillEmptySpaces(
  const Value: Boolean);
begin
  if FFillEmptySpaces <> Value then
  begin
    FFillEmptySpaces := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetLayouts(
  const Value: TTMSFNCPlannerGroupsLayouts);
begin
  if FLayouts <> Value then
  begin
    FLayouts := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopFill <> Value then
    FTopFill.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTopFont <> Value then
    FTopFont.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTopHorizontalTextAlign <> Value then
  begin
    FTopHorizontalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopSize(const Value: Double);
begin
  if FTopSize <> Value then
  begin
    FTopSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopStroke <> Value then
    FTopStroke.Assign(Value);
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTopVerticalTextAlign <> Value then
  begin
    FTopVerticalTextAlign := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCPlannerGroupsAppearance.SetTopVerticalTextMode(const Value: TTMSFNCPlannerVerticalTextMode);
begin
  if FTopVerticalTextMode <> Value then
  begin
    FTopVerticalTextMode := Value;
    Changed(Self);
  end;
end;

{ TTMSFNCPlannerFullDaysAppearance }

procedure TTMSFNCPlannerFullDaysAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerFullDaysAppearance then
  begin
    FLayouts := (Source as TTMSFNCPlannerFullDaysAppearance).Layouts;
    FTopSize := (Source as TTMSFNCPlannerFullDaysAppearance).TopSize;
    FBottomSize := (Source as TTMSFNCPlannerFullDaysAppearance).BottomSize;
    FBottomFill.Assign((Source as TTMSFNCPlannerFullDaysAppearance).BottomFill);
    FBottomStroke.Assign((Source as TTMSFNCPlannerFullDaysAppearance).BottomStroke);
    FTopFill.Assign((Source as TTMSFNCPlannerFullDaysAppearance).TopFill);
    FTopStroke.Assign((Source as TTMSFNCPlannerFullDaysAppearance).TopStroke);
    FFillEmptySpaces := (Source as TTMSFNCPlannerFullDaysAppearance).FillEmptySpaces;
    FAutoSize := (Source as TTMSFNCPlannerFullDaysAppearance).AutoSize;
    FAutoItemHeight := (Source as TTMSFNCPlannerFullDaysAppearance).AutoItemHeight;
  end;
end;

constructor TTMSFNCPlannerFullDaysAppearance.Create(
  APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FLayouts := [pfdlTop];
  FAutoSize := True;
  FAutoItemHeight := 25;
  FFillEmptySpaces := True;
  FTopSize := 25;
  FBottomSize := 25;
  FBottomFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FBottomStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FTopStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFont := TTMSFNCGraphicsFont.Create;
  FBottomFont := TTMSFNCGraphicsFont.Create;
end;

destructor TTMSFNCPlannerFullDaysAppearance.Destroy;
begin
  FTopFont.Free;
  FBottomFont.Free;
  FBottomFill.Free;
  FBottomStroke.Free;
  FTopFill.Free;
  FTopStroke.Free;
  inherited;
end;

procedure TTMSFNCPlannerFullDaysAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetAutoItemHeight(
  const Value: Double);
begin
  if FAutoItemHeight <> Value then
  begin
    FAutoItemHeight := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetBottomFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomFill <> Value then
    FBottomFill.Assign(Value);
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetBottomFont(const Value: TTMSFNCGraphicsFont);
begin
  FBottomFont.Assign(Value);
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetBottomSize(const Value: Double);
begin
  if FBottomSize <> Value then
  begin
    FBottomSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomStroke <> Value then
    FBottomStroke.Assign(Value);
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetFillEmptySpaces(const Value: Boolean);
begin
  if FFillEmptySpaces <> Value then
  begin
    FFillEmptySpaces := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetLayouts(const Value: TTMSFNCPlannerFullDaysLayouts);
begin
  if FLayouts <> Value then
  begin
    FLayouts := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetTopFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopFill <> Value then
    FTopFill.Assign(Value);
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetTopFont(const Value: TTMSFNCGraphicsFont);
begin
  FTopFont.Assign(Value);
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetTopSize(const Value: Double);
begin
  if FTopSize <> Value then
  begin
    FTopSize := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerFullDaysAppearance.SetTopStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopStroke <> Value then
    FTopStroke.Assign(Value);
end;

{ TTMSFNCPlannerGridCellAppearance }

procedure TTMSFNCPlannerGridCellAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerGridCellAppearance then
  begin
    FFill.Assign((Source as TTMSFNCPlannerGridCellAppearance).Fill);
    FInActiveFill.Assign((Source as TTMSFNCPlannerGridCellAppearance).InActiveFill);
    FDisabledFill.Assign((Source as TTMSFNCPlannerGridCellAppearance).DisabledFill);
    FVerticalStroke.Assign((Source as TTMSFNCPlannerGridCellAppearance).VerticalStroke);
    FHorizontalStroke.Assign((Source as TTMSFNCPlannerGridCellAppearance).HorizontalStroke);
    FHorizontalSubStroke.Assign((Source as TTMSFNCPlannerGridCellAppearance).HorizontalSubStroke);
  end;
end;

constructor TTMSFNCPlannerGridCellAppearance.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FVerticalStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FHorizontalStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FHorizontalSubStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightGray);
  FInactiveFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(225, 245, 255));
  FFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(230, 230, 230));

  FFill.OnChanged := @Changed;
  FInActiveFill.OnChanged := @Changed;
  FDisabledFill.OnChanged := @Changed;
  FVerticalStroke.OnChanged := @Changed;
  FHorizontalStroke.OnChanged := @Changed;
  FHorizontalSubStroke.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerGridCellAppearance.Destroy;
begin
  FFill.Free;
  FInActiveFill.Free;
  FDisabledFill.Free;
  FVerticalStroke.Free;
  FHorizontalStroke.Free;
  FHorizontalSubStroke.Free;
  inherited;
end;

procedure TTMSFNCPlannerGridCellAppearance.SetDisabledFill(const Value: TTMSFNCGraphicsFill);
begin
  if FDisabledFill <> Value then
    FDisabledFill.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.SetHorizontalStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FHorizontalStroke <> Value then
    FHorizontalStroke.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.SetInActiveFill(const Value: TTMSFNCGraphicsFill);
begin
  if FInActiveFill <> Value then
    FInActiveFill.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.SetHorizontalSubStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FHorizontalSubStroke <> Value then
    FHorizontalSubStroke.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.SetVerticalStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FVerticalStroke <> Value then
    FVerticalStroke.Assign(Value);
end;

procedure TTMSFNCPlannerGridCellAppearance.Changed(Sender: TObject);
begin
  FPlanner.UpdatePlannerCache;
end;

{ TTMSFNCPlannerSelectionAppearance }

procedure TTMSFNCPlannerSelectionAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerSelectionAppearance then
  begin
    FFill.Assign((Source as TTMSFNCPlannerSelectionAppearance).Fill);
  end;
end;

procedure TTMSFNCPlannerSelectionAppearance.Changed(Sender: TObject);
begin
  FPlanner.Invalidate;
end;

constructor TTMSFNCPlannerSelectionAppearance.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;

  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(149, 213, 246));
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, MakeGraphicsColor(149, 213, 246));

  FFill.OnChanged := @Changed;
  FStroke.OnChanged := @Changed;
end;

destructor TTMSFNCPlannerSelectionAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCPlannerSelectionAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCPlannerSelectionAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

{ TTMSFNCPlannerModeSettings }

procedure TTMSFNCPlannerModeSettings.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerModeSettings then
  begin
    FStartTime := (Source as TTMSFNCPlannerModeSettings).StartTime;
    FEndTime := (Source as TTMSFNCPlannerModeSettings).EndTime;
    FInActiveDays := (Source as TTMSFNCPlannerModeSettings).InActiveDays;
    FOverlappableItems := (Source as TTMSFNCPlannerModeSettings).OverlappableItems;
  end;
end;

constructor TTMSFNCPlannerModeSettings.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FStartTime := Now;
  FEndTime := Now + 20;
  FInActiveDays := [padSaturday, padSunday];
  FOverlappableItems := True;
end;

procedure TTMSFNCPlannerModeSettings.SetInActiveDays(
  const Value: TTMSFNCPlannerInActiveDays);
begin
  if FInActiveDays <> Value then
  begin
    FInActiveDays := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerModeSettings.SetOverlappableItems(const Value: Boolean);
begin
  if FOverlappableItems <> Value then
    FOverlappableItems := Value;
end;

procedure TTMSFNCPlannerModeSettings.SetEndTime(const Value: TDateTime);
begin
  if FEndTime <> Value then
  begin
    FEndTime := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerModeSettings.SetStartTime(const Value: TDateTime);
begin
  if FStartTime <> Value then
  begin
    FStartTime := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
    FPlanner.TimeLine.ViewStart := Int(FStartTime);
  end;
end;

{ TTMSFNCPlannerInteraction }

procedure TTMSFNCPlannerInteraction.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCPlannerInteraction) then
  begin
    FTopNavigationButtons := (Source as TTMSFNCPlannerInteraction).TopNavigationButtons;
    FBottomNavigationButtons := (Source as TTMSFNCPlannerInteraction).BottomNavigationButtons;
    FSizeMode := (Source as TTMSFNCPlannerInteraction).SizeMode;
    FDeleteMode := (Source as TTMSFNCPlannerInteraction).DeleteMode;
    FMoveMode := (Source as TTMSFNCPlannerInteraction).MoveMode;
    FMouseInsertMode := (Source as TTMSFNCPlannerInteraction).MouseInsertMode;
    FMouseEditMode := (Source as TTMSFNCPlannerInteraction).MouseEditMode;
    FUpdateMode := (Source as TTMSFNCPlannerInteraction).UpdateMode;
    FKeyboardInsertMode := (Source as TTMSFNCPlannerInteraction).KeyboardInsertMode;
    FKeyboardDelete := (Source as TTMSFNCPlannerInteraction).KeyboardDelete;
    FKeyboardEdit := (Source as TTMSFNCPlannerInteraction).KeyboardEdit;
    FMultiSelect := (Source as TTMSFNCPlannerInteraction).MultiSelect;
    FTouchScrolling := (Source as TTMSFNCPlannerInteraction).TouchScrolling;
    FInplaceEditorMode := (Source as TTMSFNCPlannerInteraction).InplaceEditorMode;
    FSwipeToNextDateTime := (Source as TTMSFNCPlannerInteraction).SwipeToNextDateTime;
    FSwipeToPreviousDateTime := (Source as TTMSFNCPlannerInteraction).SwipeToPreviousDateTime;
    FKeepSelection := (Source as TTMSFNCPlannerInteraction).KeepSelection;
    FShowSelection := (Source as TTMSFNCPlannerInteraction).ShowSelection;
    FReadOnly := (Source as TTMSFNCPlannerInteraction).ReadOnly;
    FAutoSelectLinkedItems := (Source as TTMSFNCPlannerInteraction).AutoSelectLinkedItems;
    FAutoDeleteLinkedItems := (Source as TTMSFNCPlannerInteraction).AutoDeleteLinkedItems;
    FAutoOpenURL := (Source as TTMSFNCPlannerInteraction).AutoOpenURL;
    FKeyboardMode := (Source as TTMSFNCPlannerInteraction).KeyboardMode;
  end;
end;

constructor TTMSFNCPlannerInteraction.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FKeyboardMode := pkmDefault;
  FAutoOpenURL := True;
  FSizeMode := psmAuto;
  FDeleteMode := pdmAuto;
  FReadOnly := False;
  FMoveMode := pmmAuto;
  FMouseInsertMode := pmimNone;
  FUpdateMode := pumInplace;
  FTopNavigationButtons := [];
  FBottomNavigationButtons := [];
  FMouseEditMode := pmemSingleClickOnSelectedItem;
  FKeyboardDelete := False;
  FKeyboardInsertMode := pkimNone;
  FSwipeToNextDateTime := True;
  FShowSelection := True;
  FSwipeToPreviousDateTime := True;
  FKeyboardEdit := True;
  FKeepSelection := True;
  FTouchScrolling := True;
  FMultiSelect := False;
  FInplaceEditorMode := piemText;
end;

procedure TTMSFNCPlannerInteraction.SetBottomNavigationButtons(
  const Value: TTMSFNCPlannerNavigationButtons);
begin
  if FBottomNavigationButtons <> Value then
  begin
    FBottomNavigationButtons := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetDeleteMode(
  const Value: TTMSFNCPlannerDeleteMode);
begin
  if FDeleteMode <> Value then
    FDeleteMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetTopNavigationButtons(
  const Value: TTMSFNCPlannerNavigationButtons);
begin
  if FTopNavigationButtons <> Value then
  begin
    FTopNavigationButtons := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetUpdateMode(
  const Value: TTMSFNCPlannerUpdateMode);
begin
  if FUpdateMode <> Value then
    FUpdateMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetInplaceEditorMode(
  const Value: TTMSFNCPlannerInplaceEditorMode);
begin
  if FInplaceEditorMode <> Value then
    FInplaceEditorMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetKeepSelection(const Value: Boolean);
begin
  if FKeepSelection <> Value then
    FKeepSelection := Value;
end;

procedure TTMSFNCPlannerInteraction.SetKeyboardDelete(const Value: Boolean);
begin
  if FKeyboardDelete <> Value then
    FKeyboardDelete := Value;
end;

procedure TTMSFNCPlannerInteraction.SetKeyboardInsertMode(const Value: TTMSFNCPlannerKeyboardInsertMode);
begin
  if FKeyboardInsertMode <> Value then
    FKeyboardInsertMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetKeyboardMode(
  const Value: TTMSFNCPlannerKeyboardMode);
begin
  if FKeyboardMode <> Value then
    FKeyboardMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetKeyboardEdit(const Value: Boolean);
begin
  if FKeyboardEdit <> Value then
    FKeyboardEdit := Value;
end;

procedure TTMSFNCPlannerInteraction.SetMouseEditMode(
  const Value: TTMSFNCPlannerMouseEditMode);
begin
  if FMouseEditMode <> Value then
    FMouseEditMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetMouseInsertMode(
  const Value: TTMSFNCPlannerMouseInsertMode);
begin
  if FMouseInsertMode <> Value then
    FMouseInsertMode := Value;
end;

procedure TTMSFNCPlannerInteraction.SetMultiSelect(const Value: Boolean);
begin
  if FMultiSelect <> Value then
    FMultiSelect := Value;
end;

procedure TTMSFNCPlannerInteraction.SetReadOnly(const Value: Boolean);
begin
  if FReadOnly <> Value then
  begin
    FReadOnly := Value;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetShowSelection(
  const Value: Boolean);
begin
  if FShowSelection <> Value then
  begin
    FShowSelection := Value;
    FPlanner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetSizeMode(
  const Value: TTMSFNCPlannerSizeMode);
begin
  if FSizeMode <> Value then
  begin
    FSizeMode := Value;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetMoveMode(
  const Value: TTMSFNCPlannerMoveMode);
begin
  if FMoveMode <> Value then
  begin
    FMoveMode := Value;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetSwipeToNextDateTime(const Value: Boolean);
begin
  if FSwipeToNextDateTime <> Value then
  begin
    FSwipeToNextDateTime := Value;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetSwipeToPreviousDateTime(const Value: Boolean);
begin
  if FSwipeToPreviousDateTime <> Value then
  begin
    FSwipeToPreviousDateTime := Value;
  end;
end;

procedure TTMSFNCPlannerInteraction.SetTouchScrolling(const Value: Boolean);
begin
  if FTouchScrolling <> Value then
    FTouchScrolling := Value;
end;

{ TTMSFNCPlannerPositions }

procedure TTMSFNCPlannerPositions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCPlannerPositions) then
  begin
    FCount := (Source as TTMSFNCPlannerPositions).Count;
    FFormat := (Source as TTMSFNCPlannerPositions).Format;
    FViewStart := (Source as TTMSFNCPlannerPositions).ViewStart;
  end;
end;

constructor TTMSFNCPlannerPositions.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FCount := 3;
  FViewStart := 0;
end;

function TTMSFNCPlannerPositions.GetViewStart: Integer;
begin
  Result := FViewStart;
end;

procedure TTMSFNCPlannerPositions.SetCount(const Value: Integer);
begin
  if FCount <> Value then
  begin
    FCount := Value;
    FPlanner.FNeedsConflictsUpdate := True;
    FPlanner.FNeedsInitialization := True;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositions.SetFormat(const Value: String);
begin
  if FFormat <> Value then
  begin
    FFormat := Value;
    FPlanner.UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerPositions.SetViewStart(const Value: Integer);
begin
  if FViewStart <> Value then
  begin
    FViewStart := Value;
    FPlanner.UpdateScrollPosition;
    FPlanner.UpdateDisplay;
  end;
end;

{ TTMSFNCPlannerConflict }

constructor TTMSFNCPlannerConflict.Create(APlanner: TTMSFNCCustomPlanner);
begin
  FPlanner := APlanner;
  FNeedsConflictsUpdate := True;
  {$IFNDEF LCLWEBLIB}
  FCompareResourceDates := TDelegatedComparer<TTMSFNCPlannerResourceDate>.Create(
    function(const Item1, Item2: TTMSFNCPlannerResourceDate): Integer
    begin
      Result := CompareDateTime(Item1.DateTime, Item2.DateTime);
    end
    );
  {$ENDIF}
  FItems := TTMSFNCPlannerResourceItems.Create;
  FDates := TTMSFNCPlannerResourceDates.Create{$IFNDEF LCLWEBLIB}(FCompareResourceDates){$ENDIF};
end;

destructor TTMSFNCPlannerConflict.Destroy;
begin
  FDates.Free;
  FItems.Free;
  inherited;
end;

procedure TTMSFNCPlannerConflict.UpdateDates;
var
  I: Integer;
  it: TTMSFNCPlannerItem;
  rcs, rce: TTMSFNCPlannerResourceDate;
begin
  if not Assigned(FPlanner) then
    Exit;

  Dates.Clear;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    if Assigned(it) then
    begin
      rcs.X := 0;
      rcs.Y := 0;
      rcs.Z := nil;
      rcs.DateTime := FPlanner.MaxPositionDateTime(it.StartTime, False, Position);
      if not Dates.IndexOf(rcs) > -1 then
        Dates.Add(rcs);

      rce.X := 0;
      rce.Y := 0;
      rcs.Z := nil;
      rce.DateTime := FPlanner.MaxPositionDateTime(it.EndTime, True, Position);
      if not Dates.IndexOf(rce) > -1 then
        Dates.Add(rce);
    end;
  end;

  {$IFNDEF LCLWEBLIB}
  Dates.Sort(FCompareResourceDates);
  {$ENDIF}
  {$IFDEF LCLWEBLIB}
  {$IFDEF LCLLIB}
  Dates.Sort(@CompareRes);
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Dates.Sort(TListSortCompare(@CompareRes));
  {$ENDIF}
  {$ENDIF}
end;

procedure TTMSFNCPlannerConflict.UpdateItems;
var
  I: Integer;
  it: TTMSFNCPlannerItemOpen;
begin
  if not Assigned(FPlanner) then
    Exit;

  Items.Clear;
  for I := 0 to FPlanner.Items.Count - 1 do
  begin
    it := TTMSFNCPlannerItemOpen(FPlanner.Items[I]);
    if (it.PositionsList.IndexOf(Position) > -1) and FPlanner.IsValidItem(it) and not FPlanner.IsFullDayItem(it) and not it.Background then
      Items.Add(it);
  end;
end;

{ TTMSFNCPlannerSizeHandlerPanel }

constructor TTMSFNCPlannerSizeHandlerPanel.Create(AOwner: TComponent);
begin
  inherited;
  DisableBackground;
  {$IFDEF FMXLIB}
  ClipChildren := True;
  {$ENDIF}
end;

{$IFDEF FMXLIB}
function TTMSFNCPlannerSizeHandlerPanel.GetClipRect: TRectF;
var
  cr, br: TRectF;
begin
  Result := inherited;
  br := RectF(Position.X, Position.Y, Position.X + Width, Position.Y + Height);
  cr := Planner.GetContentClipRect;
  Result.Top := Max(cr.Top - br.Top, 0);
  Result.Bottom := Min(Result.Bottom - (br.Bottom - cr.Bottom), Result.Bottom);
  Result.Left := Max(cr.Left - br.Left, 0);
  Result.Right := Min(Result.Right - (br.Right - cr.Right), Result.Right);
end;
{$ENDIF}

procedure TTMSFNCCustomPlanner.LinkItems(AItems: TTMSFNCPlannerLinkedItemArray; ACircular: Boolean = False; ALinkType: TTMSFNCPlannerItemLinkType = iltNone);
var
  i: integer;
begin
  BeginUpdate;
  for I := 0 to Length(AItems) - 2 do
  begin
    AItems[I].LinkedItem := Items[I + 1];
    AItems[I].LinkType := ALinkType;
  end;

  if ACircular then
  begin
    AItems[Length(AItems) - 1].LinkedItem := AItems[0];
    AItems[Length(AItems) - 1].LinkType := ALinkType;
  end;
  EndUpdate;
end;

procedure TTMSFNCPlannerSizeHandlerPanel.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  bmp: TTMSFNCBitmap;
begin
  inherited;
  if not Assigned(Planner) then
    Exit;

  case Planner.OrientationMode of
    pomHorizontal:
    begin
      case Kind of
        pshpkStartTime: bmp := Planner.ItemsAppearance.SizeHandlerLeftBitmap;
        pshpkEndTime: bmp := Planner.ItemsAppearance.SizeHandlerRightBitmap;
        else
          bmp := nil;
      end;
    end;
    pomVertical:
    begin
      case Kind of
        pshpkStartTime: bmp := Planner.ItemsAppearance.SizeHandlerUpBitmap;
        pshpkEndTime: bmp := Planner.ItemsAppearance.SizeHandlerDownBitmap;
        else
          bmp := nil;
      end;
    end;
    else
      bmp := nil;
  end;

  if Assigned(bmp) then
    AGraphics.DrawBitmap(RectF(0, 0, Width, Height), bmp);
end;

procedure TTMSFNCPlannerSizeHandlerPanel.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  FMouseDown := True;
  FDownPoint := PointF(X, Y);
  if Assigned(Planner) then
  begin
    Planner.FDrawItemHelpers := True;
    Planner.Invalidate;
  end;
  CaptureEx;
end;

procedure TTMSFNCPlannerSizeHandlerPanel.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
var
  {$IFDEF FMXLIB}
  pt: TPointF;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  pt: TPoint;
  {$ENDIF}
  cl, cls, clsn, cle, clen: TTMSFNCPlannerCell;
  m: TTMSFNCPlannerMouseInteractionMode;
  offx, offy: Single;
begin
  inherited;
  if FMouseDown and Assigned(Planner) and Assigned(Planner.ActiveItem) then
  begin
    offx := 0;
    offy := 0;
    case Planner.OrientationMode of
      pomHorizontal:
      begin
        case Kind of
          pshpkStartTime: offx := Width - FDownPoint.X;
          pshpkEndTime: offx := -FDownPoint.X;
        end;
      end;
      pomVertical:
      begin
        case Kind of
          pshpkStartTime: offy := Height - FDownPoint.Y;
          pshpkEndTime: offy := -FDownPoint.Y;
        end;
      end;
    end;

    {$IFDEF FMXLIB}
    pt := Planner.ScreenToLocal(LocalToScreen(PointF(X + offx, Y + offy)));
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    pt := Planner.ScreenToClient(ClientToScreen(Point(Round(X + offx), Round(Y + offy))));
    {$ENDIF}
    cl := Planner.XYToCell(pt.X, pt.Y);

    if (cl.Col <> -1) and (cl.Row <> -1) then
    begin
      cls := Planner.ItemToStartCell(Planner.ActiveItem);
      cle := Planner.ItemToEndCell(Planner.ActiveItem);

      clsn.Col := cls.Col;
      clen.Col := cle.Col;
      clsn.Row := cls.Row;
      clen.Row := cle.Row;

      case Kind of
        pshpkStartTime:
        begin
          if ((cls.Row >= cle.Row) and (cle.Col - cl.Col > 0)) or ((cls.Row < cle.Row) and (cle.Col - cl.Col >= 0)) then
            clsn.Col := cl.Col;

          if clsn.Col = cle.Col then
            clsn.Row := Min(cle.Row - 1, cl.Row)
          else
            clsn.Row := cl.Row;
        end;
        pshpkEndTime:
        begin
          if ((cle.Row <= cls.Row) and (cl.Col - cls.Col > 0)) or ((cle.Row > cls.Row) and (cl.Col - cls.Col >= 0)) then
            clen.Col := cl.Col;

          if clen.Col = cls.Col then
            clen.Row := Max(cls.Row + 1, cl.Row + 1)
          else
            clen.Row := cl.Row + 1;
        end;
      end;

      m := pmmSizeUp;
      Planner.HandleItemMouseInteraction(Planner.ActiveItem, cls, cle, clsn, clen, m);
    end;
  end;
end;

procedure TTMSFNCPlannerSizeHandlerPanel.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  ReleaseCaptureEx;

  FMouseDown := False;
  if Assigned(Planner) then
  begin
    Planner.HandleAfterMouseEvents;
    Planner.FDrawItemHelpers := False;
    Planner.Invalidate;
  end;
end;

procedure TTMSFNCPlannerSizeHandlerPanel.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCPlannerSizeHandlerPanel);
end;

{ TTMSFNCPlannerAdapter }

constructor TTMSFNCPlannerAdapter.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  FActive := False;
  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCCustomPlanner) then
      begin
        Planner := AOwner.Components[i] as TTMSFNCCustomPlanner;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCPlannerAdapter.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCPlannerAdapter.LoadItems;
begin
  if not Assigned(FPlanner) then
    Exit;

  FPlanner.Items.Clear;

  if Active then
    GetItems(FPlanner.DisplayStartDateTime, FPlanner.DisplayEndDateTime);
end;

procedure TTMSFNCPlannerAdapter.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FPlanner) then
    FPlanner := nil;
end;

procedure TTMSFNCPlannerAdapter.SetActive(const Value: boolean);
begin
  if (Value <> FActive) then
  begin
    FActive := Value;
    LoadItems;
  end;
end;

procedure TTMSFNCPlannerAdapter.UpdateItems;
begin
  Active := false;
  Active := true;
end;

{ TTMSFNCPlannerCustomItemEditor }

procedure TTMSFNCPlannerCustomItemEditor.Assign(Source: TPersistent);
begin
end;

constructor TTMSFNCPlannerCustomItemEditor.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCCustomPlanner) then
      begin
        Planner := AOwner.Components[i] as TTMSFNCCustomPlanner;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCPlannerCustomItemEditor.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCPlannerCustomItemEditor.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FPlanner) then
    FPlanner := nil;
end;

{ TTMSFNCPlannerDeleteHandlerPanel }

procedure TTMSFNCPlannerDeleteHandlerPanel.Click;
begin
  inherited;
  if Assigned(Planner) and Assigned(Planner.ActiveItem) then
    Planner.HandleItemDelete(Planner.ActiveItem, pidmKeyboard);
end;

procedure TTMSFNCPlannerDeleteHandlerPanel.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  bmp: TTMSFNCBitmap;
begin
  inherited;
  if not Assigned(Planner) then
    Exit;

  bmp := Planner.ItemsAppearance.DeleteHandlerBitmap;
  AGraphics.DrawBitmap(RectF(0, 0, Width, Height), bmp);
end;

procedure TTMSFNCPlannerDeleteHandlerPanel.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCPlannerDeleteHandlerPanel);
end;

constructor TTMSFNCPlannerDeleteHandlerPanel.Create(AOwner: TComponent);
begin
  inherited;
  DisableBackground;
  {$IFDEF FMXLIB}
  ClipChildren := True;
  {$ENDIF}
end;

{$IFDEF FMXLIB}
function TTMSFNCPlannerDeleteHandlerPanel.GetClipRect: TRectF;
var
  cr, br: TRectF;
begin
  Result := inherited;
  br := RectF(Position.X, Position.Y, Position.X + Width, Position.Y + Height);
  cr := Planner.GetContentClipRect;
  Result.Top := Max(cr.Top - br.Top, 0);
  Result.Bottom := Min(Result.Bottom - (br.Bottom - cr.Bottom), Result.Bottom);
  Result.Left := Max(cr.Left - br.Left, 0);
  Result.Right := Min(Result.Right - (br.Right - cr.Right), Result.Right);
end;
{$ENDIF}

{$IFDEF LCLLIB}
class operator TTMSFNCPlannerDisplayGroup.=(z1, z2: TTMSFNCPlannerDisplayGroup)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCPlannerDisplayFullDay.=(z1, z2: TTMSFNCPlannerDisplayFullDay)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{$IFDEF CMNWEBLIB}
constructor TTMSFNCPlannerMemo.Create(AOwner: TComponent);
begin
  inherited;
  if AOwner is TTMSFNCCustomPlanner then
    FPlanner := AOwner as TTMSFNCCustomPlanner;
end;

procedure TTMSFNCPlannerMemo.DoExit;
begin
  inherited;
  if Assigned(FPlanner) and FPlanner.FInplaceEditorActive then
    FPlanner.CloseInplaceEditor(False);
end;

procedure TTMSFNCPlannerMemo.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if Assigned(FPlanner) and FPlanner.FInplaceEditorActive then
  begin
    case Key of
      KEY_ESCAPE:
      begin
        FPlanner.CloseInplaceEditor(True, True);
        Key := 0;
      end;
      KEY_F2:
      begin
        FPlanner.CloseInplaceEditor(False, True);
        Key := 0;
      end;
    end;
  end;
end;
{$ENDIF}

{$IFDEF WEBLIB}
function TTMSFNCPlannerCache.GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerCache.SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerDisplayList.GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerDisplayList.SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerDisplayFullDays.GetItem(Index: Integer): TTMSFNCPlannerDisplayFullDay;
begin
  Result := TTMSFNCPlannerDisplayFullDay(inherited Items[Index]);
end;

procedure TTMSFNCPlannerDisplayFullDays.SetItem(Index: Integer; const Value: TTMSFNCPlannerDisplayFullDay);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerDisplayGroups.GetItem(Index: Integer): TTMSFNCPlannerDisplayGroup;
begin
  Result := TTMSFNCPlannerDisplayGroup(inherited Items[Index]);
end;

procedure TTMSFNCPlannerDisplayGroups.SetItem(Index: Integer; const Value: TTMSFNCPlannerDisplayGroup);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerConflicts.GetItem(Index: Integer): TTMSFNCPlannerConflict;
begin
  Result := TTMSFNCPlannerConflict(inherited Items[Index]);
end;

procedure TTMSFNCPlannerConflicts.SetItem(Index: Integer; const Value: TTMSFNCPlannerConflict);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerDateTimes.GetItem(Index: Integer): TDateTime;
begin
  Result := TDateTime(inherited Items[Index]);
end;

procedure TTMSFNCPlannerDateTimes.SetItem(Index: Integer; const Value: TDateTime);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerSelectedItems.GetItem(Index: Integer): TTMSFNCPlannerItem;
begin
  Result := TTMSFNCPlannerItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerSelectedItems.SetItem(Index: Integer; const Value: TTMSFNCPlannerItem);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.

