{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPlannerBase;

interface

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

uses
  {$IFDEF MSWINDOWS}
  Windows,
  {$ENDIF}
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTypes, WEBLib.StdCtrls
  {$IFNDEF LCLLIB}
  ,Types, Generics.Collections
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,SysUtils, Contnrs
  {$ENDIF}
  ;

const
  {$IFDEF LCLLIB}
  SWIPECOUNT = 300;
  DOWNCOUNT = 15;
  {$ELSE}
  {$IFDEF MSWINDOWS}
  SWIPECOUNT = 300;
  DOWNCOUNT = 15;
  {$ENDIF}
  {$IFDEF MACOS}
  {$IFDEF IOS}
  SWIPECOUNT = 300;
  DOWNCOUNT = 200;
  {$ELSE}
  SWIPECOUNT = 300;
  DOWNCOUNT = 200;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF ANDROID}
  SWIPECOUNT = 300;
  DOWNCOUNT = 100;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  {$IFDEF LINUX}
  SWIPECOUNT = 300;
  DOWNCOUNT = 200;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  SWIPECOUNT = 300;
  DOWNCOUNT = 100;
  {$ENDIF}

type
  TTMSFNCPlannerBase = class;

  TTMSFNCPlannerDoubleListItem = class
  private
    FValue: Double;
    FCellVal: Integer;
  public
    property Value: Double read FValue write FValue;
    property CellVal: Integer read FCellVal write FCellVal;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerDoubleList = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerDoubleListItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerDoubleListItem);
  public
    function IndexOfValue(ACellVal: Integer): Integer;
    property Items[Index: Integer]: TTMSFNCPlannerDoubleListItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerDoubleList = class(TObjectList<TTMSFNCPlannerDoubleListItem>)
  public
    function IndexOfValue(ACellVal: Integer): Integer;
  end;
  {$ENDIF}

  TTMSFNCPlannerScrollMode = (smPixelScrolling, smCellScrolling);

  TTMSFNCPlannerCell = record
    Col, Row: Integer
  end;

  TTMSFNCPlannerTime = record
    StartTime, EndTime: TDateTime;
  end;

  TTMSFNCPlannerSelection = record
    StartCell, EndCell: TTMSFNCPlannerCell;
  end;

  TTMSFNCPlannerOrientationMode = (pomHorizontal, pomVertical);

  TTMSFNCPlannerItemCachingMode = (picmNoCaching, picmDelayedCaching, picmFullCaching);

  TTMSFNCPlannerBase = class(TTMSFNCCustomControl)
  private
    FSaveHScrollPos, FSaveVScrollPos: Single;
    FTotalRowHeight: Double;
    FTotalColumnWidth: Double;
    FStartCol, FStopCol, FStartRow, FStopRow: Integer;
    FStartX, FStopX, FStartY, FStopY: Double;
    FBlockScrollingUpdate: Boolean;
    FUpdateCount: Integer;
    FVerticalScrollBar, FHorizontalScrollBar: TScrollBar;
    FRowCount: Integer;
    FColumnCount: Integer;
    FColumnW, FRowH, FColumnP, FRowP: TTMSFNCPlannerDoubleList;
    FDefaultRowHeight: Double;
    FDefaultColumnWidth: Double;
    FVerticalScrollBarVisible: Boolean;
    FHorizontalScrollBarVisible: Boolean;
    FScrollMode: TTMSFNCPlannerScrollMode;
    FOrientationMode: TTMSFNCPlannerOrientationMode;
    FIsMouseDown: Boolean;
    FDelayedLoading: Boolean;
    FItemCachingMode: TTMSFNCPlannerItemCachingMode;
    FStretchScrollBars: Boolean;
    procedure SetColumnCount(const Value: Integer);
    procedure SetRowCount(const Value: Integer);
    procedure SetDefaultColumnWidth(const Value: Double);
    procedure SetDefaultRowHeight(const Value: Double);
    procedure SetHorizontalScrollBarVisible(const Value: Boolean);
    procedure SetVerticalScrollBarVisible(const Value: Boolean);
    procedure SetScrollMode(const Value: TTMSFNCPlannerScrollMode);
    procedure SetColWidths(Col: Integer; const Value: Double);
    procedure SetRowHeights(Row: Integer; const Value: Double);
    procedure SetOrientationMode(const Value: TTMSFNCPlannerOrientationMode);
    procedure SetItemCachingMode(const Value: TTMSFNCPlannerItemCachingMode);
    function GetColWidths(Col: integer): Double;
    function GetRowHeights(Row: integer): Double;
    function GetColPos(Col: integer): Double;
    function GetRowPos(Row: integer): Double;
    procedure SetColPos(Col: Integer; const Value: Double);
    procedure SetRowPos(Row: Integer; const Value: Double);
    procedure SetStretchScrollBars(const Value: Boolean);
  protected
    procedure BeforeExport; override;
    procedure AfterExport; override;
    procedure UpdateVisualRange; virtual;
    procedure UpdateControlAfterResize; override;
    procedure StretchColumn(ACol: Integer = -1; ANewWidth: Double = -1); virtual;
    procedure StretchRow(ARow: Integer = -1; ANewHeight: Double = -1); virtual;
    procedure StopAnimationTimer; virtual; abstract;
    procedure Scroll(AHorizontalPos, AVerticalPos: Double);
    procedure UpdateDisplay; virtual;
    procedure DoHScroll(APosition: Single); virtual; abstract;
    procedure DoVScroll(APosition: Single); virtual; abstract;
    procedure VerticalScrollPositionChanged; virtual; abstract;
    procedure HorizontalScrollPositionChanged; virtual; abstract;
    procedure VScrollChanged(Sender: TObject);
    procedure HScrollChanged(Sender: TObject);
    procedure UpdateAutoSizing; virtual; abstract;
    procedure UpdateColumnRowCalculations; virtual; abstract;
    procedure UpdateScrollBars(AUpdate: Boolean = True; ACalculate: Boolean = True);
    procedure UpdateScrollPosition(AForce: Boolean = True); virtual; abstract;
    procedure UpdateCalculations(AForce: Boolean = False); virtual; abstract;
    procedure UpdatePositionsCache; virtual; abstract;
    procedure UpdateFullDaysItemsCache; virtual; abstract;
    procedure UpdateGroupsCache; virtual; abstract;
    procedure UpdateFullDaysCache; virtual; abstract;
    procedure UpdateTimeLinesCache; virtual; abstract;
    procedure UpdateGridCache; virtual; abstract;
    procedure UpdatePlannerDisplay; virtual;
    procedure DirtyItems; virtual; abstract;
    procedure Loaded; override;
    procedure SetHScrollValue(AValue: Single); virtual;
    procedure SetVScrollValue(AValue: Single); virtual;
    function ColumnStretchingActive: Boolean; virtual; abstract;
    function GetVViewPortSize: Single; virtual;
    function GetHViewPortSize: Single; virtual;
    function GetHScrollValue: Single; virtual;
    function GetVScrollValue: Single; virtual;
    function GetUpdateCount: Integer;
    function GetCalculationRect: TRectF; virtual;
    function GetContentRect: TRectF; override;
    property TotalColumnWidth: Double read FTotalColumnWidth write FTotalColumnWidth;
    property TotalRowHeight: Double read FTotalRowHeight write FTotalRowHeight;
    property ItemCachingMode: TTMSFNCPlannerItemCachingMode read FItemCachingMode write SetItemCachingMode default picmNoCaching;
    property IsMouseDown: Boolean read FIsMouseDown write FIsMouseDown;
    property DefaultRowHeight: Double read FDefaultRowHeight write SetDefaultRowHeight;
    property DefaultColumnWidth: Double read FDefaultColumnWidth write SetDefaultColumnWidth;
    property HorizontalScrollBarVisible: Boolean read FHorizontalScrollBarVisible write SetHorizontalScrollBarVisible default True;
    property VerticalScrollBarVisible: Boolean read FVerticalScrollBarVisible write SetVerticalScrollBarVisible default True;
    property RowCount: Integer read FRowCount write SetRowCount;
    property ColumnCount: Integer read FColumnCount write SetColumnCount;
    property ScrollMode: TTMSFNCPlannerScrollMode read FScrollMode write SetScrollMode default smPixelScrolling;
    property OrientationMode: TTMSFNCPlannerOrientationMode read FOrientationMode write SetOrientationMode default pomVertical;
    property RowPositions[Row: Integer]: Double read GetRowPos write SetRowPos;
    property ColumnPositions[Col: Integer]: Double read GetColPos write SetColPos;
    property ColumnWidths[Col: Integer]: Double read GetColWidths write SetColWidths;
    property RowHeights[Row: Integer]: Double read GetRowHeights write SetRowHeights;
    property ColumnW: TTMSFNCPlannerDoubleList read FColumnW;
    property RowH: TTMSFNCPlannerDoubleList read FRowH;
    property ColumnP: TTMSFNCPlannerDoubleList read FColumnP;
    property RowP: TTMSFNCPlannerDoubleList read FRowP;
    property BlockScrollingUpdate: Boolean read FBlockScrollingUpdate write FBlockScrollingUpdate;
    property StretchScrollBars: Boolean read FStretchScrollBars write SetStretchScrollBars default False;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property UpdateCount: Integer read GetUpdateCount;
    procedure Assign(Source: TPersistent); override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure RestoreScrollPosition; virtual;
    procedure SaveScrollPosition; virtual;
    procedure UpdateConflicts; virtual; abstract;
    procedure UpdateFullDayConflicts; virtual; abstract;
    procedure UpdatePlannerCache(ADirtyItems: Boolean = True); virtual;
    procedure UpdateItemsCache; virtual; abstract;
    function IsFullDayAutoSize: Boolean; virtual; abstract;
    function GetColumnViewPortSize: Double; virtual;
    function GetRowViewPortSize: Double; virtual;
    function GetVerticalScrollPosition: Double; virtual;
    function GetHorizontalScrollPosition: Double; virtual;
    function HorizontalScrollBar: TScrollBar;
    function VerticalScrollBar: TScrollBar;
    function XYToCell(X, Y: Double): TTMSFNCPlannerCell; virtual;
    function StartCol: Integer;
    function StopCol: Integer;
    function StartX: Double;
    function StopX: Double;
    function StartRow: Integer;
    function StopRow: Integer;
    function StartY: Double;
    function StopY: Double;
    function GetTotalColumnWidth: Double;
    function GetTotalRowHeight: Double;
  end;

implementation

uses
  WEBLib.Controls, WEBLib.Forms, Math
  {$IFNDEF WEBLIB}
  ,SysUtils
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  ;

{ TTMSFNCPlannerBase }

procedure TTMSFNCPlannerBase.RestoreScrollPosition;
begin
  Scroll(FSaveHScrollPos, FSaveVScrollPos);
end;

procedure TTMSFNCPlannerBase.SaveScrollPosition;
begin
  FSaveHScrollPos := GetHScrollValue;
  FSaveVScrollPos := GetVScrollValue;
end;

procedure TTMSFNCPlannerBase.AfterExport;
begin
  inherited;
  UpdatePlannerCache;
end;

procedure TTMSFNCPlannerBase.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCPlannerBase then
  begin
    FItemCachingMode := (Source as TTMSFNCPlannerBase).ItemCachingMode;
    FOrientationMode := (Source as TTMSFNCPlannerBase).OrientationMode;
    FScrollMode := (Source as TTMSFNCPlannerBase).ScrollMode;
    FStretchScrollBars := (Source as TTMSFNCPlannerBase).StretchScrollBars;
  end;
end;

procedure TTMSFNCPlannerBase.BeforeExport;
begin
  inherited;
  UpdatePlannerCache;
end;

procedure TTMSFNCPlannerBase.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

constructor TTMSFNCPlannerBase.Create(AOwner: TComponent);
begin
  inherited;
  FDelayedLoading := False;
  FStretchScrollBars := False;
  FOrientationMode := pomVertical;
  FItemCachingMode := picmNoCaching;

  FVerticalScrollBar := TScrollBar.Create(Self);
  FVerticalScrollBar.Parent := Self;
  FHorizontalScrollBar := TScrollBar.Create(Self);
  FHorizontalScrollBar.Parent := Self;

  {$IFDEF FMXLIB}
  FVerticalScrollBar.Stored := False;
  FHorizontalScrollBar.Stored := False;
  FVerticalScrollBar.Orientation := TOrientation.Vertical;
  {$ENDIF}

  {$IFDEF CMNWEBLIB}
  FVerticalScrollBar.DoubleBuffered := False;
  FHorizontalScrollBar.DoubleBuffered := False;
  FVerticalScrollBar.Kind := sbVertical;
  {$ENDIF}

  FVerticalScrollBar.Visible := True;
  FHorizontalScrollBar.Visible := True;
  FVerticalScrollBar.OnChange := @VScrollChanged;
  FHorizontalScrollBar.OnChange := @HScrollChanged;

  FScrollMode := smPixelScrolling;

  FColumnW := TTMSFNCPlannerDoubleList.Create;
  FRowH := TTMSFNCPlannerDoubleList.Create;
  FRowP := TTMSFNCPlannerDoubleList.Create;
  FColumnP := TTMSFNCPlannerDoubleList.Create;

  FDefaultRowHeight := 30;
  FDefaultColumnWidth := 75;
  FColumnCount := 10;
  FRowCount := 24;
  FVerticalScrollBarVisible := True;
  FHorizontalScrollBarVisible := True;
end;

destructor TTMSFNCPlannerBase.Destroy;
begin
  FColumnP.Free;
  FRowP.Free;
  FColumnW.Free;
  FRowH.Free;
  FVerticalScrollBar.Free;
  FHorizontalScrollBar.Free;
  inherited;
end;

function TTMSFNCPlannerBase.GetContentRect: TRectF;
begin
  Result := inherited GetContentRect;
  if HorizontalScrollBar.Visible and not IsExporting then
    Result.Bottom := Result.Bottom - HorizontalScrollBar.Height - 1;

  if VerticalScrollBar.Visible and not IsExporting then
    Result.Right := Result.Right - VerticalScrollBar.Width - 1;
end;

function TTMSFNCPlannerBase.GetCalculationRect: TRectF;
begin
  Result := LocalRect;
end;

function TTMSFNCPlannerBase.GetHorizontalScrollPosition: Double;
var
  hVal, scrollh: Double;
begin
  hVal := GetHScrollValue;
  if ScrollMode = smCellScrolling then
  begin
    case OrientationMode of
      pomHorizontal: scrollh := RowPositions[Round(hval)];
      pomVertical: scrollh := ColumnPositions[Round(hval)];
      else
        scrollh := 0;
    end;

    hVal := scrollh;
  end;

  Result := hVal;
end;

function TTMSFNCPlannerBase.GetHScrollValue: Single;
begin
  {$IFDEF FMXLIB}
  Result := Min(HorizontalScrollBar.Max - HorizontalScrollBar.ViewportSize, Max(0, HorizontalScrollBar.Value));
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  {$IFNDEF LCLLIB}
  Result := Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, Round(HorizontalScrollBar.Position)));
  {$ENDIF}
  {$IFDEF LCLLIB}
  {$IFDEF MSWINDOWS}
  Result := {%H-}Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, Round(HorizontalScrollBar.Position)));
  {$ELSE}
  case ScrollMode of
    smPixelScrolling: Result := {%H-}Min(HorizontalScrollBar.Max, Max(0, Round(HorizontalScrollBar.Position - HorizontalScrollBar.Position * (HorizontalScrollBar.PageSize / HorizontalScrollBar.Max))));
    smCellScrolling: Result := {%H-}Min(HorizontalScrollBar.Max, Max(0, Round(HorizontalScrollBar.Position)));
  end;
  {$ENDIF}
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCPlannerBase.GetHViewPortSize: Single;
begin
  {$IFDEF FMXLIB}
  Result := HorizontalScrollBar.ViewportSize;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result := HorizontalScrollBar.PageSize;
  {$ENDIF}
end;

function TTMSFNCPlannerBase.GetRowHeights(Row: Integer): Double;
var
  idx: Integer;
begin
  idx := RowH.IndexOfValue(Row);
  if idx <> -1 then
    Result := RowH[idx].Value
  else
    Result := DefaultRowHeight;
end;

function TTMSFNCPlannerBase.GetRowPos(Row: Integer): Double;
var
  idx: Integer;
begin
  idx := RowP.IndexOfValue(Row);
  if idx <> -1 then
    Result := RowP[idx].Value
  else
    Result := -1;
end;

function TTMSFNCPlannerBase.GetRowViewPortSize: Double;
var
  I,cnt: Integer;
  h: Double;
  ch: Double;
  cr: TRectF;
begin
  cr := GetContentRect;
  case OrientationMode of
    pomHorizontal: ch := cr.Right - cr.Left;
    pomVertical: ch := cr.Bottom - cr.Top;
    else
      ch := 0;
  end;
  Result := 0;
  h := 0;
  cnt := 0;
  for I := RowCount - 1 downto 0 do
  begin
    h := h + RowHeights[I];
    if h > ch then
    begin
      Result := Max(1, cnt);
      Break;
    end;
    Inc(cnt);
  end;
end;

function TTMSFNCPlannerBase.GetTotalColumnWidth: Double;
begin
  Result := TotalColumnWidth;
end;

function TTMSFNCPlannerBase.GetTotalRowHeight: Double;
begin
  Result := TotalRowHeight;
end;

function TTMSFNCPlannerBase.GetVerticalScrollPosition: Double;
var
  vVal, scrollv: Double;
begin
  vVal := GetVScrollValue;

  if ScrollMode = smCellScrolling then
  begin
    case OrientationMode of
      pomHorizontal: scrollv := ColumnPositions[Round(vval)];
      pomVertical: scrollv := RowPositions[Round(vval)];
      else
        scrollv := 0;
    end;

    vVal := scrollv;
  end;

  Result := vVal;
end;

function TTMSFNCPlannerBase.GetVScrollValue: Single;
begin
  {$IFDEF FMXLIB}
  Result := Min(VerticalScrollBar.Max - VerticalScrollBar.ViewportSize, Max(0, VerticalScrollBar.Value));
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  {$IFNDEF LCLLIB}
  Result := Round(Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, VerticalScrollBar.Position)));
  {$ENDIF}
  {$IFDEF LCLLIB}
  {$IFDEF MSWINDOWS}
  Result := {%H-}Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, Round(VerticalScrollBar.Position)));
  {$ELSE}
  case ScrollMode of
    smPixelScrolling: Result := {%H-}Min(VerticalScrollBar.Max, Max(0, Round(VerticalScrollBar.Position - VerticalScrollBar.Position * (VerticalScrollBar.PageSize / VerticalScrollBar.Max))));
    smCellScrolling: Result := {%H-}Min(VerticalScrollBar.Max, Max(0, Round(VerticalScrollBar.Position)));
  end;
  {$ENDIF}
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCPlannerBase.GetVViewPortSize: Single;
begin
  {$IFDEF FMXLIB}
  Result := VerticalScrollBar.ViewportSize;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result := VerticalScrollBar.PageSize;
  {$ENDIF}
end;

function TTMSFNCPlannerBase.HorizontalScrollBar: TScrollBar;
begin
  Result := FHorizontalScrollBar;
end;

procedure TTMSFNCPlannerBase.HScrollChanged(Sender: TObject);
begin
  if FBlockScrollingUpdate then
    Exit;

  if not IsMouseDown then
    StopAnimationTimer;

  HorizontalScrollPositionChanged;
end;

procedure TTMSFNCPlannerBase.Loaded;
begin
  inherited;
  UpdatePlannerCache;
end;

function TTMSFNCPlannerBase.VerticalScrollBar: TScrollBar;
begin
  Result := FVerticalScrollBar;
end;

procedure TTMSFNCPlannerBase.VScrollChanged(Sender: TObject);
begin
  if FBlockScrollingUpdate then
    Exit;

  if not IsMouseDown then
    StopAnimationTimer;

  VerticalScrollPositionChanged;
end;

function TTMSFNCPlannerBase.XYToCell(X, Y: Double): TTMSFNCPlannerCell;
var
  I: Integer;
  val: Double;
  xv, yv: Double;
  cr: TRectF;
begin
  cr := GetContentRect;
  Result.Row := -1;
  Result.Col := -1;

  if PtInRectEx(GetContentRect, PointF(X, Y)) then
  begin
    case OrientationMode of
      pomHorizontal:
      begin
        yv := X + GetHorizontalScrollPosition - cr.Left;
        xv := Y + GetVerticalScrollPosition - cr.Top;
      end;
      pomVertical:
      begin
        xv := X + GetHorizontalScrollPosition - cr.Left;
        yv := Y + GetVerticalScrollPosition - cr.Top;
      end;
      else
      begin
        xv := 0;
        yv := 0;
      end;
    end;
    val := 0;
    for I := 0 to RowCount - 1 do
    begin
      val := val + RowHeights[I];
      if val >= yv then
      begin
        Result.Row := I;
        Break;
      end;
    end;

    val := 0;
    for I := 0 to ColumnCount - 1 do
    begin
      val := val + ColumnWidths[I];
      if val >= xv then
      begin
        Result.Col := I;
        Break;
      end;
    end;
  end;
end;

procedure TTMSFNCPlannerBase.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    UpdatePlannerCache(False);
end;

function TTMSFNCPlannerBase.GetColumnViewPortSize: Double;
var
  I,cnt: Integer;
  w: Double;
  cw: Double;
  cr: TRectF;
begin
  cr := GetContentRect;
  case OrientationMode of
    pomHorizontal: cw := cr.Bottom - cr.Top;
    pomVertical: cw := cr.Right - cr.Left;
    else
      cw := 0;
  end;
  Result := 0;
  w := 0;
  cnt := 0;
  for I := ColumnCount - 1 downto 0 do
  begin
    w := w + ColumnWidths[I];
    if w > cw then
    begin
      Result := Max(1, cnt);
      Break;
    end;
    Inc(cnt);
  end;
end;

function TTMSFNCPlannerBase.GetColWidths(Col: Integer): Double;
var
  idx: Integer;
begin
  idx := ColumnW.IndexOfValue(Col);
  if idx <> -1 then
    Result := ColumnW[idx].Value
  else
    Result := DefaultColumnWidth;
end;

function TTMSFNCPlannerBase.GetColPos(Col: Integer): Double;
var
  idx: Integer;
begin
  idx := ColumnP.IndexOfValue(Col);
  if idx <> -1 then
    Result := ColumnP[idx].Value
  else
    Result := -1;
end;

procedure TTMSFNCPlannerBase.Scroll(AHorizontalPos, AVerticalPos: Double);
var
  h, v: Single;
begin
  FBlockScrollingUpdate := True;
  h := GetHScrollValue;
  SetHScrollValue(AHorizontalPos);
  v := GetVScrollValue;
  SetVScrollValue(AVerticalPos);

  if v <> GetVScrollValue then
    DoVScroll(GetVScrollValue);

  if h <> GetHScrollValue then
    DoHScroll(GetHScrollValue);

  FBlockScrollingUpdate := False;
  UpdateDisplay;
end;

procedure TTMSFNCPlannerBase.SetColumnCount(const Value: Integer);
begin
  if FColumnCount <> Value then
    FColumnCount := Value;
end;

procedure TTMSFNCPlannerBase.SetColWidths(Col: Integer; const Value: Double);
var
  idx: Integer;
  c: TTMSFNCPlannerDoubleListItem;
begin
  idx := ColumnW.IndexOfValue(Col);
  if (idx >= 0) and (idx <= ColumnW.Count - 1) then
  begin
    if Value = DefaultColumnWidth then
      ColumnW.Delete(idx)
    else
    begin
      ColumnW[idx].Value := Max(0, Value);
      ColumnW[idx].CellVal := Col;
    end;
  end
  else if Value <> DefaultColumnWidth then
  begin
    c := TTMSFNCPlannerDoubleListItem.Create;
    c.Value := Max(0, Value);
    c.CellVal := Col;
    ColumnW.Add(c);
  end;
end;

procedure TTMSFNCPlannerBase.SetColPos(Col: Integer; const Value: Double);
var
  idx: Integer;
  c: TTMSFNCPlannerDoubleListItem;
begin
  idx := ColumnP.IndexOfValue(Col);
  if (idx >= 0) and (idx <= ColumnP.Count - 1) then
  begin
    if Value = -1 then
      ColumnP.Delete(idx)
    else
    begin
      ColumnP[idx].Value := Max(0, Value);
      ColumnP[idx].CellVal := Col;
    end;
  end
  else if Value <> -1 then
  begin
    c := TTMSFNCPlannerDoubleListItem.Create;
    c.Value := Max(0, Value);
    c.CellVal := Col;
    ColumnP.Add(c);
  end;
end;

procedure TTMSFNCPlannerBase.SetDefaultColumnWidth(const Value: Double);
begin
  if FDefaultColumnWidth <> Value then
    FDefaultColumnWidth := Value;
end;

procedure TTMSFNCPlannerBase.SetDefaultRowHeight(const Value: Double);
begin
  if FDefaultRowHeight <> Value then
    FDefaultRowHeight := Value;
end;

procedure TTMSFNCPlannerBase.SetHorizontalScrollBarVisible(
  const Value: Boolean);
begin
  if FHorizontalScrollBarVisible <> Value then
  begin
    FHorizontalScrollBarVisible := Value;
    UpdatePlannerCache(True);
  end;
end;

procedure TTMSFNCPlannerBase.SetHScrollValue(AValue: Single);
begin
 {$IFDEF FMXLIB}
  HorizontalScrollBar.Value := Min(HorizontalScrollBar.Max - HorizontalScrollBar.ViewportSize, Max(0, AValue));
  {$ENDIF}
  {$IFDEF VCLLIB}
  HorizontalScrollBar.Position := Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, Round(AValue)));
  {$ENDIF}
  {$IFDEF WEBLIB}
  HorizontalScrollBar.Position := Round(Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, AValue)));
  {$ENDIF}
  {$IFDEF LCLLIB}
  {$IFDEF MSWINDOWS}
  HorizontalScrollBar.Position := {%H-}Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, Round(AValue)));
  {$ELSE}
  case ScrollMode of
    smPixelScrolling: HorizontalScrollBar.Position := {%H-}Min(HorizontalScrollBar.Max, Max(0, Round(AValue + AValue * (HorizontalScrollBar.PageSize / (HorizontalScrollBar.Max - HorizontalScrollBar.PageSize)))));
    smCellScrolling: HorizontalScrollBar.Position := {%H-}Min(HorizontalScrollBar.Max - HorizontalScrollBar.PageSize, Max(0, Round(AValue)));
  end;
  {$ENDIF}
  {$ENDIF}
end;

procedure TTMSFNCPlannerBase.SetItemCachingMode(
  const Value: TTMSFNCPlannerItemCachingMode);
begin
  if FItemCachingMode <> Value then
  begin
    FItemCachingMode := Value;
    UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerBase.SetOrientationMode(const Value: TTMSFNCPlannerOrientationMode);
begin
  if FOrientationMode <> Value then
  begin
    FOrientationMode := Value;
    UpdatePlannerCache;
  end;
end;

procedure TTMSFNCPlannerBase.SetRowHeights(Row: Integer; const Value: Double);
var
  idx: Integer;
  r: TTMSFNCPlannerDoubleListItem;
begin
  idx := RowH.IndexOfValue(Row);
  if (idx >= 0) and (idx <= RowH.Count - 1) then
  begin
    if Value = DefaultRowHeight then
      RowH.Delete(idx)
    else
    begin
      RowH[idx].Value := Max(0, Value);
      RowH[idx].CellVal := Row;
    end;
  end
  else if Value <> DefaultRowHeight then
  begin
    r := TTMSFNCPlannerDoubleListItem.Create;
    r.Value := Max(0, Value);
    r.CellVal := Row;
    RowH.Add(r);
  end;
end;

procedure TTMSFNCPlannerBase.SetRowPos(Row: Integer; const Value: Double);
var
  idx: Integer;
  r: TTMSFNCPlannerDoubleListItem;
begin
  idx := RowP.IndexOfValue(Row);
  if (idx >= 0) and (idx <= RowP.Count - 1) then
  begin
    if Value = -1 then
      RowP.Delete(idx)
    else
    begin
      RowP[idx].Value := Max(0, Value);
      RowP[idx].CellVal := Row;
    end;
  end
  else if Value <> -1 then
  begin
    r := TTMSFNCPlannerDoubleListItem.Create;
    r.Value := Max(0, Value);
    r.CellVal := Row;
    RowP.Add(r);
  end;
end;

procedure TTMSFNCPlannerBase.SetRowCount(const Value: Integer);
begin
  if FRowCount <> Value then
    FRowCount := Value;
end;

procedure TTMSFNCPlannerBase.SetScrollMode(
  const Value: TTMSFNCPlannerScrollMode);
begin
  if FScrollMode <> Value then
  begin
    FScrollMode := Value;
    UpdatePlannerDisplay;
  end;
end;

procedure TTMSFNCPlannerBase.SetStretchScrollBars(const Value: Boolean);
begin
  if FStretchScrollBars <> Value then
  begin
    FStretchScrollBars := Value;
    UpdatePlannerDisplay;
  end;
end;

procedure TTMSFNCPlannerBase.SetVerticalScrollBarVisible(
  const Value: Boolean);
begin
  if FVerticalScrollBarVisible <> Value then
  begin
    FVerticalScrollBarVisible := Value;
    UpdatePlannerCache(True);
  end;
end;

procedure TTMSFNCPlannerBase.SetVScrollValue(AValue: Single);
begin
 {$IFDEF FMXLIB}
  VerticalScrollBar.Value := Min(VerticalScrollBar.Max - VerticalScrollBar.ViewportSize, Max(0, AValue));
  {$ENDIF}
  {$IFDEF VCLLIB}
  VerticalScrollBar.Position := Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, Round(AValue)));
  {$ENDIF}
  {$IFDEF WEBLIB}
  VerticalScrollBar.Position := Round(Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, AValue)));
  {$ENDIF}
  {$IFDEF LCLLIB}
  {$IFDEF MSWINDOWS}
  VerticalScrollBar.Position := {%H-}Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, Round(AValue)));
  {$ELSE}
  case ScrollMode of
    smPixelScrolling: VerticalScrollBar.Position := {%H-}Min(VerticalScrollBar.Max, Max(0, Round(AValue + AValue * (VerticalScrollBar.PageSize / (VerticalScrollBar.Max - VerticalScrollBar.PageSize)))));
    smCellScrolling: VerticalScrollBar.Position := {%H-}Min(VerticalScrollBar.Max - VerticalScrollBar.PageSize, Max(0, Round(AValue)));
  end;
  {$ENDIF}
  {$ENDIF}
end;

function TTMSFNCPlannerBase.StartCol: Integer;
begin
  Result := FStartCol;
end;

function TTMSFNCPlannerBase.StartRow: Integer;
begin
  Result := FStartRow;
end;

function TTMSFNCPlannerBase.StartX: Double;
begin
  Result := FStartX;
end;

function TTMSFNCPlannerBase.StartY: Double;
begin
  Result := FStartY;
end;

function TTMSFNCPlannerBase.StopCol: Integer;
begin
  Result := FStopCol;
end;

function TTMSFNCPlannerBase.StopRow: Integer;
begin
  Result := FStopRow;
end;

function TTMSFNCPlannerBase.StopX: Double;
begin
  Result := FStopX;
end;

function TTMSFNCPlannerBase.StopY: Double;
begin
  Result := FStopY;
end;

procedure TTMSFNCPlannerBase.StretchColumn(ACol: Integer = -1; ANewWidth: Double = -1);
var
  i: Integer;
  w, nw, d: Double;
  cnt: Integer;
  horz, vert: TScrollBar;
  stretch, stretchall: Boolean;
  r: TRectF;
begin
  stretch := True;
  stretchall := True;
  horz := HorizontalScrollBar;
  vert := VerticalScrollBar;
  if not Assigned(vert) or not Assigned(horz) then
    Exit;

  if ANewWidth = -1 then
  begin
    r := GetContentRect;
    case OrientationMode of
      pomHorizontal: nw := r.Bottom - r.Top;
      pomVertical: nw := r.Right - r.Left;
      else
        nw := 0;
    end;
  end
  else
    nw := ANewWidth;

  cnt := ColumnCount;

  if ACol = - 1 then
    ACol := cnt - 1;

  if ACol >= cnt then
    raise Exception.Create('Stretch column index out of range');

  if (cnt = 0) or not stretch then
    Exit;

  if cnt = 1 then
  begin
    ColumnWidths[0] := nw;
    Exit;
  end;

  w := 0;

  if stretchall then
  begin
    if (cnt > 0) then
    begin
      d := nw;

      w := d / cnt;

      for i := 0 to cnt - 1 do
        ColumnWidths[i] := w;
    end;
  end
  else
  begin
    for i := 0 to cnt - 1 do
    begin
      if i <> ACol then
        w := w + ColumnWidths[i];
    end;

    ColumnWidths[ACol] := nw - w {- 1};
  end;
end;

procedure TTMSFNCPlannerBase.StretchRow(ARow: Integer = -1; ANewHeight: Double = -1);
var
  i: Integer;
  w, nw, d: Double;
  cnt: Integer;
  stretch, stretchall: Boolean;
  r: TRectF;
begin
  stretch := True;
  stretchall := True;

  if ANewHeight = -1 then
  begin
    r := GetContentRect;
    case OrientationMode of
      pomHorizontal: nw := r.Right - r.Left;
      pomVertical: nw := r.Bottom - r.Top;
      else
        nw := 0;
    end;
  end
  else
    nw := ANewHeight;

  cnt := RowCount;

  if ARow = - 1 then
    ARow := cnt - 1;

  if ARow >= cnt then
    raise Exception.Create('Stretch Row index out of range');

  if (cnt = 0) or not stretch then
    Exit;

  if cnt = 1 then
  begin
    RowHeights[0] := nw;
    Exit;
  end;

  w := 0;

  if stretchall then
  begin
    if (cnt > 0) then
    begin
      d := nw;

      w := d / cnt;
      for i := 0 to cnt - 1 do
        RowHeights[i] := w;
    end;
  end
  else
  begin
    for i := 0 to cnt - 1 do
    begin
      if i <> ARow then
        w := w + RowHeights[i];
    end;

    RowHeights[ARow] := nw - w;
  end;
end;

procedure TTMSFNCPlannerBase.UpdateControlAfterResize;
begin
  UpdatePlannerCache;
end;

function TTMSFNCPlannerBase.GetUpdateCount: Integer;
begin
  Result := FUpdateCount;
end;

procedure TTMSFNCPlannerBase.UpdateVisualRange;
var
  c, r: Integer;
  xval, yval: Double;
  cw, ch: Double;
  vpos, hpos: Double;
  cr: TRectF;
begin
  hpos := GetHorizontalScrollPosition;
  vpos := GetVerticalScrollPosition;
  cr := GetContentRect;
  case OrientationMode of
    pomHorizontal:
    begin
      xval := -vpos;
      yval := -hpos;
      cw := cr.Bottom - cr.Top;
      ch := cr.Right - cr.Left;
    end;
    pomVertical:
    begin
      xval := -hpos;
      yval := -vpos;
      cw := cr.Right - cr.Left;
      ch := cr.Bottom - cr.Top;
    end;
    else
    begin
      xval := 0;
      yval := 0;
      cw := 0;
      ch := 0;
    end;
  end;
  FStartCol := -1;
  FStartRow := -1;
  FStopCol := -1;
  FStopRow := -1;
  case OrientationMode of
    pomHorizontal:
    begin
      FStartX := cr.Top;
      FStopX := cr.Top;
      FStartY := cr.Left;
      FStopY := cr.Left;
    end;
    pomVertical:
    begin
      FStartX := cr.Left;
      FStopX := cr.Left;
      FStartY := cr.Top;
      FStopY := cr.Top;
    end;
  end;

  for c := 0 to ColumnCount - 1 do
  begin
    xval := xval + ColumnWidths[c];
    if (xval > 0) and (FStartCol = -1) then
    begin
      FStartCol := c;
      FStartX := FStartX + int(xval - ColumnWidths[c]);
    end;

    if (xval >= cw) and (FStopCol = -1) then
    begin
      FStopCol := c;
      FStopX := FStopX + int(xval);
    end;

    if (FStartCol > -1) and (FStopCol > -1) then
      Break;
  end;

  for r := 0 to RowCount - 1 do
  begin
    yval := yval + RowHeights[r];
    if (yval > 0) and (FStartRow = -1) then
    begin
      FStartRow := r;
      FStartY := FStartY + int(yval - RowHeights[r]);
    end;

    if (yval >= ch) and (FStopRow = -1) then
    begin
      FStopRow := r;
      FStopY := FStopY + int(yval);
    end;

    if (FStartRow > -1) and (FStopRow > -1) then
      Break;
  end;

  if (FStartRow > -1) and (FStopRow = -1) then
    FStopRow := RowCount - 1;

  if (FStartCol > -1) and (FStopCol = -1) then
    FStopCol := ColumnCount - 1;
end;

procedure TTMSFNCPlannerBase.UpdateDisplay;
begin
  UpdateVisualRange;
  if (ItemCachingMode = picmDelayedCaching) and (UpdateCount = 0) then
    UpdateItemsCache;
end;

procedure TTMSFNCPlannerBase.UpdatePlannerCache(ADirtyItems: Boolean = True);
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) or (csLoading in ComponentState) then
    Exit;

  UpdateCalculations;
  if ADirtyItems then
    DirtyItems;

  UpdateConflicts;
  if IsFullDayAutoSize then
    UpdateFullDayConflicts;

  UpdateScrollBars;
  UpdateVisualRange;
  UpdateGridCache;
  UpdateGroupsCache;
  UpdateFullDaysItemsCache;
  UpdateFullDaysCache;
  UpdatePositionsCache;
  UpdateTimeLinesCache;
  UpdateItemsCache;
end;

procedure TTMSFNCPlannerBase.UpdatePlannerDisplay;
begin
  UpdateScrollBars;
  UpdateDisplay;
end;

procedure TTMSFNCPlannerBase.UpdateScrollBars(AUpdate: Boolean = True; ACalculate: Boolean = True);
var
  vs, hs: TScrollBar;
  w, h: Double;
  cw, ch: Double;
  cr: TRectF;
  vmgr: TTMSFNCMargins;
  hmgr: TTMSFNCMargins;
begin
  if (FBlockScrollingUpdate) or (UpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  FBlockScrollingUpdate := True;

  if ACalculate then
  begin
    UpdateAutoSizing;
    UpdateColumnRowCalculations;
  end;

  vs := VerticalScrollBar;
  hs := HorizontalScrollBar;
  if Assigned(vs) and Assigned(hs) then
  begin
    if AUpdate then
      cr := GetCalculationRect
    else
      cr := GetContentRect;

    cw := cr.Right - cr.Left;
    ch := cr.Bottom - cr.Top;
    case OrientationMode of
      pomHorizontal:
      begin
        h := TotalColumnWidth;
        w := TotalRowHeight;
      end;
      pomVertical:
      begin
        w := TotalColumnWidth;
        h := TotalRowHeight;
      end;
      else
      begin
        w := 0;
        h := 0;
      end;
    end;

    case OrientationMode of
      pomHorizontal:
      begin
        hs.Visible := (w > 0) and (CompareValueEx(w, cw) = 1) and HorizontalScrollBarVisible;
        vs.Visible := (h > 0) and (CompareValueEx(h, ch) = 1) and VerticalScrollBarVisible and not ColumnStretchingActive;
      end;
      pomVertical:
      begin
        hs.Visible := (w > 0) and (CompareValueEx(w, cw) = 1) and HorizontalScrollBarVisible and not ColumnStretchingActive;
        vs.Visible := (h > 0) and (CompareValueEx(h, ch) = 1) and VerticalScrollBarVisible;
      end;
    end;

    {$IFDEF CMNWEBLIB}
    if vs.Visible then
      vs.Parent := Self
    else
      vs.Parent := nil;

    if hs.Visible then
      hs.Parent := Self
    else
      hs.Parent := nil;
    {$ENDIF}

    vmgr := TTMSFNCMargins.Create;
    hmgr := TTMSFNCMargins.Create;

    if vs.Visible and StretchScrollBars then
      hmgr.Right := vs.Width + 1
    else
      hmgr.Right := 1;

    vmgr.Top := 1;
    vmgr.Right := 1;
    hmgr.Bottom := 1;
    hmgr.Left := 1;

    if hs.Visible then
      vmgr.Bottom := hs.Height + 1
    else
      vmgr.Bottom := 1;

    if not StretchScrollBars then
    begin
      cr := GetCalculationRect;
      hmgr.Left := hmgr.Left + cr.Left;
      hmgr.Right := hmgr.Right + (Width - cr.Right);
      vmgr.Top := vmgr.Top + cr.Top;
      vmgr.Bottom := vmgr.Bottom + (Height - cr.Bottom);
    end;

    {$IFDEF FMXLIB}
    hs.Position.X := hmgr.Left;
    hs.Position.Y := Height - hmgr.Bottom - hs.Height;
    vs.Position.X := Width - vmgr.Right - vs.Width;
    vs.Position.Y := vmgr.Top;
    hs.Width := Width - hmgr.Right - hmgr.Left;
    vs.Height := Height - vmgr.Bottom - vmgr.Top;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    hs.Left := Round(hmgr.Left);
    hs.Top := Round(Height - hmgr.Bottom - hs.Height);
    vs.Left := Round(Width - vmgr.Right - vs.Width);
    vs.Top := Round(vmgr.Top);
    hs.Width := Round(Max(0, Width - hmgr.Right - hmgr.Left));
    vs.Height := Round(Max(0, Height - vmgr.Bottom - vmgr.Top));
    {$IFDEF MSWINDOWS}
    vs.Width := GetSystemMetrics(SM_CYVSCROLL);
    hs.Height := GetSystemMetrics(SM_CYHSCROLL);
    {$ENDIF}
    {$ENDIF}

    hmgr.Free;
    vmgr.Free;

    cr := GetContentRect;
    cw := cr.Right - cr.Left;
    ch := cr.Bottom - cr.Top;

    {$IFDEF FMXLIB}
    if ScrollMode = smCellScrolling then
    begin
      vs.ViewportSize := GetRowViewPortSize;
      vs.Max := RowCount;
    end
    else
    begin
      vs.ViewPortSize := Min(h, ch);
      vs.Max := h;
      vs.SmallChange := Round(DefaultRowHeight);
      vs.Value := Min(vs.Value, vs.Max - vs.ViewportSize);
    end;

    if ScrollMode = smCellScrolling then
    begin
      hs.ViewportSize := GetColumnViewPortSize;
      hs.Max := ColumnCount;
    end
    else
    begin
      hs.ViewPortSize := Min(w, cw);
      hs.Max := w;
      hs.Value := Min(hs.Value, hs.Max - hs.ViewportSize);
    end;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    if ScrollMode = smCellScrolling then
    begin
      vs.PageSize := Round(Max(0, GetRowViewPortSize));
      vs.Max := Max(vs.PageSize, RowCount);
    end
    else
    begin
      vs.PageSize := Round(Max(0, Min(h, ch)));
      vs.Max := Round(Max(0, h));
      vs.SmallChange := Round(DefaultRowHeight);
      vs.LargeChange := Max(1, vs.PageSize);
      vs.Position := Min(vs.Position, vs.Max);
    end;

    if ScrollMode = smCellScrolling then
    begin
      hs.PageSize := Round(Max(0, GetColumnViewPortSize));
      hs.Max := Max(hs.PageSize, ColumnCount);
    end
    else
    begin
      hs.PageSize := Round(Max(0, Min(w, cw)));
      hs.Max := Round(Max(hs.PageSize, w));
      hs.Position := Min(hs.Position, hs.Max);
    end;
    {$ENDIF}
  end;

  FBlockScrollingUpdate := False;

  if AUpdate then
    UpdateScrollBars(False)
  else
    UpdateScrollPosition(False);
end;

{ TTMSFNCPlannerDoubleList }

function TTMSFNCPlannerDoubleList.IndexOfValue(ACellVal: Integer): Integer;
var
  r: Integer;
begin
  Result := -1;
  for r := 0 to Count - 1 do
  begin
    if Items[r].CellVal = ACellVal then
    begin
      Result := r;
      Exit;
    end;
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCPlannerDoubleList.GetItem(Index: Integer): TTMSFNCPlannerDoubleListItem;
begin
  Result := TTMSFNCPlannerDoubleListItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerDoubleList.SetItem(Index: Integer; const Value: TTMSFNCPlannerDoubleListItem);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.
