{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPlannerData;

interface

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

uses
  Classes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCPlannerBase, WEBLib.Graphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCPersistence
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Generics.Collections, Types, UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,SysUtils
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  {$IFDEF FMXLIB}
  TMSFNCPlannerColor = $FFD7E4EC;
  TMSFNCPlannerFontColor = $FF116F9F;
  TMSFNCPlannerSelectedColor = $FF1BADF8;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TMSFNCPlannerColor = $ECE4D7;
  TMSFNCPlannerFontColor = $9F6F11;
  TMSFNCPlannerSelectedColor = $F8AD1B;
  {$ENDIF}

resourcestring
  sTMSFNCPlannerGroup = 'Group';
  sTMSFNCPlannerResource = 'Resource';

type
  TTMSFNCPlannerFullDayLayout = (pfdlTop, pfdlBottom);

  TTMSFNCPlannerFullDaysLayouts = set of TTMSFNCPlannerFullDayLayout;

const
  AllFullDayLayouts = [pfdlTop, pfdlBottom];

type
  TTMSFNCPlannerData = class;

  TTMSFNCPlannerItem = class;
  TTMSFNCPlannerGroup = class;
  TTMSFNCPlannerResource = class;

  TTMSFNCPlannerCacheItemKind = (ikCell, ikItem, ikPositionTop, ikPositionBottom, ikGroupTop, ikGroupBottom, ikTimeLineLeft, ikTimeLineRight, ikFullDayTop, ikFullDayBottom, ikFullDayItemTop, ikFullDayItemBottom);

  TTMSFNCPlannerCacheItem = class
  private
    FBitmap: TBitmap;
    FRect: TRectF;
    FDrawRect: TRectF;
    FKind: TTMSFNCPlannerCacheItemKind;
    FItem: TTMSFNCPlannerItem;
    FGroup: Integer;
    FFullDay: Integer;
    FPosition: Integer;
    FValue: Double;
    FStartTime: TDateTime;
    FEndTime: TDateTime;
    FRow: Integer;
    FCol: Integer;
    FIdx: Integer;
    FStartPosition: Integer;
    FEndPosition: Integer;
  public
    class function CreateCache(ARect: TRectF; ABitmap: TBitmap; AKind: TTMSFNCPlannerCacheItemKind; APosition: Integer = -1): TTMSFNCPlannerCacheItem;

    class function CreateCell(ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime; APosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateItem(ARect: TRectF; APosition: Integer; AItem: TTMSFNCPlannerItem; AIndex: Integer): TTMSFNCPlannerCacheItem;
    class function CreateFullDayItemTop(ARect: TRectF; APosition: Integer; AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCacheItem;
    class function CreateFullDayItemBottom(ARect: TRectF; APosition: Integer; AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCacheItem;
    class function CreatePositionTop(ARect: TRectF; APosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateGroupTop(ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateFullDayTop(ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateTimeLineLeft(ARect: TRectF; AValue: Double; ARow: Integer): TTMSFNCPlannerCacheItem;
    class function CreatePositionBottom(ARect: TRectF; APosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateGroupBottom(ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateFullDayBottom(ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
    class function CreateTimeLineRight(ARect: TRectF; AValue: Double; ARow: Integer): TTMSFNCPlannerCacheItem;

    property Rect: TRectF read FRect write FRect;
    property DrawRect: TRectF read FDrawRect write FDrawRect;
    property Bitmap: TBitmap read FBitmap write FBitmap;
    property Kind: TTMSFNCPlannerCacheItemKind read FKind write FKind;
    property Item: TTMSFNCPlannerItem read FItem write FItem;
    property Group: Integer read FGroup write FGroup;
    property FullDay: Integer read FFullDay write FFullDay;
    property StartPosition: Integer read FStartPosition write FStartPosition;
    property EndPosition: Integer read FEndPosition write FEndPosition;
    property Position: Integer read FPosition write FPosition;
    property Col: Integer read FCol write FCol;
    property Row: Integer read FRow write FRow;
    property StartTime: TDateTime read FStartTime write FStartTime;
    property EndTime: TDateTime read FEndTime write FEndTime;
    property Value: Double read FValue write FValue;
    property Idx: Integer read FIdx write FIdx;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerCacheItemList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
  public
    property Items[Index: Integer]: TTMSFNCPlannerCacheItem read GetItem write SetItem; default;
  end;
  TTMSFNCPlannerIntegerList = class(TList)
  private
    function GetItem(Index: Integer): Integer;
    procedure SetItem(Index: Integer; const Value: Integer);
  public
    property Items[Index: Integer]: Integer read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerCacheItemList = class(TList<TTMSFNCPlannerCacheItem>);
  TTMSFNCPlannerIntegerList = class(TList<Integer>);
  {$ENDIF}

  TTMSFNCPlannerItemLinkType = (iltFull, iltStartEnd, iltEndStart, iltEndEnd, iltStartStart, iltNone);
  TTMSFNCPlannerItemLinkArrow = (ilaNone, ilaFromTo, ilaToFrom, ilaBoth);

  TTMSFNCPlannerItemMarkType = (imtLeft, imtTop, imtRight, imtBottom);

  TTMSFNCPlannerItemMarkTypes = set of TTMSFNCPlannerItemMarkType;

  TTMSFNCPlannerItem = class(TCollectionItem)
  private
    FCanUpdate: Boolean;
    FUpdatingLinkedItem: Boolean;
    FBlockUpdate: Boolean;
    FCacheList: TTMSFNCPlannerCacheItemList;
    FUpdateCount: Integer;
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FPlanner: TTMSFNCPlannerData;
    FText: String;
    FStartTime: TDateTime;
    FEndTime: TDateTime;
    FResource: Integer;
    FVisible: Boolean;
    FConflictsPosList: TTMSFNCPlannerIntegerList;
    FConflictsList: TTMSFNCPlannerIntegerList;
    FTitle: String;
    FEnabled: Boolean;
    FEditable: Boolean;
    FSelectable: Boolean;
    FColor: TTMSFNCGraphicsColor;
    FTitleColor: TTMSFNCGraphicsColor;
    FDisabledTitleFontColor: TTMSFNCGraphicsColor;
    FSelectedTitleColor: TTMSFNCGraphicsColor;
    FActiveTitleColor: TTMSFNCGraphicsColor;
    FDisabledColor: TTMSFNCGraphicsColor;
    FSelectedColor: TTMSFNCGraphicsColor;
    FActiveColor: TTMSFNCGraphicsColor;
    FTitleFontColor: TTMSFNCGraphicsColor;
    FDisabledFontColor: TTMSFNCGraphicsColor;
    FDisabledTitleColor: TTMSFNCGraphicsColor;
    FSelectedFontColor: TTMSFNCGraphicsColor;
    FActiveFontColor: TTMSFNCGraphicsColor;
    FFontColor: TTMSFNCGraphicsColor;
    FSelectedTitleFontColor: TTMSFNCGraphicsColor;
    FActiveTitleFontColor: TTMSFNCGraphicsColor;
    FDirtyList: TTMSFNCPlannerIntegerList;
    FCleanupList: TTMSFNCPlannerIntegerList;
    FHint: String;
    FPositionsList, FOldPositionsList: TTMSFNCPlannerIntegerList;
    FStartTimeExt: TDateTime;
    FEndTimeExt: TDateTime;
    FConflictsExt: integer;
    FConflictsPosExt: integer;
    FMovable: Boolean;
    FSizeable: Boolean;
    FFixedResource: Boolean;
    FShowTitle: Boolean;
    FDBKey: String;
    FOverlappable: Boolean;
    FCallPosition: Integer;
    FDataBoolean: Boolean;
    FRecurrency: string;
    FRecurrent: Boolean;
    FMasterItem: TTMSFNCPlannerItem;
    FOldEndTime: TDateTime;
    FOldStartTime: TDateTime;
    FMasterStartDateTime: TDateTime;
    FMasterEndDateTime: TDateTime;
    FDeletable: Boolean;
    FLinkedItem: TTMSFNCPlannerItem;
    FLinkType: TTMSFNCPlannerItemLinkType;
    FNewPosition: Integer;
    FNewStartTime: TDateTime;
    FNewEndTime: TDateTime;
    FUpdatingLinked: Boolean;
    FLinkArrow: TTMSFNCPlannerItemLinkArrow;
    FSelectedLinkColor: TTMSFNCGraphicsColor;
    FLinkColor: TTMSFNCGraphicsColor;
    FDisabledFontStyle: TFontStyles;
    FSelectedFontStyle: TFontStyles;
    FActiveFontStyle: TFontStyles;
    FFontStyle: TFontStyles;
    FDisabledTitleFontStyle: TFontStyles;
    FSelectedTitleFontStyle: TFontStyles;
    FActiveTitleFontStyle: TFontStyles;
    FTitleFontStyle: TFontStyles;
    FUseDefaultAppearance: Boolean;
    FDisabledFontName: string;
    FSelectedFontName: string;
    FActiveFontName: string;
    FDisabledTitleFontName: string;
    FSelectedTitleFontName: string;
    FActiveTitleFontName: string;
    FTitleFontName: string;
    FFontName: string;
    FTextVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTextHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FActiveFontSize: Single;
    FFontSize: Single;
    FDisabledTitleFontSize: Single;
    FSelectedTitleFontSize: Single;
    FActiveTitleFontSize: Single;
    FTitleFontSize: Single;
    FDisabledFontSize: Single;
    FSelectedFontSize: Single;
    FDataPointer: Pointer;
    FBackground: Boolean;
    FMargins: TTMSFNCMargins;
    FFullDayLayout: TTMSFNCPlannerFullDaysLayouts;
    FFullDay: Boolean;
    FFullDayMaxListTop: TTMSFNCPlannerIntegerList;
    FFullDayMaxListBottom: TTMSFNCPlannerIntegerList;
    FFullDayPosListTop: TTMSFNCPlannerIntegerList;
    FFullDayPosListBottom: TTMSFNCPlannerIntegerList;
    FMarkRounding: Integer;
    FMarkSizeRight: Single;
    FMarkColorBottom: TTMSFNCGraphicsColor;
    FMarkCorners: TTMSFNCGraphicsCorners;
    FMarkSizeBottom: Single;
    FMarkColorTop: TTMSFNCGraphicsColor;
    FMarkColorLeft: TTMSFNCGraphicsColor;
    FMarkColor: TTMSFNCGraphicsColor;
    FMarkColorRight: TTMSFNCGraphicsColor;
    FMarkSizeTop: Single;
    FMarkSizeLeft: Single;
    FMarkType: TTMSFNCPlannerItemMarkTypes;
    FRounding: Integer;
    FRoundingCorners: TTMSFNCGraphicsCorners;
    FDisabledStrokeColor: TTMSFNCGraphicsColor;
    FSelectedStrokeColor: TTMSFNCGraphicsColor;
    FActiveStrokeColor: TTMSFNCGraphicsColor;
    FStrokeColor: TTMSFNCGraphicsColor;
    FDisabledTitleStrokeColor: TTMSFNCGraphicsColor;
    FSelectedTitleStrokeColor: TTMSFNCGraphicsColor;
    FActiveTitleStrokeColor: TTMSFNCGraphicsColor;
    FTitleStrokeColor: TTMSFNCGraphicsColor;
    FFullDayMaxListBottomIndex: Integer;
    FFullDayMaxListTopIndex: Integer;
    FFullDayMaxListBottomCount: Integer;
    FFullDayMaxListTopCount: Integer;
    FFullDayCacheList: TTMSFNCPlannerCacheItemList;
    procedure SetText(const Value: String);
    procedure SetEndTime(const Value: TDateTime);
    procedure SetStartTime(const Value: TDateTime);
    procedure SetResource(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
    procedure SetTitle(const Value: String);
    procedure SetEditable(const Value: Boolean);
    procedure SetEnabled(const Value: Boolean);
    procedure SetSelectable(const Value: Boolean);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveTitleColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHint(const Value: String);
    procedure SetMovable(const Value: Boolean);
    procedure SetSizeable(const Value: Boolean);
    procedure SetFixedResource(const Value: Boolean);
    procedure SetShowTitle(const Value: Boolean);
    procedure SetOverlappable(const Value: Boolean);
    procedure SetDeletable(const Value: Boolean);
    procedure SetLinkedItem(const Value: TTMSFNCPlannerItem);
    procedure SetLinkType(const Value: TTMSFNCPlannerItemLinkType);
    procedure SetLinkArrow(const Value: TTMSFNCPlannerItemLinkArrow);
    procedure SetLinkColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedLinkColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveFontStyle(const Value: TFontStyles);
    procedure SetActiveTitleFontStyle(const Value: TFontStyles);
    procedure SetDisabledFontStyle(const Value: TFontStyles);
    procedure SetDisabledTitleFontStyle(const Value: TFontStyles);
    procedure SetFontStyle(const Value: TFontStyles);
    procedure SetSelectedFontStyle(const Value: TFontStyles);
    procedure SetSelectedTitleFontStyle(const Value: TFontStyles);
    procedure SetTitleFontStyle(const Value: TFontStyles);
    procedure SetActiveFontName(const Value: string);
    procedure SetActiveTitleFontName(const Value: string);
    procedure SetDisabledFontName(const Value: string);
    procedure SetDisabledTitleFontName(const Value: string);
    procedure SetSelectedFontName(const Value: string);
    procedure SetSelectedTitleFontName(const Value: string);
    procedure SetTitleFontName(const Value: string);
    procedure SetUseDefaultAppearance(const Value: Boolean);
    procedure SetFontName(const Value: string);
    procedure SetTextHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTextVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    function IsActiveFontSizeStored: Boolean;
    function IsActiveTitleFontSizeStored: Boolean;
    function IsDisabledFontSizeStored: Boolean;
    function IsDisabledTitleFontSizeStored: Boolean;
    function IsFontSizeStored: Boolean;
    function IsSelectedFontSizeStored: Boolean;
    function IsSelectedTitleFontSizeStored: Boolean;
    function IsTitleFontSizeStored: Boolean;
    procedure SetActiveFontSize(const Value: Single);
    procedure SetActiveTitleFontSize(const Value: Single);
    procedure SetDisabledFontSize(const Value: Single);
    procedure SetDisabledTitleFontSize(const Value: Single);
    procedure SetFontSize(const Value: Single);
    procedure SetSelectedFontSize(const Value: Single);
    procedure SetSelectedTitleFontSize(const Value: Single);
    procedure SetTitleFontSize(const Value: Single);
    procedure SetBackground(const Value: Boolean);
    procedure SetMargins(const Value: TTMSFNCMargins);
    procedure SetFullDay(const Value: Boolean);
    procedure SetFullDayLayout(const Value: TTMSFNCPlannerFullDaysLayouts);
    function IsMarkSizeBottomStored: Boolean;
    function IsMarkSizeLeftStored: Boolean;
    function IsMarkSizeRightStored: Boolean;
    function IsMarkSizeTopStored: Boolean;
    procedure SetMarkColor(const Value: TTMSFNCGraphicsColor);
    procedure SetMarkColorBottom(const Value: TTMSFNCGraphicsColor);
    procedure SetMarkColorLeft(const Value: TTMSFNCGraphicsColor);
    procedure SetMarkColorRight(const Value: TTMSFNCGraphicsColor);
    procedure SetMarkColorTop(const Value: TTMSFNCGraphicsColor);
    procedure SetMarkCorners(const Value: TTMSFNCGraphicsCorners);
    procedure SetMarkRounding(const Value: Integer);
    procedure SetMarkSizeBottom(const Value: Single);
    procedure SetMarkSizeLeft(const Value: Single);
    procedure SetMarkSizeRight(const Value: Single);
    procedure SetMarkSizeTop(const Value: Single);
    procedure SetMarkType(const Value: TTMSFNCPlannerItemMarkTypes);
    procedure SetRounding(const Value: Integer);
    procedure SetRoundingCorners(const Value: TTMSFNCGraphicsCorners);
    procedure SetActiveStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
  protected
    procedure DoMarginsChanged(Sender: TObject);
    property FullDayMaxListTopIndex: Integer read FFullDayMaxListTopIndex write FFullDayMaxListTopIndex;
    property FullDayMaxListBottomIndex: Integer read FFullDayMaxListBottomIndex write FFullDayMaxListBottomIndex;
    property FullDayMaxListTopCount: Integer read FFullDayMaxListTopCount write FFullDayMaxListTopCount;
    property FullDayMaxListBottomCount: Integer read FFullDayMaxListBottomCount write FFullDayMaxListBottomCount;
    property FullDayMaxListTop: TTMSFNCPlannerIntegerList read FFullDayMaxListTop write FFullDayMaxListTop;
    property FullDayMaxListBottom: TTMSFNCPlannerIntegerList read FFullDayMaxListBottom write FFullDayMaxListBottom;
    property FullDayPosListTop: TTMSFNCPlannerIntegerList read FFullDayPosListTop write FFullDayPosListTop;
    property FullDayPosListBottom: TTMSFNCPlannerIntegerList read FFullDayPosListBottom write FFullDayPosListBottom;
    property ConflictsList: TTMSFNCPlannerIntegerList read FConflictsList write FConflictsList;
    property ConflictsPosList: TTMSFNCPlannerIntegerList read FConflictsPosList write FConflictsPosList;
    property PositionsList: TTMSFNCPlannerIntegerList read FPositionsList write FPositionsList;
    property OldPositionsList: TTMSFNCPlannerIntegerList read FOldPositionsList write FOldPositionsList;
    property DirtyList: TTMSFNCPlannerIntegerList read FDirtyList write FDirtyList;
    property CleanupList: TTMSFNCPlannerIntegerList read FCleanupList write FCleanupList;
    property CacheList: TTMSFNCPlannerCacheItemList read FCacheList write FCacheList;
    property FullDayCacheList: TTMSFNCPlannerCacheItemList read FFullDayCacheList write FFullDayCacheList;
    property StartTimeExt: TDateTime read FStartTimeExt write FStartTimeExt;
    property EndTimeExt: TDateTime read FEndTimeExt write FEndTimeExt;
    property ConflictsExt: integer read FConflictsExt write FConflictsExt;
    property ConflictsPosExt: integer read FConflictsPosExt write FConflictsPosExt;
    property CallPosition: Integer read FCallPosition write FCallPosition;
    property CanUpdate: Boolean read FCanUpdate write FCanUpdate;
    property NewStartTime: TDateTime read FNewStartTime write FNewStartTime;
    property NewEndTime: TDateTime read FNewEndTime write FNewEndTime;
    property NewPosition: Integer read FNewPosition write FNewPosition;
    property UpdatingLinked: Boolean read FUpdatingLinked write FUpdatingLinked;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure DirtyItem;
    procedure UpdateItem(ANeedsConflictsUpdate: Boolean = False; ADirty: Boolean = True);
    procedure Assign(Source: TPersistent); override;
    procedure BeginUpdate;
    procedure EndUpdate(ADirty: Boolean = True; AUpdate: Boolean = True);
    function ConflictsForPosition(APosition: Integer): Integer;
    function ConflictsPosForPosition(APosition: Integer): Integer;
    function Planner: TTMSFNCPlannerData;
    function GetRect(AIndex: Integer = -1): TRectF; virtual;
    function GetFirstRect: TRectF; virtual;
    function GetLastRect: TRectF; virtual;
    function RectCount: Integer; virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property Recurrency: string read FRecurrency write FRecurrency;
    property Recurrent: Boolean read FRecurrent write FRecurrent;
    property OldStartTime: TDateTime read FOldStartTime write FOldStartTime;
    property OldEndTime: TDateTime read FOldEndTime write FOldEndTime;
    property LinkedItem: TTMSFNCPlannerItem read FLinkedItem write SetLinkedItem;
    property LinkType: TTMSFNCPlannerItemLinkType read FLinkType write SetLinkType default iltNone;
    property MasterStartDateTime: TDateTime read FMasterStartDateTime write FMasterStartDateTime;
    property MasterEndDateTime: TDateTime read FMasterEndDateTime write FMasterEndDateTime;
    property Background: Boolean read FBackground write SetBackground default False;
  published
    property Text: String read FText write SetText;
    property Title: String read FTitle write SetTitle;
    property Resource: Integer read FResource write SetResource default 0;
    property StartTime: TDateTime read FStartTime write SetStartTime;
    property EndTime: TDateTime read FEndTime write SetEndTime;
    property FullDay: Boolean read FFullDay write SetFullDay default False;
    property FullDayLayout: TTMSFNCPlannerFullDaysLayouts read FFullDayLayout write SetFullDayLayout default AllFullDayLayouts;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Editable: Boolean read FEditable write SetEditable default True;
    property Selectable: Boolean read FSelectable write SetSelectable default True;
    property Deletable: Boolean read FDeletable write SetDeletable default True;
    property FixedResource: Boolean read FFixedResource write SetFixedResource default False;
    property ShowTitle: Boolean read FShowTitle write SetShowTitle default True;
    property Movable: Boolean read FMovable write SetMovable default True;
    property Sizeable: Boolean read FSizeable write SetSizeable default True;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default TMSFNCPlannerColor;
    property StrokeColor: TTMSFNCGraphicsColor read FStrokeColor write SetStrokeColor default TMSFNCPlannerColor;
    property TitleColor: TTMSFNCGraphicsColor read FTitleColor write SetTitleColor default gcNull;
    property TitleStrokeColor: TTMSFNCGraphicsColor read FTitleStrokeColor write SetTitleStrokeColor default TMSFNCPlannerColor;
    property SelectedColor: TTMSFNCGraphicsColor read FSelectedColor write SetSelectedColor default TMSFNCPlannerSelectedColor;
    property SelectedTitleColor: TTMSFNCGraphicsColor read FSelectedTitleColor write SetSelectedTitleColor default gcNull;
    property SelectedStrokeColor: TTMSFNCGraphicsColor read FSelectedStrokeColor write SetSelectedStrokeColor default gcDarkGray;
    property SelectedTitleStrokeColor: TTMSFNCGraphicsColor read FSelectedTitleStrokeColor write SetSelectedTitleStrokeColor default gcDarkGray;
    property ActiveColor: TTMSFNCGraphicsColor read FActiveColor write SetActiveColor default TMSFNCPlannerSelectedColor;
    property ActiveTitleColor: TTMSFNCGraphicsColor read FActiveTitleColor write SetActiveTitleColor default gcNull;
    property ActiveStrokeColor: TTMSFNCGraphicsColor read FActiveStrokeColor write SetActiveStrokeColor default gcDarkGray;
    property ActiveTitleStrokeColor: TTMSFNCGraphicsColor read FActiveTitleStrokeColor write SetActiveTitleStrokeColor default gcDarkGray;
    property DisabledColor: TTMSFNCGraphicsColor read FDisabledColor write SetDisabledColor default gcDarkGray;
    property DisabledTitleColor: TTMSFNCGraphicsColor read FDisabledTitleColor write SetDisabledTitleColor default gcNull;
    property DisabledStrokeColor: TTMSFNCGraphicsColor read FDisabledStrokeColor write SetDisabledStrokeColor default gcDarkGray;
    property DisabledTitleStrokeColor: TTMSFNCGraphicsColor read FDisabledTitleStrokeColor write SetDisabledTitleStrokeColor default gcDarkGray;
    property FontColor: TTMSFNCGraphicsColor read FFontColor write SetFontColor default TMSFNCPlannerFontColor;
    property TitleFontColor: TTMSFNCGraphicsColor read FTitleFontColor write SetTitleFontColor default TMSFNCPlannerFontColor;
    property SelectedFontColor: TTMSFNCGraphicsColor read FSelectedFontColor write SetSelectedFontColor default gcWhite;
    property SelectedTitleFontColor: TTMSFNCGraphicsColor read FSelectedTitleFontColor write SetSelectedTitleFontColor default gcWhite;
    property ActiveFontColor: TTMSFNCGraphicsColor read FActiveFontColor write SetActiveFontColor default gcWhite;
    property ActiveTitleFontColor: TTMSFNCGraphicsColor read FActiveTitleFontColor write SetActiveTitleFontColor default gcWhite;
    property DisabledFontColor: TTMSFNCGraphicsColor read FDisabledFontColor write SetDisabledFontColor default gcWhitesmoke;
    property DisabledTitleFontColor: TTMSFNCGraphicsColor read FDisabledTitleFontColor write SetDisabledTitleFontColor default gcWhitesmoke;
    property FontStyle: TFontStyles read FFontStyle write SetFontStyle default [];
    property TitleFontStyle: TFontStyles read FTitleFontStyle write SetTitleFontStyle default [TFontStyle.fsBold];
    property SelectedFontStyle: TFontStyles read FSelectedFontStyle write SetSelectedFontStyle default [];
    property SelectedTitleFontStyle: TFontStyles read FSelectedTitleFontStyle write SetSelectedTitleFontStyle default [TFontStyle.fsBold];
    property ActiveFontStyle: TFontStyles read FActiveFontStyle write SetActiveFontStyle default [];
    property ActiveTitleFontStyle: TFontStyles read FActiveTitleFontStyle write SetActiveTitleFontStyle default [TFontStyle.fsBold];
    property DisabledFontStyle: TFontStyles read FDisabledFontStyle write SetDisabledFontStyle default [];
    property DisabledTitleFontStyle: TFontStyles read FDisabledTitleFontStyle write SetDisabledTitleFontStyle default [TFontStyle.fsBold];
    property FontName: string read FFontName write SetFontName;
    property TitleFontName: string read FTitleFontName write SetTitleFontName;
    property SelectedFontName: string read FSelectedFontName write SetSelectedFontName;
    property SelectedTitleFontName: string read FSelectedTitleFontName write SetSelectedTitleFontName;
    property ActiveFontName: string read FActiveFontName write SetActiveFontName;
    property ActiveTitleFontName: string read FActiveTitleFontName write SetActiveTitleFontName;
    property DisabledFontName: string read FDisabledFontName write SetDisabledFontName;
    property DisabledTitleFontName: string read FDisabledTitleFontName write SetDisabledTitleFontName;
    property FontSize: Single read FFontSize write SetFontSize stored IsFontSizeStored nodefault;
    property TitleFontSize: Single read FTitleFontSize write SetTitleFontSize stored IsTitleFontSizeStored nodefault;
    property SelectedFontSize: Single read FSelectedFontSize write SetSelectedFontSize stored IsSelectedFontSizeStored nodefault;
    property SelectedTitleFontSize: Single read FSelectedTitleFontSize write SetSelectedTitleFontSize stored IsSelectedTitleFontSizeStored nodefault;
    property ActiveFontSize: Single read FActiveFontSize write SetActiveFontSize stored IsActiveFontSizeStored nodefault;
    property ActiveTitleFontSize: Single read FActiveTitleFontSize write SetActiveTitleFontSize stored IsActiveTitleFontSizeStored nodefault;
    property DisabledFontSize: Single read FDisabledFontSize write SetDisabledFontSize stored IsDisabledFontSizeStored nodefault;
    property DisabledTitleFontSize: Single read FDisabledTitleFontSize write SetDisabledTitleFontSize stored IsDisabledTitleFontSizeStored nodefault;
    property UseDefaultAppearance: Boolean read FUseDefaultAppearance write SetUseDefaultAppearance default False;
    property LinkColor: TTMSFNCGraphicsColor read FLinkColor write SetLinkColor default gcDarkgray;
    property SelectedLinkColor: TTMSFNCGraphicsColor read FSelectedLinkColor write SetSelectedLinkColor default TMSFNCPlannerSelectedColor;
    property LinkArrow: TTMSFNCPlannerItemLinkArrow read FLinkArrow write SetLinkArrow default ilaNone;
    property TitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTitleHorizontalTextAlign write SetTitleHorizontalTextAlign default gtaLeading;
    property TitleVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTitleVerticalTextAlign write SetTitleVerticalTextAlign default gtaLeading;
    property TextHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTextHorizontalTextAlign write SetTextHorizontalTextAlign default gtaLeading;
    property TextVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTextVerticalTextAlign write SetTextVerticalTextAlign default gtaLeading;
    property Overlappable: Boolean read FOverlappable write SetOverlappable default True;
    property Hint: String read FHint write SetHint;
    property Tag: NativeInt read FTag write FTag default 0;
    property Margins: TTMSFNCMargins read FMargins write SetMargins;
    property MarkType: TTMSFNCPlannerItemMarkTypes read FMarkType write SetMarkType default [];
    property MarkColor: TTMSFNCGraphicsColor read FMarkColor write SetMarkColor default gcRed;
    property MarkColorLeft: TTMSFNCGraphicsColor read FMarkColorLeft write SetMarkColorLeft default gcNull;
    property MarkColorRight: TTMSFNCGraphicsColor read FMarkColorRight write SetMarkColorRight default gcNull;
    property MarkColorTop: TTMSFNCGraphicsColor read FMarkColorTop write SetMarkColorTop default gcNull;
    property MarkColorBottom: TTMSFNCGraphicsColor read FMarkColorBottom write SetMarkColorBottom default gcNull;
    property MarkSizeLeft: Single read FMarkSizeLeft write SetMarkSizeLeft stored IsMarkSizeLeftStored nodefault;
    property MarkSizeTop: Single read FMarkSizeTop write SetMarkSizeTop stored IsMarkSizeTopStored nodefault;
    property MarkSizeRight: Single read FMarkSizeRight write SetMarkSizeRight stored IsMarkSizeRightStored nodefault;
    property MarkSizeBottom: Single read FMarkSizeBottom write SetMarkSizeBottom stored IsMarkSizeBottomStored nodefault;
    property MarkRounding: Integer read FMarkRounding write SetMarkRounding default 0;
    property MarkCorners: TTMSFNCGraphicsCorners read FMarkCorners write SetMarkCorners;
    property Rounding: Integer read FRounding write SetRounding default 0;
    property RoundingCorners: TTMSFNCGraphicsCorners read FRoundingCorners write SetRoundingCorners;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerItems = class(TTMSFNCOwnedCollection, ITMSFNCBaseListIO, ITMSFNCBasePersistenceIO)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCPlannerItem>, ITMSFNCBaseListIO, ITMSFNCBasePersistenceIO)
  {$ENDIF}
  private
    FOwnerInterface: IInterface;
    FPlanner: TTMSFNCPlannerData;
    function GetItem(Index: Integer): TTMSFNCPlannerItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerItem);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
    function ITMSFNCBaseListIO.GetItemClass = GetInterfaceItemClass;
    function CreateObject(const AClassName: string; const ABaseClass: TClass): TObject;
    function GetInterfaceItemClass: TClass;
    {$IFDEF LCLLIB}
    function _AddRef : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    function _Release : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    {$ELSE}
    function _AddRef: Integer; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    function _Release: Integer; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    {$ENDIF}
  public
    {$IFDEF LCLLIB}
    function QueryInterface({$IFDEF FPC_HAS_CONSTREF}constref{$ELSE}const{$ENDIF} iid : tguid;out obj) : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    {$ELSE}
    function QueryInterface(const IID: TGUID; out Obj): HResult; virtual; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    {$ENDIF}
    procedure Clear;
    function Planner: TTMSFNCPlannerData;
    constructor Create(APlanner: TTMSFNCPlannerData);
    function Add: TTMSFNCPlannerItem;
    function Insert(Index: Integer): TTMSFNCPlannerItem;
    property Items[Index: Integer]: TTMSFNCPlannerItem read GetItem write SetItem; default;
  end;

  TTMSFNCPlannerResourceDate = record
    DateTime: TDateTime;
    X: integer;
    Y: integer;
    Z: array of boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCPlannerResourceDate) b : Boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerResourceItems = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerItem);
  public
    property Items[Index: Integer]: TTMSFNCPlannerItem read GetItem write SetItem; default;
  end;
  TTMSFNCPlannerResourceDates = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPlannerResourceDate;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerResourceDate);
  public
    property Items[Index: Integer]: TTMSFNCPlannerResourceDate read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerResourceItems = class(TList<TTMSFNCPlannerItem>);
  TTMSFNCPlannerResourceDates = class(TList<TTMSFNCPlannerResourceDate>);
  {$ENDIF}

  TTMSFNCPlannerResource = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FPlanner: TTMSFNCPlannerData;
    FText: String;
    FName: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FDataPointer: Pointer;
    FHint: string;
    procedure SetText(const Value: String);
    procedure SetName(const Value: String);
    procedure SetHint(const Value: string);
  protected
    procedure UpdateResource;
    function GetText: String; virtual;
    function GetResourceText: String; virtual;
  public
    function Planner: TTMSFNCPlannerData;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
  published
    property Name: String read FName write SetName;
    property Text: String read FText write SetText;
    property Hint: string read FHint write SetHint;
    property Tag: NativeInt read FTag write FTag default 0;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerResources = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerResources = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCPlannerResource>)
  {$ENDIF}
  private
    FPlanner: TTMSFNCPlannerData;
    function GetItem(Index: Integer): TTMSFNCPlannerResource;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerResource);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
  public
    function Planner: TTMSFNCPlannerData;
    constructor Create(APlanner: TTMSFNCPlannerData);
    function Add: TTMSFNCPlannerResource;
    function Insert(Index: Integer): TTMSFNCPlannerResource;
    property Items[Index: Integer]: TTMSFNCPlannerResource read GetItem write SetItem; default;
  end;

  TTMSFNCPlannerGroup = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FPlanner: TTMSFNCPlannerData;
    FText: String;
    FStartPosition: Integer;
    FEndPosition: Integer;
    FName: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FDataPointer: Pointer;
    FHint: string;
    procedure SetText(const Value: String);
    procedure SetStartPosition(const Value: Integer);
    procedure SetEndPosition(const Value: Integer);
    procedure SetName(const Value: String);
    procedure SetHint(const Value: string);
  protected
    procedure UpdateGroup;
  public
    function Planner: TTMSFNCPlannerData;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    function GetText: String; virtual;
  published
    property Name: String read FName write SetName;
    property Text: String read FText write SetText;
    property Hint: string read FHint write SetHint;
    property StartPosition: Integer read FStartPosition write SetStartPosition default 0;
    property EndPosition: Integer read FEndPosition write SetEndPosition default 0;
    property Tag: NativeInt read FTag write FTag default 0;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPlannerGroups = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPlannerGroups = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCPlannerGroup>)
  {$ENDIF}
  private
    FPlanner: TTMSFNCPlannerData;
    function GetItem(Index: Integer): TTMSFNCPlannerGroup;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPlannerGroup);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
  public
    function Planner: TTMSFNCPlannerData;
    constructor Create(APlanner: TTMSFNCPlannerData);
    function Add: TTMSFNCPlannerGroup;
    function Insert(Index: Integer): TTMSFNCPlannerGroup;
    property Items[Index: Integer]: TTMSFNCPlannerGroup read GetItem write SetItem; default;
  end;

  TTMSFNCPlannerData = class(TTMSFNCPlannerBase)
  private
    FItems: TTMSFNCPlannerItems;
    FResources: TTMSFNCPlannerResources;
    FGroups: TTMSFNCPlannerGroups;
    procedure SetItems(const Value: TTMSFNCPlannerItems);
    procedure SetResources(const Value: TTMSFNCPlannerResources);
    procedure SetGroups(const Value: TTMSFNCPlannerGroups);
  protected
    procedure DirtyItems; override;
    procedure DirtyItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure RemoveItemsFromCache(AList: TTMSFNCPlannerCacheItemList; APosition: Integer = -1); virtual; abstract;
    procedure RemoveAllItemsFromCache; virtual; abstract;
    procedure NeedsConflictsUpdate(AItem: TTMSFNCPlannerItem = nil); virtual; abstract;
    procedure RemoveLinkedItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    procedure UpdateLinkedItems(AItem: TTMSFNCPlannerItem; ADiffStartTime, ADiffEndTime: TDateTime; ADiffPosition: Integer); virtual; abstract;
    function CreateItems: TTMSFNCPlannerItems; virtual;
    function CreateResources: TTMSFNCPlannerResources; virtual;
    function CreateGroups: TTMSFNCPlannerGroups; virtual;
    function GetDefaultItem: TTMSFNCPlannerItem; virtual; abstract;
    function GetActiveItem: TTMSFNCPlannerItem; virtual; abstract;
    procedure UpdateActiveItem(AItem: TTMSFNCPlannerItem); virtual; abstract;
    property Items: TTMSFNCPlannerItems read FItems write SetItems;
    property Resources: TTMSFNCPlannerResources read FResources write SetResources;
    property Groups: TTMSFNCPlannerGroups read FGroups write SetGroups;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function FindResourceByName(AName: String): TTMSFNCPlannerResource; virtual;
    function FindGroupByName(AName: String): TTMSFNCPlannerGroup; virtual;
    function FindResourceIndexByName(AName: String): Integer; virtual;
    function FindGroupIndexByName(AName: String): Integer; virtual;
  end;

function TranslateTextEx(AText: String): String;

implementation

uses
  DateUtils
  {$IFNDEF WEBLIB}
  ,SysUtils
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  {$IFNDEF LCLLIB}
  ,StrUtils
  {$ENDIF}
  ;

function TranslateTextEx(AText: String): String;
begin
  {$IFDEF FMXLIB}
  Result := TranslateText(AText);
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result := AText;
  {$ENDIF}
end;

{ TTMSFNCPlannerData }

procedure TTMSFNCPlannerData.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCPlannerData then
  begin
    FItems.Assign((Source as TTMSFNCPlannerData).Items);
    FResources.Assign((Source as TTMSFNCPlannerData).Resources);
    FGroups.Assign((Source as TTMSFNCPlannerData).Groups);
  end;
end;

constructor TTMSFNCPlannerData.Create(AOwner: TComponent);
begin
  inherited;
  FItems := CreateItems;
  FResources := CreateResources;
  FGroups := CreateGroups;
end;

function TTMSFNCPlannerData.CreateGroups: TTMSFNCPlannerGroups;
begin
  Result := TTMSFNCPlannerGroups.Create(Self);
end;

function TTMSFNCPlannerData.CreateItems: TTMSFNCPlannerItems;
begin
  Result := TTMSFNCPlannerItems.Create(Self);
end;

function TTMSFNCPlannerData.CreateResources: TTMSFNCPlannerResources;
begin
  Result := TTMSFNCPlannerResources.Create(Self);
end;

destructor TTMSFNCPlannerData.Destroy;
begin
  FGroups.Free;
  FResources.Free;
  FItems.Free;
  inherited;
end;

procedure TTMSFNCPlannerData.DirtyItems;
var
  I: Integer;
begin
  for I := 0 to Items.Count - 1 do
    Items[I].DirtyItem;
end;

function TTMSFNCPlannerData.FindGroupByName(AName: String): TTMSFNCPlannerGroup;
var
  I: Integer;
  grp: TTMSFNCPlannerGroup;
begin
  Result := nil;
  for I := 0 to Groups.Count - 1 do
  begin
    grp := Groups[I];
    if grp.Name = AName then
    begin
      Result := grp;
      Break;
    end;
  end;
end;

function TTMSFNCPlannerData.FindGroupIndexByName(AName: String): Integer;
var
  grp: TTMSFNCPlannerGroup;
begin
  Result := -1;
  grp := FindGroupByName(AName);
  if Assigned(grp) then
    Result := grp.Index;
end;

function TTMSFNCPlannerData.FindResourceByName(
  AName: String): TTMSFNCPlannerResource;
var
  I: Integer;
  res: TTMSFNCPlannerResource;
begin
  Result := nil;
  for I := 0 to Resources.Count - 1 do
  begin
    res := Resources[I];
    if res.Name = AName then
    begin
      Result := res;
      Break;
    end;
  end;
end;

function TTMSFNCPlannerData.FindResourceIndexByName(AName: String): Integer;
var
  res: TTMSFNCPlannerResource;
begin
  Result := -1;
  res := FindResourceByName(AName);
  if Assigned(res) then
    Result := res.Index;
end;

procedure TTMSFNCPlannerData.SetGroups(const Value: TTMSFNCPlannerGroups);
begin
  FGroups.Assign(Value);
end;

procedure TTMSFNCPlannerData.SetItems(const Value: TTMSFNCPlannerItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCPlannerData.SetResources(const Value: TTMSFNCPlannerResources);
begin
  FResources.Assign(Value);
end;

{ TTMSFNCPlannerItem }

procedure TTMSFNCPlannerItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerItem then
  begin
    FBlockUpdate := True;
    FMargins.Assign((Source as TTMSFNCPlannerItem).Margins);
    FRecurrency := (Source as TTMSFNCPlannerItem).Recurrency;
    FRecurrent := (Source as TTMSFNCPlannerItem).Recurrent;
    FOverlappable := (Source as TTMSFNCPlannerItem).Overlappable;
    FLinkArrow := (Source as TTMSFNCPlannerItem).LinkArrow;
    FLinkColor := (Source as TTMSFNCPlannerItem).LinkColor;
    FSelectedLinkColor := (Source as TTMSFNCPlannerItem).SelectedLinkColor;
    FTag := (Source as TTMSFNCPlannerItem).Tag;
    FText := (Source as TTMSFNCPlannerItem).Text;
    FStartTime := (Source as TTMSFNCPlannerItem).StartTime;
    FEndTime := (Source as TTMSFNCPlannerItem).EndTime;
    FResource := (Source as TTMSFNCPlannerItem).Resource;
    FTitle := (Source as TTMSFNCPlannerItem).Title;
    FVisible := (Source as TTMSFNCPlannerItem).Visible;
    FFullDay := (Source as TTMSFNCPlannerItem).FullDay;
    FFullDayLayout := (Source as TTMSFNCPlannerItem).FullDayLayout;
    FEditable := (Source as TTMSFNCPlannerItem).Editable;
    FShowTitle := (Source as TTMSFNCPlannerItem).ShowTitle;
    FEnabled := (Source as TTMSFNCPlannerItem).Enabled;
    FSelectable := (Source as TTMSFNCPlannerItem).Selectable;
    FDeletable := (Source as TTMSFNCPlannerItem).Deletable;
    FSizeable := (Source as TTMSFNCPlannerItem).Sizeable;
    FMovable := (Source as TTMSFNCPlannerItem).Movable;
    FFixedResource := (Source as TTMSFNCPlannerItem).FixedResource;
    FColor := (Source as TTMSFNCPlannerItem).Color;
    FFontColor := (Source as TTMSFNCPlannerItem).FontColor;
    FFontStyle := (Source as TTMSFNCPlannerItem).FontStyle;
    FFontName := (Source as TTMSFNCPlannerItem).FontName;
    FFontSize := (Source as TTMSFNCPlannerItem).FontSize;
    FTitleColor := (Source as TTMSFNCPlannerItem).TitleColor;
    FTitleFontColor := (Source as TTMSFNCPlannerItem).TitleFontColor;
    FTitleFontStyle := (Source as TTMSFNCPlannerItem).TitleFontStyle;
    FTitleFontName := (Source as TTMSFNCPlannerItem).TitleFontName;
    FTitleFontSize := (Source as TTMSFNCPlannerItem).TitleFontSize;
    FSelectedColor := (Source as TTMSFNCPlannerItem).SelectedColor;
    FSelectedFontColor := (Source as TTMSFNCPlannerItem).SelectedFontColor;
    FSelectedFontName := (Source as TTMSFNCPlannerItem).SelectedFontName;
    FSelectedFontSize := (Source as TTMSFNCPlannerItem).SelectedFontSize;
    FSelectedFontStyle := (Source as TTMSFNCPlannerItem).SelectedFontStyle;
    FSelectedTitleColor := (Source as TTMSFNCPlannerItem).SelectedTitleColor;
    FSelectedTitleFontColor := (Source as TTMSFNCPlannerItem).SelectedTitleFontColor;
    FSelectedTitleFontStyle := (Source as TTMSFNCPlannerItem).SelectedTitleFontStyle;
    FSelectedTitleFontName := (Source as TTMSFNCPlannerItem).SelectedTitleFontName;
    FSelectedTitleFontSize := (Source as TTMSFNCPlannerItem).SelectedTitleFontSize;
    FActiveColor := (Source as TTMSFNCPlannerItem).ActiveColor;
    FActiveFontColor := (Source as TTMSFNCPlannerItem).ActiveFontColor;
    FActiveTitleColor := (Source as TTMSFNCPlannerItem).ActiveTitleColor;
    FActiveTitleFontColor := (Source as TTMSFNCPlannerItem).ActiveTitleFontColor;
    FActiveFontStyle := (Source as TTMSFNCPlannerItem).ActiveFontStyle;
    FActiveFontName := (Source as TTMSFNCPlannerItem).ActiveFontName;
    FActiveFontSize := (Source as TTMSFNCPlannerItem).ActiveFontSize;
    FActiveTitleFontStyle := (Source as TTMSFNCPlannerItem).ActiveTitleFontStyle;
    FActiveTitleFontName := (Source as TTMSFNCPlannerItem).ActiveTitleFontName;
    FActiveTitleFontSize := (Source as TTMSFNCPlannerItem).ActiveTitleFontSize;
    FDisabledColor := (Source as TTMSFNCPlannerItem).DisabledColor;
    FDisabledFontColor := (Source as TTMSFNCPlannerItem).DisabledFontColor;
    FDisabledTitleColor := (Source as TTMSFNCPlannerItem).DisabledTitleColor;
    FDisabledTitleFontColor := (Source as TTMSFNCPlannerItem).DisabledTitleFontColor;
    FDisabledFontName := (Source as TTMSFNCPlannerItem).DisabledFontName;
    FDisabledFontSize := (Source as TTMSFNCPlannerItem).DisabledFontSize;
    FDisabledFontStyle := (Source as TTMSFNCPlannerItem).DisabledFontStyle;
    FDisabledTitleFontName := (Source as TTMSFNCPlannerItem).DisabledTitleFontName;
    FDisabledTitleFontStyle := (Source as TTMSFNCPlannerItem).DisabledTitleFontStyle;
    FDisabledTitleFontSize := (Source as TTMSFNCPlannerItem).DisabledTitleFontSize;
    FUseDefaultAppearance := (Source as TTMSFNCPlannerItem).UseDefaultAppearance;
    FTextVerticalTextAlign := (Source as TTMSFNCPlannerItem).TextVerticalTextAlign;
    FTextHorizontalTextAlign := (Source as TTMSFNCPlannerItem).TextHorizontalTextAlign;
    FTitleVerticalTextAlign := (Source as TTMSFNCPlannerItem).TitleVerticalTextAlign;
    FTitleHorizontalTextAlign := (Source as TTMSFNCPlannerItem).TitleHorizontalTextAlign;
    FMarkRounding := (Source as TTMSFNCPlannerItem).MarkRounding;
    FMarkSizeRight := (Source as TTMSFNCPlannerItem).MarkSizeRight;
    FMarkColorBottom := (Source as TTMSFNCPlannerItem).MarkColorBottom;
    FMarkCorners := (Source as TTMSFNCPlannerItem).MarkCorners;
    FMarkSizeBottom := (Source as TTMSFNCPlannerItem).MarkSizeBottom;
    FMarkColorTop := (Source as TTMSFNCPlannerItem).MarkColorTop;
    FMarkColorLeft := (Source as TTMSFNCPlannerItem).MarkColorLeft;
    FMarkColor := (Source as TTMSFNCPlannerItem).MarkColor;
    FMarkColorRight := (Source as TTMSFNCPlannerItem).MarkColorRight;
    FMarkSizeTop := (Source as TTMSFNCPlannerItem).MarkSizeTop;
    FMarkSizeLeft := (Source as TTMSFNCPlannerItem).MarkSizeLeft;
    FMarkType := (Source as TTMSFNCPlannerItem).MarkType;
    FRounding := (Source as TTMSFNCPlannerItem).Rounding;
    FRoundingCorners := (Source as TTMSFNCPlannerItem).RoundingCorners;
    FDisabledStrokeColor := (Source as TTMSFNCPlannerItem).DisabledStrokeColor;
    FSelectedStrokeColor := (Source as TTMSFNCPlannerItem).SelectedStrokeColor;
    FActiveStrokeColor := (Source as TTMSFNCPlannerItem).ActiveStrokeColor;
    FStrokeColor := (Source as TTMSFNCPlannerItem).StrokeColor;
    FDisabledTitleStrokeColor := (Source as TTMSFNCPlannerItem).DisabledTitleStrokeColor;
    FSelectedTitleStrokeColor := (Source as TTMSFNCPlannerItem).SelectedTitleStrokeColor;
    FActiveTitleStrokeColor := (Source as TTMSFNCPlannerItem).ActiveTitleStrokeColor;
    FTitleStrokeColor := (Source as TTMSFNCPlannerItem).TitleStrokeColor;
    FHint := (Source as TTMSFNCPlannerItem).Hint;
    FBlockUpdate := False;
  end;
end;

procedure TTMSFNCPlannerItem.BeginUpdate;
begin
  Inc(FUpdateCount);
end;

function TTMSFNCPlannerItem.ConflictsForPosition(APosition: Integer): Integer;
var
  I: Integer;
begin
  Result := 1;
  for I := 0 to PositionsList.Count - 1 do
  begin
    if (PositionsList[I] = APosition) and (I >= 0) and (I <= ConflictsList.Count - 1) then
    begin
      Result := ConflictsList[I];
      Break;
    end;
  end;
end;

function TTMSFNCPlannerItem.ConflictsPosForPosition(
  APosition: Integer): Integer;
var
  I: Integer;
begin
  Result := 1;
  for I := 0 to PositionsList.Count - 1 do
  begin
    if (PositionsList[I] = APosition) and (I >= 0) and (I <= ConflictsPosList.Count - 1) then
    begin
      Result := ConflictsPosList[I];
      Break;
    end;
  end;
end;

constructor TTMSFNCPlannerItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
  begin
    FPlanner := (Collection as TTMSFNCPlannerItems).Planner;
    FTitle := 'Item ' + inttostr(FPlanner.Items.Count - 1);
  end;

  FOldPositionsList := TTMSFNCPlannerIntegerList.Create;
  FPositionsList := TTMSFNCPlannerIntegerList.Create;
  FDirtyList := TTMSFNCPlannerIntegerList.Create;
  FCleanupList := TTMSFNCPlannerIntegerList.Create;
  FConflictsPosList := TTMSFNCPlannerIntegerList.Create;
  FConflictsList := TTMSFNCPlannerIntegerList.Create;
  FFullDayPosListTop := TTMSFNCPlannerIntegerList.Create;
  FFullDayPosListBottom := TTMSFNCPlannerIntegerList.Create;
  FFullDayMaxListTop := TTMSFNCPlannerIntegerList.Create;
  FFullDayMaxListBottom := TTMSFNCPlannerIntegerList.Create;
  FCacheList := TTMSFNCPlannerCacheItemList.Create;
  FFullDayCacheList := TTMSFNCPlannerCacheItemList.Create;

  FResource := 0;
  FOverlappable := True;
  FShowTitle := True;
  FVisible := True;
  FFullDay := False;
  FFullDayLayout := AllFullDayLayouts;
  FSelectable := True;
  FDeletable := True;
  FMovable := True;
  FSizeable := True;
  FFixedResource := False;
  FLinkColor := gcDarkgray;
  FSelectedLinkColor := TMSFNCPlannerSelectedColor;
  FLinkArrow := ilaNone;
  FLinkType := iltNone;

  FMargins := TTMSFNCMargins.Create;
  FMargins.OnChange := @DoMarginsChanged;

  FColor := TMSFNCPlannerColor;
  FFontColor := TMSFNCPlannerFontColor;
  FTitleColor := gcNull;
  FTitleFontColor := TMSFNCPlannerFontColor;
  FSelectedColor := TMSFNCPlannerSelectedColor;
  FSelectedFontColor := gcWhite;
  FSelectedTitleColor := gcNull;
  FSelectedTitleFontColor := gcWhite;
  FActiveColor := TMSFNCPlannerSelectedColor;
  FActiveFontColor := gcWhite;
  FActiveTitleColor := gcNull;
  FActiveTitleFontColor := gcWhite;
  FDisabledColor := gcDarkGray;
  FDisabledFontColor := gcWhitesmoke;
  FDisabledTitleColor := gcNull;
  FDisabledTitleFontColor := gcWhitesmoke;
  FStartTime := Int(Now) + EncodeTime(6, 30, 0, 0);
  FEndTime := FStartTime + EncodeTime(2, 30, 0, 0);

  FFontStyle := [];
  FActiveFontStyle := [];
  FDisabledFontStyle := [];
  FSelectedFontStyle := [];

  FTitleFontStyle := [TFontStyle.fsBold];
  FActiveTitleFontStyle := [TFontStyle.fsBold];
  FDisabledTitleFontStyle := [TFontStyle.fsBold];
  FSelectedTitleFontStyle := [TFontStyle.fsBold];

  FUseDefaultAppearance := False;

  FFontName := '';
  FActiveFontName := '';
  FDisabledFontName := '';
  FSelectedFontName := '';

  FTitleFontName := '';
  FActiveTitleFontName := '';
  FDisabledTitleFontName := '';
  FSelectedTitleFontName := '';

  FFontSize := -1;
  FActiveFontSize := -1;
  FDisabledFontSize := -1;
  FSelectedFontSize := -1;

  FTitleFontSize := -1;
  FActiveTitleFontSize := -1;
  FDisabledTitleFontSize := -1;
  FSelectedTitleFontSize := -1;

  FEditable := True;
  FEnabled := True;

  FTitleVerticalTextAlign := gtaLeading;
  FTitleHorizontalTextAlign := gtaLeading;
  FTextHorizontalTextAlign := gtaLeading;
  FTextVerticalTextAlign := gtaLeading;

  FMarkType := [];
  FMarkColor := gcRed;
  FMarkColorLeft := gcNull;
  FMarkColorRight := gcNull;
  FMarkColorBottom := gcNull;
  FMarkColorTop := gcNull;
  FMarkSizeLeft := 4;
  FMarkSizeTop := 4;
  FMarkSizeRight := 4;
  FMarkSizeBottom := 4;

  FRounding := 0;
  FMarkRounding := 0;
  FMarkCorners := [gcTopLeft, gcTopRight, gcBottomLeft, gcBottomRight];
  FRoundingCorners := [gcTopLeft, gcTopRight, gcBottomLeft, gcBottomRight];

  FStrokeColor := gcDarkgray;
  FActiveStrokeColor := gcDarkgray;
  FSelectedStrokeColor := gcDarkgray;
  FDisabledStrokeColor := gcDarkgray;
  FTitleStrokeColor := gcDarkgray;
  FActiveTitleStrokeColor := gcDarkgray;
  FSelectedTitleStrokeColor := gcDarkgray;
  FDisabledTitleStrokeColor := gcDarkgray;

  if Assigned(FPlanner) then
    Assign(FPlanner.GetDefaultItem);

  UpdateItem(True);
end;

destructor TTMSFNCPlannerItem.Destroy;
begin
  if Assigned(FPlanner) and (FPlanner.GetActiveItem = Self) then
    FPlanner.UpdateActiveItem(nil);

  if Assigned(FCacheList) and Assigned(FPlanner) and not (csDestroying in Planner.ComponentState) then
    FPlanner.RemoveItemsFromCache(FCacheList);

  if Assigned(FPlanner) then
    FPlanner.NeedsConflictsUpdate(Self);

  if Assigned(FPlanner) then
    FPlanner.RemoveLinkedItem(Self);

  FMasterItem := nil;
  FLinkedItem := nil;
  FFullDayPosListTop.Free;
  FFullDayPosListBottom.Free;
  FFullDayMaxListTop.Free;
  FFullDayMaxListBottom.Free;
  FConflictsPosList.Free;
  FConflictsList.Free;
  FOldPositionsList.Free;
  FPositionsList.Free;
  FDirtyList.Free;
  FCleanupList.Free;
  FCacheList.Free;
  FFullDayCacheList.Free;
  FMargins.Free;
  inherited;
  UpdateItem(True);
  FPlanner := nil;
end;

procedure TTMSFNCPlannerItem.DirtyItem;
begin
  if Assigned(FPlanner) then
    FPlanner.DirtyItem(Self);
end;

procedure TTMSFNCPlannerItem.DoMarginsChanged(Sender: TObject);
begin
  UpdateItem(True);
end;

procedure TTMSFNCPlannerItem.SetSelectable(const Value: Boolean);
begin
  if FSelectable <> Value then
    FSelectable := Value;
end;

procedure TTMSFNCPlannerItem.SetSelectedColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedColor <> Value then
  begin
    FSelectedColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedLinkColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedLinkColor <> Value then
  begin
    FSelectedLinkColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedStrokeColor <> Value then
  begin
    FSelectedStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedFontColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedFontColor <> Value then
  begin
    FSelectedFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedFontName(const Value: string);
begin
  if FSelectedFontName <> Value then
  begin
    FSelectedFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedFontSize(const Value: Single);
begin
  if FSelectedFontSize <> Value then
  begin
    FSelectedFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedFontStyle(const Value: TFontStyles);
begin
  if FSelectedFontStyle <> Value then
  begin
    FSelectedFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTitleColor <> Value then
  begin
    FSelectedTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTitleFontColor <> Value then
  begin
    FSelectedTitleFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleFontName(const Value: string);
begin
  if FSelectedTitleFontName <> Value then
  begin
    FSelectedTitleFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleFontSize(const Value: Single);
begin
  if FSelectedTitleFontSize <> Value then
  begin
    FSelectedTitleFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleFontStyle(
  const Value: TFontStyles);
begin
  if FSelectedTitleFontStyle <> Value then
  begin
    FSelectedTitleFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetSelectedTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTitleStrokeColor <> Value then
  begin
    FSelectedTitleStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetShowTitle(const Value: Boolean);
begin
  if FShowTitle <> Value then
  begin
    FShowTitle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveColor <> Value then
  begin
    FActiveColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveFontColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveFontColor <> Value then
  begin
    FActiveFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveFontName(const Value: string);
begin
  if FActiveFontName <> Value then
  begin
    FActiveFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveFontSize(const Value: Single);
begin
  if FActiveFontSize <> Value then
  begin
    FActiveFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveFontStyle(const Value: TFontStyles);
begin
  if FActiveFontStyle <> Value then
  begin
    FActiveFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveStrokeColor <> Value then
  begin
    FActiveStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveTitleColor <> Value then
  begin
    FActiveTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FActiveTitleFontColor <> Value then
  begin
    FActiveTitleFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleFontName(const Value: string);
begin
  if FActiveTitleFontName <> Value then
  begin
    FActiveTitleFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleFontSize(const Value: Single);
begin
  if FActiveTitleFontSize <> Value then
  begin
    FActiveTitleFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleFontStyle(const Value: TFontStyles);
begin
  if FActiveTitleFontStyle <> Value then
  begin
    FActiveTitleFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetActiveTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveTitleStrokeColor <> Value then
  begin
    FActiveTitleStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetBackground(const Value: Boolean);
begin
  if FBackground <> Value then
  begin
    FBackground := Value;
    UpdateItem(True);
  end;
end;

procedure TTMSFNCPlannerItem.SetSizeable(const Value: Boolean);
begin
  if FSizeable <> Value then
  begin
    FSizeable := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.EndUpdate(ADirty: Boolean = True; AUpdate: Boolean = True);
begin
  Dec(FUpdateCount);
  if (FUpdateCount = 0) and AUpdate then
    UpdateItem(False, ADirty);
end;

function TTMSFNCPlannerItem.GetFirstRect: TRectF;
begin
  Result := EmptyRect;
  if (CacheList.Count > 0) then
    Result := CacheList[CacheList.Count - 1].DrawRect;
end;

function TTMSFNCPlannerItem.GetLastRect: TRectF;
begin
  Result := EmptyRect;
  if (CacheList.Count > 0) then
    Result := CacheList[0].DrawRect;
end;

function TTMSFNCPlannerItem.GetRect(AIndex: Integer): TRectF;
begin
  Result := EmptyRect;
  if (AIndex >= 0) and (AIndex <= CacheList.Count - 1) then
    Result := CacheList[AIndex].DrawRect
  else
    Result := GetFirstRect;
end;

function TTMSFNCPlannerItem.IsActiveFontSizeStored: Boolean;
begin
  Result := ActiveFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsActiveTitleFontSizeStored: Boolean;
begin
  Result := ActiveTitleFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsDisabledFontSizeStored: Boolean;
begin
  Result := DisabledFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsDisabledTitleFontSizeStored: Boolean;
begin
  Result := DisabledTitleFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsFontSizeStored: Boolean;
begin
  Result := FontSize <> -1;
end;

function TTMSFNCPlannerItem.IsMarkSizeBottomStored: Boolean;
begin
  Result := MarkSizeBottom <> 4;
end;

function TTMSFNCPlannerItem.IsMarkSizeLeftStored: Boolean;
begin
  Result := MarkSizeLeft <> 4;
end;

function TTMSFNCPlannerItem.IsMarkSizeRightStored: Boolean;
begin
  Result := MarkSizeRight <> 4;
end;

function TTMSFNCPlannerItem.IsMarkSizeTopStored: Boolean;
begin
  Result := MarkSizeTop <> 4;
end;

function TTMSFNCPlannerItem.IsSelectedFontSizeStored: Boolean;
begin
  Result := SelectedFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsSelectedTitleFontSizeStored: Boolean;
begin
  Result := SelectedTitleFontSize <> -1;
end;

function TTMSFNCPlannerItem.IsTitleFontSizeStored: Boolean;
begin
  Result := TitleFontSize <> -1;
end;

function TTMSFNCPlannerItem.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

function TTMSFNCPlannerItem.RectCount: Integer;
begin
  Result := CacheList.Count;
end;

procedure TTMSFNCPlannerItem.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDeletable(const Value: Boolean);
begin
  if FDeletable <> Value then
  begin
    FDeletable := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledColor <> Value then
  begin
    FDisabledColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledFontColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledFontColor <> Value then
  begin
    FDisabledFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledFontName(const Value: string);
begin
  if FDisabledFontName <> Value then
  begin
    FDisabledFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledFontSize(const Value: Single);
begin
  if FDisabledFontSize <> Value then
  begin
    FDisabledFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledFontStyle(const Value: TFontStyles);
begin
  if FDisabledFontStyle <> Value then
  begin
    FDisabledFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledStrokeColor <> Value then
  begin
    FDisabledStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTitleColor <> Value then
  begin
    FDisabledTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTitleFontColor <> Value then
  begin
    FDisabledTitleFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleFontName(const Value: string);
begin
  if FDisabledTitleFontName <> Value then
  begin
    FDisabledTitleFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleFontSize(const Value: Single);
begin
  if FDisabledTitleFontSize <> Value then
  begin
    FDisabledTitleFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleFontStyle(
  const Value: TFontStyles);
begin
  if FDisabledTitleFontStyle <> Value then
  begin
    FDisabledTitleFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetDisabledTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTitleStrokeColor <> Value then
  begin
    FDisabledTitleStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetEditable(const Value: Boolean);
begin
  if FEditable <> Value then
    FEditable := Value;
end;

procedure TTMSFNCPlannerItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetEndTime(const Value: TDateTime);
var
  dst, det: Double;
  drs: Integer;
begin
  if (CompareDateTime(FEndTime, Value) <> 0) and not FUpdatingLinkedItem then
  begin
    FUpdatingLinkedItem := True;
    dst := 0;
    det := Value - FEndTime;
    drs := 0;
    FEndTime := Value;
    if Assigned(FPlanner) then
      FPlanner.UpdateLinkedItems(Self, dst, det, drs);
    UpdateItem(True);
    FUpdatingLinkedItem := False;
  end;
end;

procedure TTMSFNCPlannerItem.SetFixedResource(const Value: Boolean);
begin
  if FFixedResource <> Value then
  begin
    FFixedResource := Value;
  end;
end;

procedure TTMSFNCPlannerItem.SetFontColor(const Value: TTMSFNCGraphicsColor);
begin
  if FFontColor <> Value then
  begin
    FFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetFontName(const Value: string);
begin
  if FFontName <> Value then
  begin
    FFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetFontSize(const Value: Single);
begin
  if FFontSize <> Value then
  begin
    FFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetFontStyle(const Value: TFontStyles);
begin
  if FFontStyle <> Value then
  begin
    FFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetFullDay(const Value: Boolean);
begin
  if FFullDay <> Value then
  begin
    FFullDay := Value;
    if Assigned(FPlanner) then
      FPlanner.BeginUpdate;

    UpdateItem(True);

    if Assigned(FPlanner) then
      FPlanner.EndUpdate;
  end;
end;

procedure TTMSFNCPlannerItem.SetFullDayLayout(
  const Value: TTMSFNCPlannerFullDaysLayouts);
begin
  if FFullDayLayout <> Value then
  begin
    FFullDayLayout := Value;
    if Assigned(FPlanner) then
      FPlanner.BeginUpdate;

    UpdateItem(True);

    if Assigned(FPlanner) then
      FPlanner.EndUpdate;
  end;
end;

procedure TTMSFNCPlannerItem.SetHint(const Value: String);
begin
  if FHint <> Value then
    FHint := Value;
end;

procedure TTMSFNCPlannerItem.SetLinkArrow(
  const Value: TTMSFNCPlannerItemLinkArrow);
begin
  if FLinkArrow <> Value then
  begin
    FLinkArrow := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetLinkColor(const Value: TTMSFNCGraphicsColor);
begin
  if FLinkColor <> Value then
  begin
    FLinkColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetLinkedItem(const Value: TTMSFNCPlannerItem);
begin
  if FLinkedItem <> Value then
    FLinkedItem := Value;
end;

procedure TTMSFNCPlannerItem.SetLinkType(
  const Value: TTMSFNCPlannerItemLinkType);
begin
  if FLinkType <> Value then
    FLinkType := Value;
end;

procedure TTMSFNCPlannerItem.SetMargins(const Value: TTMSFNCMargins);
begin
  FMargins.Assign(Value);
end;

procedure TTMSFNCPlannerItem.SetMarkColor(const Value: TTMSFNCGraphicsColor);
begin
  if FMarkColor <> Value then
  begin
    FMarkColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkColorBottom(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMarkColorBottom <> Value then
  begin
    FMarkColorBottom := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkColorLeft(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMarkColorLeft <> Value then
  begin
    FMarkColorLeft := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkColorRight(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMarkColorRight <> Value then
  begin
    FMarkColorRight := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkColorTop(const Value: TTMSFNCGraphicsColor);
begin
  if FMarkColorTop <> Value then
  begin
    FMarkColorTop := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkCorners(
  const Value: TTMSFNCGraphicsCorners);
begin
  if FMarkCorners <> Value then
  begin
    FMarkCorners := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkRounding(const Value: Integer);
begin
  if FMarkRounding <> Value then
  begin
    FMarkRounding := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkSizeBottom(const Value: Single);
begin
  if FMarkSizeBottom <> Value then
  begin
    FMarkSizeBottom := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkSizeLeft(const Value: Single);
begin
  if FMarkSizeLeft <> Value then
  begin
    FMarkSizeLeft := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkSizeRight(const Value: Single);
begin
  if FMarkSizeRight <> Value then
  begin
    FMarkSizeRight := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkSizeTop(const Value: Single);
begin
  if FMarkSizeTop <> Value then
  begin
    FMarkSizeTop := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMarkType(const Value: TTMSFNCPlannerItemMarkTypes);
begin
  if FMarkType <> Value then
  begin
    FMarkType := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetMovable(const Value: Boolean);
begin
  if FMovable <> Value then
  begin
    FMovable := Value;
  end;
end;

procedure TTMSFNCPlannerItem.SetOverlappable(const Value: Boolean);
begin
  if FOverlappable <> Value then
    FOverlappable := Value;
end;

procedure TTMSFNCPlannerItem.SetResource(const Value: Integer);
var
  dst, det: Double;
  drs: Integer;
begin
  if (FResource <> Value) and not FUpdatingLinkedItem then
  begin
    FUpdatingLinkedItem := True;
    dst := 0;
    det := 0;
    drs := Value - FResource;
    FResource := Value;
    if Assigned(FPlanner) then
      FPlanner.UpdateLinkedItems(Self, dst, det, drs);
    UpdateItem(True);
    FUpdatingLinkedItem := False;
  end;
end;

procedure TTMSFNCPlannerItem.SetRounding(const Value: Integer);
begin
  if FRounding <> Value then
  begin
    FRounding := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetRoundingCorners(const Value: TTMSFNCGraphicsCorners);
begin
  if FRoundingCorners <> Value then
  begin
    FRoundingCorners := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetStartTime(const Value: TDateTime);
var
  dst, det: Double;
  drs: Integer;
begin
  if (CompareDateTime(FStartTime, Value) <> 0) and not FUpdatingLinkedItem then
  begin
    FUpdatingLinkedItem := True;
    dst := Value - FStartTime;
    det := 0;
    drs := 0;
    FStartTime := Value;
    if Assigned(FPlanner) then
      FPlanner.UpdateLinkedItems(Self, dst, det, drs);
    UpdateItem(True);
    FUpdatingLinkedItem := False;
  end;
end;

procedure TTMSFNCPlannerItem.SetStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FStrokeColor <> Value then
  begin
    FStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTextHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextHorizontalTextAlign <> Value then
  begin
    FTextHorizontalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTextVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextVerticalTextAlign <> Value then
  begin
    FTextVerticalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitle(const Value: String);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTitleColor <> Value then
  begin
    FTitleColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleFontColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTitleFontColor <> Value then
  begin
    FTitleFontColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleFontName(const Value: string);
begin
  if FTitleFontName <> Value then
  begin
    FTitleFontName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleFontSize(const Value: Single);
begin
  if FTitleFontSize <> Value then
  begin
    FTitleFontSize := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleFontStyle(const Value: TFontStyles);
begin
  if FTitleFontStyle <> Value then
  begin
    FTitleFontStyle := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleHorizontalTextAlign <> Value then
  begin
    FTitleHorizontalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleStrokeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTitleStrokeColor <> Value then
  begin
    FTitleStrokeColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetTitleVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleVerticalTextAlign <> Value then
  begin
    FTitleVerticalTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetUseDefaultAppearance(const Value: Boolean);
begin
  if FUseDefaultAppearance <> Value then
  begin
    FUseDefaultAppearance := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCPlannerItem.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateItem(True);
  end;
end;

procedure TTMSFNCPlannerItem.UpdateItem(ANeedsConflictsUpdate: Boolean = False; ADirty: Boolean = True);
var
  c: TTMSFNCPlannerData;
begin
  if FBlockUpdate or (Assigned(FPlanner) and (csDestroying in FPlanner.ComponentState)) then
    Exit;

  c := Planner;
  if Assigned(c) then
  begin
    if Assigned(Self.Collection) then
    begin
      if ADirty then
        DirtyItem;
      if ANeedsConflictsUpdate then
        c.NeedsConflictsUpdate(Self);
    end;

    if (FUpdateCount > 0) or (Assigned(FPlanner) and (FPlanner.UpdateCount > 0)) then
      Exit;

    c.UpdateConflicts;
    c.UpdateFullDayConflicts;
    c.UpdateItemsCache;
    c.UpdateFullDaysItemsCache;
    c.Invalidate;
  end;
end;

{ TTMSFNCPlannerItems }

function TTMSFNCPlannerItems.Add: TTMSFNCPlannerItem;
begin
  Result := TTMSFNCPlannerItem(inherited Add);
end;

procedure TTMSFNCPlannerItems.Clear;
var
  p: TTMSFNCPlannerData;
begin
  p := Planner;
  if Assigned(p) and not (csDestroying in p.ComponentState) then
    p.RemoveAllItemsFromCache;

  inherited Clear;
end;

constructor TTMSFNCPlannerItems.Create(APlanner: TTMSFNCPlannerData);
begin
  inherited Create(APlanner, GetItemClass);
  FPlanner := APlanner;
end;

function TTMSFNCPlannerItems.CreateObject(const AClassName: string;
  const ABaseClass: TClass): TObject;
begin
  Result := TTMSFNCPlannerItem.Create(Self);
  if Assigned(FPlanner) then
    FPlanner.UpdatePlannerCache;
end;

function TTMSFNCPlannerItems.GetInterfaceItemClass: TClass;
begin
  Result := TTMSFNCPlannerItem;
end;

function TTMSFNCPlannerItems.GetItem(Index: Integer): TTMSFNCPlannerItem;
begin
  Result := TTMSFNCPlannerItem(inherited Items[Index]);
end;

function TTMSFNCPlannerItems.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCPlannerItem;
end;

function TTMSFNCPlannerItems.Insert(Index: Integer): TTMSFNCPlannerItem;
begin
  Result := TTMSFNCPlannerItem(inherited Insert(Index));
end;

function TTMSFNCPlannerItems.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

{$IFDEF LCLLIB}
function TTMSFNCPlannerItems.QueryInterface({$IFDEF FPC_HAS_CONSTREF}constref{$ELSE}const{$ENDIF} iid : tguid;out obj) : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCPlannerItems.QueryInterface(const IID: TGUID; out Obj): HResult;
{$ENDIF}
const
  E_NOINTERFACE = HResult($80004002);
begin
  if GetInterface(IID, Obj) then Result := 0 else Result := E_NOINTERFACE;
end;

procedure TTMSFNCPlannerItems.SetItem(Index: Integer;
  const Value: TTMSFNCPlannerItem);
begin
  inherited Items[Index] := Value;
end;

{$IFDEF LCLLIB}
function TTMSFNCPlannerItems._AddRef: longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCPlannerItems._AddRef: Integer;
{$ENDIF}
begin
  if FOwnerInterface <> nil then
    Result := FOwnerInterface._AddRef else
    Result := -1;
end;

{$IFDEF LCLLIB}
function TTMSFNCPlannerItems._Release: longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCPlannerItems._Release: Integer;
{$ENDIF}
begin
  if FOwnerInterface <> nil then
    Result := FOwnerInterface._Release else
    Result := -1;
end;

{ TTMSFNCPlannerGroup }

procedure TTMSFNCPlannerGroup.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerGroup then
  begin
    FTag := (Source as TTMSFNCPlannerGroup).Tag;
    FText := (Source as TTMSFNCPlannerGroup).Text;
    FHint := (Source as TTMSFNCPlannerGroup).Hint;
    FStartPosition := (Source as TTMSFNCPlannerGroup).StartPosition;
    FEndPosition := (Source as TTMSFNCPlannerGroup).EndPosition;
  end;
end;

constructor TTMSFNCPlannerGroup.Create(ACollection: TCollection);
var
  grp: TTMSFNCPlannerGroup;
begin
  inherited;
  if Assigned(Collection) then
    FPlanner := (Collection as TTMSFNCPlannerGroups).Planner;
  FText := TranslateTextEx(sTMSFNCPlannerGroup) + ' ' + inttostr(FPlanner.Groups.Count - 1);
  if FPlanner.Groups.Count < 2 then
  begin
    FStartPosition := 0;
    FEndPosition := 0
  end
  else
  begin
    grp := FPlanner.Groups[FPlanner.Groups.Count - 2];
    FStartPosition := grp.StartPosition + 1;
    FEndPosition := FStartPosition;
  end;
  UpdateGroup;
end;

destructor TTMSFNCPlannerGroup.Destroy;
begin
  inherited;
  UpdateGroup;
end;

function TTMSFNCPlannerGroup.GetText: String;
begin
  Result  := Text;
  if Result = '' then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCPlannerGroup) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCPlannerGroup.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

procedure TTMSFNCPlannerGroup.SetStartPosition(const Value: Integer);
begin
  if FStartPosition <> Value then
  begin
    FStartPosition := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCPlannerGroup.SetEndPosition(const Value: Integer);
begin
  if FEndPosition <> Value then
  begin
    FEndPosition := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCPlannerGroup.SetHint(const Value: string);
begin
  if FHint <> Value then
  begin
    FHint := Value;
  end;
end;

procedure TTMSFNCPlannerGroup.SetName(const Value: String);
begin
  if FName <> Value then
  begin
    FName := Value;
  end;
end;

procedure TTMSFNCPlannerGroup.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCPlannerGroup.UpdateGroup;
var
  c: TTMSFNCPlannerData;
begin
  c := Planner;
  if Assigned(c) then
    c.UpdatePlannerCache;
end;

{ TTMSFNCPlannerGroups }

function TTMSFNCPlannerGroups.Add: TTMSFNCPlannerGroup;
begin
  Result := TTMSFNCPlannerGroup(inherited Add);
end;

constructor TTMSFNCPlannerGroups.Create(APlanner: TTMSFNCPlannerData);
begin
  inherited Create(APlanner, GetItemClass);
  FPlanner := APlanner;
end;

function TTMSFNCPlannerGroups.GetItem(Index: Integer): TTMSFNCPlannerGroup;
begin
  Result := TTMSFNCPlannerGroup(inherited Items[Index]);
end;

function TTMSFNCPlannerGroups.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCPlannerGroup;
end;

function TTMSFNCPlannerGroups.Insert(Index: Integer): TTMSFNCPlannerGroup;
begin
  Result := TTMSFNCPlannerGroup(inherited Insert(Index));
end;

function TTMSFNCPlannerGroups.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

procedure TTMSFNCPlannerGroups.SetItem(Index: Integer;
  const Value: TTMSFNCPlannerGroup);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCPlannerResource }

procedure TTMSFNCPlannerResource.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPlannerResource then
  begin
    FTag := (Source as TTMSFNCPlannerResource).Tag;
    FHint := (Source as TTMSFNCPlannerResource).Hint;
    FText := (Source as TTMSFNCPlannerResource).Text;
    FName := (Source as TTMSFNCPlannerResource).Name;
  end;
end;

constructor TTMSFNCPlannerResource.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FPlanner := (Collection as TTMSFNCPlannerResources).Planner;

  if Assigned(FPlanner) then
    FText := TranslateTextEx(sTMSFNCPlannerResource) + ' ' + inttostr(FPlanner.Resources.Count - 1);

  FName := StringReplace(FText, ' ', '', [rfReplaceAll]);
  UpdateResource;
end;

destructor TTMSFNCPlannerResource.Destroy;
begin
  inherited;
  UpdateResource;
end;

function TTMSFNCPlannerResource.GetResourceText: String;
begin
  Result := Text;
  if (Result = '') or (Pos('</', Result) > 0) or (Pos('/>', Result) > 0) or (Pos('<BR>', UpperCase(Result)) > 0) then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCPlannerResource) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCPlannerResource.GetText: String;
begin
  Result := Text;
  if Result = '' then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCPlannerResource) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCPlannerResource.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

procedure TTMSFNCPlannerResource.SetHint(const Value: string);
begin
  if FHint <> Value then
  begin
    FHint := Value;
  end;
end;

procedure TTMSFNCPlannerResource.SetName(const Value: String);
begin
  if FName <> Value then
  begin
    FName := Value;
  end;
end;

procedure TTMSFNCPlannerResource.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateResource;
  end;
end;

procedure TTMSFNCPlannerResource.UpdateResource;
var
  c: TTMSFNCPlannerData;
begin
  if (Assigned(FPlanner) and (csDestroying in FPlanner.ComponentState)) then
    Exit;

  c := Planner;
  if Assigned(c) then
    c.UpdatePlannerCache;
end;

{ TTMSFNCPlannerResources }

function TTMSFNCPlannerResources.Add: TTMSFNCPlannerResource;
begin
  Result := TTMSFNCPlannerResource(inherited Add);
end;

constructor TTMSFNCPlannerResources.Create(APlanner: TTMSFNCPlannerData);
begin
  inherited Create(APlanner, GetItemClass);
  FPlanner := APlanner;
end;

function TTMSFNCPlannerResources.GetItem(Index: Integer): TTMSFNCPlannerResource;
begin
  Result := TTMSFNCPlannerResource(inherited Items[Index]);
end;

function TTMSFNCPlannerResources.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCPlannerResource;
end;

function TTMSFNCPlannerResources.Insert(Index: Integer): TTMSFNCPlannerResource;
begin
  Result := TTMSFNCPlannerResource(inherited Insert(Index));
end;

function TTMSFNCPlannerResources.Planner: TTMSFNCPlannerData;
begin
  Result := FPlanner;
end;

procedure TTMSFNCPlannerResources.SetItem(Index: Integer;
  const Value: TTMSFNCPlannerResource);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCPlannerCacheItem }

class function TTMSFNCPlannerCacheItem.CreateCache(ARect: TRectF;
  ABitmap: TBitmap; AKind: TTMSFNCPlannerCacheItemKind; APosition: Integer = -1): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := AKind;
  Result.Position := APosition;
  Result.Rect := ARect;
  Result.Bitmap := ABitmap;
end;

class function TTMSFNCPlannerCacheItem.CreateCell(ARect: TRectF; ACol, ARow: Integer; AStartTime, AEndTime: TDateTime; APosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikCell;
  Result.Rect := ARect;
  Result.Col := ACol;
  Result.Row := ARow;
  Result.StartTime := AStartTime;
  Result.EndTime := AEndTime;
  Result.Position := APosition;
end;

class function TTMSFNCPlannerCacheItem.CreateFullDayBottom(
  ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikFullDayBottom;
  Result.FullDay := AFullDay;
  Result.StartPosition := AStartPosition;
  Result.EndPosition := AEndPosition;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreateFullDayItemBottom(ARect: TRectF;
  APosition: Integer; AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikFullDayItemBottom;
  Result.Rect := ARect;
  Result.Position := APosition;
  Result.Item := AItem;
end;

class function TTMSFNCPlannerCacheItem.CreateFullDayItemTop(ARect: TRectF;
  APosition: Integer; AItem: TTMSFNCPlannerItem): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikFullDayItemTop;
  Result.Rect := ARect;
  Result.Position := APosition;
  Result.Item := AItem;
end;

class function TTMSFNCPlannerCacheItem.CreateFullDayTop(
  ARect: TRectF; AFullDay: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikFullDayTop;
  Result.FullDay := AFullDay;
  Result.StartPosition := AStartPosition;
  Result.EndPosition := AEndPosition;
  Result.Rect := ARect;
end;


class function TTMSFNCPlannerCacheItem.CreateGroupBottom(
  ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikGroupBottom;
  Result.Group := AGroup;
  Result.StartPosition := AStartPosition;
  Result.EndPosition := AEndPosition;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreateGroupTop(
  ARect: TRectF; AGroup: Integer; AStartPosition, AEndPosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikGroupTop;
  Result.Group := AGroup;
  Result.StartPosition := AStartPosition;
  Result.EndPosition := AEndPosition;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreateItem(ARect: TRectF; APosition: Integer; AItem: TTMSFNCPlannerItem; AIndex: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikItem;
  Result.Rect := ARect;
  Result.Position := APosition;
  Result.Idx := AIndex;
  Result.Item := AItem;
end;

class function TTMSFNCPlannerCacheItem.CreatePositionTop(ARect: TRectF; APosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikPositionTop;
  Result.Position := APosition;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreatePositionBottom(ARect: TRectF; APosition: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikPositionBottom;
  Result.Position := APosition;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreateTimeLineLeft(ARect: TRectF; AValue: Double; ARow: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikTimeLineLeft;
  Result.Value := AValue;
  Result.Row := ARow;
  Result.Rect := ARect;
end;

class function TTMSFNCPlannerCacheItem.CreateTimeLineRight(ARect: TRectF; AValue: Double; ARow: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem.Create;
  Result.Kind := ikTimeLineRight;
  Result.Value := AValue;
  Result.Row := ARow;
  Result.Rect := ARect;
end;

destructor TTMSFNCPlannerCacheItem.Destroy;
begin
  if Assigned(FItem) and Assigned(FItem.FCacheList) then
    FItem.FCacheList.Remove(Self);

  FItem := nil;

  if Assigned(FBitmap) then
    FBitmap.Free;
  inherited;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCPlannerResourceDate.=(z1, z2: TTMSFNCPlannerResourceDate)b: boolean;
begin
  Result := z1.DateTime = z2.DateTime;
end;
{$ENDIF}

{$IFDEF WEBLIB}
function TTMSFNCPlannerCacheItemList.GetItem(Index: Integer): TTMSFNCPlannerCacheItem;
begin
  Result := TTMSFNCPlannerCacheItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerCacheItemList.SetItem(Index: Integer; const Value: TTMSFNCPlannerCacheItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerIntegerList.GetItem(Index: Integer): Integer;
begin
  Result := Integer(inherited Items[Index]);
end;

procedure TTMSFNCPlannerIntegerList.SetItem(Index: Integer; const Value: Integer);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerResourceItems.GetItem(Index: Integer): TTMSFNCPlannerItem;
begin
  Result := TTMSFNCPlannerItem(inherited Items[Index]);
end;

procedure TTMSFNCPlannerResourceItems.SetItem(Index: Integer; const Value: TTMSFNCPlannerItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCPlannerResourceDates.GetItem(Index: Integer): TTMSFNCPlannerResourceDate;
begin
  Result := TTMSFNCPlannerResourceDate(inherited Items[Index]);
end;

procedure TTMSFNCPlannerResourceDates.SetItem(Index: Integer; const Value: TTMSFNCPlannerResourceDate);
var
  v: TTMSFNCPlannerResourceDate;
begin
  v := Value;
  inherited Items[Index] := v;
end;
{$ENDIF}

end.
