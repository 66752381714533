unit Main;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCPlannerBase, VCL.TMSFNCPlannerData, VCL.TMSFNCPlanner,
  Vcl.Controls, WEBLib.ExtCtrls;

type
  TForm1 = class(TForm)
    WebPanel1: TPanel;
    TMSFNCPlanner1: TTMSFNCPlanner;
    WebPanel2: TPanel;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  TMSFNCPlanner1 := TTMSFNCPlanner.Create(Self);
  WebPanel2 := TPanel.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  TMSFNCPlanner1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 791;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 791;
    WebPanel1.Height := 60;
    WebPanel1.ElementClassName := 'card shadow p-3 mb-5 bg-white rounded';
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'Landrix Teampartner Network';
    WebPanel1.ElementBodyClassName := 'card-body';
    TMSFNCPlanner1.SetParentComponent(Self);
    TMSFNCPlanner1.Name := 'TMSFNCPlanner1';
    TMSFNCPlanner1.Left := 113;
    TMSFNCPlanner1.Top := 60;
    TMSFNCPlanner1.Width := 678;
    TMSFNCPlanner1.Height := 420;
    TMSFNCPlanner1.Align := alClient;
    TMSFNCPlanner1.ParentDoubleBuffered := False;
    TMSFNCPlanner1.Color := 16380654;
    TMSFNCPlanner1.DoubleBuffered := True;
    TMSFNCPlanner1.TabOrder := 1;
    TMSFNCPlanner1.DefaultItem.Text := 'Sample text for this item.';
    TMSFNCPlanner1.DefaultItem.Title := 'Item Title';
    TMSFNCPlanner1.DefaultItem.StartTime := 45189.270833333340000000;
    TMSFNCPlanner1.DefaultItem.EndTime := 45189.375000000000000000;
    TMSFNCPlanner1.DefaultItem.StrokeColor := 11119017;
    TMSFNCPlanner1.DefaultItem.TitleStrokeColor := 11119017;
    TMSFNCPlanner1.DefaultItem.SelectedColor := 15776936;
    TMSFNCPlanner1.DefaultItem.SelectedStrokeColor := 15106394;
    TMSFNCPlanner1.DefaultItem.ActiveColor := 15776936;
    TMSFNCPlanner1.DefaultItem.ActiveStrokeColor := 15106394;
    TMSFNCPlanner1.DefaultItem.FontColor := 8026746;
    TMSFNCPlanner1.DefaultItem.TitleFontColor := 4539717;
    TMSFNCPlanner1.DefaultItem.ActiveFontColor := 4539717;
    TMSFNCPlanner1.DefaultItem.ActiveTitleFontColor := 4539717;
    TMSFNCPlanner1.DefaultItem.MarkType := [imtTop];
    TMSFNCPlanner1.DefaultItem.MarkColor := 15106394;
    TMSFNCPlanner1.DefaultItem.MarkSizeTop := 8.000000000000000000;
    TMSFNCPlanner1.DefaultItem.MarkRounding := 3;
    TMSFNCPlanner1.DefaultItem.MarkCorners := [gcTopLeft,gcTopRight];
    TMSFNCPlanner1.DefaultItem.Rounding := 3;
    TMSFNCPlanner1.DefaultItem.RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    TMSFNCPlanner1.GridCellAppearance.HorizontalSubStroke.Color := 13816015;
    TMSFNCPlanner1.GridCellAppearance.InActiveFill.Color := 16711679;
    TMSFNCPlanner1.GridCellAppearance.Fill.Color := 16578806;
    TMSFNCPlanner1.GroupsAppearance.TopSize := 50.000000000000000000;
    TMSFNCPlanner1.GroupsAppearance.BottomSize := 50.000000000000000000;
    TMSFNCPlanner1.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.GroupsAppearance.TopFont.Color := clGray;
    TMSFNCPlanner1.GroupsAppearance.TopFont.Height := -11;
    TMSFNCPlanner1.GroupsAppearance.TopFont.Name := 'Segoe UI';
    TMSFNCPlanner1.GroupsAppearance.TopFont.Style := [];
    TMSFNCPlanner1.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.GroupsAppearance.BottomFont.Color := clGray;
    TMSFNCPlanner1.GroupsAppearance.BottomFont.Height := -11;
    TMSFNCPlanner1.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    TMSFNCPlanner1.GroupsAppearance.BottomFont.Style := [];
    TMSFNCPlanner1.FullDaysAppearance.AutoItemHeight := 25.000000000000000000;
    TMSFNCPlanner1.FullDaysAppearance.TopSize := 25.000000000000000000;
    TMSFNCPlanner1.FullDaysAppearance.BottomSize := 25.000000000000000000;
    TMSFNCPlanner1.FullDaysAppearance.TopFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.FullDaysAppearance.TopFont.Color := clWindowText;
    TMSFNCPlanner1.FullDaysAppearance.TopFont.Height := -11;
    TMSFNCPlanner1.FullDaysAppearance.TopFont.Name := 'Segoe UI';
    TMSFNCPlanner1.FullDaysAppearance.TopFont.Style := [];
    TMSFNCPlanner1.FullDaysAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.FullDaysAppearance.BottomFont.Color := clWindowText;
    TMSFNCPlanner1.FullDaysAppearance.BottomFont.Height := -11;
    TMSFNCPlanner1.FullDaysAppearance.BottomFont.Name := 'Segoe UI';
    TMSFNCPlanner1.FullDaysAppearance.BottomFont.Style := [];
    TMSFNCPlanner1.Items.Clear;
    with TMSFNCPlanner1.Items.Add do
    begin
      Text := 'Test performance of the new feature.<br><i>And with remote connection.</i>';
      Title := 'QA';
      StartTime := 45189.354166666660000000;
      EndTime := 45189.458333333340000000;
      StrokeColor := 11119017;
      TitleStrokeColor := 11119017;
      SelectedColor := 15776936;
      SelectedStrokeColor := 15106394;
      SelectedTitleStrokeColor := 15106394;
      ActiveColor := 15776936;
      ActiveStrokeColor := 15106394;
      ActiveTitleStrokeColor := 15106394;
      FontColor := 8026746;
      TitleFontColor := 4539717;
      ActiveFontColor := 4539717;
      ActiveTitleFontColor := 4539717;
      MarkType := [imtTop];
      MarkColor := 15106394;
      MarkSizeTop := 8.000000000000000000;
      MarkRounding := 3;
      MarkCorners := [gcTopLeft,gcTopRight];
      Rounding := 3;
      RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    end;
    with TMSFNCPlanner1.Items.Add do
    begin
      Text := 'Write the developers guide and <a href="https://www.tmssoftware.com">FAQ</a>';
      Title := 'Documentation';
      StartTime := 45189.520833333340000000;
      EndTime := 45189.593750000000000000;
      Color := 16711679;
      StrokeColor := 11119017;
      TitleStrokeColor := 11119017;
      SelectedColor := 13824723;
      SelectedStrokeColor := 11070896;
      SelectedTitleStrokeColor := 11070896;
      ActiveColor := 13824723;
      ActiveStrokeColor := 11070896;
      ActiveTitleStrokeColor := 11070896;
      FontColor := 8026746;
      TitleFontColor := 4539717;
      ActiveFontColor := 4539717;
      ActiveTitleFontColor := 4539717;
      MarkType := [imtTop];
      MarkColor := 11070896;
      MarkSizeTop := 8.000000000000000000;
      MarkRounding := 3;
      MarkCorners := [gcTopLeft,gcTopRight];
      Rounding := 3;
      RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    end;
    with TMSFNCPlanner1.Items.Add do
    begin
      Text := 'Fix <u>high priority</u> issues documented by customer.';
      Title := 'Bugfix 376';
      StartTime := 45190.458333333340000000;
      EndTime := 45190.645833333340000000;
      Color := 16711679;
      StrokeColor := 11119017;
      TitleStrokeColor := 11119017;
      SelectedColor := 10984945;
      SelectedStrokeColor := 5456878;
      SelectedTitleStrokeColor := 5456878;
      ActiveColor := 10984945;
      ActiveStrokeColor := 5456878;
      ActiveTitleStrokeColor := 5456878;
      FontColor := 8026746;
      TitleFontColor := 4539717;
      ActiveFontColor := 4539717;
      ActiveTitleFontColor := 4539717;
      MarkType := [imtTop];
      MarkColor := 5456878;
      MarkSizeTop := 8.000000000000000000;
      MarkRounding := 3;
      MarkCorners := [gcTopLeft,gcTopRight];
      Rounding := 3;
      RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    end;
    with TMSFNCPlanner1.Items.Add do
    begin
      Text := 'Get in contact with the customer to let him know how far we are on developing the newly requested items.';
      Title := 'Customer Update';
      StartTime := 45191.354166666660000000;
      EndTime := 45191.458333333340000000;
      Color := 16711679;
      StrokeColor := 11119017;
      TitleStrokeColor := 11119017;
      SelectedColor := 16116138;
      SelectedStrokeColor := 15719007;
      SelectedTitleStrokeColor := 15719007;
      ActiveColor := 16116138;
      ActiveStrokeColor := 15719007;
      ActiveTitleStrokeColor := 15719007;
      FontColor := 8026746;
      TitleFontColor := 4539717;
      ActiveFontColor := 4539717;
      ActiveTitleFontColor := 4539717;
      MarkType := [imtTop];
      MarkColor := 15719007;
      MarkSizeTop := 8.000000000000000000;
      MarkRounding := 3;
      MarkCorners := [gcTopLeft,gcTopRight];
      Rounding := 3;
      RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    end;
    with TMSFNCPlanner1.Items.Add do
    begin
      Text := 'Issue when using non-alphanumeric characters.';
      Title := 'Bugfix 374';
      StartTime := 45191.520833333340000000;
      EndTime := 45191.666666666660000000;
      Color := 16711679;
      StrokeColor := 11119017;
      TitleStrokeColor := 11119017;
      SelectedColor := 10019314;
      SelectedStrokeColor := 3525360;
      SelectedTitleStrokeColor := 3525360;
      ActiveColor := 10019314;
      ActiveStrokeColor := 3525360;
      ActiveTitleStrokeColor := 3525360;
      FontColor := 8026746;
      TitleFontColor := 4539717;
      ActiveFontColor := 4539717;
      ActiveTitleFontColor := 4539717;
      MarkType := [imtTop];
      MarkColor := 3525360;
      MarkSizeTop := 8.000000000000000000;
      MarkRounding := 3;
      MarkCorners := [gcTopLeft,gcTopRight];
      Rounding := 3;
      RoundingCorners := [gcTopLeft,gcTopRight,gcBottomLeft,gcBottomRight];
    end;
    TMSFNCPlanner1.ModeSettings.StartTime := 45189.337772847220000000;
    TMSFNCPlanner1.ModeSettings.EndTime := 45209.337772847220000000;
    TMSFNCPlanner1.PositionsAppearance.Size := 100.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.TopSize := 50.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.BottomSize := 50.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.PositionsAppearance.TopFont.Color := 4539717;
    TMSFNCPlanner1.PositionsAppearance.TopFont.Height := -13;
    TMSFNCPlanner1.PositionsAppearance.TopFont.Name := 'Segoe UI';
    TMSFNCPlanner1.PositionsAppearance.TopFont.Style := [fsBold];
    TMSFNCPlanner1.PositionsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.PositionsAppearance.BottomFont.Color := 4539717;
    TMSFNCPlanner1.PositionsAppearance.BottomFont.Height := -13;
    TMSFNCPlanner1.PositionsAppearance.BottomFont.Name := 'Segoe UI';
    TMSFNCPlanner1.PositionsAppearance.BottomFont.Style := [fsBold];
    TMSFNCPlanner1.PositionsAppearance.TopLeftNavigationButtonSize := 18.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.TopRightNavigationButtonSize := 18.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.BottomLeftNavigationButtonSize := 18.000000000000000000;
    TMSFNCPlanner1.PositionsAppearance.BottomRightNavigationButtonSize := 18.000000000000000000;
    TMSFNCPlanner1.Resources.Clear;
    with TMSFNCPlanner1.Resources.Add do
    begin
      Name := 'Resource0';
      Text := 'David';
    end;
    with TMSFNCPlanner1.Resources.Add do
    begin
      Name := 'Resource1';
      Text := 'Marie';
    end;
    with TMSFNCPlanner1.Resources.Add do
    begin
      Name := 'Resource2';
      Text := 'Carol';
    end;
    TMSFNCPlanner1.SelectionAppearance.Stroke.Color := 15702829;
    TMSFNCPlanner1.SelectionAppearance.Stroke.Width := 2.000000000000000000;
    TMSFNCPlanner1.Stroke.Color := 11119017;
    TMSFNCPlanner1.TimeLine.ActiveStart := 17;
    TMSFNCPlanner1.TimeLine.ActiveEnd := 34;
    TMSFNCPlanner1.TimeLine.DisplayUnitSize := 25.000000000000000000;
    TMSFNCPlanner1.TimeLine.ViewStart := 45189.333333333340000000;
    TMSFNCPlanner1.TimeLineAppearance.LeftSize := 50.000000000000000000;
    TMSFNCPlanner1.TimeLineAppearance.RightSize := 50.000000000000000000;
    TMSFNCPlanner1.TimeLineAppearance.LeftFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.TimeLineAppearance.LeftFont.Color := 4539717;
    TMSFNCPlanner1.TimeLineAppearance.LeftFont.Height := -19;
    TMSFNCPlanner1.TimeLineAppearance.LeftFont.Name := 'Segoe UI';
    TMSFNCPlanner1.TimeLineAppearance.LeftFont.Style := [];
    TMSFNCPlanner1.TimeLineAppearance.RightFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.TimeLineAppearance.RightFont.Color := 4539717;
    TMSFNCPlanner1.TimeLineAppearance.RightFont.Height := -19;
    TMSFNCPlanner1.TimeLineAppearance.RightFont.Name := 'Segoe UI';
    TMSFNCPlanner1.TimeLineAppearance.RightFont.Style := [];
    TMSFNCPlanner1.TimeLineAppearance.LeftSubUnitFontSize := 9.000000000000000000;
    TMSFNCPlanner1.TimeLineAppearance.RightSubUnitFontSize := 9.000000000000000000;
    TMSFNCPlanner1.TimeLineAppearance.CurrentTimeColor := 15702829;
    TMSFNCPlanner1.ItemsAppearance.Gap := 10.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.MoveAreaSize := 4.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.SizeAreaSize := 4.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.DeleteAreaSize := 16.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.DeleteHandlerBitmap.LoadFromFile('Main.TMSFNCPlanner1.ItemsAppearance.DeleteHandlerBitmap.png');
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerHeight := 30.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerWidth := 50.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.DeleteHandlerHeight := 30.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.DeleteHandlerWidth := 30.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerLeftBitmap.LoadFromFile('Main.TMSFNCPlanner1.ItemsAppearance.SizeHandlerLeftBitmap.png');
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerRightBitmap.LoadFromFile('Main.TMSFNCPlanner1.ItemsAppearance.SizeHandlerRightBitmap.png');
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerUpBitmap.LoadFromFile('Main.TMSFNCPlanner1.ItemsAppearance.SizeHandlerUpBitmap.png');
    TMSFNCPlanner1.ItemsAppearance.SizeHandlerDownBitmap.LoadFromFile('Main.TMSFNCPlanner1.ItemsAppearance.SizeHandlerDownBitmap.png');
    TMSFNCPlanner1.ItemsAppearance.LinkStroke.Width := 2.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.LinkArrowSize := 7.000000000000000000;
    TMSFNCPlanner1.ItemsAppearance.TitleStroke.Color := -1;
    TMSFNCPlanner1.ItemsAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.TitleFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.TitleFont.Height := -12;
    TMSFNCPlanner1.ItemsAppearance.TitleFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.TitleFont.Style := [fsBold];
    TMSFNCPlanner1.ItemsAppearance.Stroke.Kind := gskSolid;
    TMSFNCPlanner1.ItemsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.Font.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.Font.Height := -11;
    TMSFNCPlanner1.ItemsAppearance.Font.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.Font.Style := [];
    TMSFNCPlanner1.ItemsAppearance.SelectedTitleFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.SelectedTitleFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.SelectedTitleFont.Height := -12;
    TMSFNCPlanner1.ItemsAppearance.SelectedTitleFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.SelectedTitleFont.Style := [fsBold];
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleStroke.Color := -1;
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleFont.Height := -12;
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.ActiveTitleFont.Style := [fsBold];
    TMSFNCPlanner1.ItemsAppearance.SelectedFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.SelectedFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.SelectedFont.Height := -11;
    TMSFNCPlanner1.ItemsAppearance.SelectedFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.SelectedFont.Style := [];
    TMSFNCPlanner1.ItemsAppearance.ActiveStroke.Kind := gskSolid;
    TMSFNCPlanner1.ItemsAppearance.ActiveFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.ActiveFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.ActiveFont.Height := -11;
    TMSFNCPlanner1.ItemsAppearance.ActiveFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.ActiveFont.Style := [];
    TMSFNCPlanner1.ItemsAppearance.DisabledTitleFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.DisabledTitleFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.DisabledTitleFont.Height := -12;
    TMSFNCPlanner1.ItemsAppearance.DisabledTitleFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.DisabledTitleFont.Style := [fsBold];
    TMSFNCPlanner1.ItemsAppearance.DisabledFont.Charset := DEFAULT_CHARSET;
    TMSFNCPlanner1.ItemsAppearance.DisabledFont.Color := clWindowText;
    TMSFNCPlanner1.ItemsAppearance.DisabledFont.Height := -11;
    TMSFNCPlanner1.ItemsAppearance.DisabledFont.Name := 'Segoe UI';
    TMSFNCPlanner1.ItemsAppearance.DisabledFont.Style := [];
    TMSFNCPlanner1.GlobalFont.Scale := 1.000000000000000000;
    TMSFNCPlanner1.GlobalFont.Style := [];
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 60;
    WebPanel2.Width := 113;
    WebPanel2.Height := 420;
    WebPanel2.Align := alLeft;
    WebPanel2.ChildOrder := 2;
  finally
    WebPanel1.AfterLoadDFMValues;
    TMSFNCPlanner1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
  end;
end;

end.